import { NgModule } from '@angular/core';
import { CustomCurrencyPipe } from './customcurrency.pipe';
import { CurrencyPipe } from '@angular/common';

@NgModule({
  imports: [],
  exports: [CustomCurrencyPipe],
  declarations: [CustomCurrencyPipe],
  providers: [CurrencyPipe]
})
export class CustomCurrencyPipeModule {
}
