import { FormControl, ValidatorFn } from '@angular/forms';

export default class AssetFormValidators {

  static sendToSelf(prms: { fromAddress: string }): ValidatorFn {
    return (control: FormControl): { [key: string]: boolean } => {
      if (prms.fromAddress === control.value) {
        return { no_send_to_self: true };
      } else {
        return null;
      }
    };
  }

}
