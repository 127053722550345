import { Directive, EventEmitter, HostListener, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appDebounceInput]'
})
export class DebounceInputDirective implements OnChanges {
  // Input for debounce time with a default of 500ms
  @Input() debounceTime = 500;

  // Output event that emits after the debounce period
  @Output() debouncedInput = new EventEmitter<any>();

  // Subject to handle the debouncing
  private inputSubject = new Subject<any>();

  constructor() {
    // Initialize debounce logic with the default debounce time
    this.initializeDebounce();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If debounceTime input changes, reinitialize the debounce logic
    if (changes['debounceTime']) {
      this.initializeDebounce();
    }
  }

  private initializeDebounce() {
    // Unsubscribe from any previous subscriptions to avoid memory leaks
    this.inputSubject = new Subject<any>();
    this.inputSubject.pipe(debounceTime(this.debounceTime)).subscribe(value => {
      this.debouncedInput.emit(value);
    });
  }

  // HostListener to listen to the native 'input' event
  @HostListener('input', ['$event'])
  onInput(event: any) {
    this.inputSubject.next(event);
  }
}
