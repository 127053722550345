import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate } from '@angular/router';

export interface ComponentCanDeactivate {
  canDeactivate: (route) => Promise<boolean> | boolean;
}

@Injectable()
export class SettingsGuardService implements CanDeactivate<ComponentCanDeactivate> {

  async canDeactivate(component: ComponentCanDeactivate, route: ActivatedRouteSnapshot): Promise<boolean> {
    return component.canDeactivate(route);
  }

}
