import {Injectable } from "@angular/core";
import { isNil } from "../ts-util";
import { Store } from "@ngrx/store";
import { AppState } from "../store/appState";
import { Logger } from "./logger.service";
import { WalletConnectService } from "./wallet-connect.service";
import { inspect } from "util";
import { ORDER_STATUS, SingularityAssetInfo, SingularityOrderInfo, getSingularityAssets } from "../store/purchaseOrder.state";
import { take } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SingularityErrorAction, SingularityInitNewPurchaseRequest, SingularityStateInitRequestAction, SingularitySyncAssetInfoAction } from "../actions/singularityPayment.action";
import { isArray } from "rxjs/internal-compatibility";
import { MembersPortalService } from "./members-portal.service";
import { WalletService } from "./wallet.service";
import { getSingularitySession, getWalletConnectInstance } from "../store/walletConnect";
import { WalletConnectSessionDisconnectRequestAction } from "../actions/walletConnect.actions";
@Injectable({
    providedIn: "root"
})
export class SingularityPaymentService {
  
  private allowedAssets = ['AGX', 'AUX', 'LODE', 'AVAX', 'USDC','WAVAX'];
  
  constructor(
    private store: Store<AppState>,
    private walletConnectService: WalletConnectService,
    private http: HttpClient,
    private memberPortalService: MembersPortalService,
    private walletService: WalletService,
  ) {}

  async initializedSingularityPayment() {
    await this.disconnectAllExistingSingularityTopicSession();
    this.store.dispatch(new SingularityStateInitRequestAction());
  }

  async disconnectAllExistingSingularityTopicSession() {
    const singularitySession = await this.store.select(getSingularitySession).pipe(take(1)).toPromise();
    if (! isNil(singularitySession?.handshakeTopic)) {
      this.store.dispatch(new WalletConnectSessionDisconnectRequestAction({sessionTopic: singularitySession?.handshakeTopic}))
    }
    const web3WalletInstance = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
    Logger.info(`web3WalletInstance - ${inspect(web3WalletInstance)}`);
    let existingPairs = web3WalletInstance.core.pairing.getPairings();
    Logger.info(`current pairs ${inspect(existingPairs)}`);
    for (let pair of existingPairs) {
      if (pair?.topic && pair?.peerMetadata?.name == 'Singularity') {
        Logger.info(`disconnect Singularity Session ${inspect(pair)}`);
        try {
          await web3WalletInstance.core.pairing.disconnect({topic: pair.topic});
        } catch (err) {
          // ignore this error
          Logger.error(`Error disconnectAllExistingSingularityTopicSession ${inspect(err.message)}`);
        }
      }
    }
  }

  async purchaseTokenByCard(tokenSymbol: string, amount: number) {
    try {
      
      let singularityAssetInfo = await this.getSingularityAsset(tokenSymbol);
      const lodeUser = await this.memberPortalService.getUserInfo();
      const userLodeId = lodeUser.lodeid;
      const userWalletAddress = await this.getUserWalletAddress(singularityAssetInfo);
      ///https://docs.s9y.xyz/web-sdk-integration-guide/appchain-onramp
      const body = {
        clientReferenceId: lodeUser.lodeid,  //Your internal transaction // reference ID
        singularityTransactionType: 'RECEIVE',
        transactionLabel: 'Lode user ' + userLodeId + ' purchase',
        transactionDescription: 'Purchase token by card from LODE and Singularity',
        transactionIconLink:
            'https://github.com/lode-one/public-asset-info/blob/main/snowtrace-logo/LODE_icon.png', // Any public url //of png or jpg image. This will be shown to the user during payment flow.
        clientReceiveObject: {
          clientRequestedAssetId: singularityAssetInfo.assetId,  //This is Singularity's internal ID for the // particular token you want to receive. Get the full list of IDs for your 
        // AppChain by contacting us.
            clientRequestedAssetQuantity: amount,
            address: userWalletAddress // This field is 
        // optional and points to who will receive the asset.
        // if the field is not provided, the asset will be transferred to your 
        // registered address, instead of the user's.
        }
      }
      let singularityOrderInfo: SingularityOrderInfo = {
        paymentType: 'CreditCard',
        purchaseAmount: amount,
        paymentMethod: 'Singularity',
        status: ORDER_STATUS.INIT,
        createdTime: Date.now(),
        walletAddress: userWalletAddress,
        body,
        singularityAssetInfo
      }
      this.store.dispatch(new SingularityInitNewPurchaseRequest({pendingOrder: singularityOrderInfo}));
    } catch (err) {
      Logger.error(`error - purchaseTokenByCard ${inspect(err)}`);
      this.store.dispatch(new SingularityErrorAction(`Error init Singularity payment ${err?.message}`));
    }
  }



  async getUserWalletAddress(singularityAssetInfo: SingularityAssetInfo): Promise<string> {
    const supportedNetworkExplorers = await this.walletConnectService.getWalletConnectSupportNetworks();
    let selectedNetworkExplorer = supportedNetworkExplorers
                                      .find(supportedNetworkExplorer => supportedNetworkExplorer.chainId == singularityAssetInfo.networkId);
    if (isNil(selectedNetworkExplorer)) {
      throw Error(`Singularity Network ${singularityAssetInfo.networkId} is not supported`);
    }
    let walletAddress = await this.walletService.getWalletAddressByBaseChainSymbol(selectedNetworkExplorer.chain.toUpperCase());
      
    return walletAddress;
  }



  private async getSingularityAsset(tokenSymbol: string): Promise<SingularityAssetInfo>{
    try {
      let singularityAssets = await this.store.select(getSingularityAssets).pipe(take(1)).toPromise();
      if (isNil(singularityAssets) || singularityAssets?.length == 0) {
        const headers = new HttpHeaders({
          'x-api-key': environment.SINGULARITY_API_KEY,
          'Content-Type': 'text/plain',
          'Cookie': 'JSESSIONID.16af3aad=node0g166hxm1kqo1fwii4uwkbrjc407.node0'
        });
        const singularityAssetURL = `https://cerebro.s9y.gg/v1/sdk-protected/getAllAssets`;
  
        let res = await this.http.get(singularityAssetURL, {headers}).toPromise();
        Logger.info(`response Singularity Asset List ${inspect(res)}`);
        if (isArray(res)) {
          singularityAssets = [];
          res.forEach((asset, index) => {
            if (this.allowedAssets.includes(asset?.assetName)) {
              let singularityAsset: SingularityAssetInfo = {
                ...asset,
              }
              singularityAssets.push(singularityAsset);
            }
          })

          this.store.dispatch(new SingularitySyncAssetInfoAction({singularityAssets}));
        } else {
          throw Error('Singularity Asset is empty');
        }
      }
      return singularityAssets.find((e) => e.assetName == tokenSymbol);
    } catch (err) {
      Logger.error(`Fail to get Singularity Asset List ${inspect(err)}`);
      this.store.dispatch(new SingularityErrorAction(`Error- get Singularity Assets ${err?.message}`));
    }
  }

}
