import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../store/appState";
import { SingularityActionTypes, SingularityErrorAction, SingularityInitNewPurchaseRequest, SingularityStateInitRequestAction, SingularityStateInitRequestSuccessAction, SingularityUserCloseDrawer, SingularityUserLogOutAction, SingularityUserLogOutFromDAppSingularityAction } from "../actions/singularityPayment.action";
import { take, tap, withLatestFrom } from "rxjs/operators";
import { Logger } from "../services/logger.service";
import { inspect } from "util";
import { environment } from "src/environments/environment";
import { AlertController } from "@ionic/angular";
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Hex from 'crypto-js/enc-hex';
import { WalletConnectSessionDisconnectRequestAction, WalletConnectSessionDisconnectedRequestFromDAppAction, WalletConnectSessionProposalSingularityPairRequestAction } from "../actions/walletConnect.actions";
import { getSingularitySession } from "../store/walletConnect";
import { isNil } from "../ts-util";

@Injectable()
export class SingularityPaymentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private alertController: AlertController
  ) {}

  singularityInitialize = createEffect(() => this.actions$.pipe(
    ofType<SingularityStateInitRequestAction>(SingularityActionTypes.SINGULARITY_INIT_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        const singularity = (window as any).Singularity;
        Logger.info(`singularity object ${inspect(singularity)}`);
        if (singularity?.isMounted) {
          Logger.info('Singularity Mounted');
          
          const singularityApiKey = environment.SINGULARITY_API_KEY;
          singularity.init(singularityApiKey, async() => {
            Logger.info(`Singularity Initiated`);
            this.store.dispatch(new SingularityStateInitRequestSuccessAction());
            this.store.dispatch(new WalletConnectSessionProposalSingularityPairRequestAction());
          });
        }
      } catch (err) {
        Logger.error(`Error: singularityInitialize ${inspect(err)}`);
        this.store.dispatch(new SingularityErrorAction(`Error initialize Singularity connection ${err?.message}`));
      }
    })
  ), { dispatch: false });



  singularityInitPurchaseOrder = createEffect(() => this.actions$.pipe(
    ofType<SingularityInitNewPurchaseRequest>(SingularityActionTypes.SINGULARITY_INIT_PURCHASE_ORDER_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        const singularity = (window as any).Singularity;
        Logger.info(`singularity object ${inspect(singularity)}`);
        const pendingOrder = action.payload.pendingOrder;
        Logger.info(`Singularity order ${inspect(pendingOrder)}`);
        const secret = environment.SINGULARITY_SECRET_API_KEY;
        const requestString = JSON.stringify(pendingOrder.body)
        const signature =  Hex.stringify(hmacSHA512(requestString, secret));
        await (window as any).SingularityEvent.transactionFlow(requestString, null);
        Logger.info(`Post transaction successful`);
      } catch (err) {
        Logger.error(`Error: singularityInitPurchaseOrder ${inspect(err)}`);
        this.store.dispatch(new SingularityErrorAction(`Error Init Singularity Purchase Order ${err?.message}`));
      }
    })
  ), { dispatch: false });


  singularityUserLogout$ = createEffect(() => this.actions$.pipe(
    ofType<SingularityUserLogOutAction>(SingularityActionTypes.SINGULARITY_USER_LOG_OUT),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        await (window as any).SingularityEvent.logout();
        Logger.info(`log out session`);
        const singularitySession = await this.store.select(getSingularitySession).pipe(take(1)).toPromise();
        if (! isNil(singularitySession?.handshakeTopic)) {
          this.store.dispatch(new WalletConnectSessionDisconnectRequestAction({sessionTopic: singularitySession?.handshakeTopic}))
        }
      } catch (err) {
        Logger.error(`Error: singularityUserLogout ${inspect(err)}`);
        this.store.dispatch(new SingularityErrorAction(`Error singularityUserLogout ${err?.message}`));
      }
    })
  ), { dispatch: false });


  singularityUserCloseDrawer$ = createEffect(() => this.actions$.pipe(
    ofType<SingularityUserCloseDrawer>(SingularityActionTypes.SINGULARITY_USER_CLOSE_DRAWER),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        Logger.info(`close the drawwer`);
        await (window as any).SingularityEvent.close();
      } catch (err) {
        Logger.error(`Error: singularityUserCloseDrawer ${inspect(err)}`);
        this.store.dispatch(new SingularityErrorAction(`Error singularityUserCloseDrawer ${err?.message}`));
      }
    })
  ), { dispatch: false });


  singularityUserLogoutFromSingularityDApp$ = createEffect(() => this.actions$.pipe(
    ofType<SingularityUserLogOutFromDAppSingularityAction>(SingularityActionTypes.SINGULARITY_USER_LOG_OUT_FROM_SINGULARITY_DAPP),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        (window as any).SingularityEvent.logout();
        this.store.dispatch(new WalletConnectSessionDisconnectedRequestFromDAppAction(action.payload))
      } catch (err) {
        Logger.error(`Error: singularityUserLogoutFromSingularityDApp ${inspect(err)}`);
        this.store.dispatch(new SingularityErrorAction(`Error singularityUserLogoutFromSingularityDApp ${err?.message}`));
      }
    })
  ), { dispatch: false });


  displaySingularityError$ = createEffect(() => this.actions$.pipe(
    ofType<SingularityErrorAction>(SingularityActionTypes.SINGULARITY_ERROR_ACTION),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      this.showErrorMessage(action.payload);
    })
  ), { dispatch: false });


  private async showErrorMessage(errorMessage: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      message: errorMessage,
      buttons: ['OK'],
    });

    await alert.present();
  }

  
}
