import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { fetchState, StorageSyncActions } from '../lib/ngrx-store-ionic-storage';
import { StorageService } from '../services/storage.service';
import { HydrateAction, HydratedAction } from '../actions/syncStorage.actions';
import { Store } from '@ngrx/store';

@Injectable()
export class StorageSyncEffects {

  hydrate$ = createEffect(() => this.actions$.pipe(
    ofType<HydrateAction>(StorageSyncActions.HYDRATE),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        const state = await fetchState(this.storageService)
        Logger.info('wallet', 'HydratedAction', state);
        return this.store.dispatch(new HydratedAction(state));
      } catch (err) {
        Logger.error('wallet', 'HydratedAction error', err);
      }
    })
  ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private storageService: StorageService,
    private store: Store
  ) {
  }
}
