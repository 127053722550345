import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MembersPortalService } from './members-portal.service';
import { Logger } from './logger.service';
import { NgxPlaidLinkService, PlaidConfig } from 'ngx-plaid-link';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { KycModalComponent } from '../../components/kyc-modal/kyc-modal.component';
import { KycstatusModalComponent } from '../../components/kycstatus-modal/kycstatus-modal.component';
import { BehaviorSubject } from 'rxjs';
import { AppModeService } from './app-mode.service';
import { EXTERNAL_APIS, PLAID_SERVICE_REDIRECT_ANDROID, PLAID_SERVICE_REDIRECT_BROWSER, PLAID_SERVICE_REDIRECT_IOS } from "../constants";
import { ModalController, Platform } from "@ionic/angular";
import { SetPlaidLinkToken } from "../actions/bankAccount.actions";
import { StorageService } from "./storage.service";
import { inspect } from 'util';
import { AuthGuardService } from './auth-guard.service';

enum BLOCKCHAIN_TRANSFER_TYPE {
  ETHEREUM = 'ethereum',
  BITCOIN = 'bitcoin'
}

export interface AssetDepositAddress {
  assetTransferMethodId: string;
  depositWalletAddress: string;
  transferDirection: string;
  label: string;
  createdAt: Date;
  costBasis: number;
  transferType: BLOCKCHAIN_TRANSFER_TYPE;
  acquistionOn: Date;
}

interface DepositWalletAddressRequestBody {
  asset: ASSET_TOKEN;
  lodeId: string;
  pricePerUnit?: string;
  acquisitionDate?: string;
}

interface DepositFundsParams {
  transferMethodId: string;
  amount: number | string;
}

interface ExchangeFiatToTokenParms {
  fiatAmount: number | string;
  tokenAmount: number | string;
  token: "agx" | "aux" | "lode";
}

interface GetTokenRateParams {
  token: ASSET_TOKEN;
  amount: number | string;
}

export interface ITransfer {
  createdAt: string;
  amount?: string;
  transferSubject: TRANSFER_TYPE_SUBJECT;
  status: TRANSER_STATUS;
  type: TRANSFER_TYPE;
  unitCount?: string;
}

export enum TRANSFER_TYPE_SUBJECT {
  FIAT = 'fiat',
  ASSET = 'asset',
  EXCHANGE = 'exchange'
}

export enum TRANSFER_TYPE {
  FIAT_WITHDRAW = 'FIAT_WITHDRAW',
  FIAT_DEPOSIT = 'FIAT_DEPOSIT',
  ASSET_WITHDRAW = 'ASSET_WITHDRAW',
  ASSET_DEPOSIT = 'ASSET_DEPOSIT',
  EXCHANGE_FIAT_TO_TOKEN = 'EXCHANGE_FIAT_TO_TOKEN',
  EXCHANGE_TOKEN_TO_TOKEN = 'EXCHANGE_TOKEN_TO_TOKEN',
  EXCHANGE_TOKEN_TO_FIAT = 'EXCHANGE_TOKEN_TO_FIAT'
}

export enum TRANSER_STATUS {
  COMPLETE = 'complete',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  REFUNDED = 'refunded'
}

export enum TransferType {
  FIAT = 'fiat',
  ASSET = 'asset',
  EXCHANGE = 'exchange'
}
export enum ASSET_TOKEN {
  LODE = 'LODE',
  AGX = 'AGX',
  AUX = 'AUX',
  USDC_AVAX_FUJI = 'USDC_AVAX_FUJI',
  USDC_ETHEREUM = 'USDC_ETHEREUM'
}

export function tokenToAssetToken(token: string): ASSET_TOKEN {
  token = token.toUpperCase();
  switch (token) {
    case 'LODE':
      return ASSET_TOKEN.LODE;
    case 'AGX':
      return ASSET_TOKEN.AGX;
    case 'AUX':
      return ASSET_TOKEN.AUX;
    case 'USDC_AVAX_FUJI':
      return ASSET_TOKEN.USDC_AVAX_FUJI;
    case 'USDC_ETHEREUM':
      return ASSET_TOKEN.USDC_ETHEREUM;
    default:
      throw new Error(`Invalid token: ${token}`);
  }
}
@Injectable()
export class BankAccountService {
  private config;
  private baseApi = EXTERNAL_APIS.MEMBERS_PORTAL_BANK_API;
  private getBankAccountApiPath = `${this.baseApi}/banks/me`;
  private unlinkBankAccountApiPath = `${this.baseApi}/banks/unlink`;
  private getBankAccountBalanceApiPath = `${this.baseApi}/funds/me`;
  private getACHStatusApiPath = `${this.baseApi}/custodial-accounts/me`;
  private getACHAgreementAPI = `${this.baseApi}/custodial-accounts/account-agreement-preview`;
  private createCustodialAccountApiPath = `${this.baseApi}/custodial-accounts`;
  private createLinkTokenApiPath = `${this.baseApi}/banks/create-link-token`;
  private linkBankAccountApiPath = `${this.baseApi}/banks/link`;
  private depositFundsApiPath = `${this.baseApi}/funds/deposit`;
  private withdrawFundsApiPath = `${this.baseApi}/funds/withdraw`;
  private getTokenRateApiPath = `${this.baseApi}/exchange/get-token-rate`;
  private getTokenToFiatRateApiPath = `${this.baseApi}/exchange/calculate-buyback-rate`;
  private exchangeFiatToTokenApiPath = `${this.baseApi}/exchange/fiat-to-token`;
  private exchangeTokenToFiatApiPath = `${this.baseApi}/exchange/token-to-fiat`;
  private approveKycAndAmlSanbox = `${this.baseApi}/custodial-accounts/sandbox-approve-kyc-and-aml`;
  private getAssetBalanceApiPath = `${this.baseApi}/assets/balance/me`;
  private getTransferHistoryByTransferTypeTransactionApiPath = (transferType: TransferType) => `${this.baseApi}/transfers/transferType/${transferType}/me`;
  private getAssetTransactionApiPath = (asset: ASSET_TOKEN) => `${this.baseApi}/transfers/assets/${asset}/me`;
  private transferEthApiPath = `${this.baseApi}/assets/withdraw`;
  private getUpdatePlaidTokenApiPath = `${this.baseApi}/banks/create-update-link-token`;
  private getKycSyncStatusApiPath = `${this.baseApi}/custodial-accounts/document-sync-status`;
  private getKycDocApproveStatusApiPath = `${this.baseApi}/custodial-accounts/track-kyc-status`;
  private uploadKycDocApiPath = `${this.baseApi}/custodial-accounts/upload-documents`;
  private depositWalletAddressPath = `${this.baseApi}/assets/get-deposit-wallet-address`;
  public transactionLoader = new BehaviorSubject<boolean>(false);
  public isDesktopMode: boolean;
  public bankingHistoryProgress = new BehaviorSubject(false);
  private transferType: TransferType;
  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private store: Store<AppState>,
    private modalController: ModalController,
    private membersPortal: MembersPortalService,
    private linkPlaidService: NgxPlaidLinkService,
    public appMode: AppModeService,
    private platform: Platform,
    private authGuardService: AuthGuardService,
  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  async getRedirectByDevice() {
    //determine if platform is browser, ios or android
    if (this.platform.is("desktop")) {
      return PLAID_SERVICE_REDIRECT_BROWSER;
    } else if (this.platform.is("ios")) {
      return PLAID_SERVICE_REDIRECT_IOS;
    } else if (this.platform.is("android")) {
      return PLAID_SERVICE_REDIRECT_ANDROID;
    } else {
      return PLAID_SERVICE_REDIRECT_BROWSER;
    }
  }

  // Link plaid
  async linkPlaidAcc(onSuccess?, onError?, receivedRedirectUri?, receivedRedirectUriForCreatingLinkToken?, linkAccToken?) {
    Logger.info("linkPlaidAcc: ", linkAccToken, receivedRedirectUri, receivedRedirectUriForCreatingLinkToken);
    if (!linkAccToken) {
      let storageLinkToken = await this.storage.get("plaidLinkToken");
      //check if we have a link token in storage from previous to the oauth redirect
      //if the received redirect uri was not for creating the link token, then the 
      //redirect is being used during the oauth redirect sequence
      if (storageLinkToken && !receivedRedirectUriForCreatingLinkToken) {
        linkAccToken = storageLinkToken;
        Logger.info("storage linkPlaidAcc: ", linkAccToken);
        await this.storage.remove("plaidLinkToken");
      } else {
        //if we don't have a link token in storage, we need to get one from plaid
        linkAccToken = await this.createLinkToken(receivedRedirectUri);
        Logger.info("Create new linkPlaidAcc: ", linkAccToken);
        //store plaid link token for redirect reference
        this.store.dispatch(new SetPlaidLinkToken(linkAccToken));
        await this.storage.set("plaidLinkToken", linkAccToken);
      }
    }

    const configObj: PlaidConfig = Object.assign({}, this.config, {
      token: linkAccToken,
      onSuccess: async (token, metadata) => {
        try {
          await this.linkBankAccount(token);
          if (onSuccess) {
            onSuccess(token, metadata);
          }
        } catch (err) {
          Logger.error("linkPlaidAcc: ", err);
          onError(err);
        }
      },
      onExit: () => Logger.info("Plaid widget cancelled"),
    });
    //add redirectUri to configObj
    if (receivedRedirectUri && !receivedRedirectUriForCreatingLinkToken) {
      configObj.receivedRedirectUri = receivedRedirectUri;
    }
    Logger.info("Link Config Bank Account Service", configObj);
    const handler = await this.linkPlaidService.createPlaid(configObj);

    handler.open();
  }

  // Unlink plaid
  async updateLoader(type: string, status: boolean) {
    try {
      switch (type) {
        case 'transaction':
          this.transactionLoader.next(status);
          break;

        default:
          break;
      }
    } catch (error) {

    }
  }
  async unlinkPlaidAcc(methodId, onSuccess?, onError?) {
    let linkAccToken;
    try {
      linkAccToken = await this.getUpdatePlaidToken(methodId);
    } catch (err) {
      onError(err);
    }

    const configObj: PlaidConfig = Object.assign({}, this.config, {
      token: linkAccToken,
      product: ["auth"],
      onSuccess: async (token, metadata) => {
        try {
          await this.unlinkBankAccount(token, methodId);
          if (onSuccess) {
            onSuccess(token, metadata);
          }
        } catch (err) {
          Logger.error("linkPlaidAcc: ", err);
          onError(err);
        }
      },
      onExit: () => Logger.info("Plaid widget cancelled"),
    });
    const handler = await this.linkPlaidService.createPlaid(configObj);

    handler.open();
  }

  // -----------------

  async getBankAccounts() {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      return [];
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http.get(this.getBankAccountApiPath, { headers }).toPromise();
  }

  async getACHStatus() {
    return false;/// disable ACH
    try {
      const jwt = await this.membersPortal.returnJwtIfValid();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
      });
      await this.http.get(this.getACHStatusApiPath, { headers }).toPromise();
      return true;
    } catch (err: any) {
      Logger.error("getACHStatus", JSON.stringify(err));
      if (err instanceof HttpErrorResponse && err.status === 422) {
        return true;
      }
      return false;
    }
  }
  async getAchAgreement() {
    try {
      const jwt = await this.membersPortal.returnJwtIfValid();
      let res;
      const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
      });
      return (await this.http.post(this.getACHAgreementAPI, {}, { headers }).toPromise());
    } catch (err) {
      // 403 error  we should popup KYC requirement
      Logger.error("getAchAgreement Error: ", inspect(err));
      throw err;
    }
  }
  async startKyc(configs) {
    const modal = await this.modalController.create({
      component: KycModalComponent,
      componentProps: {
        cssClass: "auto-height",
        urlAPI: configs.environment,
        token: configs.token,
        flowName: configs.flowName,
      },
    });

    await modal.present();
  }

  async showKYCStatus(configs) {
    const dateObject = new Date(Number(configs.last_updated) * 1000);
    const cssClass = (this.isDesktopMode) ? 'select-asset-modal-desktop' : 'select-asset-modal';

    const modal = await this.modalController.create({
      component: KycstatusModalComponent,
      componentProps: {
        amlStatus: configs.aml.status,
        cipStatus: configs.cip.status,
        idStatus: configs.identity.status,
        poaStatus: configs.proof_of_address_documents.status,
        last_updated: dateObject.toLocaleString()
      },
      cssClass
    });

    await modal.present();
  }

  async getBankAccountsBalance() {
    return 0; // eric remove bankAccount
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      return 0;
    }

    let res;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    res = await this.http.get(this.getBankAccountBalanceApiPath, { headers }).toPromise();

    return res.settled;
  }
  async getBankAccountsBalanceFor() {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      return 0;
    }

    let res;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`
    });

    res = await this.http.get(this.getBankAccountBalanceApiPath, { headers }).toPromise();

    return res;
  }

  async unlinkBankAccount(token: string, methodId: string) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("unlinkBankAccount: no valid JWT");
      return 0;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const options = {
      headers,
      body: { "funds-transfer-method-id": methodId, "plaid-public-token": token },
    };

    return this.http.delete(this.unlinkBankAccountApiPath, options).toPromise();
  }

  async createCustodialAccount() {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http.post(this.createCustodialAccountApiPath, {}, { headers }).toPromise();
  }

  async createLinkToken(redirectUri: string) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("createLinkToken: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http.post(this.createLinkTokenApiPath, { redirectUri }, { headers, responseType: "text" }).toPromise();
  }

  async getUpdatePlaidToken(methodId) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("createLinkToken: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(this.getUpdatePlaidTokenApiPath, { fundsTransferMethodId: methodId }, { headers, responseType: "text" })
      .toPromise();
  }

  async getPTKycSyncStatus() {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("Get PT KYC Status: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http.get(this.getKycSyncStatusApiPath, { headers, responseType: "text" }).toPromise();
  }

  async getPTKycApproveStatus() {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("Get PT KYC Status: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http.get(this.getKycDocApproveStatusApiPath, { headers, responseType: "text" }).toPromise();
  }

  async uploadPTKycDoc() {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("Get PT KYC Status: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http.put(this.uploadKycDocApiPath, {}, { headers, responseType: "text" }).toPromise();
  }

  async linkBankAccount(token) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("linkBankAccount: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(
        this.linkBankAccountApiPath,
        {
          "plaid-public-token": token,
        },
        { headers }
      )
      .toPromise();
  }

  async getTransactionList(pageNumber, pageSize, type?: string) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      return [];
    }
    this.bankingHistoryProgress.next(true);

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const url = `${this.baseApi}/transfers/transferType/${type}/me?page-number=${pageNumber}&page-size=${pageSize}`; // limit set to 50 for now
    let data: any;
    try {
      data = await this.http.get(url, { headers }).toPromise();
    } catch (error) {
      Logger.info('Failed to get orders', error);
      data = [];
    }
    this.bankingHistoryProgress.next(false);
    return data;
  }

  async depositFunds(params: DepositFundsParams) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("depositFunds: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(
        this.depositFundsApiPath,
        {
          "funds-transfer-method-id": params.transferMethodId,
          amount: params.amount.toString(),
        },
        { headers }
      )
      .toPromise();
  }

  async withdrawFunds(params: DepositFundsParams) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("withdrawFunds: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(
        this.withdrawFundsApiPath,
        {
          "funds-transfer-method-id": params.transferMethodId,
          amount: params.amount.toString(),
        },
        { headers }
      )
      .toPromise();
  }

  async getTokenRate(params: GetTokenRateParams) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("getTokenRate: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(
        this.getTokenRateApiPath,
        {
          token: tokenToAssetToken(params.token.toUpperCase()),
          amount: params.amount.toString(),
        },
        { headers, responseType: "text" }
      )
      .toPromise();
  }

  async getTokentoFiatRate(params: GetTokenRateParams) {
    return false;
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("getTokenToFiatRate: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(
        this.getTokenToFiatRateApiPath,
        {
          token: tokenToAssetToken(params.token.toUpperCase()),
          amount: params.amount.toString(),
        },
        { headers, responseType: "text" }
      )
      .toPromise();
  }

  async exchangeFiatToToken(params: ExchangeFiatToTokenParms) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("exchangeFiatToToken: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(
        this.exchangeFiatToTokenApiPath,
        {
          fiatAmount: params.fiatAmount.toString(),
          tokenAmount: params.tokenAmount.toString(),
          token: params.token.toUpperCase(),
        },
        { headers }
      )
      .toPromise();
  }

  async exchangeTokenToFiat(params: ExchangeFiatToTokenParms) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("exchangeFiatToToken: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(
        this.exchangeTokenToFiatApiPath,
        {
          fiatAmount: params.fiatAmount.toString(),
          tokenAmount: params.tokenAmount.toString(),
          token: params.token.toUpperCase(),
        },
        { headers }
      )
      .toPromise();
  }

  async approveKycAndAml() {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("createCustodialAccount: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http.post(this.approveKycAndAmlSanbox, {}, { headers }).toPromise();
  }

  async getAssetBalances() {

    return [
      { balance: "0", pendingBalance: "0", token: "AGX" },
      { balance: "0", pendingBalance: "0", token: "AUX" },
      { balance: "0", pendingBalance: "0", token: "LODE" },
    ];

    /// removethe AssetBlaance from bank account
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      return [
        { balance: "0", pendingBalance: "0", token: "AGX" },
        { balance: "0", pendingBalance: "0", token: "AUX" },
        { balance: "0", pendingBalance: "0", token: "LODE" },
      ];
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return await this.http.get(this.getAssetBalanceApiPath, { headers }).toPromise();
  }

  async getDepositWalletAddress(asset: ASSET_TOKEN): Promise<AssetDepositAddress> {
    const lodeId = this.authGuardService.userDetails?.lodeid || (await this.membersPortal.getUserInfo())?.lodeid;
    const jwt = await this.membersPortal.returnJwtIfValid();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    return await this.http.post<AssetDepositAddress>(this.depositWalletAddressPath,
      {
        lodeId,
        asset
      },
      { headers }).toPromise();
  }

  async getAgxBalance() {
    const balances: any = await this.getAssetBalances();

    return balances.find((balance) => balance.token === "AGX");
  }

  async getAuxBalance() {
    const balances: any = await this.getAssetBalances();

    return balances.find((balance) => balance.token === "AUX");
  }

  async getLodeBalance() {
    const balances: any = await this.getAssetBalances();

    return balances.find((balance) => balance.token === "LODE");
  }

  async getPTTokenTransactions(pageNum, itemsPerPage, asset: ASSET_TOKEN, token) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      return [];
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    const txs = await this.http.get(this.getAssetTransactionApiPath(asset), { headers }).toPromise();
    return { transactions: txs, keyringId: token };
  }

  async getTransferTypeTransactionHistory(pageNum, itemsPerPage, transferType: TransferType, token: string): Promise<{ transactions: ITransfer[], keyringId: string }> {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      throw new Error("getTransferTypeTransactionHistory: no valid JWT");
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    //query params page-number and page-size
    const txs: any = await this.http
      .get(this.getTransferHistoryByTransferTypeTransactionApiPath(transferType), {
        headers,
        params: {
          "page-number": pageNum,
          "page-size": itemsPerPage,
        }
      })
      .toPromise();
    return { transactions: txs?.data, keyringId: token };
  }

  async withdrawAssetToAddress(token: string, amount: string | number, address: string) {
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      Logger.error("withdrawAssetToAddress: no valid JWT");
      return false;
    }

    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return this.http
      .post(
        this.transferEthApiPath,
        {
          asset: token.toUpperCase(),
          amount,
          address,
        },
        { headers }
      )
      .toPromise();
  }

  public setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }
}
