import { Action } from '@ngrx/store';
import { VirtualDebitCardState } from '../store/virtualDebitCard';
import { SetVirtualCardDataAction, SetVirtualDebitCardDataAuthAction, GetVirtualDebitCardsSuccessAction, VirtualDebitCardActionTypes } from '../actions/virtualDebitCard.actions';
import { operationTypes, VirtualDebitCardService } from '../services/virtual-debit-card.service';
import { UserPreferencesActionTypes } from '../actions/userPreferences.actions';

export const initialVirtualDebitCardState: VirtualDebitCardState = {
  cards: {
    data: [],
    error: false
  },
  newCard: {
    address: '',
    amount: 0,
    operationType: 'create',
    epin: 0
  },
  auth: false
};

export function virtualDebitCardReducer(
  state: VirtualDebitCardState = initialVirtualDebitCardState,
  action: Action): VirtualDebitCardState {

  let payload;
  switch (action.type) {
    case VirtualDebitCardActionTypes.SET_VIRTUAL_DEBIT_CARD_DATA:
      payload = (action as SetVirtualCardDataAction).payload;
      return {
        ...state,
        newCard: {
          address: payload.address,
          amount: payload.amount,
          operationType: payload.operationType,
          epin: payload.epin
        }
      };

    case VirtualDebitCardActionTypes.GET_VIRTUAL_DEBIT_CARDS_SUCCESS:
      const cards = (action as GetVirtualDebitCardsSuccessAction).payload.cards;
      return {
        ...state,
        cards: {
          data: cards,
          error: false
        }
      };

    case VirtualDebitCardActionTypes.GET_VIRTUAL_DEBIT_CARDS_FAILURE:
      return {
        ...state,
        cards: {
          data: [],
          error: true
        }
      };

    case VirtualDebitCardActionTypes.SET_VIRTUAL_DEBIT_CARD_AUTH:
      const isAuthenticated = (action as SetVirtualDebitCardDataAuthAction).payload.authenticated;
      return { ...state, auth: isAuthenticated };

    case UserPreferencesActionTypes.REMOVE_LODE_ACC:
      return { ...initialVirtualDebitCardState };

    case UserPreferencesActionTypes.RESET_USER_PREFERENCES:
      return { ...initialVirtualDebitCardState };

    default:
      return state;
  }
}
