import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthSendTokensSuccessAction, SendTokenActionTypes, SendTokensSuccessAction } from '../actions/sendToken.actions';
import { Store } from '@ngrx/store';
import { AppState, getSendTokenState, getWalletState } from '../store/appState';
import { TransactionSenderService } from '../services/transaction-sender.service';
import { take, tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { AlertController, NavController, ModalController } from '@ionic/angular';
import { parseSyscoinError, parseEthereumError } from '../utils';
import { TranslateService } from '@ngx-translate/core';
import { BigNumber, ethers } from 'ethers';
import { ERC20_ABI, GAS_LIMIT_SEND, GAS_LIMIT_ERC20_SEND } from '../constants';
import { NetworkService } from '../services/network.service';
import { BankAccountService } from '../services/bank-account.service';
import { AppModeService } from '../services/app-mode.service';
import { inspect } from 'util';
import { SendTokenState } from '../store/sendToken';
import { Explorer } from 'src/environments/environment.interface';

@Injectable()
export class SendTokenEffects {

  authSendTokens$ = createEffect(() => this.actions$.pipe(
    ofType<AuthSendTokensSuccessAction>(SendTokenActionTypes.AUTH_SEND_TOKENS_SUCCESS),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      Logger.info('authSendTokens');
      let baseChainSymbol;
      let guid;
      try {
        baseChainSymbol = store.sendToken.tokenInfo.baseChainSymbol;
        guid = store.sendToken.tokenInfo.guid;
      } catch (err) {
        baseChainSymbol = 'PT';
        guid = store.bankAccount.send.data.token;
      }
      if (store?.sendToken?.tokenInfo?.custodyService == 'PT') {
        await this.primeTrustSendTransaction(action, store);
      } else {
        switch (baseChainSymbol) {
          case 'SYS':
            await this.sysSendTransaction(action, store);
            break;
          case 'BTC':
            await this.btcSendTransaction(action, store);
            break;
          case 'ETH':
            let ethNetwork = await this.network.getActiveEthNetwork()
            await this.evmSendTransaction(action, store, ethNetwork);
            break;
          case 'AVAX':
            let avaxNetwork = await this.network.getActiveAvaxNetwork();
            await this.evmSendTransaction(action, store, avaxNetwork);
            break;
          case 'SYSNEVM':
            let sysnevmNetwork = await this.network.getActiveSysnevmNetwork();
            await this.evmSendTransaction(action, store, sysnevmNetwork);
            break;
          case 'PT':
            await this.primeTrustSendTransaction(action, store);
            break;
          case 'SOL':
            await this.solSendTransaction(action, store);
            break;
          default:
            break;
        }
      }
      this.appMode.isDesktopMode.subscribe(async (mode) => {
        if (!mode) {
          if (guid) {
            // await this.nav.navigateForward(
            // ['/wallet/assets/', store.sendToken.tokenInfo.baseChainSymbol, store.sendToken.tokenInfo.guid],
            //   { queryParams: { action: 'closeSendForm' } }
            // );
          } else {
            // await this.nav.navigateForward(['/wallet/assets/', store.sendToken.tokenInfo.baseChainSymbol],
            //   { queryParams: { action: 'closeSendForm' } }
            // );
          }
        }
      });
    })
  ), { dispatch: false });

  async btcSendTransaction(action, store) {
    try {
      // let unsignedTxObj;
      let txid;
      store = (store as AppState);
      txid = await this.transactionSender.sendBitcoin(
        store.sendToken.amount,
        store.sendToken.fromAddress,
        store.sendToken.toAddress,
        store.sendToken.networkFee,
        action.payload.pin
      );
      Logger.info('txid:', txid);
      this.store.dispatch(new SendTokensSuccessAction({ txid }));
    } catch (e) {
      Logger.error('raw err', e);
      
      try {
        await this.modalController.dismiss({}, '', 'pinModal');
      } catch (error) {
        Logger.error('modalController', inspect(error));
      }
      if (e.message.indexOf('txVersion') !== -1) {
        const alertCtrl = await this.alertController.create({
          header: 'Error',
          // tslint:disable-next-line
          message: await this.translate.get('tokens.send.errors.txversion', { coin: store.sendToken.tokenInfo.symbol }).toPromise(),
          buttons: ['OK']
        });
        return await alertCtrl.present();
      }
      if (e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string') {
        const errMessage = JSON.parse(e.response.data.message)[0].error_msg;

        const alert = await this.alertController.create({
          header: 'Error',
          message: errMessage,
          buttons: ['OK']
        });
        return await alert.present();
      }
    
    }
  }

  async sysSendTransaction(action, store) {
    try {
      // let unsignedTxObj;
      let txid;
      store = (store as AppState);
      if (!store.sendToken.tokenInfo.guid) {
        // SEND SYSCOIN
        // tslint:disable-next-line
        //unsignedTxObj = await this.transactionSender.getUnsignedTransactionHex(store.sendToken.fromAddress, store.sendToken.toAddress, store.sendToken.amount);
        txid = await this.transactionSender.sendSyscoin(
          store.sendToken.amount,
          store.sendToken.fromAddress,
          store.sendToken.toAddress,
          store.sendToken.memo,
          action.payload.pin,
          store.sendToken.tokenInfo.baseChainSymbol
        );
      } else {
        // SEND ASSET
        // tslint:disable-next-line
        txid = await this.transactionSender.sendAsset(
          store.sendToken.amount,
          store.sendToken.fromAddress,
          store.sendToken.toAddress,
          action.payload.pin,
          store.sendToken.tokenInfo.guid,
          store.sendToken.memo
        );
      }
      // Logger.info('hexstr:', unsignedTxObj);
      // tslint:disable-next-line
      Logger.info('txid:', txid);
      this.store.dispatch(new SendTokensSuccessAction({ txid }));
    } catch (e) {
      Logger.error('sysSendTransaction', inspect(e));
      try {
        await this.modalController.dismiss({}, '', 'pinModal');
      } catch (error) {
        Logger.error('sysSendTransaction - failed to dismiss', inspect(error));
      }

      const errObj = parseSyscoinError(e, store.wallet.keychain[`SYS`].balance);
      Logger.info('err', errObj);
      const errMsg = await this.translate.get(errObj.key, errObj.params).toPromise() ?? errObj.key;
      const alertErrorCtrl = await this.alertController.create({
        header: 'Error',
        // tslint:disable-next-line
        message: errMsg,
        buttons: ['OK']
      });
      await alertErrorCtrl.present();
    }
  }

  async solSendTransaction(action, store) {
    try {
      let txid;
      if (!store.sendToken.tokenInfo.guid) { // SEND SOLANA
        txid = await this.transactionSender.sendSolTransaction(
          store.sendToken.amount,
          store.sendToken.fromAddress,
          store.sendToken.toAddress,
          store.sendToken.memo,
          action.payload.pin,
          store.sendToken.tokenInfo.baseChainSymbol
        );
      } else { // SEND SPL
        txid = await this.transactionSender.sendSolSplTransaction(
          store.sendToken.amount,
          store.sendToken.fromAddress,
          store.sendToken.toAddress,
          action.payload.pin,
          store.sendToken.tokenInfo.guid,
          store.sendToken.memo
        );
      }
      Logger.info('txid:', txid);
      this.store.dispatch(new SendTokensSuccessAction({ txid }));
    } catch (e) {
      Logger.info('raw err', e);

      try {
        await this.modalController.dismiss({}, '', 'pinModal');
      } catch (error) {}

      if (e.error && !e.error.error) {
        e.error = JSON.parse(e.error);
      }
      // TODO check parserror SOL
      const errObj = parseEthereumError(e.error.error, store.wallet.keychain[`SOL`].balance);
      Logger.info('err', errObj);
      const alert = await this.alertController.create({
        header: 'Error',
        // tslint:disable-next-line
        message: errObj ? await this.translate.get(errObj.key, errObj.params).toPromise() : 'An error occured when trying to send tokens.',
        buttons: ['OK']
      });
      await alert.present();
    }
  }

 async evmSendTransaction(action, store, network) {
  try {
    const sendTokenData = await this.store.select(getSendTokenState).pipe(take(1)).toPromise();
    Logger.info('sendTokenData :', inspect(sendTokenData));
    const nonce = await this.transactionSender.getNonceByEVMChain(sendTokenData.fromAddress, network);
    
    const gasPrice = ethers.BigNumber.from(sendTokenData.ethGasFee);

    let tx;
    if (sendTokenData?.tokenInfo?.guid) {
      tx = this.buildSendErc20TokenTransaction(sendTokenData, network, gasPrice, nonce);
    } else {
      tx = this.buildSendNativeTokenTransaction(sendTokenData, network, gasPrice, nonce);
    }
    const signed = await this.transactionSender.signEthRawTransaction(sendTokenData.fromAddress, tx, action.payload.pin);
    const txid = await this.transactionSender.sendEvmRawTransaction(signed, network);
    Logger.info('signed tx sent; txid:', txid);
    this.store.dispatch(new SendTokensSuccessAction({ txid }));
  } catch (err) {
    Logger.error('evmSendTransaction', inspect(err));
    try {
      await this.modalController.dismiss({}, '', 'pinModal');
    } catch (error) {
      Logger.error('evmSendTransaction - failed to dismiss', inspect(error));
    }
    const wallet = await this.store.select(getWalletState).pipe(take(1)).toPromise();
    const errObj = parseEthereumError(err, wallet.keychain[network.chain].balance);
    const errMsg = await this.translate.get(errObj.key, errObj.params).toPromise() ?? errObj.key;
    const alert = await this.alertController.create({
      header: 'Error',
      // tslint:disable-next-line
      message: errMsg,
      buttons: ['OK']
    });
    await alert.present();
  }
 }

 private buildSendNativeTokenTransaction(sendTokenData: SendTokenState, network: Explorer, gasPrice: BigNumber, nonce: number) {
  return {
    chainId: network.chainId,
    from: sendTokenData.fromAddress,
    to: sendTokenData.toAddress,
    gasLimit: GAS_LIMIT_SEND,
    gasPrice: ethers.utils.hexlify(gasPrice),
    nonce: ethers.utils.hexlify(nonce),
    value: ethers.utils.parseEther(sendTokenData.amount.toString())
  };
 }

 private buildSendErc20TokenTransaction(sendTokenData: SendTokenState, network: Explorer, gasPrice: BigNumber, nonce: number) {
    const args = Object.values({
      _to: sendTokenData.toAddress,
      _value: ethers.utils.parseUnits(sendTokenData.amount.toString(), sendTokenData.tokenInfo.precision)
    });
    const iface = new ethers.utils.Interface(ERC20_ABI);
    const calldata = iface.encodeFunctionData('transfer', args);
    Logger.info('sendToken Effect: calldata', calldata);
    return {
      chainId: network.chainId,
      gasLimit: GAS_LIMIT_ERC20_SEND,      // TODO: investigate if there's a way to know this limit
      gasPrice: ethers.utils.hexlify(gasPrice),
      nonce: ethers.utils.hexlify(nonce),
      to: sendTokenData.tokenInfo.guid,
      data: calldata
    };
 }

async primeTrustSendTransaction(action, store) {
  const data = store.bankAccount.send.data;
  let success;
  try {
    success = await this.bankAccount.withdrawAssetToAddress(data.token, data.amount, data.address);
  } catch (err) {
    return Logger.error('Can not send PT transaction. Error: ', err);
  }
  this.store.dispatch(new SendTokensSuccessAction({ txid: 'PT tx' }));
}

constructor(
  private actions$: Actions,
  private store: Store<AppState>,
  private transactionSender: TransactionSenderService,
  private nav: NavController,
  private alertController: AlertController,
  private modalController: ModalController,
  private translate: TranslateService,
  private network: NetworkService,
  private appMode: AppModeService,
  private bankAccount: BankAccountService
) {}
}
