import Moralis from 'moralis';
import { environment } from "src/environments/environment";
import { Logger } from "./logger.service";
import { inspect } from "util";
import { EvmChain, EvmTransactionData } from '@moralisweb3/evm-utils';
import { Injectable } from "@angular/core";
import { BehaviorSubject, interval, Subscription, throwError } from "rxjs";
import { NetworkService } from './network.service';
import { ERC20_TOKEN_RANDOM_LOGOS, SMART_CONTRACT_CHAINS } from '../constants';
import { SmallToCryptoPipe } from '../pipes/smalltocrypto/smalltocrypto.pipe';
import { ERC20tokenData } from 'src/app/global';
import { TokenManagerService } from './token-manager.service';
import { AddERC20TokensAction } from '../actions/wallet.actions';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { isNil } from '../ts-util';
import { startWith, take } from 'rxjs/operators';
import { CoinGeckoService } from './coingecko.service';
import { getBaseCurrency } from '../store/userPreferences';
import { supportedAssetList } from '../lib/supported-assets/supported-assets';
import { getERC20TokenList, getKeychain } from '../store/wallet';
import { getTokenInfo } from '../utils';
import { BigNumber, ethers } from 'ethers';
import { ApiPaginatedResultAdapter, PaginatedResult } from '@moralisweb3/api-utils';
@Injectable({
  providedIn: 'root'
})
export class MoralisService {
  public addERC20tokenData: ERC20tokenData;
  public tokensForImportERC20token: Array<any>;
  public moralisSchedulerProgress: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private hiddenAssetInterval: Subscription = null;

  constructor(
    private networkManager: NetworkService,
    private smallToCrypto: SmallToCryptoPipe,
    private tokenService: TokenManagerService,
    private store: Store<AppState>,
    private coinGeckoService: CoinGeckoService
  ) { }

  public async initateConnection(): Promise<boolean> {
    try {
      const moralisApiKey = environment.MORALIS_API_KEY;
      await Moralis.start({
        apiKey: moralisApiKey
      })
      return true;
    } catch (err) {
      Logger.error('initateConnection', inspect(err));
      return false;
    }
  }

  public async getEVMWalletTokenBalance(address: string, chain: EvmChain) {
    try {
      if (this.initateConnection()) {
        return await Moralis.EvmApi.token.getWalletTokenBalances({
          address,
          chain,
        });
      } else {
        throwError('Moralis service is failed to start');
      }
    } catch (err) {
      Logger.error('getEVMWalletTokenBalance', inspect(err));
    }
  }

  public async getAllERC20TokensOwnedByAnAddress(walletAddress: string, chain: EvmChain, tokenAddresses?: Array<string>) {
    try {
      if (this.initateConnection()) {
        return await Moralis.EvmApi.token.getWalletTokenBalances({
          address: walletAddress,
          chain,
          tokenAddresses
        });
      } else {
        throwError('Moralis service is failed to start');
      }
    } catch (err) {
      Logger.error('getAllERC20TokensOwnedByAnAddress', inspect(err));
    }
  }

  public async getEVMWalletTokenMetadata(addresses: Array<string>, chain: EvmChain) {
    try {
      if (this.initateConnection()) {
        return await Moralis.EvmApi.token.getTokenMetadata({
          addresses,
          chain,
        });
      } else {
        throwError('Moralis service is failed to start');
      }
    } catch (err) {
      Logger.error('getEVMWalletTokenMetadata', inspect(err));
    }
  }

  public async getNativeBalanceToken(address: string, chain: EvmChain) {
    try {
      if (this.initateConnection()) {
        return await Moralis.EvmApi.balance.getNativeBalance({
          address,
          chain,
        });
      } else {
        throwError('Moralis service is failed to start');
      }
    } catch (err) {
      Logger.error('getEVMWalletTokenBalance', inspect(err));
    }
  }

  public async getEthMoralisChain() {
    const ethNetwork = await this.networkManager.getActiveEthNetwork();
    if (ethNetwork.network === 'mainnet') {
      return EvmChain.ETHEREUM;
    } else {
      return EvmChain.SEPOLIA;
    }
  }

  public async getAvaxMoralisChain() {
    const avaxNetwork = await this.networkManager.getActiveAvaxNetwork();
    if (avaxNetwork.network === 'mainnet') {
      return EvmChain.AVALANCHE;
    } else {
      return EvmChain.FUJI;
    }
  }

  async findChainIdAgainstNetwork(selectedNetwork: string) {
    switch (selectedNetwork) {
      case 'AVAX':
        return await this.getAvaxMoralisChain();
      case 'ETH':
        return await this.getEthMoralisChain();

      default:
        break;
    }
  }

  setERC20tokenData(data, tokens?) {
    this.addERC20tokenData = data;
    this.tokensForImportERC20token = tokens;
  }

  async getRandomLogoForHiddenAsset() {
    // take all the logos of each tokens into an array
    const supportedAssetList = await this.tokenService.getSupportedTokensList();
    const tokens = Object.values(supportedAssetList);
    const logosOfAlreadyExistingAssets = tokens.map((token: any) => token.logo.toString());
    const uniqueLogoArray = ERC20_TOKEN_RANDOM_LOGOS.filter(x => !logosOfAlreadyExistingAssets.includes(x));
    if (uniqueLogoArray.length > 0) {
      return uniqueLogoArray[Math.floor(Math.random() * uniqueLogoArray.length)];
    } else {
      return ERC20_TOKEN_RANDOM_LOGOS[Math.floor(Math.random() * ERC20_TOKEN_RANDOM_LOGOS.length)];
    }
  }

  // get all token balances for Ethereum and Avalanche networks : scheduled for 5 mins
  public async getHiddenAssetsFromMoralisScheduler() {
    if (!isNil(this.hiddenAssetInterval)) { return; }

    // scheduler to find and set liquity pairs in storage
    this.hiddenAssetInterval = interval(60000 * 5).pipe(startWith(0)).subscribe(async (_) => {
      this.getHiddenAssetsFromMoralis();
    });
  }

  // get all token balances for Ethereum and Avalanche networks
  public async getHiddenAssetsFromMoralis() {

    if (this.moralisSchedulerProgress.getValue()) { return; } // handle previous calls

    this.moralisSchedulerProgress.next(true);

    // get wallet address, taking ETH wallet address for now, since its same as AVAX
    const keyChain = await this.store.select(getKeychain).pipe(take(1)).toPromise();
    const ethWallet = getTokenInfo(keyChain, 'ETH');

    const avaxChain = await this.getAvaxMoralisChain();
    const ethChain = await this.getEthMoralisChain();
    try {
      const moralisTokensAvax: any = await this.getAllERC20TokensOwnedByAnAddress(ethWallet.address, avaxChain);
      const moralisTokensEth: any = await this.getAllERC20TokensOwnedByAnAddress(ethWallet.address, ethChain);

      let consolidatedTokens = [];
      if (moralisTokensAvax?.data?.length > 0) {
        const filtered = moralisTokensAvax.data.map(asset => {
          asset['baseChainSymbol'] = 'AVAX';
          asset['chainId'] = avaxChain.apiId;
          return asset;
        });
        consolidatedTokens = [...consolidatedTokens, ...filtered];
      }
      if (moralisTokensEth?.data?.length > 0) {
        const filtered = moralisTokensEth.data.map(asset => {
          asset['baseChainSymbol'] = 'ETH';
          asset['chainId'] = ethChain.apiId;
          return asset;
        });
        consolidatedTokens = [...consolidatedTokens, ...filtered];
      }
      const finalListOfERC20Tokens = await this.populateHiddenAssets(consolidatedTokens);
      Logger.info('finalListOfERC20Tokens', finalListOfERC20Tokens);

      this.store.dispatch(new AddERC20TokensAction({ erc20TokenList: finalListOfERC20Tokens }));
      this.moralisSchedulerProgress.next(false);
    } catch (error) {
      Logger.error('getHiddenAssetsFromMoralisError', inspect(error));
    }
  }

  private getConsolidatedArrayOfHiddenAssets(dataFromMoralisService: Array<any>, chain: string) {
    
  }

  private async populateHiddenAssets(moralisAssets: Array<any>) {
    const supportedAssetList = await this.tokenService.getSupportedTokensList();
    const supportedAssetListArray = Object.values(supportedAssetList).filter((asset: any) => asset.assetGuid);

    const filteredAsset = moralisAssets.filter(asset => {
      const checkIfExist = supportedAssetListArray.find((supportedAsset: any) => (
        asset.token_address.toLowerCase() === supportedAsset.assetGuid.toLowerCase() &&
        asset.baseChainSymbol === supportedAsset.baseChainSymbol
      ));
      if (!checkIfExist) {
        return asset;
      }
    });
    Logger.info('populateHiddenAssets', filteredAsset);

    const baseCurrency = await this.store.select(getBaseCurrency).pipe(take(1)).toPromise();
    const hiddenERC20tokens = Promise.all(
      filteredAsset.map(async token => {
        const randomLogo = await this.getRandomLogoForHiddenAsset();
        const coingeckoId = await this.coinGeckoService.getCoinId(token.symbol.toLowerCase());
        const coinDetails = coingeckoId && await this.coinGeckoService.getCoinDetails(coingeckoId, baseCurrency.toLowerCase());
        const tokenCopy: any = { ...token };
        const tokenAddress = await ethers.utils.getAddress(token.token_address);
        tokenCopy['chainId'] = +token.chainId;
        tokenCopy['assetGuid'] = tokenAddress;
        tokenCopy['token_address'] = tokenAddress;
        tokenCopy['balance'] = this.smallToCrypto.transform(token.balance, { type: token.baseChainSymbol, precision: token.decimals });
        tokenCopy['fiatValue'] = (coinDetails && coinDetails.currentPrice) ? coinDetails.currentPrice : undefined;
        tokenCopy['coingeckoId'] = coingeckoId ? coingeckoId : undefined;
        tokenCopy['symbol'] = token.symbol;
        tokenCopy['baseChainSymbol'] = token.baseChainSymbol;
        tokenCopy['name'] = token.name;
        tokenCopy['precision'] = token.decimals;
        tokenCopy['logo'] = token.logo ? token.logo : randomLogo;
        return tokenCopy;
      })
    );
    Logger.info('populateHiddenAssetsFinal', hiddenERC20tokens);
    return hiddenERC20tokens;
  }

  public async removeAddedERC20TokenFromState(assetGuid: string) {

    const erc20Tokens = await this.store.select(getERC20TokenList).pipe(take(1)).toPromise();
    const updatedERC20Tokens = erc20Tokens.filter(token => token.assetGuid.toLowerCase() !== assetGuid.toLowerCase());
    Logger.info('removederc20Tokens', updatedERC20Tokens);
    this.store.dispatch(new AddERC20TokensAction({ erc20TokenList: updatedERC20Tokens }));
  }

  public async getTransactionHistory(address: string, chain: EvmChain, limit: number): Promise<PaginatedEvmTransactionData> {
    let response: any;
    if (this.initateConnection()) {
      response = await Moralis.EvmApi.transaction.getWalletTransactions({
        address,
        chain,
        limit,
        cursor: null
      });
    } else {
      throwError('Moralis service failed to start');
    }

    const { total, page, page_size, cursor, result } = response?.data;
    return {
      result: result.map((transaction: any) => ({
        blockHash: transaction.block_hash,
        blockNumber: transaction.block_number,
        from: transaction.from_address,
        to: transaction.to_address,
        value: transaction.value,
        gas: transaction.gas,
        gasPrice: transaction.gas_price,
        hash: transaction.hash,
        chain: chain,
        index: transaction.transaction_index,
        blockTimestamp: transaction.block_timestamp,
        nonce: transaction.nonce,
        cumulativeGasUsed: transaction.receipt_cumulative_gas_used,
        gasUsed: transaction.receipt_gas_used,
        status: parseFloat(transaction.receipt_status),
        transactionFee: BigNumber.from(transaction.receipt_gas_used).mul(BigNumber.from(transaction.gas_price)).toString(),
        logs: null
      })),
      total,
      page,
      page_size,
      cursor
    };
  }
}

export interface PaginatedEvmTransactionData extends PaginatedResult<EvmTransactionData[]> {}
