import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../store/appState";
import { catchError, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import {
  AddPurchaseHistoryAction,
  AddTradeOrdersAction,
  AddTradeOrdersHistoryAction,
  AuthenticateAction,
  GetFeePerAssetTxFailedAction,
  GetFeePerAssetTxRequestAction,
  GetFeePerAssetTxSuccessAction,
  GetTokenBalancesFailedAction,
  GetTokenBalancesRequestAction,
  GetTokenBalancesSuccessAction,
  GetTokenFiatValuesFailedAction,
  GetTokenFiatValuesRequestAction,
  GetTokenFiatValuesSuccessAction,
  GetTokenHistoryFailedAction,
  GetTokenHistoryRequestAction,
  GetTokenHistorySuccessAction,
  RemoveUnconfirmedTransactionsAction,
  ResetWalletAction,
  ResetWalletDataAction,
  ResetWalletWithAuthAction,
  WalletActionTypes,
} from '../actions/wallet.actions';
import { AGX_KEYRING_ID, AUX_KEYRING_ID, EXCHANGE_RATES, GRAPH_DATA_HOME, GRAPH_DATA_OF_ALL_TOKENS, GRAPH_DATA_TRADE, LIQUIDITY_PAIRS, LNG_KEY, LODE_DEBIT_CARD_EMAIL_SUBSCRIPTION, PRICE_LIST_OF_TOKENS, SKIP_INTRO_PAGE_KEY, SKIP_OPEN_VAULT_PAGE_KEY, SKIP_PRIMARY_ADDRESS_MODAL, SKIP_VAULT_MODEL_INFO, SUPPORTED_ASSETS, SYS_PER_ASSET_TX, TOKEN_ALLOWANCE, USER_PREFERENCES_STORAGE_KEY, WALLET_KEY, WALLET_STORAGE_KEY } from '../constants';
import { from, of } from 'rxjs';
import { BlockBookService } from '../services/blockbook.service';
import { getVirtualCardsAndBalances, hashCode, lookupTokenKeyring, getBankAccountsAndBalances, getPTBalances, getTokenInfoArray, formatTokenAmount, isAGXKeyringId, isAUXKeyringId } from '../utils';
import { GasStationService } from '../services/gas-station.service';
import { BankAccountService, tokenToAssetToken } from '../services/bank-account.service';
import { WalletState } from '../store/wallet';
import { environment } from 'src/environments/environment';
import { MembersPortalService } from '../services/members-portal.service';
import { PriceOracleService } from '../services/price-oracle.service';
import BigNumber from 'bignumber.js';
import { BlockbookGetAddressTxsResponseWithWalletData } from '../services/blockbook';
import promiseAllProperties from 'promise-all-properties';
import { GetVirtualDebitCardsSuccessAction } from '../actions/virtualDebitCard.actions';
import { VirtualDebitCardService } from '../services/virtual-debit-card.service';
import { TokenManagerService } from '../services/token-manager.service';
import { createKeyringId } from '../keyringUtils';
import { WalletService } from '../services/wallet.service';
import { AvalancheService } from '../services/avalanche.service';
import { SysnevmService } from '../services/syscoin-nevm.service';
import { SolanaService } from '../services/solana.service';
import { ChangeActivePage } from '../actions/appSettings.actions';
import { OneInchService } from '../services/one-inch.service';
import { GraphqlService } from '../services/graphql.service';
import { AuthService } from '../services/auth.service';
import { NavController } from "@ionic/angular";
import { StorageService } from "../services/storage.service";
import { WebSocketAddressNotifierService } from "../services/WebSocketAddressNotifier.service";
import { CoinGeckoService } from "../services/coingecko.service";
import { TokenFiatValueMap, TokenKeyring } from "src/app/global";
import { Logger } from "../services/logger.service";
import { ResetBankAccountDataAction } from "../actions/bankAccount.actions";
import { LoadUserPreferencesAction } from "../actions/userPreferences.actions";
import { ResetSendTokenDataAction } from "../actions/sendToken.actions";
import { ResetConnectionAction } from "../actions/connection.actions";
import { ResetGasStationDataAction } from "../actions/gasStation.actions";
import { ResetGraphDataAction } from "../actions/graphData.actions";
import { ResetPriceOracleDataAction } from "../actions/priceOracle.actions";
import { RoutingService } from "../services/routing.service";
import { TokenDefinitionService } from "../services/token-definition.service";
import { TradeService } from "../services/trade.service";
import { PriceOracleState } from "../store/priceOracle";
import { isNil } from "../ts-util";

@Injectable()
export class WalletEffects {
  private isJwtValid;
  private walletRedirectUri: string;
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private nav: NavController,
    private storage: StorageService,
    private wsAddressNotifier: WebSocketAddressNotifierService,
    private coinGeckoService: CoinGeckoService,
    private gasStationService: GasStationService,
    private blockbookService: BlockBookService,
    private members: MembersPortalService,
    private oraclePriceService: PriceOracleService,
    private virtualCardService: VirtualDebitCardService,
    private authService: AuthService,
    private tokenManager: TokenManagerService,
    private avalancheService: AvalancheService,
    private sysnevmService: SysnevmService,
    private solanaService: SolanaService,
    private bankAccountService: BankAccountService,
    private oneInchService: OneInchService,
    private graphQL: GraphqlService,
    private wallet: WalletService,
    private routingService: RoutingService,
    private tokenDef: TokenDefinitionService,
    private tokenService: TokenManagerService,
    private tradeService: TradeService
  ) { }

  createKeychainSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.CREATE_KEYCHAIN_SUCCESS),
      map((action: { payload: { keyrings: Array<TokenKeyring> } }) => {
        const chains = [];
        if (environment.features.sys) {
          chains.push({ symbol: "SYS", address: lookupTokenKeyring("SYS", action.payload.keyrings).address });
        }

        if (environment.features.eth) {
          chains.push({ symbol: "ETH", address: lookupTokenKeyring("ETH", action.payload.keyrings).address });
        }

        if (environment.features.hl) {
          chains.push({ symbol: "HL", address: "" });
        }

        if (environment.features.debitCard) {
          chains.push({ symbol: "debit-card", address: "" });
        }

        if (environment.features.btc) {
          chains.push({ symbol: "BTC", address: lookupTokenKeyring("BTC", action.payload.keyrings).address });
        }

        if (environment.features.bankAccounts) {
          chains.push({ symbol: "bank-account" });
          chains.push({ symbol: "PT" }); // Prime Trust tokens
        }

        if (environment.features.avax) {
          chains.push({ symbol: "AVAX", address: lookupTokenKeyring("AVAX", action.payload.keyrings).address });
        }

        if (environment.features.sol) {
          chains.push({ symbol: "SOL", address: lookupTokenKeyring("SOL", action.payload.keyrings).address });
        }

        if (environment.features.sysnevm) {
          chains.push({ symbol: "SYSNEVM", address: lookupTokenKeyring("SYSNEVM", action.payload.keyrings).address });
        }

        return new GetTokenBalancesRequestAction({
          chains,
        });
      })
    )
  );

  getAllTokenBalances$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WalletActionTypes.GET_ALL_TOKEN_BALANCES),
        withLatestFrom(this.store),
        map(async ([action, store]) => {
          const keychain = (store as AppState).wallet.keychain;
          Logger.info('GET_ALL_TOKEN_BALANCES', keychain);
          const sysAddress = keychain.SYS.address;
          const chains = [];

          await this.tokenDef.mergeTokenDefinitions(); // update token definitions with storage data

          this.isJwtValid = await this.members.isJwtValid();

          if (environment.features.sys) {
            chains.push({ symbol: "SYS", address: sysAddress });
          }
          if (environment.features.eth) {
            const ethAddress = keychain.ETH.address;
            chains.push({ symbol: "ETH", address: ethAddress });
          }

          if (environment.features.btc) {
            chains.push({ symbol: "BTC", address: keychain.BTC.address });
          }

          if (environment.features.hl) {
            chains.push({ symbol: "HL", address: "" });
          }

          if (environment.features.debitCard) {
            chains.push({ symbol: "debit-card" });
          }

          if (environment.features.bankAccounts) {
            chains.push({ symbol: "bank-account" });
            chains.push({ symbol: "PT" }); // Prime Trust tokens
          }

          if (environment.features.avax) {
            const avaxAddress = keychain.AVAX.address;
            chains.push({ symbol: "AVAX", address: avaxAddress });
          }

          if (environment.features.sysnevm) {
            const ethAddress = keychain.SYSNEVM.address;
            chains.push({ symbol: "SYSNEVM", address: ethAddress });
          }



          if (environment.features.sol) {
            const solAddress = keychain.SOL.address;
            chains.push({ symbol: "SOL", address: solAddress });
          }

          return this.store.dispatch(
            new GetTokenBalancesRequestAction({
              chains,
            })
          );
        })
      ),
    { dispatch: false }
  );

  tokenBalancesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_BALANCES_REQUEST),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        let actionRef: GetTokenBalancesRequestAction = action["payload"];
        const requests: {
          SYS?: any;
          ETH?: any;
          HL?: any;
          DEBIT_CARD?: any;
          BANK_ACCOUNT?: any;
          chains?: any;
          tokens?: any;
          BTC?: any;
          PT?: any;
          AVAX?: any;
          SOL?: any;
          SYSNEVM?:any;
        } = {};
        actionRef["chains"].forEach((chain) => {
          if (chain.symbol === "ETH") {
            requests.ETH = environment.features.eth ? this.blockbookService.getEthTokenBalances(chain.address) || [{}] : [{}];
          } else if (chain.symbol === "SYS") {
            requests.SYS = environment.features.sys ? this.blockbookService.getSysTokenBalances(chain.address) || [{}] : [{}];
          } else if (chain.symbol === "SYSNEVM") {
            requests.SYSNEVM = environment.features.sysnevm ? this.sysnevmService.getSysnevmTokenBalances(chain.address) || [{}] : [{}];
          } else if (chain.symbol === "BTC") {
            requests.BTC = environment.features.btc ? this.blockbookService.getBtcBalance(chain.address) || [{}] : [{}];
          } else if (chain.symbol === "HL") {
            requests.HL = environment.features.hl ? this.members.getHlTokensBalance() || [] : [];
          } else if (chain.symbol === "debit-card") {
            requests.DEBIT_CARD = environment.features.debitCard ? getVirtualCardsAndBalances(this.members, this.virtualCardService) || [] : [];
          } else if (chain.symbol === "bank-account") {
            requests.BANK_ACCOUNT = environment.features.bankAccounts ? getBankAccountsAndBalances(this.bankAccountService) || { list: [], balance: 0 } : { list: [], balance: 0 };
          } else if (chain.symbol === "PT") {
            requests.PT = environment.features.bankAccounts ? getPTBalances(this.bankAccountService) || {
              agxBalance: 0,
              auxBalance: 0,
              lodeBalance: 0
            } : {
              agxBalance: 0,
              auxBalance: 0,
              lodeBalance: 0
            };
          } else if (chain.symbol === "AVAX") {
            requests.AVAX = environment.features.avax ? this.avalancheService.getAvaxTokenBalances(chain.address) || [{}] : [{}];
          } else if (chain.symbol === "SOL") {
            requests.SOL = environment.features.sol ? this.solanaService.getSolTokenBalances(chain.address) || [{}] : [{}];
          }
        });

        requests.chains = actionRef[`chains`].map((chain) => chain.symbol);
        requests.tokens = this.tokenManager.getTokenList();

        return from(promiseAllProperties(requests));
      }),
      withLatestFrom(this.store),
      switchMap(async ([keyrings, store]) => {
        // first go thru the eth tokens and assign them indexes for filtering later on
        if (keyrings.ETH) {
          keyrings.ETH = keyrings.ETH.map((keyring, index) => {
            if (keyring.symbol !== "ETH") {
              keyring.ethFilterIndex = index + 1;
            }

            return keyring;
          });
        }

        // see if we have a debit card keyring, process it and remove it from further processing
        if (keyrings.DEBIT_CARD) {
          this.store.dispatch(new GetVirtualDebitCardsSuccessAction({ cards: keyrings.DEBIT_CARD }));
          delete keyrings.DEBIT_CARD;
        }

        if (keyrings.BANK_ACCOUNT) {
          // this.store.dispatch(new GetBankAccountSuccessAction(keyrings.BANK_ACCOUNT.list));
          // this.store.dispatch(new GetBankAccountBalanceSuccess(keyrings.BANK_ACCOUNT.balance));
          delete keyrings.BANK_ACCOUNT;
        }
        //merges an empty array with an array of the object values
        const flatKeyrings = [].concat(...Object.values(keyrings));
        const result: any = {};
        //creates an object with the keyring symbol as the key and the keyring as the value
        flatKeyrings.forEach(
          (keyring) =>
          (result[
            createKeyringId(
              keyring.baseChainSymbol,
              keyring.baseChainSymbol === "HL" || keyring.baseChainSymbol === "PT" ? keyring.symbol : keyring.guid
            )
          ] = keyring)
        );

        const supportedAssetList = keyrings.tokens;

        // Filtering out unsupported coins
        const supportedList = Object.keys(supportedAssetList);
        const tokensToInclude = [];

        if (keyrings.BTC) {
          result[`BTC`] = Object.assign({}, keyrings.BTC[0], keyrings.tokens.BTC);
        }

        if (keyrings.PT) {
          result["PT-AGX"] = Object.assign({}, result.undefined["PT-AGX"], keyrings.PT.agxBalance);
          result["PT-AGX"] = {...result["PT-AGX"], custodyService: "PT"};
          result["PT-AUX"] = Object.assign({}, result.undefined["PT-AUX"], keyrings.PT.auxBalance);
          result["PT-AUX"] = {...result["PT-AUX"], custodyService: "PT"};
          result["PT-LODE"] = Object.assign({}, result.undefined["PT-LODE"], keyrings.PT.lodeBalance);
          result["PT-LODE"] = {...result["PT-LODE"], custodyService: "PT"};
        }

        for (const keyringId in result) {
          if (environment.features.multicoin || supportedList.includes(keyringId) || keyringId.startsWith("PT-")) {
            tokensToInclude[keyringId] = result[keyringId];
          }
        }

        const serviceStatuses = store.connection.services;

        await Promise.all(supportedList.map(async (supportedKeyringId) => { 
          if (!tokensToInclude[supportedKeyringId]) {
            if (environment.features.bankAccounts && (await this.authService.hasGroup('GROUP_ACH')) && supportedKeyringId.startsWith("PT-AVAX") && (result["PT-AGX"] || result["PT-AUX"] || result["PT-LODE"])) {
              const tokenInfo = supportedAssetList[supportedKeyringId];
              const balanceDetails = this.mapKeyringBalanceToSymbol(tokenInfo.symbol, keyrings.PT);
              let address;
              try {
                address = await this.bankAccountService.getDepositWalletAddress(tokenInfo.symbol.toUpperCase().trim());
              } catch (error) {
                Logger.error('No address available', error);
                address = '0x0000000000000000000000000000000000000000';
              }
              tokensToInclude[supportedKeyringId] = {
                ...tokenInfo,
                keyringId: supportedKeyringId,
                balance: balanceDetails?.balance,
                balanceDetails: balanceDetails,//map balance to keyrings.PT based on tokenInfo.symbol
                address: address,
                custodyService: 'PT'
              }
            } else {
              if (supportedKeyringId === AGX_KEYRING_ID && !environment.features.agx) {
                return;
              }

              if (supportedKeyringId === AUX_KEYRING_ID && !environment.features.aux) {
                return;
              }

              if (supportedAssetList[supportedKeyringId].baseChainSymbol === "ETH" && !environment.features.eth) {
                return;
              }

              // Added to tolerate services down
              if (
                (supportedKeyringId === "ETH" && !store.connection.services.ethRPC) ||
                (supportedKeyringId === "BTC" && !store.connection.services.btcBB) ||
                (supportedKeyringId === "SYS" && !store.connection.services.sysBB) ||
                (supportedAssetList[supportedKeyringId].baseChainSymbol === "ETH" && !store.connection.services.ethRPC) ||
                (supportedAssetList[supportedKeyringId].baseChainSymbol === "AVAX" && !store.connection.services.avaxSnowTrace) ||
                (supportedAssetList[supportedKeyringId].baseChainSymbol === "AVAX" && !store.connection.services.avaxRpc) ||
                (supportedAssetList[supportedKeyringId].baseChainSymbol === "SOL" && !store.connection.services.solRpc)
              ) {
                return;
              }

              const tokenInfo = supportedAssetList[supportedKeyringId];

              if (tokenInfo.baseChainSymbol === "SYS" && !serviceStatuses.sysBB) {
                return;
              }

              if (keyrings.chains.indexOf(tokenInfo.baseChainSymbol) === -1) {
                return;
              }

              tokensToInclude[supportedKeyringId] = {
                ...tokenInfo,
                balance: 0,
                keyringId: supportedKeyringId,
                guid: tokenInfo.assetGuid,
                address: tokensToInclude[tokenInfo.baseChainSymbol] ? tokensToInclude[tokenInfo.baseChainSymbol].address : "",
              };

              if (tokenInfo.baseChainSymbol === "ETH" && tokenInfo.symbol !== tokenInfo.baseChainSymbol) {
                // Need this or ERC20 tx history will contain normal ETH txs.
                // this is a generic number, but should be something big or might find another token.
                tokensToInclude[supportedKeyringId].ethFilterIndex = 999999999;
              }
            }
          }
        }));
        return new GetTokenBalancesSuccessAction({ keyrings: tokensToInclude });
      }),
      catchError((error) => {
        Logger.error('walletEffect tokenBalanceRequest', error);
        return of(new GetTokenBalancesFailedAction({ error }));
      })
    )
  );
  mapKeyringBalanceToSymbol(symbol, keyringsPT) {
    switch(symbol) {
      case 'AUX':
        return keyringsPT?.auxBalance;
      case 'AGX':
        return keyringsPT?.agxBalance;
      case 'LODE':
        return keyringsPT?.lodeBalance;
      default:
        return 0;
    }
  }
  //check if keyringid contains PT-AGX, PT-AUX or PT-LODE and supported asset list has equivalent assets
  isPTChainKeyring(tokensToInclude, supportedKeyringId) {
    return supportedKeyringId.startsWith("PT-") && this.checkArrayKeys(tokensToInclude);
  }
  checkArrayKeys(array) {
    const keys = ["PT-AGX", "PT-AUX", "PT-LODE"];
    return keys.some(key => array[key]);
  }
  tokenBalancesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_BALANCES_SUCCESS),
      withLatestFrom(this.store),
      tap(async ([action, store]) => {
        if (environment.features.dust) {
          const sysKeyring = store.wallet.keychain.SYS;
          const sysBalance = Number(sysKeyring.balance);

          if (sysBalance < (sysKeyring.feePerAssetTx || SYS_PER_ASSET_TX)) {
            Logger.info("Claiming dust");
            try {
              const dust = await this.gasStationService.claimDust();

              if (dust instanceof Error) {
                throw dust;
              }
            } catch (err) {
              Logger.error(err);
            }
          }
        }
      }),
      switchMap(([action, store]) => {
        const { queryCount, keychain } = store.wallet as WalletState;

        if (queryCount === 1) {
          // start address notifier
          Object.values(keychain).forEachAsync(async (keyring: TokenKeyring) => {
            let type;
            if (keyring.baseChainSymbol === "SYS") {
              type = keyring.symbol === "SYS" ? "sys" : "spt";
            } else {
              type = keyring.baseChainSymbol.toLowerCase();
            }
        
            if (type && !this.wsAddressNotifier.hasNotifierOfType(type)) {
              await this.wsAddressNotifier.newNotifier(keyring.address, type);
            }
          });

          this.wsAddressNotifier.logNotifiers();

          this.wallet.checkForMultipleWallets(true);

          // commeneted for new on-boarding screens - check if this needed works for other scenario
          // this.nav.navigateRoot('/wallet/assets');
          //We will need this here for the redirect
          if (this.walletRedirectUri) {
            Logger.info(`Wallet balances loaded, navigating to ${this.walletRedirectUri}`);
            this.nav.navigateRoot(this.walletRedirectUri);
          }
        }

        const actions = [];
        // update token fiat values every time we fetch balances
        actions.push(
          new GetTokenFiatValuesRequestAction({
            symbols: Object.keys(store.wallet.keychain),
          })
        );

        // update syscoin fee per asset tx only if we haven't already
        if (environment.features.hl && keychain.SYS.feePerAssetTx === undefined) {
          actions.push(new GetFeePerAssetTxRequestAction());
        }
        this.tokenService.addTokenProgress.next(false); // TODO: Check if it token list gets updated after this action completed

        return actions;
      })
    )
  );

  feePerAssetTxRequest = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_FEE_PER_ASSET_TX_REQUEST),
      switchMap((action) => from(this.oraclePriceService.getSysFee())),
      map((fee: number) => new GetFeePerAssetTxSuccessAction({ fee })),
      catchError((error) => {
        Logger.error(error);
        return of(new GetFeePerAssetTxFailedAction({ error }));
      })
    )
  );

  tokenFiatValuesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_FIAT_VALUES_REQUEST),
      map((action: GetTokenFiatValuesRequestAction) => action.payload),
      withLatestFrom(this.store),
      switchMap(async ([payload, store]) => {


        return Promise.all([
          this.coinGeckoService.getValueInBaseCurrency(payload.symbols, [store.userPreferences.baseCurrency]),
          this.oraclePriceService.getMarketPriceFromLodeBackend(payload.symbols, [store.userPreferences.baseCurrency]),
        ]);
      }),
      withLatestFrom(this.store),
      map(([fiatResults, store]) => {
        const coinGeckoPrices = fiatResults[0];
        const lodeBackendPrices = fiatResults[1];
        const fiatValues: TokenFiatValueMap = {};

        this.getTokenPriceFromLodeBackend(lodeBackendPrices, fiatValues, store.priceOracle);
        
        this.getTokenPriceFromCoinGeckoApi(coinGeckoPrices, fiatValues);
        return new GetTokenFiatValuesSuccessAction({
          baseCurrency: store.userPreferences.baseCurrency,
          fiatValues,
        });
      }),
      catchError((error) => {
        Logger.error(error);
        return of(new GetTokenFiatValuesFailedAction({ error }));
      })
    )
  );

  private getTokenPriceFromLodeBackend(lodeBackendPrices : any, fiatValues: TokenFiatValueMap, priceOracleData: PriceOracleState) {
    Object.keys(lodeBackendPrices.fiatValues).forEach(keyringId => {
      if (keyringId == AGX_KEYRING_ID) {
        fiatValues[keyringId] = {
          fiatValue: lodeBackendPrices.fiatValues[keyringId],
          markup: priceOracleData.agxMarkup,
          tx_fee: lodeBackendPrices.tx_fee, // tx_fee is only for syscoin chain
        };
      } else if (keyringId == AUX_KEYRING_ID) {
        fiatValues[keyringId] = {
          fiatValue: lodeBackendPrices.fiatValues[keyringId],
          markup: priceOracleData.auxMarkup,
          tx_fee: lodeBackendPrices.tx_fee, // tx_fee is only for syscoin chain
        };
      }else if (isAGXKeyringId(keyringId)) {
        fiatValues[keyringId] = {
          fiatValue: lodeBackendPrices.fiatValues[keyringId],
          markup: priceOracleData.agxMarkup
        };
      } else if (isAUXKeyringId(keyringId)) {
        fiatValues[keyringId] = {
          fiatValue: lodeBackendPrices.fiatValues[keyringId],
          markup: priceOracleData.auxMarkup
        };
      } else {
        fiatValues[keyringId] = {
          fiatValue: lodeBackendPrices.fiatValues[keyringId],
          markup: 1
        };
      }

      // NOTE: for AGX and AUX the fiatValue (spot price) isn't actually used anywhere in the UI. The value
      // displayed should be retailFiatValue. Because the UI is already referencing fiatValue in many places
      // and spot price is not needed we populate AGX/AUX fiatValue with its retailFiatValue.
      fiatValues[keyringId].retailFiatValue = new BigNumber(fiatValues[keyringId].fiatValue)
        .multipliedBy(fiatValues[keyringId].markup)
        .toNumber();
      fiatValues[keyringId].fiatValue = fiatValues[keyringId].retailFiatValue;
    });

  }


  private getTokenPriceFromCoinGeckoApi(coinGeckoPrices: any, fiatValues: TokenFiatValueMap) {
    // convert public tokens to the correct data struct
    Object.keys(coinGeckoPrices.fiatValues)
    .filter((keychainId) => isNil(fiatValues[keychainId])) // only update for fiat value is nil - failed to get from Lode backend api
    .forEach((keychainId) => {
      fiatValues[keychainId] = { fiatValue: coinGeckoPrices.fiatValues[keychainId] };
    });
  }

  tokenHistoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTokenHistoryRequestAction>(WalletActionTypes.GET_TOKEN_HISTORY_REQUEST),
      withLatestFrom(this.store),
      map(async([action, store]) => {
        console.log("----------------------switch match -GetTokenHistoryRequestAction ");
        const { baseChainSymbol, sysGuid, ethFilterIndex, address, pageNum, itemsPerPage } = action.payload;
        let result: BlockbookGetAddressTxsResponseWithWalletData;
        try {
          switch (baseChainSymbol) {
            case "SYS":
              result = await this.blockbookService.getSysTokenHistory(address, pageNum, itemsPerPage, sysGuid);
              break;
            case "ETH":
              result = await this.blockbookService.getEthTokenHistory(address, pageNum, itemsPerPage, ethFilterIndex, sysGuid);
              break;
            case "BTC":
              result = await this.blockbookService.getBtcHistory(address, pageNum, itemsPerPage);
              break;
            case "SYSNEVM":
              result = await this.sysnevmService.getSysnevmTokenHistory(address, pageNum, itemsPerPage, ethFilterIndex, sysGuid);
              break;
            default:
              result = await this.avalancheService.getAvaxTokenHistory(address, pageNum, itemsPerPage, ethFilterIndex, sysGuid);
              break;
          }
          if (result) {
            if (result.page === 1) {
              result.hash = hashCode(JSON.stringify(result.transactions || []));
            } else {
              result.lastHash = hashCode(JSON.stringify(result.transactions || []));
            }
            this.store.dispatch(new GetTokenHistorySuccessAction({ result }));
          } else {
            this.store.dispatch(new GetTokenHistoryFailedAction({ error: 'Data is empty' }));
          }
        } catch (error) {
          Logger.error(`GetTokenHistoryRequestAction----- `+ error);
          this.store.dispatch(new GetTokenHistoryFailedAction({ error }));
        }
      })
    ),
    {dispatch:false}
  );

  getTokenHistorySuccess$ = createEffect(() => this.actions$.pipe(
    ofType<GetTokenHistorySuccessAction>(WalletActionTypes.GET_TOKEN_HISTORY_SUCCESS),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      // after we fetch balance data push user to assets view. Only do this the first fetch.
      if (store.wallet.queryCount === 1) {
        await this.routingService.goHome();
      }

      // get a list of current unconfirmed txs and return if there aren't any
      const unconfirmedTxs = store.wallet.unconfirmedTxs;
      if (!unconfirmedTxs.length) {
        return;
      }

      // get an array of all confirmed txids we have available. any unconfirmed txs will end up in list once confirmed
      // filter confirmed txs before mapping
      const confirmedTxIds = [].concat.apply(
        [],
        action.payload.result.transactions
          .filter((tx) => tx.confirmations)
          .map((tx) => {
            return tx.txid || tx["hash"];
          })
      );

      // filter the unconfirmed list to only include transactions which are in the confirmed list. These are the txs to remove
      const newlyConfirmedTxIds = unconfirmedTxs
        .filter((tx) => confirmedTxIds.find((txid) => txid === tx.txid))
        .map((tx) => tx.txid || tx["hash"]);

      // fire off an action to remove those transactions from the unconfirmedTxs state since they are now confirmed
      if (newlyConfirmedTxIds.length) {
        Logger.info("removing newly-confirmed transactions: " + newlyConfirmedTxIds);
        this.store.dispatch(new RemoveUnconfirmedTransactionsAction({ transactionIds: newlyConfirmedTxIds }));
      }
    })
  ),
    { dispatch: false }
  );

  resetWallet$ = createEffect(() => this.actions$.pipe(
    ofType<ResetWalletAction>(WalletActionTypes.RESET_WALLET),
    tap(async (data) => {
      this.members.clearRefreshInterval();

      // TODO: clear storage and reset state for wallet only (check)
      await this.storage.remove(WALLET_STORAGE_KEY);
      await this.storage.remove(WALLET_KEY);
      await this.storage.remove(SKIP_INTRO_PAGE_KEY);
      await this.storage.remove(SKIP_VAULT_MODEL_INFO);
      await this.storage.remove(SKIP_OPEN_VAULT_PAGE_KEY);
      await this.storage.remove(LIQUIDITY_PAIRS);
      await this.storage.remove(TOKEN_ALLOWANCE);
      await this.storage.remove(GRAPH_DATA_HOME);
      //await this.storage.remove(PRICE_LIST_OF_TOKENS); Eric I don't think that we need to clear this data
      await this.storage.remove(EXCHANGE_RATES);
      await this.storage.remove(GRAPH_DATA_OF_ALL_TOKENS);
      await this.storage.remove(GRAPH_DATA_TRADE);
      await this.storage.remove(SKIP_PRIMARY_ADDRESS_MODAL);
      await this.storage.remove(SUPPORTED_ASSETS);
      await this.storage.remove(LODE_DEBIT_CARD_EMAIL_SUBSCRIPTION);

      this.wsAddressNotifier.clearNotifiers();
      this.oneInchService.stopAvaxListening();

      this.store.dispatch(new ResetSendTokenDataAction());
      this.store.dispatch(new ResetConnectionAction());
      this.store.dispatch(new ResetGasStationDataAction());
      this.store.dispatch(new ResetConnectionAction());
      this.store.dispatch(new ResetPriceOracleDataAction());
      this.store.dispatch(new ResetBankAccountDataAction());
      this.store.dispatch(new ResetGraphDataAction());
      this.store.dispatch(new ResetWalletDataAction());
      this.store.dispatch(new ChangeActivePage("home"));

      // reset liquidity scheduler 
      this.tokenService.resetUpdateTokenService();
      this.tradeService.resetLiquidityScheduler();
    })
  ), { dispatch: false });

  resetWalletWithAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ResetWalletAction>(WalletActionTypes.RESET_WALLET_AUTH),
        tap(async (data) => {
          this.members.clearRefreshInterval();
          this.wsAddressNotifier.clearNotifiers();
          this.oneInchService.stopAvaxListening();
          return data;
        })
      ),
    { dispatch: false }
  );

  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticateAction>(WalletActionTypes.AUTHENTICATE),
      map(() => new LoadUserPreferencesAction())
    )
  );

  cancelTradeOrder$ = createEffect(() => this.actions$.pipe(
    ofType(WalletActionTypes.REMOVE_TRADE_ORDERS),
    withLatestFrom(this.store),
    map(async ([action, store]) => {
      const wallet = (store as AppState).wallet;
      const pageNumber = wallet.tradeOrders.pageNumber;
      const pageSize = wallet.tradeOrders.pageSize;
      const loadedAll = wallet.tradeOrders.loadedAll;
      const existingData = wallet.tradeOrders.data;
      const orderToRemove: any = action['order'];
      const data = existingData.filter(order => order.orderHash !== orderToRemove.orderHash) as [];
      this.store.dispatch(new AddTradeOrdersAction({ data, pageNumber, pageSize, loadedAll }));
    })
  ), { dispatch: false });

  getTradeOrders$ = createEffect(() => this.actions$.pipe(
    ofType(WalletActionTypes.GET_TRADE_ORDERS),
    withLatestFrom(this.store),
    map(async ([action, store]) => {
      const wallet = (store as AppState).wallet;
      const keychain = wallet.keychain;
      const avaxAddress = keychain.AVAX.address;
      const tokens = Object.values(wallet.keychain);

      const existingData = wallet.tradeOrders.data;
      const pageNumber = action['initialCall'] ? 1 : (wallet.tradeOrders.pageNumber);
      const pageSize = wallet.tradeOrders.pageSize;
      const newData = await this.oneInchService.getActiveOrderList(avaxAddress, pageNumber, pageSize) as [];

      // update or insert data
      let addedNewDataCount = 0;
      if (newData && newData.length > 0) {
        newData.map((data: any) => {
          const order = { ...data };
          order.maker = tokens.find(keychain => (keychain.guid && (keychain.guid).toLowerCase() === (order.data.makerAsset).toLowerCase()));
          order.taker = tokens.find(keychain => (keychain.guid && (keychain.guid).toLowerCase() === (order.data.takerAsset).toLowerCase()));

          // filtering tokens take we only support
          if (order.maker && order.taker) {
            const makerDecimal = (order.maker && order.maker.precision) ? order.maker.precision : 18;
            const takerDecimal = (order.taker && order.taker.precision) ? order.taker.precision : 18;

            order.maker.logo = `../../assets/images/coins/${(order.maker.symbol).toLowerCase()}_token.png`;
            order.taker.logo = `../../assets/images/coins/${(order.taker.symbol).toLowerCase()}_token.png`;

            // convert to contract's decimals & remove trailing zeros
            order.parsedMakingAmount = formatTokenAmount(Number(order.parsedMakingAmount) / (10 ** makerDecimal));
            order.parsedTakingAmount = formatTokenAmount(Number(order.parsedTakingAmount) / (10 ** takerDecimal));

            order.fills = formatTokenAmount(Number(order.fills) / (10 ** makerDecimal));
            order.remaining = formatTokenAmount(Number(order.remaining) / (10 ** makerDecimal));

            order.makerPrice = formatTokenAmount(parseFloat(order.maker.fiatValue) * parseFloat(order.parsedMakingAmount));
            order.takerPrice = formatTokenAmount(parseFloat(order.taker.fiatValue) * parseFloat(order.parsedTakingAmount));

            const dataIndex = existingData.findIndex(i => i.orderHash === order.orderHash);
            if (dataIndex === -1) {
              existingData.push(order);
              addedNewDataCount++;
            } else {
              existingData[dataIndex] = order;
            }
          }
        });
      }

      // sort by date
      const data = existingData.sort((a, b) => {
        return <any>new Date(b.createDateTime) - <any>new Date(a.createDateTime);
      });

      // update page number or finish pagination
      const updatePageNumber = action['initialCall']
        ? (addedNewDataCount >= pageSize) ? 2 : wallet.tradeOrders.pageNumber // if all data from page 1 gets inserted, restart the pagination from 1
        : (newData && newData.length >= pageSize ? (pageNumber + 1) : pageNumber);
      const loadedAll = (newData.length >= pageSize) ? false : true;

      // initate listner for transaction evensts to update trade-events
      if (newData && newData.length > 0) {
        this.oneInchService.startAvaxListening();
      } else {
        this.oneInchService.stopAvaxListening();
      }

      this.store.dispatch(new AddTradeOrdersAction({ data, pageNumber: updatePageNumber, pageSize, loadedAll }));
    })
  ), { dispatch: false });

  getTradeOrdersHistory$ = createEffect(() => this.actions$.pipe(
    ofType(WalletActionTypes.GET_TRADE_ORDERS_HISTORY),
    withLatestFrom(this.store),
    map(async ([action, store]) => {
      const wallet = (store as AppState).wallet;
      const keychain = wallet.keychain;
      const avaxAddress = keychain.AVAX.address;
      const tokens = Object.values(wallet.keychain);

      const existingData = wallet.tradeOrdersHistory.data;
      const pageNumber = action['initialCall'] ? 1 : (wallet.tradeOrdersHistory.pageNumber);
      const pageSize = wallet.tradeOrdersHistory.pageSize;
      const newData = await this.oneInchService.getOrderHistoryList(avaxAddress, pageNumber, pageSize) as [];

      // update or insert data
      let addedNewDataCount = 0;
      if (newData && newData.length > 0) {
        newData.map((data: any) => {
          const order = { ...data };
          order.maker = tokens.find(keychain => (keychain.guid && (keychain.guid).toLowerCase() === (order.data.makerAsset).toLowerCase()));
          order.taker = tokens.find(keychain => (keychain.guid && (keychain.guid).toLowerCase() === (order.data.takerAsset).toLowerCase()));

          // filtering tokens take we only support
          if (order.maker && order.taker) {
            const makerDecimal = (order.maker && order.maker.precision) ? order.maker.precision : 18;
            const takerDecimal = (order.taker && order.taker.precision) ? order.taker.precision : 18;

            order.maker.logo = `../../assets/images/coins/${(order.maker.symbol).toLowerCase()}_token.png`;
            order.taker.logo = `../../assets/images/coins/${(order.taker.symbol).toLowerCase()}_token.png`;

            // convert to contract's decimals & remove trailing zeros
            order.parsedMakingAmount = formatTokenAmount(Number(order.parsedMakingAmount) / (10 ** makerDecimal));
            order.parsedTakingAmount = formatTokenAmount(Number(order.parsedTakingAmount) / (10 ** takerDecimal));

            order.fills = formatTokenAmount(Number(order.fills) / (10 ** makerDecimal));
            order.remaining = formatTokenAmount(Number(order.remaining) / (10 ** makerDecimal));

            order.makerPrice = formatTokenAmount(parseFloat(order.maker.fiatValue) * parseFloat(order.parsedMakingAmount));
            order.takerPrice = formatTokenAmount(parseFloat(order.taker.fiatValue) * parseFloat(order.parsedTakingAmount));

            const dataIndex = existingData.findIndex(i => i.orderHash === order.orderHash);
            if (dataIndex === -1) {
              existingData.push(order);
              addedNewDataCount++;
            } else {
              existingData[dataIndex] = order;
            }
          }
        });
      }

      // sort by date
      const data = existingData.sort((a, b) => {
        return <any>new Date(b.createDateTime) - <any>new Date(a.createDateTime);
      });

      // update page number or finish pagination
      const updatePageNumber = action['initialCall']
        ? (addedNewDataCount >= pageSize) ? 2 : wallet.tradeOrdersHistory.pageNumber // if all data from page 1 gets inserted, restart the pagination from 1
        : (newData && newData.length >= pageSize ? (pageNumber + 1) : pageNumber);
      const loadedAll = (newData && newData.length >= pageSize) ? false : true;

      this.store.dispatch(new AddTradeOrdersHistoryAction({ data, pageNumber: updatePageNumber, pageSize, loadedAll }));
    })
  ), { dispatch: false });

  getPurchaseHistory$ = createEffect(() => this.actions$.pipe(
    ofType(WalletActionTypes.GET_PURCHASE_HISTORY),
    withLatestFrom(this.store),
    map(async ([action, store]) => {
      const keychain = (store as AppState).wallet.keychain;
      if(keychain) {
        let data = [];
        const purchaseHistory = await this.members.getPurchaseHistory() as [];
        data = data.concat(purchaseHistory);
        const avaxTokens = await Promise.all(
          getTokenInfoArray(keychain)
            .filter(c => c.baseChainSymbol == "AVAX" && !c.guid)
        );
        if (Array.isArray(avaxTokens) && avaxTokens[0]?.address) {
          const swapHistory = await this.graphQL.getSwapTransactionData(avaxTokens[0].address) as [];
          if (swapHistory) {
            data = data.concat(swapHistory);
            // showing AGX, AUX & LODE for now in purchase history
            data = data.filter(txn => (['agx', 'aux', 'lode', 'BTC', 'ETH', 'AVAX', 'USDC'].includes(txn.token)));
          }
        }
       
        data = data.sort((a, b) => {
          return <any>new Date(b.created_at) - <any>new Date(a.created_at);
        });

        this.store.dispatch(new AddPurchaseHistoryAction({ purchaseHistory: data }));
      }
    })
  ), { dispatch: false });
}
