import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { PAYMENT_MODES, CRYPTO_PAYMENT } from 'src/app/angular-wallet-base/constants';
import { SmallToCryptoPipe } from 'src/app/angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { AuthGuardService } from 'src/app/angular-wallet-base/services/auth-guard.service';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { WalletService } from 'src/app/angular-wallet-base/services/wallet.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { SubscriptionListenerComponent } from 'src/app/angular-wallet-base/SubscriptionListenerComponent';
import { SelectCreditCardComponent } from 'src/app/components/select-credit-card/select-credit-card.component';
import { SelectCryptoCardComponent } from 'src/app/components/select-crypto-card/select-crypto-card.component';
import { SelectAssetModal } from '../select-asset-modal/select-asset-modal.component';
import { WalletConnectService } from 'src/app/angular-wallet-base/services/wallet-connect.service';
import { SingularityPaymentService } from 'src/app/angular-wallet-base/services/singularity-payment.service';

@Component({
  selector: 'app-select-payment-modal',
  templateUrl: './select-payment-modal.html',
  styleUrls: ['./select-payment-modal.scss'],
})
export class SelectPaymentModal extends SubscriptionListenerComponent implements OnInit {
  public isDesktopMode: boolean;
  public itemsTemp;
  public selectedToken?;
  public selectedTokenFiatValue?;
  public items = [
    {
      logo: '../../../assets/images/CC_WHT.png',
      payWith: PAYMENT_MODES.CREDIT_DEBIT_CARD,
      display_name: 'buy_assets.credit_debit',
      symbol: '',
      network: '',
      childElements: [],
      isExpanded: false,
    },
    {
      logo: '../../../assets/images/Bank_WHT.png',
      payWith: PAYMENT_MODES.BANK_TRANSFER,
      display_name: 'buy_assets.bank_transfer',
      symbol: '',
      network: '',
      childElements: [],
      isExpanded: false,
    },
    {
      logo: '../../../assets/images/metals.png',
      payWith: PAYMENT_MODES.MY_METALS,
      display_name: 'buy_assets.my_metals',
      symbol: '',
      network: '',
      childElements: [],
      isExpanded: false,
    },
    {
      logo: '../../../assets/images/Crypto_WHT.png',
      payWith: PAYMENT_MODES.CRYPTO,
      display_name: 'buy_assets.crypto',
      symbol: '',
      network: '',
      childElements: [],
      isExpanded: false,
    },
  ];
  private tokens;
  public walletType: any;
  public selectedTokenAddress: any;
  public symbol: any;
  public newMember: boolean;
  public promoCode: string = null;
  public selectedFromVault: any;

  constructor(
    private modalController: ModalController,
    private router: Router,
    public appMode: AppModeService,
    protected store: Store<AppState>,
    private smallToCrypto: SmallToCryptoPipe,
    private walletService: WalletService,
    private nav: NavController,
    private authGuardService: AuthGuardService,
    private authService: AuthService,
    private walletConnectService: WalletConnectService,
    private singularityPaymentService: SingularityPaymentService
  ) {
    super(store);
    this.appMode.isDesktopMode.subscribe((mode) => (this.isDesktopMode = mode));
  }

  async ngOnInit(): Promise<void> {
    this.walletType = this.walletService.getWalletType();
    this.modalController.dismiss({}, '', 'select-asset-model');
    this.modalController.dismiss({}, '', 'select-credit-model');
    if (this.selectedToken == 'LODE' || this.selectedToken == 'AUX' || this.selectedToken == 'AGX') {
      this.items = this.items.filter(
        (token) =>
          token.payWith == PAYMENT_MODES.CREDIT_DEBIT_CARD ||
          token.payWith == PAYMENT_MODES.BANK_TRANSFER ||
          token.payWith == PAYMENT_MODES.CRYPTO
      );
    } else {
      this.items = this.items.filter((token) => token.payWith == PAYMENT_MODES.CREDIT_DEBIT_CARD);
    }

    if (!CRYPTO_PAYMENT) {
      this.items = this.items.filter((token) => token.payWith != PAYMENT_MODES.CRYPTO);
    }

    this.itemsTemp = this.items;
    this.walletConnectService.initiateWalletConnectWeb3Instance();
  }

  async closeModal(item?) {
    Logger.info(this.selectedToken, item);
    this.authGuardService.setSelectedBuyData('');
    const selectType = {
      path: item.logo,
      payWith: item.payWith,
      fiatValue: item.fiatValue,
      selectedTokenFiatValue: null,
      totalBalance: item.totalFiatBalance,
      name: item.name,
      symbol: item.symbol,
      baseChainSymbol: item.baseChainSymbol,
      assetGuid: item.assetGuid,
      tokenFrom: item,
      destinationWalletAddress: this.selectedTokenAddress,
    };
    if (item) {
      if (item.payWith === PAYMENT_MODES.CREDIT_DEBIT_CARD) {
        const cssClass = this.isDesktopMode ? 'select-asset-modal-desktop' : 'select-asset-modal';
        const modal = await this.modalController.create({
          component: SelectCreditCardComponent,
          componentProps: {
            selectedToken: this.selectedToken,
            selectType,
            symbol: this.symbol,
          },
          id: 'select-credit-model',
          cssClass,
          backdropDismiss: false,
          initialBreakpoint: this.isDesktopMode ? undefined : 0.6,
          breakpoints: this.isDesktopMode ? undefined : [0, 0.6, 0.9, 1],
        });
        await modal.present();
        return;
      } else if (item.payWith === PAYMENT_MODES.CRYPTO) {
        // const cssClass = (this.isDesktopMode) ? 'select-asset-modal-desktop' : 'select-asset-modal';
        // const modal = await this.modalController.create({
        //   component: SelectCryptoCardComponent,
        //   componentProps: {
        //     selectedToken: this.selectedToken,
        //     selectType,
        //     symbol: this.symbol
        //   },
        //   id: 'select-crypto-model',
        //   cssClass,
        //   backdropDismiss: false,
        //   initialBreakpoint: (this.isDesktopMode) ? undefined : 0.6,
        //   breakpoints: (this.isDesktopMode) ? undefined : [0, 0.6, 0.9, 1],
        // });
        // await modal.present();
      }
    }
    this.authGuardService.setSelectedBuyData({ form: selectType });

    if (this.router.url.includes('buy-assets')) {
      await this.authService.dismissModal('select-payment-model');
      await this.authService.dismissModal('select-credit-model');
      this.router.navigateByUrl('/buy-assets', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/buy-assets', item.payWith === PAYMENT_MODES.CRYPTO ? 'singularity' : '', this.symbol], {
          replaceUrl: true,
        });
      });
    } else {
      await this.authService.dismissModal('select-payment-model');
      await this.authService.dismissModal('select-credit-model');
      this.nav.navigateForward(['/buy-assets', item.payWith === PAYMENT_MODES.CRYPTO ? 'singularity' : '', this.symbol]);
    }
  }

  SearchToken(ev) {
    // this.itemsTemp = this.items.filter((item) => item.payWith.toLowerCase().includes(ev.target.value.toLowerCase()));
  }

  isExpandChange(i, item) {
    if (item.tokens && item.tokens.length > 1 && !['BTC', 'ETH', 'SYS', 'SOL', 'AVAX'].includes(item.symbol)) {
      this.items[i].isExpanded = !this.items[i].isExpanded;
    } else {
      Logger.info(item);
      this.closeModal(item);
    }
  }
  closeModalpopup() {
    this.modalController.dismiss();
  }
  isExpandChangeChild(item) {
    this.closeModal(item);
  }

  async backModalpopup() {
    this.closeModalpopup();
    if (this.selectedFromVault) {
      return;
    }
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
    let cssClass = '';
    cssClass += this.isDesktopMode ? 'select-asset-modal-desktop' : 'select-asset-modal';
    cssClass += !sideMenuHidden && this.isDesktopMode ? ' with-side-menu' : '';
    const modal = await this.modalController.create({
      component: SelectAssetModal,
      id: 'select-asset-model',
      componentProps: {
        actionType: 'buy',
        newMember: this.newMember,
        promocode: this.promoCode,
      },
      cssClass,
      initialBreakpoint: this.isDesktopMode ? undefined : 0.9,
      breakpoints: this.isDesktopMode ? undefined : [0, 0.6, 0.9, 1],
    });
    await modal.present();
    await modal.onDidDismiss().then();
  }
}
