import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { MembersPortalService } from 'src/app/angular-wallet-base/services/members-portal.service';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { getPurchaseHistory } from 'src/app/angular-wallet-base/store/wallet';
import { SubscriptionListenerComponent } from 'src/app/angular-wallet-base/SubscriptionListenerComponent';

@Component({
  selector: 'purchase-history-modal',
  templateUrl: './purchase-history.modal.html',
  styleUrls: ['./purchase-history.modal.scss'],
})

export class PurchaseHistoryModalComponent extends SubscriptionListenerComponent implements OnInit {

  public isDesktopMode: boolean;
  public data: any = [];
  public status: any = {};
  public swapHistory: any = [];

  constructor(private modal: ModalController,
    private translate: TranslateService,
    public appMode: AppModeService,
    public membersPortalService: MembersPortalService,
    protected store: Store<AppState>,
  ) {
    super(store);
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  async ngOnInit() {
    try {
      // graphql
      this.store.select(getPurchaseHistory).pipe(take(1)).toPromise().then(async purchaseHisroty => {
        this.data = purchaseHisroty;
        Logger.info(this.data)

      });
      this.status.cancelled = await this.translate.get('buy_agx.purchase_history.cancelled').toPromise();
      this.status.new = await this.translate.get('buy_agx.purchase_history.new').toPromise();
      this.status.minted = await this.translate.get('buy_agx.purchase_history.minted').toPromise();
      this.status.confirmed = await this.translate.get('buy_agx.purchase_history.confirmed').toPromise();
    } catch (err) {
      this.data = [];
    }
  }

  back() {
    this.modal.dismiss();
  }



  statusParse(status) {
    switch (status) {
      case 'new':
        return { text: this.status.new, color: 'yellow' }
      case 'minted':
        return { text: this.status.confirmed, color: 'green' }
      case 'cancelled':
        return { text: this.status.cancelled, color: 'red' }
      case 'confirmed':
        return { text: this.status.confirmed, color: 'green' }
    }
  }

  toCamelCase(str) {
    switch (str) {
      case 'deliver':
          return 'buy_assets.deliver'
        break;
      case 'crypto':
        return 'buy_assets.crypto'
        break;
      case 'wire':
        return 'buy_assets.wire'
        break;
      case 'simplex':
        return 'buy_assets.simplex'
        break;

      default:
        break;
    }
  }

}
