import { Injectable } from "@angular/core";
import { isNil } from "../ts-util";
import { NetworkService } from "./network.service";
import { StorageService } from "./storage.service";
import web3 from 'web3';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Logger } from "./logger.service";
import { inspect } from "util";
import { RpcProvider } from "./rpc-provider";
import { gweiToEth, nAvaxToAvax, weiToNum } from "../utils";
import { GAS_LIMIT_ERC20_SEND, GAS_LIMIT_SEND } from "../constants";
import BigNumber from "bignumber.js";
import { SmallToCryptoPipe } from "../pipes/smalltocrypto/smalltocrypto.pipe";
import {Avalanche} from 'avalanche';
import { Wallet } from "ethers";
import { hdPaths } from "../lib/pangolin/keyring";

export interface BlockchainAddressInfo {
  address: string;
  balance: string;
  unconfirmedBalance?: string,
  unconfirmedTxs?: number;
  txs?: number;
  nonce?: string;
}

export interface EVMBlockchainTransactionInfo {
  blockNumber: string;
  timeStamp: number,
  hash: string,
  nonce?: string,
  blockHash: string,
  transactionIndex?: number,
  from: string,
  to?: string,
  value?: string,
  gas?: number,
  gasPrice: number,
  isError?: string,
  txreceipt_status?: string,
  input?: string,
  contractAddress?: string,
  cumulativeGasUsed?: number,
  gasUsed?: number,
  confirmations?: number,
  methodId?: string,
  functioName?: string 
}

/// all price will base on the native token
/// such as AVAX or ETH
export interface GasPriceOracle {
  fast: number,
  standard: number,
  slow: number,
  usdPrice: number
}


@Injectable({
  providedIn: 'root'
})
export class EvmNetworkService extends NetworkService {
  private static ethWeb3;
  private static avaxWeb3;
  
  constructor(protected storage: StorageService, 
    protected http: HttpClient, 
    protected rpcProvider: RpcProvider,
    protected smallToCryptoPipe: SmallToCryptoPipe) {
    super(storage, http, rpcProvider, smallToCryptoPipe);
  }



  public async getETHWeb3Provider(): Promise<web3> {
    if(isNil(EvmNetworkService.ethWeb3)) {
      let ethNetwork = await this.getActiveEthNetwork();
      EvmNetworkService.ethWeb3 = new web3(ethNetwork.RPC_URL);
    }
    return EvmNetworkService.ethWeb3;
  }

  public async getAvaxWeb3Provider(): Promise<web3> {
    if (isNil(EvmNetworkService.avaxWeb3)) {
      let avaxNetwork = await this.getActiveAvaxNetwork();
      EvmNetworkService.avaxWeb3 = new web3(avaxNetwork.RPC_URL)
    }
    return EvmNetworkService.avaxWeb3;
  }

  public async getETHBlockchainAddressInfo(address: string): Promise<BlockchainAddressInfo> {
    let balance = '0', unconfirmedBalance = '0', unconfirmedTxs = 0, txs = 0, nonce = '0';
    try {
      let ethProvider = await this.getETHWeb3Provider();
      balance = await ethProvider.eth.getBalance(address, 'latest');
      unconfirmedBalance = await ethProvider.eth.getBalance(address, 'pending');
      txs = await ethProvider.eth.getTransactionCount(address, 'latest');
      nonce = txs.toString();
      unconfirmedTxs = await ethProvider.eth.getTransactionCount(address, 'pending');
    } catch (err) {
      Logger.error('getETHBlockchainAddressInfo', inspect(err));
    }
    return { address, balance, unconfirmedBalance, unconfirmedTxs, txs, nonce};
  }

  public async getEthereumTokenHistory(address: string, page: number, itemsPerPage: number, tokenContract: string): Promise<EVMBlockchainTransactionInfo[]> {
    try {
      let res;
      if (!isNil(tokenContract)) {
        res = await this.http
          .get(`${await this.getEtherScanApi()}/api?module=account&action=tokentx&contractaddress=${tokenContract}&address=${address}&page=${page}&offset=${itemsPerPage}&sort=desc&apikey=${environment.ETHERSCAN_API_KEY}`)
          .toPromise();
          return Array.isArray(res['result']) ? res['result'] : [];
      } else {
        res = await this.http
          .get(`${await this.getEtherScanApi()}/api?module=account&action=txlist&address=${address}&startblock=1&endblock=9999999999&page=${page}&offset=${itemsPerPage}&sort=desc&apikey=${environment.ETHERSCAN_API_KEY}`)
          .toPromise();
        return Array.isArray(res['result']) ? res['result'] : [];
      }
    } catch (err) {
      Logger.error('getEthereumTokenHistory', inspect(err));
      return [];
    }
  }

  public async getEVMGasPrice(baseChainSymbol: 'ETH' | 'AVAX' | 'SYSNEVM'){
    const gasOraclePrice = await this.getEVMGasOraclePrice(baseChainSymbol);
    let gasPrices: any = {};
    gasPrices.slow = new BigNumber(gasOraclePrice.slow).multipliedBy(GAS_LIMIT_SEND);
    // for simple ERC20 transaction, so we hardcode for GAS_LIMIT_ERC20 transaction
    // https://ethereum.stackexchange.com/questions/39401/how-do-you-calculate-gas-limit-for-transaction-with-data-in-ethereum
    // otherwise :gasLimit = Gtransaction + Gtxdatanonzero × dataByteLength
    gasPrices.slowErc20 = new BigNumber(gasOraclePrice.slow).multipliedBy(GAS_LIMIT_ERC20_SEND);

    gasPrices.standard = new BigNumber(gasOraclePrice.standard).multipliedBy(GAS_LIMIT_SEND);
    gasPrices.standardErc20 = new BigNumber(gasOraclePrice.standard).multipliedBy(GAS_LIMIT_ERC20_SEND);

    gasPrices.fast = new BigNumber(gasOraclePrice.fast).multipliedBy(GAS_LIMIT_SEND);
    gasPrices.fastErc20 = new BigNumber(gasOraclePrice.fast).multipliedBy(GAS_LIMIT_ERC20_SEND);
    gasPrices.usdPrice = gasOraclePrice.usdPrice;
    return gasPrices;
  }



  public async getEVMGasOraclePrice(baseChainSymbol: string): Promise<GasPriceOracle> {
    try {
      switch (baseChainSymbol) {
        case "AVAX":
          return this.getAvaxGasOraclePrice();
        case "ETH": 
          return this.getETHGasOraclePrice();
        case "SYSNEVM": 
          return this.getSysnevmGasOraclePrice();
        default:
          throw new Error("getEVMGasOraclePrice - Not found baseChain" + baseChainSymbol);
      }
    } catch (err) {
      Logger.error('getEVMGasOraclePrice', inspect(err));
      return {fast: 0, standard: 0, slow: 0, usdPrice: 0};
    }
   
  }

  private async getSysnevmGasOraclePrice(): Promise<GasPriceOracle> {
    try {
      const avaxNetwork = await this.getActiveAvaxNetwork();
      // sample result: {"status":"1","message":"OK","result":{"LastBlock":"21872811","SafeGasPrice":"26","ProposeGasPrice":"27.4","FastGasPrice":"27.9","priceUSD":"17.6"}}
      const avalanche = new Avalanche(
        avaxNetwork.URL,
        undefined,
        "https",
        avaxNetwork.chainId
      );
      const cchain = avalanche.CChain();

      const baseFee = parseInt(await cchain.getBaseFee(), 16) / 1e9;
      let maxPriorityFeePerGas = parseInt(await cchain.getMaxPriorityFeePerGas(), 16) / 1e9;
      let maxFeePerGas = baseFee + maxPriorityFeePerGas;;
      if (maxFeePerGas < maxPriorityFeePerGas) {
        throw "Error: Max fee per gas cannot be less than max priority fee per gas";
      }

      let result: any = await this.http.get(avaxNetwork.GAS_PRICE).toPromise();
      return {
        fast: nAvaxToAvax(maxFeePerGas), // in AVAX
        standard: nAvaxToAvax(baseFee),
        slow: nAvaxToAvax(baseFee),
        usdPrice: Number(result.result.UsdPrice)
      }
    } catch (err) {
      /// default value on 16-Dec
      // https://gavax.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle
      // {"status":"1","message":"OK","result":{"LastBlock":"23719557","SafeGasPrice":"26","ProposeGasPrice":"26.5","FastGasPrice":"27","UsdPrice":"12.46"}}
      return {
        fast: nAvaxToAvax(27), // in AVAX
        standard: nAvaxToAvax(26.5),
        slow: nAvaxToAvax(26),
        usdPrice: Number(12.46)
      }
    }
   
  }

  
  private async getAvaxGasOraclePrice(): Promise<GasPriceOracle> {
    try {
      const avaxNetwork = await this.getActiveAvaxNetwork();
      // sample result: {"status":"1","message":"OK","result":{"LastBlock":"21872811","SafeGasPrice":"26","ProposeGasPrice":"27.4","FastGasPrice":"27.9","priceUSD":"17.6"}}
      const avalanche = new Avalanche(
        avaxNetwork.URL,
        undefined,
        "https",
        avaxNetwork.chainId
      );
      const cchain = avalanche.CChain();

      const baseFee = parseInt(await cchain.getBaseFee(), 16) / 1e9;
      let maxPriorityFeePerGas = parseInt(await cchain.getMaxPriorityFeePerGas(), 16) / 1e9;
      maxPriorityFeePerGas = maxPriorityFeePerGas == 0 ? 2 : maxPriorityFeePerGas;
      let maxFeePerGas = baseFee + maxPriorityFeePerGas;
      if (maxFeePerGas < maxPriorityFeePerGas) {
        throw "Error: Max fee per gas cannot be less than max priority fee per gas";
      }

      let result: any = await this.http.get(avaxNetwork.GAS_PRICE).toPromise();
      return {
        fast: nAvaxToAvax(maxFeePerGas), // in AVAX
        standard: nAvaxToAvax(baseFee),
        slow: nAvaxToAvax(baseFee),
        usdPrice: Number(result.result.UsdPrice)
      }
    } catch (err) {
      /// default value on 16-Dec
      // https://gavax.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle
      // {"status":"1","message":"OK","result":{"LastBlock":"23719557","SafeGasPrice":"26","ProposeGasPrice":"26.5","FastGasPrice":"27","UsdPrice":"12.46"}}
      Logger.error(`getAvaxGasOraclePrice - ${inspect(err)}`);
      return {
        fast: nAvaxToAvax(27), // in AVAX
        standard: nAvaxToAvax(26.5),
        slow: nAvaxToAvax(26),
        usdPrice: Number(12.46)
      }
    }
   
  }

  private async getETHGasOraclePrice(): Promise<GasPriceOracle> {
    try {
      const ethNetwork = await this.getActiveEthNetwork();
      let result: any = await this.http.get(ethNetwork.GAS_PRICE).toPromise();
      return {
        fast: weiToNum(result.data.rapid), // in Eth
        standard: weiToNum(result.data.fast),
        slow: weiToNum(result.data.standard),
        usdPrice: Number(result.data.priceUSD)
      }
    } catch (err) {
      Logger.error('getETHGasOraclePrice', inspect(err));
      // default value on 16-Dec
      //https://beaconcha.in/api/v1/execution/gasnow
      // {"code":200,"data":{"rapid":26650749907,"fast":17474044521,"standard":17474044521,"slow":17474044521,"timestamp":1671214944013,"priceUSD":1196.73}}
      return {
        fast: weiToNum(26650749907), // in Eth
        standard: weiToNum(17474044521),
        slow: weiToNum(17474044521),
        usdPrice: Number(1196)
      }
    }
    
  }
  
  public async getWalletEVM(pin: any): Promise<Wallet> {
    const defaultNetwork = await this.getActiveAvaxNetwork();
    return this.getWalletByHDPath(pin, defaultNetwork.hdPath);
  }
}
