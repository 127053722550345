import { Action } from '@ngrx/store';

export enum VirtualDebitCardActionTypes {
  SET_VIRTUAL_DEBIT_CARD_DATA = '[virtualdebitcard] Set virtual debit card data',
  SEND_VIRTUAL_DEBIT_CARD_DATA = '[virtualdebitcard] Send virtual debit card data',
  GET_VIRTUAL_DEBIT_CARDS_REQUEST = '[virtualdebitcard] Get virtual debit cards request',
  GET_VIRTUAL_DEBIT_CARDS_SUCCESS = '[virtualdebitcard] Get virtual debit cards success',
  GET_VIRTUAL_DEBIT_CARDS_FAILURE = '[virtualdebitcard] Get virtual debit cards failure',
  SET_VIRTUAL_DEBIT_CARD_AUTH = '[virtualdebitcard] Set virtual debit card detail auth',
  RESET_VIRTUAL_DEBIT_CARD_DATA = '[virtualdebitcard] Reset virtual debit card deta',
}

export class SetVirtualCardDataAction implements Action {
  readonly type = VirtualDebitCardActionTypes.SET_VIRTUAL_DEBIT_CARD_DATA;
  constructor(readonly payload: { address: string, amount: number, operationType: string, epin: number }) { }
}

export class SendVirtualDebitCardDataAction implements Action {
  readonly type = VirtualDebitCardActionTypes.SEND_VIRTUAL_DEBIT_CARD_DATA;
  constructor(readonly payload: { pin: string }) { }
}

export class GetVirtualDebitCardsRequestAction implements Action {
  readonly type = VirtualDebitCardActionTypes.GET_VIRTUAL_DEBIT_CARDS_REQUEST;
  constructor() { }
}

export class GetVirtualDebitCardsSuccessAction implements Action {
  readonly type = VirtualDebitCardActionTypes.GET_VIRTUAL_DEBIT_CARDS_SUCCESS;
  constructor(readonly payload: { cards: any[] }) { }
}

export class GetVirtualDebitCardsFailureAction implements Action {
  readonly type = VirtualDebitCardActionTypes.GET_VIRTUAL_DEBIT_CARDS_FAILURE;
  constructor() { }
}

export class SetVirtualDebitCardDataAuthAction implements Action {
  readonly type = VirtualDebitCardActionTypes.SET_VIRTUAL_DEBIT_CARD_AUTH;
  constructor(readonly payload: { authenticated: boolean }) { }
}

export class ResetVirtualDebitCardDataAction implements Action {
  readonly type = VirtualDebitCardActionTypes.RESET_VIRTUAL_DEBIT_CARD_DATA;
  constructor() { }
}

