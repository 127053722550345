import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { take, tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { WalletConnectActionTypes, WalletConnectErrorAction, WalletConnectEventRequestSignAuthSuccessAction, WalletConnectEventRequestSignSuccessAction, WalletConnectSessionDisconnectRequestAction, WalletConnectSessionDisconnectSuccessAction, WalletConnectSessionDisconnected, WalletConnectSessionProposalPairRequestAction, WalletConnectSessionProposalPairSuccessAction, WalletConnectSessionProposalSingularityPairRequestAction, WalletConnectSetChainRequestAction, WalletConnectSetChainRequestFailureAction, WalletConnectSetChainRequestSuccessAction, WalletConnectStateInitFailureAction, WalletConnectStateInitRequestAction, WalletConnectStateInitSuccessAction } from '../actions/walletConnect.actions';
import { AlertController } from '@ionic/angular';
import { WalletConnectService } from '../services/wallet-connect.service';
import { inspect } from 'util';
import { SingularityUserCloseDrawer, SingularityUserLoginSuccessFromDApp } from '../actions/singularityPayment.action';
import { getPendingOrder } from '../store/purchaseOrder.state';

@Injectable()
export class WalletConnectEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private walletConnectService: WalletConnectService,
    private alertController: AlertController
  ) {}

  walletConnectStateInitRequest$ = createEffect(() => this.actions$.pipe(
    ofType<WalletConnectStateInitRequestAction>(WalletConnectActionTypes.WALLETCONNECT_STATE_INIT_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        const web3Wallet = await this.walletConnectService.initiateWalletConnectWeb3Wallet();
        return this.store.dispatch(new WalletConnectStateInitSuccessAction({web3Wallet}));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new WalletConnectStateInitFailureAction(`Failed to initiate the Wallet Connect instance. Please refresh the page`));
      }
    })
  ), { dispatch: false });


  pairDAppRequest$ = createEffect(() => this.actions$.pipe(
    ofType<WalletConnectSessionProposalPairRequestAction>(WalletConnectActionTypes.WALLETCONNECT_SESSION_PROPOSAL_PAIR_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        const uri = action.payload.uri;
        const web3Wallet = await this.walletConnectService.pairNewDApp(uri);
        return this.store.dispatch(new WalletConnectSessionProposalPairSuccessAction({web3Wallet}));
      } catch (err) {
        Logger.error(`Error: pairDAppRequest ${inspect(err)}`);
        return this.store.dispatch(new WalletConnectErrorAction(`Error in pair Dapp Request: ${err?.message}`));
      }
    })
  ), { dispatch: false });



  setChainRequest$ = createEffect(() => this.actions$.pipe(
    ofType<WalletConnectSetChainRequestAction>(WalletConnectActionTypes.WALLETCONNECT_SET_CHAIN_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        const baseChainSymbol = action.payload.baseChainSymbol;
        const walletConnectSelectedNetwork = await this.walletConnectService.setNewChain(baseChainSymbol);
        return this.store.dispatch(new WalletConnectSetChainRequestSuccessAction({walletConnectSelectedNetwork}));
      } catch (err) {
        Logger.error(`Error: setChainRequest ${inspect(err)}`);
        return this.store.dispatch(new WalletConnectSetChainRequestFailureAction(`Error: ${err?.message}`)); // preprana can you help to show error message in this case
      }
    })
  ), { dispatch: false });


  disconnectWalletConnectSession$ = createEffect(() => this.actions$.pipe(
    ofType<WalletConnectSessionDisconnectRequestAction>(WalletConnectActionTypes.WALLETCONNECT_SESSION_DISCONNECT_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        let sessionTopic = action.payload.sessionTopic;
        const web3Wallet = await this.walletConnectService.disconnectActiveSession(sessionTopic);
        return this.store.dispatch(new WalletConnectSessionDisconnectSuccessAction({web3Wallet, sessionTopic}));
      } catch (err) {
        Logger.error(`Error: disconnectWalletConnectSession ${inspect(err)}`);
        return this.store.dispatch(new WalletConnectErrorAction(`Error: ${err?.message}`)); // preprana can you help to show error message in this case
      }
    })
  ), { dispatch: false });

  signingEventRequest$ = createEffect(() => this.actions$.pipe(
    ofType<WalletConnectEventRequestSignAuthSuccessAction>(WalletConnectActionTypes.WALLETCONNECT_EVENT_REQUEST_SIGN_AUTH_SUCCESS),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        const signedResult = await this.walletConnectService.signEventRequest(action.payload.pin);
        return this.store.dispatch(new WalletConnectEventRequestSignSuccessAction(signedResult));
      } catch (err) {
        Logger.error(`Error: signingEventRequest ${inspect(err)}`);
        return this.store.dispatch(new WalletConnectErrorAction(`Error-signingEventRequest: ${err?.message}`)); // preprana can you help to show error message in this case
      }
    })
  ), { dispatch: false });

  pairingSingularityWalletConnectSession$ = createEffect(() => this.actions$.pipe(
    ofType<WalletConnectSessionProposalSingularityPairRequestAction>(WalletConnectActionTypes.WALLETCONNECT_SINGULARITY_SESSION_PROPOSAL_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
        Logger.info(`start to login by WalletConnect`);
        const singularityEvent = (window as any).SingularityEvent;
        await singularityEvent.logout();
        Logger.info(`Singularity logout successfully`);
        const res = await singularityEvent.getWalletConnectConnectionUri();
        Logger.info(`uri response ${inspect(res)}`);
        const {name, metaData} = res;
        Logger.info(`pairingSingularityWalletConnectSession wallet connect data ${name} - ${metaData}`);
        singularityEvent.subscribe('SingularityEvent-login', data => { 
          const userData = JSON.parse(data) 
          Logger.info('SingularityEvent-login: userData --->', userData); 
          this.store.dispatch(new SingularityUserLoginSuccessFromDApp());
        });
        const web3Wallet = await this.walletConnectService.pairNewProposalSessionSingularityDApp(metaData);
        return this.store.dispatch(new WalletConnectSessionProposalPairSuccessAction({web3Wallet}));
      } catch (err) {
        Logger.error(`Error: pairingSingularityWalletConnectSession ${inspect(err)}`);
        return this.store.dispatch(new WalletConnectErrorAction(`Error-pairingSingularityWalletConnectSession: ${err?.message}`)); // preprana can you help to show error message in this case
      }
    })
  ), { dispatch: false });

  displayWalletConnectError$ = createEffect(() => this.actions$.pipe(
    ofType<WalletConnectErrorAction>(WalletConnectActionTypes.WALLETCONNECT_ERROR_ACTION),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      this.showErrorMessage(action.payload);
    })
  ), { dispatch: false });


  private async showErrorMessage(errorMessage: string) {
    const alert = await this.alertController.create({
      header: 'Error',
      message: errorMessage,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
