import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../angular-wallet-base/store/appState';
import { openLinkExternal } from '../../angular-wallet-base/utils';
import { LanguageService } from '../../angular-wallet-base/services/language.service';
import { RemoteVersionInfo } from '../../global';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../../angular-wallet-base/services/logger.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'update-required-modal',
  templateUrl: './update-required-modal.component.html',
  styleUrls: ['./update-required-modal.component.scss'],
})
export class UpdateRequiredModalComponent implements OnInit {
  @Input() remoteVersion: RemoteVersionInfo;
  public remoteVersionString: string;
  public releaseNotesString: string;
  public upgradeNoticeString: string;

  constructor(public modalController: ModalController,
              public store: Store<AppState>,
              public platform: Platform,
              private translate: TranslateService,
              private language: LanguageService,
              private inAppBrowser: InAppBrowser) { }


  async ngOnInit() {
    this.remoteVersionString = this.remoteVersion.latest_version;
    const currentLang = await this.language.getCurrentLanguage()[0];
    switch (currentLang.value) {
      case 'en':
      case 'fr':
      case 'es':
        this.releaseNotesString = this.remoteVersion.release_notes[currentLang.value];
        this.upgradeNoticeString = this.remoteVersion.upgrade_notice[currentLang.value];
        break;

      default: // Default to English
        this.releaseNotesString = this.remoteVersion.release_notes.en;
        this.upgradeNoticeString = this.remoteVersion.upgrade_notice.en;
    }
    Logger.info('Update-required language detected', this.translate.currentLang, this.translate.getBrowserLang());
  }

  onSubmit() {

    if (this.platform.is('android')) {
      openLinkExternal(environment.version_releases.playstore_link, this.inAppBrowser);
    } else if (this.platform.is('ios')) {
      openLinkExternal(environment.version_releases.appstore_link, this.inAppBrowser);
    } else {
      // Handle browser case
    }
  }

  async dismiss() {
    // Don't allow backing out of this modal
    return;
  }
}
