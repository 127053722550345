import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TransactionSummary } from '../../global';
import { Store } from '@ngrx/store';
import { AppState } from '../../angular-wallet-base/store/appState';
import { SubscriptionListenerComponent } from '../../angular-wallet-base/SubscriptionListenerComponent';

@Component({
  selector: 'transaction-detail-modal',
  templateUrl: './transaction-detail-modal.component.html',
  styleUrls: [ './transaction-detail-modal.component.scss' ],
})
export class TransactionDetailModalComponent extends SubscriptionListenerComponent implements OnInit {
  @Input() transaction: TransactionSummary;

  constructor(public modalController: ModalController,
              public alertController: AlertController,
              protected store: Store<AppState>) {
    super(store);
  }

  async ngOnInit() {}

  async dismiss() {
    return await this.modalController.dismiss();
  }
}
