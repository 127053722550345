import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { RoutingService } from '../../../angular-wallet-base/services/routing.service';
import { SelectAssetModal } from '../../select-asset-modal/select-asset-modal.component';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {

  public isDesktopMode;

  constructor(
    private modalController: ModalController,
    public appMode: AppModeService,
    private nav: NavController,
    protected store: Store<AppState>,
    private routingService: RoutingService,

  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  ngOnInit() {
  }

  async selectAssetModal() {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();

    let cssClass = '';
    cssClass += (this.isDesktopMode) ? 'select-asset-modal-desktop' : 'select-asset-modal';
    cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';

    const modal = await this.modalController.create({
      component: SelectAssetModal,
      componentProps: {
        actionType: 'send'
      },
      cssClass,
      initialBreakpoint: (this.isDesktopMode) ? undefined : 0.9,
      breakpoints: (this.isDesktopMode) ? undefined : [0, 0.6, 0.9, 1],
    });
    await modal.present();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async goToHome(){
    await this.routingService.goHome();
  }

}
