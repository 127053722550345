import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ImagePreloaderDirective } from './image-preloader.directive';

@NgModule({
  declarations: [ImagePreloaderDirective],
  imports: [CommonModule, IonicModule, TranslateModule],
  exports: [ImagePreloaderDirective]
})
export class ImagePreloaderModule { }
