import { AfterViewInit, Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { WalletService } from '../../angular-wallet-base/services/wallet.service';
import { AUTH_DATA_KEY, GRAPH_DATA_HOME, GRAPH_DATA_OF_ALL_TOKENS, GRAPH_DATA_TRADE, PRICE_LIST_OF_TOKENS, WALLET_STORAGE_KEY } from 'src/app/angular-wallet-base/constants';
import { Platform, AlertController, MenuController, ModalController, NavController, ToastController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AUTH_REASON, TWO_FA_CANCELLED, TWO_FA_UNLINK_ACCOUNT } from '../../angular-wallet-base/constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../angular-wallet-base/store/appState';
import { AuthenticateAction, GetAllTokenBalancesAction, PreventAuthOnResumeAction, ResetWalletAction, SetMnemonicAction } from '../../angular-wallet-base/actions/wallet.actions';
import { SetOldPinAction } from '../../angular-wallet-base/actions/setup.actions';
import { StorageService } from '../../angular-wallet-base/services/storage.service';
import { SubscriptionListenerComponent } from '../../angular-wallet-base/SubscriptionListenerComponent';
import { getAuthenticated, getQueryCount } from '../../angular-wallet-base/store/wallet';
import { SendGasStationDataAction } from '../../angular-wallet-base/actions/gasStation.actions';
import { getForceUpgrade } from '../../angular-wallet-base/store/appSettings';
import { TokenInfo } from '../../global';
import { getSendTokenInfo } from '../../angular-wallet-base/store/sendToken';
import { Logger } from '../../angular-wallet-base/services/logger.service';
import { isEnterKey, getToastPosition, encryptData } from '../../angular-wallet-base/utils';
import { LoadUserPreferencesAction, UpdateJwtAction } from '../../angular-wallet-base/actions/userPreferences.actions';
import { SetUserDataAction } from '../../angular-wallet-base/actions/userPreferences.actions';
import { SwitchNetworkAction } from '../../angular-wallet-base/actions/userPreferences.actions';
import { MembersPortalService } from '../../angular-wallet-base/services/members-portal.service';
import { VirtualDebitCardService } from '../../angular-wallet-base/services/virtual-debit-card.service';
import { decryptData } from '../../angular-wallet-base/utils';
import { BiometricAuthService } from '../../angular-wallet-base/services/biometric-auth.service';
import { SendVirtualDebitCardDataAction, SetVirtualDebitCardDataAuthAction } from '../../angular-wallet-base/actions/virtualDebitCard.actions';
import { AppModeService } from '../../angular-wallet-base/services/app-mode.service';
import { CaptureEmailModalComponent } from 'src/app/components/capture-email-modal/capture-email-modal.component';
import { ServiceCheckerService } from 'src/app/angular-wallet-base/services/service-check.service';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { AuthGuardService } from 'src/app/angular-wallet-base/services/auth-guard.service';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { ShowSideMenu } from 'src/app/angular-wallet-base/actions/appSettings.actions';
import { LanguageService } from 'src/app/angular-wallet-base/services/language.service';
import { RoutingService } from '../../angular-wallet-base/services/routing.service';
import { inspect } from 'util';
import { getUnlockKeychain } from 'src/app/angular-wallet-base/lib/pangolin/keyring/keychainUtils';

@Component({
  selector: 'authenticate',
  templateUrl: 'authenticate.page.html',
  styleUrls: ['authenticate.page.scss'],
})
export class AuthenticatePage extends SubscriptionListenerComponent implements OnInit, AfterViewInit {
  @ViewChild('pinInput') pinInput;
  @Input() auth_reason: string;
  @Input() authReason: string;
  public showPinError = false;
  public pinErrorMessage: string = '';
  public pageTitle = '';
  public authenticateTitle = '';
  public bauthTextLineOne = '';
  public bauthTextLineTwo = '';
  public pin: string = '';
  public showLoader: boolean = false;
  @HostBinding('class.isShowPhrase') isShowPhrase: boolean;
  // public authReason;
  public url;
  public queryParams: any;
  public authenticated: boolean;
  private sendTokenInfo: TokenInfo;
  private isLodeLinked: boolean;
  private queryCount;
  private bauthActive: boolean;
  private bauthType;
  private fallbackToPin: boolean;
  private shouldActiveBauth: boolean;
  public isDesktopMode: boolean = false;
  public upgradeInProgress: boolean = false;
  private password: string;
  public passwordType: string = 'password';
  public passwordIcon: string = 'eye';
  public showBackButton: boolean = false;
  public showForgotPin: boolean = false;
  public returnObject = {
    url: null,
    type: 'root' || 'forward' || 'null',
    queryParams: null,
    urlParams: null,
    skipLocationChange: false,
    replaceUrl: false
  };

  constructor(
    private walletService: WalletService,
    private nav: NavController,
    private translate: TranslateService,
    protected store: Store<AppState>,
    private storage: StorageService,
    private authService: AuthService,
    private menu: MenuController,
    private membersPortalService: MembersPortalService,
    private modalController: ModalController,
    private toast: ToastController,
    private bauth: BiometricAuthService,
    private checkerServices: ServiceCheckerService,
    private appMode: AppModeService,
    private authGuardService: AuthGuardService,
    private platform: Platform,
    private languageService: LanguageService,
    private routingService: RoutingService
  ) {
    super(store);
    // this.authReason = this.route.snapshot.params[`reason`];
    // Logger.info('auth reason:', this.authReason);

  }

  async ngOnInit() {
    if (this.authReason) {
      this.showBackButton = true;
      if (this.authReason === AUTH_REASON.UPDATE_PASSWORD) {
        // close update password modal
        try {
          this.modalController.dismiss();
        } catch (error) { }
      }
    } else {
      this.showForgotPin = true;
    }
    this.store.dispatch(new ShowSideMenu(false));
    await this.languageService.setInitialAppLanguage();

    if (this.auth_reason) {
      this.authReason = this.auth_reason;
    }
    Logger.info('auth reasonnnnnnnn:', this.authReason);

    this.subscriptions.add(this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode));
    this.subscriptions.add(this.store.select(getAuthenticated).subscribe(authenticated => this.authenticated = authenticated));
    this.subscriptions.add(this.store.select(getSendTokenInfo).subscribe(tokenInfo => this.sendTokenInfo = tokenInfo));
    this.subscriptions.add(this.store.select(getQueryCount).subscribe(count => this.queryCount = count));
    this.subscriptions.add(this.store.select(getForceUpgrade).subscribe(forceUpgrade => this.upgradeInProgress = forceUpgrade));
    await this.setTitle();
    this.bauthActive = await this.bauth.isActive();

    await this.menu.enable(false, 'sideMenu');

    if (!this.shouldShowPin()) {
      let bauthPin;
      this.bauthType = await this.bauth.getAuthenticationMethod();
      this.authenticateTitle = await this.translate.get('authenticate.bauth_title').toPromise();
      this.bauthTextLineOne = await this.translate.get(this.bauthType === 'face' ? 'authenticate.bauth_face_login_line_one' : 'authenticate.bauth_login_line_one').toPromise();
      this.bauthTextLineTwo = await this.translate.get(this.bauthType === 'face' ? 'authenticate.bauth_face_login_line_two' : 'authenticate.bauth_login_line_two').toPromise();
      try {
        bauthPin = await this.bauth.getPin();
      } catch (err) {
        Logger.info('ERR', err);
        await this.setTitle();
        this.fallbackToPin = true;
        return;
      }
      this.handlePin(bauthPin);
    }
  }

  ngAfterViewInit() {
    this.pin = '';
    this.showLoader = false;
  }

  async setTitle() {
    switch (this.authReason) {
      case AUTH_REASON.RESET_WALLET:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.reset_wallet').toPromise();
        break;

      case AUTH_REASON.SHOW_RECOVERY_PHRASE:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.show_recovery_phrase').toPromise();
        this.isShowPhrase = !this.isShowPhrase;
        break;

      case AUTH_REASON.LINK_LODE_ACCOUNT:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.confirm_to_lode_account').toPromise();
        break;

      case AUTH_REASON.LOGIN_LODE_ACCOUNT:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.confirm_to_lode_account').toPromise();
        break;

      case AUTH_REASON.UPDATE_PASSWORD:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.update_password').toPromise();
        break;

      case AUTH_REASON.AUTH_SEND_TOKENS:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.send_tokens').toPromise();
        break;

      case AUTH_REASON.TWO_FA_UNLINK:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.two_fa_unlink').toPromise();
        break;

      case AUTH_REASON.ACTIVATE_BIOMETRIC:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.activate_biometric').toPromise();
        break;

      case AUTH_REASON.DEACTIVATE_BIOMETRIC:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.deactivate_biometric').toPromise();
        break;

      case AUTH_REASON.GAS_STATION_SEND:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.gas_station_send').toPromise();
        break;

      case AUTH_REASON.VIRTUAL_DEBIT_CARD_CREATE:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.virtual_debit_card_create').toPromise();
        break;

      case AUTH_REASON.VIRTUAL_DEBIT_CARD_FUND:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.virtual_debit_card_fund').toPromise();
        break;

      case AUTH_REASON.VIRTUAL_DEBIT_CARD_SHOW_DETAIL:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.virtual_debit_card_show_detail').toPromise();
        break;

      case AUTH_REASON.CHANGE_NETWORK:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.change_network').toPromise();
        break;

      case AUTH_REASON.CHANGE_PIN:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.change_pin').toPromise();
        break;

      case AUTH_REASON.RE_AUTHENTICATE:
        this.pageTitle = await this.translate.get('authenticate.label_login_pin').toPromise();
        this.authenticateTitle = await this.translate.get('authenticate.reauthenticate').toPromise();
        break;

      default:
        this.translate.stream('authenticate.label_login_pin').subscribe(
          value => {
            this.pageTitle = value;
          }
        );
        this.translate.stream('authenticate.enter_pin').subscribe(
          value => {
            this.authenticateTitle = value;
          }
        );
        break;
    }
  }

  shouldShowPin() {
    return (
      !this.bauthActive
      || (this.bauthActive && this.authReason === AUTH_REASON.DEACTIVATE_BIOMETRIC)
      || this.fallbackToPin
    );
  }

  // handle pin for validating user log status
  async handlePin(pin) {
    const authData = await this.storage.get(AUTH_DATA_KEY);
    const vaultData = await this.storage.get(WALLET_STORAGE_KEY);
    if (authData) {
      await this.handlePinByAuthData(pin);
    }
    if (vaultData && !authData) {
      await this.handlePinByVaultData(pin);
    }
    this.pinInput.handleClear();
  }

  async handlePinByVaultData(pin) {
    this.pin = pin;
    this.showLoader = true;
    const vault = await this.storage.get(WALLET_STORAGE_KEY);

    // Check pin
    let validPass;
    try {
      validPass = await decryptData(this.pin, vault);

      // update jwt token
    if (validPass) {

      this.store.dispatch(new LoadUserPreferencesAction());

      await this.authService.storePin(this.pin);
      await this.walletService.unlockWallet(pin);
      await this.authService.completeSetup();

      this.checkerServices.updateAllServices();
      this.checkerServices.setCheckServicesInterval();
      this.returnObject.url = 'home-page/first-time';
      if (await this.storage.getVaultInfo()) {
        this.returnObject.url = 'home-page/established';
      }
      this.returnObject.type = 'root';
      await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
    } else {

      let errorPinMessage = await this.translate.get('alerts.invalid_input.pin').toPromise();
      throw Error(errorPinMessage);
      
    }
    } catch (error) { 
      Logger.error(inspect(error));
      let errorPinMessage = await this.translate.get('alerts.invalid_input.pin').toPromise();
      setTimeout(() => {
        this.pinErrorMessage = errorPinMessage;
        this.showLoader = false;
        this.showPinError = true;
      }, 250);
    }

    
  }

  async handlePinByAuthData(pin) {
    this.pin = pin;
    this.showLoader = true;
    const vault = await this.storage.get(AUTH_DATA_KEY);

    // Check pin
    let validPass;
    try {
      validPass = await decryptData(this.pin, vault);

      // update jwt token
    if (validPass) {

      this.store.dispatch(new LoadUserPreferencesAction());

      this.checkerServices.updateAllServices();
      this.checkerServices.updateService('gas-station');
      this.checkerServices.updateService('vdc');
      this.checkerServices.setCheckServicesInterval();

      // update token with new password
      if (this.authReason === AUTH_REASON.UPDATE_PASSWORD) {
        // validPass.credentials.password = this.route.snapshot.queryParamMap.get('newPassword');
        validPass.credentials.password = this.queryParams.newPassword;
      }

      let credentials;
      try {
        credentials = await this.updateToken(validPass.credentials.username, validPass.credentials.password);
      } catch (err) {
        // 2FA: some kinda error happened
        return;
      }

      if (credentials === TWO_FA_CANCELLED) {
        return;
      }

      if (this.queryCount === 0) {
        const currentVault = await this.storage.get(WALLET_STORAGE_KEY);
        if (currentVault) {
          const unlockResult = await this.walletService.unlockWallet(pin);
          //if unlockResult is true, instantiate dependent services on wallet for separate ethers Wallet entities
          //having conditional logic in this component to test whether or not this is the first login attempt seems like it can be refactored into a decoupled service.
          if (unlockResult) {
            this.walletService.instantiateWalletServices(pin);
          }
        }
      }

      if (!this.authenticated) {
        this.store.dispatch(new AuthenticateAction());
      }

      if (this.authReason) {
        await this.doNavigate(credentials);
      } else {
        this.returnObject.url = 'home-page/first-time';
        if (await this.storage.getVaultInfo()) {
          this.returnObject.url = 'home-page/established';
        }
        Logger.warn('this.returnObject.urllllllllllll ', this.returnObject.url);
        this.returnObject.type = 'root';
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');

        // this.nav.navigateRoot(this.url);

        // this.route.queryParams.subscribe(async data => {
        // });
      }
    } else {
      
      let errorPinMessage = await this.translate.get('alerts.invalid_input.pin').toPromise();
      throw Error(errorPinMessage.stringify());
    }

    } catch (error) {
      Logger.error(inspect(error));
      let errorPinMessage = await this.translate.get('alerts.invalid_input.pin').toPromise();
      setTimeout(() => {
        this.pinErrorMessage = errorPinMessage;
        this.showLoader = false;
        this.showPinError = true;
      }, 250);
    }

    
  }

  async showError(error) {
    this.pinErrorMessage = error;
    this.showPinError = true;
  }

  async openModal() {
    const modal = await this.modalController.create({
      component: CaptureEmailModalComponent,
      cssClass: 'capture-email-modal',
      id: 'CaptureEmail'
    });
    await modal.present();
  }

  afterTime(valor, multiple) {
    const resto = valor % multiple;
    if (resto === 0) {
      return true;
    } else {
      return false;
    }
  }

  handlePinClear(event) {
    this.showPinError = false;
    this.pinErrorMessage = '';
  }

  async handlePasswordSubmit() {
    await this.handlePin(this.password);
  }

  handlePasswordInputChange(event) {
    if (event.target.name === 'password') {
      this.password = event.target.value;
    }
    if (this.showPinError) {
      this.showPinError = false;
    }
  }

  handleTogglePassword() {
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    this.passwordIcon = this.passwordIcon === 'eye' ? 'eye-off' : 'eye';
  }

  async updateToken(username: string, password: string) {
    let result;
    if (username && password) {
      try {
        result = await this.membersPortalService.updateJwt(username, password);
        // this.membersPortalService.setRefreshInterval(username, password);

        // update guard data
        this.authGuardService.setCredentials({ username, password });
      } catch (err) {
        // 2FA: User clicked on cancel. Reset status.
        this.resetAuthState();
        throw new Error(err);
      }
    }

    if (result === TWO_FA_CANCELLED) {
      this.resetAuthState();
      return result;
    }
    if (result === TWO_FA_UNLINK_ACCOUNT) {
      return result;
    }

    return { username, password };
  }

  private resetAuthState() {
    this.showLoader = false;
    this.pinInput.pin = '';
  }

  async showOrderPlacedToast() {
    const toastComponent = await this.toast.create({
      header: await this.translate.get('gas_station.notifications.order_placed_title').toPromise(),
      message: await this.translate.get('gas_station.notifications.order_initiated_message').toPromise(),
      position: getToastPosition(this.platform),
      duration: 4000
    });
    await toastComponent.present();
  }

  async doNavigate(credentials) {
    switch (this.authReason) {
      case AUTH_REASON.RESET_WALLET:
        this.store.dispatch(new ResetWalletAction());
        this.modalController.dismiss({}, '', 'authPinModal').then(async (_) => await this.routingService.goHome());
        break;

      case AUTH_REASON.SHOW_RECOVERY_PHRASE:
        const vault = await this.storage.get(WALLET_STORAGE_KEY);
        const keychain = await getUnlockKeychain(vault, this.pin);
        this.returnObject.type = null;
        this.authService.setRecoveryPhrase(keychain.keyrings[0].mnemonic);
        this.store.dispatch(new ShowSideMenu(true));
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        break;

      // case AUTH_REASON.AUTH_SEND_TOKENS:
      //   this.store.dispatch(new AuthSendTokensSuccessAction({ pin: this.pin }));
      //   this.subscriptions.add(this.actions$.pipe(
      //     ofType(SendTokenActionTypes.SEND_TOKENS_SUCCESS),
      //     takeUntil(this.destroyed$))
      //     .subscribe(async (action: SendTokensSuccessAction) => {
      //       if (action.payload.txid) {
      //         this.showOrderPlacedToast();
      //       }
      //     }));
      //   break;

      case AUTH_REASON.TWO_FA_UNLINK:
        this.returnObject.type = 'forward';
        this.returnObject.url = '/wallet/settings';
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateForward('/wallet/settings');
        break;

      case AUTH_REASON.UPDATE_PASSWORD:
        // const oldPassword = this.route.snapshot.queryParamMap.get('currentPassword');
        const oldPassword = this.queryParams.currentPassword;
        // const newPassword = this.route.snapshot.queryParamMap.get('newPassword');
        const newPassword = this.queryParams.newPassword;
        const preferences = await this.storage.get(AUTH_DATA_KEY);

        let userData;
        if (preferences) {
          userData = await decryptData(this.pin, preferences);
        }
        if (this.pin && userData && (userData.credentials.password === oldPassword)) {
          const userDetails = {
            pin: this.pin,
            credentials: {
              username: userData.credentials.username,
              password: newPassword
            },
            jwtToken: userData.jwtToken,
            lodeid: userData.lodeid
          };
          const encryptedData = encryptData(this.pin, userDetails);
          await this.storage.set(AUTH_DATA_KEY, encryptedData);
        }
        this.returnObject.type = 'forward';
        this.returnObject.url = '/wallet/settings';
        this.returnObject.queryParams = {
          tab: 'wallet'
        };
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateForward('/wallet/settings', {
        //   queryParams: {
        //     tab: 'wallet'
        //   }
        // });
        break;

      case AUTH_REASON.LINK_LODE_ACCOUNT:
        // this.route.queryParams.subscribe(params => {
        this.store.dispatch(new SetUserDataAction({
          pin: this.pin, jwtToken: this.queryParams.jwtData,
          credentials: {
            username: this.queryParams.username,
            password: this.queryParams.password
          },
          lodeid: this.queryParams.lodeid
        }));
        this.store.dispatch(new UpdateJwtAction({ jwtToken: this.queryParams.jwtData, lodeid: this.queryParams.lodeid }));
        // });
        this.returnObject.type = 'root';
        this.returnObject.url = '/wallet/assets';
        this.returnObject.skipLocationChange = true;
        this.returnObject.replaceUrl = true;
        this.returnObject.queryParams = { action: 'refresh' };
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateRoot('/wallet/assets',
        //   { skipLocationChange: true, replaceUrl: true, queryParams: { action: 'refresh' } });
        break;

      case AUTH_REASON.LOGIN_LODE_ACCOUNT:
        // this.route.queryParams.subscribe(params => {
        this.store.dispatch(new SetUserDataAction({
          pin: this.pin, jwtToken: this.queryParams.jwtData,
          credentials: {
            username: this.queryParams.username,
            password: this.queryParams.password
          },
          lodeid: this.queryParams.lodeid
        }));
        this.store.dispatch(new UpdateJwtAction({ jwtToken: this.queryParams.jwtData, lodeid: this.queryParams.lodeid }));
        // });
        this.returnObject.type = 'root';
        this.returnObject.url = 'account-setup/methods/terms-of-service';
        this.returnObject.skipLocationChange = true;
        this.returnObject.replaceUrl = true;
        this.returnObject.queryParams = { action: 'refresh' };
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateRoot('account-setup/methods/terms-of-service',
        //   { skipLocationChange: true, replaceUrl: true });
        break;

      case AUTH_REASON.RE_AUTHENTICATE:
        if (credentials) {
          await this.membersPortalService.updateJwt(credentials.username, credentials.password);
        }
        this.store.dispatch(new GetAllTokenBalancesAction());
        let goTo = this.url || '';

        if (goTo.indexOf('show-recovery-phrase') !== -1) {
          goTo = '/wallet/assets';
        }
        this.returnObject.type = 'forward';
        this.returnObject.url = goTo;
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateForward(goTo);
        break;

      case AUTH_REASON.ACTIVATE_BIOMETRIC:
        this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: true }));
        try {
          await this.bauth.activateBiometric(this.pin);
        } catch (err) {
        }
        this.returnObject.type = 'forward';
        this.returnObject.url = '/wallet/settings';
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateForward('/wallet/settings');
        break;

      case AUTH_REASON.DEACTIVATE_BIOMETRIC:
        this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: true }));
        try {
          await this.bauth.deactivateBiometric();
        } catch (err) {
        }
        this.returnObject.type = 'forward';
        this.returnObject.url = '/wallet/settings';
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateForward('/wallet/settings');
        break;

      case AUTH_REASON.GAS_STATION_SEND:
        this.store.dispatch(new SendGasStationDataAction({ pin: this.pin }));
        this.showLoader = true;
        this.returnObject.type = null;
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        break;

      case AUTH_REASON.VIRTUAL_DEBIT_CARD_FUND:
        this.store.dispatch(new SendVirtualDebitCardDataAction({ pin: this.pin }));
        this.showLoader = true;
        this.returnObject.type = null;
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        break;

      case AUTH_REASON.VIRTUAL_DEBIT_CARD_CREATE:
        this.store.dispatch(new SendVirtualDebitCardDataAction({ pin: this.pin }));
        this.showLoader = true;
        this.returnObject.type = null;
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        break;

      case AUTH_REASON.VIRTUAL_DEBIT_CARD_SHOW_DETAIL:
        this.store.dispatch(new SetVirtualDebitCardDataAuthAction({ authenticated: true }));
        this.showLoader = true;
        this.returnObject.type = 'forward';
        this.returnObject.url = '/wallet/card';
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateForward(['/wallet/card']);
        break;

      case AUTH_REASON.CHANGE_PIN:
        this.store.dispatch(new SetOldPinAction({ pin: this.pin }));
        this.returnObject.type = 'forward';
        this.returnObject.url = 'setup/change-pin';
        this.returnObject.queryParams = { type: AUTH_REASON.CHANGE_PIN };
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateForward(['/setup/setup-pin', AUTH_REASON.CHANGE_PIN]);
        break;

      case AUTH_REASON.CHANGE_NETWORK:
        // const params = this.route.snapshot.queryParamMap;
        // this.store.dispatch(new SwitchNetworkAction({
        //   pin: this.pin,
        //   networkId: params.get('networkId'),
        //   network: params.get('network'),
        //   chain: params.get('chain')
        // }));
        this.store.dispatch(new SwitchNetworkAction({
          pin: this.pin,
          networkId: this.queryParams.networkId,
          network: this.queryParams.network,
          chain: this.queryParams.chain
        }));
        this.returnObject.type = null;
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        break;

      case AUTH_REASON.ENTER_RECOVERY_PHRASE:
        await this.walletService.completeSetup(this.pin);
        this.returnObject.type = 'root';
        this.returnObject.url = '/setup/success-recovery-phrase';
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateRoot('/setup/success-recovery-phrase');
        break;

      default:
        // TODO NOTE: default login NAVIGATION experience is handled by AddressQuerySuccess action in wallet effects to ensure
        //       a good user-data-loading experience. Consider refactoring to a redux saga in the future.
        //       DO NOT ADD NAVIGATION HERE!!!!

        // TODO 2: we need to add this pattern to wallet setup/recover too as it has the '0' balance flash issue
        break;
    }
  }

  onKeyUp(ev) {
    if (isEnterKey(ev)) {
      this.handlePasswordSubmit();
    }
  }

  async onBackButton() {
    switch (this.authReason) {
      case AUTH_REASON.UPDATE_PASSWORD:
        this.returnObject.type = null;
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        break;
      case AUTH_REASON.ACTIVATE_BIOMETRIC:
        this.returnObject.type = null;
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        break;
      case AUTH_REASON.RESET_WALLET:
        this.returnObject.type = 'root';
        this.returnObject.url = '/wallet/settings';
        this.returnObject.queryParams = { tab: 'wallet' };
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        // await this.nav.navigateRoot('/wallet/settings', {
        //   queryParams: { tab: 'wallet' }
        // });
        break;
      case AUTH_REASON.CHANGE_PIN:
        this.returnObject.type = 'root';
        this.returnObject.url = '/wallet/settings';
        this.returnObject.queryParams = { tab: 'wallet' };
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');

        // await this.nav.navigateRoot('/wallet/settings', {
        //   queryParams: { tab: 'wallet' }
        // });
        break;
      case AUTH_REASON.SHOW_RECOVERY_PHRASE:
        this.returnObject.type = 'root';
        this.returnObject.url = '/wallet/settings';
        this.returnObject.queryParams = { tab: 'wallet' };
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');

        // await this.nav.navigateRoot('/wallet/settings', {
        //   queryParams: { tab: 'wallet' }
        // });
        break;

      case AUTH_REASON.ENTER_RECOVERY_PHRASE:
        this.returnObject.type = null;
        await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
        break;
      default:
        await this.nav.back();
    }
  }

  async confirmRestore() {
    await this.nav.navigateForward('/setup/forgot-pin');
  }

  async forgetPassword() {
    this.returnObject.type = 'forward';
    this.returnObject.url = 'forgot-pin/info';
    await this.modalController.dismiss({ returnObject: this.returnObject }, '', 'authPinModal');
  }
}
