import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalRequestComponent } from './modal-request.component';
import { SmallToCryptoPipeModule } from '../../angular-wallet-base/pipes/smalltocrypto/smalltocrypto.module';

@NgModule({
  declarations: [ModalRequestComponent],
  exports: [
    ModalRequestComponent
  ],
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule, SmallToCryptoPipeModule]
})
export class ModalRequestComponentModule {}
