import { Injectable } from "@angular/core";
import * as ethers from "ethers";
import { signTypedData, SignTypedDataVersion } from "@metamask/eth-sig-util";
import { utils, providers, Wallet, Contract, BigNumber } from "ethers";
import { WALLET_STORAGE_KEY } from "../constants";
import { StorageService } from "./storage.service";
import { NetworkService } from "./network.service";
import { Store } from "@ngrx/store";
import { AppState } from "../store/appState";
import { getWalletConnectSelectedNetwork, IWalletConnectSelectedNetwork } from "../store/walletConnect";
import { environment } from "../../../environments/environment";
import { ENTROPY_KEY, MNEMONIC_KEY, DEFAULT_ACTIVE_INDEX, DEFAULT_CHAIN_ID } from "../lib/wallet-connect/constants/default";
import { hdPaths } from "../lib/pangolin/keyring";
import { getUnlockKeychain } from "../lib/pangolin/keyring/keychainUtils";
import { EvmNetworkService } from "./evm-network.service";

@Injectable({
  providedIn: "root"
})
export class WalletConnectWalletControllerService {
  public path: string;
  public entropy: string;
  public mnemonic: string;
  public wallet: Wallet;

  private provider: providers.BaseProvider;
  private walletConnectSelectedNetwork: IWalletConnectSelectedNetwork;

  public activeIndex: number = DEFAULT_ACTIVE_INDEX;
  public activeChainId: number = DEFAULT_CHAIN_ID;

  constructor(private storage: StorageService, 
    private networkService : NetworkService, 
    private store: Store<AppState>) {
    this.store.select(getWalletConnectSelectedNetwork).subscribe(walletConnectSelectedNetwork => this.walletConnectSelectedNetwork = walletConnectSelectedNetwork);
  }

  /**
   * Generate an ethers instance of the wallet for signing transactions
   * @param pin the pin/password to unlock the wallet
   * @returns
   */
  public async generateWallet(pin): Promise<ethers.Wallet> {
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getUnlockKeychain(vault, pin);
    //TODO: This will need to be more flexible when we support multiple chains
    const walletFromMnemonic = Wallet.fromMnemonic(keychain.keyrings[0].mnemonic, environment.production ? hdPaths.avaxMainnnet : hdPaths.avaxTestnet);
    this.provider = new providers.JsonRpcProvider(this.walletConnectSelectedNetwork.rpcUrl);
    return walletFromMnemonic.connect(this.provider);
  }

  public isActive() {
    if (!this.wallet) {
      return this.wallet;
    }
    return null;
  }

  public getWalletConnectSelectedNetwork() {
    return this.walletConnectSelectedNetwork;
  }

  public getIndex() {
    return this.activeIndex;
  }

  public async init({ index = DEFAULT_ACTIVE_INDEX, chainId = DEFAULT_CHAIN_ID, pin }: {index?: number, chainId?: number, pin?: any}): Promise<Wallet> {
    return await this.update(index, chainId, pin);
  }

  public async update(index: number, chainId: number, pin): Promise<Wallet> {
    const firstUpdate = typeof this.wallet === "undefined";
    this.activeIndex = index;
    this.activeChainId = chainId;
    this.wallet = await this.generateWallet(pin);
    if (!firstUpdate) {
      // update another controller if necessary here
    }
    return this.wallet;
  }

  public async populateTransaction(transaction: any) {
    let tx = Object.assign({}, transaction);
    if (this.wallet) {
      if (tx.gas) {
        tx.gasLimit = tx?.gas;
        delete tx.gas;
      }
      if (tx.from) {
        tx.from = ethers.utils.getAddress(tx?.from);
      }

      try {
        tx = await this.wallet.populateTransaction(tx);
        tx.gasLimit = tx?.gasLimit && ethers.BigNumber.from(tx.gasLimit).toHexString();
        tx.gasPrice = tx?.gasPrice && ethers.BigNumber.from(tx.gasPrice).toHexString();
        tx.nonce = tx?.nonce && ethers.BigNumber.from(tx.nonce).toHexString();
      } catch (err) {
        console.error("Error populating transaction", tx, err);
      }
    }

    return tx;
  }

  public async sendTransaction(transaction: any) {
    if (this.wallet) {
      if (transaction.from && transaction.from.toLowerCase() !== this.wallet.address.toLowerCase()) {
        console.error("Transaction request From doesn't match active account");
      }

      if (transaction.from) {
        delete transaction.from;
      }

      // ethers.js expects gasLimit instead
      if ("gas" in transaction) {
        transaction.gasLimit = transaction.gas;
        delete transaction.gas;
      }

      const result = await this.wallet.sendTransaction(transaction);
      return result.hash;
    } else {
      console.error("No Active Account");
    }
    return null;
  }

  public async signTransaction(data: any) {
    if (this.wallet) {
      if (data && data.from) {
        delete data.from;
      }
      data.gasLimit = data.gas;
      delete data.gas;
      const result = await this.wallet.signTransaction(data);
      return result;
    } else {
      console.error("No Active Account");
    }
    return null;
  }

  public async signMessage(data: any) {
    if (this.wallet) {
      const signingKey = new ethers.utils.SigningKey(this.wallet.privateKey);
      const sigParams = await signingKey.signDigest(ethers.utils.arrayify(data));
      const result = await ethers.utils.joinSignature(sigParams);
      return result;
    } else {
      console.error("No Active Account");
    }
    return null;
  }

  public async signPersonalMessage(message: any) {
    if (this.wallet) {
      const result = await this.wallet.signMessage(ethers.utils.isHexString(message) ? ethers.utils.arrayify(message) : message);
      return result;
    } else {
      console.error("No Active Account");
    }
    return null;
  }

  public async signTypedData(data: any) {
    if (this.wallet) {
      const result = signTypedData({
        privateKey: Buffer.from(this.wallet.privateKey.slice(2), "hex"),
        data: JSON.parse(data),
        version: SignTypedDataVersion.V4
      });
      return result;
    } else {
      console.error("No Active Account");
    }
    return null;
  }
}
