import { inject, Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import { Effect } from "@ngrx/effects";
import { Action, ActionReducer } from "@ngrx/store";
import { defer, from, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { WALLET_STORAGE_KEY } from "../constants";
import { Logger } from "../services/logger.service";
import { StorageService } from "../services/storage.service";

const STORAGE_KEY = "NSIS_APP_STATE";

// get/setNested inspired by
// https://github.com/mickhansen/dottie.js
function getNested(obj: any, path: string): any {
  if (obj !== null && path) {
    // Recurse into the object.
    const parts = path.split(".").reverse();
    while (obj != null && parts.length) {
      obj = obj[parts.pop()];
    }
  }
  return obj;
}

function setNested(obj: any, path: string, value: any): any {
  if (obj != null && path) {
    let pieces = path.split("."),
      current = obj,
      piece,
      i,
      length = pieces.length;

    for (i = 0; i < length; i++) {
      piece = pieces[i];
      if (i === length - 1) {
        current[piece] = value;
      } else if (!current[piece]) {
        current[piece] = {};
      }
      current = current[piece];
    }
  }

  return obj;
}

export async function fetchState(storageService: StorageService): Promise<any> {
  //if storage is not created yet, return empty object
  if (!storageService.getStorage()) {
    return Promise.resolve({});
  }
  try { 
    return storageService.get(STORAGE_KEY);
  } catch (err) { 
      Logger.error("Storage: Error fetching state", err);
  }
}

function saveState(state: any, keys: string[], storageService: StorageService): Promise<void> {
  // Pull out the portion of the state to save.
  if (keys) {
    state = keys.reduce((acc, k) => {
      const val = getNested(state, k);
      if (val) {
        setNested(acc, k, val);
      }
      return acc;
    }, {});
  }
  //return promise void
  Logger.info("Storage: Saved state", state);
  return storageService.set(STORAGE_KEY, state);
}

export const StorageSyncActions = {
  HYDRATE: "NSIS_APP_HYDRATE",
  HYDRATED: "NSIS_APP_HYDRATED",
};

export interface StorageSyncOptions {
  keys?: string[];
  ignoreActions?: string[];
  hydratedStateKey?: string;
  onSyncError?: (err: any) => void;
}

const defaultOptions: StorageSyncOptions = {
  keys: [],
  ignoreActions: [],
  onSyncError: (err) => {},
};

export function storageSync(options?: StorageSyncOptions) {
  const { keys, ignoreActions, hydratedStateKey, onSyncError } = Object.assign({}, defaultOptions, options || {});

  ignoreActions.push(StorageSyncActions.HYDRATE);
  ignoreActions.push(StorageSyncActions.HYDRATED);
  ignoreActions.push("@ngrx/store/init");
  ignoreActions.push("@ngrx/effects/init");
  ignoreActions.push("@ngrx/store/update-reducers");

  const hydratedState: any = {};

  return function storageSyncReducer(reducer: ActionReducer<any, Action>, storageService: StorageService) {
    return (state: any, action: any) => {
      if (storageService?.getStorage()) {
        const { type, payload } = action;

        if (type === StorageSyncActions.HYDRATED) {
          state = Object.assign({}, state, payload);
          if (hydratedStateKey) {
            hydratedState[hydratedStateKey] = true;
          }
        }

        const nextState = Object.assign({}, reducer(state, action), hydratedState);

        if (ignoreActions.indexOf(type) === -1) {
          saveState(nextState, keys, storageService).catch((err) => onSyncError(err));
        }
        return nextState;
      } else {
        return reducer(state, action);
      }
    };
  };
}
