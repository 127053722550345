import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../angular-wallet-base/store/appState';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../../angular-wallet-base/services/logger.service';
import { CodePush, DownloadProgress, InstallMode, IRemotePackage } from '@ionic-native/code-push/ngx';

@Component({
  selector: 'codepush-modal',
  templateUrl: './codepush-modal.component.html',
  styleUrls: ['./codepush-modal.component.scss'],
})
export class CodePushModalComponent implements OnInit {
  @Input() remotePackage: IRemotePackage;
  downloadProgress: DownloadProgress;
  public downloadProgressPercentage = 0;

  constructor(public modalController: ModalController,
              public store: Store<AppState>,
              public platform: Platform,
              private translate: TranslateService,
              private codePush: CodePush,
              private zone: NgZone) {
  }

  ngOnInit() {
    this.checkCodePush();
  }

  checkCodePush() {
    this.codePush.sync({
      installMode: InstallMode.IMMEDIATE
    }, (progress) => this.updateDownloadProgress(progress)).subscribe(
      (data) => {
        Logger.info('CODE PUSH SUCCESSFUL: ' + data);
      },
      (err) => {
        Logger.info('CODE PUSH ERROR: ' + err);

      }
    );
  }

  async updateDownloadProgress(progress: DownloadProgress) {
    this.downloadProgress = progress;
    await this.zone.run(() => {
      this.downloadProgressPercentage = this.downloadProgress.receivedBytes / this.downloadProgress.totalBytes;
    });
  }

  async dismiss() {
    // Don't allow backing out of this modal
    return;
  }

}
