// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  ethNetwork: 'eth-sepolia',
  sysNetwork: 'sys-test',
  btcNetwork: 'btc-test',
  ga: 'G-SE9B203FC6',
  sentryDsn: null,
  features: {
    agx: true,
    aux: true,
    cryptoPayment: true,
    epin: true,
    bauth: true,
    linkLode: true,
    eth: true,
    avax: true,
    sol: false,
    sysnevm: true,
    hl: false,
    gasStation: true,
    debitCard: false,
    dust: true,
    multicoin: false,
    kycCheck: false,
    versionCheck: true,
    networkSwitcher: true,
    componentSheet: false,
    btc: true,
    bankAccounts: false,
    aggregateTokens: true,
    customGasTextBox: false,
    sentry: false,
    sys: true,
    trade: true,
    walletConnect: true,
    move: false,
    otc3: true
  },
  version_releases: {
    playstore_link: 'https://play.google.com/store/apps/details?id=one.lode.lodepay&hl=en_US',
    appstore_link: 'https://apps.apple.com/ca/app/agxpay-mobile/id1483976654'
  },
  USER_PREFERENCES_STORAGE_KEY: 'wallet_userprefs',
  AGX_GUID: '341906151',
  AGX_KEYRING_ID: 'SYS-341906151',
  AUX_GUID: '2899587689',
  AUX_KEYRING_ID: 'SYS-2899587689',
  EMAIL_CAPTURE_ATTEMPTS: 5,
  SOLANA_HISTORY_LIMIT: 7,
  PLAID_SERVICE_REDIRECT_IOS: 'ionic://localhost/prime-intro',
  PLAID_SERVICE_REDIRECT_ANDROID: 'http://localhost/prime-intro',
  PLAID_SERVICE_REDIRECT_BROWSER: 'https://wallet-v3.lode.tech/prime-intro',
  EXTERNAL_APIS: {
    PREVIOUS_SYSNET_BB_URL: 'https://sys-41.lode.tech/address/',
    MEMBERS_PORTAL: 'https://members.lode.tech/api',
    MEMBERS_PORTAL_BANK_API: 'https://api.lode.tech/exchange',
    COMPLIANCE: 'https://api.lode.tech/compliance',
    SIMPLEX: 'https://simplex-api.lod3wallet.com',
    GAS_STATION: 'https://gas-station-v3.lode.tech',
    VERSION_URL: 'https://s3-us-west-2.amazonaws.com/dl.lode.one/version/lodepay_version_staging.json',
    VIRTUAL_DEBIT_CARD: 'https://virtual-cards.lode.tech',
    MEMBER_PORTAL_HOME: 'https://members.lode.tech',
    STATUS_CHECK_API: 'https://status.lode.tech',
    BLOCKCHAIN_SERVICE_API: 'https://blockchain.lode.tech',
    EXPLORERS: [
      {
        id: 'sys-main',
        chain: 'SYS',
        chainId: 57,
        network: 'mainnet',
        name: 'Syscoin mainnet',
        shortName: 'Mainnet',
        URL: 'https://sys.lode.tech',
        TOKEN_DEFINITIONS: 'https://raw.githubusercontent.com/blockchainfoundryinc/spt-descriptions/master/spt.json',
        SOCKET_URL: 'https://sys.lode.tech',
        SPT_SOCKET_URL: 'https://sys-ws.lode.tech',
        isSmartContractChain: false
      }, 
      {
        id: 'sys-test',
        chain: 'SYS',
        chainId: 57,
        network: 'testnet',
        name: 'Syscoin Testnet',
        shortName: 'Testnet',
        URL: 'https://sys-test.lode.tech',
        TOKEN_DEFINITIONS: 'https://raw.githubusercontent.com/blockchainfoundryinc/spt-descriptions/master/spt.json',
        SOCKET_URL: 'https://sys-test.lode.tech',
        SPT_SOCKET_URL: 'https://sys-test-ws.lode.tech',
        isSmartContractChain: false
      },
      {
        id: 'sysnevm-main',
        chain: 'SYSNEVM',
        chainId: 570,
        network: 'mainnet',
        name: 'Syscoin NEVM mainnet',
        shortName: 'Mainnet',
        URL: 'https://rpc.rollux.com',
        RPC_URL: 'https://rpc.rollux.com',
        GAS_PRICE: 'https://gavax.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle',
        ETHERSCAN_URL: 'https://explorer.rollux.com',
        SOCKET_URL: 'wss://rollux.rpc.syscoin.org/wss',
        isSmartContractChain: true,
        API_EXPLORER : 'https://explorer.rollux.com/',
        hdPath: `m/44'/60'/0'/0`,
      },
      {
        id: 'sysnevm-test',
        chain: 'SYSNEVM',
        chainId: 57000,
        network: 'testnet',
        name: 'Syscoin NEVM Testnet',
        shortName: 'Testnet',
        URL: 'https://rpc-tanenbaum.rollux.com',
        RPC_URL: 'https://rpc-tanenbaum.rollux.com',
        GAS_PRICE: 'https://gavax.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle',
        ETHERSCAN_URL: 'https://rollux.tanenbaum.io',
        SOCKET_URL: 'wss://rpc-tanenbaum.rollux.com/wss',
        isSmartContractChain: true,
        API_EXPLORER : 'https://rollux.tanenbaum.io/',
        hdPath: `m/44'/1'/0'/0/0`,
      },
      {
        id: 'eth-main',
        chain: 'ETH',
        chainId: 1,
        network: 'mainnet',
        name: 'Ethereum mainnet',
        shortName: 'Mainnet',
        URL: 'https://prettiest-tiniest-water.quiknode.pro/94d85f9859d94fbc98ab543f12681806ff5a589c',
        RPC_URL: 'https://prettiest-tiniest-water.quiknode.pro/94d85f9859d94fbc98ab543f12681806ff5a589c',
        GAS_PRICE: 'https://beaconcha.in/api/v1/execution/gasnow',
        ETHERSCAN_URL: 'https://api.etherscan.io/',
        SOCKET_URL: 'wss://prettiest-tiniest-water.quiknode.pro/94d85f9859d94fbc98ab543f12681806ff5a589c',
        isSmartContractChain: true,
        API_EXPLORER : 'https://etherscan.io/',
        hdPath: `m/44'/60'/0'/0`,
      }, {
        id: 'eth-sepolia',
        chain: 'ETH',
        chainId: 11155111,
        network: 'testnet',
        shortName: 'Sepolia',
        name: 'Ethereum Sepolia testnet',
        URL: 'https://sepolia.infura.io/v3/2388d2ccb54e4a0fa2cba61d14ca3585',
        RPC_URL: 'https://sepolia.infura.io/v3/2388d2ccb54e4a0fa2cba61d14ca3585',
        SOCKET_URL: 'wss://sepolia.infura.io/ws/v3/2388d2ccb54e4a0fa2cba61d14ca3585',
        isSmartContractChain: true,
        ETHERSCAN_URL: 'https://api-sepolia.etherscan.io/',
        GAS_PRICE: 'https://etherchain.org/api/gasnow',
        API_EXPLORER: 'https://sepolia.etherscan.io/',
        hdPath: `m/44'/1'/0'/0/0`,
      },
      {
        id: 'sys-test',
        chain: 'SYS',
        network: 'testnet',
        name: 'Syscoin Testnet',
        shortName: 'Testnet',
        URL: 'https://sys-test.lode.tech',
        TOKEN_DEFINITIONS: 'https://raw.githubusercontent.com/blockchainfoundryinc/spt-descriptions/master/spt.json',
        SOCKET_URL: 'https://sys-test.lode.tech',
        SPT_SOCKET_URL: 'https://sys-test-ws.lode.tech',
        isSmartContractChain: false
      }, {
        id: 'btc-main',
        chain: 'BTC',
        network: 'mainnet',
        name: 'Bitcoin mainnet',
        shortName: 'Mainnet',
        URL: 'https://btcbook.nownodes.io/api/v2',
        EXPLORER_URL: 'https://blockexplorers.nownodes.io/bitcoin',
        URL_2: 'https://api.blockcypher.com/v1/btc/main',
        SOCKET_URL: 'https://btc1.bcfn.ca',
        hdPath: `m/84'/0'/0'/0'`,
        nowNodeAPIKey: '99d36e2c-cccc-42a3-a530-ddeaa89faa8a',
        isSmartContractChain: false,
      },
      {
        id: 'btc-test',
        chain: 'BTC',
        network: 'testnet',
        name: 'Bitcoin testnet',
        shortName: 'Testnet',
        URL: 'https://btcbook-testnet.nownodes.io/api/v2',
        URL_2: 'https://api.blockcypher.com/v1/btc/test3',
        SOCKET_URL: 'https://btc1-testnet.bcfn.ca',
        isSmartContractChain: false,
        ETHERSCAN_URL: 'https://blockstream.info/',
        hdPath: `m/84'/1'/0'/0'`,
        nowNodeAPIKey: '99d36e2c-cccc-42a3-a530-ddeaa89faa8a'
      },
      {
        id: 'avax-main',
        chain: 'AVAX',
        chainId: 43114,
        network: 'mainnet',
        name: 'Avalanche mainnet',
        URL: 'api.avax.network',
        SNOWTRACE_URL: 'https://api.snowtrace.io',
        // RPC_URL: 'https://shy-small-sun.avalanche-mainnet.quiknode.pro/54189497e39a642e32d57998bfd7b6bd1dfc401f/ext/bc/C/rpc',
        RPC_URL: 'https://avalanche-c-chain-rpc.publicnode.com',
        API_EXPLORER: 'https://snowtrace.io',
        // SOCKET_URL: 'wss://shy-small-sun.avalanche-mainnet.quiknode.pro/54189497e39a642e32d57998bfd7b6bd1dfc401f',
        SOCKET_URL: 'wss://avalanche-c-chain-rpc.publicnode.com',
        GAS_PRICE: 'https://gavax.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle',
        GSD_ORACLE_ADDRESS: '0x282e5703B4F5B1112CE672DAc11FF2EF0aBd235d',
        ONEINCH_API_URL: 'https://api.1inch.io/v4.0/43114',
        ONEINCH_LIMIT_ORDER_URL: 'https://limit-orders.1inch.io/v2.0/43114/limit-order',
        ONEINCH_BROADCAST_URL: 'https://tx-gateway.1inch.io/v1.1/43114/broadcast',
        ONEINCH_CONTRACT_ADDRESS: '0x0F85A912448279111694F4Ba4F85dC641c54b594',
        GRAPHQL_EXCHANGE_URL: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/exchange',
        GRAPHQL_CANDLE_URL: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/dexcandles',
        NATIVE_TOKEN_ADDRESS: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        REFERRER_ADDRESS: '0xE322264678d7f419Fd5d3B508ca0D3F5045250C0',
        ADDITIONAL_GAS_LIMIT: 20000,
        isSmartContractChain: true,
        hdPath: `m/44'/60'/0'/0`,
      }, {
        id: 'avax-test',
        chain: 'AVAX',
        chainId: 43113,
        network: 'testnet',
        shortName: 'Fuji',
        name: 'AVAX Fuji testnet',
        URL: 'api.avax-test.network',
        SNOWTRACE_URL: 'https://api-testnet.snowtrace.io',
        RPC_URL: 'https://api.avax-test.network/ext/bc/C/rpc',
        API_EXPLORER: 'https://testnet.snowtrace.io',
        SOCKET_URL: 'https://api.lode.tech/web3-socket',
        GAS_PRICE: 'https://gavax.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle',
        GSD_ORACLE_ADDRESS: '0x282e5703B4F5B1112CE672DAc11FF2EF0aBd235d',
        ONEINCH_API_URL: 'https://api.1inch.io/v4.0/43114', // its mainnet (1inch dont support testnet)
        ONEINCH_LIMIT_ORDER_URL: 'https://limit-orders.1inch.io/v2.0/43114/limit-order', // its mainnet (1inch dont support testnet)
        ONEINCH_BROADCAST_URL: 'https://tx-gateway.1inch.io/v1.1/43114/broadcast', // its mainnet (1inch dont support testnet)
        ONEINCH_CONTRACT_ADDRESS: '0x0F85A912448279111694F4Ba4F85dC641c54b594', // its mainnet (1inch dont support testnet)
        GRAPHQL_EXCHANGE_URL: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/exchange-fuji',
        GRAPHQL_CANDLE_URL: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/dexcandles-fuji',
        NATIVE_TOKEN_ADDRESS: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
        REFERRER_ADDRESS: '0xE322264678d7f419Fd5d3B508ca0D3F5045250C0',
        ADDITIONAL_GAS_LIMIT: 20000,
        isSmartContractChain: true,
        hdPath: `m/44'/1'/0'/0/0`,
      }
    ]
  },
  ETHERSCAN_API_KEY: 'SQR1M83ABI43R5TKPUUJ1S4I6R6HNU5VE8',
  SNOWTRACE_API_KEY: 'UUJH6T79FVT4MPGF2MSP7ZUTDRX3PK1SC8',
  TRADER_JOE_ROUTER_ADDRESS: '0xd7f655E3376cE2D7A2b08fF01Eb3B1023191A901',
  TRADER_JOE_V2_ROUTER_ADDRESS: '0xb4315e873dBcf96Ffd0acd8EA43f689D8c20fB30',
  TRADER_JOE_V2_QUOTER_ADDRESS: '0xd76019A16606FDa4651f636D9751f500Ed776250',
  TRADER_JOE_V2_2_ROUTER_ADDRESS: '0x18556DA13313f3532c54711497A8FedAC273220E',
  TRADER_JOE_V2_2_QUOTER_ADDRESS: '0x9A550a522BBaDFB69019b0432800Ed17855A51C3',
  OTC3_ADDRESS: "0x5C08effCEb20305bE4b31B72D690477D8EB29774",
  ONEINCH_ROUTER_ADDRESS: '0x1111111254fb6c44bAC0beD2854e76F90643097d',
  SERUM_PROGRAM_ADDRESS: '9xQeWvG816bUx9EPjHmaT23yvVM2ZWbrrpZb9PusVFin',
  TRADE_MARKET_ADDRESS: {
    AGX_USDC: {
      AMM_ID: '48XXbh3KHoxVLKZ6EYQsiFpwdp8TDUjBi6gBtzMJnour',
      MARKET_ID: 'FLntpJBYvHfq4wPacu1pR9rZ5kUDH1xfwhLvCpck5EgV',
    },
    AUX_USDC: {
      AMM_ID: 'HQKDdqorVGfRMqJKYgENxaJHZtfjTrsSVBnZYMixEXWp',
      MARKET_ID: '3QcV1JdPpXZZvD1oCKz7HmCrugTWbk9YyM3Ug3bE4Gdn'
    },
    SOL_USDC: {
      AMM_ID: '58oQChx4yWmvKdwLLZzBi4ChoCc2fqCUWBkwMihLYQo2',
      MARKET_ID: '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT'
    }
  },
  SIMPLEX_URL: 'https://cdn.simplex.com/sdk/v1/js/sdk.js',
  PLAID_CONFIG: {
    apiVersion: 'v2',
    env: 'development',
    selectAccount: false,
    token: null,
    webhook: '',
    countryCodes: ['US']
  },
  EXCHANGE_RATE_URL: 'https://api.exchangerate.host',
  SIMPLEX_PRODUCT_KEY: 'pk_live_f16819ae-f09b-49dc-8029-3eb01a167812',
  SIMPLEX_PARTNER: 'lode',
  MORALIS_API_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjhjNWE2YmE4LTJmNTctNDg1OC1hZjI0LWZkNjY3ODJkYzhjYiIsIm9yZ0lkIjoiMTcxMTEyIiwidXNlcklkIjoiMTcwNzgzIiwidHlwZUlkIjoiOGU1ODU0NDYtNTAxYS00MmYwLThhZTYtMDY5ZDJmYmIwZDRjIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2OTA4MjYwMTQsImV4cCI6NDg0NjU4NjAxNH0.fqOiJKijBxBT1hIOCBc7D9IH1OwQBfm-6xAI0AWLscQ',
  NIKO_SUBSCRIPTION_URL: 'https://one.us17.list-manage.com/subscribe/post-json?u=292e6639ebdf2262e029a0d70&amp;id=f234ddf2e5&amp;f_id=00664de0f0&EMAIL=',
  INTERCOM_APP_ID: 'h09p3q4c',
  WALLET_CONNECT_PROJECT_ID: 'bcdda3a89e1369545c37e076a0cd69b5',
  SINGULARITY_API_KEY: 'F21nGK7KSyFqkeI6BTDkTU3vn0V76imb',
  SINGULARITY_SECRET_API_KEY: 'SSk49aq1/kQ1eKH7Sg+u4JsisvrycRcLopHdM6lNEMVe/p7lsSVoRiY0neFYNJkHoWVEK30bPAV2pNU2WwOJXQ==',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
