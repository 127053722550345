import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertController, IonItemSliding, ModalController, NavController, Platform, LoadingController } from '@ionic/angular';
import { RawTransactionVerbose, TokenInfo, TransactionSummary } from '../../global';
import { TransactionDetailModalComponent } from '../transaction-detail-modal/transaction-detail-modal.component';
import { getTokenKeyring, getTokenKeyringPagedTransactions } from '../../angular-wallet-base/store/wallet';
import { Store } from '@ngrx/store';
import { AppState } from '../../angular-wallet-base/store/appState';
import {
  getAvatarUrl,
  getTransactionMemo,
  handleCopy,
  getTransactionAmountsByAddress,
  openLinkExternal,
  getAuxFeeFromTx,
  removeAuxFeeAddressFromArray,
  getSendTypeValueFromtxAmount,
  getReceiveTypeValueFromtxAmount,
  getTransactionAmountsByAddressSol,
  getTransactionAmountsByEVMAddress
} from '../../angular-wallet-base/utils';
import { ASSET_ALLOC_FEE_SATOSHI, EXTERNAL_APIS } from '../../angular-wallet-base/constants';
import { Logger } from '../../angular-wallet-base/services/logger.service';
import { QrDataServiceService } from '../../angular-wallet-base/services/qr-data-service.service';
import { TransactionSenderService } from '../../angular-wallet-base/services/transaction-sender.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { TranslateService } from '@ngx-translate/core';
import { GetTokenHistoryRequestAction, WalletActionTypes, ClearTxHistory, GetTokenHistorySuccessAction } from '../../angular-wallet-base/actions/wallet.actions';
import { SubscriptionListenerComponent } from '../../angular-wallet-base/SubscriptionListenerComponent';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { TokenManagerService } from '../../angular-wallet-base/services/token-manager.service';
import { WebSocketAddressNotifierService } from '../../angular-wallet-base/services/WebSocketAddressNotifier.service';
import { BlockBookService } from '../../angular-wallet-base/services/blockbook.service';
import { TransactionDetailDataService } from '../../angular-wallet-base/services/transactiondetaildata.service';
import { AppModeService } from '../../angular-wallet-base/services/app-mode.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionHistoryComponent extends SubscriptionListenerComponent implements OnInit, OnChanges {

  @Input() unconfirmedTransactions: any[] = [];
  @Input() tokenInfo: TokenInfo;
  @Input() mode: string = 'mobile';
  @Output() sendAgain: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(IonItemSliding) ionItemSliding: IonItemSliding;

  public viewData = [];
  @Output() scrollAction: EventEmitter<any> = new EventEmitter<any>();
  private modal = null;
  private pageNumber = 1;
  private itemsPerPage = 25;
  public fetching = false;
  public allDataLoaded;
  public isNonIos = true;
  public isMobile = false;
  public isDesktop;
  public old_bb_url;
  public walletAddress;

  constructor(public modalController: ModalController,
    protected store: Store<AppState>,
    private clipboard: Clipboard,
    private inAppBrowser: InAppBrowser,
    public alertController: AlertController,
    private translate: TranslateService,
    private clipboardService: ClipboardService,
    private actions$: Actions,
    private platform: Platform,
    private wsService: WebSocketAddressNotifierService,
    private appMode: AppModeService) {
    super(store);

    wsService.getEvents().subscribe(event => {
      if (event === 'go_history_top') {
        this.updateHistory();
      }
    });

    if (this.platform.is('ios')) {
      this.isNonIos = false;
    }

    // Screen size check to determine modal or page for transaction detail
    if (this.platform.width() < 765) {
      this.isMobile = true;
    }

    this.isDesktop = appMode.isDesktopMode;
  }

  private goToTopAndUpdateHistory() {
    this.wsService.getEvents().next('go_history_top');
    this.updateHistory();
  }

  private async updateHistory() {
    this.viewData = [];
    this.pageNumber = 1;
    this.clearHistory();
    await this.fetchPage(1);

    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_HISTORY_SUCCESS),
      takeUntil(this.destroyed$))
      .subscribe((action: GetTokenHistorySuccessAction) => {
        this.handleAllDataLoaded(action);
      });
  }

  async getWalletAddress() {
    const coinKeyring = await this.store.select(getTokenKeyring, {
      keyringId: this.tokenInfo.keyringId
    }).first().toPromise();
    return coinKeyring.address;
  }

  async ngOnInit() {
    Logger.info('init');

    if (this.tokenInfo.baseChainSymbol === 'HL') {
      Logger.info('HL history/sending/receive not supported yet');
      return;
    }
    this.walletAddress = await this.getWalletAddress();
    this.old_bb_url = EXTERNAL_APIS.PREVIOUS_SYSNET_BB_URL + this.walletAddress;
    
    // Reducing pagination for SOL to avoid many request
    if (this.tokenInfo.baseChainSymbol === 'SOL') {
      this.itemsPerPage = environment.SOLANA_HISTORY_LIMIT;
    }

    this.subscriptions.add(this.store.select(getTokenKeyringPagedTransactions, { keyringId: this.tokenInfo.keyringId })
      .subscribe(async pagedTransactions => {
        if (!pagedTransactions) {
          return;
        } else if (pagedTransactions.transactions[0] === undefined || pagedTransactions.transactions.length < this.itemsPerPage) {
          this.allDataLoaded = true;
        }
 
        this.viewData = [].concat(await this.decorateTransactions(
          // Just adding confirmed transactions
          pagedTransactions.transactions.filter(tx => tx && tx.confirmations)
        ));
      }));
  }

  async fetchPage(page) {
    if (this.tokenInfo.baseChainSymbol === 'HL') {
      Logger.info('HL history/sending/receive not supported yet');
      return;
    }

    this.fetching = true;

    this.store.dispatch(new GetTokenHistoryRequestAction({
      sysGuid: this.tokenInfo.symbol === this.tokenInfo.baseChainSymbol ? null : (this.tokenInfo.guid || this.tokenInfo.symbol),
      ethFilterIndex: this.tokenInfo.ethFilterIndex || null,
      address: this.tokenInfo.address,
      pageNum: page,
      itemsPerPage: this.itemsPerPage,
      baseChainSymbol: this.tokenInfo.baseChainSymbol
    }));
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.walletAddress) {
      this.walletAddress = await this.getWalletAddress();
    }
    if (this.tokenInfo.baseChainSymbol === 'HL') {
      Logger.info('HL history/sending/receive not supported yet');
      return;
    }

    Logger.info('changes:', changes);
    if (changes.unconfirmedTransactions) {
      await this.decorateUnconfirmedTransactions();
    }

    if (!changes.tokenInfo) {
      await this.fetchPage(1);
    }

    // TODO: REINSTATE THIS FOR BB STUFF IF NEEDED
    // if tx list has actually changed, reset paging
    // if (changes.transactions) {
    //   // tslint:disable-next-line
    //   if(changes.transactions.currentValue && changes.transactions.previousValue) {
    //     if (changes.transactions.currentValue.length !== changes.transactions.previousValue.length) {
    //       Logger.info('re-render tx list');
    //       this.pageNumber = 0;
    //       let page = this.getPage();
    //       page = await this.decorateTransactions(page);
    //       this.viewData = page;
    //     }
    //   }
    // }
  }

  async loadData(event) {
    this.pageNumber += 1;
    
    let lastTx = null;
    if (this.tokenInfo.baseChainSymbol === 'SOL') {
      lastTx = this.viewData[this.viewData.length - 1].txid;
      this.itemsPerPage = environment.SOLANA_HISTORY_LIMIT;
    }
    this.store.dispatch(new GetTokenHistoryRequestAction({
      ethFilterIndex: this.tokenInfo.ethFilterIndex || null,
      sysGuid: this.tokenInfo.symbol === this.tokenInfo.baseChainSymbol ? null : (this.tokenInfo.guid || this.tokenInfo.symbol),
      address: this.tokenInfo.address,
      pageNum: lastTx !== null ? lastTx : this.pageNumber,
      itemsPerPage: this.itemsPerPage,
      baseChainSymbol: this.tokenInfo.baseChainSymbol
    }));

    this.actions$.pipe(
      ofType(WalletActionTypes.GET_TOKEN_HISTORY_SUCCESS),
      takeUntil(this.destroyed$))
      .subscribe((action: GetTokenHistorySuccessAction) => {
        // Logger.info('getTokenHistorySuccess:', action);
        this.handleAllDataLoaded(action);
        event.target.complete();
      });
  }

  handleAllDataLoaded(action: GetTokenHistorySuccessAction) {
    const transactions = action.payload.result.transactions;
    this.fetching = false;
    if (this.viewData[this.viewData.length - 1]) {
      if (!transactions || transactions[transactions.length - 1].txid === this.viewData[this.viewData.length - 1].txid) {
        this.allDataLoaded = true;
      } else {
        this.allDataLoaded = false;
      }
    }
  }

  async doSendAgain(tx: TransactionSummary, $event) {
    const sendString = tx.type === 'send' ? tx.toAddress : tx.fromAddress;
    this.sendAgain.emit({ toAddress: sendString, toTempAddress: sendString });
    this.closeItemSlide($event);
  }

  closeItemSlide(event) {
    try {
      // uncertain the path is consistent across all devices...
      // worst-case: the items slide function doesn't slide back to closed state.
      event.path[1].close();
    } catch (e) {
      Logger.error('items path [1] does not have a close function.');
    }
  }

  isBaseChain() {
    return this.tokenInfo.symbol === this.tokenInfo.baseChainSymbol;
  }

  getSendTypeValueFromtxAmount(txAmount, walletAddress, isBaseChain) {
    const addresses = Object.keys(txAmount.amounts);

    return addresses
      .filter(addr => addr !== walletAddress)
      .map(addr => {
        if (isBaseChain) {
          return txAmount.amounts[addr]?.value;
        }

        return txAmount.amounts[addr]?.tokenValue[this.tokenInfo.guid];
      })
      .reduce((prev, next) => prev + next, 0);
  }

  getReceiveTypeValueFromtxAmount(txAmount) {
    if (this.isBaseChain()) {
      return txAmount.amounts[this.walletAddress].value;
    }

    return txAmount.amounts[this.walletAddress]?.tokenValue[this.tokenInfo.guid];
  }
  
  
  appendDataToTransactionEVM(txn) {
    const txAmount = getTransactionAmountsByEVMAddress(txn, this.walletAddress);
    txn.type = txAmount.type;
    txn.fromAddress = txn.from;
    txn.toAddress = txn.to;
    txn.amount = txAmount.totalValue;
    txn.avatar = txn.to;
    txn.fee = txn.gasPrice * txn.gasUsed;
    txn.time = txn.timeStamp || Date.now();
    return txn;
  }
  
  appendDataToTransactionSol(txn) {
    const txAmount = getTransactionAmountsByAddressSol(txn, this.walletAddress);
    txn.type = txAmount.type;
    txn.fromAddress = txn.from;
    txn.toAddress = txn.to;
    txn.amount = txAmount.totalValue;
    txn.avatar = txn.to;
    txn.fee = txn.fee;
    txn.time = txn.blockTime || Date.now();
    return txn;
  }

  appendDataToTransaction(txn) {
    const txAmount = getTransactionAmountsByAddress(txn, this.walletAddress, this.tokenInfo.baseChainSymbol === 'ETH');
    
    txn.type = txAmount.type;
    if (txn.type === 'send') {
      txn.fromAddress = this.walletAddress;
      txn.toAddress = removeAuxFeeAddressFromArray(txAmount.voutAddresses, this.tokenInfo.auxFeeAddress || [])[0];
      txn.amount = getSendTypeValueFromtxAmount(txAmount, this.walletAddress, this.isBaseChain(), this.tokenInfo.guid);
      txn.avatar = txn.toAddress;
      txn.fee = txn.fees;
      txn.time = txn.blockTime;
    } else if (txn.type === 'receive') {
      txn.amount = getReceiveTypeValueFromtxAmount(txAmount, this.walletAddress, this.isBaseChain(), this.tokenInfo.guid);
      txn.toAddress = this.walletAddress;
      txn.fromAddress = txAmount.vinAddresses[0];
      txn.avatar = txn.toAddress;
      txn.fee = txn.fees;
      txn.time = txn.blockTime;
    } else {
      // self
      txn.amount = txn.fee;
    }

    // SPT txs sends a small amount of SYS, that most likely will be in index 1 if the array is sorted
    // (0 = OP_RETURN, 1 = sys, X > 1 return)
    // txn.vout = txn.vout.sort((prev, next) => prev.value - next.value);
    txn.memo = getTransactionMemo(txn);

    return txn;
  }

  async decorateTransactions(transactions: any[]) {
    let result = transactions
      .map(tx => JSON.parse(JSON.stringify(tx))) // clone txs, we don't want to modify store
      .filter(txn => {
        if (this.tokenInfo.symbol === 'ETH') {
          // Hide ERC20 txs in ETH history.
          return Number(txn.value);
        }

        return true;
      }).map(txItem => {
        // Avoid modifying store.
        const txn = { ...txItem };

        switch (this.tokenInfo.baseChainSymbol) {
          case 'AVAX':
          case 'ETH' :
            return this.appendDataToTransactionEVM(txn);
            break;
          case 'SOL':
            return this.appendDataToTransactionSol(txn);
            break;
          default:
            return this.appendDataToTransaction(txn);
            break;
        }
      }).filter(txn => txn.amount); // remove txs with amount === 0

    return result;
  }

  async decorateUnconfirmedTransactions() {
    const newUnconfTxs = this.unconfirmedTransactions.map(txItem => {
      // Avoid modifying store.
      const txn = { ...txItem.tx };
      switch (this.tokenInfo.baseChainSymbol) {
        case 'AVAX':
        case 'ETH' :
          return this.appendDataToTransactionEVM(txn);
          break;
        case 'SOL':
          return this.appendDataToTransactionSol(txn);
          break;
        default:
          return this.appendDataToTransaction(txn);
          break;
      }
    });

    this.unconfirmedTransactions = newUnconfTxs;
  }

  async transactionClicked(txn) {
    await this.goToTransactionDetail(txn);
  }

  async goToTransactionDetail(txn) {
    if (this.modal !== null) {
      await this.dismissModal();
    }

    const txSummary: any = {
      fromAddress: txn.fromAddress,
      toAddress: txn.toAddress,
      amount: txn.amount,
      time: txn.time || txn.blockTime,
      confirmations: txn.confirmations,
      txid: txn.txid || txn.hash,
      type: txn.type,
      tokenInfo: this.tokenInfo,
      systx: txn.systx,
      fee: txn.fee,
      memo: this.tokenInfo.baseChainSymbol === 'ETH' ? '' : getTransactionMemo(txn),
      assetAllocFee: txn.systx && (this.tokenInfo.symbol !== 'SYS' || this.isTransactionFee(txn)) ? 0.0000068 : null
    };

    txSummary.memo = this.tokenInfo.baseChainSymbol === 'ETH' ? '' : getTransactionMemo(txn);

    if (txn.zdag_error) {
      txSummary.zdag_error = true;
    } else if (!txn.zdag_error) {
      if (txn.zdag_confirmed) {
        txSummary.zdag_warn = false;
      } else {
        txSummary.zdag_warn = true;
      }
    }

    try {
      txSummary.auxFee = getAuxFeeFromTx(txn, this.tokenInfo.auxFeeAddress);
      txSummary.auxFeeAddress = this.tokenInfo.auxFeeAddress[0];
    } catch(err) {
      // not SPT/no auxfee set
    }

    if (txn.systx) {
      txSummary.auxFee = getAuxFeeFromTx(txn, this.tokenInfo.auxFeeAddress);
    }

    this.modal = await this.modalController.create({
      component: TransactionDetailModalComponent,
      componentProps: {
        transaction: txSummary
      },
      cssClass: `${this.isMobile ? '' : 'token-modal-css'}`
    });
    return await this.modal.present();
  }

  public async openLinkExternal() {
    openLinkExternal(this.old_bb_url, this.inAppBrowser);
  }

  async dismissModal() {
    if (this.modal === null) {
      return false;
    }
    const theModal = this.modal;
    this.modal = null;
    return await theModal.dismiss();
  }

  async doCopy(tx, $event) {
    const message = tx.type === 'send' ? tx.toAddress : tx.fromAddress;
    await handleCopy(message, this.platform, this.clipboard, this.alertController, this.translate, this.clipboardService, null);
    this.closeItemSlide($event);
  }

  getAvatarUrl(str, size) {
    return getAvatarUrl(str, size);
  }

  shouldShowZdag() {
    return this.tokenInfo.baseChainSymbol === 'SYS' && this.tokenInfo.symbol !== 'SYS';
  }

  showZdagIcon(tx) {
    if (tx.zdag_error) {
      return 'error';
    } else if (!tx.zdag_confirmed) {
      return 'warn';
    } else {
      return 'confirmed';
    }
  }
  public isTransactionFee(tx: RawTransactionVerbose) {
    let x;
    if (tx.systx && this.tokenInfo.symbol === 'SYS') {
      const vouts = tx.vout;
      if (vouts[0].assetInfo) {
        return 'tokens.label_tx_tx_fee';
      }
    }
    return x;
  }
  showTxLabel(tx) {
    let toReturn;
    if (tx.memo && tx.memo.indexOf('Gas station') !== -1) {
      toReturn = 'tokens.label_tx_gas_station';
    }
    if (tx.systx && this.tokenInfo.symbol === 'SYS') {
      toReturn = this.isTransactionFee(tx);
    }
    if (tx.memo && tx.memo.indexOf('Epin') !== -1 && this.tokenInfo.symbol !== 'SYS') {
      toReturn = 'tokens.label_tx_epin';
    }
    if (tx.memo && tx.systx && this.tokenInfo.symbol === 'SYS' && tx.memo.indexOf('Gas station') !== -1) {
      toReturn = 'tokens.label_tx_gas_fee';
    }
    if (tx.memo && tx.memo === 'Virtual card TX' && tx.systx) {
      toReturn = 'tokens.label_tx_virtual_card';
    }

    return toReturn;
  }

  isIos() {
    return this.platform.is('ios');
  }

  clearHistory() {
    this.store.dispatch(new ClearTxHistory({ keyringId: this.tokenInfo.keyringId }));
  }

  getHistoryStatusLabel() {
    if (this.fetching) {
      return 'tokens.refresh_txs_to_display';
    }

    if (!this.unconfirmedTransactions.length && !this.viewData.length) {
      return 'tokens.no_txs_to_display';
    }

    return '';
  }
}
