import { Action } from '@ngrx/store';
import { TokenInfo } from '../../global';

export enum SendTokenActionTypes {
  SET_SEND_TOKEN_DATA = '[sendtoken] Set send token data',
  AUTH_SEND_TOKENS_SUCCESS = '[sendtoken] Authorize token send',
  SEND_TOKENS_SUCCESS = '[sendtoken] Send tokens success',
  HIDE_SEND_TOKEN_FORM = '[sendtoken] Hide send token form',
  RESET_SEND_TOKEN_DATA = '[sendtoken] Reset send token form',
}

export class SetSendTokenDataAction implements Action {
  readonly type = SendTokenActionTypes.SET_SEND_TOKEN_DATA;
  constructor(readonly payload: {
    fromAddress: string,
    toAddress: string,
    amount: number,
    memo?: string,
    ethGasFee?: string,
    tokenInfo: TokenInfo,
    networkFee?: string,
  }) { }
}

export class AuthSendTokensSuccessAction implements Action {
  readonly type = SendTokenActionTypes.AUTH_SEND_TOKENS_SUCCESS;
  constructor(readonly payload: { pin: string }) { }
}

export class SendTokensSuccessAction implements Action {
  readonly type = SendTokenActionTypes.SEND_TOKENS_SUCCESS;
  constructor(readonly payload: { txid: string }) { }
}

export class ResetSendTokenDataAction implements Action {
  readonly type = SendTokenActionTypes.RESET_SEND_TOKEN_DATA;
  constructor() { }
}

