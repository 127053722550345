import { Action } from '@ngrx/store';
import { OTC3Order } from 'src/app/global';

export enum Otc3ActionTypes {
  UPDATE_ORDERS = '[otc3] update orders',
  FETCH_ORDERS = '[otc3] fetch orders',
  FETCH_ORDERS_SUCCESS = '[otc3] fetch orders success',
  FETCH_ORDERS_FAILURE = '[otc3] fetch orders failure',
  PLACE_ORDER = '[otc3] place order',
  PLACE_ORDER_SUCCESS = '[otc3] place order success',
  PLACE_ORDER_FAILURE = '[otc3] place order failure',
  DISMISS_ORDER = '[otc3] dismiss order',
  DISMISS_ORDER_SUCCESS = '[otc3] dismiss order success',
  DISMISS_ORDER_FAILURE = '[otc3] dismiss order failure',
  FULFILL_ORDER = '[otc3] fulfill order',
  FULFILL_ORDER_SUCCESS = '[otc3] fulfill order success',
  FULFILL_ORDER_FAILURE = '[otc3] fulfill order failure',
  FETCH_ORDERS_STATE = '[otc3] fetch orders state',
  TX_PENDING_STATE = '[otc3] tx pending state',
  ORDER_PENDING_STATE = '[otc3] order pending state',
}

export class UpdateOrdersAction implements Action {
  readonly type = Otc3ActionTypes.UPDATE_ORDERS;
  constructor(
    readonly payload: {
      orders?: any[];
      orderType: 'user_order' | 'market_order';
    }
  ) {}
}

export class FetchOrdersRequestAction implements Action {
  readonly type = Otc3ActionTypes.FETCH_ORDERS;
  constructor(
    readonly payload: {
      wallet: any;
      orderType: 'user_order' | 'market_order';
      tokenIn?: string;
      tokenOut?: string;
    }
  ) {}
}

export class FetchOrdersFailureAction implements Action {
  readonly type = Otc3ActionTypes.FETCH_ORDERS_FAILURE;
  constructor(
    readonly payload: {
      error: any;
    }
  ) {}
}

export class PlaceOrderAction implements Action {
  readonly type = Otc3ActionTypes.PLACE_ORDER;
  constructor(
    readonly payload: {
      wallet: any;
      order: OTC3Order;
    }
  ) {}
}

export class PlaceOrderSuccessAction implements Action {
  readonly type = Otc3ActionTypes.PLACE_ORDER_SUCCESS;
  constructor() {}
}

export class PlaceOrderFailureAction implements Action {
  readonly type = Otc3ActionTypes.PLACE_ORDER_FAILURE;
  constructor(
    readonly payload: {
      error: any;
    }
  ) {}
}

export class DismissOrderAction implements Action {
  readonly type = Otc3ActionTypes.DISMISS_ORDER;
  constructor(
    readonly payload: {
      wallet: any;
      orderIndex: number;
    }
  ) {}
}

export class DismissOrderSuccessAction implements Action {
  readonly type = Otc3ActionTypes.DISMISS_ORDER_SUCCESS;
  constructor() {}
}

export class DismissOrderFailureAction implements Action {
  readonly type = Otc3ActionTypes.DISMISS_ORDER_FAILURE;
  constructor(
    readonly payload: {
      error: any;
    }
  ) {}
}

export class FulfillOrderAction implements Action {
  readonly type = Otc3ActionTypes.FULFILL_ORDER;
  constructor(
    readonly payload: {
      wallet: any;
      order: OTC3Order;
    }
  ) {}
}

export class FulfillOrderSuccessAction implements Action {
  readonly type = Otc3ActionTypes.FULFILL_ORDER_SUCCESS;
  constructor() {}
}

export class FulfillOrderFailureAction implements Action {
  readonly type = Otc3ActionTypes.FULFILL_ORDER_FAILURE;
  constructor(
    readonly payload: {
      error: any;
    }
  ) {}
}
export class FetchOrdersStateAction implements Action {
  readonly type = Otc3ActionTypes.FETCH_ORDERS_STATE;
  constructor(public payload: { isFetchingOrders: boolean }) {}
}

export class TxPendingStateAction implements Action {
  readonly type = Otc3ActionTypes.TX_PENDING_STATE;
  constructor(public payload: { isTxPending: boolean }) {}
}

export class OrderPendingStateAction implements Action {
  readonly type = Otc3ActionTypes.ORDER_PENDING_STATE;
  constructor(public payload: { pendingOrderId: number }) {}
}
