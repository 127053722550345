import { Action } from '@ngrx/store';
import { BankAccountState as BankAccountState } from '../store/bankAccounts';
import {
  bankAccountActionTypes,
  GetBankAccountSuccessAction,
  GetBankAccountBalanceSuccess,
  GetBankAccountTransactionHistorySuccess,
  SetPTTokenSendDataAction,
  UpdateBankAccountAssetAction,
  AddBankingHistoryAction,
  SetPlaidLinkToken
} from '../actions/bankAccount.actions';

export const bankAccountsState = {
  list: {
    data: [],
    loading: false,
    error: false
  },
  assets: {
    data: []
  },
  balance: {
    amount: 0,
    loading: false,
    error: false
  },
  transactions: {
    data: [],
    loading: false,
    error: true
  },
  send: {
    data: {
      amount: '',
      address: '',
      token: ''
    },
    loading: false,
    error: false
  },
  bankingHistory: {
    pageNumber: 1,
    pageSize: 10,
    loadedAll: false,
    data: []
  },
  plaidLinkToken: false
};

export function bankAccountsReducer(state: BankAccountState = bankAccountsState, action: Action): BankAccountState {
  let payload;
  switch (action.type) {
    case bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_REQUEST:
      return { ...state, list: { ...state.list, loading: true, error: false } };
    case bankAccountActionTypes.BANK_ACCOUNT_UPDATE_ASSET_LIST:
      payload = (action as UpdateBankAccountAssetAction).payload;
      return { ...state, assets: { data: payload } };
    case bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_ERROR:
      return { ...state, list: { ...state.list, error: true, loading: false } };
    case bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_SUCCESS:
      payload = (action as GetBankAccountSuccessAction).payload;
      return { ...state, list: { data: payload, loading: false, error: false } };
    case bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_REQUEST:
      return { ...state, balance: { ...state.balance, loading: true, error: false } };
    case bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_ERROR:
      return { ...state, balance: { ...state.balance, loading: false, error: true } };
    case bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_SUCCESS:
      payload = (action as GetBankAccountBalanceSuccess).payload;
      return { ...state, balance: { amount: payload, loading: false, error: false } };
    case bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_REQUEST:
      return { ...state, transactions: { ...state.transactions, loading: true, error: false } };
    case bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_SUCCESS:
      payload = (action as GetBankAccountTransactionHistorySuccess).payload;
      return { ...state, transactions: { data: payload, error: true, loading: false } };
    case bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_ERROR:
      return { ...state, transactions: { ...state.transactions, loading: false, error: true } };
    case bankAccountActionTypes.PT_TOKEN_SET_SEND_DATA:
      payload = (action as SetPTTokenSendDataAction).payload;
      return {
        ...state,
        send: {
          data: {
            amount: payload.amount,
            address: payload.address,
            token: payload.token
          },
          loading: false,
          error: false
        }
      };
    case bankAccountActionTypes.ADD_BANKING_HISTORY:
      payload = (action as AddBankingHistoryAction).payload;
      return {
        ...state,
        bankingHistory: {
          data: payload.data,
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
          loadedAll: payload.loadedAll
        }
      };
    case bankAccountActionTypes.PLAID_SET_LINK_TOKEN:
      payload = (action as SetPlaidLinkToken).payload;
      return {
        ...state,
        plaidLinkToken: payload
      }
    case bankAccountActionTypes.RESET_BANK_ACCOUNT_DATA:
      return { ...bankAccountsState };
    default:
      return state;
  }
}
