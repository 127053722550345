import { createSelector } from '@ngrx/store';
import { getAccountSetupState } from './appState';

export interface AccountSetupState {
  readonly email: string;
  readonly contactNo: object;
  readonly password: string;
  readonly lodeid: number;
  readonly token: string;
  readonly otpCode: string;
  readonly setupLevel: number;
  readonly isNewUser: boolean;
  readonly skipRegiserSuccessPage: boolean;
}

export const getAccountSetupDetails = createSelector(
  getAccountSetupState,
  (state) => state
);

export const getSetupLevel = createSelector(
  getAccountSetupState,
  (state) => state.setupLevel
);

export const getUserStatus = createSelector(
  getAccountSetupState,
  (state) => state.isNewUser
);

export const getSkipRegisterSuccessPage = createSelector(
  getAccountSetupState,
  (state) => state.skipRegiserSuccessPage
);
