import { Action } from '@ngrx/store';
import { UserPreferencesState } from '../store/userPreferences';
import {
  SetBaseCurrencyAction,
  SetUserDataAction,
  SetUserPreferencesAction,
  UserPreferencesActionTypes,
  SwitchNetworkAction,
  SetBaseGweiAction,
  SetBaseGasAction,
  GetLodeId,
  SetUserIsRestricted
} from '../actions/userPreferences.actions';
import { WalletActionTypes } from '../actions/wallet.actions';
import { encryptData } from '../utils';
import { NetworkService } from '../services/network.service';
import { constants } from 'ethers';
import { MAX_GAS_LIMIT_TO_SEND, MAX_GWEI_TO_SEND } from '../constants';

export const initialPreferencesState = {
  baseCurrency: 'USD',
  sys: {
    network: NetworkService.defaultNetworks.sys.network
  },
  eth: {
    network: NetworkService.defaultNetworks.eth.network
  },
  btc: {
    network: NetworkService.defaultNetworks.btc.network
  },
  avax: {
    network: NetworkService.defaultNetworks.avax.network
  },
  baseGwei: MAX_GWEI_TO_SEND,
  baseGas: MAX_GAS_LIMIT_TO_SEND
};

export function userPreferencesReducer(state: UserPreferencesState = initialPreferencesState, action: Action): UserPreferencesState {
  let payload;
  switch (action.type) {
    case UserPreferencesActionTypes.GET_LODE_ID:
      payload = (action as GetLodeId).payload;
      return { ...state, lodeid: payload.lodeId };

    case UserPreferencesActionTypes.SET_USER_IS_RESTRICED:
      payload = (action as SetUserIsRestricted).payload;
      return { ...state, isRestricted: payload.isRestricted };

    case UserPreferencesActionTypes.SET_BASE_CURRENCY:
      payload = (action as SetBaseCurrencyAction).payload;
      return { ...state, baseCurrency: payload.baseCurrency };

    case UserPreferencesActionTypes.SET_BASE_GWEI:
      payload = (action as SetBaseGweiAction).payload;
      return { ...state, baseGwei: payload.baseGwei };

    case UserPreferencesActionTypes.SET_BASE_GAS:
      payload = (action as SetBaseGasAction).payload;
      return { ...state, baseGas: payload.baseGas };

    case UserPreferencesActionTypes.SET_USER_PREFERENCES:
      payload = (action as SetUserPreferencesAction).payload;
      return payload.preferences ? payload.preferences : initialPreferencesState;

    case UserPreferencesActionTypes.SET_USER_DATA:
      payload = (action as SetUserDataAction).payload;
      const encryptedCredentials = encryptData(payload.pin, payload.credentials);
      return { ...state, jwtToken: payload.jwtToken, credentials: encryptedCredentials, lodeid: payload.lodeid };

    case UserPreferencesActionTypes.UPDATE_JWT_TOKEN:
      payload = (action as SetUserDataAction).payload;
      return { ...state, jwtToken: payload.jwtToken };

    // for new flow, no need to clear the token, credentails and lodeid
    case WalletActionTypes.RESET_WALLET:
      return state;

    case UserPreferencesActionTypes.REMOVE_LODE_ACC:
      return { ...state, jwtToken: null, credentials: null, lodeid: null };

    case UserPreferencesActionTypes.SWITCH_NETWORK:
      payload = (action as SwitchNetworkAction).payload;
      return {
        ...state,
        [payload.chain]: {
          ...state[payload.chain],
          network: payload.networkId
        }
      };

    case UserPreferencesActionTypes.RESET_USER_PREFERENCES:
      return { ...initialPreferencesState };

    default:
      return state;
  }
}



