import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { GestureController } from '@ionic/angular';
import { Logger } from '../../services/logger.service';

@Directive({
  selector: '[long-press]'
})
export class LongPressDirective implements OnInit, AfterViewInit {
  @Output() press = new EventEmitter();
  @Input('delay') delay = 1500;
  action: any; // not stacking actions
  private longPressActive = false;

  constructor(
    private el: ElementRef,
    private gestureCtrl: GestureController,
    private zone: NgZone) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.loadLongPressOnElement();
  }

  loadLongPressOnElement() {
    const gesture = this.gestureCtrl.create({
      el: this.el.nativeElement,
      threshold: 0,
      gestureName: 'long-press',
      onStart: ev => {
        this.longPressActive = true;
        this.longPressAction();
      },
      onEnd: ev => {
        this.longPressActive = false;
      }
    });
    gesture.enable(true);
  }

  private longPressAction() {
    Logger.info('lon press action');
    if (this.action) {
      clearInterval(this.action);
    }
    this.action = setTimeout(() => {
      this.zone.run(() => {
        if (this.longPressActive === true) {
          this.longPressActive = false;
          this.press.emit();
        }
      });
    }, this.delay);
  }
}
