import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { KycConfirmModalComponent } from './kyc-confirm-modal';

@NgModule({
  declarations: [KycConfirmModalComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [KycConfirmModalComponent]
})
export class KycConfirmModalModule { }
