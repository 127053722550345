import { Action } from "@ngrx/store";
import { SingularityAssetInfo, SingularityOrderInfo } from "../store/purchaseOrder.state";
import { IWeb3Wallet } from "@walletconnect/web3wallet";

export enum SingularityActionTypes {
  SINGULARITY_INIT_REQUEST = "[singularity] Init the Singularity state request",
  SINGULARITY_INIT_REQUEST_SUCCESS = "[singularity] Init the Singularity state request success",

  SINGULARITY_INIT_PURCHASE_ORDER_REQUEST = "[singularity] Init a new purchase",

  SINGULARITY_ERROR_ACTION = "[singularity] Error in Singularity process",

  SINGULARITY_SYNC_ASSETS_INFO = "[singularity] sync asset list from Singularity server",

  SINGULARITY_USER_LOG_OUT = "[singularity] user logout from singularity service and delete Singularity session",

  SINGULARITY_USER_LOG_OUT_FROM_SINGULARITY_DAPP = "[singularity] Singularity DApp request to log out",

  SINGULARITY_USER_LOGIN_SUCCESS = "[singularity] Singularity User login successful",

  SINGULARITY_USER_CLOSE_DRAWER = "[singularity] user close the drawer",
}


export class SingularityStateInitRequestAction implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_INIT_REQUEST;
  constructor(){}
}

export class SingularityStateInitRequestSuccessAction implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_INIT_REQUEST_SUCCESS;
  constructor(){}
}

export class SingularityErrorAction implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_ERROR_ACTION;
  constructor(readonly payload: string) {}
}

export class SingularitySyncAssetInfoAction implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_SYNC_ASSETS_INFO;
  constructor(readonly payload: {singularityAssets: SingularityAssetInfo[]}){}
}


export class SingularityInitNewPurchaseRequest implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_INIT_PURCHASE_ORDER_REQUEST
  constructor(readonly payload: {pendingOrder: SingularityOrderInfo}){}
} 


export class SingularityUserLogOutAction implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_USER_LOG_OUT
  constructor(){}
} 


export class SingularityUserLogOutFromDAppSingularityAction implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_USER_LOG_OUT_FROM_SINGULARITY_DAPP
  constructor(readonly payload: {web3Wallet: IWeb3Wallet, sessionTopic: string}) {}
  
}

export class SingularityUserLoginSuccessFromDApp implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_USER_LOGIN_SUCCESS
  constructor(){}
}

export class SingularityUserCloseDrawer implements Action {
  readonly type = SingularityActionTypes.SINGULARITY_USER_CLOSE_DRAWER
  constructor(){}
}


