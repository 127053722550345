import { Component, Input, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, Platform } from '@ionic/angular';
import { SysTxAssetAllocationSend, TransactionSummary } from '../../global';
import { TransactionSenderService } from '../../angular-wallet-base/services/transaction-sender.service';
import { getAvatarUrl, handleCopy, openLinkExternal, getAuxFeeFromTx } from '../../angular-wallet-base/utils';
import { EXTERNAL_APIS } from '../../angular-wallet-base/constants';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../../angular-wallet-base/services/logger.service';
import { getUnconfirmedTxZdagState } from '../../angular-wallet-base/store/wallet';
import { Store } from '@ngrx/store';
import { AppState } from '../../angular-wallet-base/store/appState';
import { SubscriptionListenerComponent } from '../../angular-wallet-base/SubscriptionListenerComponent';
import { getBaseCurrency } from '../../angular-wallet-base/store/userPreferences';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { NetworkService } from '../../angular-wallet-base/services/network.service';
import { take } from 'rxjs/operators'
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: [ './transaction-detail.component.scss' ],
})
export class TransactionDetailComponent extends SubscriptionListenerComponent implements OnInit {
  @Input() transaction: TransactionSummary;
  public fee: number;
  public auxFee: number;
  public auxFeeAddress: string;
  public baseCurrency: string;
  public baseCurrencyAmount;

  constructor(public actionSheetController: ActionSheetController,
              private clipboard: Clipboard,
              public alertController: AlertController,
              private platform: Platform,
              private translate: TranslateService,
              private clipboardService:ClipboardService,
              private transactionSender: TransactionSenderService,
              protected store: Store<AppState>,
              private inAppBrowser: InAppBrowser,
              private networkService: NetworkService) {
    super(store);
  }

  async ngOnInit() {
    this.fee = this.transaction.fee;
    this.baseCurrencyAmount = this.transaction.amount * (this.transaction.tokenInfo.retailFiatValue || this.transaction.tokenInfo.fiatValue)
    this.baseCurrency = await this.store.select(getBaseCurrency).pipe(take(1)).toPromise();

    try {
      this.auxFee = this.transaction.auxFee;
      this.auxFeeAddress = this.transaction.auxFeeAddress;
    } catch (err) {
      // SYS tx, no aux fee
    }
    if (this.shouldShowZdag()) {
      this.subscriptions.add(this.store.select(getUnconfirmedTxZdagState, {txid: this.transaction.txid}).subscribe(async zdagState => {
        this.transaction.zdag_error = zdagState.zdag_error;
        this.transaction.zdag_warn = !zdagState.zdag_confirmed;
      }));
    }

    const assetAllocationSend = this.transaction.systx as SysTxAssetAllocationSend;

    if (
      !this.isBaseCoinTransaction()
      && this.transaction.tokenInfo.auxFeeAddress
      && assetAllocationSend
      && assetAllocationSend.allocations[1]
    ) {
      const network = await this.networkService.getActiveSysNetwork();
      this.auxFeeAddress = this.getFeeAddressbyNetwork(network)
    }
  }

  getFeeAddressbyNetwork(network) {
    return this.transaction.tokenInfo.auxFeeAddress
      .find(address => {
        const addressFirstLetters = address.slice(0, 4);
        return network.network === 'testnet' ? addressFirstLetters === 'tsys' : addressFirstLetters === 'sys1';
      });
  }

  async presentActionSheet(event: string, isAddress: boolean) {
    let activeNetwork;
    switch (this.transaction.tokenInfo.baseChainSymbol) {
      case 'SYS':
        activeNetwork = await this.networkService.getActiveSysNetwork();
        break;
      case 'ETH':
        activeNetwork = await this.networkService.getActiveEthNetwork();
        break;
      case 'BTC':
        activeNetwork = await this.networkService.getActiveBtcNetwork();
        break;
      case 'AVAX':
        activeNetwork = await this.networkService.getActiveAvaxNetwork();
        activeNetwork.URL = activeNetwork.API_EXPLORER;
        break;
      case 'SOL':
        activeNetwork = await this.networkService.getActiveSolNetwork();
        activeNetwork.URL = activeNetwork.API_EXPLORER;
        break;
    }

    const actionSheet = await this.actionSheetController.create({
      cssClass: 'light-font-sheet',
      header: event,
      buttons: [
        {
          text: await this.translate.get('btn_copy').toPromise(),
          icon: 'copy',
          role: 'selected',
          cssClass: 'light-font-sheet',
          handler: () => this.doCopy(event)
        },
        {
          text: await this.translate.get('link_labels.link_to_block_explorer').toPromise(),
          icon: 'open',
          role: 'selected',
          cssClass: 'light-font-sheet',
          handler: () => openLinkExternal(
            `${activeNetwork.URL}/${isAddress ? 'address/' : 'tx/'}${ event }${activeNetwork.chain === 'SOL' && activeNetwork.network === 'testnet' ? `?cluster=testnet`: ''}`, this.inAppBrowser),
        },
        {
          text: await this.translate.get('btn_cancel').toPromise(),
          icon: 'close',
          role: 'cancel',
          cssClass: 'light-font-sheet',
          handler: () => Logger.info('Cancelled')
        },
      ]
    });

    await actionSheet.present();
  }

  async doCopy(item) {
    await handleCopy(item, this.platform, this.clipboard, this.alertController, this.translate, this.clipboardService, null);
  }

  getAvatarUrl(str, size) {
    return getAvatarUrl(str, size);
  }

  isSysBaseTransaction() {
    return this.transaction.tokenInfo.baseChainSymbol === 'SYS';
  }

  isSysTransaction() {
    return this.transaction.tokenInfo.baseChainSymbol === 'SYS';
  }

  isBaseCoinTransaction() {
    return this.transaction.tokenInfo.symbol === this.transaction.tokenInfo.baseChainSymbol;
  }

  shouldShowZdag() {
    // Disable ZDAG for now
    // return !this.isSysTransaction() && !this.transaction.confirmations;
    return false;
  }

  showZdagIcon() {
    if (this.transaction.zdag_error) {
      return 'error';
    } else if (this.transaction.zdag_warn) {
      return 'warn';
    } else {
      return 'confirmed';
    }
  }
}
