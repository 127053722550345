import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CodePushModalComponent } from './codepush-modal.component';
import { CodePush } from '@ionic-native/code-push/ngx';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule,
        FormsModule
    ],
    declarations: [CodePushModalComponent],
    providers: [CodePush]
})
export class CodePushModalModule { }
