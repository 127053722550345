import { createSelector } from '@ngrx/store';
import { getGasStationState } from './appState';

export interface GasStationState {
  readonly amount: number;
  readonly address: string;
  readonly assetGuid: number;
  readonly memo: string;
  readonly type: string;
  readonly to: string;
}

export const getGasStationInfo = createSelector(
  getGasStationState,
  (state: GasStationState) => state
);
