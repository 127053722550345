import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';

@Component({
  selector: 'kyc-modal',
  templateUrl: './kycstatus-modal.component.html',
  styleUrls: ['./kycstatus-modal.component.scss'],
})

export class KycstatusModalComponent implements OnInit {
  // public ptKycData: any = {};
  public amlStatus: boolean;
  public cipStatus: boolean;
  public idStatus: boolean;
  public poaStatus: boolean;
  public isDesktopMode: boolean;
  public last_updated: any;
  constructor(private modal: ModalController,
              private translate: TranslateService,
              public appMode: AppModeService,
            ) {
              this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
            //   Logger.info(this.ptKycDataString)
            // this.ptKycData = JSON.parse(this.ptKycDataString);
  }

  ngOnInit() {
    // Logger.info(this.ptKycDataString)
    // this.ptKycData = JSON.parse(this.ptKycDataString);
  }


  async cancelKYC() {
    this.modal.dismiss();
  }

}
