import { Action } from "@ngrx/store";
import { UserVestingLTC, UserVestingInformation, UserStakingLODE } from '../../global';


export enum VestingLtcActionTypes {
  // initiate the wallet connect web3wallet when loading page
  VESTING_LTC_STATE_INIT_REQUEST = "[vestingLtc] Init Vesting LTC Request",
  VESTING_LTC_STATE_SUCCESS = "[vestingLtc] Init Vesting LTC Instance successfully",
  VESTING_LTC_STATE_FAILURE = "[vestingLtc] Init Vesting LTC Instance failure",
  DEPOSIT_VESTING_LTC_STATE_REQUEST = "[vestingLtc] Deposti Vesting LTC Request",
  DEPOSIT_VESTING_LTC_STATE_SUCCESS = "[vestingLtc] Deposti  LTC Success",
  DEPOSIT_VESTING_LTC_STATE_FAILURE = "[vestingLtc] Deposti  LTC Failure",
  STAKE_VESTING_LTC_STATE_REQUEST = "[vestingLtc] Stake Vesting LTC Request",
  STAKE_VESTING_LTC_STATE_SUCCESS = "[vestingLtc] Stake  LTC Success",
  STAKE_VESTING_LTC_STATE_FAILURE = "[vestingLtc] Stake  LTC Failure",
  UNSTAKE_VESTING_LTC_STATE_REQUEST = "[vestingLtc] UnStake Vesting LTC Request",
  UNSTAKE_VESTING_LTC_STATE_SUCCESS = "[vestingLtc] UnStake  LTC Success",
  UNSTAKE_VESTING_LTC_STATE_FAILURE = "[vestingLtc] UnStake  LTC Failure",
  CLAIM_VESTING_LTC_STATE_REQUEST = "[vestingLtc] Claim Vesting LTC Request",
  CLAIM_VESTING_LTC_STATE_SUCCESS = "[vestingLtc] Claim  LTC Success",
  CLAIM_VESTING_LTC_STATE_FAILURE = "[vestingLtc] Claim  LTC Failure",

}


export interface ClaimVestingLtcStateSuccessPayload {
  depositBalance: number;
  amountClaimed: number;
  claimableBalance: number;
}

export class VestingLtcStateInitRequestAction implements Action {
  readonly type = VestingLtcActionTypes.VESTING_LTC_STATE_INIT_REQUEST;
  constructor(readonly address: string) {}
}

export class VestingLtcStateInitSuccessAction implements Action {
  readonly type = VestingLtcActionTypes.VESTING_LTC_STATE_SUCCESS;
  constructor(readonly payload: {user: UserVestingInformation}) {}
}

export class VestingLtcStateInitFailureAction implements Action {
  readonly type = VestingLtcActionTypes.VESTING_LTC_STATE_FAILURE;
  constructor(readonly payload: string) {}
}


export class DepositVestingLtcStateAction implements Action {
  readonly type = VestingLtcActionTypes.DEPOSIT_VESTING_LTC_STATE_REQUEST;
  constructor(readonly address: any) {}
}


export class DepositVestingLtcStateSuccessAction implements Action {
  readonly type = VestingLtcActionTypes.DEPOSIT_VESTING_LTC_STATE_SUCCESS;
  constructor(readonly payload: {user: UserVestingInformation}) {}
}

export class DepositVestingLtcStateFailureAction implements Action {
  readonly type = VestingLtcActionTypes.DEPOSIT_VESTING_LTC_STATE_FAILURE;
  constructor(readonly payload: string) {}
}


export class StakeVestingLtcStateAction implements Action {
  readonly type = VestingLtcActionTypes.STAKE_VESTING_LTC_STATE_REQUEST;
  constructor(readonly address: any) {}
}


export class StakeVestingLtcStateSuccessAction implements Action {
  readonly type = VestingLtcActionTypes.STAKE_VESTING_LTC_STATE_SUCCESS;
   constructor(readonly payload: {user: UserVestingInformation}) {}
}

export class StakeVestingLtcStateFailureAction implements Action {
  readonly type = VestingLtcActionTypes.STAKE_VESTING_LTC_STATE_FAILURE;
  constructor(readonly payload: string) {}
}

export class ClaimVestingLtcStateAction implements Action {
  readonly type = VestingLtcActionTypes.CLAIM_VESTING_LTC_STATE_REQUEST;
  constructor(readonly address: any) {}
}


export class ClaimVestingLtcStateSuccessAction implements Action {
  readonly type = VestingLtcActionTypes.CLAIM_VESTING_LTC_STATE_SUCCESS;
   constructor(readonly payload: {user: UserVestingInformation}) {}
}

export class ClaimVestingLtcStateFailureAction implements Action {
  readonly type = VestingLtcActionTypes.CLAIM_VESTING_LTC_STATE_FAILURE;
  constructor(readonly payload: string) {}
}


export class UnStakeVestingLtcStateAction implements Action {
  readonly type = VestingLtcActionTypes.UNSTAKE_VESTING_LTC_STATE_REQUEST;
  constructor(readonly address: any) {}
}


export class UnStakeVestingLtcStateSuccessAction implements Action {
  readonly type = VestingLtcActionTypes.UNSTAKE_VESTING_LTC_STATE_SUCCESS;
   constructor(readonly payload: {user: UserVestingInformation}) {}
}

export class UnStakeVestingLtcStateFailureAction implements Action {
  readonly type = VestingLtcActionTypes.UNSTAKE_VESTING_LTC_STATE_FAILURE;
  constructor(readonly payload: string) {}
}

