import { Action } from '@ngrx/store';
import { PriceOracleState } from '../store/priceOracle';

export enum PriceOracleActionTypes {
  SET_PRICE_ORACLE_DATA = '[price oracle] Set price oracle data',
  CLEAR_PRICE_ORACLE_DATA = '[price oracle] Clear price oracle data',
  RESET_PRICE_ORACLE_DATA = '[price oracle] Clear price oracle data',
}

export class SetPriceOracleDataAction implements Action {
  readonly type = PriceOracleActionTypes.SET_PRICE_ORACLE_DATA;
  constructor(readonly payload: { data: PriceOracleState }) { }
}

export class ClearPriceOracleDataAction implements Action {
  readonly type = PriceOracleActionTypes.CLEAR_PRICE_ORACLE_DATA;
  constructor() { }
}

export class ResetPriceOracleDataAction implements Action {
  readonly type = PriceOracleActionTypes.RESET_PRICE_ORACLE_DATA;
  constructor() { }
}
