import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { MembersPortalService } from 'src/app/angular-wallet-base/services/members-portal.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { openKYC } from 'src/app/angular-wallet-base/utils';
import { ComplianceService } from '../../angular-wallet-base/services/compliance.service';

@Component({
  selector: 'app-kyc-confirm-modal',
  templateUrl: './kyc-confirm-modal.html',
  styleUrls: ['./kyc-confirm-modal.scss'],
})
export class KycConfirmModalComponent implements OnInit {
  isDesktopMode:boolean;
  modalCntrl:ModalController;
  @Input() walletType: string;
  constructor(
    public modal: ModalController,
    protected store: Store<AppState>,
    public appMode: AppModeService,
    public complianceService: ComplianceService,
    public translate: TranslateService,
    public toast: ToastController,
    public platform: Platform,


  ) {
    this.store.select(getSideMenuStatus).subscribe(sideMenu => {
      // updare sidebar css variable to match the modal backdrop and position
      if (sideMenu) {
        document.documentElement.style.setProperty('--sidebar-width', '20px');
      } else {
        document.documentElement.style.setProperty('--sidebar-width', '300px');
      }
    });
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);

  }

  ngOnInit() { }

  closeModal() {
    this.modal.dismiss();
  }
  async goToKyc(){
    this.modal.dismiss();
    let cssClass = '';
    cssClass += (this.isDesktopMode) ? 'kyc-modal-desktop' : 'kyc-modal';
    await openKYC(
      true,
      this.complianceService,
      this.translate,
      this.toast,
      this.modalCntrl,
      this.platform, cssClass
    );
  }
}
