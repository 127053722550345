import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NIKO_SUBSCRIPTION_URL } from '../constants';
import { Logger } from './logger.service';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(
    private http: HttpClient,
  ) { }

  async emailSubscriptionRequest(email: string) {
    try {
      // mailchimp subscription url used for debit card registration request
      const subscriptionUrl = NIKO_SUBSCRIPTION_URL + email.toString();
      return this.http.jsonp<MailChimpResponse>(subscriptionUrl, 'c').toPromise();
    } catch (err) {
      throw new Error(`Email subscription request error : ${err}`);
    }
  }

}
