import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastController, AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { io } from 'socket.io-client';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { getJwtToken } from '../store/userPreferences';
import { EXTERNAL_APIS } from '../constants';
import { environment } from '../../../environments/environment';
import { Logger } from './logger.service';
import { VDCFees } from '../../global';
import { getToastPosition } from '../utils';
import { map, timeout } from 'rxjs/operators';

import { take } from 'rxjs/operators';
export type operationTypes = 'create' | 'reload';

export interface FulFillParams {
  txid: string;
  operationType?: operationTypes;
  epin?: number;
}

@Injectable({
  providedIn: 'root'
})
export class VirtualDebitCardService {
  private VDC_API: string = EXTERNAL_APIS.VIRTUAL_DEBIT_CARD;
  private VDC_API_GET_ADDRESS: string = this.VDC_API + '/address';
  private VDC_API_FULFILL: string = this.VDC_API + '/card/fulfill';
  private VDC_API_VIEW: string = this.VDC_API + '/card/view';
  private VDC_API_LIST: string = this.VDC_API + '/card/list';
  private VDC_API_LIST_ORDERS: string = this.VDC_API + '/card/order/list';
  private VDC_API_CHECK_BALANCE: string = this.VDC_API + '/card/balance';
  private VDC_API_CHECK_CARD_LIMIT: string = this.VDC_API + '/card/limit';
  private VDC_API_HEALTH: string = this.VDC_API + '/health';
  private VDC_API_ELIGIBILITY: string = this.VDC_API + '/card/eligibility';
  private VDC_API_GET_FEES: string = this.VDC_API + '/fees';
  private VDC_CREATE_RELOAD_FEE: number = 0.5;

  private socket;
  protected isConnected: boolean = false;

  constructor(
    private http: HttpClient,
    private toast: ToastController,
    private translate: TranslateService,
    private store: Store<AppState>,
    private alertController: AlertController,
    private platform: Platform
  ) {
    if (environment.features.debitCard) {
      this.socket = io(this.VDC_API);
      this.socket.on('connect', () => {
        this.isConnected = true;
      });
      this.socket.on('disconnect', () => {
        this.socket = null;
        this.isConnected = false;
      });
    }
  }

  public async getAddress() {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt,
    });

    return this.http.get(this.VDC_API_GET_ADDRESS, { headers }).toPromise();
  }

  public async fulfillOrder(params: FulFillParams) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });
    const isReload = params.operationType === 'reload';

    const res = await this.http.post(this.VDC_API_FULFILL, params, { headers }).toPromise();
    const toast = await this.toast.create({
      header: await this.translate.get('virtual_debit_card.notifications.order_placed_title').toPromise(),
      message: await this.translate.get(isReload ?
        'virtual_debit_card.notifications.order_reload_placed_message' :
        'virtual_debit_card.notifications.order_placed_message').toPromise(),
      position: getToastPosition(this.platform),
      duration: 4000
    });

    await toast.present();

    if (!this.socket) {
      return res;
    }

    this.socket.on((res as any)._id, async (data) => {
      if (data.error) {
        Logger.error('Something went wrong error', data.error);
        const alert = await this.alertController.create({
          header: await this.translate.get('virtual_debit_card.notifications.order_error_title').toPromise(),
          message: await this.translate.get(
            'virtual_debit_card.notifications.order_error_message',
            { errorMsg: data.message, orderId: (res as any)._id }).toPromise(),
        });
        await alert.present();
        return res;
      }

      const successToast = await this.toast.create({
        header: await this.translate.get(isReload ?
          'virtual_debit_card.notifications.order_reload_processed_title' :
          'virtual_debit_card.notifications.order_processed_title').toPromise(),
        message: await this.translate.get(isReload ?
          'virtual_debit_card.notifications.order_reload_processed_message' :
          'virtual_debit_card.notifications.order_processed_message').toPromise(),
        position: getToastPosition(this.platform),
        duration: 4000
      });

      await successToast.present();
    });

    return res;
  }

  public async listCards() {
    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt,
    });

    return this.http.get(this.VDC_API_LIST, { headers }).toPromise();
  }

  public async listOrders() {
    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt,
    });

    return this.http.get(this.VDC_API_LIST_ORDERS, { headers }).toPromise();
  }

  public async viewCard(epin: string, email: string) {
    let params = new HttpParams();

    const jwt: any = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    params = params.append('epin', epin);
    params = params.append('email', email);
    const headers = new HttpHeaders({
      Authorization: jwt,
    });

    return this.http.get(this.VDC_API_VIEW, { headers, params }).toPromise();
  }

  public async checkCardBalance(epin) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    let res;
    const headers = new HttpHeaders({
      Authorization: jwt,
    });
    let params = new HttpParams();
    params = params.append('epin', epin);

    try {
      res = await this.http.get(this.VDC_API_CHECK_BALANCE, { headers, params }).toPromise();
      return res.balance;
    } catch (err) {
      Logger.error(err);
      return null;
    }
  }

  public async checkCardLimits(epin) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    let res;
    const headers = new HttpHeaders({
      Authorization: jwt,
    });
    let params = new HttpParams();
    params = params.append('epin', epin);

    try {
      res = await this.http.get(this.VDC_API_CHECK_CARD_LIMIT, { headers, params }).toPromise();
      return res;
    } catch (err) {
      Logger.error(err);
      return null;
    }
  }

  public isAvailable() {
    return this.isConnected;
  }

  public async getStatus() {
    let health;
    try {
      health = await this.http.get(this.VDC_API_HEALTH).pipe(timeout(5000)).toPromise();
    } catch (err) {
      return false;
    }

    return health;
  }

  public async isVDCAvailable() {
    let health;

    try {
      health = await this.getStatus();
    } catch (err) {
      return false;
    }

    return health.connection.rpc && health.connection.db && health.connection.socket;
  }

  public async getEligibility() {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });

    try {
      const response: any = await this.http.get(this.VDC_API_ELIGIBILITY, { headers }).pipe(timeout(5000)).toPromise();
      return response;
    } catch (err) {
      return err.error;
    }
  }

  public checkReloadAmount(num: number) {
    return num >= this.VDC_CREATE_RELOAD_FEE;
  }

  public async getFees(): Promise<VDCFees> {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: jwt
    });

    return await this.http.get(this.VDC_API_GET_FEES, { headers }).toPromise();
  }

}
