import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { NotTranslatedService } from './angular-wallet-base/services/nottranslated.service';

@Injectable()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    this.nts.notTranslated(params.key);
    return '[MISSING]' + params.key;
  }

  constructor(private nts: NotTranslatedService) {}
}
