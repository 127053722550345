import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { CompleteSetupAction, SetupActionTypes } from '../actions/setup.actions';
import { AuthenticateAction } from '../actions/wallet.actions';

@Injectable()
export class SetupEffects {

  completeSetup$ = createEffect(() => this.actions$.pipe(
    ofType<CompleteSetupAction>(SetupActionTypes.COMPLETE_SETUP),
    map(action => new AuthenticateAction())
  ));

  constructor(private actions$: Actions) { }
}
