import { NgModule, APP_INITIALIZER, ErrorHandler, Inject } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, META_REDUCERS, StoreModule } from '@ngrx/store';
import { HttpClient, HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers, Storage } from '@ionic/storage';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { DB_NAME } from '../db_name';
import { rootReducer } from './angular-wallet-base/reducers/rootReducer';
import { EffectsModule } from '@ngrx/effects';
import { WalletEffects } from './angular-wallet-base/effects/wallet.effects';
import { WalletService } from './angular-wallet-base/services/wallet.service';
import { CryptoTransferService } from './angular-wallet-base/services/crypto-transfer.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { CustomMissingTranslationHandler } from './missingTranslationHandler';
import { NotTranslatedService } from './angular-wallet-base/services/nottranslated.service';
import { WalletGuardService } from './angular-wallet-base/services/walletguard.service';
import { NetworkService } from './angular-wallet-base/services/network.service';
import { BankAccountService } from './angular-wallet-base/services/bank-account.service';
import 'arraync';
import { CoinGeckoService } from './angular-wallet-base/services/coingecko.service';
import { SubscriptionListenerComponent } from './angular-wallet-base/SubscriptionListenerComponent';
import { UserPreferencesEffects } from './angular-wallet-base/effects/userPreferences.effects';
import { SetupEffects } from './angular-wallet-base/effects/setup.effects';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { StakingLodeEffects } from './angular-wallet-base/effects/stakingLode.effects';
import { QRCodeModule } from 'angularx-qrcode';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SideMenuComponent } from './components/sidemenu/sidemenu.component';
import { HTTP } from '@ionic-native/http/ngx';
import { SendTokenEffects } from './angular-wallet-base/effects/sendToken.effects';
import { GasStationEffects } from './angular-wallet-base/effects/gasStation.effects';
import { VirtualDebitCardEffects } from './angular-wallet-base/effects/virtualDebitCard.effects';
import { DevicesListEffects } from './angular-wallet-base/effects/devicesList.effects';
import { BankAccountsEffects } from './angular-wallet-base/effects/bankAccount.effects';
import { TransactionSenderService } from './angular-wallet-base/services/transaction-sender.service';
import { WebSocketAddressNotifierService } from './angular-wallet-base/services/WebSocketAddressNotifier.service';
import { TokenManagerService } from './angular-wallet-base/services/token-manager.service';
import { BiometricAuthService } from './angular-wallet-base/services/biometric-auth.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { CustomTranslateLoader } from './angular-wallet-base/CustomTranslateLoader';
import { CodePush } from '@ionic-native/code-push/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { NgxKjuaModule } from 'ngx-kjua';
import { TokenDefinitionService } from './angular-wallet-base/services/token-definition.service';
import { SyscoinWebsocketConfigService, SyscoinWebsocketService } from 'syscoin-websocket';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { TwoFaModule } from './components/two-fa/two-fa.component.module';
import { TwoFaComponent } from './components/two-fa/two-fa.component';
import { UpdateRequiredModalComponent } from './components/update-required-modal/update-required-modal.component';
import { UpdateRequiredModalModule } from './components/update-required-modal/update-required-modal.component.module';
import { PriceOracleService } from './angular-wallet-base/services/price-oracle.service';
import { CodePushModalComponent } from './components/codepush-modal/codepush-modal.component';
import { CodePushModalModule } from './components/codepush-modal/codepush-modal.component.module';
import { StagingModeComponent } from './components/staging-mode/staging-mode.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AppModeService } from './angular-wallet-base/services/app-mode.service';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { AnalyticsTrackerService } from './angular-wallet-base/services/analytics-tracker.service';
import { MembersPortalService } from './angular-wallet-base/services/members-portal.service';
import { SettingsGuardService } from './angular-wallet-base/services/settingsguard.service';
import { CustomCurrencyPipeModule } from './angular-wallet-base/pipes/customcurrency/customcurrency.module';
import { ServiceCheckerService } from './angular-wallet-base/services/service-check.service';
import { StorageService } from './angular-wallet-base/services/storage.service';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { PreviousRouteService } from './angular-wallet-base/services/previousroute.service';

import { SmallToCryptoPipe } from './angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';

import { File } from '@ionic-native/file/ngx';
import { Intercom } from '@awesome-cordova-plugins/intercom/ngx';
import { pageTransition } from './animations/page-transition';
import { TourGuideComponent } from './components/tour-guide/tour-guide.component';
import { AuthService } from './angular-wallet-base/services/auth.service';
import { AuthGuardService } from './angular-wallet-base/services/auth-guard.service';
import { KycConfirmModalModule } from './components/kyc-confirm-modal/kyc-confirm-modal.module';
import { TradeService } from './angular-wallet-base/services/trade.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { SelectAssetModule } from './views/select-asset-modal/select-asset-modal.module';
import { DebounceDirectiveModule } from './angular-wallet-base/directives/debounce/debounce-directive.module';
import { GraphqlService } from './angular-wallet-base/services/graphql.service';
import { GraphqlModule } from './angular-wallet-base/config/graphql.module';
import { ModalRequestComponent } from './components/modal-request/modal-request.component';
import { ModalRequestComponentModule } from './components/modal-request/modal-request.component.module';
import { AuthenticateModule } from './views/authenticate/authenticate.module';
import { ModalWalletConnectionComponent } from './components/modal-wallet-connection/modal-wallet-connection.component';
// import { BuildInfo } from '@awesome-cordova-plugins/build-info/ngx';
import { BuildInfo } from '@awesome-cordova-plugins/build-info/ngx';
import { OneInchService } from './angular-wallet-base/services/one-inch.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { PrimetransactionsService } from './angular-wallet-base/services/primetransactions.service';
import { InvestorsComponent } from './components/investors/investors.component';
import { GraphService } from './angular-wallet-base/services/graph.service';
import { InterceptorService } from './angular-wallet-base/services/interceptor.service';
import { TraderJoeService } from './angular-wallet-base/services/trader-joe.service';
import { NonAuthGuardService } from './angular-wallet-base/services/non-auth-guard.service-';
import { NonWalletGuardService } from './angular-wallet-base/services/non-wallet-guard.service';
import { RoutingService } from './angular-wallet-base/services/routing.service';
import { LongPressDirectiveModule } from './angular-wallet-base/directives/long-press/long-press-directive.module';
import { MicroRewardService } from './angular-wallet-base/services/micro-reward.service';
import { EnvironmentService } from './angular-wallet-base/services/environment.service';
import { REDUCERS_TO_SYNC } from './angular-wallet-base/constants';
import { WalletConnectEffects } from './angular-wallet-base/effects/walletConnect.effects';
import { VestingLtcEffects } from './angular-wallet-base/effects/vestingLTC.effects';
import { Logger } from './angular-wallet-base/services/logger.service';
import { storageSync } from './angular-wallet-base/lib/ngrx-store-ionic-storage';
import { AppState } from './angular-wallet-base/store/appState';
import { walletConnectReducer } from './angular-wallet-base/reducers/walletConnect.reducer';
import { vestingLtcReducer } from './angular-wallet-base/reducers/vestingLTC.reducer';
import { stakingLodeReducer } from './angular-wallet-base/reducers/stakingLode.reducer';

import { StorageSyncEffects } from './angular-wallet-base/effects/syncIonicStorage.effect';
import { SafePipe } from './angular-wallet-base/pipes/safe-urls/safe-urls-pipe';
import { EmailService } from './angular-wallet-base/services/email.service';
import { SingularityPaymentEffects } from './angular-wallet-base/effects/singularityPayment.effects';
import { SingularityPaymentService } from './angular-wallet-base/services/singularity-payment.service';
import { FormatTokenPricePipe } from './pipes/format-token-price.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Otc3Effects } from './angular-wallet-base/effects/otc3.effects';


export function onSyncError(err) {
  Logger.error(err);
}

export const storageSyncReducer = storageSync({
  keys: REDUCERS_TO_SYNC,
  hydratedStateKey: 'hydrated',
  onSyncError,
})

export function storageMetaReducer(reducer: ActionReducer<any, Action>, storageService: StorageService): ActionReducer<any, any> {
  return storageSyncReducer(reducer, storageService);
}

export const metaReducerWithDepFactory: (d: any) => MetaReducer =
  (storageService: StorageService) => reducer => {
    return storageMetaReducer(reducer, storageService)
  };
@NgModule({
  declarations: [
    AppComponent,
    SubscriptionListenerComponent,
    SideMenuComponent,
    StagingModeComponent,
    TopbarComponent,
    TourGuideComponent,
    InvestorsComponent,
    ModalWalletConnectionComponent,
    FormatTokenPricePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    NgxPlaidLinkModule,
    IonicModule.forRoot({
      mode: 'md',
      navAnimation: pageTransition,
      // scrollAssist: false
    }),
    IonicStorageModule.forRoot({
      name: DB_NAME,
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
    }),
    StoreModule.forRoot(rootReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
        deps: [NotTranslatedService]
      },
      useDefaultLang: true
    }),
    EffectsModule.forRoot([
      WalletEffects,
      UserPreferencesEffects,
      SetupEffects,
      SendTokenEffects,
      GasStationEffects,
      VirtualDebitCardEffects,
      DevicesListEffects,
      BankAccountsEffects,
      WalletConnectEffects,
      StorageSyncEffects,
      SingularityPaymentEffects,
      VestingLtcEffects,
      Otc3Effects,
      StakingLodeEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    NgxKjuaModule,
    TwoFaModule,
    UpdateRequiredModalModule,
    CodePushModalModule,
    BrowserAnimationsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    CustomCurrencyPipeModule,
    NgxPlaidLinkModule,
    TranslateModule,
    SelectAssetModule,
    DebounceDirectiveModule,
    GraphqlModule,
    ModalRequestComponentModule,
    AuthenticateModule,
    LongPressDirectiveModule
  ],
  providers: [
    DocumentViewer,
    FileTransfer,
    {
      provide: META_REDUCERS,
      multi: true,
      useFactory: metaReducerWithDepFactory,
      deps: [StorageService]
    },
    File,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    PriceOracleService,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    WalletService,
    AuthService,
    CryptoTransferService,
    BarcodeScanner,
    QRCodeModule,
    Clipboard,
    WalletGuardService,
    AuthGuardService,
    NonAuthGuardService,
    SettingsGuardService,
    CoinGeckoService,
    HTTP,
    CoinGeckoService,
    TransactionSenderService,
    WebSocketAddressNotifierService,
    ScreenOrientation,
    TokenDefinitionService,
    BiometricAuthService,
    FingerprintAIO,
    SocialSharing,
    Network,
    BuildInfo,
    Keyboard,
    // BlockbookTxNotifierService,
    // BlockbookTxNotifierConfigService,
    SyscoinWebsocketService,
    SyscoinWebsocketConfigService,
    NetworkService,
    TokenManagerService,
    CodePush,
    InAppBrowser,
    AppModeService,
    AnalyticsTrackerService,
    MembersPortalService,
    ServiceCheckerService,
    BankAccountService,
    StorageService,
    PreviousRouteService,
    SmallToCryptoPipe,
    Intercom,
    KycConfirmModalModule,
    TradeService,
    OneInchService,
    GraphqlService,
    PrimetransactionsService,
    GraphService,
    NonWalletGuardService,
    RoutingService,
    MicroRewardService,
    TraderJoeService,
    NonWalletGuardService,
    EnvironmentService,
    EmailService,
    SingularityPaymentService
  ],
  bootstrap: [AppComponent],
  entryComponents: [TwoFaComponent, UpdateRequiredModalComponent, CodePushModalComponent, TopbarComponent, ModalWalletConnectionComponent]
})
export class AppModule { }
