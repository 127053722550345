import { Injectable } from '@angular/core';
import { Fetcher, TokenAmount, Percent, ChainId, Trade, Router } from '@traderjoe-xyz/sdk';
import { PairV2, RouteV2 } from '@traderjoe-xyz/sdk-v2';
import { utils, providers, Contract, BigNumber } from 'ethers';
import { inspect } from 'util';
import { ERC20_ABI, LIQUIDITY_PROVIDER_FEE_PERCENTAGE, MAX_INT_ALLOWANCE, TOKEN_ALLOWANCE, TRADER_JOE_ABI, TRADER_JOE_V2_ABI, TRADER_JOE_ROUTER_ADDRESS, TRADER_JOE_V2_ROUTER_ADDRESS } from '../constants';
import { Logger } from './logger.service';
import { NetworkService } from './network.service';
import { StorageService } from './storage.service';
import { TokenManagerService } from './token-manager.service';

@Injectable({
  providedIn: 'root'
})
export class TraderJoeService {

  private network: any;
  private chainId: ChainId;
  private provider: providers.BaseProvider;
  private tokenBases = [];
  private swapContractAddress: string;
  private swapV2ContractAddress: string;

  private usdcAvalanche: string;
  private v2UsdcPools = {};
  private decimals = {};

  constructor(
    private storage: StorageService,
    private networkService: NetworkService,
    private tokenManager: TokenManagerService
  ) { }

  // get all requried for once
  async initiateConnection() {
    this.network = await this.networkService.getActiveAvaxNetwork();
    this.chainId = this.network.chainId;
    this.provider = providers.getDefaultProvider(this.network.RPC_URL);
    this.swapContractAddress = TRADER_JOE_ROUTER_ADDRESS;
    this.swapV2ContractAddress = TRADER_JOE_V2_ROUTER_ADDRESS;

    this.usdcAvalanche = "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E";

    this.v2UsdcPools["0x68327a91E79f87F501bC8522fc333FB7A72393cb"] = "0x356747a599E5B9BD0918F6863CE21afFD7FDF490";
    this.v2UsdcPools["0x13E7bceFddE72492E656f3fa58baE6029708e673"] = "0xD7Aa79c4D6d265eB09B449B3206c1d6Df321Ea98";
    this.v2UsdcPools["0xbBAAA0420D474B34Be197f95A323C2fF3829E811"] = "0xE24563774DD4050e28E3c63567B2918495367626";

    this.decimals[this.usdcAvalanche] = 6;
    this.decimals["0x68327a91E79f87F501bC8522fc333FB7A72393cb"] = 18;
    this.decimals["0x13E7bceFddE72492E656f3fa58baE6029708e673"] = 18;
    this.decimals["0xbBAAA0420D474B34Be197f95A323C2fF3829E811"] = 18;
  }

  async cacheTokenAllowanceByAddress(
    owner: string,
    token: string,
    spender: string,
    allowanceValue: string
  ) {
    const spenderAllowance = { [spender]: allowanceValue };
    const tokenAllowance = { [token]: spenderAllowance };
    let userAllowances = await this.storage.get(TOKEN_ALLOWANCE);

    userAllowances = userAllowances ? JSON.parse(userAllowances) : {};
    if (userAllowances) {
      if (userAllowances[owner]) {
        if (userAllowances[owner][token]) {
          userAllowances[owner][token][spender] = allowanceValue;
        } else {
          userAllowances[owner][token] = spenderAllowance;
        }
      } else {
        tokenAllowance[token] = spenderAllowance;
        userAllowances[owner] = tokenAllowance;
      }
    } else {
      tokenAllowance[token] = spenderAllowance;
      userAllowances[owner] = tokenAllowance;
    }
    await this.storage.set(TOKEN_ALLOWANCE, JSON.stringify(userAllowances));
  }

  // get token allowance stored in local
  async getTokenAllowanceByAddress(
    owner: string,
    token: string,
    spender: string
  ) {
    let userAllowances = await this.storage.get(TOKEN_ALLOWANCE);
    if (userAllowances) {
      userAllowances = JSON.parse(userAllowances);
      try {
        return userAllowances[owner][token][spender];
      } catch (error) { }
    }
    return false;
  }

  async checkAllowance(tokenAddress: string, walletAddress: string) {
    try {
      // get from cached allowances
      const cachedAllowance = await this.getTokenAllowanceByAddress(walletAddress, tokenAddress, this.swapContractAddress);
      if (cachedAllowance) {
        return cachedAllowance as string;
      }

      const makerContract = new Contract(tokenAddress, ERC20_ABI, this.provider);
      const getAllowance = await makerContract.allowance(walletAddress, this.swapContractAddress);
      const currentAllowance = utils.formatUnits(getAllowance);
      if (Number(currentAllowance) > 0) {
        try {
          await this.cacheTokenAllowanceByAddress(walletAddress, tokenAddress, this.swapContractAddress, currentAllowance);
        } catch (error) { }
      }
      return currentAllowance;
    } catch (error) {
      Logger.error('Check allowance error for', tokenAddress, walletAddress, error);
    }
  }


  async approveTheMaxAllowanceForRouter(tokenAddress: string, wallet: any) {
    try {
      const tokenContract = new Contract(
        tokenAddress,
        ['function approve(address spender, uint amount) public returns(bool)'],
        wallet
      );
      const maxAllowance = BigInt(MAX_INT_ALLOWANCE);
      const askApprove = await tokenContract.approve(
        this.swapContractAddress,
        maxAllowance,
      );
      const receiptApprove = await askApprove.wait();
      Logger.info(`Approved max allowance for`, tokenAddress, wallet.address, receiptApprove);

      try {
        await this.cacheTokenAllowanceByAddress(wallet.address, tokenAddress, this.swapContractAddress, maxAllowance.toString());
      } catch (error) { }

      return true;
    } catch (error) {
      Logger.info(`Approval error`, error);
      return false;
    }
  }


  async setTokenBases(tokenList: any[]) {
    if (!this.provider) { await this.initiateConnection(); }
    const tokens = ['USDC', 'WAVAX'];
    const tokenAddress = tokenList.filter(token => (token.baseChainSymbol === 'AVAX' && tokens.includes(token.symbol)));
    for (const token of tokenAddress) {
      const tokenData = await this.getTokenData(token.guid);
      if (tokenData) {
        this.tokenBases.push(tokenData);
      }
    }
    Logger.info('token bases', this.tokenBases);
  }

  // get token data
  async getTokenData(tokenAddress: string) {
    if (!this.provider) { await this.initiateConnection(); }
    try {
      const tokenData = await Fetcher.fetchTokenData(
        this.chainId,
        tokenAddress,
        this.provider
      );
      return tokenData;
    } catch (error) {
      Logger.info('Get token data error for ', tokenAddress, error, inspect(error));
      return null;
    }
  }

  // get price estimation for pair
  async getEstimationForSwap(token1: string, token2: string, amount: number, slippage: number): Promise<Trade> {
    if (!this.provider) { await this.initiateConnection(); }
    const tokenFrom = await this.getTokenData(token1);
    const tokenTo = await this.getTokenData(token2);

    // get all [Token, Token] combinations for route
    const allTokenPairs = PairV2.createAllTokenPairs(tokenFrom, tokenTo, this.tokenBases);
    const allPairs = PairV2.initPairs(allTokenPairs);
    const allRoutes = RouteV2.createAllRoutes(allPairs, tokenFrom, tokenTo, 4);

    // format amount and slippage
    const amountInValue = utils.parseUnits(amount.toString(), tokenFrom.decimals);
    const amountIn = new TokenAmount(tokenFrom, amountInValue.toBigInt());

    // get all possible trades and find the best one
    const pairs = [];
    for (const route of allRoutes) {
      const currentPairs = route.pairs;
      for (const currentPair of currentPairs) {
        const currentToken0 = currentPair.token0;
        const currentToken1 = currentPair.token1;
        try {
          const generatePair = await Fetcher.fetchPairData(
            currentToken0,
            currentToken1,
            this.provider
          );
          pairs.push(generatePair);
        } catch (error) {
          Logger.info(
            `\nFailed to generate pair for ${currentToken0.symbol} => ${currentToken1.symbol}`,
            currentToken0,
            currentToken1
          );
        }
      }
    }
    const bestTrade = Trade.bestTradeExactIn(pairs, amountIn, tokenTo);
    return bestTrade[0]; // TODO: need to check if there is a possiblity of getting mulitple best trades
  }

  async getEstimation(token1: string, token2: string, amount: number, slippage: number) {
    const bestTrade = await this.getEstimationForSwap(token1, token2, amount, slippage);
    const slippageTorelance = new Percent((slippage * 100).toString(), '10000');
    const midPrice = bestTrade.route.midPrice.toSignificant(6);
    const executionNextMidPrice = bestTrade.nextMidPrice.toSignificant(6);
    const maxAmountOut = bestTrade
      .maximumAmountIn(slippageTorelance)
      .toSignificant();
    const executionPrice = bestTrade.executionPrice.toSignificant(6);
    const outputAmount = bestTrade.outputAmount.toSignificant();
    const minAmountOut = bestTrade.minimumAmountOut(slippageTorelance);
    const priceImpact = Math.abs(LIQUIDITY_PROVIDER_FEE_PERCENTAGE - parseFloat(bestTrade.priceImpact.toSignificant()));
    const tradePathList = bestTrade.route.path;
    const path = tradePathList.map((tradePath) => tradePath.address);
    const parsedPath = tradePathList.map((routePath) => routePath.symbol).join(' => ');
    Logger.info(`slippageTorelance ${inspect(slippageTorelance.toSignificant())}`);
    Logger.info(`slippageTorelance to number ${slippageTorelance}`)
    return {
      pair: '',
      from: token1,
      to: token2,
      path,
      parsedPath,
      slippageTorelance: slippage,
      amount,
      price: Number(outputAmount),
      priceImpact: priceImpact,
      minimumReceived: Number(minAmountOut.toSignificant()),
      fee: (amount * LIQUIDITY_PROVIDER_FEE_PERCENTAGE) / 100
    };
  }

  private async swapMethodNameForSupportingFeeToken(methodName: string, token1: string, token2: string) {  
    const supportingFeeTokens = await this.tokenManager.getSupportingFeeTokens();
    const tokenAddresses = supportingFeeTokens.map(a => a.assetGuid);
    if(tokenAddresses.includes(token1) || tokenAddresses.includes(token2)) {
      return "swapExactTokensForTokensSupportingFeeOnTransferTokens"; 
    }
    return methodName;
  }

  async getPayloadForSwap(walletAddress: string, token1: string, token2: string, amount: number, slippage: number) {
    try {
      Logger.info('getPayloadForSwap-------------------');
      const bestTrade = await this.getEstimationForSwap(token1, token2, amount, slippage);
      const outputAmount = bestTrade.outputAmount.toSignificant();
      const slippageTorelance = new Percent((slippage * 100).toString(), '10000');
      const minAmountOut = bestTrade.minimumAmountOut(slippageTorelance);
      const tradePathList = bestTrade.route.path;
      const path = tradePathList.map((tradePath) => tradePath.address);
      const parsedPath = tradePathList.map((routePath) => routePath.symbol).join(' => ');
      const priceImpact = Math.abs(LIQUIDITY_PROVIDER_FEE_PERCENTAGE - parseFloat(bestTrade.priceImpact.toSignificant()));
      const block = await this.provider.getBlock('latest');
      const deadline = block.timestamp + 300;

      const tradeOptions = {
        allowedSlippage: slippageTorelance,
        ttl: deadline, // How long the swap is valid until it expires, in seconds.
        recipient: utils.getAddress(walletAddress)
      };
      
      const swapCallParameters = Router.swapCallParameters(bestTrade, tradeOptions);

      const swapPayload = {
        method: swapCallParameters.methodName, // previously: swapCallParameters.methodName
        inputAmount: BigNumber.from(bestTrade.inputAmount.raw.toString()).toHexString(),
        outputAmount: BigNumber.from(bestTrade.outputAmount.raw.toString()).toHexString(),
        path,
        to: utils.getAddress(walletAddress),
        deadline
      };

      const minimum = BigNumber.from(minAmountOut.numerator[0].toString());
      swapPayload.method = await this.swapMethodNameForSupportingFeeToken(swapPayload.method, token1, token2);
      swapPayload.outputAmount = minimum.toString();

      return {
        swapPayload,
        swapDetails: {
          pair: '',
          from: token1,
          to: token2,
          path,
          parsedPath,
          slippageTorelance: slippageTorelance.toSignificant(),
          amount,
          price: outputAmount,
          priceImpact: priceImpact.toFixed(2),
          minimumReceived: minAmountOut.toSignificant(),
          fee: (amount * LIQUIDITY_PROVIDER_FEE_PERCENTAGE) / 100
        },
        allowance: await this.checkAllowance(token1, walletAddress)
      };
    } catch (error) {
      Logger.error('swapTargetPayloadForSwapTransaction', error);
      return { error: 1, message: `Insufficent liquidity - ${error?.message} !!` };
    }
  }

  // swap tokens
  async swapOrderMarketPlace(wallet: any, marketPayload: any) {
    const traderJoeInterface: any = new utils.Interface(TRADER_JOE_ABI);
    const traderJoeContract = new Contract(
      this.swapContractAddress,
      traderJoeInterface,
      wallet
    );
    const swapMethod = marketPayload.method;

    // get gas price and limit
    const gasPrice = await wallet.getGasPrice();
    const gasLimit = await traderJoeContract.estimateGas.swapExactTokensForTokensSupportingFeeOnTransferTokens(
      marketPayload.inputAmount,
      marketPayload.outputAmount,
      marketPayload.path,
      marketPayload.to,
      marketPayload.deadline,
    );
    const optionsSwapTx = {
      gasPrice,
      gasLimit
    };

    Logger.info('\nFee', {
      gasPriceParsed: utils.formatUnits(gasPrice),
      gasLimitParsed: gasLimit.toNumber(),
      optionsSwapTx
    });

    try {
      let txSwap: any;
      switch (swapMethod) {
        case 'swapExactTokensForTokens':
          txSwap = await traderJoeContract.swapExactTokensForTokens(
            marketPayload.inputAmount,
            marketPayload.outputAmount,
            marketPayload.path,
            marketPayload.to,
            marketPayload.deadline,
            optionsSwapTx
          );
          break;
        case 'swapExactTokensForTokensSupportingFeeOnTransferTokens':
          txSwap = await traderJoeContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
            marketPayload.inputAmount,
            marketPayload.outputAmount,
            marketPayload.path,
            marketPayload.to,
            marketPayload.deadline,
            optionsSwapTx
          );
          break;
        default:
            throw Error(`Swap method ${swapMethod} does not exist/is not supported`);
                      
      }
      const receiptTxSwap = await txSwap.wait();
      Logger.info(`Transaction success hash: \n${txSwap.hash} \nTransaction was mined in block: ${receiptTxSwap.blockNumber}\n`);
      return {
        transactionHash: txSwap.hash,
        transactionBlock: receiptTxSwap.blockNumber
      };
    } catch (error) {
      Logger.info('Swap token error using trader-joe', marketPayload, wallet.address, error);
      return { error: 2, message: 'Market Place order failed! Please try again' };
    }
  }




  // V2 functionality.
  async checkV2Allowance(tokenAddress: string, walletAddress: string) {
    try {
      // get from cached allowances
      const cachedAllowance = await this.getTokenAllowanceByAddress(walletAddress, tokenAddress, this.swapV2ContractAddress);
      if (cachedAllowance) {
        return cachedAllowance as string;
      }

      const makerContract = new Contract(tokenAddress, ERC20_ABI, this.provider);
      const getAllowance = await makerContract.allowance(walletAddress, this.swapV2ContractAddress);
      const currentAllowance = utils.formatUnits(getAllowance);
      if (Number(currentAllowance) > 0) {
        try {
          await this.cacheTokenAllowanceByAddress(walletAddress, tokenAddress, this.swapV2ContractAddress, currentAllowance);
        } catch (error) { 
          Logger.error(`checkV2Allowance ${inspect(error)}`);
        }
      }
      return currentAllowance;
    } catch (error) {
      Logger.error('Check V2 allowance error for', tokenAddress, walletAddress, error);
    }    
  }

  async approveTheMaxAllowanceForV2Router(tokenAddress: string, wallet: any) {
    try {
      const tokenContract = new Contract(
        tokenAddress,
        ['function approve(address spender, uint amount) public returns(bool)'],
        wallet
      );
      const maxAllowance = BigInt(MAX_INT_ALLOWANCE);
      const askApprove = await tokenContract.approve(
        this.swapV2ContractAddress,
        maxAllowance,
      );
      const receiptApprove = await askApprove.wait();
      Logger.info(`Approved max allowance for`, tokenAddress, wallet.address, receiptApprove);

      try {
        await this.cacheTokenAllowanceByAddress(wallet.address, tokenAddress, this.swapV2ContractAddress, maxAllowance.toString());
      } catch (error) {
        Logger.error(`approveTheMaxAllowanceForV2Router ${inspect(error)}`);
       }

      return true;
    } catch (error) {
      Logger.error(`Approval error`, error);
      return false;
    }
  }

  async getV2Estimation(token1: string, token2: string, amount: number, slippage: number) {
    if (!this.provider) { await this.initiateConnection(); }

    const traderJoeInterface: any = new utils.Interface(TRADER_JOE_V2_ABI);
    const traderJoeContract = new Contract(
      this.swapV2ContractAddress,
      traderJoeInterface,
      this.provider
    );

    amount = amount * 10 ** this.decimals[token1];

    const v2pool = this.v2UsdcPools[token1 === this.usdcAvalanche ? token2 : token1];
    const getSwapOut = await traderJoeContract.getSwapOut(v2pool, BigInt(amount), false);

    const outputAmount = getSwapOut.amountOut;

    return {
      pair: v2pool,
      from: token1,
      to: token2,
      slippageTorelance: slippage,
      amount,
      price: parseFloat((Number(outputAmount) / (10 ** 18)).toFixed(6)),
      priceImpact: 0, //priceImpact,
      minimumReceived: 0, // Number(minAmountOut.toSignificant()),
      fee: (amount * LIQUIDITY_PROVIDER_FEE_PERCENTAGE) / 100
    }
  }

  async getPayloadForV2Swap(walletAddress: string, token1: string, token2: string, amount: number, slippage: number) {
    try {
      Logger.info('getPayloadForSwapV2-------------------');
      const bestTrade = await this.getV2Estimation(token1, token2, amount, slippage);

      const block = await this.provider.getBlock('latest');
      const deadline = block.timestamp + 300;

      const swapPayload = {
        //method: swapCallParameters.methodName, // previously: swapCallParameters.methodName
        inputAmount: amount,
        outputAmount: bestTrade.price,
        path: [token1, token2],
        to: utils.getAddress(walletAddress),
        deadline
      };

      return {
        swapPayload,
        swapDetails: {
          pair: '',
          from: token1,
          to: token2,
          path: [token1, token2],
          parsedPath: [token1, token2],
          amount,
          price: bestTrade.price, //outputAmount,
          fee: (amount * LIQUIDITY_PROVIDER_FEE_PERCENTAGE) / 100
        },
        allowance: await this.checkAllowance(token1, walletAddress)
      };

    } catch (error) {
      Logger.error('swapTargetPayloadForSwapTransactionV2', error);
      return { error: 1, message: `Insufficient liquidity - ${error?.message} !!` };
    }
  }

  async swapOrderV2MarketPlace(wallet: any, marketPayload: any) {
    const traderJoeInterface: any = new utils.Interface(TRADER_JOE_V2_ABI);
    const traderJoeContract = new Contract(
      this.swapV2ContractAddress,
      traderJoeInterface,
      wallet
    );
    const path = [
      [25],
      [2],
      marketPayload.path
    ];

    try {
      const formatInputAmount = BigInt(marketPayload.inputAmount * 10 ** this.decimals[marketPayload.path[0]]);
      const formatOutputAmount = BigInt(marketPayload.outputAmount * 10 ** this.decimals[marketPayload.path[1]]) * BigInt(95) / BigInt(100);

      const txSwap = await traderJoeContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
        formatInputAmount,
        formatOutputAmount,
        path,
        marketPayload.to,
        marketPayload.deadline,
        //optionsSwapTx
      );
      const receiptTxSwap = await txSwap.wait();
      Logger.info(`V2 transaction success hash: \n${txSwap.hash} \nTransaction was mined in block: ${receiptTxSwap.blockNumber}\n`);

      return {
        transactionHash: txSwap.hash,
        transactionBlock: receiptTxSwap.blockNumber
      };
    } catch (error) {
      Logger.error('Swap token error using trader-joe-v2', marketPayload, wallet.address, error);
      return { error: 2, message: 'Market Place order on V2 failed! Please try again' };
    }
  }
}
