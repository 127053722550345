import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from '../../angular-wallet-base/store/appState';
import { GetTokenFiatValuesRequestAction, PreventAuthOnResumeAction } from '../../angular-wallet-base/actions/wallet.actions';
import { createRemoveAccountAlert } from '../../angular-wallet-base/utils';
import { TranslateService } from '@ngx-translate/core';
import { getKeychain } from '../../angular-wallet-base/store/wallet';
import { TWO_FA_CANCELLED, TWO_FA_UNLINK_ACCOUNT } from '../../angular-wallet-base/constants';
import { MembersPortalService } from '../../angular-wallet-base/services/members-portal.service';
@Component({
  selector: 'app-two-fa',
  templateUrl: './two-fa.component.html',
  styleUrls: ['./two-fa.component.scss'],
})
export class TwoFaComponent implements OnInit, OnDestroy {
  @Input() showCancel: boolean;
  @Input() isLodeLinked = false;
  @Input() removeAccountFn: () => void;

  public code = '';
  public keychain;
  public lodeUser;
  public is2FAEnabled;
  public typeAuth2FA;

  constructor(public modalController: ModalController,
              public store: Store<AppState>,
              private portalService: MembersPortalService,
              private nav: NavController,
              private translate: TranslateService,
              private alertController: AlertController) { }

  async ngOnInit() {
    this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: true }));
    this.lodeUser = await this.portalService.getUserInfo();
    this.typeAuth2FA = this.lodeUser.two_factor;
    this.store.select(getKeychain).subscribe(keychain => {
      this.keychain = keychain;
    });
  }

  ngOnDestroy() {
    this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: false }));
  }

  onSubmit() {
    this.modalController.dismiss(this.code);
  }

  async onCancel() {
    await this.removeAccountFn();
    await this.modalController.dismiss(TWO_FA_CANCELLED);
  }

  async unlinkAccount() {
    const handler = async () => {
      await this.removeAccountFn();
      await this.modalController.dismiss(TWO_FA_UNLINK_ACCOUNT);

      // only dispatch follow up action if we have an initialized keychain
      if (this.keychain) {
        this.store.dispatch(
          new GetTokenFiatValuesRequestAction({
            symbols: Object.keys(this.keychain)
          }));
      }

      await this.nav.navigateForward('/wallet/assets');
    };

    await createRemoveAccountAlert(this.alertController, this.translate, handler, 'lode_acc.remove_account.alert_title', 'lode_acc.remove_account.alert_description', 'lode_acc.remove_account.alert_cancel', 'lode_acc.remove_account.alert_accept');
  }
}
