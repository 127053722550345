import { Action } from '@ngrx/store';

export enum GasStationActionTypes {
  SET_GAS_STATION_SEND_DATA = '[gasstation] Set gas station data',
  SEND_GAS_STATION_DATA = '[gasstation] Send gas station data',
  TRADE_MOVE_ASSET_SUCCESS = '[moveasset] Move asset success',
  RESET_GAS_STATION_DATA = '[gasstation] Reset gas station data',
}

export class SetSendTokenDataAction implements Action {
  readonly type = GasStationActionTypes.SET_GAS_STATION_SEND_DATA;
  constructor(readonly payload: { address: string, amount: number, assetGuid: number , memo: string, type: string , to: string }) { }
}

export class SendGasStationDataAction implements Action {
  readonly type = GasStationActionTypes.SEND_GAS_STATION_DATA;
  constructor(readonly payload: { pin: string }) { }
}

export class TradeMoveAssetSuccessAction implements Action {
  readonly type = GasStationActionTypes.TRADE_MOVE_ASSET_SUCCESS;
  constructor(readonly payload: { txid: string }) { }
}
export class ResetGasStationDataAction implements Action {
  readonly type = GasStationActionTypes.RESET_GAS_STATION_DATA;
  constructor() { }
}

