import { Component, Input, OnInit } from "@angular/core";
import { InAppBrowser, InAppBrowserOptions } from "@ionic-native/in-app-browser/ngx";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Logger } from "src/app/angular-wallet-base/services/logger.service";
import {
  WalletConnectErrorAction,
  WalletConnectSessionApproveAction,
  WalletConnectSessionRejectAction,
} from "../../angular-wallet-base/actions/walletConnect.actions";
import { AppState } from "../../angular-wallet-base/store/appState";
import {
  getWalletConnectInstance
} from "../../angular-wallet-base/store/walletConnect";
import { SubscriptionListenerComponent } from "../../angular-wallet-base/SubscriptionListenerComponent";
import { take } from "rxjs/operators";
import { isNil } from "src/app/angular-wallet-base/ts-util";
import { getSdkError } from "@walletconnect/utils";
import { IWeb3Wallet } from "@walletconnect/web3wallet";
import { IPeerMeta } from "src/app/angular-wallet-base/lib/wallet-connect/helpers/types";

@Component({
  selector: "app-modal-request",
  templateUrl: "./modal-request.component.html",
  styleUrls: ["./modal-request.component.scss"],
})
export class ModalRequestComponent extends SubscriptionListenerComponent implements OnInit {
  
  @Input() id: number;
  @Input() payload: any;
  @Input() pendingSession: any;

  options: InAppBrowserOptions = {
    location: "yes", // Or 'no'
    hidden: "no", // Or  'yes'
    clearcache: "yes",
    clearsessioncache: "yes",
    zoom: "yes", // Android only ,shows browser zoom controls
    hardwareback: "yes",
    mediaPlaybackRequiresUserAction: "no",
    shouldPauseOnSuspend: "no", // Android only
    closebuttoncaption: "Close", // iOS only
    toolbar: "yes", // iOS only
    toolbarposition: "top", // iOS only
    enableViewportScale: "no", // iOS only
    allowInlineMediaPlayback: "no", // iOS only
    presentationstyle: "fullscreen", // iOS only
    fullscreen: "yes", // Windows only
  };

  private isLoaded = false;
  public peerMeta: IPeerMeta;
  private wcWeb3Wallet: IWeb3Wallet;

  constructor(
    private modalController: ModalController,
    protected store: Store<AppState>,
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
  ) {
    super(store);
  }

  async ngOnInit() {

    this.wcWeb3Wallet = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
    this.peerMeta = this.pendingSession?.proposer?.metadata;
    this.isLoaded = true;
  }

  async approveSession() {
    await this.approveSessionProposalToConnectDApp();
    this.closeModal();
  }

  private async approveSessionProposalToConnectDApp() {
    try {
      const connectedSession = await this.wcWeb3Wallet.approveSession({
        id: this.id,
        namespaces: this.payload,
      });
      // accounts = ['eip155:43114:0xCC...']
      let accountInfo = connectedSession?.namespaces?.eip155?.accounts[0]?.split(':');

      const session = {
        connected: true,
        accounts: isNil(accountInfo)? [] : [accountInfo[2]], 
        chainId: isNil(accountInfo)? 0 : Number(accountInfo[1]),
        bridge:connectedSession.relay.protocol,
        key: connectedSession.controller,
        clientId: connectedSession.self.publicKey,
        clientMeta: connectedSession.self.metadata,
        peerId: connectedSession.peer.publicKey,
        peerMeta: connectedSession.peer.metadata,
        handshakeId: this.id,
        handshakeTopic: connectedSession.topic
      }
      const eventRequests = await this.wcWeb3Wallet.getPendingSessionRequests();
      this.store.dispatch(new WalletConnectSessionApproveAction({web3Wallet: this.wcWeb3Wallet, session, eventRequests}))
    } catch (err) {
      Logger.error(`handleApproveSessionProposaltoConnectDapp - ${err}`);
      this.store.dispatch(new WalletConnectErrorAction( `Error in approve session proposal + ${err?.message}`));
    }
  }

  async rejectSession() {
    await this.rejectSessionProposalToConnectDApp();
    this.closeModal();
  }

  private async rejectSessionProposalToConnectDApp() {
    try {
      await this.wcWeb3Wallet.rejectSession({
        id: this.id,
        reason: getSdkError('USER_REJECTED_METHODS')
      });
      this.store.dispatch(new WalletConnectSessionRejectAction({web3Wallet: this.wcWeb3Wallet, message: 'User reject method'}))
    } catch (err) {
      Logger.error(`handleApproveSessionProposaltoConnectDapp - ${err}`);
      this.store.dispatch(new WalletConnectErrorAction( `Error in rejecting a session proposal + ${err?.message}`));
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  openUrlPeerMetaUrl() {
    this.platform.ready().then(() => {
      this.inAppBrowser.create(this.peerMeta.url, "_system", this.options);
    });
  }

  isArray(property: any) {
    return Array.isArray(property);
  }

}
