import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-capture-email-modal',
  templateUrl: './capture-email-modal.component.html',
  styleUrls: ['./capture-email-modal.component.scss'],
})

export class CaptureEmailModalComponent {
  
  @Input() showLinkAccount: boolean = true;
  @Input() showDoNotAsk: boolean = true;
  @Input() showSkipForNow: boolean = true;
  @Input() showCancelBtn: boolean = false;

  constructor() {
  }
}
