import { Action } from "@ngrx/store";
import { ClaimVestingLtcStateFailureAction, VestingLtcActionTypes, DepositVestingLtcStateSuccessAction, DepositVestingLtcStateFailureAction, 
  VestingLtcStateInitFailureAction, VestingLtcStateInitSuccessAction, 

  ClaimVestingLtcStateSuccessAction, StakeVestingLtcStateFailureAction,
  StakeVestingLtcStateSuccessAction, UnStakeVestingLtcStateSuccessAction, UnStakeVestingLtcStateFailureAction } from "../actions/vestingLTC.actions";
import { VestingLtcState } from "../store/vestingLTC";

export const initialVestingLtcState = {
  userVestingLTC: {
      ltcBalance: null,
      hasDeposited: false,
      isStaking: false,
      vestingInfo: {
        depositTimestamp: new Date(0),
        depositBalance: 0,
        amountClaimed: 0,
        claimableBalance: 0,
      },
      stakingInfo: {
        amountStaked: 0,
        interestEarned: 0,
        interestClaimed: 0,
        hasClaimedJackpot: false,
      },
  },
  message: '',
  loading: false,
  error: false
};

export function vestingLtcReducer(
  state: VestingLtcState = initialVestingLtcState,
  action: Action
): VestingLtcState {
  let payload;
  switch (action.type) {
    case VestingLtcActionTypes.VESTING_LTC_STATE_INIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case VestingLtcActionTypes.VESTING_LTC_STATE_SUCCESS:
    payload = (action as VestingLtcStateInitSuccessAction).payload;
      return {
        ...state,
        userVestingLTC: payload.user,
        loading: false,
        error: null
      };

    case VestingLtcActionTypes.VESTING_LTC_STATE_FAILURE:
      payload = (action as VestingLtcStateInitFailureAction).payload;
      return {
        ...state,
        loading: false,
        error:  true,
        message: payload
      };

      case VestingLtcActionTypes.DEPOSIT_VESTING_LTC_STATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case VestingLtcActionTypes.DEPOSIT_VESTING_LTC_STATE_SUCCESS:
     payload = (action as DepositVestingLtcStateSuccessAction).payload;
     return {
        ...state,
        userVestingLTC: payload.user,
        loading: false,
        error: null
     };

    case VestingLtcActionTypes.DEPOSIT_VESTING_LTC_STATE_FAILURE:
       payload = (action as DepositVestingLtcStateFailureAction).payload;
      return {
        ...state,
        loading: false,
        error:  true,
        message: payload
      };



    case VestingLtcActionTypes.STAKE_VESTING_LTC_STATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case VestingLtcActionTypes.STAKE_VESTING_LTC_STATE_SUCCESS:
     payload = (action as StakeVestingLtcStateSuccessAction).payload;
          return {
        ...state,
        userVestingLTC: payload.user,
        loading: false,
        error: null
     };



    case VestingLtcActionTypes.STAKE_VESTING_LTC_STATE_FAILURE:
      payload = (action as StakeVestingLtcStateFailureAction).payload;
      return {
        ...state,
        loading: false,
        error:  true,
        message: payload
      };

    case VestingLtcActionTypes.UNSTAKE_VESTING_LTC_STATE_REQUEST:
      
      return {
        ...state,
        loading: true,
        error: null
      };

    case VestingLtcActionTypes.UNSTAKE_VESTING_LTC_STATE_SUCCESS:
      payload = (action as UnStakeVestingLtcStateSuccessAction).payload;
         return {
        ...state,
        userVestingLTC: payload.user,
        loading: false,
        error: null
     };


    case VestingLtcActionTypes.UNSTAKE_VESTING_LTC_STATE_FAILURE:
      payload = (action as UnStakeVestingLtcStateFailureAction).payload;
      return {
        ...state,
        loading: false,
        error:  true,
        message: payload
      };




    case VestingLtcActionTypes.CLAIM_VESTING_LTC_STATE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };

    case VestingLtcActionTypes.CLAIM_VESTING_LTC_STATE_SUCCESS:
      payload = (action as ClaimVestingLtcStateSuccessAction).payload;
         return {
        ...state,
        userVestingLTC: payload.user,
        loading: false,
        error: null
     };


    case VestingLtcActionTypes.CLAIM_VESTING_LTC_STATE_FAILURE:
       payload = (action as ClaimVestingLtcStateFailureAction).payload;
      return {
        ...state,
        loading: false,
        error:  true,
        message: payload
      };


    default:
      return state;
  }
}
