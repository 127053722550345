import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private storage: StorageService,
    private nav: NavController,
  ) { }

  public async goHome() {
    const vaultInfo = await this.storage.getVaultInfo();
    if (vaultInfo) {
      await this.nav.navigateRoot('/home-page/established');
    } else {
      await this.nav.navigateRoot('/home-page/first-time');
    }
  }
}
