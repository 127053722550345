import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { take, tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { VestingLtcActionTypes, VestingLtcStateInitRequestAction, VestingLtcStateInitSuccessAction, VestingLtcStateInitFailureAction,
 DepositVestingLtcStateAction, DepositVestingLtcStateFailureAction, DepositVestingLtcStateSuccessAction,
 StakeVestingLtcStateSuccessAction, StakeVestingLtcStateFailureAction, StakeVestingLtcStateAction,
 ClaimVestingLtcStateSuccessAction, ClaimVestingLtcStateFailureAction,
 UnStakeVestingLtcStateSuccessAction, UnStakeVestingLtcStateAction, UnStakeVestingLtcStateFailureAction
} from '../actions/vestingLTC.actions';
import { AlertController } from '@ionic/angular';
import { VestingLTCService } from '../services/vesting-ltc.service';
import { inspect } from 'util';
import { SingularityUserCloseDrawer, SingularityUserLoginSuccessFromDApp } from '../actions/singularityPayment.action';
import { getPendingOrder } from '../store/purchaseOrder.state';

@Injectable()
export class VestingLtcEffects {

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private vestingLTCService: VestingLTCService
  ) {}

  vestingLtcStateInitRequest$ = createEffect(() => this.actions$.pipe(
    ofType<VestingLtcStateInitRequestAction>(VestingLtcActionTypes.VESTING_LTC_STATE_INIT_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const user = await this.vestingLTCService.getUserInformation(action.address);
       return this.store.dispatch(new VestingLtcStateInitSuccessAction({ user: user }));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new VestingLtcStateInitFailureAction(`Failed to initiate the Wallet Connect instance. Please refresh the page`));
      }
    })
  ), { dispatch: false });


  depositVestingLtcStateInitRequest$ = createEffect(() => this.actions$.pipe(
    ofType<DepositVestingLtcStateAction>(VestingLtcActionTypes.DEPOSIT_VESTING_LTC_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const user = await this.vestingLTCService.depositLTC(action.address);
       return this.store.dispatch(new DepositVestingLtcStateSuccessAction({user: user}));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new DepositVestingLtcStateFailureAction(`Failed to deposit LTC on the Vesting contract. Please try again`));
      }
    })
  ), { dispatch: false });

  stakeVestingLtcStateInitRequest$ = createEffect(() => this.actions$.pipe(
    ofType<StakeVestingLtcStateAction>(VestingLtcActionTypes.STAKE_VESTING_LTC_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const user = await this.vestingLTCService.stakeLODE(action.address);
       return this.store.dispatch(new StakeVestingLtcStateSuccessAction({user: user}));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new StakeVestingLtcStateFailureAction(`Failed to stake on the Vesting contract. Please try again`));
      }
    })
  ), { dispatch: false });

  unStakeVestingLtcStateInitRequest$ = createEffect(() => this.actions$.pipe(
    ofType<UnStakeVestingLtcStateAction>(VestingLtcActionTypes.UNSTAKE_VESTING_LTC_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const user = await this.vestingLTCService.unstakeLODE(action.address);
       return this.store.dispatch(new UnStakeVestingLtcStateSuccessAction({user: user}));
      } catch (err) {
        console.log("Error when unstaking LODE tokens:", err);
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new UnStakeVestingLtcStateFailureAction(`Failed to unstake from the Vesting contract. Please try again`));
      }
    })
  ), { dispatch: false });


  claimVestingLtcStateInitRequest$ = createEffect(() => this.actions$.pipe(
    ofType<DepositVestingLtcStateAction>(VestingLtcActionTypes.CLAIM_VESTING_LTC_STATE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      try {
       const user = await this.vestingLTCService.claimLODE(action.address);
       return this.store.dispatch(new ClaimVestingLtcStateSuccessAction({user: user}));
      } catch (err) {
        Logger.error(`Error: walletConnectStateInitRequest ${inspect(err)}`);
        return this.store.dispatch(new ClaimVestingLtcStateFailureAction(`Failed to claim from the Vesting contract. Please try again`));
      }
    })
  ), { dispatch: false });


 
}
