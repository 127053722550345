import { Component, Input, OnInit } from "@angular/core";
import { InAppBrowser, InAppBrowserOptions } from "@ionic-native/in-app-browser/ngx";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import {
  WalletConnectSessionDisconnectRequestAction,
} from "../../angular-wallet-base/actions/walletConnect.actions";
import { AppState } from "../../angular-wallet-base/store/appState";
import { SubscriptionListenerComponent } from "../../angular-wallet-base/SubscriptionListenerComponent";
import { Logger } from "src/app/angular-wallet-base/services/logger.service";
import { IWalletConnectSession } from "@walletconnect/types";
import { SingularityUserLogOutAction } from "src/app/angular-wallet-base/actions/singularityPayment.action";

//TODO: This file requires cleanup. It's got a lot of unused code duplicated from modal-request.component.ts
@Component({
  selector: "modal-wallet-connection",
  templateUrl: "./modal-wallet-connection.component.html",
  styleUrls: ["./modal-wallet-connection.component.scss"],
})
export class ModalWalletConnectionComponent extends SubscriptionListenerComponent implements OnInit {
  @Input() session: IWalletConnectSession;
  @Input() singularitySession: IWalletConnectSession;
  params;
  options: InAppBrowserOptions = {
    location: "yes", // Or 'no'
    hidden: "no", // Or  'yes'
    clearcache: "yes",
    clearsessioncache: "yes",
    zoom: "yes", // Android only ,shows browser zoom controls
    hardwareback: "yes",
    mediaPlaybackRequiresUserAction: "no",
    shouldPauseOnSuspend: "no", // Android only
    closebuttoncaption: "Close", // iOS only
    toolbar: "yes", // iOS only
    toolbarposition: "top", // iOS only
    enableViewportScale: "no", // iOS only
    allowInlineMediaPlayback: "no", // iOS only
    presentationstyle: "fullscreen", // iOS only
    fullscreen: "yes", // Windows only
  };

  constructor(
    private modal: ModalController,
    protected store: Store<AppState>,
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
  ) {
    super(store);
  }

  ngOnInit() {
    Logger.info(`session ${this.session}`);
    Logger.info(`singularity session ${this.singularitySession}`);
  }


  closeModal() {
    this.modal.dismiss();
  }

  openUrlPeerMetaUrl(showingSession: IWalletConnectSession) {
    this.platform.ready().then(() => {
      this.inAppBrowser.create(showingSession?.peerMeta?.url, "_system", this.options);
    });
  }

  disconnectSession(sessionTopic: string) {
    this.store.dispatch(new WalletConnectSessionDisconnectRequestAction({sessionTopic}));
    this.closeModal();
  }

  disconnectSingularity() {
    Logger.info(`disconnect Singularity session ${this.singularitySession}`);
    this.store.dispatch(new SingularityUserLogOutAction());
  }
}
