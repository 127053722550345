import * as _ from 'lodash';
import HdKeyring from './hd.keyring';
import * as CryptoJS from 'crypto-js';
import { Logger } from '../../../services/logger.service';
import { HdKeyringOptions, TransactionOutputs } from './index';
import HdNode from './hd.node';
import { TypedDataDomain, TypedDataField } from 'ethers';


/**
 * Manages keyrings
 *
 * Usage:
 * 1. Create or restore a vault. A vault is an encrypted representation of the keyrings
 *    managed by this controller.
 * 2. optionally add new keyrings.
 * 3. lock the keyrings - clears in memory keyrings
 * 4. unlock the keyrings with the vault password - restores the in memory keyrings from the vault
 */
export default class HdKeyringController {
  keyrings: any[] = [];
  vault: string;

  private password: string;

  // tslint:disable-next-line:variable-name
  private _locked: boolean;

  get locked(): boolean {
    return this._locked;
  }

  constructor() {
    this.keyrings = [];
    this._locked = true;
  }

  /**
   * Creates a new, unlocked vault
   * @param password the vault password
   */
  async createVault(password: string): Promise<boolean> {
    this._locked = false;
    this.password = password;
    try {
      this.vault = await this.encryptVault(password);
    } catch (e) {
      Logger.error('ERROR encrypting vault', e);
    }

    return true;
  }

  /**
   * Restores keyrings from the encrypted vault
   * @param password the vault password
   * @param vault the vault to restore
   */
  async restoreVault(password: string, vault: string): Promise<boolean> {
    this.password = password;
    this.vault = vault;

    return await this.unlock(password);
  }

  /**
   * Returns an array of serialized keyrings. This is what is encrypted to the vault
   */
  serialize(): any[] {
    return this.keyrings.map((keyring: HdKeyring) => keyring.serialize());
  }

  /**
   * Adds a new keyring
   * @param opts the HD Keyring options
   */
  async addNewKeyring(opts: HdKeyringOptions): Promise<HdKeyring> {
    if (this.locked) {
      throw new Error('Cannot add a keyring when locked');
    }

    let keyring;
    try {
      keyring = await HdKeyring.createKeyring(opts);
      this.keyrings.push(keyring);
      this.vault = this.encryptVault(this.password);
    } catch (e) {
      Logger.error('error', e);
    }

    return keyring;
  }

  /**
   * Locks all keyrings, making them inaccessible.
   */
  async lock(): Promise<boolean> {
    this._locked = true;
    this.keyrings = [];
    this.password = null;
    return true;
  }

  /**
   * Unlock the keyrings, restoring them from the encrypted vault
   * @param password the vault password
   */
  async unlock(password: string): Promise<boolean> {
    try {
      await this.deserialize(this.decryptVault(password), password);

      if (this.keyrings.length && !this.keyrings[0].nodes) {
        throw new Error('migration_needed');
      }

      if (this.keyrings.length === 0) {
        Logger.error('no_keyrings');
        return false;
      }
      this._locked = false;
      return true;
    } catch (err) {
      Logger.error('hd.keyring.controller unlock error', err);
      return false;
    }
  }

  /**
   * Unlock the keyrings, restoring them from the encrypted vault
   * @param password the vault password
   */
  isPasswordValid(password: string): boolean {
    try {
      this.decryptVault(password);
      return true;
    } catch (err) {
      return false;
    }
  }

  // Restores keyrings from their serialized formats
  private async deserialize(serializedKeyrings, password?: string) {
    await serializedKeyrings.forEachAsync(async (serialized: any) => {
      this.keyrings.push(await HdKeyring.createKeyring(serialized));
    });

    // if we don't have an eth ring, add one
    // let hasEth = false;
    // this.keyrings.forEach(keyring => {
    //   if (keyring.isEth()) {
    //     hasEth = true;
    //   }
    // });
    //
    // if (!hasEth) {
    //   Logger.info('Adding ETH keychain upgrade!');
    //   const keyring = await HdKeyring.ethKeyringFromMnemonic(this.keyrings[0].mnemonic);
    //   this.keyrings.push(keyring);
    // } else {
    //   Logger.info('Has eth!');
    // }

  }

  signTransaction(address: string, tx: any, outputs: TransactionOutputs) {
    const node = this.getNodeFor(address);
    if (!node) {
      throw new Error('Address not found in any keyring');
    }

    return node.signTransaction(address, tx, outputs);
  }

  getAccounts(): string[] {
    return _.flatMap(this.keyrings, keyring => keyring.getAccounts());
  }

  getPublicKeyForAddress(address: string): string {
    const keyring = this.getKeyringFor(address);
    if (!keyring) {
      return undefined;
    }

    // return keyring.getAccountForAddress(address).publicKey;
    return 'FIXMe';
  }

  encryptVault(password: string): string {
    const str = JSON.stringify(this.serialize());
    const res = CryptoJS.AES.encrypt(str, password);

    return res.toString();
  }

  public decryptVault(password: string, vault?: string): any[] {
    const str = vault ? vault : this.vault;
    const res = CryptoJS.AES.decrypt(str, password);

    const decrypted = res.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decrypted);
  }

  private getKeyringFor(address: string): HdKeyring {
    for (const keyring of this.keyrings) {
      if (keyring.getNodeFor(address) !== null) {
        return keyring;
      }
    }

    return null;
  }

  private getNodeFor(address: string): HdNode {
    Logger.info(this.getKeyringFor(address));
    return this.getKeyringFor(address).getNodeFor(address);
  }
  signMessage(address: string, msg: string) {
    const node = this.getNodeFor(address);
    if (!node) {
      throw new Error('Address not found in any keyring');
    }
    return node.signMessage(address, msg);
  }

  signTypedData(address: string, domain: TypedDataDomain, types: Record<string, Array<TypedDataField>>, data: Record<string, any>) {
    const node = this.getNodeFor(address);
    if (!node) {
      throw new Error('Address not found in any keyring');
    }
    return node.signTypedData(address, domain, types, data);
  }
}
