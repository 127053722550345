import { Injectable } from '@angular/core';
import web3 from 'web3';
import { Contract, Wallet, providers, utils, BigNumber } from 'ethers';
import {
  LimitOrderBuilder,
  LimitOrderProtocolFacade,
  LimitOrderPredicateBuilder,
  Web3ProviderConnector,
  PrivateKeyProviderConnector,
  ChainId
} from '@1inch/limit-order-protocol';
import {
  ERC20_ABI, MAX_INT_ALLOWANCE,
  ONE_INCH_ABI,
  TOKEN_ALLOWANCE,
  WALLET_STORAGE_KEY,
  LIQUIDITY_PAIRS
} from '../constants';
import { StorageService } from './storage.service';
import { NetworkService } from './network.service';
import { HttpClient } from '@angular/common/http';
import { delay, take } from 'rxjs/operators';
import { BehaviorSubject, identity } from 'rxjs';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { GetTradeOrdersAction, GetTradeOrdersHistoryAction } from '../actions/wallet.actions';
import { getTradeOrders } from '../store/wallet';
import { Logger } from './logger.service';
import { hdPaths } from '../lib/pangolin/keyring';
import { nFormatter } from '../utils';
import { getUnlockKeychain } from '../lib/pangolin/keyring/keychainUtils';

@Injectable({
  providedIn: 'root'
})
export class OneInchService {
  private contractAddress: string;
  private swapContractAddress: string;
  private chainId: ChainId;
  private provider: any;
  private connector: Web3ProviderConnector;
  private web3Provider: any;
  private limitOrderBuilder: LimitOrderBuilder;
  private limitOrderProtocolFacade: LimitOrderProtocolFacade;
  private limitOrderPredicateBuilder: LimitOrderPredicateBuilder;

  private cacheContractDecimals = {}

  private rpcUrl: string;
  private limitOrderUrl: string;
  private broadcastApiUrl: string;
  private apiBaseUrl: string;
  private network: any;
  private orderContract: Contract;
  private referrerAddress: string;
  private additionalGasLimit: number;

  public orderProgress = new BehaviorSubject(false);
  public orderHistoryProgress = new BehaviorSubject(false);
  public askBidLoader = new BehaviorSubject(false);
  public getLiquidityLoader = new BehaviorSubject(false);

  constructor(
    protected store: Store<AppState>,
    private storage: StorageService,
    private networkService: NetworkService,
    private http: HttpClient
  ) {
  }

  // get all requried for once
  async initiateConnection() {
    this.network = await this.networkService.getActiveAvaxNetwork();

    this.chainId = this.network.chainId;
    this.rpcUrl = this.network.RPC_URL;
    this.limitOrderUrl = this.network.ONEINCH_LIMIT_ORDER_URL;
    this.broadcastApiUrl = this.network.ONEINCH_BROADCAST_URL;
    this.apiBaseUrl = this.network.ONEINCH_API_URL;
    this.contractAddress = this.network.ONEINCH_CONTRACT_ADDRESS;
    this.referrerAddress = this.network.REFERRER_ADDRESS;
    this.additionalGasLimit = Number(this.network.ADDITIONAL_GAS_LIMIT);

    // default
    this.provider = providers.getDefaultProvider(this.rpcUrl);
    this.web3Provider = new web3(this.rpcUrl);
    this.connector = new Web3ProviderConnector(this.web3Provider);

    // Limit order 
    this.limitOrderBuilder = new LimitOrderBuilder(
      this.contractAddress,
      this.chainId,
      this.connector
    );
    this.limitOrderProtocolFacade = new LimitOrderProtocolFacade(
      this.contractAddress,
      this.connector
    );
    this.limitOrderPredicateBuilder = new LimitOrderPredicateBuilder(
      this.limitOrderProtocolFacade
    );

    // get latest 1inch swap contract address
    if (!this.swapContractAddress) {
      //this.swapContractAddress = await this.getSwapContractAddress();
    }
  }

  async getDecimal(address: string): Promise<number> {
    if (!this.network) { await this.initiateConnection(); }
    if (this.cacheContractDecimals[address]) {
      return this.cacheContractDecimals[address];
    }
    try {
      const tokenContract = new Contract(
        address,
        ERC20_ABI,
        this.provider
      );
      const tokenDecimal = await tokenContract.decimals();
      this.cacheContractDecimals[address] = Number(tokenDecimal);
      return this.cacheContractDecimals[address];
    } catch (error) {
      Logger.error('Error on finding decimal', address, error);
    }
  }

  async cacheTokenAllowanceByAddress(
    owner: string,
    token: string,
    spender: string,
    allowanceValue: string
  ) {
    const spenderAllowance = { [spender]: allowanceValue };
    const tokenAllowance = { [token]: spenderAllowance };
    let userAllowances = await this.storage.get(TOKEN_ALLOWANCE);

    userAllowances = userAllowances ? JSON.parse(userAllowances) : {};
    if (userAllowances) {
      if (userAllowances[owner]) {
        if (userAllowances[owner][token]) {
          userAllowances[owner][token][spender] = allowanceValue;
        } else {
          userAllowances[owner][token] = spenderAllowance;
        }
      } else {
        tokenAllowance[token] = spenderAllowance;
        userAllowances[owner] = tokenAllowance;
      }
    } else {
      tokenAllowance[token] = spenderAllowance;
      userAllowances[owner] = tokenAllowance;
    }
    await this.storage.set(TOKEN_ALLOWANCE, JSON.stringify(userAllowances));
  }

  // get token allowance stored in local
  async getTokenAllowanceByAddress(
    owner: string,
    token: string,
    spender: string
  ) {
    let userAllowances = await this.storage.get(TOKEN_ALLOWANCE);
    if (userAllowances) {
      userAllowances = JSON.parse(userAllowances);
      try {
        return userAllowances[owner][token][spender];
      } catch (error) { }
    }
    return false;
  }

  async getSwapContractAddress() {
    try {
      const swapContractAddressUrl = this.apiRequestUrl('/approve/spender', {});
      const swapContractAddressData = await this.http.get(swapContractAddressUrl).pipe().toPromise();
      const swapContractAddress = swapContractAddressData['address'] as string;
      return swapContractAddress;
    } catch (error) {
      Logger.info('get spender address errror', error);
      return null;
    }
  }

  async getDecimals(fromTokenAddress: string, toTokenAddress: string) {
    const nativeTokenAddress = this.network.NATIVE_TOKEN_ADDRESS;
    let fromTokenDecimal = 18;
    let toTokenDecimal = 18;

    const fromTokenPromise = nativeTokenAddress !== fromTokenAddress
      ? new Contract(fromTokenAddress, ERC20_ABI, this.provider).decimals()
      : Promise.resolve(fromTokenDecimal);

    const toTokenPromise = nativeTokenAddress !== toTokenAddress
      ? new Contract(toTokenAddress, ERC20_ABI, this.provider).decimals()
      : Promise.resolve(toTokenDecimal);

     [fromTokenDecimal, toTokenDecimal] = await Promise.all([fromTokenPromise, toTokenPromise]);

    return [fromTokenDecimal, toTokenDecimal];
  }

  apiRequestUrl(methodName: string, queryParams: any) {
    return this.apiBaseUrl + methodName + '?' + (new URLSearchParams(queryParams)).toString();
  }

  async getEstimationForSwap(payload: any) {
    try {
      const getEstimationForSwapUrl = this.apiRequestUrl('/quote', payload);
      const getEstimationSwapData: any = await this.http.get(getEstimationForSwapUrl).pipe().toPromise();
      return getEstimationSwapData;
    } catch (error) {
      Logger.info('getEstimationForSwap: ', error);
      return false;
    }
  }

  async buildTxForSwap(payload: any) {
    try {
      const buildTxForSwapUrl = this.apiRequestUrl('/swap', payload);
      const buildTxForSwapData: any = await this.http.get(buildTxForSwapUrl).pipe().toPromise();
      return buildTxForSwapData;
    } catch (error) {
      Logger.info('buildTxForSwap : ', error);
      throw error;
    }
  }

  async broadCastRawTransaction(rawTransaction: any, delayResponse: boolean) {
    try {
      // Added small delay after approval, as consecutive call to check allowance resulted '0'
      const broadCastData: any = await this.http.post(
        this.broadcastApiUrl, { rawTransaction }
      ).pipe(delayResponse ? delay(2000) : identity).toPromise();
      return broadCastData.transactionHash;
    } catch (error) {
      Logger.info('broadcase raw transaction error : ', error);
      return false;
    }
  }

  async signAndSendTransaction(transaction: any, privateKey: string, delayed = false) {
    const { rawTransaction } = await this.web3Provider.eth.accounts.signTransaction(transaction, privateKey);
    return await this.broadCastRawTransaction(rawTransaction, delayed);
  }

  async checkAllowance(tokenAddress: string, walletAddress: string) {
    try {
      // get from cached allowances
      const cachedAllowance = await this.getTokenAllowanceByAddress(walletAddress, tokenAddress, this.swapContractAddress);
      if (cachedAllowance) {
        return cachedAllowance as string;
      }

      const currentTime = new Date().getTime(); // need to get the latest data, not the cached one
      const allowanceUrl = this.apiRequestUrl('/approve/allowance', { tokenAddress, walletAddress, currentTime });
      const allowanceData = await this.http.get(allowanceUrl).pipe().toPromise();
      const allowanceValue = allowanceData['allowance'] as string;

      // cache allowance 
      try {
        await this.cacheTokenAllowanceByAddress(walletAddress, tokenAddress, this.swapContractAddress, allowanceValue);
      } catch (e) { }

      return allowanceValue;
    } catch (error) {
      return false;
    }
  }

  async buildTxForApproveTradeWithRouter(walletAddress: string, tokenAddress: string, amount) {
    try {
      const buildTxUrl = this.apiRequestUrl(
        '/approve/transaction',
        amount ? { tokenAddress, amount } : { tokenAddress }
      );
      const transaction: any = await this.http.get(buildTxUrl).pipe().toPromise();
      const gasLimit = await this.web3Provider.eth.estimateGas({ ...transaction, from: walletAddress });
      return { ...transaction, gas: gasLimit };
    } catch (error) {
      Logger.info('buildTxForApproveTradeWithRouter : ', error);
      return false;
    }
  }

  async getEstimation(
    fromTokenAddress: string,
    toTokenAddress: string,
    amountValue: number,
    slippage: number
  ) {
    if (!this.network) { await this.initiateConnection(); }

    const [fromTokenDecimal, toTokenDecimal] = await this.getDecimals(fromTokenAddress, toTokenAddress);
    const amount = (amountValue * (10 ** fromTokenDecimal)).toString();
    const getEstimationData = await this.getEstimationForSwap({ fromTokenAddress, toTokenAddress, amount });
    if (getEstimationData) {
      return {
        pair: getEstimationData.fromToken.symbol + '/' + getEstimationData.toToken.symbol,
        from: getEstimationData.fromToken.address,
        to: getEstimationData.toToken.address,
        slippageTorelance: slippage,
        amount: utils.formatUnits(BigNumber.from(getEstimationData.fromTokenAmount), fromTokenDecimal),
        price: utils.formatUnits(BigNumber.from(getEstimationData.toTokenAmount), toTokenDecimal),
        priceImpact: null,
        minimumReceived: null,
        fee: '0.000000025' // utils.formatUnits(BigNumber.from(getEstimationData.tx.gasPrice), fromTokenDecimal)
      };
    } else {
      return { error: 1, message: 'Insufficent liquidity !!' };
    }
  }

  async getPayloadForSwap(
    walletAddress: string,
    fromTokenAddress: string,
    toTokenAddress: string,
    amountValue: number,
    slippage: number
  ) {
    try {
      Logger.info('getPayloadForSwap-------------------');
      const [fromTokenDecimal, toTokenDecimal] = await this.getDecimals(fromTokenAddress, toTokenAddress);
      const payload = {
        fromTokenAddress,
        toTokenAddress,
        amount: (amountValue * (10 ** fromTokenDecimal)).toString(),
        fromAddress: walletAddress,
        walletAddress,
        referrerAddress: this.referrerAddress,
        slippage,
        disableEstimate: false,
        allowPartialFill: false,
      };
      const swapTransaction = await this.buildTxForSwap(payload);
      return {
        swapPayload: swapTransaction.tx,
        swapDetails: {
          pair: swapTransaction.fromToken.symbol + '/' + swapTransaction.toToken.symbol,
          from: swapTransaction.fromToken.address,
          to: swapTransaction.toToken.address,
          slippageTorelance: slippage,
          amount: utils.formatUnits(BigNumber.from(swapTransaction.fromTokenAmount), fromTokenDecimal),
          price: utils.formatUnits(BigNumber.from(swapTransaction.toTokenAmount), toTokenDecimal),
          priceImpact: null,
          minimumReceived: null,
          fee: utils.formatUnits(BigNumber.from(swapTransaction.tx.gasPrice), 18),
        },
        allowance: await this.checkAllowance(fromTokenAddress, walletAddress)
      };
    } catch (error) {
      Logger.info('swapTrgetPayloadForSwapansaction', error);
      if (error.error && error.error.meta && error.error.meta.find(err => err.type === 'allowance')) {
        return { error: 2, message: error.error.description }; // returned as 2 to identify allowance error (only)
      } else {
        return {
          error: 1,
          message: (error.error && error.error.description)
            ? error.error.description
            : 'Insufficent liquidity !!'
        };
      }
    }
  }

  async approveTheMaxAllowanceForRouter(tokenAddress: string, wallet: any) {
    const transactionForSign = await this.buildTxForApproveTradeWithRouter(
      wallet.address,
      tokenAddress,
      BigInt(MAX_INT_ALLOWANCE)
    );

    const approveTxHash = await this.signAndSendTransaction(transactionForSign, wallet.privateKey, true);
    if (approveTxHash) {

      // cache allowance
      try {
        const allowance = BigInt(MAX_INT_ALLOWANCE).toString();
        await this.cacheTokenAllowanceByAddress(wallet.address, tokenAddress, this.swapContractAddress, allowance);
      } catch (e) { }

      return true;
    } else {
      Logger.error(`\nFailed to approve and transaction cancelled . . . \n`, approveTxHash);
      return false;
    }
  }

  async getWallet(pin: any) {
    const hdPath = this.network.network.toLowerCase() === 'testnet' ? hdPaths.avaxTestnet : hdPaths.avaxMainnnet;
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getUnlockKeychain(vault, pin);
    // TODO: Eric need to fix this hd path
    const walletFromMnemonic = Wallet.fromMnemonic(keychain.keyrings[0].mnemonic, hdPath); // For AVAX only
    return walletFromMnemonic.connect(this.provider);
  }

  async swapOrderMarketPlace(
    wallet: Wallet,
    transaction: any
  ) {
    Logger.info('swapOrder ------------------------------------------------ start');
    transaction.gas += this.additionalGasLimit;

    const swapTxHash = await this.signAndSendTransaction(transaction, wallet.privateKey);
    if (swapTxHash) {
      return { transactionHash: swapTxHash };
    } else {
      Logger.error('swapOrderMarketPlace', swapTxHash);
      return { error: 2, message: 'Market Place order failed! Please try again' };
    }
  }

  // place limit order
  async placeOrder(
    pin: any,
    makerAsset: string,
    makerAmount: number,
    takerAsset: string,
    takerAmount: number,
    expiry: any
  ) {

    // wallet address
    const wallet = await this.getWallet(pin);

    const makerContract = new Contract(
      makerAsset,
      ERC20_ABI,
      wallet
    );
    const makerDecimal = await makerContract.decimals();

    const takerContract = new Contract(
      takerAsset,
      ERC20_ABI,
      wallet
    );
    const takerDecimal = await takerContract.decimals();

    // check for allowance first
    const getAllowance = await makerContract.allowance(
      wallet.address,
      this.contractAddress
    );
    const currentAllowance = utils.formatUnits(getAllowance);

    //  if allowance lower than the given amount or zero, we need to set the allowance for maximum value accepted
    if (
      parseFloat(currentAllowance) === 0 ||
      parseFloat(currentAllowance) <= Number(makerAmount)
    ) {
      try {
        const approvalRequest = await makerContract.approve(
          this.contractAddress,
          BigInt(MAX_INT_ALLOWANCE)
        );
        const finishApprove = await approvalRequest.wait();
      } catch (error) {
        Logger.info(`Approval error`, error);
        return { error: 2, message: 'Failed to approve the transaction' };
      }
    }

    // set expiry time for limit order (predicate)
    const { and, timestampBelow } = this.limitOrderPredicateBuilder;
    const expireOn = moment().add(expiry.value, expiry.type).unix();
    const simplePredicate = and(
      timestampBelow(expireOn), // a limit order is valid only for 1 minute
    );

    const limitOrder = this.limitOrderBuilder.buildLimitOrder({
      makerAssetAddress: makerAsset,
      takerAssetAddress: takerAsset,
      makerAddress: wallet.address,
      makerAmount: (makerAmount * (10 ** makerDecimal)).toString(),
      takerAmount: (takerAmount * (10 ** takerDecimal)).toString(),
      predicate: simplePredicate,
      permit: '0x',
      interaction: '0x',
    });

    const limitOrderTypedData = this.limitOrderBuilder.buildLimitOrderTypedData(
      limitOrder
    );

    const limitOrderHash = this.limitOrderBuilder.buildLimitOrderHash(
      limitOrderTypedData
    );

    const signingKey = wallet.privateKey.replace(/0x/g, '');
    const privateKeyProviderConnector = new PrivateKeyProviderConnector(
      signingKey,
      this.web3Provider
    );

    const limitOrderSignature = await privateKeyProviderConnector.signTypedData(
      wallet.address,
      limitOrderTypedData
    );

    // JSON data for creating an order
    const payload = {
      orderHash: limitOrderHash,
      signature: limitOrderSignature,
      data: limitOrderTypedData.message
    };
    if (!payload) {
      return { error: 2, message: 'Failed !!, try again later' };
    }

    // Placing order
    try {
      const orderData: any = await this.http.post(this.limitOrderUrl, payload).pipe().toPromise();
      return orderData;
    } catch (error) {
      Logger.info('Placing order error', error);
      return { error: 2, message: 'Failed !!, try again later' };
    }
  }

  // check events for active orders
  async checkOrderEvents(
    orderData: any,
    activeOnly = false
  ) {
    let events;
    const orders = [];
    const orderHasdIds = [];

    if (orderData && orderData.length > 0) {
      for (const order of orderData) {
        order.status = 'Active';
        orderHasdIds.push(order.orderHash);
        orders.push(order);
      }
    } else {
      Logger.info(`No orders found . . .`);
      return [];
    }

    // get all order events
    if (orderHasdIds.length > 0) {
      const orderIds = orderHasdIds.join(',');
      const eventResponse: any = await this.http.get(
        `${this.limitOrderUrl}/events/${orderIds}`, {}
      ).pipe().toPromise();
      events = eventResponse;
    }

    // set status
    for (const order of orders) {
      order.parsedMakingAmount = order.data.makingAmount;
      order.parsedTakingAmount = order.data.takingAmount;
      order.fills = (Number(order.data.makingAmount) - Number(order.remainingMakerAmount)).toString();
      order.remaining = order.remainingMakerAmount;

      if (events[order.orderHash]) {
        const isCancel = events[order.orderHash].find(orderEvent => orderEvent.action === 'cancel');
        order.status = isCancel
          ? 'Cancelled'
          : (Number(order.fills) > 0 && Number(order.remaining) <= 0)
            ? 'Success'
            : 'Expired';
      } else {
        order.status = activeOnly ? 'Active' : 'Expired';
      }
    }
    return orders;
  }

  // get active order List for wallet address
  async getActiveOrderList(
    walletAddress: string,
    page = 1,
    limit = 50
  ) {
    if (!this.provider) { await this.initiateConnection(); }
    if (this.orderProgress.getValue()) { return; }

    this.orderProgress.next(true);
    const orderTypes = '%5B1%2C2%5D';
    const url = `${this.limitOrderUrl}/address/${walletAddress}?page=${page}&limit=${limit}&statuses=${orderTypes}&sortBy=createDateTime`; // limit set to 50 for now

    let orderData;
    try {
      const orderResponse = await this.http.get(url, {}).pipe().toPromise();
      orderData = await this.checkOrderEvents(orderResponse);
    } catch (error) {
      Logger.info('getActiveOrderList: ', error);
      orderData = [];
    }

    this.orderProgress.next(false);
    return orderData;
  }

  // get active order List for wallet address
  async getOrderHistoryList(
    walletAddress: string,
    page = 1,
    limit = 50
  ) {
    if (!this.provider) { await this.initiateConnection(); }
    if (this.orderHistoryProgress.getValue()) { return; }

    this.orderHistoryProgress.next(true);
    const orderTypes = '%5B3%5D';
    const url = `${this.limitOrderUrl}/address/${walletAddress}?page=${page}&limit=${limit}&statuses=${orderTypes}&sortBy=createDateTime`; // limit set to 50 for now

    let orderData;
    try {
      const orderResponse = await this.http.get(url, {}).pipe().toPromise();
      orderData = await this.checkOrderEvents(orderResponse);
    } catch (error) {
      Logger.info('getOrderHistoryList: ', error);
      orderData = [];
    }

    this.orderHistoryProgress.next(false);
    return orderData;
  }

  // get all orders (for advanced graph)
  async getAllActiveOrders(
    makerAsset: string,
    takerAsset: string,
    page = 1,
    limit = 20,
  ) {
    const url = `${this.limitOrderUrl}/all?page=${page}&limit=${limit}&statuses=%5B1%2C2%5D&sortBy=createDateTime&takerAsset=${takerAsset}&makerAsset=${makerAsset}`;
    try {
      const orderData: any = await this.http.get(url, {}).pipe().toPromise();
      if (orderData && orderData.length > 0) {
        return orderData;
      } else {
        Logger.info(`No orders found . . .`);
        return [];
      }
    } catch (error) {
      Logger.info('Failed to get orders', error);
      return [];
    }
  }

  // get ask/bids for market pair
  async getAskBids(
    makerAsset: string,
    takerAsset: string
  ) {
    this.askBidLoader.next(true);
    const buyData = await this.getAllActiveOrders(makerAsset, takerAsset, 1, 1);
    const sellData = await this.getAllActiveOrders(takerAsset, makerAsset, 1, 1);
    const ask = {
      price: buyData[0] ? nFormatter(Number(buyData[0].makerRate)) : '0',
      size: buyData[0] ? nFormatter(Number(buyData[0].takerRate)) : '0'
    };
    const bid = {
      price: sellData[0] ? nFormatter(Number(sellData[0].makerRate)) : '0',
      size: sellData[0] ? nFormatter(Number(sellData[0].takerRate)) : '0'
    };
    this.askBidLoader.next(false);
    return { ask, bid };
  }

  // cancel an order
  async cancelOrder(
    pin: any,
    orderPayload: any
  ) {
    if (!this.provider) { await this.initiateConnection(); }

    // wallet address
    const wallet = await this.getWallet(pin);
    const signer = wallet.connect(this.provider);

    const payload = this.limitOrderProtocolFacade.cancelLimitOrder(orderPayload);
    return await signer.sendTransaction({
      from: wallet.address,
      to: this.contractAddress,
      data: payload,
    }).then(async cancelledOrderResponse => {
      const cancelledOrder = await cancelledOrderResponse.wait();
      return { status: 1, message: cancelledOrderResponse?.hash };
    }).catch(error => {
      Logger.info('Failed to cancel order', error);
      return false;
    });
  }

  // TODO: may need to change like deeplink listens for needed transactions
  async startAvaxListening() {
    if (!this.network) { await this.initiateConnection(); }

    if (!this.orderContract) {
      // contract connection for listening events
      this.provider.pollingInterval = 5000;  // delay event for 
      this.orderContract = new Contract(
        this.contractAddress,
        ONE_INCH_ABI,
        this.provider
      );

      this.orderContract.once('OrderFilled', async (address, orderHash, remaining) => {
        const orders = await this.store.select(getTradeOrders).pipe(take(1)).toPromise();
        if (orders && orders.length > 0) {
          const order = orders.find(currentOrder => (currentOrder.orderHash.toLowerCase() === orderHash.toLowerCase()));
          if (order && !this.orderProgress.getValue()) {  // setting to prevent multiple api calls
            // get open orders
            this.store.dispatch(new GetTradeOrdersAction(true));
            this.store.dispatch(new GetTradeOrdersHistoryAction(true));
          }
        }
      });
    }
  }

  stopAvaxListening() {
    try {
      this.orderContract.removeAllListeners();
      this.provider.removeAllListeners();
      this.provider = null;
      this.orderContract = null;
    } catch (error) {
      Logger.info('Remove AVAX listner', error);
    }
  }

  async setAvailableLiquidityPairs(tokens: any[]) {
    if (this.getLiquidityLoader.getValue()) { return; }

    // tokens filter for AVAX (skipped GSD)
    const tokenAddress = tokens
      .filter(token => (token.baseChainSymbol === 'AVAX' && token.symbol !== 'GSD'))
      .map(token => token.assetGuid && token);
    const tokenGuids = tokenAddress.map(token => token.assetGuid);

    // get all liquidity pair combinations
    const liquidityPairs = tokenAddress.reduce((acc, v, i) =>
      acc.concat(tokenAddress.slice(i + 1).map(w => [v, w])), []
    );

    // get quotes for all liquidity pairs
    this.getLiquidityLoader.next(true);
    const quoteliquidityPairs = [];
    for (let pair of liquidityPairs) {
      const precision = Number(pair[0].precision) > 0 ? pair[0].precision : 18;
      const amount = 0.01 * (10 ** precision); // TODO: setting the minimum amount to 0.01 for now, may change
      const fromTokenAddress = pair[0].assetGuid;
      const toTokenAddress = pair[1].assetGuid;
      const quote = await this.getEstimationForSwap({ fromTokenAddress, toTokenAddress, amount });
      if (quote) {
        quoteliquidityPairs.push([pair[0].assetGuid, pair[1].assetGuid])
      }
    }

    // group token pairs
    const groupedPairs = {};
    for (let token of tokenGuids) {
      const mappedToken = quoteliquidityPairs
        .filter(pair => pair.includes(token))
        .map(pair => {
          if (pair[0] === token) {
            return pair[1];
          } else {
            return pair[0];
          }
        });
      groupedPairs[token] = mappedToken;
    }

    // store liquidity pairs
    await this.storage.set(LIQUIDITY_PAIRS, JSON.stringify(groupedPairs));
    this.getLiquidityLoader.next(false);
  }
}
