import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { UpdateJwtAction } from '../actions/userPreferences.actions';
import { getJwtToken } from '../store/userPreferences';
import { AgxTosComponent } from 'src/app/views/settings/agx-tos/agx-tos.component';
import {
  EXTERNAL_APIS,
  AUTH_REASON,
  USER_PREFERENCES_STORAGE_KEY,
  TWO_FA_CANCELLED,
  TWO_FA_UNLINK_ACCOUNT,
  Unit,
  Metal,
  TokenType,
  ENABLE_TOS
} from '../constants';
import { StorageService } from './storage.service';
import { AlertController, NavController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from './logger.service';
import { PreventAuthOnResumeAction, ResetWalletAction, ResetWalletWithAuthAction } from '../actions/wallet.actions';
import { HlAgxBalanceService } from './hl-agx-balance.service';
import { LodeBalanceService } from './lode-balance.service';
import { LanguageService } from './language.service';
import { TokenManagerService } from './token-manager.service';
import BigNumber from 'bignumber.js';
import { create2faModal } from '../../components/two-fa/twoFaUtils';
import { map, retry } from 'rxjs/operators';
import { getToastPosition } from '../utils';
import { SetCredentialsAction, SetUserStatusAction } from '../actions/accountSetup.actions';
import { Intercom } from '@awesome-cordova-plugins/intercom/ngx';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { inspect } from 'util';
import * as jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root',
})
export class MembersPortalService {
  private baseApi = EXTERNAL_APIS.MEMBERS_PORTAL;
  private loginUrl = `${this.baseApi}/login`;
  private logoutUrl = `${this.baseApi}/logout`;
  private userInfoUrl = `${this.baseApi}/me`;
  private countriesUrl: string = `${this.baseApi}/countries`;
  private regionsUrl: string = `${this.countriesUrl}/:countryId/regions`;
  private userDevicesUrl: string = `${this.baseApi}/userDevices`;
  private deleteDeviceUrl: string = `${this.baseApi}/deleteDevice`;
  private generateGoogle2faUrl: string = `${this.baseApi}/generateGoogle2fa`;
  private enableVerify2FA: string = `${this.baseApi}/verifyAndEnableGoogle2fa`;
  private disable2FAUrl: string = `${this.baseApi}/disableGoogle2fa`;
  private enableVerifyUser2FA: string = `${this.baseApi}/user/2fa/verify`;
  private enableSmsVerify2FA: string = `${this.baseApi}/user/2fa/sms/verify`;
  private disableUser2FAUrl: string = `${this.baseApi}/user/2fa/disable`;
  private enableSmsVerifyUser2FA: string = `${this.baseApi}/user/2fa/sms/enable`;
  private disableSms2FAUrl: string = `${this.baseApi}/user/2fa/sms/disable`;
  private sendDisable2FASmsUrl: string = `${this.baseApi}/user/2fa/sms/generate`;
  private listCardsUrl: string = `${this.baseApi}/virtualcard/get`;
  private updatePasswordUrl: string = `${this.baseApi}/resetauthpassword`;
  private getPriceQuoteUrl: string = `${this.baseApi}/token/{{coin}}/getpricequote`;
  private updateUserInfoUrl = `${this.baseApi}/users`;
  private getKYCTokenUrl: string = `${this.baseApi}/getSumSubAccessToken`;
  private getTosStatusUrl: string = `${this.baseApi}/get_tos_and_status`;
  private getAgreedToTosUrl: string = `${this.baseApi}/get_agreed_to_tos`;
  private updateAgreementsUrl: string = `${this.baseApi}/updateUserAgreement`;
  private updateUserLanguageUrl: string = `${this.baseApi}/updateUserLang`;
  private updateNewsletterListUrl: string = `${this.baseApi}/updateNewsletterList`;
  private updateAnnounceListUrl: string = `${this.baseApi}/updateAnnounceList`;
  private validatePhoneNumberUrl: string = `${this.baseApi}/validatePhoneNumber`;
  private registerUserUrl: string = `${this.baseApi}/register`;
  private phoneNumberSMSVerifyUrl: string = `${this.baseApi}/phoneNumberSMSVerify`;
  private updatePhoneVerificationUrl: string = `${this.baseApi}/updatePhoneVerification`;
  private updatePhoneNumberSMSSendUrl: string = `${this.baseApi}/updatePhoneNumberSMSSend`;
  private captureEmailUrl: string = `${this.baseApi}/emailRegister`;
  private publicInfoUrl: string = `${this.baseApi}/publicinfo`;
  private getFeatureFlagsUrl: string = `${this.baseApi}/GetFeatureFlagsStatusFromArray`;
  private payByDeliveryUrl: string = `${this.baseApi}/orders/deliver`;
  private getUnifiedPriceHistoryUrl: string = `${this.baseApi}/order-history`;
  private getNewMemberPromoDataUrl: string = `${this.baseApi}/user/promos/get-new-member-alert`;
  private deleteAccountUrl: string = `${this.baseApi}/deleteAccount`;

  private verifyEmail: string = `${this.baseApi}/activate`;
  private verifyPhone: string = `${this.baseApi}/activateUsingSmsCode`;
  private verifyPhoneViaSms: string = `${this.baseApi}/getVerificationCodeViaSms`;
  private acceptAgreementUrl: string = `${this.baseApi}/acceptAgreement`;
  private verifyEmailViaOtp: string = `${this.baseApi}/getVerificationCodeViaEmail`;
  private forgotPasswordOtpRequestUrl: string = `${this.baseApi}/forgotpassword`;
  private forgotPasswordVerifyOtpUrl: string = `${this.baseApi}/checkVerificationCode`;
  private updateUserPasswordUrl: string = `${this.baseApi}/resetpassword`;
  private getTokenDetailsFromGramsUrl: string = `${this.baseApi}/token-details-from-grams`;
  private getTokenConversion: string = `${this.baseApi}/token-service/conversion`;
  private twoFactorInput = [
    {
      name: '2fa',
      type: 'number',
      min: 6,
    },
  ];
  private refreshInterval;
  private REFRESH_INTERVAL = 180000; // refresh token every 3 mins
  private modalInfo = '';
  private userContactInfo = '';
      private modalOpened = false;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private storage: StorageService,
    private nav: NavController,
    public alertController: AlertController,
    private translate: TranslateService,
    private toastController: ToastController,
    private modalController: ModalController,
    private agx: HlAgxBalanceService,
    private lode: LodeBalanceService,
    private languageService: LanguageService,
    private tokenManager: TokenManagerService,
    private modal: ModalController,
    private authService: AuthService,
    public intercom: Intercom,
    private platform: Platform
  ) {}

  // Checks the validity of a jwt token passed in... or a jwt token from storage.
  async isJwtValid(jwt?: any) {
    if (!jwt) {
      const userPref = await this.storage.get(USER_PREFERENCES_STORAGE_KEY).then((val) => JSON.parse(val));
      if (!userPref || !userPref.jwtToken) {
        return false;
      }
      jwt = userPref.jwtToken;
    }
    const jwtDecode = require('jwt-decode');
    let decoded;
    try {
      decoded = jwtDecode(jwt);
    } catch (e) {
      return false;
    }
    return Date.now() <= decoded.exp * 1000;
  }

  async returnJwtIfValid() {
    const userPref = await this.storage.get(USER_PREFERENCES_STORAGE_KEY).then((val) => JSON.parse(val));
    if (!userPref || !userPref.jwtToken) {
      return false;
    }
    const jwt = userPref.jwtToken;

    const jwtDecode = require('jwt-decode');
    let decoded;
    try {
      decoded = await jwtDecode(jwt);
      if (Date.now() <= decoded.exp * 1000) {
        return jwt;
      }
    } catch (e) {
      return false;
    }
  }

  async pushAlert(status, header?, subHeader?, message?, buttons?, inputs?, backdropDismiss = false) {
    const alert = await this.alertController.create({
      header,
      subHeader,
      message,
      buttons: buttons || ['OK'],
      backdropDismiss,
      inputs,
    });
    return await alert.present();
  }

  async login(u, p, otp?) {
    const res = await this.http
      .post(`${this.loginUrl}/`, {
        username: u,
        password: p,
        one_time_password: otp,
      })
      .toPromise();
    return res;
  }

  setRefreshInterval(u, p) {
    this.refreshInterval = setInterval(async () => {
      const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
      if (jwt) {
        this.updateJwt(u, p);
      }
    }, this.REFRESH_INTERVAL);
  }

  clearRefreshInterval() {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
  }

  twoFactorButtons = (username, password, firstTime = false, canClose = false) => {
    return [
      {
        text: 'Submit',
        handler: (event) => {
          return firstTime ? this.linkThenLogin(username, password, event['2fa']) : this.updateJwt(username, password, event['2fa']);
        },
      },
    ];
  };

  async linkThenLogin(username, password, oneTimePassword?, prevent2faModal?) {
    // ensure password is of correct length
    if (!(await this.validatePassword(password))) {
      return;
    }

    try {
      const resp: any = await this.login(username, password, oneTimePassword);
      if (resp.status === 1) {
        if (await this.isJwtValid(resp.data.access_token)) {
          this.languageService.setLanguage(resp.data.lang);
          this.store.dispatch(
            new SetCredentialsAction({
              email: username,
              password,
              token: resp.access_token,
              lodeid: resp.data.lodeid,
            })
          );
          this.store.dispatch(new SetUserStatusAction({ status: false }));

          this.store.dispatch(new UpdateJwtAction({ jwtToken: resp.data.access_token, lodeid: resp.data.lodeid }));
          return resp;
        }
      }
      if (resp.status === 2 || resp.error_code === '2fa_invalid') {
        if (prevent2faModal) {
          return '2fa_required';
        }
        this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: true }));

        try {
          await this.show2fa(username, password, false, true);
        } catch (err) {
          // User clicked on cancel. Do nothing...
        }
      }
      if (resp.status === 1) {
        return 'login_success';
      }
    } catch (e) {
      if (e.error && e.error.error === 'Invalid 2FA Code') {
        const { header, subheader, msg, toast_msg } = await this.translate.get('alerts.prompt_2fa').toPromise();
        const toast = await this.toastController.create({
          message: toast_msg,
          duration: 2000,
        });
        toast.present();
        if (prevent2faModal) {
          return '2fa_required';
        }
        this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: true }));
        return await this.pushAlert(0, header, subheader, msg, this.twoFactorButtons(username, password, true), this.twoFactorInput);
      }
      if (e && e.status === 401) {
        const { header, subheader, msg } = await this.translate.get('alerts.invalid_input.password').toPromise();

        if (e.error.error_code) {
          const { header, subheader, msg } = await this.translate.get(`alerts.${e.error.error_code}`).toPromise();
          return this.pushAlert(0, header, subheader, msg);
        }
        this.pushAlert(0, e.error.error, header, subheader);
      }
    }
  }

  async updateJwt(username, password, oneTimePassword?) {
    try {
      const resp: any = await this.login(username, password, oneTimePassword);
      if (resp.status === 1) {
        this.languageService.setLanguage(resp.data.lang);
        if (await this.isJwtValid(resp.data.access_token)) {
          this.store.dispatch(new UpdateJwtAction({ jwtToken: resp.data.access_token, lodeid: resp.data.lodeid }));
        }

        // update user agreement
        const userAgreement = resp.data && resp.data.userAgreements;
        const latestLodeTosVersion = resp.data.lode_tos.version;
      const hasConfirmedLodeTos = resp.data.userAgreements.some(agreement => {
          return agreement.name == 'lode_tos' && agreement.confirmed == 1 && agreement.version == latestLodeTosVersion;
      });
 
      if(ENABLE_TOS && !hasConfirmedLodeTos) {
        try {
         this.modalOpened = true;
          const modal = await this.modalController.create({
            component: AgxTosComponent,
            backdropDismiss: false,
            componentProps: {
              tosObj: resp.data.lode_tos,
              close: false
       }
          });
          await modal.present();
        } catch (error) {
           Logger.info('Lode Tos Modal Preset error', error);
        }

      }


      }
      if (resp.status === 2) {
        try {
          if (document.getElementsByClassName('two-fa-modal').length === 0) {
            // To avoid calling 2FA popup again if it already exist.
            const result = await this.show2fa(username, password);

            if (result === TWO_FA_CANCELLED || result === TWO_FA_UNLINK_ACCOUNT) {
              return result;
            }
          }
        } catch (err) {
          throw err;
        }
      }
    } catch (e) {
      if (e?.error?.error === 'Invalid 2FA Code') {
        const { header, subheader, msg } = await this.translate.get('alerts.prompt_2fa').toPromise();
        return await this.pushAlert(0, header, subheader, msg, this.twoFactorButtons(username, password, false), this.twoFactorInput);
      }
      if (e.error && e.error.error === 'login_with_sms_otp') {
        try {
          if (document.getElementsByClassName('two-fa-modal').length === 0) {
            // To avoid calling 2FA popup again if it already exist.
            const result = await this.show2fa(username, password);

            if (result === TWO_FA_CANCELLED || result === TWO_FA_UNLINK_ACCOUNT) {
              await this.authService.logoutAccount();
              await this.nav.navigateRoot('setup/intro');
              // return result;
            }
          }
        } catch (err) {
          throw err;
        }
      }

      if (e && e.status === 401) {
        // This means the user changed their login credentials through the portal.
        const { header, subheader, msg } = await this.translate.get('alerts.failed_credentials').toPromise();
        const alert = this.pushAlert(0, e.error.error || header, '', msg);

        // clear state and redirect to login page
        alert.then(async () => {
          await this.authService.logoutAccount();
          await this.nav.navigateRoot('setup/intro');
        });
      }
    }
  }

  private async validatePassword(password) {
    if (!password || password.length < 6) {
      const { header, subheader, msg } = await this.translate.get('alerts.invalid_input.password').toPromise();
      this.pushAlert(0, header, subheader, msg);
      return false;
    }
    return true;
  }

  public async getTosAndStatus() {
    try {
      const jwt = await this.returnJwtIfValid();
      if (!jwt) {
        throw new Error('No LODE account linked');
      }
      const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
      });
      return await this.http.post(this.getTosStatusUrl, {}, { headers }).toPromise();
    } catch (err) {
      return [];
    }
  }

  public async getAgreedToTos() {
    try {
      const jwt = await this.returnJwtIfValid();
      if (!jwt) {
        throw new Error('No LODE account linked');
      }
      const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
      });
      return await this.http.post(this.getAgreedToTosUrl, {}, { headers }).toPromise();
    } catch (err) {
      return [];
    }
  }

  public async updateAgreements(formId) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res = await this.http.post(`${this.updateAgreementsUrl}?token=${jwt}`, { form_id: formId, response: 1 }).toPromise();
    return res as any;
  }

  public async getUserInfo() {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    let params = new HttpParams();
    params = params.append('token', jwt);
    const res = await this.http.get(this.userInfoUrl, { params }).toPromise();
    const { data } = res as any;

    // Country and region data comes as ids, so we need to fetch the real name
    if (data.country) {
      let countries;

      try {
        countries = await this.getCountries();
      } catch (err) {
        return data;
      }

      const country = countries.find((countryInfo) => countryInfo.id === data.country);
      if (country) {
        data.country = country;

        if (data.prov) {
          let regions;

          try {
            regions = await this.getRegions(country.id);
          } catch (err) {
            return data;
          }

          const region = regions.find((regionInfo) => regionInfo.id === data.prov);

          data.prov = region;
        }
      }
    }

    return data;
  }
  public async getActPatriotText() {
    // Should be an endpoint call, not implemented yet so we hardcoded
    return [
      {
        name: 'patriot_act',
        agreements_id: 1,
      },
    ];
  }
  public async updateUserInfo(lodeUserData) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return await this.http.post(this.updateUserInfoUrl, lodeUserData, { headers }).toPromise();
  }

  public async removeAccount() {
    this.store.dispatch(new ResetWalletAction());
    await this.storage.clear(); // clearing entire storage for now
  }

  public async getCountries() {
    const res = await this.http.get(this.countriesUrl).toPromise();
    return (res as any).data;
  }

  public async getLodeIdFromJwt() {
    //get lodeId from decoded jwt
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const decodedJwt = jwt_decode(jwt);
    return decodedJwt.lodeId;
  }

  public async getRegions(countryId) {
    const res = await this.http.get(this.regionsUrl.replace(':countryId', countryId)).toPromise();
    return (res as any).data;
  }

  public async getUserDevices() {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res = await this.http.get(this.userDevicesUrl + `?token=${jwt}`).toPromise();
    return (res as any).data;
  }
  public async accountLogOut() {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res = await this.http.post(this.logoutUrl + `?token=${jwt}`, {}).toPromise();
    return res as any;
  }

  public async deleteUserDevice(password, otp, device) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const verify = await this.http
      .post(this.enableVerify2FA + `?token=${jwt}`, { current_password: password, one_time_password: otp })
      .toPromise();

    const res = await this.http.post(this.deleteDeviceUrl + `?token=${jwt}`, device).toPromise();
    return (res as any).data;
  }

  public async disable2FA(password, otp) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http
      .post(this.disable2FAUrl + `?token=${jwt}`, { current_password: password, one_time_password: otp })
      .toPromise();

    return (res as any).data;
  }

  public async enable2FA(password, otp) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http
      .post(this.enableVerify2FA + `?token=${jwt}`, { current_password: password, one_time_password: otp })
      .toPromise();

    return (res as any).data;
  }

  //enable google 2fa (new app)
  public async enableUser2FA(otpValue) {
    let Verify2faUrl = this.enableVerifyUser2FA;
    const jwt = await this.returnJwtIfValid();
    const res = await this.http.post(Verify2faUrl + `?token=${jwt}`, { one_time_password: otpValue }).toPromise();

    return (res as any).data;
  }

  //enable sms 2fa (new app)
  public async enableUserSms2FA(otpValue) {
    let Verify2faUrl = this.enableSmsVerify2FA;
    const jwt = await this.returnJwtIfValid();
    const res = await this.http.post(Verify2faUrl + `?token=${jwt}`, { otp: otpValue }).toPromise();

    return (res as any).data;
  }

  //send sms for 2fa (new app)
  public async sendUserSms2FA(phoneNumber) {
    const jwt = await this.returnJwtIfValid();
    const res = await this.http.post(this.enableSmsVerifyUser2FA + `?token=${jwt}`, { phone: phoneNumber }).toPromise();

    return res as any;
  }

  // send otp via sms to disable 2fa (in New app)
  public async sendSmsToDisable2FA() {
    const jwt = await this.returnJwtIfValid();
    const res = this.http.get(this.sendDisable2FASmsUrl + `?token=${jwt}`).toPromise();
    return (res as any).data;
  }

  // Disable google 2fa (new app)
  public async disableGoogleUser2FA(otpValue) {
    const jwt = await this.returnJwtIfValid();
    let disable2faUrl = this.disableUser2FAUrl;
    const res = await this.http.post(disable2faUrl + `?token=${jwt}`, { one_time_password: otpValue }).toPromise();

    return (res as any).data;
  }

  // Disable sms 2fa (new app)
  public async disableSmsUser2FA(otpValue) {
    const jwt = await this.returnJwtIfValid();
    let disable2faUrl = this.disableSms2FAUrl;
    const res = await this.http.post(disable2faUrl + `?token=${jwt}`, { otp: otpValue }).toPromise();

    return (res as any).data;
  }

  // delete device for new app
  public async delete2FADevice(otpValue, device) {
    const jwt = await this.returnJwtIfValid();

    const res = await this.http.post(this.deleteDeviceUrl + `?token=${jwt}`, { unformatted_agent: device, otp: otpValue }).toPromise();
    return (res as any).data;
  }

  public async generateGoogle2fa() {
    const jwt = await this.returnJwtIfValid();
    // const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http.post(this.generateGoogle2faUrl + `?token=${jwt}`, {}).toPromise();

    return res as any;
  }

  public async updatePassword(currenPassword, newPassword, confirmPassword) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();

    const res = await this.http
      .post(this.updatePasswordUrl + `?token=${jwt}`, {
        current_password: currenPassword,
        password: newPassword,
        password_confirmation: confirmPassword,
      })
      .toPromise();

    return res as any;
  }

  public async deleteAccount(password, code2fa?) {
    const requestParam = { password };
    code2fa ? (requestParam[`one_time_password`] = code2fa) : null;

    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res = await this.http.post(this.deleteAccountUrl + `?token=${jwt}`, requestParam).toPromise();

    return res as any;
  }

  public async registerGetVerifyCode(newUser) {
    const code = await this.http
      .post(`${this.validatePhoneNumberUrl}`, { phone: newUser.phone, phone_country_code: newUser.phone_country_code })
      .toPromise();
    const user = await this.http.post(`${this.registerUserUrl}`, newUser).toPromise();
    return { code: code as any, user: user as any };
  }

  public async captureEmail(newEmail) {
    const res = await this.http.post(`${this.captureEmailUrl}`, newEmail).toPromise();
    return res as any;
  }

  public async registerUser(newUser) {
    const user = await this.http.post(`${this.registerUserUrl}`, newUser).toPromise();
    return { user: user as any };
  }

  public async VerifyUserEmail(payload) {
    // To verify email during registration
    const user = await this.http.post(`${this.verifyEmail}`, payload).toPromise();
    return { user: user as any };
  }

  public async VerifyUserPhone(payload) {
    // To verify phone during registration
    const user = await this.http.post(`${this.verifyPhone}`, payload).toPromise();
    return { user: user as any };
  }

  public async SendSmsCode(payload) {
    // To send sms code for account activation
    const user = await this.http.post(`${this.verifyPhoneViaSms}`, payload).toPromise();
    return { user: user as any };
  }

  public async acceptAgreement(payload) {
    // To accept load tos agreement
    const user = await this.http.post(`${this.acceptAgreementUrl}`, payload).toPromise();
    return { user: user as any };
  }

  public async resendOtpEmail(payload) {
    // To resend otp to email for account verification
    const user = await this.http.post(`${this.verifyEmailViaOtp}`, payload).toPromise();
    return { user: user as any };
  }

  public async forgotPasswordOtpRequest(payload) {
    // To send otp to email for forgot password
    const user = await this.http.post(`${this.forgotPasswordOtpRequestUrl}`, payload).toPromise();
    return { user: user as any };
  }

  public async forgotPasswordVerifyOtp(payload) {
    // To verify otp for forgot password
    const user = await this.http.post(`${this.forgotPasswordVerifyOtpUrl}`, payload).toPromise();
    return { user: user as any };
  }

  public async updateUserPassword(payload) {
    // To update user password
    const user = await this.http.post(`${this.updateUserPasswordUrl}`, payload).toPromise();
    return { user: user as any };
  }

  public async resendCode(phone, phoneCountryCode) {
    const code = await this.http.post(`${this.validatePhoneNumberUrl}`, { phone, phoneCountryCode }).toPromise();
    return code as any;
  }

  public async updatePhoneVerification(phoneVerification) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    const formData = new FormData();
    formData.append('phone_verification', phoneVerification);
    return await this.http.post(`${this.updatePhoneVerificationUrl}?token=${jwt}`, formData).toPromise();
  }

  public async updatePhoneNumberSMSSend(phone, phoneCountryCode, phoneVerification?) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });

    return await this.http.post(this.updatePhoneNumberSMSSendUrl, { phone, phone_country_code: phoneCountryCode,phone_verification: phoneVerification ? phoneVerification: false }, { headers }).toPromise();
  }

  public async phoneNumberSMSVerify(code) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res = await this.http.post(`${this.phoneNumberSMSVerifyUrl}?token=${jwt}`, { code }).toPromise();
    return (res as any).success;
  }

  public async updateUserLanguage(lang) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    const res = await this.http.post(`${this.updateUserLanguageUrl}?token=${jwt}`, { lang }).toPromise();
    return (res as any).success;
  }

  public async updateNewsletterList(updateValue) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    const res = await this.http.post(`${this.updateNewsletterListUrl}?token=${jwt}`, { list_newsletter: updateValue }).toPromise();
    return (res as any).success;
  }

  public async updateAnnounceList(updateValue) {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    const res = await this.http.post(`${this.updateAnnounceListUrl}?token=${jwt}`, { list_announce: updateValue }).toPromise();
    return (res as any).success;
  }

  show2fa(username, password, isRetry?: boolean, isLink?: boolean) {
    return new Promise(async (resolve, reject) => {
      const { toast_msg } = await this.translate.get('alerts.prompt_2fa').toPromise();
      let result;

      if (isRetry) {
        const toast = await this.toastController.create({
          message: toast_msg,
          duration: 2000,
        });
        toast.present();
      }

      const modal = await create2faModal(this.modalController, await this.isJwtValid(), this.removeAccount.bind(this));
      this.clearRefreshInterval();
      const { data: otp } = await modal.onDidDismiss();

      if (otp === TWO_FA_CANCELLED || otp === TWO_FA_UNLINK_ACCOUNT) {
        return resolve(otp);
      }

      let res: any;

      try {
        res = await this.login(username, password, otp);

        if (await this.isJwtValid(res.data.access_token)) {
          this.languageService.setLanguage(res.data.lang);
          this.store.dispatch(new UpdateJwtAction({ jwtToken: res.data.access_token, lodeid: res.data.lodeid }));

          if (isLink) {
            let queryParams = { jwtData: res.data.access_token, username, password, lodeid: res.data.lodeid };
            await this.authService.openAuthPinModal(AUTH_REASON.LINK_LODE_ACCOUNT, null, queryParams);

            // await this.nav.navigateForward(['/auth', AUTH_REASON.LINK_LODE_ACCOUNT], {
            //   skipLocationChange: true,
            //   queryParams: { jwtData: res.data.access_token, username, password, lodeid: res.data.lodeid }
            // });
          }
          return resolve(true);
        }
      } catch (err) {
        result = await this.show2fa(username, password, true, isLink);
      }
      return resolve(result);
    });
  }

  async getHlTokensBalance() {
    let agxBalance;
    let lodeBalance;
    const tokens = [];
    const supportedAssetList = await this.tokenManager.getTokenList();

    tokens.push({ ...supportedAssetList['HL-LODE'], balance: 0, keyringId: 'HL-LODE' });
    tokens.push({ ...supportedAssetList['HL-AGX'], balance: 0, keyringId: 'HL-AGX' });

    try {
      const jwt = await this.returnJwtIfValid();
      agxBalance = await this.agx.getAgxBalance(jwt);
      lodeBalance = await this.lode.getLodeBalance(jwt);

      tokens[0].balance = new BigNumber(lodeBalance.balance).toFixed();
      tokens[1].balance = new BigNumber(agxBalance.balance).toFixed();
      tokens[1].pendingBalance = new BigNumber(agxBalance.pendingBalance).toFixed();
    } catch (err) {
      // no linked lode acc most likely
    }

    return tokens;
  }

  async getVirtualCards() {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const cards = await this.http.get(`${this.listCardsUrl}?token=${jwt}`).toPromise();

    return cards;
  }

  async getWirePriceQuote(
    amount: number | string,
    promoCode: string = null,
    readOnly: string = '0',
    coin: string = 'agx',
    currency: string = 'usd'
  ) {
    let params = new HttpParams();

    const jwt = await this.returnJwtIfValid();

    if (!jwt) {
      throw new Error('JWT is not valid');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    // params = params.append('token', jwt);
    params = params.append('currency', currency);
    params = params.append('qty', amount.toString());
    params = params.append('readonly', readOnly);
    params = params.append('skip_auto_order', '1');
    params = params.append('paymentType', '5');

    if (promoCode) {
      params = params.append('promocode', promoCode);
    }

    return this.http.get(`${this.getPriceQuoteUrl.replace('{{coin}}', coin.toLowerCase())}`, { headers, params }).toPromise();
  }

  async getPriceQuote(
    amount: number | string,
    promoCode: string = null,
    readOnly: string = '0',
    coin: string = 'agx',
    currency: string = 'usd'
  ): Promise<LaravelPriceQuote> {
    let params = new HttpParams();

    const jwt = await this.returnJwtIfValid();

    if (!jwt) {
      throw new Error('JWT is not valid');
    }
    params = params.append('token', jwt);
    params = params.append('currency', currency);
    params = params.append('qty', amount.toString());
    params = params.append('readonly', readOnly);

    if (promoCode) {
      params = params.append('promocode', promoCode);
    }
    let resp;
    try {
      resp = await this.http.get(`${this.getPriceQuoteUrl.replace('{{coin}}', coin.toLowerCase())}`, { params }).toPromise();
      return resp as LaravelPriceQuote;
    } catch (err) {
      resp = err.error;
      Logger.error('getPriceQuote', err);
    }
  }

  async getPriceQuoteForMetaPayment(amount: string, promoCode: string = null, readOnly: string = '1', currency: string = 'usd',token:string,isTokenAmountChange?:boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('currency', currency);
    httpParams = httpParams.append('readonly', readOnly);
    if (promoCode) {
      httpParams = httpParams.append('promocode', promoCode);
    }
    httpParams = httpParams.append('paymentType', 'deliver');
    if(isTokenAmountChange){
      httpParams = httpParams.append('qty', amount);
    }else{
      httpParams = httpParams.append('usd', amount);
    }
    httpParams = httpParams.append('skip_auto_order', '1');

    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const options = { params: httpParams, headers };
    return this.http.get(`${this.baseApi}/token/${token.toLocaleLowerCase()}/getpricequote`, options).toPromise();
  }

  async getKYCToken() {
    // const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const jwt = await this.returnJwtIfValid();
    const res = await this.http
      .post(this.getKYCTokenUrl + `?token=${jwt}`, { token: jwt })
      .pipe(
        map((val: any) => {
          if (val?.arg === null) throw new Error('Invalid response');
          return val;
        }),
        retry(2)
      )
      .toPromise();

    return res as any;
  }

  getPublicInfo() {
    return this.http.get(this.publicInfoUrl).pipe().toPromise();
  }

  async checkMembersPortalServiceStatus(): Promise<boolean> {
    try {
      const result = await this.getPublicInfo();
      return true;
    } catch (err) {
      Logger.error('checkMembersPortalStatus', inspect(err));
      return false;
    }
  }

  async getFeatureFlags(flags) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }

    const formData = new FormData();
    formData.append('flags', JSON.stringify(flags));
    const res = await this.http.post(this.getFeatureFlagsUrl + `?token=${jwt}`, formData).toPromise();
    return res as any;
  }

  async getTokenDetailsFromGrams(tokenType: string, grams: number): Promise<TokenDetails> {
    try {
      const jwt = await this.returnJwtIfValid();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      });
      const res = await this.http
        .get(`${this.getTokenDetailsFromGramsUrl}?tokenType=${tokenType}&totalGrams=${grams}`, { headers })
        .toPromise();
      return res as TokenDetails;
    } catch (error: any) {
      Logger.error('getTokenDetailsFromGrams', error);
      throw error;
    }
  }

  async getEquivalentTokenAmountFromWeight(tokenType: TokenType, unit: Unit, weight: number, metal: Metal) {
    try {
      const jwt = await this.returnJwtIfValid();
      const headers = new HttpHeaders({
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      });
      const res = await this.http
        .get(`${this.getTokenConversion}?token=${tokenType}&unit=${unit}&weight=${weight}&metal=${metal}`, { headers })
        .toPromise();
      return res as LaravelResponse;
    } catch (error: any) {
      Logger.error('getTokenDetailsFromGrams', error);
      throw error;
    }
  }

  async createBullionOrder(cart: any, review: any, promo?, asset?) {
    let params = new HttpParams();
    const headers = new HttpHeaders();
    const formData = new FormData();

    const jwt = await this.returnJwtIfValid();

    if (!jwt) {
      throw new Error('JWT is not valid');
    }
    params = params.append('token', jwt);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    formData.append('items', JSON.stringify(cart));
    formData.append('review', JSON.stringify(review));
    formData.append('tokenType', asset);
    formData.append('promoCode', promo);
    try {
      const res = await this.http.post(this.payByDeliveryUrl, formData, { headers, params }).toPromise();
      return res;
    } catch (err) {
      if (err.error.error_code == 'validation_error') {
        return err;
      }
      return false;
    }
  }

  async getNewMemberPromoData() {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res: any = await this.http.get(`${this.getNewMemberPromoDataUrl}?token=${jwt}`).toPromise();
    return res;
  }

  async getPurchaseHistory() {
    const jwt = await this.returnJwtIfValid();
    if (!jwt) {
      throw new Error('No LODE account linked');
    }
    const res: any = await this.http.get(`${this.getUnifiedPriceHistoryUrl}?token=${jwt}`).toPromise();

    res.forEach((element) => {
      element[`status`] = 1;
    });

    return res;
  }

  async setModalInfo(modaldata) {
    // To share "Learn More" modal data btw components
    this.modalInfo = modaldata;
  }
  async getModalInfo() {
    // To share "Learn More" modal data btw components
    return this.modalInfo;
  }
  async setUserContactInfo(data) {
    this.userContactInfo = data;
  }
  async getUserContactInfo() {
    return this.userContactInfo;
  }

  presentToast = async (msg: string) => {
    const toast = await this.toastController.create({
      // header: hdr,
      message: msg,
      position: getToastPosition(this.platform),
      duration: 4000,
    });
    await toast.present();
  };
}

export interface TokenDetails {
  tokenAmount: string;
  priceTier: string;
}

export interface LaravelResponse {
  data: any;
  status: boolean;
}

export enum Currency {
  USD = 'USD',
}

export enum PaymentType {
  silvercard = 'silvercard',
}

export enum Network {
  syscoin = 'syscoin',
}
export interface LaravelPriceQuote {
  data: {
    token: 'agx' | 'lode' | 'aux';
    qty: number;
    currency_code: Currency;
    spot_price: string;
    base_premium: number;
    base_premium_discount_percent: string; // "1%",
    premium: number;
    discount: number;
    price_total: number;
    price_total_before_discount: number;
    price_total_before_fees: number;
    ppt: number;
    payment_type: PaymentType;
    fee_amount: number;
    mint_destination: Network;
    user_id: number;
    lodeid: number;
  };
}
