import { Component, OnInit } from '@angular/core';
import { AppModeService } from '../../../angular-wallet-base/services/app-mode.service';
import { ModalController, NavController } from '@ionic/angular';
import { MembersPortalService } from '../../../angular-wallet-base/services/members-portal.service';
import { SubscriptionListenerComponent } from '../../../angular-wallet-base/SubscriptionListenerComponent';
import { Store } from '@ngrx/store';
import { AppState } from '../../../angular-wallet-base/store/appState';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { StorageService } from 'src/app/angular-wallet-base/services/storage.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { take } from 'rxjs/operators';
import { ResetWalletComponent } from '../reset-wallet/reset-wallet.component';
import { WALLET_STORAGE_KEY } from 'src/app/angular-wallet-base/constants';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})

export class LogoutComponent extends SubscriptionListenerComponent implements OnInit {

  public isDesktopMode: boolean;
  public loadingRemoveAccount: boolean = false;
  public checkWallet = false;

  constructor(
    public appMode: AppModeService,
    private authService: AuthService,
    private storage: StorageService,
    private modalctrl: ModalController,
    private membersPortalService: MembersPortalService,
    protected store: Store<AppState>,
  ) {
    super(store);
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  async ngOnInit() {
    const vaultWallet = await this.storage.get(WALLET_STORAGE_KEY);
    this.checkWallet = vaultWallet ? true : false;
  }

  closeModal() {
    this.modalctrl.dismiss();
  }

  async initiateLogout() {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();

    let cssClass = '';
    cssClass += (this.isDesktopMode) ? 'logout-reset-wallet-modal-desktop' : 'logout-reset-wallet-modal';
    cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';

    const resetModal = await this.modalctrl.create({
      component: ResetWalletComponent,
      cssClass,
      initialBreakpoint: (this.isDesktopMode) ? undefined : 0.45,
      breakpoints: (this.isDesktopMode) ? undefined : [0, 0.45, 0.6, 1],
      id: 'resetWallet'
    });
    await resetModal.present();
    await resetModal.onDidDismiss().then(async resp => {
      if ((resp && resp.data && resp.data.hasOwnProperty('reset'))) {
        await this.removeAccount(resp.data.reset);
      }
    });
  }

  async removeAccount(resetWallet = false) {
    this.loadingRemoveAccount = true;
    const logOutRes = await this.membersPortalService.accountLogOut();
    if (logOutRes.message === 'Successfully logged out.') {
      await this.authService.logoutAccount({ resetWallet });
      this.loadingRemoveAccount = false;
      this.modalctrl.dismiss({ status: 1 });
    } else {
      this.loadingRemoveAccount = false;
    }
  }

}
