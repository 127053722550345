import { Action } from '@ngrx/store';

export enum bankAccountActionTypes {
  BANK_ACCOUNT_GET_LIST_REQUEST = '[bankAccount] Get bank accounts request',
  BANK_ACCOUNT_GET_LIST_SUCCESS = '[bankAccount] Get bank accounts success',
  BANK_ACCOUNT_GET_LIST_ERROR = '[bankAccount] Get bank accounts error',
  BANK_ACCOUNT_GET_ASSET_LIST_REQUEST = '[bankAccount] Get bank accounts asset request',
  BANK_ACCOUNT_UPDATE_ASSET_LIST = '[bankAccount] update bank accounts assets request',
  BANK_ACCOUNT_GET_BALANCE_REQUEST = '[bankAccount] Get bank account balance request',
  BANK_ACCOUNT_GET_BALANCE_ERROR = '[bankAccount] Get bank account balance error',
  BANK_ACCOUNT_GET_BALANCE_SUCCESS = '[bankAccount] Get bank account balance success',
  BANK_ACCOUNT_GET_TRANSACTION_HISTORY_REQUEST = '[bankAccount] Get bank account history request',
  BANK_ACCOUNT_GET_TRANSACTION_HISTORY_SUCCESS = '[bankAccount] Get bank account history success',
  BANK_ACCOUNT_GET_TRANSACTION_HISTORY_ERROR = '[bankAccount] Get bank account history error',
  PT_TOKEN_SET_SEND_DATA = '[bankAccount] PT Token set send data',
  ADD_BANKING_HISTORY = '[bankAccount] Add trade orders history',
  GET_BANKING_HISTORY = '[bankAccount] Get all trade history',
  PLAID_SET_LINK_TOKEN = '[bankAccount] Plaid link token',
  RESET_BANK_ACCOUNT_DATA = '[bankAccount] Reset bank account data',
}

export class SetPlaidLinkToken implements Action {
  readonly type = bankAccountActionTypes.PLAID_SET_LINK_TOKEN;
  constructor(readonly payload: string) {}
}

export class GetBankAccountRequestAction implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_REQUEST;
  constructor() { }
}

export class GetBankAccountSuccessAction implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_SUCCESS;
  constructor(readonly payload: Array<any>) { }
}

export class GetBankAccountErrorAction implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_ERROR;
  constructor() { }
}

export class GetBankAccountBalanceRequest implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_REQUEST;
  constructor() { }
}

export class GetBankAccountBalanceError implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_ERROR;
  constructor() { }
}

export class GetBankAccountBalanceSuccess implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_SUCCESS;
  constructor(readonly payload: number) { }
}

export class GetBankAccountTransactionHistoryRequest implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_REQUEST;
  constructor() { }
}

export class GetBankAccountTransactionHistorySuccess implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_SUCCESS;
  constructor(readonly payload: Array<any>) { }
}

export class GetBankAccountTransactionHistoryError implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_ERROR;
  constructor() { }
}

export class SetPTTokenSendDataAction implements Action {
  readonly type = bankAccountActionTypes.PT_TOKEN_SET_SEND_DATA;
  constructor(readonly payload: { amount: string, address: string, token: string }) { }
}

export class GetBankAccountAssetRequestAction implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_GET_ASSET_LIST_REQUEST;
  constructor() { }
}

export class UpdateBankAccountAssetAction implements Action {
  readonly type = bankAccountActionTypes.BANK_ACCOUNT_UPDATE_ASSET_LIST;
  constructor(readonly payload: Array<any>) { }
}

export class AddBankingHistoryAction implements Action {
  readonly type = bankAccountActionTypes.ADD_BANKING_HISTORY;
  constructor(readonly payload: { data: any[], pageNumber: number, pageSize: number, loadedAll: boolean }) { }
}

export class GetBankingHistoryAction implements Action {
  readonly type = bankAccountActionTypes.GET_BANKING_HISTORY;
  constructor(readonly initialCall: boolean = false) { }
}

export class ResetBankAccountDataAction implements Action {
  readonly type = bankAccountActionTypes.RESET_BANK_ACCOUNT_DATA;
  constructor() { }
}

