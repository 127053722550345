import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BackButtonComponent } from './back-button.component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule
    ],
    declarations: [BackButtonComponent],
    exports: [BackButtonComponent]
})
export class BackButtonModule { }
