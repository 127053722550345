import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { NetworkService } from './network.service';
import { MembersPortalService } from './members-portal.service';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { WebSocketAddressNotifierService } from './WebSocketAddressNotifier.service';
import { GetTokenBalancesRequestAction } from '../actions/wallet.actions';
import { getTokenKeyring } from '../store/wallet';
import { getServiceStatus } from '../store/connection';
import { BankAccountService } from './bank-account.service';
import { environment } from '../../../environments/environment';
import { AvalancheService } from './avalanche.service';
import { SolanaService } from './solana.service';
import { Logger } from './logger.service';
import { inspect } from 'util';
import { AssetSymbols, BaseChainSymbol } from '../../global';

interface StatusesAPIResponse {
  gasStation: boolean;
  vdcServer: boolean;
  lodeSysBB: boolean;
  bcfSysBB: boolean;
  btcBB: boolean;
  ethRPC: boolean;
  sysBlockCount?: number;
  avaxSnowTrace: boolean;
  avaxRpc: boolean;
  solRpc: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ServiceCheckerService {

  private servicesInterval;
  private CHECK_SERVICES_INTERVAL = 120000; // check services every 2 mins
  
  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private network: NetworkService,
              private membersPortal: MembersPortalService,
              private bankAccountService: BankAccountService,
              private avalancheService: AvalancheService,
              private solanaService: SolanaService,
              private wsService: WebSocketAddressNotifierService) {
    
  }

  // check services every X milliseconds
  setCheckServicesInterval() {
    if (!this.servicesInterval) {
      this.servicesInterval = setInterval(async () => {
        await this.updateAllServices(true);
      }, this.CHECK_SERVICES_INTERVAL);
    }
  }

  closeCheckServicesInterval() {
    if (this.servicesInterval) {
      clearInterval(this.servicesInterval);
    }
  }

  fetchStatuses(): Promise<object> {
    return this.http.get(environment.EXTERNAL_APIS.STATUS_CHECK_API + '/status').toPromise();
  }

  async checkVdcService(): Promise<boolean> {
    try {
      const statuses = (await this.fetchStatuses()) as StatusesAPIResponse;
      return statuses.vdcServer;
    } catch (err) {
      Logger.error('checkVdcService', inspect(err));
      return false;
    }
  }

  async checkGasStationService(): Promise<boolean> {
    try {
      const statuses = await this.fetchStatuses() as StatusesAPIResponse;

      return statuses.gasStation;
    } catch (err) {
      Logger.error('checkGasStationService', inspect(err));
      return false;
    }
  }

  async checkBBService(coin: 'sys' | 'btc'): Promise<boolean> {
    const parsedCoin = coin.toString().toLocaleLowerCase();
    const statuses = await this.fetchStatuses() as StatusesAPIResponse;

    switch (parsedCoin) {
      case 'sys':
        return statuses.lodeSysBB;
      case 'btc':
        return statuses.btcBB;
      default:
        return false;
    }
  }


  async checkWebSocketServices(service: 'sys' | 'eth' | 'spt' | 'avax' | 'sol'): Promise<boolean> {
    try {
      return this.wsService.getWebSocketStatus(service);
    } catch (err) {
      Logger.error('checkWebSocketServices', inspect(err));
      return false;
    }
  }

  async updateService(service: 'sys'|'eth'|'btc'|'gas-station'|'vdc'|'memberPortal'|'websocketETH'|'websocketSYS'|'websocketSPT'|'websocketAVAX'|'websocketSOL'|'bank-account'|'snowTrace'|'avaxRpc'|'solRpc') {
    let parsedService;

    const services: any = {};
    let selected;

    switch (service.toLocaleLowerCase()) {
      case 'sys':
        parsedService = 'sysBB';
        selected = 'sys';
        break;
      default:
    }
    Logger.info('service', service);
    if (parsedService && parsedService.indexOf('BB') !== -1) {
      Logger.info('parsedService', parsedService);
      services[parsedService] = await this.checkBBService(selected.toLocaleLowerCase());
    }

    if (service === 'btc') {
      Logger.info('goto BTC', service);
      services['btcBB'] = await this.network.checkBtcService();
    }

    if (service === 'gas-station') {
      services.gasStation = await this.checkGasStationService();
    }

    if (service === 'vdc') {
      services.vdcServer = await this.checkVdcService();
    }

    if (service === 'memberPortal') {
      services.memberPortal = await this.membersPortal.checkMembersPortalServiceStatus();
    }

    if (service === 'websocketETH') {
      services.websocketETH = await this.checkWebSocketServices('eth');
    }

    if (service === 'websocketSYS') {
      services.websocketSYS = await this.checkWebSocketServices('sys');
    }

    if (service === 'websocketSPT') {
      services.websocketSPT = await this.checkWebSocketServices('spt');
    }
    
    if (service === 'websocketAVAX') {
      services.websocketAVAX = await this.checkWebSocketServices('avax');
    }
    
    if (service === 'websocketSOL') {
      services.websocketSOL = await this.checkWebSocketServices('sol');
    }
    
    if (service === 'bank-account') {
      services.bankAccount = await this.bankAccountService.getACHStatus();
    }
    
    if (service === 'snowTrace') {
      services.avaxSnowTrace = await this.avalancheService.checkSnowTraceStatus();
    }

    if (service === 'avaxRpc') {
      services.avaxRpc = await this.network.checkRpcAvalancheService();
    }

    if (service === 'eth') {
      services.ethRPC = await this.network.checkEthRPCService();
    }
    
    if (service === 'solRpc') {
      services.solRpc = await this.solanaService.checkRpcSolanaService();
    }
    this.store.dispatch(new SetServiceStatusAction(services));
  }


  async updateWebSocketServices() {
    const services: any = {};
    const promises = [
      this.checkWebSocketServices('eth'),
      this.checkWebSocketServices('sys'),
      this.checkWebSocketServices('spt'),
      this.checkWebSocketServices('avax'),
      this.checkWebSocketServices('sol')
    ];
    const results = await Promise.all(promises.map(p => p.catch(e => e)));
    for (let i = 0; i < results.length; i++) {
      if (results[i] instanceof Error) {
        Logger.error('updateWebSocketServices', inspect(results[i]));
      } else {
        // set the result to the corresponding services
        services[Object.keys(services)[i]] = results[i];
      }
    }
    this.store.dispatch(new SetServiceStatusAction(services));
    return services;
  }

  async updateAllServices(inInterval = false) {
    const services: any = {};
    const servicesOld = await this.store.select(getServiceStatus).first().toPromise();
    const promises = [
      this.checkWebSocketServices('eth'),
      this.checkWebSocketServices('sys'),
      this.checkWebSocketServices('spt'),
      this.checkWebSocketServices('avax'),
      this.checkWebSocketServices('sol'),
      this.bankAccountService.getACHStatus(),
      this.avalancheService.checkSnowTraceStatus(),
      this.network.checkRpcAvalancheService(),
      this.solanaService.checkRpcSolanaService(),
      this.checkBBService('sys'),
      this.network.checkEthRPCService(),
      this.network.checkBtcService(),
      this.checkGasStationService(),
      this.checkVdcService(),
      this.membersPortal.checkMembersPortalServiceStatus(),
    ];
    const results = await Promise.all(promises.map(p => p.catch(e => e)));
    for (let i = 0; i < results.length; i++) {
      if (results[i] instanceof Error) {
        Logger.error('updateAllServices', inspect(results[i]));
      } else {
        // set the result to the corresponding services
        services[Object.keys(services)[i]] = results[i];
      }
    }
    this.store.dispatch(new SetServiceStatusAction(services));
    if (inInterval) {
      Logger.info('Loading services status');
      this.updateBalancesIfNotAvailableBefore(services, servicesOld);
    }
    return services;
  }

  updateBalancesIfNotAvailableBefore(services, servicesOld) {
    const promises = Object.keys(services)
      .filter(key => {
        const service: string = this.parsedBBKey(key);
        return service !== '' && servicesOld[key] !== services[key] && services[key] === true;
      })
      .map(async key => {
        const service: string = this.parsedBBKey(key);
        const info = await this.store.select(getTokenKeyring, {keyringId: service}).first().toPromise();
        if (info) {
          Logger.info(`updating balances: ${key}`);
          return this.store.dispatch(new GetTokenBalancesRequestAction({
            chains: [{symbol: service, address: info.address}]
          }));
        } else {
          Logger.error(`No keyring found for ${service}`);
          return Promise.reject(`No keyring found for ${service}`);
        }
      });
    Promise.all(promises.map(p => p.catch(e => e)));
  }

  private parsedBBKey(key: string): AssetSymbols | 'debit-card' | '' {
    switch (key) {
      case 'sysBB':
        return 'SYS';
      case 'ethRPC':
        return 'ETH';
      case 'btcBB':
        return 'BTC';
      case 'vdcServer':
        return 'debit-card';
      case 'avaxSnowTrace':
        return 'AVAX';
      case 'avaxRpc':
        return 'AVAX';
      case 'solRpc':
        return 'SOL';
      default:
        return '';
    }
  }

}
