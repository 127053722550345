import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Directive()
export abstract class AbstractDebounceDirective implements OnDestroy {
  @Input()
  public debounceTime: number;

  protected emitEvent$: Subject<any>;
  protected subscription$: Subject<void>;

  constructor() {
    this.debounceTime = 500;
    this.emitEvent$ = new Subject<any>();
    this.subscription$ = new Subject<void>();
  }

  ngOnInit(): void {
    this.emitEvent$
      .pipe(
        takeUntil(this.subscription$),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap(value => this.emitChange(value))
      )
      .subscribe();
  }

  /**
   * Override this method in the child class as it may have
   * specific logic to handle the event along with a declared output decorator.
   * @param value 
   */
  public emitChange(value: any): void {}

  ngOnDestroy(): void {
    this.subscription$.next();
    this.subscription$.complete();
  }
}
