import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionDetailComponent } from './transaction-detail.component';
import { ImagePreloaderModule } from '../../angular-wallet-base/directives/image-preloader/image-preloader.module';
import { SmallToCryptoPipeModule } from '../../angular-wallet-base/pipes/smalltocrypto/smalltocrypto.module';

@NgModule({
  declarations: [TransactionDetailComponent],
  imports: [CommonModule, IonicModule, TranslateModule, ImagePreloaderModule, SmallToCryptoPipeModule],
  exports: [TransactionDetailComponent]
})
export class TransactionDetailModule {}
