import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaxDecimalsDirective } from './max-decimals.directive';

@NgModule({
  declarations: [MaxDecimalsDirective],
  imports: [CommonModule, IonicModule, TranslateModule],
  exports: [MaxDecimalsDirective]
})
export class MaxDecimalsModule { }
