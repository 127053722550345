import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavController, AlertController, ToastController, Platform, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MembersPortalService } from 'src/app/angular-wallet-base/services/members-portal.service';
import { getToastPosition } from 'src/app/angular-wallet-base/utils';
import { WalletService } from 'src/app/angular-wallet-base/services/wallet.service';
import { EMAILREGEX } from 'src/app/angular-wallet-base/constants';

class InfoEmail {
  counter = 0;
  email = '';
  never = false;
}

export const EMAIL_STORAGE_KEY = 'info_email';

@Component({
  selector: 'capture-email-form',
  templateUrl: './capture-email-form.component.html',
  styleUrls: ['./capture-email-form.component.scss'],
})
export class CaptureEmailFormComponent {
  
  @Input() showLinkAccount: boolean = true;
  @Input() showDoNotAsk: boolean = true;
  @Input() showSkipForNow: boolean = true;
  @Input() setupPage: boolean = false;
  @Input() showCancelBtn: boolean = false;
  public email: any = '';
  captureEmailForm: FormGroup;
  public newEmail: any = {};
  public info: InfoEmail;
  public loadingCreateAccount: boolean = false;
  public stopPopup: boolean = false;
  public errorMessages = {
    email: [
      { type: 'required', message: ''},
      { type: 'email', message: ''}
    ]
  };
  public isSkip = false;

  constructor(
    private translate: TranslateService,
    public formBuilder: FormBuilder,
    private platform: Platform,
    private toast: ToastController,
    private membersPortalService: MembersPortalService,
    private walletService: WalletService,
    private nav: NavController,
    private modalController: ModalController
  ) {
    this.captureEmailForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
         Validators.pattern(EMAILREGEX)
      ])),
    });
  }
  
  async ngOnInit() {
    this.setErrorMsg();
  }
  
  async setErrorMsg() {
    this.errorMessages = {
      email: [{
        type: 'required',
        message: await this.translate.get('lode_acc.error.email_required').toPromise()
        },
        {
          type: 'pattern',
          message: await this.translate.get('lode_acc.error.email_invalid').toPromise()
        }]
    };
  }

  linkExistingAccount() {
    this.loadingCreateAccount = false;
    this.modalController.dismiss(null, null, "CaptureEmail");
    this.nav.navigateForward('/wallet/settings', {
      queryParams: {
        tab: 'profile'
      }
    });
  }
  
  async handleSkip() {
    this.isSkip = true;
    this.info = await this.walletService.getStorageEmail();
    this.info.never = this.stopPopup;
    this.walletService.setStorageEmail(this.info);
    // Is coming from setup page
    if(this.setupPage === true) {
      this.completeSetup();
    } else {
      this.modalController.dismiss(null, null, "CaptureEmail");
    }
  }
  
  completeSetup() {
    setTimeout(async () => {
      await this.walletService.completeSetup();
      await this.nav.navigateRoot('/wallet/assets');
    }, 100)
  }
  
  async handleCaptureEmail() {
    this.loadingCreateAccount = true;
    this.newEmail = this.captureEmailForm.value;
 
    if (this.captureEmailForm.invalid) {
      this.loadingCreateAccount = false;
      return;
    }
    try {
      this.info = await this.walletService.getStorageEmail();
      this.info.email = this.newEmail.email;
      this.info.never = true;
      this.walletService.setStorageEmail(this.info);
      let res = await this.membersPortalService.captureEmail(this.newEmail);
      this.loadingCreateAccount = false;
      if(this.setupPage === true) {
        this.completeSetup();
      } else {
        this.modalController.dismiss(res, null, "CaptureEmail");
      }
    } catch (err) {
      this.loadingCreateAccount = false;
      if(err.error.errors.email) {
        await this.presentToast('settings.toast_msgs.err_hdr', 'intro.slideshow.five.component.email_err_msg');
      }
    }
  }
  

  async presentToast(hdr,msg) {
    const toast = await this.toast.create({
      header: await this.translate.get(hdr).toPromise(),
      message: await this.translate.get(msg).toPromise(),
      position: getToastPosition(this.platform),
      duration: 4000
    });
    await toast.present();
  }
  
  onCancelBtn() {
    this.modalController.dismiss('cancelBtn', null, "CaptureEmail");
  } 
  
}
