import { Action } from '@ngrx/store';
import { PriceOracleState } from '../store/priceOracle';
import { PriceOracleActionTypes, SetPriceOracleDataAction } from '../actions/priceOracle.actions';

export const initialPriceOracleState = null;

export function priceOracleReducer(state: PriceOracleState = initialPriceOracleState, action: Action): PriceOracleState {
  let payload;
  switch (action.type) {
    case PriceOracleActionTypes.SET_PRICE_ORACLE_DATA:
      payload = (action as SetPriceOracleDataAction).payload;
      return payload.data;

    case PriceOracleActionTypes.CLEAR_PRICE_ORACLE_DATA:
      return {};

    case PriceOracleActionTypes.RESET_PRICE_ORACLE_DATA:
      return { ...initialPriceOracleState };

    default:
      return state;
  }
}
