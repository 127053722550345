import { Injectable } from '@angular/core';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { CoinGeckoService } from './coingecko.service';
import { GraphqlService } from './graphql.service';
import { StorageService } from './storage.service';
import * as moment from 'moment';
import { BlockBookService } from './blockbook.service';
import { AvalancheService } from './avalanche.service';
import { getKeychain, getTokenKeyring } from '../store/wallet';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import {
  getReceiveTypeValueFromtxAmount,
  getSendTypeValueFromtxAmount,
  getTokenFiatValue,
  getTokenInfoArray,
  getTransactionAmountsByAddress,
  getTransactionAmountsByAddressSol,
  getTransactionAmountsByEVMAddress,
  numberFormatter,
  setNumberToPrecision
} from '../utils';
import { SmallToCryptoPipe } from '../pipes/smalltocrypto/smalltocrypto.pipe';
import { BehaviorSubject } from 'rxjs';
import { isNil } from '../ts-util';
import { inspect } from 'util';
import { GRAPH_DATA_HOME, GRAPH_DATA_OF_ALL_TOKENS, GRAPH_DATA_TRADE, PRICE_LIST_OF_TOKENS } from '../constants';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexGrid,
  ApexMarkers,
  ApexResponsive,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from 'ng-apexcharts';
import { take } from 'rxjs/operators';
import { supportedAssetList } from '../lib/supported-assets/supported-assets';
import { getBaseCurrency } from '../store/userPreferences';
import { now } from 'moment';
import { SysnevmService } from './syscoin-nevm.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  responsive: ApexResponsive;
  grid: ApexGrid;
  fill: ApexFill;
  markers: ApexMarkers;
};
@Injectable({
  providedIn: 'root'
})
export class GraphService {
  public coinList = ['SYS', 'BTC', 'ETH', 'SOL', 'USDC', 'AVAX', 'USDC.e', 'WAVAX'];
  public yearDatesArray = [];
  public isrepeated;
  public chartYAxis = new BehaviorSubject(['1D', '1W', '1M', 'YTD', '1Y', 'ALL']);
  public chartOptions = {};
  public updateOptionsData = new BehaviorSubject({
    '1D': {
      xaxis: {
        min: moment().subtract(2, 'days').unix(),
        max: moment().endOf('day').unix(),
      },
    },
    '1W': {
      xaxis: {
        min: moment().subtract(7, 'days').unix(),
        max: moment().endOf('day').unix(),
      },
    },
    '1M': {
      xaxis: {
        min: moment().subtract(1, 'months').unix(),
        max: moment().endOf('day').unix(),
      },
    },
    YTD: {
      xaxis: {
        min: moment().startOf('year').unix(),
        max: moment().endOf('day').unix(),
      },
    },
    '1Y': {
      xaxis: {
        min: moment().subtract(1, 'years').unix(),
        max: moment().endOf('day').unix(),
      },
    },
    ALL: {
      xaxis: {
        min: undefined,
        max: undefined,
      },
    },
  });
  public chartCount = new BehaviorSubject(0);
  private dateFormat = 'MM/DD/YYYY';
  private lastTime = 0;
  private lastCurrencyBase = '';

  constructor(
    private storage: StorageService,
    private coinGecko: CoinGeckoService,
    private graphqlService: GraphqlService,
    private blockbookService: BlockBookService,
    private avalancheService: AvalancheService,
    private sysnevmService: SysnevmService,
    private smallToCrypto: SmallToCryptoPipe,
    protected store: Store<AppState>,
  ) { }

  // Fetching transaction history of tokens
  async getTransactionHistory(tokens: any): Promise<any> {
    const assetBalances = {};
    const transactionHistoryOfTokens = [];

    if (tokens && tokens.length > 10 && !this.isrepeated) {
      Logger.info('getTransactionHistory ', tokens);

      this.isrepeated = true;
      const tokensLength = tokens.length;
      for (let i = 0; i < tokensLength; i++) {
        const token = tokens[i];
        const assetPayload = {
          ethFilterIndex: token.ethFilterIndex || null,
          sysGuid: token.symbol === token.baseChainSymbol ? null : token.guid || token.symbol,
          address: token.address,
          pageNum: 1,
          itemsPerPage: 1000,
          baseChainSymbol: token.baseChainSymbol,
        };

        // get all the transactions of each token

        const key = assetPayload.sysGuid || assetPayload.baseChainSymbol;
        if (!(key in assetBalances)) {
          let tokenHistory = await this.getAssetHistory(assetPayload);
          if (tokenHistory) {
            assetBalances[key] = tokenHistory;
            assetBalances[key].tokenInfo = token;
          } else {
            assetBalances[key] = {tokenInfo: token};
          }
        }
        assetBalances[key].tokenInfo = token;
      }
    }
    // remove any token/asset if it is undefined

    for (const asset in assetBalances) {
      if (assetBalances[asset] !== undefined) {
        transactionHistoryOfTokens.push(assetBalances[asset]);
      }
    }
    Logger.info('getTransactionHistory -transactionHistoryOfTokens ', transactionHistoryOfTokens);

    const allTokensAndTransactionHistories = await this.formattedTransactionList(transactionHistoryOfTokens);

    Logger.info('allTokensAndTransactionHistories ', allTokensAndTransactionHistories);
    return allTokensAndTransactionHistories;
  }

  async formattedTransactionList(tokenHistory: any): Promise<{}> {
    const tokensAndTransactionHistory = {};
    for (const token of tokenHistory) {
      const filteredTx = await token.transactions?.filter((tx: any) => tx?.confirmations);
      Logger.info('formattedTransactionList filteredTx ', filteredTx);

      if (filteredTx && filteredTx.length > 0) {
        const walletAddress = await this.getWalletAddress(token.tokenInfo.keyringId);
        // filter only the confirmed transactions from all the transactions of token

        // decorate all transactions to the format we wanted
        token.transactions = await this.decorateTransactions(filteredTx, token.tokenInfo, walletAddress);
        // consolidate the trnsactions by date
        Logger.info('formattedTransactionList token.transactions - ', token.transactions, ' token-info', token.tokenInfo);

        token.transactions = await this.groupTransactionsByDate(token.transactions, token.tokenInfo);
        Logger.info('formattedTransactionList groupTransactionsByDate token.transactions - ', token.transactions, ' token-info', token.tokenInfo);

        tokensAndTransactionHistory[token.tokenInfo.symbol + '-' + token.tokenInfo.baseChainSymbol] = token.transactions;
        Logger.info('formattedTransactionList tokensAndTransactionHistory ', tokensAndTransactionHistory);
      } else {
        tokensAndTransactionHistory[token.tokenInfo.symbol + '-' + token.tokenInfo.baseChainSymbol] = [];
      }
    }

    return tokensAndTransactionHistory;
  }

  async decorateTransactions(transactions: any[], asset: any, walletAddress: string) {
    try {
      const avaxPrecisionvalue = 18;
      const decoratedTransactions: any[] = [];

      const formatDate = (timestamp: number) => moment.unix(timestamp).format('MM/DD/YYYY');
      const formatTime = (timestamp: number) => moment.unix(timestamp).format(this.dateFormat);

      for (const tx of transactions) {
        // Avoid modifying store.
        let res;
        let totalAmount;
        let fees;
        let amount;
        const newTxn = {
          amount: 0,
          time: moment().format('X'),
          formattedDate: moment().format(this.dateFormat),
          type: '',
          fees: '',
        };
        const txn = { ...tx };
        switch (asset.baseChainSymbol) {
          case 'AVAX':
            res = await this.appendDataToTransactionEVM(txn, walletAddress);
            amount = this.smallToCrypto.transform(res.amount, { type: asset.baseChainSymbol, precision: asset.precision });
            if (asset.baseChainSymbol === asset.symbol) {
              fees = setNumberToPrecision(Number(txn.gasPrice * txn.gasUsed), avaxPrecisionvalue);
              newTxn.amount = this.getTotalAmount(res.type, amount, fees);
            } else {
              newTxn.amount = amount;
            }
            newTxn.type = res.type;
            newTxn.time = res.timeStamp;
            newTxn.formattedDate = formatDate(res.time);
            decoratedTransactions.push(newTxn);
            break;
          case 'ETH':
            res = await this.appendDataToTransactionEVM(txn, walletAddress);
            newTxn.amount = this.smallToCrypto.transform(res.amount, { type: asset.baseChainSymbol, precision: asset.precision });
            newTxn.type = res.type;
            newTxn.time = res.timeStamp;
            newTxn.formattedDate = formatDate(res.time);
            decoratedTransactions.push(newTxn);
            break;
          case 'SOL':
            res = await this.appendDataToTransactionSol(txn, walletAddress);
            newTxn.amount = this.smallToCrypto.transform(res.amount, { type: asset.baseChainSymbol, precision: asset.precision });
            newTxn.type = res.type;
            newTxn.time = res.time;
            newTxn.formattedDate = formatDate(res.time);
            decoratedTransactions.push(newTxn);
            break;
          case 'BTC':
            res = await this.appendDataToTransaction(txn, asset, walletAddress);
            totalAmount = this.getTotalAmount(res.type, res.amount, res.fees);
            newTxn.amount = this.smallToCrypto.transform(totalAmount, {
              type: asset.basechainSymbol,
              precision: asset.precision,
            });
            newTxn.type = res.type;
            newTxn.time = moment(res.time).format('X');
            newTxn.formattedDate = formatDate(Number(newTxn.time));
            decoratedTransactions.push(newTxn);
            break;
          case 'SYS':
            res = await this.appendDataToTransaction(txn, asset, walletAddress);
            if (asset.baseChainSymbol === asset.symbol) {
              totalAmount = this.getTotalAmount(res.type, res.amount, res.fees);
              newTxn.amount = this.smallToCrypto.transform(totalAmount, {
                type: asset.baseChainSymbol,
                precision: asset.precision,
              });
            } else {
              newTxn.amount = res.amount;
            }
            newTxn.amount = this.smallToCrypto.transform(totalAmount, {
              type: asset.baseChainSymbol,
              precision: asset.precision,
            });
            newTxn.type = res.type;
            newTxn.time = res.time;
            newTxn.formattedDate = formatDate(res.time);
            decoratedTransactions.push(newTxn);
            break;
          default:
            break;
        }
      }
      return decoratedTransactions;
    } catch (error) {
      Logger.error('decorateTransactions error ', inspect(error));
    }

  }
  getTotalAmount(type: string, amount: number, fees: any) {
    return type === 'send' ? amount + Number(fees) : amount;
  }
  async get365DatesFromToday() {
    // take all 365 dates from this day to backwards
    if (this.yearDatesArray.length > 0) return;
    this.yearDatesArray.push(moment().format(this.dateFormat));
    for (let i = 1; i < 365; i++) {
      const today = moment();
      const currentDate = today.subtract(i, 'day');
      this.yearDatesArray.push(currentDate.format(this.dateFormat));
    }
    Logger.info('yearDatesArray ', this.yearDatesArray);
  }

  async groupTransactionsByDate(transactions: any[], asset: any) {
    const consolidatedTransaction = [];
    // the latest balance of asset

    let previousBalance = this.smallToCrypto.transform(asset.balance, { type: asset.baseChainSymbol, precision: asset.precision });
    previousBalance = Number(previousBalance);
    Logger.info('groupTransactionsByDate ', transactions, asset, previousBalance);
    for (const date of this.yearDatesArray) {
      // grouping the transactions by date & sorting in descending order & calculate consolidated balance of each day transactions
      //Logger.info('groupTransactionsByDate' + date + ' tes ' +  transactions[0].formattedDate);
      const consolidatedTransactionByDate = [Number(moment(date).format('X')), previousBalance, date];
      const groupedTransactionsByDate = transactions.filter(c => c.formattedDate === date).sort((a, b) => moment(b.formattedDate, this.dateFormat).diff(moment(a.formattedDate, this.dateFormat)));
      //Logger.info('groupedTransactionsByDate - groupedTransactionsByDate', groupedTransactionsByDate);
      consolidatedTransactionByDate[1] = previousBalance;
      groupedTransactionsByDate.forEach((data) => {
        if (data.type === 'send') {
          previousBalance = previousBalance + data.amount;
        } else {
          previousBalance = previousBalance - data.amount;
          previousBalance = previousBalance < 0 ? (previousBalance * -1) : previousBalance;
        }
      });
      consolidatedTransaction.push(consolidatedTransactionByDate);
    }
    Logger.info('groupTransactionsByDate - consolidatedTransaction ', consolidatedTransaction);
    return consolidatedTransaction;
  }

  appendDataToTransactionEVM(txn: any, walletAddress: string) {
    const txAmount = getTransactionAmountsByEVMAddress(txn, walletAddress);
    txn.type = txAmount.type;
    txn.amount = txAmount.totalValue;
    txn.time = txn.timeStamp || Date.now();
    return txn;
  }

  appendDataToTransactionSol(txn: any, walletAddress: string) {
    const txAmount = getTransactionAmountsByAddressSol(txn, walletAddress);
    txn.type = txAmount.type;
    txn.amount = txAmount.totalValue;
    txn.time = txn.blockTime || Date.now();
    return txn;
  }

  appendDataToTransaction(txn: any, asset: any, walletAddress: string) {
    const txAmount = getTransactionAmountsByAddress(txn, walletAddress, asset.baseChainSymbol === 'ETH');

    txn.type = txAmount.type;
    if (txn.type === 'send') {
      txn.amount = getSendTypeValueFromtxAmount(txAmount, walletAddress, this.isBaseChain(asset), asset.guid);
      txn.time = txn.blockTime;
    } else if (txn.type === 'receive') {
      txn.amount = getReceiveTypeValueFromtxAmount(txAmount, walletAddress, this.isBaseChain(asset), asset.guid);
      txn.time = txn.blockTime;
    } else {
      txn.amount = txn.fee;
    }
    return txn;
  }

  async getWalletAddress(keyringId: string) {
    const coinKeyring = await this.store
      .select(getTokenKeyring, {
        keyringId,
      })
      .first()
      .toPromise();
    return coinKeyring.address;
  }

  async getAssetHistory(payload: any) {
    try {
      switch (payload.baseChainSymbol) {
        case 'SYS':
          return await this.blockbookService.getSysTokenHistory(payload.address, payload.pageNum, payload.itemsPerPage, payload.sysGuid);
        case 'AVAX':
          return await this.avalancheService.getAvaxTokenHistory(
            payload.address, payload.pageNum, payload.itemsPerPage, payload.ethFilterIndex, payload.sysGuid);
        case 'SYSNEVM':
            return await this.sysnevmService.getSysnevmTokenHistory(
              payload.address, payload.pageNum, payload.itemsPerPage, payload.ethFilterIndex, payload.sysGuid);
        case 'ETH':
          return await this.blockbookService.getEthTokenHistory(
            payload.address,
            payload.pageNum,
            payload.itemsPerPage,
            payload.ethFilterIndex,
            payload.sysGuid
          );
        case 'BTC':
          return await this.blockbookService.getBtcHistory(payload.address, payload.pageNum, payload.itemsPerPage);
        default:
          Logger.error('getAssetHistory error', payload);
      }

    } catch (error) {
      Logger.error('transaction history error', inspect(error));
    }
  }

  isBaseChain(asset) {
    return asset.symbol === asset.baseChainSymbol;
  }

  // Fetching price history of the tokens

  async getPriceHistoryData(basecurrency: string) {

    try {
      // get from cache first
      if (!basecurrency) {
        basecurrency = await this.store.select(getBaseCurrency).pipe(take(1)).toPromise();
      }
      let PRICE_LIST_OF_TOKENS_CACHE_KEY = PRICE_LIST_OF_TOKENS + basecurrency;
      let formatedPriceList =  await this.storage.get(PRICE_LIST_OF_TOKENS_CACHE_KEY);
      let coinHistoryTimeKey = `${PRICE_LIST_OF_TOKENS_CACHE_KEY}-time`;
      let lastTime = await this.storage.get(coinHistoryTimeKey);
      //return cache within 24 hours
      if (formatedPriceList && lastTime && (parseInt(lastTime.toString()) + 24*60*60*1000 > now())) {
        Logger.info(`getPriceHistoryData - get cache ${PRICE_LIST_OF_TOKENS_CACHE_KEY}`)
        return formatedPriceList;
      }

      formatedPriceList = {};
      const priceDuration = 366;
      const keychain = await this.store.select(getKeychain).pipe(take(1)).toPromise();
      const tokenList = await Promise.all(getTokenInfoArray(keychain));
      Logger.info('keychaintokens', tokenList);
      let responseData: any;
      let responseDataByCoingeckoId = {};
      for (const token of tokenList) {
        //const currentTokenCurrencyValue = token && token.fiatValue;
        if (token.coingeckoId) {
          if (isNil(responseDataByCoingeckoId[token.coingeckoId])) {
            responseData = await this.getPriceHistoryFromCoinGecko(token, basecurrency, priceDuration);
            formatedPriceList[token.symbol] = responseData;
            responseDataByCoingeckoId[token.coingeckoId] = responseData;
          } else {
            formatedPriceList[token.symbol] = responseDataByCoingeckoId[token.coingeckoId];
          }
          
        } else {
          responseData = [];
          formatedPriceList[token.symbol] = responseData;
        }
        }
        Logger.info('getPriceHistoryData', formatedPriceList);
        await this.storage.set(PRICE_LIST_OF_TOKENS_CACHE_KEY, formatedPriceList);
        await this.storage.set(coinHistoryTimeKey, now());
      return formatedPriceList;
    } catch (error) {
      Logger.error('getPriceHistoryData error', error);
    }
  }


  private async getPriceHistoryFromCoinGecko(tokenObj: any, basecurrency: string, duration: number) {
    // Fetching price history of assets from coingecko service

    const response = [];
    try {
      const priceData: any = await this.coinGecko
        .getMarketHistoricalData(tokenObj.coingeckoId, basecurrency, duration);
      if (priceData && priceData.prices) {
        for (const data of priceData.prices) {
          const formattedDate = moment.unix(data[0] * (1 / 1000)).format(this.dateFormat);
          if (data[1] > 0) {
            response.push([(data[0] * (1 / 1000)), data[1], formattedDate]);
          }
        }
        // If the timezone prevents the current date from updating, duplicate the last item of the last date.
        // because the coingecko api return UTC time of daily
        let duplicatedLastDate = [response[response.length - 1][0], response[response.length - 1][1], moment().format(this.dateFormat)];
        response.push(duplicatedLastDate);
        return response;
      }

    } catch (error) {
      Logger.error('coingecko service error', error);
      return response;
    }

  }

  private async getPriceHistoryFromGraphql(tokenObj: any, basecurrency: string) {
    // Fetching price history of assets from graphql service


    const tokenList = Object.values(supportedAssetList);
    const tokenData = tokenList.find(obj =>
      obj.symbol &&
      obj.baseChainSymbol === tokenObj.baseChainSymbol &&
      obj.symbol === tokenObj.symbol // TODO: will change after add asset feature is merged
    );
    const response = [];
    try {
      if (tokenData && tokenData.assetGuid) {
        const priceData: any = await this.graphqlService
          .getPriceHistoryWithExchange(tokenData.assetGuid, basecurrency);
        Logger.info('priceData', tokenData.symbol, priceData);
        if (priceData) {
          for (const data of priceData) {
            const formattedDate = moment.unix(data[0] * (1 / 1000)).format(this.dateFormat);
            if (data[1] > 0) {
              response.push([(data[0]), data[1], formattedDate]);
            }
          }
        }
        return response;
      }

    } catch (error) {
      Logger.error('Graphql service error', error);
      return response;
    }
  }

  // process price history against trsansaction history

  async calculatePriceOfTransactionHistory() {
    const keychain = await this.store.select(getKeychain).pipe(take(1)).toPromise();
    const tokenList = await Promise.all(getTokenInfoArray(keychain));
    const currentBaseCurrency = await this.store.select(getBaseCurrency).pipe(take(1)).toPromise();
    Logger.info('calculatePriceOfTransactionHistory - currencyBase', currentBaseCurrency);
    const priceHistories = await this.getPriceHistoryData(currentBaseCurrency);
    const transtionHistory = await this.getTransactionHistory(tokenList);
    Logger.info('calculatePriceOfTransactionHistory - priceHistories ', priceHistories);
    Logger.info('calculatePriceOfTransactionHistory - transtionHistory ', transtionHistory);

    if (transtionHistory) {
      for (const tranHistory in transtionHistory) {
        if (tranHistory) {
          const consolidateTrans = [];
          const tranHistoryData = transtionHistory[tranHistory];
          const keyName = tranHistory.toString().split('-')[0];
          Logger.info('keyName', keyName, tranHistoryData);
          const priceHistoryData = priceHistories[keyName];
          let latestPriceDate = [0,1];
          for (const data of tranHistoryData) {
            let datePrice = await priceHistoryData?.find((x: any[]) => x[2] === data[2]);
            if (isNil(datePrice)) { // if not found we get the latest date price
              datePrice = latestPriceDate;
            }
            data[1] = data[1] * datePrice[1];
            consolidateTrans.push(data);
            latestPriceDate = datePrice;
          }
          Logger.info('consolidateTrans ', consolidateTrans, tranHistoryData);
          transtionHistory[tranHistory] = consolidateTrans;
        }

      }
    }

    Logger.info('calculatePriceOfTransactionHistory - transtionHistory ', transtionHistory);
    if (Object.keys(transtionHistory).length > 0) {
      await this.storage.set(GRAPH_DATA_OF_ALL_TOKENS, transtionHistory);
      const transtionHistoryForTrade = JSON.parse(JSON.stringify(transtionHistory));
      await this.processTradePageGraphData(transtionHistoryForTrade);
      return await this.processHomePageGraphData(transtionHistory);
    } else {
      return false;
    }
  }

  // process homePageGraphData

  async processHomePageGraphData(transactionHistory) {
    const finalTransactionHistory = JSON.parse(JSON.stringify(transactionHistory));
    const graphDataOfHomePage = [];
    if (finalTransactionHistory) {
      for (const token in finalTransactionHistory) {
        if (token) {
          const tranHistoryOfToken = finalTransactionHistory[token];
          tranHistoryOfToken.forEach((data: any[]) => {
            // check date is already exist in the graphDataOfHomePage variable

            const found = graphDataOfHomePage.findIndex(x => x[2] === data[2]);
            // if date is exist in the graphDataOfHomePage array then update its balance

            if (found !== -1) {
              graphDataOfHomePage[found][1] = graphDataOfHomePage[found][1] + data[1];
            } else {
              // if date isn't exist in the graphDataOfHomePage array then push that date into the graphDataOfHomePage array

              graphDataOfHomePage.push(data);
            }
          });
        }

      }
    }

    Logger.info('graphDataOfHomePage ', graphDataOfHomePage);
    const newgraphDataOfHomePage = graphDataOfHomePage.sort((a, b) => moment(a.formattedDate, 'MM/DD/YYYY').diff(moment(b.formattedDate, 'MM/DD/YYYY')));
    if (newgraphDataOfHomePage.length > 0) {
      await this.storage.set(GRAPH_DATA_HOME, newgraphDataOfHomePage);
      this.isrepeated = false;
      return true;
    } else {
      return false;
    }
  }

  // process trade graph data
  async processTradePageGraphData(transactionHistory: any[]) {
    const graphDataOfTradePage = [];
    // filtering only  AVAX based transactions from transactions history
    const transactions = Object.keys(transactionHistory).filter(key => key.includes('-AVAX'))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: transactionHistory[key] });
      }, {}
      );
    if (transactions) {
      for (const token in transactions) {
        if (token) {
          const tranHistoryOfToken = transactions[token];
          // check date is already exist in the graphDataOfTradePage variable
          // if date is exist in the graphDataOfTradePage array then update its balance
          // if date isn't exist in the graphDataOfTradePage array then push that date into the graphDataOfTradePage array  
          for (const data of tranHistoryOfToken) {
            const found = graphDataOfTradePage.findIndex(x => x[2] === data[2]);
            if (found !== -1) {
              graphDataOfTradePage[found][1] = graphDataOfTradePage[found][1] + data[1];
            } else {
              graphDataOfTradePage.push(data);
            }
          }
        }

      }
    }

    const newTradeGraphData = graphDataOfTradePage.sort((a, b) => moment(a.formattedDate, 'MM/DD/YYYY').diff(moment(b.formattedDate, 'MM/DD/YYYY')));
    Logger.info('newTradeGraphData ', newTradeGraphData);
    if (newTradeGraphData.length > 0) {
      await this.storage.set(GRAPH_DATA_TRADE, newTradeGraphData);
    }
  }


  async updateGraphAfterEveryTransaction() {
    try {
      const graphDataOfAllTokens = await this.storage.get(GRAPH_DATA_OF_ALL_TOKENS);
      const graphDataOfHomePage = await this.storage.get(GRAPH_DATA_HOME);
      const graphDataOfTradePage = await this.storage.get(GRAPH_DATA_TRADE);
      let tokens: any[];
      const keychain = await this.store.select(getKeychain).pipe(take(1)).toPromise();
      tokens = await Promise.all(getTokenInfoArray(keychain));

      let total = 0;
      let tradeTotal = 0;
      if (graphDataOfAllTokens) {
        const currentTokenBalance = [];
        for (const tokenData in graphDataOfAllTokens) {
          if (tokenData) {
            const currentTokenData = graphDataOfAllTokens[tokenData];
            if (currentTokenData?.length > 0) {
              currentTokenBalance.push(this.getTokenBalance(tokenData, tokens)); // fetch total balance of each tokens
            }
          }
        }
        const currentTokenBalances = await Promise.all(currentTokenBalance);
        for (const tokenData in graphDataOfAllTokens) {
          if (tokenData) {
            const currentTokenData = graphDataOfAllTokens[tokenData];
            if (currentTokenData?.length > 0) {
              const currentTokenBalance = currentTokenBalances.shift();
              if (currentTokenBalance) {
                currentTokenData[0] = [currentTokenData[0][0], currentTokenBalance, currentTokenData[0][2]];
                total += currentTokenBalance;
                if (tokenData.includes('-AVAX')) {
                  tradeTotal += currentTokenBalance;
                }
                Logger.info('currentTokenData', currentTokenData);
              }
            }
          }
        }
        if (graphDataOfHomePage) {
          graphDataOfHomePage[0] = [graphDataOfHomePage[0][0], total, graphDataOfHomePage[0][2]];
        }
        if (graphDataOfTradePage) {
          graphDataOfTradePage[0] = [graphDataOfTradePage[0][0], tradeTotal, graphDataOfTradePage[0][2]];
        }
        await this.storage.set(GRAPH_DATA_OF_ALL_TOKENS, graphDataOfAllTokens);
        await this.storage.set(GRAPH_DATA_HOME, graphDataOfHomePage);
        await this.storage.set(GRAPH_DATA_TRADE, graphDataOfTradePage);
        this.chartCount.next(this.chartCount.getValue() + 1);
      }
    } catch (err) {
      Logger.error('Graph transaction update error', err);
    }
  }

  async getTokenBalance(asset: string, tokens: any) {
    const symbol = asset.split('-')[0];
    const baseChainSymbol = asset.split('-')[1];
    const tokenInfo = tokens.filter(token => token.baseChainSymbol === baseChainSymbol && token.symbol === symbol);
    try {
      let data: any;
      let tokenBalance;
      let assetData;
      switch (baseChainSymbol) {
        case 'ETH':
          data = await this.blockbookService.getEthTokenBalances(tokenInfo[0].address);
          assetData = data.filter(obj => obj.baseChainSymbol === baseChainSymbol && obj.symbol === symbol);
          tokenBalance = await this.fetchBalance(assetData, tokenInfo);
          return Number(tokenBalance);
        case 'BTC':
          data = await this.blockbookService.getBtcBalance(tokenInfo[0].address);
          assetData = data.filter(obj => obj.baseChainSymbol === baseChainSymbol && obj.symbol === symbol);
          tokenBalance = await this.fetchBalance(assetData, tokenInfo);
          return Number(tokenBalance);
        case 'SYS':
          data = await this.blockbookService.getSysTokenBalances(tokenInfo[0].address);
          assetData = data.filter(obj => obj.baseChainSymbol === baseChainSymbol && obj.symbol === symbol);
          tokenBalance = await this.fetchBalance(assetData, tokenInfo);
          return Number(tokenBalance);
        case 'AVAX':
          data = await this.avalancheService.getAvaxTokenBalances(tokenInfo[0].address);
          assetData = data.filter(obj => obj.baseChainSymbol === baseChainSymbol && obj.symbol === symbol);
          tokenBalance = await this.fetchBalance(assetData, tokenInfo);
          return Number(tokenBalance);
        default:
          Logger.warn(`Token balance - not found base Chain, asset: ${asset} - ${tokens} - baseChain: ${baseChainSymbol} `)
      }
    }
    catch (err) {
      Logger.error('Token balance response error', err);
    }
  }

  async fetchBalance(asset: any, token: any): Promise<number> {
    try {
      if (asset && asset[0]?.balance) {
        const balance = this.smallToCrypto.transform(asset[0].balance, { type: token[0].baseChainSymbol, precision: asset[0].precision });
        const fiatBalance = getTokenFiatValue(token[0].fiatValue ? token[0].fiatValue : 0, balance);
        Logger.info('info', asset, balance, Number(fiatBalance));
        return Number(fiatBalance);
      }
    } catch (err) {
      Logger.error('balance fetch error', err);
      return 0;
    }
  }


  async getHomePagechartOptions(isDesktopMode: boolean, instance: any) {
    let chartOptions: Partial<ChartOptions>;
    chartOptions = {
      series: [
        {
          data: [],
        },
      ],
      chart: {
        width: '100%',
        height: isDesktopMode ? '350px' : 'auto',
        type: 'area',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      markers: {
        colors: ['#25343E'],
        strokeColors: '#FAA710',
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 1.8,
        colors: ['#FAA710'],
      },
      yaxis: {
        crosshairs: {
          show: false,
        },
        show: true,
        showAlways: true,
        opposite: true,
        tickAmount: 2,
        floating: true,
        forceNiceScale: true,
        labels: {
          offsetX: 40,
          style: {
            fontSize: '15px',
            fontWeight: 500,
            colors: 'white',
            cssClass: 'apexcharts-yaxis-label'
          },
          formatter: (num): any => numberFormatter(num),
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: false,
          style: {
            colors: '#25343E',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: true,
          width: '1.4',
          position: 'back',
          opacity: 0.1,
          stroke: {
            color: '#ad8232',
            dashArray: 0,
            width: 1
          },
        },
      },
      tooltip: {
        enabled: true,
        custom({ series, seriesIndex, dataPointIndex, w }) {
          const date: HTMLElement = document.getElementById('graphBalance');
          date.innerHTML = instance.selectedDate;
          return `
        <div 
          class="apexcharts-tooltip-series-group apexcharts-active" 
          style="order: 1; display: flex;flex-direction: column;background: #25333ee0;padding-top: 4px;"
        >
          <span class="tooltip-vault">${series[seriesIndex][dataPointIndex].toFixed(2)} ${instance.currentBaseCurrency}</span>
        </div>
      `;
        },
        theme: 'dark',
        x: {
          format: 'dd MMM yyyy',
          formatter(value, { series, seriesIndex, dataPointIndex, w }) {
            instance.selectedDate = new Date(value * 1000).toLocaleDateString();
            return '';
          },
        },
      },
      fill: {
        colors: ['#FAA710'],
        opacity: 0.4,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          type: 'vertical',
          gradientToColors: ['#FAA710', '#25343E'],
          opacityFrom: 1,
          opacityTo: 0,
          stops: [0, 95, 100],
        },
      },
    };

    return chartOptions;
  }

  async getVaultPagechartOptions(isDesktopMode: boolean, instance: any) {
    let chartOptions: Partial<ChartOptions>;
    chartOptions = {
      series: [
        {
          data: [],
        },
      ],
      chart: {
        width: '100%',
        height: isDesktopMode ? '330px' : 'auto',
        type: 'area',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      markers: {
        colors: ['#25343E'],
        strokeColors: '#1A73E8',
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 1.8,
        lineCap: 'butt',
        colors: ['#1A73E8'],
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: false,
          style: {
            colors: '#25343E',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: true,
          width: '1.2',
          position: 'back',
          opacity: 0.7,
          stroke: {
            color: '#2a5fa3',
            dashArray: 0,
          },
        },
      },
      yaxis: {
        crosshairs: {
          show: false,
        },
        show: true,
        showAlways: true,
        opposite: true,
        tickAmount: 2,
        floating: true,
        forceNiceScale: true,
        labels: {
          offsetX: 40,
          style: {
            fontSize: '15px',
            fontWeight: 500,
            colors: 'white',
            cssClass: 'apexcharts-yaxis-label'
          },
          formatter: (num): any => numberFormatter(num),
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      tooltip: {
        enabled: true,
        custom({ series, seriesIndex, dataPointIndex, w }) {
          const date: HTMLElement = document.getElementById('graphBalance')
          date.innerHTML = instance.selectedDate;
          return `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;flex-direction: column;background: #25333ee0;padding-top: 4px;">
                    <span class="tooltip-vault">${series[seriesIndex][dataPointIndex].toFixed(2)} ${instance.currentBaseCurrency}</span>
                  </div>`;
        },
        theme: 'dark',
        x: {
          format: 'dd MMM yyyy',
          formatter(value, { series, seriesIndex, dataPointIndex, w }) {
            instance.selectedDate = new Date(value * 1000).toLocaleDateString();
            return '';
          },
        },
      },
      fill: {
        colors: ['#1A73E8'],
        opacity: 0.4,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          type: 'vertical',
          gradientToColors: ['#1A73E8', '#25343E'],
          opacityFrom: 1,
          opacityTo: 0,
          stops: [0, 95, 100],
        },
      },
    };
    return chartOptions;
  }

  async getAssetPagechartOptions(isDesktopMode: boolean, instance: any) {
    let chartOptions: Partial<ChartOptions>;
    chartOptions = {
      series: [
        {
          data: [],
        },
      ],
      chart: {
        width: '100%',
        height: isDesktopMode ? '330px' : 'auto',
        type: 'area',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      markers: {
        colors: ['#25343E'],
        strokeColors: '#1A73E8',
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 1.8,
        lineCap: 'butt',
        colors: ['#2a5fa3'],
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: false,
          style: {
            colors: '#25343E',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: true,
          width: '1.2',
          position: 'back',
          opacity: 0.7,
          stroke: {
            color: '#174178',
            dashArray: 0,
          },
        },
      },
      yaxis: {
        crosshairs: {
          show: false,
        },
        show: true,
        floating: true,
        showAlways: true,
        showForNullSeries: false,
        opposite: true,
        tickAmount: 2,
        forceNiceScale: true,
        labels: {
          offsetX: 40,
          style: {
            fontSize: '15px',
            fontWeight: 500,
            colors: 'white',
            cssClass: 'apexcharts-yaxis-label'
          },
          formatter: (num): any => numberFormatter(num),
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      tooltip: {
        enabled: true,
        custom({ series, seriesIndex, dataPointIndex, w }) {
          const dateValue: HTMLElement = document.getElementById('graphAssetBalance');
          dateValue.innerHTML = instance.selectedDate;
          return `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;flex-direction: column;background: #25333ee0;padding-top: 4px;">
                    <span class="tooltip-vault">${series[seriesIndex][dataPointIndex].toFixed(2)} ${instance.currentBaseCurrency}</span>
                  </div>`;
        },
        theme: 'dark',
        x: {
          format: 'dd MMM yyyy',
          formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
            // this.selectedDate = new Date(value).toLocaleDateString();
            instance.selectedDate = new Date(value * 1000).toLocaleDateString();
            return '';
          },
        },
      },
      fill: {
        colors: ['#1A73E8'],
        opacity: 0.4,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          type: 'vertical',
          gradientToColors: ['#1A73E8', '#25343E'],
          opacityFrom: 1,
          opacityTo: 0,
          stops: [0, 95, 100],
        },
      },
    };
    return chartOptions;
  }

  async getAssetPageMarketChartOptions(isDesktopMode: boolean, instance: any) {
    let chartOptions: Partial<ChartOptions>;
    chartOptions = {
      series: [
        {
          data: [],
        },
      ],
      chart: {
        width: '100%',
        height: isDesktopMode ? '330px' : 'auto',
        type: 'area',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      markers: {
        colors: ['#25343E'],
        strokeColors: '#1A73E8',
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 1.8,
        lineCap: 'butt',
        colors: ['#2a5fa3'],
      },
      xaxis: {
        type: 'datetime',
        labels: {
          show: false,
          style: {
            colors: '#25343E',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: true,
          width: '1.2',
          position: 'back',
          opacity: 0.7,
          stroke: {
            color: '#174178',
            dashArray: 0,
          },
        },
      },
      yaxis: {
        crosshairs: {
          show: false,
        },
        show: true,
        showAlways: true,
        showForNullSeries: false,
        opposite: true,
        floating: true,
        tickAmount: 2,
        forceNiceScale: true,
        labels: {
          offsetX: 40,
          style: {
            fontSize: '15px',
            fontWeight: 500,
            colors: 'white',
            cssClass: 'apexcharts-yaxis-label'
          },
          formatter: (num): any => numberFormatter(num),
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const date: HTMLElement = document.getElementById('graphMarketBalance');
          date.innerHTML = instance.selectedDate;
          return `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;flex-direction: column;background: #25333ee0;padding-top: 4px;">
                    <span class="tooltip-vault">${series[seriesIndex][dataPointIndex].toFixed(2)} ${instance.currentBaseCurrency}</span>
                  </div>`;
        },
        theme: 'dark',
        x: {
          format: 'dd MMM yyyy',
          formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
            instance.selectedDate = new Date(value * 1000).toLocaleDateString();
            return '';
          },
        },
      },
      fill: {
        colors: ['#1A73E8'],
        opacity: 0.4,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          type: 'vertical',
          gradientToColors: ['#1A73E8', '#25343E'],
          opacityFrom: 1,
          opacityTo: 0,
          stops: [0, 95, 100],
        },
      },
    };
    return chartOptions;
  }

  async getTradePageChartOptions(isDesktopMode: boolean, instance: any) {
    let chartOptions: Partial<ChartOptions>;
    chartOptions = {
      series: [
        {
          data: [],
        },
      ],
      chart: {
        width: '100%',
        height: isDesktopMode ? '350px' : 'auto',
        type: 'area',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: 'easeout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      markers: {
        colors: ['#2A7210'],
        strokeColors: '#44B51E',
      },
      grid: {
        show: false,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 1.8,
        lineCap: 'butt',
        colors: ['#44B51E'],
      },
      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            colors: '#2A7210',
          },
          show: false
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          show: true,
          width: '1.2',
          position: 'back',
          opacity: 0.7,
          stroke: {
            color: '#2A7210',
            dashArray: 0,
          },
        },
      },
      yaxis: {
        crosshairs: {
          show: false,
        },
        show: true,
        floating: true,
        showAlways: true,
        showForNullSeries: false,
        opposite: true,
        tickAmount: 2,
        forceNiceScale: true,
        labels: {
          offsetX: 40,
          style: {
            fontSize: '15px',
            fontWeight: 500,
            colors: 'white',
            cssClass: 'apexcharts-yaxis-label'
          },
          formatter: (num): any => numberFormatter(num),
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        tooltip: {
          enabled: false
        }
      },
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const dateValue: HTMLElement = document.getElementById('graphBalance');
          dateValue.innerHTML = instance.selectedDate;
          return `<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;flex-direction: column;background: #25333ee0;padding-top: 4px;">
                    <span class="tooltip-vault">${series[seriesIndex][dataPointIndex].toFixed(2)} ${instance.currentBaseCurrency}</span>
                  </div>`;
        },
        theme: 'dark',
        x: {
          format: 'dd MMM yyyy',
          formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
            // this.selectedDate = new Date(value).toLocaleDateString();
            instance.selectedDate = new Date(value * 1000).toLocaleDateString();
            return '';
          },
        },
      },
      fill: {
        colors: ['#44B51E'],
        opacity: 0.4,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 1,
          type: 'vertical',
          gradientToColors: ['#44B51E', '#2A7210'],
          opacityFrom: 1,
          opacityTo: 0,
          stops: [0, 95, 100],
        },
      },
    };
    return chartOptions;
  }

  async clearGraphData() {
    await this.storage.remove(GRAPH_DATA_HOME);
    await this.storage.remove(GRAPH_DATA_OF_ALL_TOKENS);
    await this.storage.remove(GRAPH_DATA_TRADE);
  }
}
