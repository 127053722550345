import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Logger } from './logger.service';
import { key } from 'localforage-cordovasqlitedriver';
import { Environment } from 'src/environments/environment.interface';

type FeatureFlows = keyof Environment['features'];

type CustomFeaturesArray = Partial<FeatureFlows>[];

// add the names of the features that need additional checks on top of the environment flag
const customFeatureFlows: CustomFeaturesArray = ['trade'];
@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(private platform: Platform) {}

  get trade() {
    return environment.features.trade;
  }

  // dynamic get function
  service(serviceName: FeatureFlows) {
    if (customFeatureFlows.includes(serviceName)) {
      return this[serviceName];
    }
    return environment.features[serviceName];
  }
}
