import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safe-htmls-pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SafeHtmlPipe],
  exports: [SafeHtmlPipe]
})
export class SafeHtmlsModule { }
