import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { openKycModal } from 'src/app/angular-wallet-base/utils';
import { PurchaseGasModalComponent } from '../purchase-gas-model/purchase-gas-model.component';

@Component({
  selector: 'app-gas-info-modal',
  templateUrl: './gas-info-modal.component.html',
  styleUrls: ['./gas-info-modal.component.scss']
})
export class GasInfoModalComponent implements OnInit {
  isDesktopMode: boolean;
  kycData: any;
  tokenInfo: any;
  constructor(
    private modalController: ModalController,
    protected store: Store<AppState>,
    public appMode: AppModeService,
  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalController.dismiss();
  }

  async openPurchaseGasModal() {
if (this.kycData && this.kycData['passingLevel']) {
      const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
      let cssClass = '';
      cssClass += (this.isDesktopMode) ? 'purchase-gas-modal-desktop' : 'purchase-gas-modal';
      cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
      const modal = await this.modalController.create({
        component: PurchaseGasModalComponent,
        cssClass,
        componentProps: {
          assetGuid: this.tokenInfo.assetGuid,
          tokenInfo: this.tokenInfo
        },
        initialBreakpoint: (this.isDesktopMode) ? undefined : 0.5,
        breakpoints: (this.isDesktopMode) ? undefined : [0, 0.5, 1],
      });
      await modal.present();
      await modal.onDidDismiss().then();
    } else {
      const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
      await openKycModal(sideMenuHidden, this.isDesktopMode, this.modalController);
    }
  }
}
