import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { AuthGuardService } from 'src/app/angular-wallet-base/services/auth-guard.service';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { MicroRewardService } from 'src/app/angular-wallet-base/services/micro-reward.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { SelectReceivingAddressComponent } from '../select-receiving-address/select-receiving-address.component';

@Component({
    selector: 'app-primary-address-success-fail-modal',
    templateUrl: './success-fail-modal.html',
    styleUrls: ['./success-fail-modal.scss']
})
export class PrimaryAddressSuccessFailComponent implements OnInit {

    public isDesktopMode;
    public isSuccess: boolean = false;
    public successFailText: string;
    public title: string;
    public selectedAddress: any;

    constructor(
        private modalController: ModalController,
        public appMode: AppModeService,
        protected store: Store<AppState>,
        private authService: AuthService,
        private microRewardService: MicroRewardService,
        private nav: NavController,
    ) {
        this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
    }

    async ngOnInit() {
        await this.authService.dismissModal('signatureRequestModal');
        if (this.isSuccess) {
            this.successFailText = `success_primary_address.success_address_msg`;
            this.title = 'success_primary_address.success';
        } else {
            this.successFailText = 'failure_primary_address.fail_primary_address_msg';
            this.title = 'failure_primary_address.fail_primary_address_title';
        }
        
    }

    closeModal() {
        this.modalController.dismiss();
    }
    async skipModal() {
        await this.microRewardService.showAssociateAddressModal(false);
        this.closeModal();
    }
    async continueModal() {
        this.microRewardService.isSuccess.next(true);
        this.closeModal();
    }
    async setAirDropAddress() {
        let cssClass = '';
        const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
        cssClass += (this.isDesktopMode) ? 'primary-address-success-modal-desktop' : 'primary-address-success-modal';
        cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
        const modal = await this.modalController.create({
          component: SelectReceivingAddressComponent,
          cssClass,
          initialBreakpoint: (this.isDesktopMode) ? undefined : 0.5,
          breakpoints: (this.isDesktopMode) ? undefined : [0, 0.4, 0.5, 0.6, 1],
          id: 'selectAddressModal'
        });
        await modal.present();
    }
}


