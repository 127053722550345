import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { BackButtonModule } from "../../components/back-button/back-button.component.module";
import { SafeHtmlsModule } from "src/app/angular-wallet-base/pipes/safe-htmls/safe-htmls.module";
import { SelectAssetModal } from "./select-asset-modal.component";
import { SendAssetModal } from "../send-asset-modal/send-asset-modal.component";
import { GasInfoModalComponent } from "../send-asset-modal/gas-info-modal/gas-info-modal.component";
import { SuccessModalComponent } from "../send-asset-modal/success-modal/success-modal.component";
import { ConfirmModalComponent } from "../send-asset-modal/confirm-modal/confirm-modal.component";
import { PurchaseGasModalComponent } from "../send-asset-modal/purchase-gas-model/purchase-gas-model.component";
import { MatSelectModule } from "@angular/material/select";
import { ReceiveAssetModal } from "../receive-asset-modal/receive-asset-modal.component";
import { NgxKjuaModule } from "ngx-kjua";
import { SendAssetModalModule } from "../send-asset-modal/send-asset-modal.module";
import { SmallToCryptoPipeModule } from "src/app/angular-wallet-base/pipes/smalltocrypto/smalltocrypto.module";
import { CustomCurrencyPipeModule } from "src/app/angular-wallet-base/pipes/customcurrency/customcurrency.module";
import { PinModalComponent } from "src/app/components/pin-modal/pin-modal.component";
import { SelectWalletModalComponent } from "./select-wallet-modal/select-wallet-modal.component";
import { ConfirmModalModule } from "../send-asset-modal/confirm-modal/confirm-modal.module";
import { GasInfoModalModule } from "../send-asset-modal/gas-info-modal/gas-info-modal.module";
import { PinInputModule } from "../../components/pin-input/pin-input.module";

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    BackButtonModule,
    SafeHtmlsModule,
    MatSelectModule,
    NgxKjuaModule,
    SendAssetModalModule,
    SmallToCryptoPipeModule,
    CustomCurrencyPipeModule,
    ConfirmModalModule,
    GasInfoModalModule,
    PinInputModule
  ],
  declarations: [
    SelectAssetModal,
    ReceiveAssetModal,
    SuccessModalComponent,
    PinModalComponent,
    PurchaseGasModalComponent,
    SelectWalletModalComponent,
  ]
})
export class SelectAssetModule {}
