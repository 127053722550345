import { WalletState } from './wallet';
import { SetupState } from './setup';
import { UserPreferencesState } from './userPreferences';
import { VestingLtcState } from './vestingLTC';
import { SendTokenState } from './sendToken';
import { ConnectionState } from './connection';
import { GasStationState } from './gasStation';
import { StakingLodeState } from './stakingLode';
import { PriceOracleState } from './priceOracle';
import { VirtualDebitCardState } from './virtualDebitCard';
import { AppSettingsState } from './appSettings';
import { DevicesListState } from './deviceslist';
import { BankAccountState } from './bankAccounts';
import { AccountSetupState } from './accountSetup';
import { GraphDataState } from './graphData';
import { WalletConnectState } from './walletConnect';
import { PurchaseOrderState } from './purchaseOrder.state';
import { Otc3State } from './otc3';

export interface AppState {
  readonly setup?: SetupState;
  readonly wallet?: WalletState;
  readonly userPreferences?: UserPreferencesState;
  readonly stakingLode?: StakingLodeState;
  readonly vestingLTC?: VestingLtcState;
  readonly sendToken?: SendTokenState;
  readonly connection?: ConnectionState;
  readonly gasStation?: GasStationState;
  readonly priceOracle?: PriceOracleState;
  readonly virtualDebitCard?: VirtualDebitCardState;
  readonly appSettings?: AppSettingsState;
  readonly devicesList?: DevicesListState;
  readonly bankAccount?: BankAccountState;
  readonly accountSetup?: AccountSetupState;
  readonly graphData?: GraphDataState;
  readonly walletConnect?: WalletConnectState;
  readonly purchaseOrder?: PurchaseOrderState,
  readonly otc3?: Otc3State;
  readonly hydrated?: boolean;
}

export const getSetupState = (state: AppState) => state.setup;
export const getWalletState = (state: AppState) => state.wallet;
export const getUserPreferencesState = (state: AppState) => state.userPreferences;
export const getUserVestingState = (state: AppState) => state.vestingLTC;
export const getUserStakingState = (state: AppState) => state.stakingLode;
export const getSendTokenState = (state: AppState) => state.sendToken;
export const getConnectionState = (state: AppState) => state.connection;
export const getGasStationState = (state: AppState) => state.gasStation;
export const getPriceOracleState = (state: AppState) => state.priceOracle;
export const getVirtualDebitCardState = (state: AppState) => state.virtualDebitCard;
export const getAppSettingsState = (state: AppState) => state.appSettings;
export const getDevicesListState = (state: AppState) => state.devicesList;
export const getBankAccountState = (state: AppState) => state.bankAccount;
export const getAccountSetupState = (state: AppState) => state.accountSetup;
export const getGraphDataState = (state: AppState) => state.graphData;
export const getOtc3State = (state: AppState) => state.otc3;
export const getWalletConnectState = (state: AppState) => state.walletConnect;
export const getPurchaseOrderState = (state: AppState) => state.purchaseOrder;
export const getAppState = (state: AppState) => state;
