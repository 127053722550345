import { createSelector } from '@ngrx/store';
import { getAppSettingsState } from './appState';

export interface AppSettingsState {
  readonly pageTitle: string;
  readonly pageSecondaryTitle: string;
  readonly useI18nOnTitle: boolean;
  readonly forceUpgrade: boolean;
  readonly activePage: string;
  readonly hideSideMenu: boolean;
  readonly showSideMenu: boolean;
}

export const getPageTitle = createSelector(
  getAppSettingsState,
  (state) => state.pageTitle
);

export const getPageSecondaryTitle = createSelector(
  getAppSettingsState,
  (state) => state.pageSecondaryTitle
);

export const getShouldUseI18n = createSelector(
  getAppSettingsState,
  (state) => state.useI18nOnTitle
);

export const getForceUpgrade = createSelector(
  getAppSettingsState,
  (state) => state.forceUpgrade
);

export const getActivePage = createSelector(
  getAppSettingsState,
  (state) => state.activePage
);

export const getSideMenuStatus = createSelector(
  getAppSettingsState,
  (state) => state.hideSideMenu
);

export const getSideMenuShowStatus = createSelector(
  getAppSettingsState,
  (state) => state.showSideMenu
);
