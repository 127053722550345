import { Injectable, Injector } from '@angular/core';
import { supportedAssetList } from '../lib/supported-assets/supported-assets';
import { NetworkService } from './network.service';
import { Logger } from './logger.service';
import { StorageService } from './storage.service';
import { BLOCKCHAIN_SERVICE_API, SUPPORTED_ASSETS } from '../constants';
import { TokenDefinition, TokenInfoExtended } from 'src/app/global';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { GetAllTokenBalancesAction, HideERC20TokensAction } from '../actions/wallet.actions';
import { getKeychain } from '../store/wallet';
import { take } from 'rxjs/operators';
import { getCurrentNetworkIcon, getCurrentNetworkLabel, getTokenInfo, safePromiseAll } from '../utils';
import { isNil } from '../ts-util';
import { MembersPortalService } from './members-portal.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenDefinitionService } from './token-definition.service';
import { MoralisService } from './moralis.service';
import { WebSocketAddressNotifierService } from './WebSocketAddressNotifier.service';
import { GraphService } from './graph.service';
import { SmallToCryptoPipe } from '../pipes/smalltocrypto/smalltocrypto.pipe';
import { TranslateService } from '@ngx-translate/core';

export interface TokenInfo {
  name: string;
  chainId: number;
  symbol: string;
  address: string;
  decimal: number;
}

@Injectable({
  providedIn: 'root'
})
export class TokenManagerService {
  public keyrings: any;
  public addTokenProgress = new BehaviorSubject<boolean>(false);
  public hideTokenProgress = new BehaviorSubject<boolean>(false);
  public firstTimeAddTokens = false;

  constructor(
    private network: NetworkService,
    private storage: StorageService,
    protected store: Store<AppState>,
    private injector: Injector,
    private http: HttpClient,
    private smallToCrypto: SmallToCryptoPipe,
    private translate: TranslateService,
    private networkManager: NetworkService,
    ) {
  }

  private getEthKeyringIds() {
    return Object.keys(this.keyrings)
      .filter((keyringId: string) => keyringId.indexOf('ETH') !== -1);
  }

  private getSysKeyringIds() {
    return Object.keys(this.keyrings)
      .filter((keyringId: string) => keyringId.indexOf('sys') !== -1 && keyringId.indexOf('sysnevm') === -1);
  }

  private getSysnevmKeyringIds() {
    return Object.keys(this.keyrings)
      .filter((keyringId: string) => keyringId.indexOf('SYSNEVM') !== -1);
  }

  private getAvaxKeyringIds() {
    return Object.keys(this.keyrings)
      .filter((keyringId: string) => keyringId.indexOf('AVAX') !== -1);
  }

  private async modifyKeyringsIfTestnet(keychain) {
    const modifiedKeychain = { ...keychain };
    const chainsToModify = ['SYS', 'ETH', 'AVAX', 'SYSNEVM'];
    
    for (const chain of chainsToModify) {
      let keyringIds;
      let network;

      switch (chain) {
        case 'SYS':
          keyringIds = this.getSysKeyringIds();
          network = await this.network.getActiveSysNetwork();
          break;
        case 'ETH':
          keyringIds = this.getEthKeyringIds();
          network = await this.network.getActiveEthNetwork();
          break;
        case 'AVAX':
          keyringIds = this.getAvaxKeyringIds();
          network = await this.network.getActiveAvaxNetwork();;
          break;
        case 'SYSNEVM':
          keyringIds = this.getSysnevmKeyringIds();
          network = await this.network.getActiveSysnevmNetwork();;
          break;
        default:
          keyringIds = [];
      }

      // remove tokens based on env (test-net/main-net)
      if (!isNil(network)) {
        keyringIds.forEach(keyringId => {
          const currentChain = modifiedKeychain[keyringId];
          if (currentChain && currentChain.chainId && currentChain.chainId !== network.chainId) {
            delete modifiedKeychain[keyringId];
          }
        });
      }

    }

    return modifiedKeychain;
  }

  public async getTokenList() {
    this.keyrings = await this.getSupportedTokensList();
    return await this.modifyKeyringsIfTestnet(this.keyrings);
  }

  public async getSupportingFeeTokens() {
    let supportingTokens = await this.getTokenList();
    let supportingFeeTokens = [];
    Object.keys(supportingTokens).forEach((key) => {
      let token = supportingTokens[key];
      if (token?.baseChainSymbol == "AVAX" && (token?.symbol == "AGX" || token?.symbol == "AUX")) {
        supportingFeeTokens.push(token);
      }
    })
    return supportingFeeTokens;
  }

  public async getSupportedTokensList() {
    let supportedAssets = await this.storage.get(SUPPORTED_ASSETS);
    //if supported assets doesn't contain any tokens containing 'PT-AVAX' as a key, then set them again.
    if (supportedAssets && !Object.keys(JSON.parse(supportedAssets)).some(key => key.startsWith('PT-AVAX'))) {
      await this.setSupportedTokenList();
      supportedAssets = await this.storage.get(SUPPORTED_ASSETS);
    }
    if (supportedAssets) {
      return JSON.parse(supportedAssets);
    }
    return supportedAssetList;
  }

  public async setSupportedTokenList(token: { [key: string]: TokenDefinition } = null) {
    if (token) {
      const existingTokens = await this.getSupportedTokensList();
      const updatedTokens = { ...existingTokens, ...token };
      await this.storage.set(SUPPORTED_ASSETS, JSON.stringify(updatedTokens));
    } else {
      await this.storage.set(SUPPORTED_ASSETS, JSON.stringify(supportedAssetList));
    }
  }

  public async removeSupportedTokenList(token: any) {
    if (this.hideTokenProgress.getValue()) { return; }

    this.hideTokenProgress.next(true);

    const keyringId = `${token.baseChainSymbol}-${token.assetGuid}`;
    const existingTokens = await this.getSupportedTokensList();
    const updatedTokens = { ...existingTokens };
    for (const tkn in updatedTokens) {
      if (tkn === keyringId) {
        delete updatedTokens[tkn];
      }
    }
    Logger.info(updatedTokens);
    await this.storage.set(SUPPORTED_ASSETS, JSON.stringify(updatedTokens));

    // update token definitions
    const tokenDefinitionService = this.injector.get(TokenDefinitionService);
    await tokenDefinitionService.mergeTokenDefinitions();

    // update api service
    await this.updateTokenApiService();

    // update hidden token list
    const moralisService = this.injector.get(MoralisService);
    await moralisService.getHiddenAssetsFromMoralis();

    // remove from keychain state to update token list
    this.store.dispatch(new HideERC20TokensAction({ keyringId }));

    // upadate notfiers/listners
    const wsAddressNotifier = this.injector.get(WebSocketAddressNotifierService);
    await wsAddressNotifier.updateAvaxTokens();
    await wsAddressNotifier.updateEthTokens();

    // update graph after removing token
    const graphService = this.injector.get(GraphService);
    graphService.calculatePriceOfTransactionHistory();

    this.hideTokenProgress.next(false);
  }

  // Add token to keychain and supported assets  
  public async addNewToken(token: { [key: string]: TokenDefinition } = null, assetGuid: string) {
    const moralisService = this.injector.get(MoralisService);
    const graphService = this.injector.get(GraphService);
    if (this.addTokenProgress.getValue()) { return; }
    try {
      this.addTokenProgress.next(true);

      await this.setSupportedTokenList(token);
      await this.updateTokenApiService();
      this.store.dispatch(new GetAllTokenBalancesAction());

      const wsAddressNotifier = this.injector.get(WebSocketAddressNotifierService);
      await wsAddressNotifier.updateAvaxTokens();
      await wsAddressNotifier.updateEthTokens();
      await moralisService.removeAddedERC20TokenFromState(assetGuid);
      graphService.calculatePriceOfTransactionHistory(); // Update the graph after addition of new token
      
      return true;
    } catch (error) {
      Logger.error('Failed to add/import new token', token, error);
      this.addTokenProgress.next(false);
      return false;
    }
  }

  public async updateTokenApiService() {

    // get wallet address, taking ETH wallet address for now, since its same as AVAX
    const keyChain = await this.store.select(getKeychain).pipe(take(1)).toPromise();
    const ethWallet = getTokenInfo(keyChain, 'ETH');

    // get all assets for ETH and AVAX with current active chain (testnet/mainnet)
    const currentAvaxNetwork = await this.network.getActiveAvaxNetwork();
    const currentEthNetwork = await this.network.getActiveEthNetwork();
    const tokenList = await this.getSupportedTokensList();
    const parsedTokenList = Object.values(tokenList);
    const tokenInfo: TokenInfo[] = parsedTokenList
      .filter((token: any) => (
        ['AVAX', 'ETH'].includes(token.baseChainSymbol) &&
        !isNil(token.assetGuid) &&
        (
          [currentAvaxNetwork.chainId, currentEthNetwork.chainId].includes(token.chainId)
          // token.symbol==='AVAX' // incluse AVAX token too for getting AVAX liquidity pairs
        )
      ))
      .map((token: any) => {
        return {
          name: token.name,
          chainId: token.chainId ? token.chainId : currentAvaxNetwork.chainId,
          symbol: token.symbol,
          address: token.assetGuid,
          decimal: token.precision,
        };
      });

    const newTokenPayload = {
      userAddress: ethWallet.address,
      tokenInfo
    };

    // get user token
    const memberPortalService = this.injector.get(MembersPortalService);
    const jwt = await memberPortalService.returnJwtIfValid();

    try {
      const headers = new HttpHeaders({ Authorization: `Bearer ${jwt}` });
      await this.http.post(
        `${BLOCKCHAIN_SERVICE_API}/addNewToken`,
        newTokenPayload,
        { headers }
      ).toPromise();
    } catch (error) {
      Logger.error('Add new token api service', newTokenPayload, error);
    }
  }

  public async addTokenAddressForFirstTime() {
    if (this.firstTimeAddTokens) { return true; }

    this.firstTimeAddTokens = true;
    await this.updateTokenApiService();
  }

  public async hideErc20Token(token: any) {
    try {
      await this.removeSupportedTokenList(token);
      return true;
    } catch (error) {
      Logger.error('Failed to hide/remove Erc20 token', token, error);
      return false;
    }
  }

  resetUpdateTokenService() {
    this.firstTimeAddTokens = false;
  }

  public async transformTokenInfoIntoTokenInfoExtend(tokenInfos: any): Promise<TokenInfoExtended[]> {
    let tokenInfosExtended: TokenInfoExtended[] = [];
    for (const tokenInfo of tokenInfos) {
      try {
        // @ts-ignore
        const tokenExtended: TokenInfoExtended = { ...tokenInfo };
        tokenExtended.preciseBalance = this.smallToCrypto.transform(tokenInfo.balance, {
          type: tokenInfo.baseChainSymbol,
          precision: tokenInfo.precision,
        });
        tokenExtended.networkLabel = await getCurrentNetworkLabel({
          tokenInfo: tokenInfo,
          networkManager: this.networkManager,
          translateService: this.translate,
        });

        tokenExtended.iconChain = await getCurrentNetworkIcon(tokenInfo.symbol, tokenInfo.baseChainSymbol);
        tokenExtended.showIconInMain = true;
        tokenExtended.digital_currency = tokenInfo.baseChainSymbol === 'AVAX' ? 'AVAX-C' : tokenInfo.baseChainSymbol;
        tokenInfosExtended.push(tokenExtended);
      } catch (ex) {
        Logger.error('transformTokenInfoIntoTokenInfoExtended', ex);
      }
    }
    return tokenInfosExtended;
  }
}
