// from: https://robferguson.org/blog/2017/09/09/a-simple-logging-service-for-angular-4/
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export let isDebugMode = !environment.production;

const noop = (): any => undefined;

@Injectable()
export class Logger {
  /* tslint:disable:no-console */
  static get info() {
    if (isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  static get warn() {
    if (isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  static get error() {
    if (isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }

  static invokeConsoleMethod(type: string, args?: any): void {
    const logFn = (console)[type] || console.log || noop;
    logFn.apply(console, [args]);
  }
  /* tslint:enable:no-console */
}
