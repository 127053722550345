import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from './logger.service';
import { EXTERNAL_APIS } from '../constants';
import { timeout } from 'rxjs/operators';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { SetServiceStatusAction } from '../actions/connection.actions';

@Injectable({
  providedIn: 'root'
})
export class LodeBalanceService {
  private url = EXTERNAL_APIS.MEMBERS_PORTAL + '/getHlfBalances';

  constructor(private http: HttpClient, private store: Store<AppState>) {
  }

  async getLodeBalance(jwt) {
    if (jwt) {
      try {
        let response: any = await this.http.post(`${this.url}?token=${jwt}`, {}).toPromise();
        response = response.lodeBalanceHL;
        this.setServiceStatusInStore('memberPortal', true);
        return response;
      } catch (e) {
        Logger.error('lode balance failed to respond', e);
        this.setServiceStatusInStore('memberPortal', false);
        return { balance: 0 };
      }
    }
  }

  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }
}
