import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";


@Injectable({
  providedIn: 'root',
})
export class SocketService {

  address = '';
  websocketServer = ''
  socket;

  public message$: BehaviorSubject<string> = new BehaviorSubject('');
  constructor(address: string, websocketServer: string) {
    this.address = address;
    this.websocketServer = websocketServer;
    this.connectIO();
  }
  
  connectIO() {
    this.socket = io(this.websocketServer, {
      'reconnection': true,
      'reconnectionDelay': 500,
      'reconnectionAttempts': 10,
      query: {
        "address": this.address
      }
    });
  }

  public sendMessage(data) {
    this.socket.emit('message', data);
  }

  public getNewMessage = () => {
    this.socket.on('message', (message) =>{
      this.message$.next(message);
    });
    
    return this.message$.asObservable();
  };
    
  disconnect() {
    if (this.socket) {
        this.socket.disconnect();
    }
  }
  
}


