import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SetSendTokenDataAction } from 'src/app/angular-wallet-base/actions/gasStation.actions';
import { AGX_GUID, AGX_KEYRING_ID, ASSET_ALLOCATION_FEE, AUTH_REASON, AUX_GUID, AUX_KEYRING_ID, SYS_PER_ASSET_TX } from 'src/app/angular-wallet-base/constants';
import { SmallToCryptoPipe } from 'src/app/angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { GasStationService } from 'src/app/angular-wallet-base/services/gas-station.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { MembersPortalService } from 'src/app/angular-wallet-base/services/members-portal.service';
import { TransactionSenderService } from 'src/app/angular-wallet-base/services/transaction-sender.service';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { getServiceStatus } from 'src/app/angular-wallet-base/store/connection';
import { getKeychain, getTokenKeyring } from 'src/app/angular-wallet-base/store/wallet';
import { SubscriptionListenerComponent } from 'src/app/angular-wallet-base/SubscriptionListenerComponent';
import { getTokenInfo, isEnterKey, satoshiToNum } from 'src/app/angular-wallet-base/utils';
import { PinModalComponent } from 'src/app/components/pin-modal/pin-modal.component';
import { environment } from 'src/environments/environment';
import { ComplianceService } from '../../../angular-wallet-base/services/compliance.service';

@Component({
  selector: 'app-purchase-gas-modal',
  templateUrl: './purchase-gas-model.component.html',
  styleUrls: ['./purchase-gas-model.component.scss']
})
export class PurchaseGasModalComponent extends SubscriptionListenerComponent implements OnInit {

  public isDesktopMode;
  public assetGuid;
  public address: string;
  public assetBalance;
  public sysBalance = 0;
  public assetSymbol;
  public tokenInfo;
  public fromTokenPageAssetGuid;
  public agxInfo;
  public auxInfo;
  public sysInfo;
  public convRate = 1;
  public txConvRate = SYS_PER_ASSET_TX + ASSET_ALLOCATION_FEE;
  public amount = 0;
  public price = 0;
  public txAmount = 100;
  public isAvailable = false;
  public gasStationBalance = 0;
  public userCompletedKyc;
  public disable = false;
  public loading = true;
  private agxConversionRate;
  private auxConversionRate;
  protected subscriptions = new Subscription();
  public showGasStationTextBox = false;
  public noSys = false;
  public notEnoughTokenBalance = false;
  public isLodeLinked;

  constructor(
    private modal: ModalController,
    public appMode: AppModeService,
    private activatedRoute: ActivatedRoute,
    private gasStation: GasStationService,
    protected store: Store<AppState>,
    private authService: AuthService,
    private membersPortal: MembersPortalService,
    private transactionSender: TransactionSenderService,
    private modalController: ModalController,
    private complianceService: ComplianceService
  ) {
    super(store);
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);

    this.store.select(getKeychain).pipe(take(1)).toPromise().then(async keyring => {
      this.loading = true;

      this.agxInfo = getTokenInfo(keyring, AGX_KEYRING_ID);
      this.auxInfo = getTokenInfo(keyring, AUX_KEYRING_ID);
      this.sysInfo = getTokenInfo(keyring, 'SYS');

      this.tokenInfo = this.agxInfo;

      this.fromTokenPageAssetGuid = this.activatedRoute.snapshot.queryParams.assetGuid;
      this.txConvRate = this.sysInfo.feePerAssetTx + ASSET_ALLOCATION_FEE;
      this.sysBalance = this.sysInfo.balance;
      this.userCompletedKyc = (await this.complianceService.getKycCip())?.['passingLevel'];

      const gasStationStatus = await this.gasStation.getStatus();
      this.isLodeLinked = await this.membersPortal.isJwtValid();

      if (!gasStationStatus || !this.isLodeLinked) {
        this.loading = false;
        this.isLodeLinked = false;
        return;
      }

      this.gasStationBalance = gasStationStatus.wallet.balance;
      this.agxConversionRate = await this.gasStation.getConversionRate({ amount: 1, guid: AGX_GUID });
      this.auxConversionRate = await this.gasStation.getConversionRate({ amount: 1, guid: AUX_GUID });

      this.setFixedTxNumber(this.txAmount);
      if (this.fromTokenPageAssetGuid && this.fromTokenPageAssetGuid === AUX_GUID) {
        this.setTokenName('AUX');
      } else {
        this.setTokenName('AGX');
      }
      this.loading = false;
    });

    if (environment.features.customGasTextBox) {
      this.showGasStationTextBox = true;
    }
  }

  async ngOnInit() {
    await this.authService.dismissModal('gasInfoModal');
    this.subscriptions.add(this.store.select(getServiceStatus).subscribe(data => {
      this.isAvailable = data.gasStation;
    }));
    this.subscriptions.add(this.store.select(getTokenKeyring, { keyringId: 'SYS' }).subscribe(async keyring => {
      const { balance } = keyring;
      const sjsKit = await this.transactionSender.getSignAndSendKit('SYS');
      const estimatedFee = Number(await sjsKit.sjs.utils.fetchEstimateFee(sjsKit.backendURL, 6));

      if (estimatedFee >= balance) {
        this.noSys = true;
        return;
      }
    }));

    this.setFixedTxNumber(100);
  }

  currentTxNumber() {
    return Math.floor(satoshiToNum(this.sysBalance) / this.txConvRate) || 0;
  }

  assetPriceChange(agx) {
    const str = (agx * this.convRate).toFixed(8);
    this.amount = Number(str);
    this.txAmount = Math.round(Number(this.amount) / this.txConvRate);

    if (this.txAmount < 5) {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  async setTokenName(tokenName: string) {
    switch (tokenName) {
      case 'AGX':
        this.assetGuid = this.agxInfo.guid;
        this.address = this.agxInfo.address;
        this.assetSymbol = this.agxInfo.symbol;
        this.assetBalance = SmallToCryptoPipe.prototype.transform(
          this.agxInfo.balance,
          {
            precision: 8,
            type: 'SYS'
          }
        );
        this.convRate = this.agxConversionRate || this.convRate; // Xsys/agx
        break;
      case 'AUX':
        this.assetGuid = this.auxInfo.guid;
        this.address = this.auxInfo.address;
        this.assetSymbol = this.auxInfo.symbol;
        this.assetBalance = SmallToCryptoPipe.prototype.transform(
          this.auxInfo.balance,
          {
            precision: 8,
            type: 'SYS'
          }
        );
        this.convRate = this.auxConversionRate || this.convRate; // Xsys/aux
        break;
      default:
        break;
    }
    this.setFixedTxNumber(this.txAmount);
  }

  setFixedTxNumber(num: number) {
    const str = (num * this.txConvRate).toFixed(8);
    this.amount = Number(str);
    this.sysPriceChange(this.amount);
  }

  sysPriceChange(sys) {
    this.txAmount = Math.round(sys / this.txConvRate);
    const str = (this.txAmount * (this.txConvRate / this.convRate || 1)).toFixed(8);
    this.price = Number(str);

    if (this.txAmount < 5) {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  async onSubmit() {
    this.store.dispatch(new SetSendTokenDataAction({
      amount: Number(this.price),
      address: this.address,
      assetGuid: this.assetGuid,
      memo: 'Gas station',
      type: 'REFILL',
      to: ''
    }));

    const modal = await this.modalController.create({
      component: PinModalComponent,
      backdropDismiss: false,
      cssClass: 'fullscreen-modal',
      componentProps: {
        reason: AUTH_REASON.GAS_STATION_SEND
      },
      id: 'pinModal'
    });
    await modal.present();
    modal.onDidDismiss().then(async (result) => {
      if (result.data) {
        this.modal.dismiss(true);
      }
    });
  }

  closeModal() {
    this.modal.dismiss();
  }

}
