import { Action } from '@ngrx/store';
import { AccountSetupState } from '../store/accountSetup';
import {
  SetCredentialsAction,
  SetRegistrationLevelAction,
  SetContactNumberAction,
  SetForgotPasswordOtpAction,
  SetupActionTypes,
  SetUserStatusAction
} from '../actions/accountSetup.actions';

export const initialAccountSetupState = {
  email: '',
  contactNo: {},
  password: '',
  lodeid: null,
  token: null,
  otpCode: '',
  setupLevel: 1,
  isNewUser: true,
  skipRegiserSuccessPage: false,
};

export function accountSetupReducer(state: AccountSetupState = initialAccountSetupState, action: Action): AccountSetupState {
  let payload;
  switch (action.type) {
    case SetupActionTypes.SET_CREDENTIALS:
      payload = (action as SetCredentialsAction).payload;
      return {
        ...state,
        email: payload.email,
        password: payload.password,
        lodeid: payload.lodeid,
        token: payload.token,
        setupLevel: payload.setupLevel
      };

    case SetupActionTypes.SET_CONTACT_NUMBER:
      payload = (action as SetContactNumberAction).payload;
      return { ...state, contactNo: payload.contactNo };

    case SetupActionTypes.SET_OTP_CODE:
      payload = (action as SetForgotPasswordOtpAction).payload;
      return { ...state, otpCode: payload.otpCode };

    case SetupActionTypes.SET_SETUP_LEVEL:
      payload = (action as SetRegistrationLevelAction).payload;
      return { ...state, setupLevel: payload.level };

    case SetupActionTypes.SET_USER_STATUS:
      payload = (action as SetUserStatusAction).payload;
      return { ...state, isNewUser: payload.status };

    case SetupActionTypes.SET_SKIP_REGISTER_SUCCESS_PAGE:
      payload = (action as SetUserStatusAction).payload;
      return { ...state, skipRegiserSuccessPage: payload.skip };

    case SetupActionTypes.RESET_ACCOUNT:
      return { ...initialAccountSetupState };

    default:
      return state;
  }
}

