import { ElementRef, Injectable } from '@angular/core';

export enum IndicatorType {
  Spinner = 'spinner',
}

export interface ILoadingIndicator {
  resourceId: string;
  type: IndicatorType.Spinner;
  originHtmlRef: ElementRef;
}

/**
 * This service is used to manage loading indicators.
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  private loadingIndicators: ILoadingIndicator[] = [];
  private LOADING_INDICATOR_CLASS_SUFFIX = 'LOADING-INDICATOR';
  constructor() { }

  public getIndicatorByResourceId(resourceId: string): ILoadingIndicator {
    return this.loadingIndicators.find(x => x.resourceId === resourceId);
  }

  public addLoadingIndicator(resourceId: string, type: IndicatorType, originHtmlRef: ElementRef): ILoadingIndicator {
    const loadingIndicator = {
      resourceId,
      type,
      originHtmlRef
    };
    this.loadingIndicators.push(loadingIndicator);
    return loadingIndicator;
  }

  public removeLoadingIndicator(resourceId: string): void {
    //remove the rendered element
    this.removeIndicator(this.getIndicatorByResourceId(resourceId));
    //remove the internal reference
    this.loadingIndicators = this.loadingIndicators.filter(x => x.resourceId !== resourceId);
  }

  public renderLoadingIndicator(loadingIndicator: ILoadingIndicator): void {
    switch (loadingIndicator.type) {
      case IndicatorType.Spinner:
        this.renderSpinner(loadingIndicator);
        break;
      default:
        throw new Error(`Loading indicator type ${loadingIndicator.type} is not supported.`);
    }
  }

  public removeIndicator(loadingIndicator: ILoadingIndicator): void {
    const element = document.getElementById(`${loadingIndicator.resourceId}-${this.LOADING_INDICATOR_CLASS_SUFFIX}`);
    switch(loadingIndicator.type) {
      case IndicatorType.Spinner:
        //get the parent element and re-enable
        loadingIndicator.originHtmlRef.nativeElement.disabled = false;
        break;
      default:
        throw new Error(`Loading indicator type ${loadingIndicator.type} is not supported.`);
    }
    element.remove();
  }

  /**
   * Element is id'ed by the resourceId-LOADING-INDICATOR.
   * @param htmlRef 
   * @param id 
   */
  private renderSpinner(loadingIndicator: ILoadingIndicator): void {
    //disable the element
    loadingIndicator.originHtmlRef.nativeElement.disabled = true;
    //insert spinner on native element
    loadingIndicator.originHtmlRef.nativeElement.insertAdjacentHTML('afterbegin', `<ion-spinner name="crescent" id="${loadingIndicator.resourceId}-${this.LOADING_INDICATOR_CLASS_SUFFIX}" class="global-spinner"></ion-spinner>`);
  }
}
