import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE, SYSTX } from '../constants';
import { createTokenKeyring, calculateUnconfBalance, setNumberToPrecision } from '../utils';
import {
  AssetAllocationAmount,
  BaseChainSymbol,
  RawTransactionVerbose,
  RawTransactionVerboseVin,
  RawTransactionVerboseVout,
  SysTx,
  TokenKeyring
} from '../../global';
import {
  BlockbookGetAddressResponse,
  BlockbookGetAddressTxsResponse,
  BlockbookGetAddressTxsResponseWithWalletData,
  BlockbookTokenInfo,
  BlockbookTokenTransfer,
  BlockbookTransaction,
  BlockbookVin,
  BlockbookVout
} from './blockbook';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { NetworkService } from './network.service';
import BigNumber from 'bignumber.js';
import { createKeyringId } from '../keyringUtils';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { EvmNetworkService } from './evm-network.service';
import { MoralisService } from './moralis.service';
import { inspect, isArray } from 'util';
import { Logger } from './logger.service';
import { max } from 'moment';
import { isNil } from '../ts-util';
import { totalmem } from 'os';
export class BlockbookTokenInfoClass implements BlockbookTokenInfo {
  constructor(type: string, name: string, contract: string, transfers: number, 
    symbol: string, decimals: number, balance: number, totalReceived: string, totalSent: string){}
}

export interface BlockCypherResponse {
  address?: string,
  total_received?: string,
  total_sent?: string,
  balance?: string,
  unconfirmed_balance?: string,
  final_balance?: string,
  n_tx?: number,
  unconfirmed_n_tx?: number,
  final_n_tx?: number,
  txrefs?: any,
  tx_url?: string
}
@Injectable({
  providedIn: 'root'
})
export class BlockBookService {

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private networkService: NetworkService,
    private evmNetworkService: EvmNetworkService,
    private moralisService: MoralisService) {
  }

  async getEthAddressApi() {
    const network = await this.networkService.getActiveEthNetwork();

    return `${network.URL}/api/v2/address`;
  }

  async getSolAddressApi() {
    const network = await this.networkService.getActiveSolNetwork();

    return `${network.URL}`;
  }

  async getSysAddressApi() {
    const network = await this.networkService.getActiveSysNetwork();

    return `${network.URL}/api/v2/address`;
  }

  async getBtcAddressApi() {
    const network = await this.networkService.getActiveBtcNetwork();

    return `${network.URL_2}/addrs`;
  }

  private async getBlockbookBalanceFromETH(address: string): Promise<BlockbookGetAddressResponse> { 
    const tokens = await this.moralisService.getEVMWalletTokenBalance(address, await this.moralisService.getEthMoralisChain());
    let blockbookTokenInfos = [];
    tokens?.result?.forEach(
      erc20Token => blockbookTokenInfos.push({
                      type:'ERC20',
                      name: erc20Token.token.name,
                      contract: erc20Token.token.contractAddress.checksum,
                      transfers: erc20Token.amount,
                      symbol: erc20Token.token.symbol,
                      decimals: erc20Token.decimals,
                      balance: erc20Token.amount,
                      totalReceived: 'NA',
                      totalSent: 'NA'
                    })
      );
    const ethAccountInfo = await this.evmNetworkService.getETHBlockchainAddressInfo(address);
    return {...ethAccountInfo, page: 1, itemsOnPage: 10, totalPages: Math.ceil(ethAccountInfo.txs/10) , tokens: blockbookTokenInfos};
  }

  async getEthTokenBalances(address): Promise<TokenKeyring[]> {
    try {
      const response: BlockbookGetAddressResponse = await this.getBlockbookBalanceFromETH(address);
      const ethKeyring = createTokenKeyring({
        symbol: 'ETH',
        address: response.address,
        balance: calculateUnconfBalance(response.balance, response.unconfirmedBalance),
        baseChainSymbol: 'ETH'
      });

      if (!response.tokens) {
        // ETH address with no ERC20 balances wont include a tokens array.
        response.tokens = [];
      }

      const keyrings = this.deriveTokenKeyrings(response, 'ETH');
      this.setServiceStatusInStore('ethRPC', true);
      return [...keyrings, ethKeyring];
    } catch (err) {
       this.setServiceStatusInStore('ethRPC', false);
        Logger.error('getBlockbookBalanceFromETH', err);
      return [{}];
    }
  }

  async getBlockbookBalanceFromBTC(address: string): Promise<BlockbookGetAddressResponse> {
    try {
      if (!address) {
        Logger.warn(`error for input`)
        return {}
      }
      const blockCypherResponse: BlockCypherResponse = await this.http.get(`${await this.getBtcAddressApi()}/${address}`).toPromise();
      return {
        address: blockCypherResponse.address,
        balance: blockCypherResponse.balance,
        page: 1,
        itemsOnPage: 10,
        totalPages: Math.ceil(blockCypherResponse.n_tx/10),
        totalReceived: blockCypherResponse.total_received,
        totalSent: blockCypherResponse.total_sent,
        unconfirmedBalance: blockCypherResponse.unconfirmed_balance,
        unconfirmedTxs: blockCypherResponse.unconfirmed_n_tx,
        txs: blockCypherResponse.n_tx,
        nonce: blockCypherResponse.n_tx.toString(),
        tokens:[]
      }

    } catch (e) {
      Logger.error('getBlockbookBalanceFromBTC', inspect(e));
      return {};
    }
  }

  async getBtcBalance(address): Promise<TokenKeyring[]> {
    try {
      if (!address) {
        Logger.warn(`error input should not null ${address}`);
        return [{}]; 
      }
      const response: BlockbookGetAddressResponse = await this.getBlockbookBalanceFromBTC(address);
      const btcKeyring = createTokenKeyring({
        symbol: 'BTC',
        address: response.address,
        balance: calculateUnconfBalance(response.balance, response.unconfirmedBalance),
        baseChainSymbol: 'BTC'
      });

      if (!response.tokens) {
        response.tokens = [];
      }

      const keyrings = this.deriveTokenKeyrings(response, 'BTC');
      this.setServiceStatusInStore('btcBB', true);
      return [...keyrings, btcKeyring];
    } catch (err) {
      this.setServiceStatusInStore('btcBB', false);
      return [{}];
    }

  }

  /**
   * ethFilterIndex is depreciated because moving away from Blockbook
   * @param address 
   * @param page 
   * @param itemsPerPage 
   * @param ethFilterIndex 
   * @param tokenContract 
   * @returns 
   */
  async getEthTokenHistory(address: string, page: number, itemsPerPage: number, ethFilterIndex: number, tokenContract: string) {
      const ethTokenHistory = await this.evmNetworkService.getEthereumTokenHistory(address, page, itemsPerPage, tokenContract);
      const response:BlockbookGetAddressTxsResponse = {transactions: ethTokenHistory, page: page};
      return { keyringId: createKeyringId('ETH', tokenContract), ...response };
  }

  async getSysTokenBalances(address): Promise<TokenKeyring[]> {
    try {
      const response: BlockbookGetAddressResponse = await this.http.get(
        `${await this.getSysAddressApi()}/${address}?details=tokenBalances`
      ).toPromise();
      const sysKeyring = createTokenKeyring({
        symbol: 'SYS',
        address: response.address,
        balance: calculateUnconfBalance(response.balance, response.unconfirmedBalance),
        baseChainSymbol: 'SYS'
      });

      if (!response.tokens) {
        response.tokens = [];
      }

      const keyrings = this.deriveTokenKeyrings(response, 'SYS');
      this.setServiceStatusInStore('sysBB', true);
      return [...keyrings, sysKeyring];
    } catch (err) {
      this.setServiceStatusInStore('sysBB', false);
      return [{}];
    }

  }


  async getBtcHistory(address, page, itemsPerPage) {

    let response: BlockbookGetAddressTxsResponse = {
      address: address
    };

    if (!address) {
       return { ...response, keyringId: createKeyringId('BTC', '') };
    }
    
    try {
      page = page == 0 ? 1: page;
      const endItem = page * itemsPerPage;
      const blockCypherFullTransactionHistory: any = await this.http.get(`${await this.getBtcAddressApi()}/${address}/full?limit=${endItem}`).toPromise();

      response.balance = blockCypherFullTransactionHistory.balance;
      response.page = page;
      response.itemsOnPage = itemsPerPage;
      response.totalPages = Math.ceil(blockCypherFullTransactionHistory.n_tx/ itemsPerPage);
      response.totalReceived = blockCypherFullTransactionHistory.total_received;
      response.totalSent = blockCypherFullTransactionHistory.total_sent;
      response.unconfirmedBalance = blockCypherFullTransactionHistory.unconfirmed_balance;
      response.unconfirmedTxs = blockCypherFullTransactionHistory.unconfirmed_n_tx;
      response.nonTokenTxs = blockCypherFullTransactionHistory.n_tx.toString();
      response.txs = blockCypherFullTransactionHistory.n_tx;
      const historyTransactions = blockCypherFullTransactionHistory.txs;
      response.transactions = [];
      if (!isNil(historyTransactions) && Array.isArray(historyTransactions)) {
         const startItem = (page - 1) * itemsPerPage;
         const transactionSize = historyTransactions.length;
         if (transactionSize <= startItem) {
          response.transactions = [];
         } else if (transactionSize > startItem && transactionSize <= endItem) {
          for (let i = startItem; i < transactionSize; i++) {
            response.transactions.push(this.buildBlockBookBTCTransaction(historyTransactions[i], address));
          }
         } else {
          for (let i = startItem; i < endItem; i++) {
            response.transactions.push(this.buildBlockBookBTCTransaction(historyTransactions[i], address));
          }
         }
      }
    } catch (e) {
      Logger.error('getBtcHistory', inspect(e));
      response.transactions = []
    }

    const result: BlockbookGetAddressTxsResponseWithWalletData = { ...response, keyringId: createKeyringId('BTC', '') };
    return result;
  }

  private buildBlockBookBTCTransaction(blockCypherTransaction: any, address: string): BlockbookTransaction {
    let blockbookTransaction: BlockbookTransaction = {};
    blockbookTransaction.txid = blockCypherTransaction.hash;
    blockbookTransaction.version = blockCypherTransaction.ver;
    blockbookTransaction.vin = [];
    // TODO eric need to fix this logic
    if (!isNil(blockCypherTransaction.inputs) && Array.isArray(blockCypherTransaction.inputs)) {
      for (var input of blockCypherTransaction.inputs) {
        let blockbookVin: BlockbookVin = {
          txid: input.prev_hash,
          vout: input.output_value,
          sequence: input.sequence,
          n: input.output_index,
          addresses: input.addresses,
          isAddress: !isNil(input.addresses)
        }
        blockbookTransaction.vin.push(blockbookVin);
      }
    }
    blockbookTransaction.vout = [];

    if (!isNil(blockCypherTransaction.outputs) && Array.isArray(blockCypherTransaction.outputs)) {
      for (var output of blockCypherTransaction.outputs) {
        let blockbookVout: BlockbookVout = {
          addresses: output.addresses,
          isAddress: !isNil(output.addresses),
          value: output.value,
          hex: output.script,
          assetInfo: output.script_type
        }
        blockbookTransaction.vout.push(blockbookVout);
      }
    }

    blockbookTransaction.blockHash = blockCypherTransaction.block_hash;
    blockbookTransaction.blockHeight = blockCypherTransaction.block_height;
    blockbookTransaction.confirmations = blockCypherTransaction.confirmations;
    blockbookTransaction.blockTime = blockCypherTransaction.confirmed;

    let valueIn = 0;
    if (!isNil(blockbookTransaction.vin)) {
      for (var vin of blockbookTransaction.vin) {
        if (!isNil(vin)) {
          for (var vinAddress of vin.addresses) {
            if (vinAddress === address) {
              valueIn += vin.vout;
            }
          }
        }
      }
    }

    let valueOut = 0;

    if (isNil(blockbookTransaction.vout)) {
      for (var vout of blockbookTransaction.vout) {
        if (!isNil(vout)) {
          for (var voutAddress of vout.addresses) {
            if (voutAddress === address) {
              valueOut += parseInt(vout.value);
            }
          }
        }
      }
    }
    
    blockbookTransaction.value = Math.abs(valueIn - valueOut).toString();
    blockbookTransaction.valueIn = blockCypherTransaction.total;
    blockbookTransaction.fees = blockCypherTransaction.fees;
   
    return blockbookTransaction;
  }

  async getSysTokenHistory(address, page, itemsPerPage, assetGuid): Promise<BlockbookGetAddressTxsResponseWithWalletData> {
    let filter = '';
    if (assetGuid) {
      filter = `&contract=${assetGuid}`;
    }
    // else {
    //   filter = `&filter=0` // Only SYS txs
    // }
    const response: BlockbookGetAddressTxsResponse = await this.http.get(`${await this.getSysAddressApi()}/${address}?details=txslight&pageSize=${itemsPerPage}&page=${page}${filter}`).toPromise();

    if (!response.transactions) {
      response.transactions = [];
    }

    const result: BlockbookGetAddressTxsResponseWithWalletData = { ...response, keyringId: createKeyringId('SYS', assetGuid) };
    return result;
  }

  deriveTokenKeyrings(response, baseChain: BaseChainSymbol): TokenKeyring[] {
    const keyrings = [];
    response.tokens.forEach(token => {
      if (token === null) {
        return;
      }

      let balance;
      balance = calculateUnconfBalance(token.balance, token.unconfirmedBalance);

      const keyring = createTokenKeyring(
        {
          symbol: token.symbol,
          address: response.address,
          balance: balance,
          baseChainSymbol: baseChain,
          guid: token.contract || token.assetGuid,
          ethFilterIndex: undefined,
          precision: token.decimals
        }
      );
      keyrings.push(keyring);
    });

    return keyrings;
  }

  convertToCoreTransactionFormat(bbTx: BlockbookTransaction): RawTransactionVerbose {
    const tx: RawTransactionVerbose = {
      amount: Number(bbTx.value),
      blockhash: bbTx.blockHash,
      blocktime: bbTx.blockTime,
      confirmations: bbTx.confirmations,
      hash: null,
      hex: bbTx.hex,
      in_active_chain: null,
      locktime: null,
      size: null,
      time: bbTx.blockTime,
      txid: bbTx.txid,
      type: null,
      version: bbTx.version,
      vin: bbTx.vin.map(vin => this.convertToCoreVin(vin)),
      vout: bbTx.vout.map(vout => this.convertToCoreVout(vout)),
      vsize: null,
      weight: null,
      systx: bbTx.tokenType ? this.convertToCoreSysTx(bbTx.tokenTransfers[0], bbTx) : null,
      fee: Number(bbTx.fees)
    };

    return tx;
  }

  convertToCoreVin(bbVin: BlockbookVin): RawTransactionVerboseVin {
    return {
      ...bbVin,
      txid: bbVin.txid,
      vout: bbVin.vout,
      scriptSig: null,
      txinwitness: null,
      sequence: bbVin.sequence
    };
  }

  convertToCoreVout(bbVout: BlockbookVout): RawTransactionVerboseVout {
    return {
      value: new BigNumber(bbVout.value).toNumber(),
      n: bbVout.n,
      scriptPubKey: {
        asm: null,
        hex: bbVout.hex,
        reqSigs: null,
        addresses: bbVout.addresses
      },
      assetInfo: bbVout.assetInfo ? bbVout.assetInfo : null
    };
  }

  convertToCoreSysTx(bbTokenTransfer: BlockbookTokenTransfer, bbTx: BlockbookTransaction): SysTx {
    switch (bbTx.tokenType) {
      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_NEW:
        break;

      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_UPDATE:
        break;

      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_TRANSFER:
        break;

      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_SEND:
      case BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_ALLOCATION_SEND:
        return {
          txtype: bbTx.tokenType === BLOCKBOOK_SYS_TOKEN_TRANSFER_TYPE.ASSET_SEND ? SYSTX.ASSET_SEND : SYSTX.ASSET_ALLOCATION_SEND,
          asset_allocation: `${bbTokenTransfer.token}-${bbTokenTransfer.from}`,
          assetGuid: parseFloat(bbTokenTransfer.token),
          symbol: bbTokenTransfer.symbol,
          txid: bbTx.txid,
          height: bbTx.blockHeight,
          sender: bbTx.vin[0].addresses[0], // bbTokenTransfer.from,
          allocations: bbTx.vout.filter(vo => (vo.isAddress)).map(vo => this.convertToCoreAssetAllocation(vo)),
          total: Number(bbTokenTransfer.totalAmount),
          blockhash: bbTx.blockHash,
          aux_fee: bbTokenTransfer.fee
        };
    }

    return null;
  }

  convertToCoreAssetAllocation(vo: BlockbookVout): AssetAllocationAmount {
    return {
      address: vo.addresses[0],
      amount: vo.assetInfo ? Number(vo.assetInfo.value) : Number(vo.value)
    };
  }

  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }
}
