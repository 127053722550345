import { Component, Input, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController, Platform, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-agx-tos',
  templateUrl: './agx-tos.component.html',
  styleUrls: ['./agx-tos.component.scss'],
})
export class AgxTosComponent implements OnInit {
  public tosObj = {};
  public close = true;
  constructor(private navParams: NavParams, private modal: ModalController,
  ) { }

  ngOnInit() {
    this.tosObj = this.navParams.data.tosObj;
    this.close = this.navParams.data.close;
  }

  closeComponent() {
    this.modal.dismiss();
  }
}
