import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, startWith, take } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Logger } from './logger.service';
import {
  BLOCKCHAIN_SERVICE_API,
  LIQUIDITY_PAIRS,
  LIQUIDITY_PAIRS_1INCH,
  LIQUIDITY_PAIRS_1INCH_WITH_SERVICE,
  LIQUIDITY_PAIRS_WITH_SERVICE,
} from '../constants';
import { isNil } from '../ts-util';
import { BehaviorSubject, from, interval, of, Subscription, timer } from 'rxjs';
import { TraderJoeService } from './trader-joe.service';
import { TraderJoeV2Service } from './trader-joe-v2.service';
import { inspect } from 'util';
import { MembersPortalService } from './members-portal.service';
import { Store } from '@ngrx/store';
import { getKeychain } from '../store/wallet';
import { NetworkService } from './network.service';

import { supportedAssetList } from '../lib/supported-assets/supported-assets';

interface SwapEstimation {
  pair: string;
  from: string;
  to: string;
  slippageTorelance: number;
  amount: number;
  price: number;
  priceImpact: number;
  minimumReceived: number;
  fee: number;
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TradeService {

  public getLiquidityLoader = new BehaviorSubject(false);
  private liquidityInterval: Subscription = null;
  private currentNetwork;

  private avax: string;
  private wavax: string;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private traderJoeService: TraderJoeService,
    private traderJoeV2Service: TraderJoeV2Service,
    private memberPortalService: MembersPortalService,
    private store: Store,
    private networkService: NetworkService
  ) {
    (async () => {
      this.currentNetwork = await this.networkService.getActiveAvaxNetwork();
    })();

    this.avax = supportedAssetList.AVAX.assetGuid;
    this.wavax = supportedAssetList.AVAX.wrapped;
  }

  // set liquidity for token pairs : scheduled for 5 mins
  async setAvailableLiquidityPairs() {
    if (!isNil(this.liquidityInterval)) {
      return;
    }

    // scheduler to find and set liquity pairs in storage
    this.liquidityInterval = interval(60000 * 5).pipe(startWith(0)).subscribe(async (_) => {

      if (this.getLiquidityLoader.getValue()) { return; } // handle previous calls

      this.getLiquidityLoader.next(true);

      const supportedTokens = await this.store.select(getKeychain).pipe(take(1)).toPromise();
      const tokens: any = Object.values(supportedTokens);

      await this.traderJoeService.setTokenBases(tokens); // set token bases for trader-joe (currently USDC & WAVAX)

      // tokens filter for AVAX 
      const tokenAddress = tokens
        .filter(token => (token.guid && token.baseChainSymbol === 'AVAX'))
        .map(token => token.guid && token);
      const tokenGuids = tokenAddress.map(token => token.guid.toLowerCase());
      tokenGuids.push(this.currentNetwork.NATIVE_TOKEN_ADDRESS); // add AVAX native address manually 

      let quoteliquidityPairs;
      try {
        const avaxWallet = tokens.find(token => (token.baseChainSymbol === 'AVAX' && token.symbol === 'AVAX'));
        const jwt = await this.memberPortalService.returnJwtIfValid();
        const headers = new HttpHeaders({ 
          Authorization: `Bearer ${jwt}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Method": 'GET, POST, PUT, DELETE',
          "Access-Control-Allow-Headers": "*",
        });
        const quoteliquidityPairResponse: any = await this.http.get(
          `${BLOCKCHAIN_SERVICE_API}/getMyLiquidityPool/${avaxWallet.address}`,
          { headers }
        ).pipe().toPromise();
        quoteliquidityPairs = quoteliquidityPairResponse.data;
      } catch (error) {
        Logger.error('Failed to get liquidity pairs', inspect(error));
        return;
      }

      const limitOrderTokenPairs = [];
      const limitOrderTokenPairsWithService = {};
      const parsedTokenPairs = [];
      const parsedLiquidityPairs = {};
      for (const pair in quoteliquidityPairs) {
        if (pair) {
          parsedLiquidityPairs[pair.toLowerCase()] = quoteliquidityPairs[pair];
          const currentPair = pair.split('-').map(token => token.toLowerCase());
          parsedTokenPairs.push(currentPair);
        }
        if (pair && quoteliquidityPairs[pair] === '1INCH') { // take token pairs of 1inch onyl
          limitOrderTokenPairsWithService[pair.toLowerCase()] = quoteliquidityPairs[pair];
          const currentPair = pair.split('-').map(token => token.toLowerCase());
          limitOrderTokenPairs.push(currentPair)
        }
      }

      // group token pairs
      const limitOrderGroupedPairs = {}
      const groupedPairs = {};
      for (const token of tokenGuids) {
        const mappedToken = parsedTokenPairs
          .filter(pair => pair.includes(token))
          .map(pair => {
            if (pair[0].toLowerCase() === token.toLowerCase()) {
              return pair[1];
            } else {
              return pair[0];
            }
          })
          .filter((value, index, array) => array.indexOf(value) === index);
        groupedPairs[token] = mappedToken;

        // group token based on 1inch liquidity only
        const limitOrderMappedToken = limitOrderTokenPairs
          .filter(pair => pair.includes(token))
          .map(pair => {
            if (pair[0].toLowerCase() === token.toLowerCase()) {
              return pair[1];
            } else {
              return pair[0];
            }
          })
          .filter((value, index, array) => array.indexOf(value) === index);
        limitOrderGroupedPairs[token] = limitOrderMappedToken;
      }

      // store liquidity pairs with service
      await this.storage.set(LIQUIDITY_PAIRS_WITH_SERVICE, JSON.stringify(parsedLiquidityPairs));
      await this.storage.set(LIQUIDITY_PAIRS, JSON.stringify(groupedPairs));

      // store liquidity pairs with service
      await this.storage.set(LIQUIDITY_PAIRS_1INCH_WITH_SERVICE, JSON.stringify(limitOrderTokenPairsWithService));
      await this.storage.set(LIQUIDITY_PAIRS_1INCH, JSON.stringify(limitOrderGroupedPairs));

      this.getLiquidityLoader.next(false);

      Logger.info('Liquidity pairs with service', parsedLiquidityPairs);
      Logger.info('Liquidity pairs', groupedPairs);
      Logger.info('1inch Liquidity pairs with service', limitOrderTokenPairsWithService);
      Logger.info('1inch  Liquidity pairs', limitOrderGroupedPairs);
    });
  }

  // get liquidity service available for trade
  async getLiquidityService(tokenFrom: string, tokenTo: string) {
    let liquidityPairsWithService = await this.storage.get(LIQUIDITY_PAIRS_WITH_SERVICE);
    if (liquidityPairsWithService) {
      liquidityPairsWithService = JSON.parse(liquidityPairsWithService);
      const pairPossiblitity = [
        `${tokenFrom.toLowerCase()}-${tokenTo.toLowerCase()}`,
        `${tokenTo.toLowerCase()}-${tokenFrom.toLowerCase()}`,
      ];
      return liquidityPairsWithService[pairPossiblitity[0]] || liquidityPairsWithService[pairPossiblitity[1]];
    } else {
      return null;
    }
  }

  // get allowance for TRADE JOE with wallet
  async checkAllowance(walletAddress: string, tokenFrom: string, tokenTo: string) {
    if (tokenFrom !== this.avax) {
      return await this.traderJoeV2Service.checkAllowance(tokenFrom, walletAddress);
    }
  }

  // get estimation/quote price for token pairs
  async getEstimationForSwap(
    tokenFrom: string,
    tokenTo: string,
    amount: number,
    slippage: number
  ): Promise<SwapEstimation> {
    if (tokenFrom === this.avax) {
      tokenFrom = this.wavax;
    } else if (tokenTo === this.avax) {
      tokenTo = this.wavax;
    }

    return await this.traderJoeV2Service.getEstimation(tokenFrom, tokenTo, amount, slippage);
  }

  // get payload for swap
  async getPayloadForSwap(
    tokenAddress: string,
    tokenFrom: string,
    tokenTo: string,
    amount: number,
    slippage: number
  ) {
    if (tokenFrom === this.avax) {
      tokenFrom = this.wavax;
    } else if (tokenTo === this.avax) {
      tokenTo = this.wavax;
    }

    return await this.traderJoeV2Service.getPayloadForSwap(tokenAddress, tokenFrom, tokenTo, amount, slippage);
  }

  // set max allowed value for router
  async approveTheMaxAllowanceForRouter(tokenFrom: string, tokenTo: string, wallet: any) {
    if (tokenFrom !== this.avax) {
      const payload = await this.traderJoeV2Service.approveTheMaxAllowanceForRouter(tokenFrom, wallet);
      return of(payload).pipe(delay(5000)).toPromise(); // delayed for 5 seconds for Approval to update  
    }
  }

  // swap/market order
  async swapOrderMarketPlace(tokenFrom: string, tokenTo: string, userWallet: any, marketPayload: any) {
    if (tokenFrom === this.avax) {
      marketPayload.avaxFrom = true; 
    } else if (tokenTo === this.avax) {
      marketPayload.avaxTo = true;
    }

    return await this.traderJoeV2Service.swapOrderMarketPlace(userWallet, marketPayload);
  }

  resetLiquidityScheduler() {
    this.liquidityInterval.unsubscribe();
    this.liquidityInterval = null;
  }
}
