import { Action } from '@ngrx/store';
import { DevicesListState } from '../store/deviceslist';
import {
  SetUserDeviceAction,
  RemoveUserDeviceAction,
  SetUserDevicesAction,
  GetUserDevicesSuccessAction,
  DevicesListActionTypes
} from '../actions/devicesList.actions';
import { encryptData } from '../utils';

export const initialUserDeviceState: DevicesListState = {
  devices: {
    data: [],
    error: false
  },
  newDevice: {
    ip: '',
    agent: '',
    most_recent_device_date: ''
  }
};

export function devicesListReducer(
  state: DevicesListState = initialUserDeviceState,
  action: Action): DevicesListState {

  let payload;
  switch (action.type) {
    case DevicesListActionTypes.SET_USER_DEVICE_DATA:
      payload = (action as SetUserDeviceAction).payload;
      return {
        ...state,
        newDevice: {
          ip: payload.ip,
          agent: payload.agent,
          most_recent_device_date: payload.most_recent_device_date
        }
      };

    case DevicesListActionTypes.SET_USER_DEVICES_DATA:
      const newdevices = (action as SetUserDevicesAction).payload.devices;
      return {
        ...state,
        devices: {
          data: [...state.devices.data, newdevices],
          error: false
        }
      };

    case DevicesListActionTypes.GET_USER_DEVICES_SUCCESS:
      const devices = (action as GetUserDevicesSuccessAction).payload.devices;
      return {
        ...state,
        devices: {
          data: devices,
          error: false
        }
      };

    case DevicesListActionTypes.GET_USER_DEVICES_FAILURE:
      return {
        ...state,
        devices: {
          data: [],
          error: true
        }
      };

    case DevicesListActionTypes.REMOVE_USER_DEVICE_DATA:
      const device = (action as RemoveUserDeviceAction).payload.device;
      return {
        ...state,
        devices: {
          data: state.devices.data.filter(item => item !== device),
          error: false
        }
      };

    case DevicesListActionTypes.RESET_USER_DEVICES_DATA:
      return { ...initialUserDeviceState };

    default:
      return state;
  }
}
