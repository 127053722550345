import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import moment from 'moment';
import { map, take } from 'rxjs/operators';
import { inspect } from 'util';
import { EXCHANGE_RATES, EXCHANGE_RATE_URL } from '../constants';
import { Logger } from './logger.service';
import { NetworkService } from './network.service';
import { StorageService } from './storage.service';

export class TokenData {
  constructor(
    public symbol: string,
    public name: string,
    public decimals: string
  ) { }
}

export class TokenPair {
  constructor(
    public name: string,
    public token0: TokenData,
    public token1: TokenData
  ) { }
}

export class TraderJoeSwapData {
  constructor(
    public id: string,
    public pair: TokenPair,
    public amount0In: string,
    public amount0Out: string,
    public amount1In: string,
    public amount1Out: string,
    public amountUSD: string
  ) { }
}

export class TraderJoeSwapTransactionData {
  constructor(
    public id: string,
    public swaps: TraderJoeSwapData[]
  ) { }
}

@Injectable({
  providedIn: 'root'
})

export class GraphqlService {

  private latestExchangeRate: any;
  private apolloExchangeEndPoint: ApolloBase<any>;
  private apolloCandleEndPoint: ApolloBase<any>;

  constructor(
    private apolloProvider: Apollo,
    private network: NetworkService,
    private http: HttpClient,
    private storage: StorageService
  ) {
    (async () => {
      const activeNetwork = await this.network.getActiveAvaxNetwork();
      this.apolloExchangeEndPoint = activeNetwork.id == 'avax-main'
        ? this.apolloProvider.use('exchange')
        : this.apolloProvider.use('exchangeFuji');
      this.apolloCandleEndPoint = activeNetwork.id == 'avax-main'
        ? this.apolloProvider.use('dexcandles')
        : this.apolloProvider.use('dexcandles-fuji');
    })();
  }

  async getActiveNetwork() {
    return await this.network.getActiveAvaxNetwork();
  }

  async getSwapTransactionData(address: string) {
    try {
      const SWAP_TRANSACTON_DATA = gql`
      query exchange($to: Bytes!) {
        transactions(
          orderBy: timestamp, 
          orderDirection: desc, 
          where: {swaps_: {to: $to}}
        ) {
          swaps {
            timestamp,
            id
            pair {
              token0 {
                id,
                symbol
              }
              token1 {
                id,
                symbol
              }
            }
            amount1In
            amount0Out
            amount0In
            amount1Out
          }
        }
      }`;
      return this.apolloExchangeEndPoint.query<any>({
        query: SWAP_TRANSACTON_DATA,
        variables: {
          to: address
        }
      }).pipe(take(1)).toPromise().then(({ data }) => data.transactions
        .map(result => result.swaps[0])
        .map(txn => ({
          id: txn.id,
          user_id: txn.pair.token1.id,
          amount: parseFloat(txn.amount0Out) > 0 ? parseFloat(txn.amount0Out).toFixed(6) : parseFloat(txn.amount1Out).toFixed(6),
          token: parseFloat(txn.amount0Out) > 0 ? txn.pair.token0.symbol?.toLowerCase() : txn.pair.token1.symbol?.toLowerCase(),
          order_type: 'Crypto',
          state: 'confirmed',
          created_at: new Date(Number(txn.timestamp) * 1000).toISOString(),
        }))
      );
    } catch (error) {
      Logger.error('Error getting swap transactions for address', address, error);
      return [];
    }
  }

  async getTraderJoeTransactionDetail(transactionId: string): Promise<TraderJoeSwapTransactionData> {
    try {
      const SwapTransactionDataById = gql`
      query exchange($txId: Bytes!) {
        transaction(
          id: $txId
        ) {
          id
          blockNumber
          timestamp
          swaps {
            id
            amount0In
            amount0Out
            amount1In
            amount1Out
            amountUSD
            pair {
              name
              token0{
                symbol
                decimals
              }
              token1{
                symbol
                decimals
              }
            }
          }
        }
      }`;
      const transactionQuery = this.apolloExchangeEndPoint.watchQuery<any>({
        query: SwapTransactionDataById,
        variables: {
          txId: transactionId
        }
      });
      return await transactionQuery.valueChanges.pipe(take(1)).toPromise().then(({ data }) => data.transaction);
    } catch (err) {
      Logger.error('getTraderJoeTransactionDetail ', inspect(err));
      return;
    }
  }

  async getCandleData({ token0, token1, period, startTime }: { token0: string, token1: string, period: number, startTime: number }) {
    try {
      const CANDLE_CHART_DATA = gql`
      query dexcandles($token0: String!, $token1: String!, $period: Int!, $startTime: Int!) {
        candles(
          orderBy: time, 
          orderDirection: desc, 
          where: {
            time_gt: $startTime, 
            period: $period, 
            token0: $token0, 
            token1: $token1
          }
        ) {
          id
          time
          open
          close
          low
          high
          token0TotalAmount
          token1TotalAmount
        }
      }`;
      return this.apolloCandleEndPoint.query<any>({
        query: CANDLE_CHART_DATA,
        variables: {
          token0: token0.toLowerCase(),
          token1: token1.toLowerCase(),
          period: period,
          startTime: startTime
        },
      }).pipe(map(response => {
        Logger.info('response', response);
        const candleData = response?.data?.candles
          ? response.data.candles.sort((a, b) => a.time - b.time) as []
          : [];
        return candleData;
      })).toPromise();
    } catch (error) {
      Logger.error('Error getting candle data for address', { token0, token1, period, startTime }, error);
      return [];
    }
  }

  async setLatestExchangeRate() {
    const currency = ['USD', 'EUR', 'CAD', 'MXN', 'GBP', 'JPY'];
    const url = EXCHANGE_RATE_URL + `/latest?base=USD&symbols=${currency.join(',')}&source=crypto&places=2`;
    try {
      const exchangeRatesResponse: any = await this.http.get(url, {}).pipe().toPromise();
      if (exchangeRatesResponse && exchangeRatesResponse.rates) {
        this.latestExchangeRate = exchangeRatesResponse.rates;
      } else {
        Logger.info(`No exchange rates found . . .`);
        return [];
      }
    } catch (error) {
      Logger.info('Failed to get exchange rates', error);
      return [];
    }
  }

  async getExchangeRate() {
    const startDate1 = moment().subtract(366, "days").format('YYYY-MM-DD');
    const endDate1 = moment().subtract(360, "days").format('YYYY-MM-DD');
    const startDate2 = moment().subtract(359, "days").format('YYYY-MM-DD');
    const endDate2 = moment().format('YYYY-MM-DD');
    const currency = ['USD', 'EUR', 'CAD', 'MXN', 'GBP', 'JPY'];

    if (!this.latestExchangeRate) {
      await this.setLatestExchangeRate();
    }

    const exchangeRates = await this.storage.get(EXCHANGE_RATES);
    if (exchangeRates) {
      const exchangeRatesParsed = JSON.parse(exchangeRates);
      const lastUpdatedRate = exchangeRatesParsed[exchangeRatesParsed.length - 1];
      const lastUpdatedDate = lastUpdatedRate ? lastUpdatedRate.date : null;
      if (lastUpdatedDate === endDate2) {
        return exchangeRatesParsed;
      }
    }

    // calling as a single endpoint (last 365 days) had skipped response date (like last 2 days are not coming)
    const url1 = EXCHANGE_RATE_URL + `/timeseries?base=USD&symbols=${currency.join(',')}&start_date=${startDate1}&end_date=${endDate1}&source=crypto&places=2`;
    const url2 = EXCHANGE_RATE_URL + `/timeseries?base=USD&symbols=${currency.join(',')}&start_date=${startDate2}&end_date=${endDate2}&source=crypto&places=2`;

    try {
      let exchangeRatesResponse = [];
      return await Promise.all([
        this.http.get(url1, {}).pipe().toPromise(),
        this.http.get(url2, {}).pipe().toPromise()
      ]).then(async data => {
        data.forEach((exchangesRateResponse: any) => {
          const exchangeRateResponseData = exchangesRateResponse.rates;
          const exchangeRates = Object.keys(exchangeRateResponseData).map(key => ({ ...exchangeRateResponseData[key], date: key }));
          exchangeRatesResponse = [...exchangeRatesResponse, ...exchangeRates];
        });
        exchangeRatesResponse = exchangeRatesResponse.map(obj => {
          const x = { ...obj };
          x.date = new Date(x.date);
          return x;
        });
        exchangeRatesResponse = exchangeRatesResponse.sort((a, b) => b.date - a.date);
        await this.storage.set(EXCHANGE_RATES, JSON.stringify(exchangeRatesResponse));
        return exchangeRatesResponse;
      }).catch(function (error) {
        return [];
      });
    } catch (error) {
      Logger.info('Failed to get exchange rates', error);
      return [];
    }
  }

  getPriceHistory(token: string) {
    try {
      const PRICE_HISTORY_DATA = gql`
      query priceHistory($token0: String!) {
        token(id: $token0) {
          dayData(first: 367, orderBy: date, orderDirection: desc) {
            date,
            priceUSD
          }
        }
      }`;
      return this.apolloProvider.use('exchange').query<any>({
        query: PRICE_HISTORY_DATA,
        variables: {
          token0: token
        },
      }).pipe(map(response => {
        Logger.info('response', response);
        const responseData = response?.data?.token?.dayData
          ? response.data.token?.dayData as []
          : [];
        return responseData;
      })).toPromise();
    } catch (error) {
      Logger.error('Error getting price history data for address', token, error);
      return [];
    }
  }

  async getPriceHistoryWithExchange(token: string, currencyValue: string) {
    const currency = currencyValue.toUpperCase();
    const exchangeRate = await this.getExchangeRate();
    const priceHistory = await this.getPriceHistory((token.toLowerCase()));
    Logger.info('exchachnge', exchangeRate, priceHistory);
    const priceHistoryWithExchange = exchangeRate.map((value, index) => {
      const currenyRate = value[currency]
        ? Number(value[currency])
        : this.latestExchangeRate[currency] ? this.latestExchangeRate[currency] : null; // TODO: may need to some changes
      const priceRate = priceHistory[index] ? Number(priceHistory[index]['priceUSD']) : null; // TODO: may need to some changes
      const exchangeRate = (currenyRate * priceRate).toFixed(3);
      const date = moment(value['date']).format('x');
      return [Number(date), Number(exchangeRate)];
    });

    return priceHistoryWithExchange;
  }

}
