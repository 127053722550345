import { NgModule } from '@angular/core';
import { SmallToCryptoPipe } from './smalltocrypto.pipe';
import { CurrencyPipe } from '@angular/common';

@NgModule({
  imports: [],
  exports: [SmallToCryptoPipe],
  declarations: [SmallToCryptoPipe],
  providers: [CurrencyPipe]
})
export class SmallToCryptoPipeModule {
}
