import {
  BaseChainSymbol,
  TokenInfo,
  TokenInfoGroupedBySymbol,
  TokenKeyring,
  TransactionSummary,
  WalletKeychain,
} from '../global';
import { arrayConcat } from './arrayutils';
import { Logger } from './services/logger.service';
import networks from './lib/pangolin/config/network.config';
import { TokenDefinitionService } from './services/token-definition.service';
import { ethers } from 'ethers';
import { BigNumber } from 'bignumber.js';
import {
  AGX_KEYRING_ID,
  AUX_KEYRING_ID,
  CURRENCY_SYMBOL,
  ONEINCH_ROUTER_ADDRESS,
  PREVENT_AUTH_ON_RESUME_TIMEOUT,
  TRADER_JOE_ROUTER_ADDRESS,
  TokenType,
} from './constants';
import { MembersPortalService } from './services/members-portal.service';
import { BankAccountService } from './services/bank-account.service';
import { VirtualDebitCardService } from './services/virtual-debit-card.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { createKeyringId } from './keyringUtils';
import { Store } from '@ngrx/store';
import { AppState } from './store/appState';
import { PreventAuthOnResumeAction } from './actions/wallet.actions';
import { AlertController, ModalController, Platform, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import parsePhoneNumber from 'libphonenumber-js';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { KycModalComponent } from '../components/kyc-modal/kyc-modal.component';
import { SimplexModalCheckoutComponent } from '../components/simplex-modal/simplex-modal-checkout.component';
import { SuccessModalComponent } from '../views/send-asset-modal/success-modal/success-modal.component';
import * as solanaWeb3 from '@solana/web3.js';
import { KycConfirmModalComponent } from '../components/kyc-confirm-modal/kyc-confirm-modal';
import { ComplianceService } from './services/compliance.service';
import { inspect } from 'util';
import { PrimaryAddressSuccessFailComponent } from '../views/settings/preference/primary-address-success-fail-modal/success-fail-modal';
import { TokenManagerService } from './services/token-manager.service';
import { SelectTokenInfo } from '../views/select-asset-modal/select-asset-modal.component';
import { NetworkService } from './services/network.service';
import { BigNumber as EthersBigNumber } from 'ethers';
import { isNil, isValidJSON } from './ts-util';
import { utils } from 'ethers'
const semver = require('semver');

const bitcoin = (window as any).bitcoinjsLib;
const CryptoJS = require('crypto-js');
const sjs = require('syscoinjs-lib');
export function createTokenKeyring({
  symbol,
  address,
  balance,
  baseChainSymbol,
  guid,
  ethFilterIndex,
  precision,
}: {
  symbol: BaseChainSymbol;
  address: string;
  balance: any;
  baseChainSymbol: BaseChainSymbol;
  guid?: any;
  ethFilterIndex?: any;
  precision?: any;
}): TokenKeyring {
  return {
    symbol,
    address,
    balance,
    baseChainSymbol,
    guid,
    ethFilterIndex,
    precision,
    keyringId: createKeyringId(baseChainSymbol, guid),
  };
}

export const calculateUnconfBalance = (balance, unconfBalance) => {
  const operation = unconfBalance ? unconfBalance[0] : '0';
  let totalBalance;

  switch (operation) {
    case '+':
      totalBalance = Number(balance) + Math.abs(Number(unconfBalance));
      break;
    case '-':
      totalBalance = Number(balance) - Math.abs(Number(unconfBalance));
      break;
    default:
      return balance;
  }

  return totalBalance.toString();
};

export const isBaseChainSymbol = (baseChainSymbol: string): boolean =>
  baseChainSymbol.split('-') ? baseChainSymbol.split('-').length === 1 : false;

export const buildChainArray = (symbol, groupedTokens) => {
  const chainList = [];
  groupedTokens[symbol].forEach((item) => {
    if (item.showIconInMain === true) {
      chainList.push(item.keyringId);
    }
  });
  return chainList;
};

export const groupByTokenSymbol = (objectArray: { symbol: string }[]): TokenInfoGroupedBySymbol => {
  const objTokens = objectArray.reduce((r, a) => {
    r[a.symbol] = [...(r[a.symbol] || []), a];
    return r;
  }, {});
  let sym;
  for (sym of Object.keys(objTokens)) {
    const arr = objTokens[sym];
    customSortAssets({
      data: arr,
      sortBy: ['SYS', 'HL', 'PT'],
      sortField: 'baseChainSymbol',
    });
  }
  return objTokens;
};

export const getTokenInfoArray = (keychain: WalletKeychain): TokenInfo[] => {
  const filteredList = [];
  Object.entries(keychain).forEach(([key, keyring]) => {
    if (typeof keyring === 'object' && keyring.keyringId) {
      const tokenDef = TokenDefinitionService.getTokenDefinition(keyring.keyringId);
      filteredList.push(Object.assign({ ...keyring }, tokenDef));
    }
  });
  return filteredList;
};

export const getEVMTokenInfoArray = (keychain: WalletKeychain): TokenInfo[] => {
  let tokens = getTokenInfoArray(keychain);
  return tokens.filter(token => token.baseChainSymbol !== 'HL' && token.baseChainSymbol !== 'BTC');
}

export const getTokenInfoExcludeHLArray = (keychain: WalletKeychain): TokenInfo[] => {
  let tokens = getTokenInfoArray(keychain);
  return tokens.filter(token => token.baseChainSymbol !== 'HL');
}

export const getTokenInfoExcludeHLAndPTArray = (keychain: WalletKeychain): TokenInfo[] => {
  let tokens = getTokenInfoArray(keychain);
  return tokens.filter(token => (token.baseChainSymbol !== 'HL' && token.custodyService !== 'PT'));
}

export const getPrimeTrustTokenInfo = (keychain: WalletKeychain): TokenInfo[] => {
    let tokens = getTokenInfoArray(keychain);
    const assetPrimeTokenList = ['AGX', 'AUX', 'LODE'];
    return tokens.filter(token => (
            token.baseChainSymbol !== 'HL' 
            && token.custodyService !== 'PT' 
            && assetPrimeTokenList.includes(token.symbol)));
    
}

export const getNonAssetPrimeTokenList = (keychain: WalletKeychain): TokenInfo[] => {
  let tokens = getTokenInfoArray(keychain);
  const nonAssetPrimeTokenList = ['AGX', 'AUX', 'LODE', 'ETH', 'BTC', 'USDC', 'AVAX'];
  return tokens.filter(token => (
          token.baseChainSymbol !== 'HL' 
          && token.custodyService !== 'PT' 
          && nonAssetPrimeTokenList.includes(token.symbol)));
  
}

export const checkNumberLimit = (min: number, max: number): ValidatorFn => {
  return (c: AbstractControl): { [key: string]: boolean } | null => {
    if (c.value && (isNaN(c.value) || `${c.value}`.length < min || `${c.value}`.length > max)) {
      return { range: true };
    }
    return null;
  };
};

export const checkValidCardExpiry = (month: string, year: string) => {
  // Returns true if the check fails
  const d = new Date();
  const m = d.getMonth();
  const y = d.getFullYear();
  const lastTwoDigits = parseInt(`${y}`.substr(2));
  if (parseInt(month) < 1 || parseInt(month) > 12 || parseInt(year) < lastTwoDigits) {
    // First check the input validity then check the expiry date
    return true;
  } else if (parseInt(year) === lastTwoDigits && parseInt(month) < m + 1) {
    // Check if same year then check the month
    return true;
  }
  return false;
};

export const getTokenInfo = (keychain: WalletKeychain, keyringId: string): TokenInfo => {
  let result;
  Object.values(keychain).forEach((keyring) => {
    if (keyring.keyringId === keyringId) {
      const tokenDef = TokenDefinitionService.getTokenDefinition(keyring.keyringId);
      result = Object.assign({ ...keyring }, tokenDef);
    }
  });

  return result;
};

export const getBitcoinTransactionHash = (hash) => {
  const hexStr = hash.reverse().toString('hex');
  hash.reverse(); // restore original
  return hexStr;
};

export const getVinAddress = (vin): string => {
  const funcs = ['p2sh', 'p2wpkh'];
  let vinAddress = null;
  funcs.forEach((func) => {
    if (vinAddress) {
      return;
    }
    try {
      const result = bitcoin.payments[func]({
        witness: vin.witness,
        network: networks.testnet,
        input: vin.input,
      });
      vinAddress = result.address;
    } catch (e) { }
  });
  return vinAddress;
};

export const isVinMine = (vin, isMinetester): string => {
  return isMinetester(getVinAddress(vin)) ? getVinAddress(vin) : null;
};

interface TransactionAmountResult {
  presentIn: string[];
  inputs: any;
  outputs: any;
}

export const getTransactionAmounts = (txin, isMinetester): TransactionAmountResult => {
  const tx = txin;
  let mine = null;
  const result = {
    presentIn: [],
    inputs: {},
    outputs: {},
  };
  tx.vin.forEach((vin) => {
    mine = isVinMine(vin, isMinetester);
    if (mine) {
      if (!result.presentIn.includes('input')) {
        result.presentIn.push('input');
      }
    }
  });

  txin.vout.forEach((vout) => {
    if (vout.scriptPubKey.addresses) {
      vout.scriptPubKey.addresses.forEach((address) => {
        const isAddressMine = isMinetester(address);
        if (isAddressMine) {
          if (!result.presentIn.includes('output')) {
            result.presentIn.push('output');
          }
        }
        if (!result.outputs[address]) {
          result.outputs[address] = 0;
        }
        result.outputs[address] += vout.value;
      });
    }
  });
  return result;
};

export type TxSummary = {
  fromAddress: string;
  toAddress: string;
  amount: number;
  time: string;
  confirmations: string;
  txid: string;
  type: string;
  tokenInfo: any;
  fee: number;
  memo: string;
  assetAllocFee: number;
  systx?: any;
  auxFee?: number;
  auxFeeAddress?: string;

  // todo: review whether this actually exists
  cancelled?: boolean;
};

export const isTxASwap = (toAddress: string): boolean => {
  // trim empty space toAddress
  toAddress = ethers.utils.getAddress(toAddress.trim());
  const routerAddresses = [TRADER_JOE_ROUTER_ADDRESS, ONEINCH_ROUTER_ADDRESS];
  // check if the toAddress matches any of routerAddresses
  return routerAddresses.includes(toAddress);
};

export const decodeAbiSwap = (data: string, abi?: any): any => {
  if (!abi) {
    return ethers.utils.defaultAbiCoder.decode(
      ['uint256', 'address[]', 'address', 'uint256'],
      ethers.utils.hexDataSlice(data, 4)
    );
  } else {
    return ethers.utils.defaultAbiCoder.decode(abi, ethers.utils.hexDataSlice(data, 4));
  }
}

export const getTransactionAmountsBySysnevmAddress = (tx, walletAddress) => {
  // Gets outputs value for each address present in vin/vout
  const result = {
    txid: tx.tx_hash,
    totalValue: ethers.BigNumber.from(tx.total.value), // total baseChain amount of transaction
    fee: ethers.utils.formatUnits(tx.fee.value, 10), // for some reason, the fee coming from rollux explorer api has 18 decimals...
    type: tx.from.hash.toLowerCase() === walletAddress.toLowerCase() ? 'send' : 'receive', // send, receive
  };

  return result;
}

export const getTransactionAmountsByEVMAddress = (tx, walletAddress) => {
  // Gets outputs value for each address present in vin/vout
  const result = {
    txid: tx.txid,
    totalValue: ethers.BigNumber.from(tx.value), // total baseChain amount of transaction
    type: null, // send, receive, self
  };

  if (tx.from.toLowerCase() === walletAddress.toLowerCase()) {
    result.type = 'send';
  }

  if (tx.value === '0') {
    if (tx.from.toLowerCase() === walletAddress.toLowerCase()) {
      result.type = 'send';
    }
    result.totalValue = ethers.BigNumber.from(tx.gasPrice * tx.gasUsed);
  }

  if (!result.type) {
    // If wallet address is not found on vins, set tx type to "receive"
    result.type = 'receive';
  }

  return result;
};

export const getTransactionAmountsByAddressSol = (tx, walletAddress) => {
  // Gets outputs value for each address present in vin/vout
  const result = {
    txid: tx.txid,
    totalValue: tx.value, // total baseChain amount of transaction
    type: null, // send, receive, self
  };

  if (tx.from.toLowerCase() === walletAddress.toLowerCase()) {
    result.type = 'send';
  }

  if (!result.type) {
    // If wallet address is not found on vins, set tx type to "receive"
    result.type = 'receive';
  }

  return result;
};

export const getTransactionAmountsByAddress = (tx, walletAddress, isEth?) => {
  // Gets outputs value for each address present in vin/vout
  const result = {
    txid: tx.txid,
    totalValue: 0, // total baseChain amount of transaction
    type: null, // send, receive, self
    vinAddresses: [], // Addresses involved in inputs
    voutAddresses: [], // Addresses involved in outputs
    amounts: {}, // Final coin/token allocations
  };

  tx.vin
    .filter((vin) => vin.addresses)
    .forEach((vin) => {
      const vinAddress = vin.addresses[0]; // Input address

      // Gotta keep a track of the addresses involved in tx
      if (!result.vinAddresses.includes(vinAddress)) {
        result.vinAddresses.push(vinAddress);
      }

      if (vinAddress === walletAddress) {
        // If wallet address is found in vins, set tx type to "send"
        result.type = 'send';
      }
    });

  if (isEth && tx.tokenTransfers) {
    // Base implementation works for normal ETH txs, but doesn't take ERC20 into consideration
    // Check token transfers (ERC20) for tx type.
    tx.tokenTransfers.forEach((tt) => {
      if (tt.from === walletAddress) {
        result.type = 'send';
      }
    });
  }

  if (!result.type) {
    // If wallet address is not found on vins, set tx type to "receive"
    result.type = 'receive';
  }

  tx.vout
    .filter((vout) => vout.addresses)
    .forEach((vout) => {
      const voutAddress = vout.addresses[0]; // Output address

      if (!voutAddress || voutAddress.indexOf('OP_RETURN') !== -1) {
        // case1: assetsend operations will not carry any of this data at all
        // case2: Do not include OP_RETURN outputs
        return;
      }

      // Gotta keep a track of the addresses involved in tx
      if (!result.voutAddresses.includes(voutAddress)) {
        result.voutAddresses.push(voutAddress);
      }

      if (voutAddress) {
        if (!result.amounts[voutAddress]) {
          // Create if doesn't exists in result.amounts
          result.amounts[voutAddress] = {
            outputs: [],
            times: 0,
            value: 0, // baseChain value (SYS outputs could be a mix between SYS and token values)
            tokenValue: {}, // token values
            address: voutAddress,
          };
        }

        if (vout.assetInfo && !isEth) {
          // If this output contains token info, append it to the results
          const tokenGuid = vout.assetInfo.assetGuid;

          if (!result.amounts[voutAddress].tokenValue[tokenGuid]) {
            result.amounts[voutAddress].tokenValue[tokenGuid] = 0; // initialize if it does not exist.
          }

          result.amounts[voutAddress].tokenValue[tokenGuid] += Number(vout.assetInfo.value); // Add value to the total sum
        }

        // Add output value to result
        result.amounts[voutAddress].times++;
        result.amounts[voutAddress].value += Number(vout.value);
        result.amounts[voutAddress].outputs.push(vout);
        if (result.type === 'send' && vout.addresses[0] != walletAddress) {
            result.totalValue += Number(vout.value);
        } 
        if (result.type === 'receive' && vout.addresses[0] == walletAddress) {
            result.totalValue += Number(vout.value);
        } 
      }
    });

  if (isEth && tx.tokenTransfers) {
    // Base implementation works for normal ETH txs, but doesn't take ERC20 into consideration
    // Add ERC20 txs to tokenValue object
    tx.tokenTransfers.forEach((tt) => {
      if (!result.amounts[tt.to]) {
        result.amounts[tt.to] = {
          tokenValue: {},
        };
      }

      result.amounts[tt.to].tokenValue[tt.token] = Number(tt.value);
    });
  }

  if (result.type === 'send' && result.amounts[walletAddress] && Object.keys(result.amounts).length === 1) {
    // If wallet address was found on vins and more than once in vouts (or is spt tx sending to itself), set the tx type to "self"
    // Assuming vouts contains legit output and change output set to walletAddress
    result.type = 'self';
  }

  return result;
};

export const ethGetTransactionAmount = (tx) => {
  // Don't know normal eth tx format yet...
  return tx.tokenTransfers ? tx.tokenTransfers[0].value : tx.value;
};

export const ethGetTransactionType = (tx, address) => {
  if (tx.tokenTransfers) {
    // ERC20 tx
    if (tx.tokenTransfers[0].from === address) {
      return 'send';
    } else if (tx.tokenTransfers[0].to === address) {
      return 'receive';
    }
  } else {
    if (tx.vout.find((output) => !!output.addresses.find((addr) => addr === address))) {
      return 'receive';
    }
    if (tx.vin.find((input) => !!input.addresses.find((addr) => addr === address))) {
      return 'send';
    }
  }

  return 'unknown';
};

export const avaxGetTransactionType = (tx, address) => {
  if (tx.from === address) {
    return 'receive';
  } else {
    return 'send';
  }
};

export const getTransactionAddress = (txin, isMinetester) => {
  const tx = txin;
  let vaddress = null;
  tx.vin.forEach((vin) => {
    if (vaddress) {
      return;
    }
    if (!isMinetester(getVinAddress(vin))) {
      vaddress = getVinAddress(vin);
    }
  });
  if (vaddress) {
    return vaddress;
  }
  txin.vout.forEach((vout) => {
    if (vaddress) {
      return;
    }
    if (vout.scriptPubKey.addresses) {
      vout.scriptPubKey.addresses.forEach((address) => {
        if (!isMinetester(address)) {
          vaddress = address;
        }
      });
    }
  });
  return vaddress;
};

const memoHeader = 'fefeafafafaf';

export const getTransactionMemo = (txn): string => {
  try {
    const script = txn.vout.find(
      (out) =>
        // Find OP_RETURN output
        out.addresses[0].indexOf('OP_RETURN') !== -1
    ).addresses[0];
    const memo = sjs.utils.getMemoFromScript(script, memoHeader);
    // Decoding the transaction memo to text (hex string => Uint8Array => text)
    const memoArray = new Uint8Array(
      memo.match(/[\da-f]{2}/gi).map((h) => {
        return parseInt(h, 16);
      })
    );
    return new TextDecoder('utf-8').decode(memoArray);
  } catch (err) {
    Logger.error('getTransactionMemo', inspect(err));
    // No memo...
    return '';
  }
};

export const setTransactionMemo = (txn, memo: string): boolean => {
  let processed = false;

  if (!memo) {
    return false;
  }
  for (let key = 0; key < txn.outs.length; key++) {
    const out = txn.outs[key];
    const chunksIn = bitcoin.script.decompile(out.script);
    if (chunksIn[0] !== bitcoin.opcodes.OP_RETURN) {
      continue;
    }
    txn.outs.splice(key, 1);

    const updatedData = arrayConcat(Uint8Array, chunksIn[1], memoHeader, new Buffer(memo));
    txn.addOutput(bitcoin.script.compile([bitcoin.opcodes.OP_RETURN, new Buffer(updatedData)]), 0);

    processed = true;
    break;
  }
  if (processed) {
    return processed;
  }
  return txn.addOutput(
    bitcoin.script.compile([bitcoin.opcodes.OP_RETURN, new Buffer(arrayConcat(Uint8Array, memoHeader, new Buffer(memo)))]),
    0
  );
};

export const mergeObjByKey = (obj: object, src: object): object => {
  Object.keys(src).forEach((key) => Object.assign(obj[key], src[key]));
  return obj;
};

export const showFormErrors = (form, formErrors, validationMessages) => {
  for (const field of Object.keys(formErrors)) {
    // clear previous error message (if any)
    formErrors[field] = '';
    const control = form.get(field);
    // Using control.value instead of control.dirty,
    // because it might lead to errors when other fields change the value of the current control
    // causing the control to be populated but still being pristine.
    if (control && control.value && !control.valid) {
      const messages = validationMessages[field];
      for (const key in control.errors) {
        if (control.errors.hasOwnProperty(key) && messages.hasOwnProperty(key)) {
          formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }
};

export const getAuxFeeFromTx = (tx, auxFeePossibleAddress: Array<string>): number => {
  if (typeof tx === 'string') {
    tx = JSON.parse(tx);
  }

  if (!Array.isArray(auxFeePossibleAddress)) {
    return 0;
  }

  const auxFeeVout = tx.vout.find((vout) => auxFeePossibleAddress.includes(vout.addresses[0]));

  return auxFeeVout ? Number(auxFeeVout.assetInfo.value) : 0;
};

export const showTransactionFee = (
  alertController,
  translate,
  amount: number,
  txFee: string,
  coin: string = null,
  auxFee: number,
  tokenService: TokenManagerService
) =>
  new Promise(async (resolve, reject) => {
    const supportedAssetList = await tokenService.getSupportedTokensList();
    coin = coin ? coin : supportedAssetList[AGX_KEYRING_ID].symbol;
    const gasSymbol = supportedAssetList[`SYS`].baseChainSymbol,
      headerText = await translate.get('tokens.send.transaction_fees.header').toPromise(),
      sendAmountText = await translate.get('tokens.send.transaction_fees.send_amount').toPromise(),
      auxFeeText = await translate.get('tokens.send.transaction_fees.aux_fee').toPromise(),
      txFeeText = await translate.get('tokens.send.transaction_fees.gas').toPromise(),
      totalText = await translate.get('tokens.send.transaction_fees.total').toPromise(),
      confirmText = await translate.get('tokens.send.transaction_fees.confirm').toPromise();

    const totalAmount = (amount + auxFee).toFixed(6);
    const alert = await alertController.create({
      header: headerText,
      // tslint:disable-next-line
      message: `
      <p><strong>${sendAmountText}</strong>: ${amount} ${coin}</p>
      <p><strong>${auxFeeText}</strong>: ${auxFee} ${coin}</p>
      <p><strong>${txFeeText}</strong>: ~ ${txFee} ${gasSymbol}</p>
      <p><strong>${totalText}</strong>: ${totalAmount} ${coin} / ~${txFee} ${gasSymbol}</p>
      <p>${confirmText}</p>`,
      buttons: [
        {
          text: await translate.get('btn_cancel').toPromise(),
          role: 'cancel',
          handler: () => reject(),
        },
        {
          text: await translate.get('btn_ok').toPromise(),
          handler: () => resolve(true),
        },
      ],
    });

    return await alert.present();
  });

export const showTransactionCoinFee = (
  alertController: AlertController,
  translate: TranslateService,
  amount: number,
  txEth: number,
  txFee: number,
  coin: string,
  coinName: string,
  networkCoin: string = null
) =>
  new Promise(async (resolve, reject) => {
    const formattedTxFee = txFee.toFixed(6),
      formattedTxEth = txEth.toFixed(6),
      headerText = await translate.get('tokens.send.transaction_details.header').toPromise(),
      sendAmountText = await translate
        .get('tokens.send.transaction_details.send_amount', { coinName: coin ? coin : coinName })
        .toPromise(),
      gasFeeText = await translate.get('tokens.send.transaction_details.gas_fee').toPromise(),
      gasFeePriceText = await translate.get('tokens.send.transaction_details.gas_fee_price').toPromise(),
      confirmText = await translate.get('tokens.send.transaction_details.confirm').toPromise();

    const totalAmount = amount.toFixed(7);
    const alert = await alertController.create({
      header: headerText,
      // tslint:disable-next-line
      message: `
        <p><strong>${sendAmountText}</strong>: ${totalAmount} ${coin}</p>
        <p><strong>${gasFeeText}</strong>: ${formattedTxEth} ${networkCoin ? networkCoin : coin}</p>
        <p><strong>${gasFeePriceText}</strong>: $${formattedTxFee}</p>
        <p>${confirmText}</p>`,
      buttons: [
        {
          text: await translate.get('btn_cancel').toPromise(),
          role: 'cancel',
          handler: () => reject(),
        },
        {
          text: await translate.get('btn_ok').toPromise(),
          handler: () => resolve(true),
        },
      ],
    });
  });

export const showTransactionCoinFeeAvax = (
  alertController: AlertController,
  translate: TranslateService,
  amount: number,
  txEth: number,
  txFee: number,
  coin: string,
  coinName: string,
  networkCoin: string = null,
  tofixedNumber: number = 6
) =>
  new Promise(async (resolve, reject) => {
    const formattedTxFee = txFee.toFixed(tofixedNumber),
      formattedTxEth = txEth.toFixed(tofixedNumber),
      headerText = await translate.get('tokens.send.transaction_details.header').toPromise(),
      sendAmountText = await translate
        .get('tokens.send.transaction_details.send_amount', { coinName: coin ? coin : coinName })
        .toPromise(),
      gasFeeText = await translate.get('tokens.send.transaction_details.gas_fee').toPromise(),
      gasFeePriceText = await translate.get('tokens.send.transaction_details.gas_fee_price').toPromise(),
      confirmText = await translate.get('tokens.send.transaction_details.confirm').toPromise();

    const totalAmount = amount.toFixed(tofixedNumber);
    const alert = await alertController.create({
      header: headerText,
      // tslint:disable-next-line
      message: `
          <p><strong>${sendAmountText}</strong>: ${totalAmount} ${coin}</p>
          <p><strong>${gasFeeText}</strong>: ${formattedTxEth} ${networkCoin ? networkCoin : coin}</p>
          <p><strong>${gasFeePriceText}</strong>: $${formattedTxFee}</p>
          <p>${confirmText}</p>`,
      buttons: [
        {
          text: await translate.get('btn_cancel').toPromise(),
          role: 'cancel',
          handler: () => reject(),
        },
        {
          text: await translate.get('btn_ok').toPromise(),
          handler: () => resolve(true),
        },
      ],
    });
    return await alert.present();
  });

export const showTransactionCoinFeeSol = (
  alertController: AlertController,
  translate: TranslateService,
  amount: number,
  txEth: number,
  txFee: number,
  coin: string,
  coinName: string,
  networkCoin: string = null,
  tofixedNumber: number = 6
) =>
  new Promise(async (resolve, reject) => {
    const formattedTxFee = txFee.toFixed(tofixedNumber),
      formattedTxEth = txEth.toFixed(tofixedNumber),
      headerText = await translate.get('tokens.send.transaction_details.header').toPromise(),
      sendAmountText = await translate
        .get('tokens.send.transaction_details.send_amount', { coinName: coin ? coin : coinName })
        .toPromise(),
      gasFeeText = await translate.get('tokens.send.transaction_details.gas_fee').toPromise(),
      gasFeePriceText = await translate.get('tokens.send.transaction_details.gas_fee_price').toPromise(),
      confirmText = await translate.get('tokens.send.transaction_details.confirm').toPromise();

    const totalAmount = amount.toFixed(tofixedNumber);
    const alert = await alertController.create({
      header: headerText,
      // tslint:disable-next-line
      message: `
            <p><strong>${sendAmountText}</strong>: ${totalAmount} ${coin}</p>
            <p><strong>${gasFeeText}</strong>: ${formattedTxEth} ${networkCoin ? networkCoin : coin}</p>
            <p><strong>${gasFeePriceText}</strong>: $${formattedTxFee}</p>
            <p>${confirmText}</p>`,
      buttons: [
        {
          text: await translate.get('btn_cancel').toPromise(),
          role: 'cancel',
          handler: () => reject(),
        },
        {
          text: await translate.get('btn_ok').toPromise(),
          handler: () => resolve(true),
        },
      ],
    });
    return await alert.present();
  });

export const showTransactionSysFee = (
  alertController: AlertController,
  translate: TranslateService,
  amount: number,
  netFee: string,
  coin: string,
  coinName: string,
  netDollarFee: string = ''
) =>
  new Promise(async (resolve, reject) => {
    const headerText = await translate.get('tokens.send.transaction_details.header').toPromise(),
      sendAmountText = await translate
        .get('tokens.send.transaction_details.send_amount', { coinName: coin ? coin : coinName })
        .toPromise(),
      gasFeeText = await translate.get('tokens.send.transaction_details.gas_fee').toPromise(),
      gasFeePriceText = await translate.get('tokens.send.transaction_details.gas_fee_price').toPromise(),
      confirmText = await translate.get('tokens.send.transaction_details.confirm').toPromise();

    const totalAmount = amount.toFixed(7);
    const alert = await alertController.create({
      header: headerText,
      // tslint:disable-next-linePlatform
      message: `
        <p><strong>${sendAmountText}</strong>: ${totalAmount} ${coin}</p>
        <p><strong>${gasFeeText}</strong>: ~ ${netFee} SAT</p>
        ${netDollarFee ? '<p><strong>' + gasFeePriceText + '</strong>: ~ $' + netDollarFee + '</p>' : ''}
        <p>${confirmText}</p>`,
      buttons: [
        {
          text: await translate.get('btn_cancel').toPromise(),
          role: 'cancel',
          handler: () => reject(),
        },
        {
          text: await translate.get('btn_ok').toPromise(),
          handler: () => resolve(true),
        },
      ],
    });
    return await alert.present();
  });

export function encryptData(password: string, data: object): string {
  const str = JSON.stringify(data);
  const res = CryptoJS.AES.encrypt(str, password);
  return res.toString();
}

export async function decryptData(password: string, encryptedData: string): Promise<any> {
  const res = await CryptoJS.AES.decrypt(encryptedData, password);
  return JSON.parse(res.toString(CryptoJS.enc.Utf8));
}

// note this can be removed after tx list fixes
export const getTransactionSummary = (sptTx, txHex, myAddress, bbTx): TransactionSummary => {
  // Logger.info('get tx summary', txJson, myAddress);
  const inAddresses = [];
  const outAddresses = [];
  const summary: TransactionSummary = {};
  const tx = bitcoin.Transaction.fromHex(txHex);

  // track some stuff we care about
  let addressCountInVins = 0;
  let addressCountInVouts = 0;

  if (!sptTx.systx) {
    // non-asset tx
    getInputAddressesFromVins(tx.ins).forEach((vaddress) => {
      inAddresses.push(vaddress);
      if (vaddress === myAddress) {
        addressCountInVins++;
      }
    });

    sptTx.vout.forEach((vout) => {
      if (vout.scriptPubKey.addresses) {
        vout.scriptPubKey.addresses.forEach((address) => {
          outAddresses.push(address);
          if (address === myAddress) {
            addressCountInVouts++;
          }
        });
      }
    });

    // Logger.info('addr vin/vout', addressCountInVins, addressCountInVouts);

    if (addressCountInVouts === 0 && addressCountInVins > 0) {
      summary.type = 'send';
    } else if (addressCountInVins > 0) {
      summary.type = 'send';
    } else if (addressCountInVins === 0 && addressCountInVouts > 0) {
      summary.type = 'receive';
    }

    if (summary.type === 'receive') {
      summary.fromAddress = inAddresses[0]; // assumes all inputs come from same address!
      summary.toAddress = myAddress;
    } else {
      summary.fromAddress = myAddress;
      summary.toAddress = outAddresses.find((address) => address !== myAddress);

      // if we are sending to self toAddress will be undefined because all outputs come back to us
      if (!summary.toAddress) {
        summary.toAddress = myAddress;
      }
    }
  } else {
    // asset tx
    // vout index is tricky here. We have little accuracy checking which vout is the one we look for
    // because auxfee could be index 0 if present
    // If not present, then the "correct" address is most likely on index 0.
    summary.toAddress = bbTx.vout[bbTx.length > 3 ? 1 : 0].addresses[0];
    summary.fromAddress = bbTx.vin[0].addresses[0];

    if (summary.fromAddress !== myAddress) {
      summary.type = 'receive';
    } else {
      summary.type = 'send';
    }
  }

  return summary;
};

function getInputAddressesFromVins(ins) {
  const result = [];
  ins.forEach((input) => {
    try {
      const p2sh = bitcoin.payments.p2sh({
        witness: input.witness,
        network: networks.testnet,
        input: input.script,
      });

      // Logger.info('Decoded', input.script.toString(), 'to', p2sh.address);
      result.push(p2sh.address);
    } catch (e) {
      // Logger.info('Failed to decode', input.script.toString(), ' s p2sh');
      try {
        const p2wpkh = bitcoin.payments.p2wpkh({
          witness: input.witness,
          network: networks.testnet,
          input: input.script,
        });

        // Logger.info('Decoded', input, 'to', p2wpkh.address);
        result.push(p2wpkh.address);
      } catch (e) {
        // Logger.error('Failed to decode', input.witness.toString(), 'as p2wpkh');
      }
    }
  });

  return result;
}

export const openLinkExternal = (linkUrl, iab: InAppBrowser, options?: InAppBrowserOptions, store?: Store<AppState>) => {
  if (store) {
    const action = new PreventAuthOnResumeAction({ preventLogout: true, preventAuthDuration: PREVENT_AUTH_ON_RESUME_TIMEOUT });
    store.dispatch(action);
  }
  iab.create(linkUrl, '_system', options);
  return false;
};

/**
 * Parses a block explorer Syscoin-cli raw error object into a translation key string and a params object if the key uses params
 * @param errStr raw error string from server, should be a JSON object
 * @param sysBalance balance of syscoin
 */
export const parseSyscoinError = (errObj, sysBalance: number): { key: string; params?: any } => {
  try {
    Logger.error(`parseSyscoinError: ${inspect(errObj)}`);
    if (errObj?.response?.data?.message && typeof errObj.response.data.message === 'string') {
       const errMessage = JSON.parse(errObj.response.data.message)[0].error_msg;
       return {
        key: errMessage,
      };
    }

    if (errObj?.message && errObj?.message.indexOf('txVersion') !== -1) {
      return {
        key: 'tokens.send.errors.txversion',
        params: {
          coin: 'SYS',
        }
      };
    }

    if (!isNil(errObj?.error?.error)) {
      const err = JSON.parse(errObj.e);
      if (err.message.indexOf('ERRCODE: 5502') > -1) {
        // insufficient funds error
        return {
          key: 'tokens.send.errors.insufficient_funds',
          params: {
            amountShort: err.message.substr(err.message.indexOf('by:') + 4),
          },
        };
      }

      if (err.message.indexOf('ERRCODE: 9000') > -1) {
        // not enough outputs error
        if (sysBalance < 0.0002) {
          return {
            key: 'tokens.send.errors.not_enough_outputs_balance',
          };
        } else {
          return {
            key: 'tokens.send.errors.not_enough_outputs_unconfirmed_txs',
          };
        }
      }

      if (err.message.indexOf('dust (code 64)') > -1) {
        // dust error
        return {
          key: 'tokens.send.errors.dust',
        };
      }

      if (err.message.indexOf('ERRCODE: 1503') > -1) {
        // please wait error
        return {
          key: 'tokens.send.errors.please_wait',
        };
      }
      Logger.error('parseSyscoinError', inspect(err));
      return {
        key: 'tokens.send.errors.system_error',
        params: {
          errMsg: err?.message?.toString().substr(0,50)
        }
      }
    }
    Logger.error('parseSyscoinError', inspect(errObj));
    return {
      key: 'tokens.send.errors.system_error',
      params: {
        errMsg: errObj?.message?.toString().substr(0,50)
      }
    }
  } catch (err) {
    Logger.error('parse sys err', inspect(err));
    return {
      key: 'tokens.send.errors.system_error',
      params: {
        errMsg: err?.message?.toString().substr(0,50)
      }
    }
  }
};

// tinygraphs `http://tinygraphs.com/labs/isogrids/hexa16/${uniqueStr}?theme=frogideas&numcolors=4&size=220&fmt=svg`
// robohash
// adorable.io `https://api.adorable.io/avatars/${size}/${uniqueStr}.png`;
// dicebear gridy
// AGX:   return `http://tinygraphs.com/labs/isogrids/hexa16/${uniqueStr}?theme=frogideas&numcolors=4&size=220&fmt=svg`;
// SYS:   return `https://avatars.dicebear.com/v2/bottts/${uniqueStr}.svg?options[colors][]=lightBlue`;
export const getAvatarUrl = (uniqueStr, size) => {
  return `http://tinygraphs.com/labs/isogrids/hexa16/${uniqueStr}?theme=frogideas&numcolors=4&size=220&fmt=svg`;
};

/* Copy current text to clipboard */
export const copyToClipboard = (data: string): boolean => {
  const textarea = document.createElement('textarea');
  try {
    textarea.textContent = data;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();
    textarea.focus();
    /* This particular function requires User Interaction, so it won't run as expected in Logs */
    /* However, we can still test the execution thru spy; check copyToClipboard test case */
    return document.execCommand('copy'); // Security exception may be thrown by some browsers.
  } catch (ex) {
    Logger.info('Copy to clipboard failed.', ex);
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
};

export const sortKeyrings = (a, b) => {
  if (a.symbol < b.symbol) {
    return -1;
  }
  if (a.symbol > b.symbol) {
    return 1;
  }
  return 0;
};

export const round = (num, digits) =>
  parseFloat(String(Math.round(num * Math.pow(10, digits)) / Math.pow(10, digits))).toFixed(digits);

export function handleCopy(message, platform, clipboard, alertController, translate, clipboardService, popupMessage) {
  copy(message, platform, clipboard, alertController, translate, popupMessage);

  if (isSafari()) {
    clipboardService.copy(message);
  }

  function isSafari() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') !== -1) {
      // Chrome
      return false;
    } else {
      // Safari
      return true;
    }
  }

  // tslint:disable-next-line: no-shadowed-variable
  async function copy(message, platform, clipboard, alertController, translate, popupMessage) {
    const alert = await alertController.create({
      cssClass: 'light-font-alert',
      header: await translate.get('asset_page.copied').toPromise(),
      message: popupMessage ? popupMessage : message,
    });

    if (platform.is('cordova')) {
      await clipboard.copy(message);
      await alert.present();
    } else {
      copyToClipboard(message);
      await alert.present();
    }
  }
}

export const matchAfterDecimal = (num, maxNumberAllowed) => {
  return String(num).match(new RegExp('\\.+\\d{' + (1 + maxNumberAllowed) + '}', 'g'));
};

export const matchBeforeDecimal = (num, maxNumberAllowed) => {
  return String(num).match(new RegExp('\\d{' + (1 + maxNumberAllowed) + '}', 'g'));
};

export const matchValidDecimal = (input) => {
  // To check the validity of input amount
  return String(input).match(new RegExp('^([0-9]*)[.]?([0-9]*)$', 'g'));
};

export const showAlertMessageScrollbar = (message) => {
  return `<div class="alert-scroll">${message}</div>`;
};

export const weiToNum = (ethNum) => {
  const ethNumString = ethNum.toString();
  const dotIndex = ethNumString.indexOf('.');
  const integerPart = ethNumString.slice(0, dotIndex === -1 ? ethNumString.length : dotIndex);
  return Number(ethers.utils.formatEther(ethers.BigNumber.from(integerPart)));
};

export const nAvaxToAvax = (nAvax) => {
  return setNumberToPrecision(nAvax, 9);
};

export const gweiToEth = (gWei) => {
  return setNumberToPrecision(gWei, 9);
};

export const lamportsToNum = (solNum) => {
  return Number(solNum / solanaWeb3.LAMPORTS_PER_SOL);
};

export const getTokenBalance = (keyring: TokenKeyring) => keyring.balance;

export const satoshiToNum = (satNum) => {
  const num = new BigNumber(Number(satNum)).dividedBy(new BigNumber(100000000)).toNumber();
  return num;
};

export const numToSatoshi = (btcAmount) => {
  const num = new BigNumber(Number(btcAmount)).multipliedBy(new BigNumber(100000000)).toNumber();
  return num;
};

export const setNumberToPrecision = (num: number | string, precision: number) => {
  return new BigNumber(Number(num)).dividedBy(new BigNumber(Math.pow(10, precision))).toNumber();
};

export const setBigNumberToPrecision = (value: BigNumber, precision: number) => {
  return value.dividedBy(new BigNumber(Math.pow(10, precision)));
};

export const lookupTokenKeyring = (keyringId, tokenKeychain) => {
  return tokenKeychain.find((keyring) => keyring.keyringId === keyringId);
};

export const hashCode = (s) => {
  return s.split('').reduce((a, b) => {
    // tslint:disable-next-line: no-bitwise
    a = (a << 5) - a + b.charCodeAt(0);
    // tslint:disable-next-line: no-bitwise
    return a & a;
  }, 0);
};

export const formatMonth = (s: string) => {
  return s.length === 1 ? '0' + s : s;
};

/**
 * Parses a ethereum blockbook explorer raw error object into a translation key string and a params object if the key uses params
 * Corresponding error messages https://github.com/ethereum/go-ethereum/blob/master/core/tx_pool.go
 * @param errStr raw error string from server, should be a JSON object
 * @param ethBalacne balance of Ethereum
 */
export const parseEthereumError = (errObj, ethBalance: number): { key: string; params?: any } => {
  try {
    if (errObj.error && !errObj.error.error) {
      let errStr = '';
      if(isValidJSON(errObj.error)) {
        errStr = JSON.parse(errObj.error);
      } else {
        errStr = errObj.error?.message;
      }
      Logger.info('parseEthereumError', errStr);
      errStr = errStr.toString();
      if (errStr.indexOf('already known') > -1) {
        return {
          key: 'tokens.send.errors.eth_already_known',
        };
      }
      if (errStr.indexOf('invalid sender') > -1) {
        return {
          key: 'tokens.send.errors.eth_invalid_sender',
        };
      }
      if (errStr.indexOf('nonce too low') > -1) {
        return {
          key: 'tokens.send.errors.eth_nonce_too_low',
        };
      }
      if (errStr.indexOf('transaction underpriced') > -1) {
        return {
          key: 'tokens.send.errors.eth_transaction_underpriced',
        };
      }
      if (errStr.indexOf('replacement transaction underpriced') > -1) {
        return {
          key: 'tokens.send.errors.eth_replacement_tx_underpriced',
        };
      }
      if (errStr.indexOf('insufficient funds for gas * price + value') > -1) {
        return {
          key: 'tokens.send.errors.eth_insufficient_funds',
        };
      }
      if (errStr.indexOf('intrinsic gas too low') > -1) {
        return {
          key: 'tokens.send.errors.eth_gas_too_low',
        };
      }
      if (errStr.indexOf('exceeds block gas limit') > -1) {
        return {
          key: 'tokens.send.errors.eth_exceeds_block_gas_limit',
        };
      }
      if (errStr.indexOf('negative value') > -1) {
        return {
          key: 'tokens.send.errors.eth_negative_value',
        };
      }
      if (errStr.indexOf('oversized data') > -1) {
        return {
          key: 'tokens.send.errors.eth_oversized_data',
        };
      }
      Logger.error('parseSyscoinError', inspect(errStr));
      return {
        key: 'tokens.send.errors.system_error',
        params: {
          errMsg: errStr.toString()
        }
      }
    }
    return {
      key: 'tokens.send.errors.system_error',
      params: {
        errMsg: errObj?.message?.toString().substr(0,50)
      }
    }
  } catch (err) {
    Logger.error('parseEthereumError', inspect(err));
      return {
        key: 'tokens.send.errors.system_error',
        params: {
          errMsg: err?.message?.toString().substr(0,50)
        }
      }
  }
};

/**
 * Checks if an address is valid in the blockchain protocol specified
 * @param address address of either Syscoin or Ethereum blockchain
 * @param baseTokenSymbol base token symbol indicating the blockchain for address validity check (SYS or ETH)
 * @param tokenGuid token guid of SPT; 0 if not an SPT
 * @returns null for no error and key value pair with key being the error value type and value being true if error
 */
export const validateAddressByBlockchain = (address, baseTokenSymbol, tokenGuid = '0') => {
  switch (baseTokenSymbol) {
    case 'SYS':
      try {
        bitcoin.address.toOutputScript(address, networks.testnet);
      } catch (e) {
        return { invalid_address: true };
      }
      if (tokenGuid !== '0' && address.indexOf('sys') !== 0) {
        return { no_legacy_address: true };
      }
      break;
    case 'ETH':
      try {
        ethers.utils.getAddress(address);
      } catch (e) {
        return { invalid_address: true };
      }
      break;
    default: {
      return { address_required: true };
    }
  }
  return null;
};

// export const restrict = () => {
//   const e = event || window.event;  // get event object
//   const key = (e as any).keyCode || (e as any).which; // get key cross-browser
//   const validKeyCodes = [8, 37, 38, 39, 40, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 190];
//   if (!validKeyCodes.includes(key)) { // if it is not a number ascii code
//     if (e.preventDefault) e.preventDefault(); // normal browsers
//     e.returnValue = false; // IE
//   } else {
//     // if the keycode is valid but its a '.', only allow 1!
//     if ( amount.toString().indexOf('.') !== -1 && key === 190) {
//       if (e.preventDefault) e.preventDefault(); // normal browsers
//       e.returnValue = false; // IE
//     }
//   }
// };

export const getVirtualCardsAndBalances = async (
  membersPortalService: MembersPortalService,
  virtualCardService: VirtualDebitCardService
) => {
  try {
    let cards: any = await membersPortalService.getVirtualCards();
    cards = await Promise.all(
      cards.map(async (card) => ({
        balance: await virtualCardService.checkCardBalance(card.card_number),
        ...card,
      }))
    );
    cards = cards.filter((item) => item.balance !== null);

    return cards;
  } catch (err) {
    return [];
  }
};

export const getBankAccountsAndBalances = async (bankAccountsService: BankAccountService) => {
  try {
    const isCustodialAccount = await bankAccountsService.getACHStatus();
    if (isCustodialAccount) {
      const list = await bankAccountsService.getBankAccounts();
      let balance = await bankAccountsService.getBankAccountsBalance();
      balance = balance.settled;
      bankAccountsService.setServiceStatusInStore('bankAccount', true);

      return { list, balance };
    } else {
      // Throw exception to execute exception block
      throw new Error('User does not have custodial account');
    }
  } catch {
    bankAccountsService.setServiceStatusInStore('bankAccount', false);
    return { list: [], balance: 0 };
  }
};

export const getPTBalances = async (bankAccountsService: BankAccountService) => {
  try {
    const [agxBalance, auxBalance, lodeBalance] = await Promise.all([
      bankAccountsService.getAgxBalance(),
      bankAccountsService.getAuxBalance(),
      bankAccountsService.getLodeBalance(),
    ]);

    agxBalance.balance = new BigNumber(agxBalance.balance).multipliedBy(100000000).toNumber();
    auxBalance.balance = new BigNumber(auxBalance.balance).multipliedBy(100000000).toNumber();
    lodeBalance.balance = new BigNumber(lodeBalance.balance).multipliedBy(100000000).toNumber();

    return {
      agxBalance,
      auxBalance,
      lodeBalance,
    };
  } catch {
    return {
      agxBalance: 0,
      auxBalance: 0,
      lodeBalance: 0,
    };
  }
};

export const getCoinNameBySymbol = (symbol: BaseChainSymbol) => {
  switch (symbol.toLowerCase()) {
    case 'sys':
      return 'syscoin';
    case 'eth':
      return 'ethereum';
    case 'avax':
      return 'avalanche';
    case 'btc':
      return 'bitcoin';
    default:
      return 'syscoin';
  }
};

export const getTokenFiatValue = (fiatRate, balance) => {
  return new BigNumber(fiatRate).multipliedBy(new BigNumber(balance)).toFixed(6);
};

export const getCurrentNetworkLabel = async ({
  tokenInfo,
  networkManager,
  translateService,
}: {
  tokenInfo: TokenInfo;
  networkManager: NetworkService;
  translateService: any;
}) => {
  let blockchain = '';
  let network = '';

  try {

    if (tokenInfo.baseChainSymbol === 'HL') {
      blockchain = 'hyperledger';
    } else if (tokenInfo.baseChainSymbol === 'PT') {
      blockchain = 'Prime Trust';
    } else if (['SYS', 'ETH', 'BTC', 'AVAX', 'SOL'].includes(tokenInfo.baseChainSymbol)) {
      blockchain = tokenInfo.symbol === tokenInfo.baseChainSymbol ? '' : getCoinNameBySymbol(tokenInfo.baseChainSymbol);
      const activeNetwork = await networkManager.getActiveNetwork(tokenInfo.baseChainSymbol);
      if (activeNetwork.network !== 'mainnet') {
        network = activeNetwork.shortName || activeNetwork.name;
      }
    }
    const finalLabel = await translateService.get('assets.on_network', { blockchain, network }).toPromise();

    return blockchain || network ? finalLabel : '';
  } catch (e) {
    Logger.error(`getCurrentNetworkLabel ` + inspect(e));
    return '';
  }



};

export const getCurrentNetworkIcon = async (symbol, baseChainSymbol) => {
  let blockchain = '';

  if (baseChainSymbol === 'HL') {
    blockchain = '../../assets/images/fabric.png';
  } else if (baseChainSymbol === 'SYS') {
    blockchain = '../../assets/images/syscoin_utxo.png';
  }else if (baseChainSymbol === 'SYSNEVM') {
    blockchain = '../../assets/images/syscoin.png';
  } else if (baseChainSymbol === 'ETH') {
    blockchain = '../../assets/images/coins/ethereum.png';
  } else if (baseChainSymbol === 'PT') {
    blockchain = '../../assets/images/ETH.svg';
  } else if (baseChainSymbol === 'BTC') {
    blockchain = '../../assets/images/btc.png';
  } else if (baseChainSymbol === 'AVAX') {
    blockchain = '../../assets/images/coins/avax_token.png';
  } else if (baseChainSymbol === 'SOL') {
    blockchain = '../../assets/images/solana-logo.svg';
  } else {
    blockchain = '../../assets/images/unknown_coin.png';
  }

  return blockchain;
};

export const mapUrlToLabel = (path) => {
  switch (path) {
    case '/wallet/assets':
      return 'pages.assets';
    case '/dev/component-sheet':
      return 'Component Sheet';
    case '/wallet/assets/SYS/341906151':
    case '/wallet/assets/SYS/367794646':
      return 'tokens.titles.agx';
    case '/wallet/assets/SYS/439183631':
    case '/wallet/assets/SYS/1358717298':
      return 'tokens.titles.aux';
    case '/wallet/assets/ETH':
      return 'tokens.titles.eth';
    case '/wallet/assets/SYS':
      return 'tokens.titles.sys';
    case '/wallet/assets/BTC':
      return 'tokens.titles.btc';
    case '/wallet/assets/ETH/0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b':
    case '/wallet/assets/ETH/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48':
      return 'tokens.titles.usdc';
    case '/wallet/transaction-detail':
      return 'txn_detail.title';
    case '/wallet/card':
      return 'virtual_debit_card.title';
    case '/wallet/settings':
      return 'pages.settings';
    case '/gas-station':
      return 'pages.gas_station';
    case '/lode-acc':
      return 'lode_acc.title';
    default:
      return '';
  }
};

export const openKYC = async (
  loading: boolean,
  complianceService: ComplianceService,
  translate: TranslateService,
  toastController: ToastController,
  modalController: ModalController,
  platform: Platform,
  cssClass?: string,
  flowName?: string
) => {
  loading = true;

  try {
    const res = flowName ? await complianceService.getKYCToken(flowName) : await complianceService.getKYCToken();
    loading = false;
    Logger.info('kyc resulttttt ', res);
    await startKYC(res, modalController, cssClass);
  } catch (err) {
    Logger.info(err);
    loading = false;
    presentToast('settings.toast_msgs.err_hdr', 'settings.toast_msgs.kyc_err_msg', translate, toastController, platform);
  }
};

export const startKYC = async (configs, modalController: ModalController, cssClass?: string) => {
  const modal = await modalController.create({
    component: KycModalComponent,
    cssClass,
    componentProps: {
      urlAPI: configs.environment,
      token: configs.token,
      flowName: configs.flowName,
    },
    backdropDismiss: false,
    id: 'kyc-modal',
  });
  await modal.present();
};

export const simplexCheckoutModal = async (modalController: ModalController, url:string) => {
  const modal = await modalController.create({
    component: SimplexModalCheckoutComponent,
    componentProps: {
      url: url,
    },
    backdropDismiss: false,
    id: 'simplex-modal',
  });
  await modal.present();
};

export const presentToast = async (
  hdr: string,
  msg: string,
  translate: TranslateService,
  toastController: ToastController,
  platform: Platform,
  direct: boolean = false
) => {
  const toast = await toastController.create({
    header: direct ? hdr : await translate.get(hdr).toPromise(),
    message: direct ? msg : await translate.get(msg).toPromise(),
    position: getToastPosition(platform),
    duration: 4000,
  });
  await toast.present();
};

export const getToastPosition = (platform: Platform): 'top' | 'bottom' => {
  return platform.is('desktop') ? 'top' : 'bottom';
};

export const createRemoveAccountAlert = async (
  alertController: AlertController,
  translate: TranslateService,
  handler: any,
  headerMsg: string,
  message: string,
  cancelBtnText: string,
  acceptBtnText: string
) => {
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: await translate.get(headerMsg).toPromise(),
    message: await translate.get(message).toPromise(),
    buttons: [
      {
        text: await translate.get(cancelBtnText).toPromise(),
        role: 'cancel',
        cssClass: 'secondary',
      },
      {
        text: await translate.get(acceptBtnText).toPromise(),
        handler,
      },
    ],
  });

  await alert.present();
};

export const confirmResetWalletAlert = async (
  alertController: AlertController,
  translate: TranslateService,
  handler: any,
  resetWithPin: boolean = false
) => {
  const alert = await alertController.create({
    header: await translate.get('settings_reset_wallet.alert_title_reset_warning').toPromise(),
    message:
      resetWithPin === true
        ? await translate.get('settings_reset_wallet.text_reset_warning').toPromise()
        : await translate.get('settings_reset_wallet.text_reset_wallet_forget_pin').toPromise(),
    buttons: [
      {
        text: await translate.get('settings_reset_wallet.btn_cancel').toPromise(),
        role: 'cancel',
        cssClass: 'secondary',
      },
      {
        text: await translate.get('settings_reset_wallet.btn_confirm_reset').toPromise(),
        role: 'handler',
      },
    ],
  });
  await alert.present();
  
  const { role } = await alert.onDidDismiss();
  if (role === 'handler') {
    await handler(); 
  }
};

export const confirmstartKycAlert = async (
  alertController: AlertController,
  translate: TranslateService,
  handler: any,
  resetWithPin: boolean = false
) => {
  const alert = await alertController.create({
    header: await translate.get('bank_account.ach_kyc_needed_hdr').toPromise(),
    message: await translate.get('bank_account.ach_kyc_needed_msg').toPromise(),
    buttons: [
      {
        text: await translate.get('btn_cancel').toPromise(),
        role: 'cancel',
        cssClass: 'secondary',
      },
      {
        text: await translate.get('settings.btn_confirm').toPromise(),
        handler,
      },
    ],
  });
  await alert.present();
};

export const createDisable2FAAlert = async (alertController: AlertController, translate: TranslateService, handler: any) => {
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: await translate.get('settings.twofa_devices.alert_title').toPromise(),
    message: await translate.get('settings.twofa_devices.alert_description').toPromise(),
    inputs: [
      { name: 'password', placeholder: 'Password', type: 'password' },
      { name: 'twofacode', placeholder: '2FA Code' },
    ],
    buttons: [
      {
        text: await translate.get('settings.btn_cancel').toPromise(),
        role: 'cancel',
        cssClass: 'secondary',
      },
      {
        text: await translate.get('settings.btn_confirm').toPromise(),
        handler,
      },
    ],
  });
  await alert.present();
};

export const presentAlert = async (
  alertController: AlertController,
  translate: TranslateService,
  handler: any,
  title: string,
  message: string,
  btnText: string,
  paramHeader?: any
) => {
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: paramHeader ? await translate.get(title, paramHeader).toPromise() : await translate.get(title).toPromise(),
    message: paramHeader ? await translate.get(message, paramHeader).toPromise() : await translate.get(message).toPromise(),
    buttons: [
      {
        text: await translate.get(btnText).toPromise(),
        handler,
      },
    ],
  });

  await alert.present();
};

export const presentDltAlert = async (
  alertController: AlertController,
  handler: any,
  title: string,
  message: string,
  btnText: string
) => {
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: title,
    message,
    buttons: [
      {
        text: btnText,
        handler,
      },
    ],
    backdropDismiss: false,
  });

  await alert.present();
};

export const accountCreatedAlert = async (alertController: AlertController, translate: TranslateService, handler: any) => {
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: await translate.get('lode_acc.account_created_title').toPromise(),
    message: await translate.get('lode_acc.account_created_description').toPromise(),
    buttons: [
      {
        text: await translate.get('lode_acc.remove_account.alert_accept').toPromise(),
        handler,
      },
    ],
  });

  await alert.present();
};

export const accountUpdatedAlert = async (
  alertController: AlertController,
  translate: TranslateService,
  handler: any,
  emailUpdated: boolean
) => {
  const description = emailUpdated ? 'account_email_updated_description' : 'account_updated_description';
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: await translate.get('lode_acc.account_updated_title').toPromise(),
    message: await translate.get(`lode_acc.${description}`).toPromise(),
    buttons: [
      {
        text: await translate.get('lode_acc.remove_account.alert_accept').toPromise(),
        handler,
      },
    ],
  });

  await alert.present();
};

export const isEnterKey = (ev) => {
  if (ev.keyCode === 13) {
    return true;
  }

  return false;
};

export const getCountryCode = (phoneNum) => {
  const res: any = {};
  const phoneNumber = parsePhoneNumber(phoneNum.e164Number);

  res.phone = phoneNumber.formatNational();
  res.countrycode = phoneNumber.country;

  if (!res.phone || !res.countrycode) {
    throw { error: { errors: { phone: true } } };
  }

  return res;
};

export const gramsToOz = (grams: string | number) => {
  return new BigNumber(grams).multipliedBy(0.035274).toNumber();
};

export const ozToGrams = (oz: string | number) => {
  return new BigNumber(oz).dividedBy(0.035274).toNumber();
};

export const ozToGramsNew = (oz: string | number) => {
  return new BigNumber(oz).multipliedBy(31.1).toNumber();
};
interface ConversionRatesType {
  GOLD: number;
  TROY_OUNCES: number;
}

export const ConversionRates: ConversionRatesType = {
  GOLD: 75,
  TROY_OUNCES: 31.1034768,
};

export const calculateLode = (oz: number, qty: number, type: string, lodeRateInGrams: number, gold_silver_ratio?: number) => {
  return ((oz * ConversionRates.TROY_OUNCES) / lodeRateInGrams) * qty * (type === 'gold' ? gold_silver_ratio : 1);
};

export const getExchangeRate = (asset: TokenType, spotPrice: number, lodeRate?: number): number => {
  if (asset === TokenType.LODE) {
    return spotPrice * lodeRate;
  } else {
    return spotPrice;
  }
};

// MW-849 - specific sort is required - by keyringId - Start
export const sortByObject = (data) =>
  data.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});

export const customSortAssets = ({ data, sortBy, sortField }) => {
  const customSortByObject = sortBy.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index,
    };
  }, {});
  return data.sort((a, b) => customSortByObject[a[sortField]] - customSortByObject[b[sortField]]);
};
// MW-849 - specific sort is required - by keyringId - End

export const mergeSptAllocationsAndVouts = (allocations: Array<any>, vouts: Array<any>): Array<any> => {
  const allocationInfoArr = JSON.parse(JSON.stringify(allocations)); // perform a deep copy, don't want to modify original object.

  allocationInfoArr.forEach((alloc) => {
    alloc.outputs.forEach((out) => {
      out.vout = vouts[out.n];
    });
  });

  return allocationInfoArr;
};

export const convertAllocsToSatoshi = (allocs) => {
  const allocsClone = JSON.parse(JSON.stringify(allocs));
  allocsClone.forEach((alloc) => {
    alloc.total = new BigNumber(alloc.total).multipliedBy(100000000).toNumber();
    alloc.outputs.forEach((output) => {
      output.amount = new BigNumber(output.amount).multipliedBy(100000000).toNumber();
    });
  });
  return allocsClone;
};

export const compareAppVersion = (latestVersion, currentVersion) => {
  return semver.gt(semver.coerce(latestVersion), semver.coerce(currentVersion));
};

export const createBankAccountServiceUnavailableAlert = async (alertController: AlertController, translate: TranslateService) => {
  const alert = await alertController.create({
    cssClass: 'light-font-alert',
    header: await translate.get('bank_account.service_unavailable').toPromise(),
    message: await translate.get('bank_account.try_again_later').toPromise(),
    buttons: [
      {
        text: await translate.get('bank_account.close').toPromise(),
        role: 'cancel',
        cssClass: 'secondary',
      },
    ],
  });

  await alert.present();
};

export const removeAuxFeeAddressFromArray = (address, auxFeePossibleAddresses) =>
  address.filter((addr) => !auxFeePossibleAddresses.includes(addr));

export const removeAllocationsFromAddress = (allocs, addresses = []) => {
  return allocs.filter((alloc) => addresses.indexOf(alloc.address) === -1);
};

export const getSendTypeValueFromtxAmount = (txAmount, walletAddress, isBaseChain, tokenGuid?) => {
  const addresses = Object.keys(txAmount.amounts);

  return addresses
    .filter((addr) => addr !== walletAddress)
    .map((addr) => {
      if (isBaseChain) {
        return txAmount.amounts[addr]?.value;
      }

      return txAmount.amounts[addr]?.tokenValue[tokenGuid];
    })
    .reduce((prev, next) => Number(prev || 0) + Number(next || 0));
};

export const getReceiveTypeValueFromtxAmount = (txAmount, walletAddress, isBaseChain, tokenGuid?) => {
  if (isBaseChain) {
    return txAmount.amounts[walletAddress].value;
  }

  return txAmount.amounts[walletAddress]?.tokenValue[tokenGuid] || 0;
};

export const successTxsModal = async (
  isDesktopMode: boolean,
  sideMenuHidden: boolean,
  modalController: ModalController,
  disMissModal?: string
) => {
  if (disMissModal) {
    modalController.dismiss({}, '', disMissModal);
  }
  let cssClass = '';
  cssClass += isDesktopMode ? 'success-modal-desktop' : 'success-modal';
  cssClass += !sideMenuHidden && isDesktopMode ? ' with-side-menu' : '';
  const modal = await modalController.create({
    component: SuccessModalComponent,
    cssClass,
    initialBreakpoint: isDesktopMode ? undefined : 0.4,
    breakpoints: isDesktopMode ? undefined : [0, 0.4, 0.6, 1],
    id: 'successModal',
  });
  await modal.present();
};
export const openKycModal = async (
  sideMenuHidden: boolean,
  isDesktopMode: boolean,
  modalController: ModalController,
  type?: string
) => {
  let cssClass = 'kyc-confirm-modal';
  cssClass += !sideMenuHidden && isDesktopMode ? ' with-side-menu' : '';

  const modal = await modalController.create({
    component: KycConfirmModalComponent,
    cssClass,
    backdropDismiss: false,
    componentProps: {
      modalCntrl: modalController,
      walletType: type,
    },
  });
  modal.onDidDismiss().then();
  await modal.present();
};

export const removeSpecialChars = (str) => {
  if (str) {
    str = str.toString();
    return str.replace(/[&\/\\#,+()$£¥€~%'":*?<>{}]/g, '');
  }
};
export const setSwappingPairs = (swappingPair) => {
  Logger.info('setSwappingPairs ', swappingPair);
  let routeSwapPair1;
  let routeSwapPair2;
  switch (swappingPair) {
    case 'AGX_AUX':
      routeSwapPair1 = 'AGX_USDC';
      routeSwapPair2 = 'USDC_AUX';
      break;
    case 'AUX_AGX':
      routeSwapPair1 = 'AUX_USDC';
      routeSwapPair2 = 'USDC_AGX';
      break;
    case 'SOL_AGX':
      routeSwapPair1 = 'SOL_USDC';
      routeSwapPair2 = 'USDC_AGX';
      break;
    case 'SOL_AUX':
      routeSwapPair1 = 'SOL_USDC';
      routeSwapPair2 = 'USDC_AUX';
      break;
    default:
      break;
  }
  return { routeSwapPair1, routeSwapPair2 };
};
export const setUnit = (swappingPair: string) => {
  let splitted;
  splitted = swappingPair.split('_', 2);
  return splitted[0];
};

export const numberFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1)) + 'k'
    : Math.sign(num) * Number(Math.abs(num).toFixed(2));
};

// take 8 decimals and remove trailing zeros
export const formatTokenAmount = (num: string | number, decimals = 8) => {
  if (Number(num) > 0) {
    return Number(num)
      .toFixed(decimals)
      .replace(/(\.0+|0+)$/, '');
  }
  return '0';
};

export const setCurrencyCode = (currencyCode: string) => {
  return CURRENCY_SYMBOL[currencyCode] ? CURRENCY_SYMBOL[currencyCode] : '$';
};
export const nFormatter = (num: number, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const openSetPrimaryAddressModal = async (
  sideMenuHidden: boolean,
  isDesktopMode: boolean,
  modalController: ModalController,
  isSuccessRes: boolean,
  address?: any
) => {
  let cssClass = '';
  cssClass += isDesktopMode ? 'success-or-failure-modal' : 'primary-address-success-modal';
  cssClass += !sideMenuHidden && isDesktopMode ? ' with-side-menu' : '';
  const modal = await modalController.create({
    component: PrimaryAddressSuccessFailComponent,
    componentProps: {
      isSuccess: isSuccessRes,
      selectedAddress: address,
    },
    cssClass,
    initialBreakpoint: isDesktopMode ? undefined : 0.6,
    breakpoints: isDesktopMode ? undefined : [0, 0.6, 1],
    id: 'primaryAddressSuccessFailModal',
  });
  await modal.present();
};
export const getObjectValueByKey = (object: Object, key: string) => {
  const mapKeyValue = new Map(Object.entries(object));
  return mapKeyValue.has(key) ? mapKeyValue.get(key) : null;
};

export const sortAlphabetically = (arr) => {
    return arr.sort((a, b) => {
      try {
      return a.symbol.charCodeAt(0) - b.symbol.charCodeAt(0);   
      } catch(e) {
        return Logger.error(`sortAlphabetically on ${JSON.stringify(a)}`, e);
      }
    });
};
export const sortAssetList = (tokenList: SelectTokenInfo[]) => {
  const tempArray = [];
  let finalTokenArray;
  tokenList.sort((a, b) => b.aggregateTotalFiat - a.aggregateTotalFiat);
  for (let i = tokenList.length - 1; i >= 0; i--) {
    if (['AUX', 'AGX', 'LODE'].includes(tokenList[i].symbol) && Number(tokenList[i].aggregateTotalFiat.toFixed(2)) > 0) {
      tempArray.unshift(tokenList[i]);
      tokenList.splice(i, 1);
    }
  }

  tokenList.unshift(...tempArray);
  const index = tokenList.findIndex((val) => Number(val.aggregateTotalFiat.toFixed(2)) == 0.0);
  if (index < tokenList.length) {
    const zeroTokens = tokenList.slice(index);
    // var sortedZeroTokens;
    let sortedZeroTokens = [];
    let basicToken = [];
    let restTokens = [];
    for (const item of zeroTokens) {
      if (['AUX', 'AGX', 'LODE'].includes(item.symbol)) {
        basicToken.push(item);
      } else {
        restTokens.push(item);
      }
    }
    basicToken = sortAlphabetically(basicToken);
    restTokens = sortAlphabetically(restTokens);

    sortedZeroTokens = basicToken.concat(restTokens);
    finalTokenArray = tokenList.slice(0, index).concat(sortedZeroTokens);
  }
  return finalTokenArray.filter(item => item.symbol != 'LTC');
};

export async function executeAllPromises(promises: Promise<any>[]): Promise<{ result?: any; error?: any }[]> {
  const results = [];

  for (const promise of promises) {
    try {
      const result = await promise;
      results.push({ result });
    } catch (error) {
      results.push({ error });
      Logger.error('error in executeAllPromises', error);
    }
  }

  return results;
}

export const getFormattedFiatAmountForDisplay = (currencyCode: string, amount: number) => {
  const symbol = setCurrencyCode(currencyCode);
  return symbol + amount;
};
export const shortHandAddress = (address: string, front = 6, back = -6) => {
  return address.slice(0, front) + '.......' + address.slice(back);
};

export async function safePromiseAll(promises: Promise<any>[]): Promise<{ result?: any; error?: any }[]> {
  const results = [];

  for (const promise of promises) {
    try {
      const result = await promise;
      results.push({ result });
    } catch (error) {
      results.push({ error });
      Logger.error('error in executeAllPromises', error);
    }
  }

  return results;
}

export async function allSettled(promises: Promise<any>[]): Promise<{ status: string; value?: any; reason?: any }[]> {
  const results = [];

  for (const promise of promises) {
    try {
      const result = await promise;
      results.push({ status: 'fulfilled', value: result });
    } catch (error) {
      results.push({ status: 'rejected', reason: error });
      Logger.error('error in executeAllPromises', error);
    }
  }

  return results;
}

export function filterByTokenSymbol(objectArray, property) {
  return objectArray.reduce((acc, current) => {
    const x = acc.find((item) => item[property] === current[property]);
    if (!x && current.baseChainSymbol !== "PT" && current.symbol !== "USDC.e" && current.symbol !== "VOT3") {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
}

export const emailPattern = /^(([^<>()[\]\\.,;:+\s@"]+(\.[^<>()[\]\\.,;:+\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isPlatformBrowser = (platform: Platform) => {
  Logger.info('platform desktop', platform.is('desktop'), 'platform mobileweb', platform.is('mobileweb'), 'platform pwa', platform.is('pwa'), 'platform tablet', platform.is('tablet'));
  return (platform.is('desktop') || platform.is('mobileweb') || platform.is('pwa') || platform.is('tablet'));
}

export const getGasFee = (gasPrice: number, gasFee: number) => {
  return EthersBigNumber.from(gasFee).mul(EthersBigNumber.from(gasPrice)).toString();
}


/**
 * Gets data from various signTypedData request methods by filtering out
 * a value that is not an address (thus is data).
 * If data is a string convert it to object
 */
export function getSignTypedDataParamsData(params: string[]) {
  const data = params.filter(p => !utils.isAddress(p))[0]

  if (typeof data === 'string') {
    return JSON.parse(data)
  }

  return data
}


export const isAGXKeyringId = (keyringId: string) => {
  const agxKeyingList = [
    AGX_KEYRING_ID,
    'HL-AGX',
    'PT-AGX',
    'AGX',
    'PT-AVAX-0x19A3f4503e8708b50F284ABB0490D42A36ad577C',
    'PT-AVAX-0x13E7bceFddE72492E656f3fa58baE6029708e673',
    'AVAX-0x905F3f24404dF6042E1dF6A52CC6bB41EE6BE432',
    'AVAX-0x13E7bceFddE72492E656f3fa58baE6029708e673',
    'SOL-Czh5EHJXZzHMiUeQwY1ZcJUpaf1yBpetdKswMx6NVAhT',
    'SOL-CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v',
    'ETH-0x5fE6A182974e158885c112126302b7575Bf0436e',
    'ETH-0x32e1b69C961DBA85e6AdFa933b5C52C743A8f390',
    'SYSNEVM-0x3a656c0F5c157A4710311694bBC8bb51A468446B',
    'SYSNEVM-0x6786bad0E883E4ca1fBa3e549eb36633146c6C09'
  ]
  return agxKeyingList.includes(keyringId);
}

export const isAUXKeyringId = (keyringId: string) => {
  const auxKeyringList = [
    AUX_KEYRING_ID,
    'PT-AUX',
    'AUX',
    'PT-AVAX-0xd686C6D4e47f8F059c535cCe29FDAe12d418ade7',
    'PT-AVAX-0x68327a91E79f87F501bC8522fc333FB7A72393cb',
    'AVAX-0x287a2f1772C64a245ab3fa3Ee1227a25e3961F45',
    'AVAX-0x68327a91E79f87F501bC8522fc333FB7A72393cb',
    'SOL-3QjcGDn3nJbnt1KZvC3RRNCN3v6gQ14aykk8eCzoLc6X',
    'SOL-Dphg7WWPYPKMtVyxBJpjwP2sG8HBkG4mm89kX1jgKA2L',
    'ETH-0xa6234ed33703244495B2739C2BbBA666456dbc80',
    'ETH-0x2cA886799aa5B15343849370D99187Ae729dF0B3',
    'SYSNEVM-0xC53Fb4f4838D0D341d6F64634aa6C5f45b5d7bC7',
    'SYSNEVM-0x39bD164003Ea4F7Dea8744C7cc384dA06C16fef5'
  ]
  return auxKeyringList.includes(keyringId);
}

export const isLodeKeyringId = (keyringId: string) => {
  const lodeKeyringList = [
    'HL-LODE',
    'PT-LODE',
    'LODE',
    'PT-AVAX-0xbBAAA0420D474B34Be197f95A323C2fF3829E811',
    'PT-AVAX-0xe212578C4dD11191DD8aA02F3BCc4dD551ea3D37',
    'AVAX-0x9792407864A79A11A651842bBe483228519fA9B8',
    'AVAX-0xbBAAA0420D474B34Be197f95A323C2fF3829E811',
    'SOL-BKzgKX1xskUMj99rR24z2h775dgF9Eju7H9YmSsFrkDn',
    'SOL-H84W8smQNsiHkwPJHvGDyecSRCCXTWew3stfMF1KGpYR',
    'AVAX-0xe0285fc6a60daa223219fb01d78b3baee133017c',
    'AVAX-0x078A20183B06E3DDa64B96bB0a2c78231cdF3e41',
    'AVAX-0x46856274bc823D9B2aaA47D1ecDD35d0D29b485e',
    'ETH-0x3a656c0F5c157A4710311694bBC8bb51A468446B',
    'ETH-0x2F120935177552E63Fa0d1Ed46549aa6F1353E57'
  ]
  return lodeKeyringList.includes(keyringId);
}

export const isLodeCompanyToken = (keyringId: string) => {
  return isLodeKeyringId(keyringId) || isAGXKeyringId(keyringId) || isAUXKeyringId(keyringId);
}
