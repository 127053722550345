import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
  NgxScannerQrcodeService,
  NgxScannerQrcodeComponent,
} from 'ngx-scanner-qrcode';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { inspect } from 'util';

@Component({
  selector: 'my-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss'],
})
export class ScannerComponent implements AfterViewInit, OnDestroy {

  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth
      },
    },
  };

  @ViewChild('action') action!: NgxScannerQrcodeComponent;
  selectedDeviceId: string;
  selectedDevice: any;
  enableScanner = true;
  availableDevices: any;

  constructor( private modalCtrl: ModalController) { }

  ngAfterViewInit(): void {
    this.action.isReady.subscribe((isReady: boolean) => {
       this.handle(this.action, 'start');
    });
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    Logger.info(`scan result ${e}`);
    if (e) {
      const result = e[0].value;
      Logger.info(`scan result ${result}`);
      this.modalCtrl.dismiss({
        scannedData: result
      });
      this.handle(this.action, 'stop');
    }
    
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
       // front camera or back camera check here!
       const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
       action.playDevice(device ? device.deviceId : devices[0].deviceId);
    }

    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe((r: any) => Logger.info(`start a camera for QR code scan ${fn} - ${inspect(r)}`), alert);
    } else {
      action[fn]().subscribe((r: any) => Logger.info(`shutdown a camera for QR code scan ${fn} - ${inspect(r)}`), alert);
    }
  }

  ngOnDestroy(): void {
    if(this?.action?.isStart) {
      this.handle(this.action, 'stop');
    }
  }
}
