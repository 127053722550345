import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

export class JsonRpcBody {
  id: number;
  method: string;
  jsonrpc: string;
  params: any;
}

@Injectable({ providedIn: 'root' })
export class RpcProvider {
  private rpcId: number = 1;

  constructor(private http: HttpClient) {}

  /** JSON RPC Request */
  private req(method: string, params?: any) {
    const body = new JsonRpcBody();
    body.id = this.rpcId;
    body.jsonrpc = '2.0';
    body.method = method;
    body.params = params;
    return body;
  }

  /** Send a request to the node */
  public rpc<T>(url: string, method: string, params?: any): Observable<{ result: any } | { error: any } | any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    const payload = this.req(method, params);
    this.rpcId++;
    return this.http.post(url, payload, httpOptions);
  }
}
