import { Action } from '@ngrx/store';

export enum GraphDataActionTypes {
  SET_HOME_GRAPH_DATA = '[appState] Set home graph data',
  SET_ASSET_GRAPH_DATA = '[appState] Set Assets graph data',
  RESET_GRAPH_DATA = '[appState] Reset graph data',
}

export class SetGraphHomeAction implements Action {
  readonly type = GraphDataActionTypes.SET_HOME_GRAPH_DATA;
  constructor(readonly payload: {
    homeGraphData: Array<any>,
  }) { }
}

export class SetAssetGraphAction implements Action {
  readonly type = GraphDataActionTypes.SET_ASSET_GRAPH_DATA;
  constructor(readonly payload: {
    assetGraphData: Object,
  }) { }
}

export class ResetGraphDataAction implements Action {
  readonly type = GraphDataActionTypes.RESET_GRAPH_DATA;
  constructor() { }
}