import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavController, NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SetSendTokenDataAction } from 'src/app/angular-wallet-base/actions/sendToken.actions';
import { AUTH_REASON, EST_SYS_ASSET_NET_FEE, EST_SYS_NET_FEE } from 'src/app/angular-wallet-base/constants';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { TokenManagerService } from 'src/app/angular-wallet-base/services/token-manager.service';
import { TransactionSenderService } from 'src/app/angular-wallet-base/services/transaction-sender.service';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { getBaseCurrency } from 'src/app/angular-wallet-base/store/userPreferences';
import { PinModalComponent } from 'src/app/components/pin-modal/pin-modal.component';
import { BankAccountService } from '../../../angular-wallet-base/services/bank-account.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmModalComponent implements OnInit {

  @ViewChild('transactionInfo') transactionInfo: ElementRef;
  sendModal: HTMLElement;

  public isDesktopMode;
  public tokenInfo;
  public sendTokenData;
  public currentBaseCurrency;
  public mode;

  constructor(
    private modal: ModalController,
    public appMode: AppModeService,
    public navParams: NavParams,
    private transactionSender: TransactionSenderService,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private translate: TranslateService,
    protected store: Store<AppState>,
    private currencyPipe: CurrencyPipe,
    private nav: NavController,
    private modalController: ModalController,
    private tokenService: TokenManagerService,
    private bankService: BankAccountService
  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  async ngOnInit() {
    this.tokenInfo = await this.navParams.get('tokenInfo');
    this.sendTokenData = await this.navParams.get('sendTokenData');
    this.currentBaseCurrency = await this.store.select(getBaseCurrency).pipe(take(1)).toPromise();
    this.mode = await this.navParams.get('mode');
    Logger.info(this.tokenInfo, this.sendTokenData);

    await this.onSendToken();
  }

  closeModal() {
    this.modal.dismiss();
  }

  async onSendToken() {
    let auxFee;

    const sjsKit = await this.transactionSender.getSignAndSendKit(this.tokenInfo.baseChainSymbol);
    const loader = await this.loadingController.create({
      duration: 60000
    });

    await loader.present();
    if (this.tokenInfo.baseChainSymbol === 'SYS' && this.tokenInfo.assetGuid) {
      // Checks if it's an AGX transaction and gets fee from utx. If fails, continue normally.
      auxFee = await this.transactionSender.calcAuxFees(this.tokenInfo.assetGuid, this.sendTokenData.amount);
    }
    await loader.dismiss();

    if (this?.mode === 'sendAch') {
      const coin = this.tokenInfo.symbol,
          coinName = this.tokenInfo.name,
          networkCoin = this.tokenInfo.baseChainSymbol !== this.tokenInfo.symbol ? this.tokenInfo.baseChainSymbol : null,
          sendAmountText = await this.translate.get(
            'tokens.send.transaction_details.send_amount', { coinName: coin ? coin : coinName }
          ).toPromise();
        const amountWithCurrency = this.getCurrency([
          (this.sendTokenData.amount * this.tokenInfo.fiatValue),
          this.currentBaseCurrency,
          '1.2-6'
        ]);
        const transactionInfoHtml = `
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${sendAmountText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.amount} ${coin}</p>
              <p class="p-amount p-lc-amount" *ngIf=${this.tokenInfo.fiatValue}>${amountWithCurrency} ${this.currentBaseCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">Send To</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.toAddress}</p>
            </ion-col>
          </ion-row>
        `;
        this.transactionInfo.nativeElement.outerHTML = transactionInfoHtml;
    } else {
      if (auxFee > -1) {
        const supportedAssetList = await this.tokenService.getSupportedTokensList();
        const gasSymbol = supportedAssetList[`SYS`].baseChainSymbol,
          sendAmountText = await this.translate.get('tokens.send.transaction_fees.send_amount').toPromise(),
          auxFeeText = await this.translate.get('tokens.send.transaction_fees.aux_fee').toPromise(),
          coin = this.tokenInfo.symbol,
          txFeeText = await this.translate.get('tokens.send.transaction_fees.gas').toPromise();
        const amountWithCurrency = this.getCurrency([
          (this.sendTokenData.amount * this.tokenInfo.fiatValue),
          this.currentBaseCurrency,
          '1.2-6'
        ]);
        const auxFeeWithCurrency = this.getCurrency([(auxFee * this.tokenInfo.fiatValue), this.currentBaseCurrency, '1.2-6']);
        const transactionInfoHtml = `
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${sendAmountText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.amount} ${coin}</p>
              <p class="p-amount p-lc-amount">${amountWithCurrency} ${this.currentBaseCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${auxFeeText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${auxFee} ${coin}</p>
              <p class="p-amount p-lc-amount">${auxFeeWithCurrency} ${this.currentBaseCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${txFeeText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">~ ${EST_SYS_ASSET_NET_FEE} ${gasSymbol}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">Send To</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.toTempAddress}</p>
            </ion-col>
          </ion-row>
        `;
        this.transactionInfo.nativeElement.outerHTML = transactionInfoHtml;
      }

      if (this.tokenInfo.baseChainSymbol === 'ETH' || this.tokenInfo.baseChainSymbol === 'AVAX' || this.tokenInfo.baseChainSymbol === 'SYSNEVM') {
        const formattedTxEth = Number(this.sendTokenData.ethTxn).toFixed(6),
          formattedTxFee = Number(this.sendTokenData.ethTxnFee).toFixed(6),
          coin = this.tokenInfo.symbol ==  'SYSNEVM' ? 'SYS-ROLLUX' : this.tokenInfo.symbol,
          coinName = this.tokenInfo.name,
          networkCoin = this.tokenInfo.baseChainSymbol !== this.tokenInfo.symbol ? this.tokenInfo.baseChainSymbol : null,
   
          sendAmountText = await this.translate.get(
            'tokens.send.transaction_details.send_amount', { coinName: coin ? coin : coinName }
          ).toPromise(),
          gasFeeText = await this.translate.get('tokens.send.transaction_details.gas_fee').toPromise();

        const amountWithCurrency = this.getCurrency([
          (this.sendTokenData.amount * this.tokenInfo.fiatValue),
          this.currentBaseCurrency,
          '1.2-6'
        ]);
        const formattedTxFeeWithCurrency = this.getCurrency([formattedTxFee, this.currentBaseCurrency, '1.2-6']);
        const transactionInfoHtml = `
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${sendAmountText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.amount} ${coin}</p>
              <p class="p-amount p-lc-amount" *ngIf=${this.tokenInfo.fiatValue}>${amountWithCurrency} ${this.currentBaseCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${gasFeeText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${formattedTxEth} ${networkCoin ? networkCoin : coin}</p>
              <p class="p-amount p-lc-amount">${formattedTxFeeWithCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">Send To</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.toTempAddress}</p>
            </ion-col>
          </ion-row>
        `;
        this.transactionInfo.nativeElement.outerHTML = transactionInfoHtml;
      } else if (this.tokenInfo.baseChainSymbol === 'BTC') {
        const networkFee = this.sendTokenData.networkFee;
        // await this.transactionSender.estimateFeeBasedOnAmount(
        //   this.sendTokenData.amount,
        //   this.tokenInfo.address,
        //   this.sendTokenData.toAddress,
        //   this.sendTokenData.memo, 'BTC'
        // );
        const coin = this.tokenInfo.symbol;
        const sendAmountText =
          await this.translate.get('tokens.send.transaction_details.send_amount', { coinName: coin ? coin : 'Bitcoin' }).toPromise(),
          gasFeeText = await this.translate.get('tokens.send.transaction_details.gas_fee').toPromise();
        const amountWithCurrency = this.getCurrency([
          (this.sendTokenData.amount * this.tokenInfo.fiatValue),
          this.currentBaseCurrency,
          '1.2-6'
        ]);
        const transactionInfoHtml = `
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${sendAmountText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.amount.toFixed(8)} ${coin}</p>
              <p class="p-amount p-lc-amount">${amountWithCurrency} ${this.currentBaseCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${gasFeeText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">~ ${networkFee} BTC</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">Send To</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.toTempAddress}</p>
            </ion-col>
          </ion-row>
        `;
        this.transactionInfo.nativeElement.outerHTML = transactionInfoHtml;
      } else if (this.tokenInfo.baseChainSymbol === 'SYS' && !this.tokenInfo.assetGuid) {
        const coin = this.tokenInfo.symbol;
        const sendAmountText =
          await this.translate.get('tokens.send.transaction_details.send_amount', { coinName: 'Syscoin' }).toPromise(),
          gasFeeText = await this.translate.get('tokens.send.transaction_details.gas_fee').toPromise();
        const amountWithCurrency = this.getCurrency([
          (this.sendTokenData.amount * this.tokenInfo.fiatValue),
          this.currentBaseCurrency,
          '1.2-6'
        ]);
        const transactionInfoHtml = `
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${sendAmountText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.amount} ${coin}</p>
              <p class="p-amount p-lc-amount">${amountWithCurrency} ${this.currentBaseCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${gasFeeText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">~ ${EST_SYS_NET_FEE} ${coin}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">Send To</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.toTempAddress}</p>
            </ion-col>
          </ion-row>
        `;
        this.transactionInfo.nativeElement.outerHTML = transactionInfoHtml;
      } else if (this.tokenInfo.baseChainSymbol === 'SOL') {
        const networkFee = 0.00005;
        const coin = this.tokenInfo.symbol;
        const sendAmountText = 'Send Amount', gasFeeText = 'Network Fee';
        const amountWithCurrency = this.getCurrency([
          (this.sendTokenData.amount * this.tokenInfo.fiatValue),
          this.currentBaseCurrency,
          '1.2-6'
        ]);
        const networkFeeWithCurrency = this.getCurrency([
          (networkFee * this.tokenInfo.fiatValue),
          this.currentBaseCurrency,
          '1.2-6'
        ]);
        const transactionInfoHtml = `
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${sendAmountText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.amount} ${coin}</p>
              <p class="p-amount p-lc-amount">${amountWithCurrency} ${this.currentBaseCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">${gasFeeText}</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${networkFee} ${this.tokenInfo.baseChainSymbol}</p>
              <p class="p-amount p-lc-amount">${networkFeeWithCurrency} ${this.currentBaseCurrency}</p>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="5">
              <p class="p-title">Send To</p>
            </ion-col>
            <ion-col size="7">
              <p class="p-amount">${this.sendTokenData.toTempAddress}</p>
            </ion-col>
          </ion-row>
        `;
        this.transactionInfo.nativeElement.outerHTML = transactionInfoHtml;
      }
    }
  }

  async confirmSend() {
    this.store.dispatch(new SetSendTokenDataAction({
      fromAddress: this.tokenInfo.address,
      tokenInfo: this.tokenInfo,
      amount: this.sendTokenData.amount || 0,
      ethGasFee: this.sendTokenData?.ethGasFee,
      memo: this.sendTokenData.memo ? this.sendTokenData.memo : '',
      networkFee: this.sendTokenData?.networkFee,
      ...this.sendTokenData
    }));

    const modal = await this.modalController.create({
      component: PinModalComponent,
      backdropDismiss: false,
      cssClass: 'fullscreen-modal',
      componentProps: {
        reason: AUTH_REASON.AUTH_SEND_TOKENS
      },
      id: 'pinModal'
    });
    await modal.present();
  }

  getCurrency(data) {
    const [value, currencyCode, digitsInfo] = data;
    try {
      switch (currencyCode) {
        case 'BTC':
          return this.currencyPipe.transform(value, currencyCode, '₿', digitsInfo);
          break;
        case 'ETH':
          return this.currencyPipe.transform(value, currencyCode, '', digitsInfo);
          break;
        case 'LTC':
          return this.currencyPipe.transform(value, currencyCode, '', digitsInfo);
          break;
        default:
          return this.currencyPipe.transform(value, currencyCode, 'symbol-narrow', digitsInfo);
      }
    } catch (e) {
      // pass, ignore NaN values.
      return value;
    }
  }

}
