import { Action } from '@ngrx/store';
import { GasStationState } from '../store/gasStation';
import { SetSendTokenDataAction, GasStationActionTypes } from '../actions/gasStation.actions';

interface InitialGasStationState {
  address: string;
  assetGuid: number;
  amount: number;
  memo: string;
  type: 'REFILL' | 'MOVE';
  to: string;
}

export const initialGasStationState: InitialGasStationState = {
  address: '',
  assetGuid: 0,
  amount: 0,
  memo: '',
  type: 'REFILL',
  to: '',
};

export function gasStationReducer(state: GasStationState = initialGasStationState, action: Action): GasStationState {
  let payload;
  switch (action.type) {
    case GasStationActionTypes.SET_GAS_STATION_SEND_DATA:
      payload = (action as SetSendTokenDataAction).payload;
      return {
        ...state,
        address: payload.address,
        amount: payload.amount,
        assetGuid: payload.assetGuid,
        memo: payload.memo,
        type: payload.type,
        to: payload.to,
      };
    case GasStationActionTypes.TRADE_MOVE_ASSET_SUCCESS:
      return { ...state, type: '', address: '', amount: 0, memo: '', assetGuid: null };

    case GasStationActionTypes.RESET_GAS_STATION_DATA:
      return { ...initialGasStationState };

    default:
      return state;
  }
}
