import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticatePage } from './authenticate.page';
import { TranslateModule } from '@ngx-translate/core';
import { PinInputModule } from '../../components/pin-input/pin-input.module';
import { CaptureEmailModalComponent } from 'src/app/components/capture-email-modal/capture-email-modal.component';
import { CaptureEmailModalModule } from 'src/app/components/capture-email-modal/capture-email-modal.module';
import { SafeHtmlsModule } from 'src/app/angular-wallet-base/pipes/safe-htmls/safe-htmls.module';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule,
    PinInputModule,
    ReactiveFormsModule,
    CaptureEmailModalModule,
    SafeHtmlsModule
  ],
  declarations: [AuthenticatePage],
  exports:[AuthenticatePage],
  entryComponents: [CaptureEmailModalComponent],
})
export class AuthenticateModule {}
