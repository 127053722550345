import { createSelector } from "@ngrx/store";
import {
  IPeerMeta,
} from "../lib/wallet-connect/helpers/types";
import { getWalletConnectState } from "./appState";
import Connector from "@walletconnect/core";
import { IWalletConnectSession } from "@walletconnect/types";
import { IWeb3Wallet } from "@walletconnect/web3wallet";

// -- Constants ------------------------------------------------------------- //

// This is the thing we're actually using for the redux store state
export interface IWalletConnectSelectedNetwork {
  name: string;
  chainId: number;
  rpcUrl: string;
  baseChainSymbol: string;
  walletAddress: string;
}
export interface WalletConnectState {
  readonly web3Wallet: IWeb3Wallet,
  readonly web3WalletNonce: number,
  readonly eventRequests?: any[];

  readonly walletConnectSelectedNetwork?: IWalletConnectSelectedNetwork;
  readonly walletConnectConnectedAddress?: string; // need to remove
  readonly connectors?: Connector[];
  readonly payload?: any;
  readonly peerMeta?: IPeerMeta;
  
  readonly results?: any[];
  readonly approvalSignatures?: any[];
  readonly rejectionErrors?: any[];
  readonly lastTransaction: any;
  readonly transactionHistory: any[];


  readonly session: IWalletConnectSession;
  readonly singularitySession: IWalletConnectSession;


  readonly signingRequest: {
    eventRequest: any,
    requestSession: any,
    networkFee: string,
  },
  supportedNameSpaces: Map<number,Record<string, any>>;
  message: string;
  loading: boolean;
  error: boolean;
}

export const getWalletConnectInstance = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state.web3Wallet
);

export const getWalletConnectInstanceNonce = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state.web3WalletNonce
)

export const getSupportedNamespaces = createSelector(
  getWalletConnectState, (state: WalletConnectState) => state.supportedNameSpaces
)


export const getWalletConnectSession = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state.session
);


export const getSingularitySession = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state.singularitySession
)








export const getWalletConnectStateAll = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state
);
export const getWalletConnectSelectedNetwork = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state.walletConnectSelectedNetwork
);

export const getWalletConnectConnectedAddress = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state.walletConnectSelectedNetwork.walletAddress
);


export const getSigningRequest = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state.signingRequest
);

export const getWalletConnectPeerMeta = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state?.peerMeta
);

export const getWalletConnectEventRequests = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state?.eventRequests
);

export const getWalletConnectConnector = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state.connectors
);

export const getWalletConnectPayload = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state?.payload
);

export const getWalletConnectApprovalSignatures = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state?.approvalSignatures
);

export const getWalletConnectRejectionErrors = createSelector(
  getWalletConnectState,
  (state: WalletConnectState) => state?.rejectionErrors
);

