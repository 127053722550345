import { TokenInfo } from '../../global';
import { createSelector } from '@ngrx/store';
import { getSendTokenState } from './appState';

export interface SendTokenState {
  readonly fromAddress: string;
  readonly toAddress: string;
  readonly amount: number;
  readonly memo?: string;
  readonly tokenInfo?: TokenInfo;
  readonly showSendForm?: boolean;
  readonly ethGasFee?: string; // will be depreciated and used networkFee
  readonly networkFee?: string;
}

export const getSendTokenInfo = createSelector(
  getSendTokenState,
  (state: SendTokenState) => state.tokenInfo
);

export const getSendToAddress = createSelector(
  getSendTokenState,
  (state: SendTokenState) => state.toAddress
);

export const getSendToken = createSelector(
  getSendTokenState,
  (state: SendTokenState) => state
);
