import { Action } from '@ngrx/store';

export enum SetupActionTypes {
  SET_CREDENTIALS = '[accountsetup] Setup Email and password',
  GET_CREDENTIALS = '[accountsetup] Get Email and password',
  CLEAR_CREDENTIALS = '[accountsetup] Clear Email and password',
  COMPLETE_SETUP = '[accountsetup] Complete setup',
  SET_CONTACT_NUMBER = '[accountsetup] Set setup Contact Number',
  SET_OTP_CODE = '[accountsetup] Set Forgot Password Otp',
  SET_SETUP_LEVEL = '[accountsetup] Set Setup Level',
  SET_SETUP_LEVEL_STATUS = '[accountsetup] Set Setup Level Status',
  SET_USER_STATUS = '[accountsetup] Set User Status',
  SET_SKIP_REGISTER_SUCCESS_PAGE = '[accountsetup] Set Register Success Page',
  RESET_ACCOUNT = '[accountsetup] Reset account statet to initial',
}

export class SetCredentialsAction implements Action {
  readonly type = SetupActionTypes.SET_CREDENTIALS;
  constructor(readonly payload: {
    email: string,
    contactNo?: object,
    password: string,
    lodeid?: number,
    token?: string,
    otpCode?: string,
    setupLevel?: number,
    isNewUser?: boolean
  }) { }
}

export class SetContactNumberAction implements Action {
  readonly type = SetupActionTypes.SET_CONTACT_NUMBER;
  constructor(readonly payload: { contactNo: object }) { }
}

export class SetForgotPasswordOtpAction implements Action {
  readonly type = SetupActionTypes.SET_OTP_CODE;
  constructor(readonly payload: { otpCode: string }) { }
}

export class SetRegistrationLevelAction implements Action {
  readonly type = SetupActionTypes.SET_SETUP_LEVEL;
  constructor(readonly payload: { level: number }) { }
}

export class SetUserStatusAction implements Action {
  readonly type = SetupActionTypes.SET_USER_STATUS;
  constructor(readonly payload: { status: boolean }) { }
}

export class SetSkipRegisterSuccessPageAction implements Action {
  readonly type = SetupActionTypes.SET_SKIP_REGISTER_SUCCESS_PAGE;
  constructor(readonly payload: { skip: boolean }) { }
}

export class ResetAccountAction implements Action {
  readonly type = SetupActionTypes.RESET_ACCOUNT;
}
