import { Action } from '@ngrx/store';
import {
  PlaceOrderAction,
  DismissOrderAction,
  FulfillOrderAction,
  Otc3ActionTypes,
  UpdateOrdersAction,
  FetchOrdersStateAction,
  TxPendingStateAction,
  OrderPendingStateAction,
} from '../actions/otc3.actions';
import { Otc3State } from '../store/otc3';

export const initialOtc3SetupState: Otc3State = {
  userOrders: [],
  marketOrders: [],
  isFetchingOrders: false,
  isTxPending: false,
  pendingOrderId: -1,
  message: '',
};

export function otc3Reducer(state: Otc3State = initialOtc3SetupState, action: Action): Otc3State {
  let payload;

  switch (action.type) {
    case Otc3ActionTypes.UPDATE_ORDERS:
      const { orderType, orders } = (action as UpdateOrdersAction).payload;
      return {
        ...state,
        [orderType === 'user_order' ? 'userOrders' : 'marketOrders']: orders,
      };

    case Otc3ActionTypes.PLACE_ORDER:
      payload = (action as PlaceOrderAction).payload;
      return {
        ...state,
      };

    case Otc3ActionTypes.DISMISS_ORDER:
      payload = (action as DismissOrderAction).payload;
      return {
        ...state,
      };

    case Otc3ActionTypes.FULFILL_ORDER:
      payload = (action as FulfillOrderAction).payload;
      return { ...state };

    case Otc3ActionTypes.FETCH_ORDERS_STATE:
      const { isFetchingOrders } = (action as FetchOrdersStateAction).payload;
      return { ...state, isFetchingOrders };

    case Otc3ActionTypes.TX_PENDING_STATE:
      const { isTxPending } = (action as TxPendingStateAction).payload;
      return { ...state, isTxPending };

    case Otc3ActionTypes.ORDER_PENDING_STATE:
      const { pendingOrderId } = (action as OrderPendingStateAction).payload;
      return { ...state, pendingOrderId };

    default:
      return state;
  }
}
