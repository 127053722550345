import { Injectable } from '@angular/core';
import { ERC20_ABI, MAX_INT_ALLOWANCE, TOKEN_ALLOWANCE, LTC_VESTING_ABI } from '../constants';
import { utils, providers, Contract, Signer, BigNumber, constants } from 'ethers';

import { NetworkService } from './network.service';
import { StorageService } from './storage.service';
import { UserVestingLTC, UserStakingLODE, UserVestingInformation } from '../../global';
import { Logger } from './logger.service';

@Injectable({
    providedIn: 'root'
})
export class VestingLTCService {

    private network: any;
    private provider: providers.BaseProvider;

    private vestingContractAddress: string;
    private ltc: string;
    private vote: string;

    constructor(
        private storage: StorageService,
        private networkService: NetworkService,    
    ) {
        this.vestingContractAddress = "0x5512d996e14DD5c029E8C3DD8Da24de2647393A8";
        this.ltc = "0x46856274bc823D9B2aaA47D1ecDD35d0D29b485e";
        this.vote = "0x52D6cb3e0DbFc7cA0dccbCda71011B07093FDd31";
    }

    async initiateVestingConnection() {
        this.network = await this.networkService.getActiveAvaxNetwork();
        this.provider = providers.getDefaultProvider(this.network.RPC_URL);
    }
    
    /*
     * Retrieves the user LTC balance.
     * The user input should be the wallet address in 0x...123 format as a string.
     * Returned value type is bigint. We can make it return a floating point number.
    **/
    async getUserLTCBalance(user: string): Promise<number> {
        const ltcInterface = new utils.Interface(ERC20_ABI);

        const ltcContract = new Contract(
            this.ltc,
            ltcInterface,
            this.provider
        );

        try {
            const ltcBalance = await ltcContract.balanceOf(user);
            const decimals = await ltcContract.decimals();
            console.log('Balance is', ltcBalance)
    
            return parseFloat((Number(ltcBalance) / (10 ** decimals)).toFixed(6));               
        } catch (e) {
            console.log(`Error when querying LTC balance`, e); 
        }
    }

    async getUserLTCAllowanceToVestingContract(user: string): Promise<number> {
        console.log(`Inside the get LTC allowance getter...`);
        if (!this.provider) { await this.initiateVestingConnection(); }

        const ltcInterface = new utils.Interface(ERC20_ABI);

        const ltcContract = new Contract(
            this.ltc,
            ltcInterface,
            this.provider
        );

        const ltcAllowance = await ltcContract.allowance(user, this.vestingContractAddress);
        const decimals = await ltcContract.decimals();

        return parseFloat((Number(ltcAllowance) / (10 ** decimals)).toFixed(6));
    }

    async getUserInformation(user: string): Promise<UserVestingInformation> {
        if (!this.provider) { await this.initiateVestingConnection(); }

        const vestingInterface = new utils.Interface(LTC_VESTING_ABI);
        
        const vestingContract = new Contract(
            this.vestingContractAddress,
            vestingInterface,
            this.provider
        );

        //console.log(`User ${user} is hitting vesting contract:`, vestingContract)
        
        //console.log(`Querying LTC balance...`);
        const ltcBalance = await this.getUserLTCBalance(user);
        //console.log(`User ${user} LTC balance is ${ltcBalance}`)
                    
        const userVestingInformation: any = await vestingContract.infoLTC(user); 
        //console.log(`User ${user} vesting information is:`, userVestingInformation)

        const userClaimableLode: any = await vestingContract.getClaimableLodeBalance(user)

        const userStakingInformation = await vestingContract.infoLODE(user);
        //console.log(`User ${user} staking information is:`, userStakingInformation)

        const userClaimableStakingInterest = await vestingContract.getLodeAccruedRewardFee(user);

        const responseLTC: UserVestingLTC = {
            depositTimestamp: new Date(userVestingInformation?.depositTimestamp * 1000),
            depositBalance: userVestingInformation?.depositedBalance,
            amountClaimed: userVestingInformation?.claimedLODE - userStakingInformation?.stakedLODEBalance,
            claimableBalance: userClaimableLode,
        }

        const responseLODE: UserStakingLODE = {
            amountStaked: userStakingInformation?.stakedLODEBalance,
            interestEarned: userClaimableStakingInterest,  
            interestClaimed: userClaimableStakingInterest,
            hasClaimedJackpot: userStakingInformation?.hasClaimedJackpot         
        }

        const response: UserVestingInformation = {
            ltcBalance: ltcBalance,
            hasDeposited: userVestingInformation?.depositTimestamp > 0,
            isStaking: userStakingInformation?.stakedLODEBalance > 0,
            vestingInfo: responseLTC,
            stakingInfo: responseLODE
        }

        console.log(`getUserInformation for ${user}:`, response);

        return response;
    }

    async getLODEBalanceToClaimOrStake(user: string) {
        if (!this.provider) { await this.initiateVestingConnection(); }

        const vestingInterface = new utils.Interface(LTC_VESTING_ABI);

        const vestingContract = new Contract(
            this.vestingContractAddress,
            vestingInterface,
            this.provider
        );

        return await vestingContract.getClaimableLodeBalance(user);
    }

    async getLODEAccruedInterest(user: string) {
        if (!this.provider) { await this.initiateVestingConnection(); }

        const vestingInterface = new utils.Interface(LTC_VESTING_ABI);

        const vestingContract = new Contract(
            this.vestingContractAddress,
            vestingInterface,
            this.provider
        );

        return await vestingContract.getLodeAccruedRewardFee(user);
    }

    /*
     * The user calls this method to allow the vesting contract to take the LTC balance from his wallet.
     * An example of the wallet input can be found at trader-joe.service.ts swapOrderMarketPlace() method.
     * The response should be an object with the approval tx ID and the block number where the tx was processed. 
     * If there's an issue, an error is returned.
    **/
    async approveLTCToVestingContract(wallet: any) {
        const ltcInterface = new utils.Interface(ERC20_ABI);

        const ltcContract = new Contract(
            this.ltc,
            ltcInterface,
            wallet
        );
        
        //console.log(`Inside the LTC approval, about to approve...`);
        try {
            //console.log(`Inside approval's try...`)
            const approveTx = await ltcContract.connect(wallet).approve(this.vestingContractAddress, constants.MaxUint256);
            const receiptTx = await approveTx.wait();

            //console.log(`Approval receipt:`, receiptTx);

            return {
                transactionHash: approveTx.hash,
                transactionBlock: receiptTx.blockNumber
            };
     
        } catch (e) {
            Logger.error('Error when setting LTC allowance to the vesting contract:', wallet.address, e);
            return { error: 2, message: 'LTC token contract error when setting allowance: please, try again' };
        }
    }

    /*
     * The user calls this method to deposit the LTC on the vesting contract.
     * An example of the wallet input can be found at trader-joe.service.ts swapOrderMarketPlace() method.
     * The response should be an object with the deposit tx ID and the block number where the tx was processed. 
     * If there's an issue, an error is returned.
    **/
    async depositLTC(wallet: any) : Promise<UserVestingInformation>  {
        //console.log(`Inside the depositLTC method call!`);
        //console.log(`Wallet object:`, wallet);

        const vestingInterface = new utils.Interface(LTC_VESTING_ABI);

        const vestingContract = new Contract(
            this.vestingContractAddress,
            vestingInterface,
            wallet
        );

        //console.log(`Querying the LTC allowance...`);
        const ltcAllowance = await this.getUserLTCAllowanceToVestingContract(wallet.address);
        //console.log(`LTC allowance is null: ${ltcAllowance === 0}`);
            if(ltcAllowance === 0) {
                //console.log(`Setting the LTC allowance...`)
                await this.approveLTCToVestingContract(wallet);
                //console.log(`LTC allowance set`)
            }
            
            //console.log("About to deposit...");
            const depositTx = await vestingContract.connect(wallet).deposit();
            const receiptTx = await depositTx.wait();

            //console.log("LTC balance deposited!")
   
            const userInformation = await this.getUserInformation(wallet.address);
   
            const response =  {
                depositTimestamp: userInformation.vestingInfo.depositTimestamp,
                depositBalance: userInformation.vestingInfo.depositBalance,
                claimableBalance: 0,
            };

            //console.log('This is the depositLTC response:', response);
            return userInformation;
    }

    async claimLODE(wallet: any) : Promise<UserVestingInformation>  {
        const vestingInterface = new utils.Interface(LTC_VESTING_ABI);

        const vestingContract = new Contract(
            this.vestingContractAddress,
            vestingInterface,
            wallet
        );
        
        const claimTx = await vestingContract.connect(wallet).claimAccruedLODE();
        const receiptTx = await claimTx.wait();    
        
        //console.log(`This is the claimLODE response start here`);

        const userInformation = await this.getUserInformation(wallet.address);

        const response =  {
            depositBalance: userInformation.vestingInfo.depositBalance,
            amountClaimed: userInformation.vestingInfo.amountClaimed,
            claimableBalance: 0,
        };

        //console.log(`This is the claimLODE response:`, response);

        return userInformation;
    }

    async stakeLODE(wallet: any): Promise<UserVestingInformation> {
        const vestingInterface = new utils.Interface(LTC_VESTING_ABI);

        const vestingContract = new Contract(
            this.vestingContractAddress,
            vestingInterface,
            wallet
        );

        const stakeTx = await vestingContract.connect(wallet).stakeAccruedLODE();
        const receiptTx = await stakeTx.wait();
        
        const userInformation = await this.getUserInformation(wallet.address);

        const response = {
            amountStaked: userInformation.stakingInfo.amountStaked,
            interestEarned: userInformation.stakingInfo.interestEarned
        };

        return userInformation;           
    }

    async unstakeLODE(wallet: any): Promise<UserVestingInformation> {
        const voteInterface = new utils.Interface(ERC20_ABI);
        
        const vestingInterface = new utils.Interface(LTC_VESTING_ABI);

        const voteContract = new Contract(
            this.vote,
            voteInterface,
            wallet
        );

        const vestingContract = new Contract(
            this.vestingContractAddress,
            vestingInterface,
            wallet
        );

        const voteAllowance = await voteContract.allowance(wallet.address, this.vestingContractAddress);
        const voteAllowanceFloat = parseFloat((Number(voteAllowance) / (10 ** 17)).toFixed(6));

        if (voteAllowanceFloat === 0) {
            const approveTx = await voteContract.connect(wallet).approve(this.vestingContractAddress, constants.MaxUint256);
            const receiptTx = await approveTx.wait();
        }

        const unstakeTx = await vestingContract.connect(wallet).unstakeLODE();
        const receiptTx = await unstakeTx.wait();
        
        const userInformation = await this.getUserInformation(wallet.address);

        const response = {
            amountStaked: userInformation.stakingInfo.amountStaked,
            interestClaimed: userInformation.stakingInfo.interestClaimed
        };

        return userInformation;     
    }
}