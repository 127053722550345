import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { AppModeService } from '../../angular-wallet-base/services/app-mode.service';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from 'src/app/angular-wallet-base/services/environment.service';

@Component({
  selector: 'app-tour-guide',
  templateUrl: './tour-guide.component.html',
  styleUrls: ['./tour-guide.component.scss'],
  animations: [
    trigger('showHideAnimation',
      [transition(':enter',
        [style({ opacity: 0 }),
        animate('400ms ease-out', style({ opacity: 1 }))]),
      transition(':leave',
        [style({ opacity: 1 }),
        animate('400ms ease-in', style({ opacity: 0 }))])
      ])]
})
export class TourGuideComponent implements OnInit {

  pageType: string = 'intro';
  pointer: boolean = true;
  btnClickCount: Number = -1;
  selectedTab: any;
  popupHeight: any;
  popup: any = {
    top: '', left: ''
  }
  pageList: any = [
    {
      type: 'home',
      title: 'quick_tour.home',
      desc: 'quick_tour.home_des',
      img: '../../assets/images/home-graphics.png'
    },
    {
      type: 'send',
      title: 'quick_tour.send_assets',
      desc: 'quick_tour.send_assets_des',
      img: '../../assets/images/Paperplane@3x.png'
    },
    {
      type: 'receive',
      title: 'quick_tour.recieve',
      desc: 'quick_tour.recieve_des',
      img: '../../assets/images/qrscan.png'
    },
    {
      type: 'buy',
      title: 'quick_tour.buy_assets',
      desc: 'quick_tour.buy_assets_des',
      img: ''
    },
    {
      type: 'trade',
      title: this.environmentService.trade ? 'quick_tour.trade_assets' : 'quick_tour.swap_assets',
      desc: this.environmentService.trade ? 'quick_tour.trade_assets_des' : 'quick_tour.swap_assets_des',
      img: '../../assets/images/coingroup.png'
    }
  ]
  coinArray: Array<any> = [
    {
      imgName: 'AGX Coin@2x.png',
      desc: 'before_activation.buy.coin.agx'
    },
    {
      imgName: 'AUX Coin@2x.png',
      desc: 'before_activation.buy.coin.aux'
    },
    {
      imgName: 'LODE Token@2x.png',
      desc: 'before_activation.buy.coin.lode'
    },
    {
      imgName: 'moreBtn.png',
      desc: 'before_activation.buy.coin.more'
    }
  ]
  tabList: Array<any> = ['home', 'send', 'receive', 'buy', 'trade'];
  tabCheckStatus: Array<any> = [false, false, false, false, false];
  coinDescription: string = this.coinArray[0].desc;
  isMobileMode: boolean = true;
  desktopPageIndex: any = 0;
  tradeFeature = this.environmentService.trade;

  constructor(
    public modalController: ModalController, 
    private translate: TranslateService, 
    public appMode: AppModeService,
    private environmentService: EnvironmentService
  ) {
    this.appMode.isMobileMode.subscribe((mode) => { this.isMobileMode = mode; });
  }

  ngOnInit() { }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  selectedContent(e, from, description?) {

    let selected, className;
    if (description) this.coinDescription = description;
    selected = document.querySelectorAll('.graphics-icons .icons .glow');
    className = 'glow';
    // tslint:disable-next-line: only-arrow-functions
    selected.forEach(function (el) {
      el.classList.remove(className);
    });
    e.currentTarget.classList.add(className);
  }

  viewContent(type, index, e?) {
    let tabindex = index > 4 ? 4 : index;
    this.selectedTab = tabindex;
    if (e) {
      this.selectedContent(e, 'tab');
      this.pageType = type;
    } else {
      this.tabCheckStatus[tabindex] = true;
      this.pageType = this.tabList[tabindex];
    }
    this.tabCheckStatus[tabindex] = true;
    this.pointer = false;
  }

  desktopNext() {
    if (this.desktopPageIndex < 5) {
      const menuItem = document.getElementById("menu-" + this.desktopPageIndex);
      const popup: any = document.getElementsByClassName('desktop-specific')[0];
      const pointer: any = document.getElementsByClassName('arrow-right')[0]
      this.pageType = this.pageList[this.desktopPageIndex].type;
      if (this.pageType == 'home') {
        this.popupHeight = menuItem.getBoundingClientRect().top - 26;
        popup.classList.add('m-l-240');
        popup.style['margin-top'] = this.popupHeight + 'px';

      }
      else popup.classList.add('custom-width');
      this.desktopPageIndex = this.desktopPageIndex + 1;
      var wHeight = menuItem.getBoundingClientRect().top - this.popupHeight;
      pointer.style['top'] = wHeight + 'px';
    } else {
      this.modalController.dismiss();
    }

  }

}
