import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { AppState, getAccountSetupState } from '../store/appState';
import { Store } from '@ngrx/store';
import { NavController } from '@ionic/angular';
import HdKeyringController from '../lib/pangolin/keyring/hd.keyring.controller';
import { Logger } from './logger.service';
import { CompleteSetupAction } from '../actions/setup.actions';
import { CreateKeychainSuccessAction } from '../actions/wallet.actions';
import { SetUserDataAction, LoadUserPreferencesAction } from '../actions/userPreferences.actions';
import { createTokenKeyring, decryptData, encryptData, getToastPosition } from '../utils';
import { getSetupMnemonic, getSetupPin } from '../store/setup';
import 'rxjs/add/operator/first';
import { Credentials } from '../../global';
import { AUTH_DATA_KEY, USER_PREFERENCES_STORAGE_KEY } from '../constants';
import { getUnlockKeychain, initWalletWithMnemonic } from '../lib/pangolin/keyring/keychainUtils';
import { hdPaths } from '../lib/pangolin/keyring/index';
import { NetworkService } from './network.service';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { ToastController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  WALLET_STORAGE_KEY,
  BACKUP_WALLET_STORAGE_KEY,
  EMAIL_STORAGE_KEY,
  WALLET_KEY,
} from 'src/app/angular-wallet-base/constants';
import { getJwtToken } from '../store/userPreferences';
import { WalletConnectWalletControllerService } from './wallet-connect-wallet-controller.service';
import { WalletType } from '../../views/select-asset-modal/select-wallet-modal/select-wallet-modal.component';
import { BankAccountService } from './bank-account.service';
import { isNil } from '../ts-util';
import { getKeychain } from '../store/wallet';
class InfoEmail {
  counter = 0;
  email = '';
  never = false;
}
@Injectable({
  providedIn: 'root',
})
export class WalletService {
  public walletType: WalletType;
  constructor(
    private storage: StorageService,
    private store: Store<AppState>,
    private nav: NavController,
    private network: NetworkService,
    private toastController: ToastController,
    private platform: Platform,
    private translate: TranslateService,
    private walletConnectWalletControllerService: WalletConnectWalletControllerService,
    private bankAccountService: BankAccountService
  ) {
  }

  async incrementLoginAttempts() {
    let vault;
    try {
      vault = await this.storage.get(EMAIL_STORAGE_KEY);
      if (!vault) {
        vault = new InfoEmail();
      }
      vault.counter++;
      await this.storage.set(EMAIL_STORAGE_KEY, vault);
      return await this.storage.get(EMAIL_STORAGE_KEY);
    } catch (e) {
      Logger.info('Error!');
    }
  }

  async getStorageEmail() {
    try {
      return await this.storage.get(EMAIL_STORAGE_KEY);
    } catch (e) {
      Logger.info('Error!');
    }
  }

  async setStorageEmail(obj) {
    try {
      return await this.storage.set(EMAIL_STORAGE_KEY, obj);
    } catch (e) {
      Logger.info('Error!');
    }
  }

  // Change pin
  async changePin(oldPin, newPin) {
    const pin = await this.store.select(getSetupPin).pipe(take(1)).toPromise();
    const jwtToken = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const userPref = await this.store.select(getAccountSetupState).pipe(take(1)).toPromise();
    if (oldPin === pin) {
      const userAction = {
        newPin,
        credentials: {
          username: userPref.email,
          password: userPref.password,
        },
        jwtToken,
        lodeid: userPref.lodeid,
      };
      // encrypt using pin and store user data
      const encryptedData = encryptData(pin, userAction);
      await this.storage.set(AUTH_DATA_KEY, encryptedData);
      return true;
    } else {
      return false;
    }
  }

  // store pin with user data with AES encryption
  async storePin() {
    const pin = await this.store.select(getSetupPin).pipe(take(1)).toPromise();
    const jwtToken = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const userPref = await this.store.select(getAccountSetupState).pipe(take(1)).toPromise();
    const userAction = {
      pin,
      credentials: {
        username: userPref.email,
        password: userPref.password,
      },
      jwtToken,
      lodeid: userPref.lodeid,
    };

    // encrypt using pin and store user data
    const encryptedData = encryptData(pin, userAction);
    await this.storage.set(AUTH_DATA_KEY, encryptedData);

    this.store.dispatch(new SetUserDataAction(userAction));
    this.store.dispatch(new CompleteSetupAction());
    return true;
  }

  async completeSetup(pin: string = '') {
    const mnemonic = await this.store.select(getSetupMnemonic).pipe(take(1)).toPromise();
    const currentPin = pin ? pin : await this.store.select(getSetupPin).pipe(take(1)).toPromise();
    if (mnemonic) {
      const keychain = await initWalletWithMnemonic(currentPin, mnemonic);

      // write the wallet to local store
      await this.storage.set(WALLET_STORAGE_KEY, keychain.vault);

      // For new wallet we are encrypted the phrase
      await this.setEncryptedPhrase(mnemonic);

      this.store.dispatch(new CompleteSetupAction());
      await this.dispatchKeychainCreated(keychain);
    }
  }

  async unlockWallet(password): Promise<boolean> {
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    let keychain = new HdKeyringController();
    keychain.vault = vault;
    Logger.info('Unlocking wallet via keychain', keychain);
    const unlocked = await keychain.unlock(password);
    Logger.info('Wallet unlocked: ', unlocked);
    const keyrings = keychain.keyrings;

    if (keyrings[0]) {
      if (
        !keyrings[0].nodes.length ||
        !keyrings[0].nodes.find((node) => node.network.bech32 === 'tb' && node.network.bech32 === 'bc') // BTC not founds in nodes
      ) {
        // If nodes length is 0, then coming from old agxpay wallet
        // Get mnemonic and initialize a new one.
        Logger.info('Starting keychain migration');

        keychain = await initWalletWithMnemonic(password, keyrings[0].mnemonic);
        await this.storage.set(WALLET_STORAGE_KEY, keychain.vault);

        Logger.info('Migration complete');
      }
    }

    if (unlocked) {
      await this.dispatchKeychainCreated(keychain);
    }
    return unlocked;
  }

  instantiateWalletServices(pin) {
    this.walletConnectWalletControllerService.init({pin});
  }

  async dispatchKeychainCreated(keychain) {
    const tokenKeyrings = [];
    //check if ACH and bankAccounts feature is enabled and ach account exists
    if (environment.features.bankAccounts && (await this.bankAccountService.getACHStatus())) {
      // tokenKeyrings.push(createTokenKeyring({
      //   symbol: 'PT',
      //   address: this.bankAccountService.get
    }
    await keychain.keyrings[0].nodes.forEachAsync(async (node) => {
      if (node.isSys() && environment.features.sys) {
        const sysKeyring = createTokenKeyring({symbol: 'SYS', address: node.getAllAccountAddresses()[0], balance: 0, baseChainSymbol: 'SYS'});
        tokenKeyrings.push(sysKeyring);
      } else if (node.isEth()) {
        if (environment.features.eth) {
          tokenKeyrings.push(createTokenKeyring({
              symbol: 'ETH',
              address: node.getAllAccountAddresses()[0],
              balance: 0,
              baseChainSymbol: 'ETH',
              guid: null,
              ethFilterIndex: 0
          }));
        }
        if (environment.features.avax) {
          tokenKeyrings.push(createTokenKeyring({
              symbol: 'AVAX',
              address: node.getAllAccountAddresses()[0],
              balance: 0,
              baseChainSymbol: 'AVAX',
              guid: null,
              ethFilterIndex: 0
          }));
        }
        if (environment.features.sysnevm) {
          tokenKeyrings.push(createTokenKeyring({
              symbol: 'SYSNEVM',
              address: node.getAllAccountAddresses()[0],
              balance: 0,
              baseChainSymbol: 'SYSNEVM',
              guid: null,
              ethFilterIndex: 0
          }));
        }
      } else if (node.isBtc() && environment.features.btc) {
        tokenKeyrings.push(createTokenKeyring({
          symbol: 'BTC',
          address: node.getAllAccountAddresses()[0],
          balance: 0,
          baseChainSymbol: 'BTC',
          guid: null,
          ethFilterIndex: 0
        }));
      } else if (node.isSol() && environment.features.sol) {
        tokenKeyrings.push(createTokenKeyring({
          symbol: 'SOL',
          address: node.getAllAccountAddresses()[0],
          balance: 0,
          baseChainSymbol: 'SOL',
          guid: null,
          ethFilterIndex: 0
        }));
      }
    });

    Logger.info('dispatchKeychainCreated ', tokenKeyrings);
    this.store.dispatch(
      new CreateKeychainSuccessAction({
        keyrings: tokenKeyrings,
      })
    );
  }

  async changeWalletPassword(oldPassword: string, newPassword: string): Promise<boolean> {
    const userPreferences = JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY));
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    let keychain = new HdKeyringController();
    keychain.vault = vault;

    // Decrypting wallet vault with old pwd
    let unlocked = await keychain.unlock(oldPassword);
    if (!unlocked) {
      return false;
    }

    // Creating new vault with new pwd
    const newEncryptedVault = keychain.encryptVault(newPassword);
    await this.storage.set(WALLET_STORAGE_KEY, newEncryptedVault);
    const newVault = await this.storage.get(WALLET_STORAGE_KEY);
    keychain = new HdKeyringController();
    keychain.vault = newVault;

    // Make sure the new vault is working fine with the new pwd
    unlocked = await keychain.unlock(newPassword);

    if (userPreferences && userPreferences.credentials && userPreferences.jwtToken) {
      // User credentials are stored and encrypted in order to update Jwt on login, so need to update them too.
      const decryptedUserPrefs: Credentials = await decryptData(oldPassword, userPreferences.credentials);

      // Effect is going to take care of saving to storage.
      await this.store.dispatch(
        new SetUserDataAction({
          pin: newPassword,
          credentials: decryptedUserPrefs,
          jwtToken: userPreferences.jwtToken,
          lodeid: userPreferences.lodeid,
        })
      );
    }

    return unlocked;
  }

  async migrateFrom201to202(pin) {
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const userPrefs = JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY));
    const decryptedVault: any = HdKeyringController.prototype.decryptVault(pin, vault);
    const decryptedNodes = decryptedVault[0].nodes;
    const activeSysNetwork = await this.network.getActiveSysNetwork();
    const activeEthNetwork = await this.network.getActiveEthNetwork();
    const activeSolNetwork = await this.network.getActiveSolNetwork();

    decryptedNodes.forEach(async (node) => {
      if (node.hdPath === hdPaths.syscoinMainnet && activeSysNetwork.network === 'testnet' && userPrefs.sys) {
        userPrefs.sys.network = 'sys-main';
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }
      if (node.hdPath === hdPaths.ethereumMainnet && activeEthNetwork.network === 'testnet' && userPrefs.eth) {
        userPrefs.eth.network = 'eth-main';
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }

      if (!userPrefs.btc) {
        userPrefs.btc = {
          network: environment.production ? 'btc-main' : 'btc-test',
        };
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }

      if (!userPrefs.sol) {
        userPrefs.sol = {
          network: environment.production ? 'sol-main' : 'sol-test',
        };
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(userPrefs));
        this.store.dispatch(new LoadUserPreferencesAction());
      }
    });
  }

  async migrationEncryptedPhrase(pin) {
    const oldHash = await this.storage.get(WALLET_KEY);
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getUnlockKeychain(vault, pin);
    if (!oldHash) {
      const mnemonic = keychain.keyrings[0].mnemonic;
      await this.setEncryptedPhrase(mnemonic);
    }
  }

  async setEncryptedPhrase(mnemonic) {
    let hash = CryptoJS.SHA256(mnemonic);
    hash = hash.toString(CryptoJS.enc.Hex);
    await this.storage.set(WALLET_KEY, hash);
  }

  async compareEncryptedPhrase(mnemonic): Promise<boolean> {
    const oldHash = await this.storage.get(WALLET_KEY);
    if (oldHash) {
      let hash = CryptoJS.SHA256(mnemonic);
      hash = hash.toString(CryptoJS.enc.Hex);
      return hash === oldHash;
    }
    return true;
  }

  public async getBackupWalletVault() {
    return this.storage.get(BACKUP_WALLET_STORAGE_KEY);
  }

  public deleteBackupWallet() {
    return this.storage.remove(BACKUP_WALLET_STORAGE_KEY);
  }

  public async checkForMultipleWallets(shouldShowToast = false) {
    const oldVault = await this.getBackupWalletVault();
    const currentVault = await this.storage.get(WALLET_STORAGE_KEY);

    const multipleWalletExist = oldVault && oldVault !== currentVault;

    if (shouldShowToast && multipleWalletExist) {
      await this.showMultipleWalletDetectedToastIfNeeded();
    }

    return multipleWalletExist;
  }

  private async showMultipleWalletDetectedToastIfNeeded() {
    const prefs = JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY));

    if (!prefs.duplicatedWalletToastShown) {
      const toast = await this.toastController.create({
        message: await this.translate.get('settings.multiple_wallet.toast_message').toPromise(),
        position: getToastPosition(this.platform),
        buttons: [
          {
            text: await this.translate.get('settings.multiple_wallet.toast_button_go_settings').toPromise(),
            handler: () => {
              toast.dismiss();
              this.nav.navigateForward(['wallet', 'settings'], {
                queryParams: {
                  tab: 'wallet',
                },
              });
            },
          },
          {
            text: await this.translate.get('settings.multiple_wallet.toast_button_hide').toPromise(),
            role: 'cancel',
          },
        ],
      });
      await toast.present();

      prefs.duplicatedWalletToastShown = true;

      await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
    }
  }

  setWalletType(val: WalletType): void {
    this.walletType = val;
  }
  getWalletType(): WalletType {
    return this.walletType || 'vault';
  }


  //baseChainSymbol must be capital
  async getWalletAddressByBaseChainSymbol(baseChainSymbol: string): Promise<string> {
    const keyChains = await this.store.select(getKeychain).pipe(take(1)).toPromise();
    if (isNil(keyChains) || isNil(keyChains[baseChainSymbol]) || isNil(keyChains[baseChainSymbol].address)) {
      throw Error(`${baseChainSymbol} is not existed`);
    }
    return keyChains[baseChainSymbol].address;
  }
}
