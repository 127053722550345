import { Action } from '@ngrx/store';
import {
  ERC20tokenData,
  GetTokenBalancesRequestPayload,
  TokenFiatValues,
  TokenInfo,
  TokenKeyring,
  UnconfirmedTransaction,
  ZdagTransactionAction
} from '../../global';
import { BlockbookGetAddressTxsResponseWithWalletData } from '../services/blockbook';

export enum WalletActionTypes {
  CREATE_KEYCHAIN_SUCCESS = '[wallet] Create keychain success',
  GET_ALL_TOKEN_BALANCES = '[wallet] Get all token balances',
  GET_TOKEN_BALANCES_REQUEST = '[wallet] Get token balances request',
  GET_TOKEN_BALANCES_SUCCESS = '[wallet] Get token balances success',
  GET_TOKEN_BALANCES_FAILED = '[wallet] Get token balances failed',
  GET_TOKEN_HISTORY_REQUEST = '[wallet] Get token history request',
  GET_TOKEN_HISTORY_SUCCESS = '[wallet] Get token history success',
  GET_TOKEN_HISTORY_FAILED = '[wallet] Get token history failed',
  GET_TOKEN_FIAT_VALUES_REQUEST = '[wallet] Get token fiat values request',
  GET_TOKEN_FIAT_VALUES_SUCCESS = '[wallet] Get token fiat values success',
  GET_TOKEN_FIAT_VALUES_FAILED = '[wallet] Get token fiat values failed',
  SET_TOKEN_RETAIL_FIAT_VALUES = '[wallet] Set token RETAIL fiat values',
  GET_FEE_PER_ASSET_TX_REQUEST = '[wallet] Get fee per asset tx request',
  GET_FEE_PER_ASSET_TX_SUCCESS = '[wallet] Get fee per asset tx success',
  GET_FEE_PER_ASSET_TX_FAILED = '[wallet] Get fee per asset tx failed',
  RESET_WALLET = '[wallet] Reset wallet',
  RESET_WALLET_AUTH = '[wallet] Reset wallet and auth too',
  SET_MNEMONIC = '[wallet] Set mnemonic',
  AUTHENTICATE = '[wallet] Authenticate',
  DEAUTHENTICATE = '[wallet] Deauthenticate',
  PREVENT_AUTH_ON_RESUME = '[wallet] Prevent auth in resume',
  ADD_UNCONFIRMED_TX = '[wallet] Add unconfirmed tx',
  SET_UNCONFIRMED_TX_CANCELLED = '[wallet] Set unconfirmed tx cancelled',
  REMOVE_UNCONFIRMED_TX = '[wallet] Remove unconfirmed tx',
  ZDAG_CONFIRMED_TX = '[wallet] Zdag confirmed tx',
  ZDAG_ERROR_TX = '[wallet] Zdag error tx',
  CLEAR_TX_HISTORY = '[wallet] Clear TX history',
  NEW_CONFIRMED_TX_RECEIVED = '[wallet] New confirmed TX received',
  ADD_TRADE_ORDERS = '[wallet] Add trade orders - active',
  REMOVE_TRADE_ORDERS = '[wallet] Remove trade orders - active',
  GET_TRADE_ORDERS = '[wallet] Get all trade order - active',
  ADD_TRADE_ORDERS_HISTORY = '[wallet] Add trade orders history',
  GET_TRADE_ORDERS_HISTORY = '[wallet] Get all trade history',
  ADD_PURCHASE_HISTORY = '[wallet] Add Purchase history',
  GET_PURCHASE_HISTORY = '[wallet] Get Purchase history',
  SET_WALLET_REDIRECT_URI = '[wallet] Wallet redirect URI for OAuth services and deeplinks',
  RESET_WALLET_DATA = '[wallet] Reset wallet data',
  ADD_ERC20_TOKENS = '[wallet] Add ERC20 tokens',
  GET_ERC20_TOKENS = '[wallet] Get ERC20 tokens',
  HIDE_ERC20_TOKENS = '[wallet] Hide ERC20 tokens',
  GET_PT_TOKENS = '[wallet] Get PT tokens',
}

export class SetWalletRedirectUriAction implements Action {
  readonly type = WalletActionTypes.SET_WALLET_REDIRECT_URI;
  constructor(public payload: string) {}
}

export class CreateKeychainSuccessAction implements Action {
  readonly type = WalletActionTypes.CREATE_KEYCHAIN_SUCCESS;
  constructor(readonly payload: { keyrings: TokenKeyring[] }) { }
}

export class GetTokenHistoryRequestAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_HISTORY_REQUEST;
  constructor(readonly payload: {
    ethFilterIndex?: number,
    sysGuid?: string,
    address: string,
    pageNum: number,
    itemsPerPage: number,
    baseChainSymbol: string,
    firstPageHash?: any
  } = null) { }
}

export class GetTokenHistorySuccessAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_HISTORY_SUCCESS;
  constructor(readonly payload: { result: BlockbookGetAddressTxsResponseWithWalletData } = null) { }
}

export class GetTokenHistoryFailedAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_HISTORY_FAILED;
  constructor(readonly payload: { error: any } = null) { }
}

export class GetAllTokenBalancesAction implements Action {
  readonly type = WalletActionTypes.GET_ALL_TOKEN_BALANCES;
  constructor() { }
}

export class GetTokenBalancesRequestAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_BALANCES_REQUEST;
  constructor(readonly payload: GetTokenBalancesRequestPayload = null) { }
}

export class GetTokenBalancesSuccessAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_BALANCES_SUCCESS;
  constructor(readonly payload: { keyrings: TokenInfo[] } = null) { }
}

export class GetTokenBalancesFailedAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_BALANCES_FAILED;
  constructor(readonly payload: { error: any } = null) { }
}

export class ResetWalletAction implements Action {
  readonly type = WalletActionTypes.RESET_WALLET;
}
export class ResetWalletWithAuthAction implements Action {
  readonly type = WalletActionTypes.RESET_WALLET_AUTH;
  constructor(readonly payload: { authenticated: boolean, queryCount: number, preventAuthOnResume: boolean }) { }
}

export class SetMnemonicAction implements Action {
  readonly type = WalletActionTypes.SET_MNEMONIC;
  constructor(readonly payload: { mnemonic: string }) { }
}

export class AuthenticateAction implements Action {
  readonly type = WalletActionTypes.AUTHENTICATE;
}

export class DeauthenticateAction implements Action {
  readonly type = WalletActionTypes.DEAUTHENTICATE;
}

export class PreventAuthOnResumeAction implements Action {
  readonly type = WalletActionTypes.PREVENT_AUTH_ON_RESUME;
  constructor(readonly payload: { preventLogout: boolean, preventAuthDuration?: number }) { }
}

export class AddUnconfirmedTransactionAction implements Action {
  readonly type = WalletActionTypes.ADD_UNCONFIRMED_TX;
  constructor(readonly payload: { transaction: UnconfirmedTransaction }) { }
}

export class SetUnconfirmedCancelledTransactionAction implements Action {
  readonly type = WalletActionTypes.SET_UNCONFIRMED_TX_CANCELLED;
  constructor(readonly payload: { transaction: string[] }) { }
}

export class RemoveUnconfirmedTransactionsAction implements Action {
  readonly type = WalletActionTypes.REMOVE_UNCONFIRMED_TX;
  constructor(readonly payload: { transactionIds: string[] }) { }
}

export class ZdagConfirmedTransactionAction implements Action {
  readonly type = WalletActionTypes.ZDAG_CONFIRMED_TX;
  constructor(readonly payload: ZdagTransactionAction) { }
}

export class ZdagErrorTransactionAction implements Action {
  readonly type = WalletActionTypes.ZDAG_ERROR_TX;
  constructor(readonly payload: ZdagTransactionAction) { }
}

export class ClearTxHistory implements Action {
  readonly type = WalletActionTypes.CLEAR_TX_HISTORY;
  constructor(readonly payload?: { keyringId: string }) { }
}

export class NewConfirmedTxReceived implements Action {
  readonly type = WalletActionTypes.NEW_CONFIRMED_TX_RECEIVED;
  constructor(readonly payload: { keyringId: string }) { }
}

export class GetTokenFiatValuesRequestAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_FIAT_VALUES_REQUEST;
  constructor(readonly payload: { symbols?: string[] } = null) { }
}

export class GetTokenFiatValuesSuccessAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_FIAT_VALUES_SUCCESS;
  constructor(readonly payload: TokenFiatValues = null) { }
}

export class GetTokenFiatValuesFailedAction implements Action {
  readonly type = WalletActionTypes.GET_TOKEN_FIAT_VALUES_FAILED;
  constructor(readonly payload: { error: any } = null) { }
}

export class GetFeePerAssetTxRequestAction implements Action {
  readonly type = WalletActionTypes.GET_FEE_PER_ASSET_TX_REQUEST;
  constructor() { }
}

export class GetFeePerAssetTxSuccessAction implements Action {
  readonly type = WalletActionTypes.GET_FEE_PER_ASSET_TX_SUCCESS;
  constructor(readonly payload: { fee: number } = null) { }
}

export class GetFeePerAssetTxFailedAction implements Action {
  readonly type = WalletActionTypes.GET_FEE_PER_ASSET_TX_FAILED;
  constructor(readonly payload: { error: any } = null) { }
}

export class SetTokenRetailFiatValuesAction implements Action {
  readonly type = WalletActionTypes.SET_TOKEN_RETAIL_FIAT_VALUES;
  constructor(readonly payload: { [key: string]: number } = null) { }
}

export class AddTradeOrdersAction implements Action {
  readonly type = WalletActionTypes.ADD_TRADE_ORDERS;
  constructor(readonly payload: { data: any[], pageNumber: number, pageSize: number, loadedAll: boolean }) { }
}

export class GetTradeOrdersAction implements Action {
  readonly type = WalletActionTypes.GET_TRADE_ORDERS;
  constructor(readonly initialCall: boolean = false) { }
}

export class RemoveTradeOrdersAction implements Action {
  readonly type = WalletActionTypes.REMOVE_TRADE_ORDERS;
  constructor(readonly order: {}) { }
}

export class AddTradeOrdersHistoryAction implements Action {
  readonly type = WalletActionTypes.ADD_TRADE_ORDERS_HISTORY;
  constructor(readonly payload: { data: any[], pageNumber: number, pageSize: number, loadedAll: boolean }) { }
}

export class GetTradeOrdersHistoryAction implements Action {
  readonly type = WalletActionTypes.GET_TRADE_ORDERS_HISTORY;
  constructor(readonly initialCall: boolean = false) { }
}

export class AddPurchaseHistoryAction implements Action {
  readonly type = WalletActionTypes.ADD_PURCHASE_HISTORY;
  constructor(readonly payload: { purchaseHistory: any[] }) { }
}

export class GetPurchaseHistoryAction implements Action {
  readonly type = WalletActionTypes.GET_PURCHASE_HISTORY;
  constructor() { }
}


export class GetPTTokens implements Action {
  readonly type = WalletActionTypes.GET_PT_TOKENS;
  constructor() { }
}

export class ResetWalletDataAction implements Action {
  readonly type = WalletActionTypes.RESET_WALLET_DATA;
  constructor() { }
}

export class AddERC20TokensAction implements Action {
  readonly type = WalletActionTypes.ADD_ERC20_TOKENS;
  constructor(readonly payload: { erc20TokenList: ERC20tokenData[] }) { }
}

export class GetERC20TokensAction implements Action {
  readonly type = WalletActionTypes.GET_ERC20_TOKENS;
  constructor() { }
}

export class HideERC20TokensAction implements Action {
  readonly type = WalletActionTypes.HIDE_ERC20_TOKENS;
  constructor(readonly payload: { keyringId: string }) { }
}
