import { Action } from "@ngrx/store";
import { PurchaseOrderState } from "../store/purchaseOrder.state";
import { SingularityActionTypes, SingularityInitNewPurchaseRequest, SingularitySyncAssetInfoAction } from "../actions/singularityPayment.action";

export const initialPurchaseOrder = {
  pendingOrder: null,
  initSingularityPayment: false,
  singularityAssets: [],
  isSingularityPaymentReady: false,
} 

export function purchaseOrderReducer(state: PurchaseOrderState = initialPurchaseOrder, action: Action): PurchaseOrderState {
  let payload;
  switch (action.type) {
    case SingularityActionTypes.SINGULARITY_INIT_REQUEST_SUCCESS:
      return {...state, initSingularityPayment: true}; 
    case SingularityActionTypes.SINGULARITY_SYNC_ASSETS_INFO:
      payload = (action as SingularitySyncAssetInfoAction).payload;
      return {...state, singularityAssets: payload.singularityAssets};
    case SingularityActionTypes.SINGULARITY_INIT_PURCHASE_ORDER_REQUEST:
      payload = (action as SingularityInitNewPurchaseRequest).payload;
      return {...state, pendingOrder: payload.pendingOrder};
    case SingularityActionTypes.SINGULARITY_USER_LOGIN_SUCCESS:
      return {...state, isSingularityPaymentReady: true};
    case SingularityActionTypes.SINGULARITY_USER_LOG_OUT_FROM_SINGULARITY_DAPP:
    return {...state, isSingularityPaymentReady: false};
    default:
      return state;
  }
}
