import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { AppState } from "../store/appState";
import { getJwtToken } from "../store/userPreferences";
import { EXTERNAL_APIS } from "../constants";
import { AlertController } from "@ionic/angular";

import { take } from "rxjs/operators";
import { Logger } from "./logger.service";
import { environment } from "src/environments/environment";

type GroupName = "GROUP_ACH" | "GROUP_WALLET_CONNECT";
@Injectable({
  providedIn: "root",
})
export class ComplianceService {
  private baseApi = EXTERNAL_APIS.COMPLIANCE;
  private getKYCTokenUrl: string = `${this.baseApi}/kyc/start`;

  constructor(private http: HttpClient, private store: Store<AppState>, public alertController: AlertController) {}

  async getKYCToken(flowName?: string) {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const requestUrl = flowName ? `${this.getKYCTokenUrl}?flowName=${flowName}` : this.getKYCTokenUrl;
    const res = await this.http.get(requestUrl, { headers }).toPromise();

    return res as any;
  }

  async getKycCip(flowName?: string) {
    //https://lodeone.atlassian.net/browse/CORE-5842
    // disable KYC
    Logger.info(`kyc check feature flag: ${environment.features.kycCheck}`);
    if (!environment.features.kycCheck) {
      return {
        "passingLevel": true,
        "idDocs": [],
      }
    }
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    let url = `${this.baseApi}/kyc/cip`;
    if (flowName) {
      url += `/${flowName}`;
    }
    const res = await this.http.get(url, { headers }).toPromise();
    Logger.info("getKycAch", res);
    return res as any;
  }

  async verifyIfKYCIsCompleted(flowName?: string): Promise<any> {
    let res: any;
    try {
      res = await this.getKYCToken(flowName);
    } catch (err) {
      Logger.error(err);
      throw err;
    }
    Logger.info("verifyIfKYCIsCompleted", res);
    //check if review answer is good
    if (!res?.missingLevel && res?.passingLevel) {
      return true;
    } else {
      return false;
    }
  }

  async createAccessToken() {
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const res: any = await this.http.get(`${this.baseApi}/kyc/create-access-token`, { headers }).toPromise();
    Logger.info("createAccessToken", res);

    return res;
  }

  async getUsCitizen() {
    const kycData = await this.getKycCip("BasicDocumentSet");
    Logger.info("getUsCitizen kycData", kycData);
    if (kycData?.idDocs?.find((doc) => doc.idDocType === "RESIDENCE_PERMIT")?.country === "USA") {
      return true;
    } else {
      return false;
    }
  }

  async isAuthGroup(groupName: GroupName) {
    const jwtDecode = require('jwt-decode');
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const decodedJwt = jwtDecode(jwt);
    return decodedJwt?.groups.includes(groupName);
  }
}
