import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AUTH_DATA_KEY, AUTH_REASON, SKIP_INTRO_PAGE_KEY, WALLET_STORAGE_KEY } from '../constants';
import { StorageService } from './storage.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { getAuthenticated, getPreventAuthInfo, getQueryCount } from '../store/wallet';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { PreventAuthInfo } from 'src/app/global';
import { Logger } from './logger.service';
import { AuthenticateAction } from '../actions/wallet.actions';
import { ChangeActivePage, ShowSideMenu } from '../actions/appSettings.actions';

@Injectable()
export class NonWalletGuardService implements CanActivate {
  private authenticated: boolean = false;
  private queryCount: number;
  private preventInfo: PreventAuthInfo;
  private showSideMenu = [
    '/home-page/first-time'
  ];
  private appLinks = [
    'home-page'
  ];
  constructor(
    private storage: StorageService,
    private nav: NavController,
    private store: Store<AppState>,
    private authService: AuthService,
  ) {

  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const url = state.url;
    this.authenticated = await this.store.select(getAuthenticated).pipe(take(1)).toPromise();
    this.preventInfo = await this.store.select(getPreventAuthInfo).pipe(take(1)).toPromise();
    this.store.select(getQueryCount).subscribe(count => this.queryCount = count);

    // check the wallet is Activated
    const checkWallet = await this.storage.get(WALLET_STORAGE_KEY);
    const authData = await this.storage.get(AUTH_DATA_KEY);
    if (!this.authenticated) {
      if (this.queryCount === 0) { // first time login
        // Logger.info('guard first login');
        await this.authService.openAuthPinModal();
        // await this.nav.navigateForward(['/auth'], { skipLocationChange: true });
      } else if (!this.preventInfo.preventAuthOnResume || this.preventAuthOnResumeTimedOut()) { // relogin
        // Logger.info('guard reauth', route.url.join(''));
        await this.authService.openAuthPinModal(AUTH_REASON.RE_AUTHENTICATE);
        // await this.nav.navigateForward(['/auth', AUTH_REASON.RE_AUTHENTICATE], { skipLocationChange: true, queryParams: { url } });
      }
    }

    if (!authData) {
      await this.nav.navigateRoot('setup/intro');
    } else {
      if (checkWallet) {
        return false;
      }
    }
    // Show side menu based on url
    if (authData && this.showSideMenu.some(text => url.includes(text))) {
      this.store.dispatch(new ShowSideMenu(true));
    } else {
      this.store.dispatch(new ShowSideMenu(false));
    }
    // TODO: hightlight active menu
    this.appLinks.map(menu => {
      if (url.includes(menu)) {
        this.store.dispatch(new ChangeActivePage(menu));
      }
    });
    return true;
  }
  private preventAuthOnResumeTimedOut() {
    Logger.info('preventAuthOnResumeTimedOut check', this.preventInfo);
    if (this.preventInfo.unauthenticatedTime && this.preventInfo.preventAuthDuration > 0) {
      const preventAuthDurationMs = this.preventInfo.preventAuthDuration * 1000;
      Logger.info('unauth time:', this.preventInfo.unauthenticatedTime);
      Logger.info('unauth prevent auth duration:', this.preventInfo.preventAuthDuration);
      const unauthDuration = (Date.now() - this.preventInfo.unauthenticatedTime);
      Logger.info('unauth duration', unauthDuration);
      if (unauthDuration >= preventAuthDurationMs) {
        return true;
      } else {
        this.store.dispatch(new AuthenticateAction());
        return false;
      }
    }
    return false;
  }
}
