import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './store/appState';
import { PriceOracleService } from './services/price-oracle.service';

@Component({
  template: `SubscriptionListenerComponent`
})
export class SubscriptionListenerComponent implements OnDestroy {

  protected subscriptions = new Subscription();
  protected destroyed$ = new Subject<boolean>();

  constructor(protected store?: Store<AppState>) {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
