import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
import { USER_PREFERENCES_STORAGE_KEY, WALLET_STORAGE_KEY } from '../constants';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { inspect } from 'util';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RpcProvider } from './rpc-provider';
import { SmallToCryptoPipe } from '../pipes/smalltocrypto/smalltocrypto.pipe';
import { Wallet } from 'ethers';
import { isNil } from '../ts-util';
import {
  BaseChainSymbol,
  BaseChainSymbolLowerCase,
  DefaultNetworks,
  NetworkId,
  StrictBaseChainSymbolLowerCase,
} from 'src/app/global';
import { Explorer } from '../../../environments/environment.interface';
import { getUnlockKeychain } from '../lib/pangolin/keyring/keychainUtils';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

   private cache: { [key: string]: Explorer } = {};
  private cacheExpiry: { [key: string]: number } = {};
  constructor(protected storage: StorageService, 
    protected http: HttpClient,
    protected rpcProvider: RpcProvider,
    protected smallToCryptoPipe: SmallToCryptoPipe) {
  }

  static get defaultNetworks(): Omit<DefaultNetworks, 'hl' | 'pt'> {
    return {
      sys: {
        network: environment.production ? 'sys-main' : 'sys-test'
      },
      sysnevm: {
        network: environment.production ? 'sysnevm-main' : 'sysnevm-test'
      },
      eth: {
        network: environment.production ? 'eth-main' : 'eth-sepolia'
      },
      btc: {
        network: environment.production ? 'btc-main' : 'btc-test'
      },
      avax: {
        network: environment.production ? 'avax-main' : 'avax-test'
      },
      sol: {
        network: environment.production ? 'sol-main' : 'sol-test'
      },
    }
  }

  getNetworks(): Explorer[] {
    return environment.EXTERNAL_APIS.EXPLORERS;
  }

  async getNetworkIdByChain(chain: BaseChainSymbolLowerCase) {
    if (!chain) {
      return;
    }
    await this.checkNetworkStorageFormat();
    const defaultNetworks = NetworkService.defaultNetworks;
    try {
      const networks = environment?.features?.networkSwitcher
        ? JSON.parse(await this.storage.get(USER_PREFERENCES_STORAGE_KEY) || '{}')
        : defaultNetworks;
      return networks?.[chain] ? networks?.[chain]?.network : defaultNetworks[chain]?.network;
    } catch (err) {
      Logger.error('getNetworkIdByChain: ', inspect(err), inspect(chain));
      return defaultNetworks[chain].network;
    }
  }


  async getNetworkById(id: NetworkId): Promise<Explorer> {
    return this.getNetworks().find((network) => network.id === id);
  }

  async getActiveNetwork(symbol: BaseChainSymbol): Promise<Explorer> {
    symbol = symbol.toLowerCase() as StrictBaseChainSymbolLowerCase;
    const networkId: NetworkId = await this.getNetworkIdByChain(symbol);
    return this.getNetworkById(networkId);
  }

  async getActiveSysNetwork(): Promise<Explorer> {
    return await this.getActiveNetwork('sys');
  }

  async getActiveSolNetwork(): Promise<Explorer> {
    return await this.getActiveNetwork('sol');
  }

  async getActiveEthNetwork(): Promise<Explorer> {
    return await this.getActiveNetwork('eth');
  }

  async getActiveAvaxNetwork(): Promise<Explorer> {
    return await this.getActiveNetwork('avax');
  }

  async getActiveBtcNetwork(): Promise<Explorer> {
    return await this.getActiveNetwork('btc');
  }

  async getActiveSysnevmNetwork(): Promise<Explorer> {
    return await this.getActiveNetwork('sysnevm');
  }

  async checkNetworkStorageFormat() {
    try {
      const prefs = JSON.parse((await this.storage.get(USER_PREFERENCES_STORAGE_KEY)) || '{}');

      if (prefs.sys && typeof prefs.sys.network === 'number') {
        prefs.sys = NetworkService.defaultNetworks.sys;
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
      }

      if (prefs.eth && (typeof prefs.eth.network === 'number' || prefs?.eth?.network === 'eth-rinkeby')) {
        prefs.eth = NetworkService.defaultNetworks.eth;
        await this.storage.set(USER_PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
      }
    } catch (e) {
      Logger.error('checkNetworkStorageFormat', inspect(e));
    }
  }

  protected async getEtherScanApi(): Promise<string> {
    const network = await this.getActiveEthNetwork();
    return `${network.ETHERSCAN_URL}`;
  }

  protected async checkEtherScanStatus(): Promise<boolean> {
    try {
      const etherScanUrl = await this.getEtherScanApi() + '/api?module=stats&action=ethsupply&apikey=' + environment.ETHERSCAN_API_KEY;
      await this.http.get(etherScanUrl).toPromise();
      return true;
    } catch (err) {
      Logger.error('checkEtherScanStatus', inspect(err));
      return false;
    }
  }

  public async checkEthRPCService(): Promise<boolean> {
    try {
      const  ethNetwork = await this.getActiveEthNetwork();
      await this.rpcProvider.rpc(ethNetwork.URL, 'eth_chainId', []).toPromise();
      return true;
    } catch (err) {
      Logger.error('checkEthRPCService', inspect(err));
      return false;
    }
  }

  public async checkBtcService() : Promise<boolean> {
    try {
      const  bitcoinNetwork = await this.getActiveBtcNetwork();
      const optionHeaders = new HttpHeaders({"api-key" : bitcoinNetwork.nowNodeAPIKey});
      let btcStatus: any = await this.http.get(bitcoinNetwork.URL, {headers: optionHeaders}).toPromise();
      Logger.info('btcStatus', btcStatus);
      return !isNil(btcStatus) && btcStatus?.blockbook?.inSync ? true : false;
    } catch (err) {
      Logger.error('checkEthRPCService', inspect(err));
      return false;
    }
  }

  

  async checkRpcAvalancheService() {
    try {
      const avaxNetwork = await this.getActiveAvaxNetwork();
      await this.rpcProvider.rpc(avaxNetwork.RPC_URL, 'eth_chainId', []).toPromise()
      return true;
    } catch (err) {
      Logger.error('checkRpcAvalancheService', inspect(err));
      return false;
    }
  }

  async getBTCFeeEstimate() {
    const transactionSize = 300;
    const networkFee = {
      slow:   this.smallToCryptoPipe.transform(10289*transactionSize/1000 , { type: 'BTC' }).toFixed(8),
      standard: this.smallToCryptoPipe.transform(17554*transactionSize/1000 , { type: 'BTC' }).toFixed(8),
      fast:  this.smallToCryptoPipe.transform(39648*transactionSize/1000 , { type: 'BTC' }).toFixed(8),
    }
    try {
      const network = await this.getActiveBtcNetwork();
      const response: any = await this.http.get(`${network.URL_2}`).toPromise();
      //estimate transaction size for 1 input and two output is 300 bytes
      networkFee.slow = this.smallToCryptoPipe.transform(response.low_fee_per_kb*transactionSize/1000 , { type: 'BTC' }).toFixed(8);
      networkFee.standard = this.smallToCryptoPipe.transform(response.medium_fee_per_kb*transactionSize/1000 , { type: 'BTC' }).toFixed(8)
      networkFee.fast = this.smallToCryptoPipe.transform(response.high_fee_per_kb*transactionSize/1000 , { type: 'BTC' }).toFixed(8)
      
    } catch (e) {
      Logger.error('getBTCFeeEstimate', inspect(e));
    }
    return networkFee;
  }

  async getWalletByHDPath(pin: any, hdPath: string) {
   const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getUnlockKeychain(vault, pin);
    Logger.info('Mnemonic', keychain.keyrings[0].mnemonic);
    
    // TODO: Eric need to fix this hd path
    return Wallet.fromMnemonic(keychain.keyrings[0].mnemonic, hdPath);
  }

}
