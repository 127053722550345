import { Action } from '@ngrx/store';
import { SetupState } from '../store/setup';
import { SetPinAction, SetSetupMnemonicAction, SetupActionTypes, SetOldPinAction } from '../actions/setup.actions';

export const initialSetupState = {};

export function setupReducer(state: SetupState = initialSetupState, action: Action): SetupState {
  let payload;
  switch (action.type) {
    case SetupActionTypes.SET_PIN:
      payload = (action as SetPinAction).payload;
      return { ...state, pin: payload.pin };

    case SetupActionTypes.CLEAR_PIN:
      return { ...state, pin: '' };

    case SetupActionTypes.SET_SETUP_MNEMONIC:
      payload = (action as SetSetupMnemonicAction).payload;
      return { ...state, mnemonic: payload.mnemonic };

    case SetupActionTypes.CLEAR_SETUP_MNEMONIC:
      return { ...state, mnemonic: '' };

    case SetupActionTypes.SET_OLD_PIN:
      payload = (action as SetOldPinAction).payload;
      return { ...state, oldPin: payload.pin };

    case SetupActionTypes.COMPLETE_SETUP:
      // this should purge the setup state entirely
      return {};

    case SetupActionTypes.RESET_SETUP_DATA:
      return { ...initialSetupState };

    default:
      return state;
  }
}
