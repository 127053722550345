import { Injectable } from '@angular/core';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

declare module '@ionic-native/fingerprint-aio/ngx' {
  interface FingerprintAIO {
    isAvailable(successCallback?, errorCallback?): Promise<any>;
    show(obj: any);
  }
}

const CLIENT_ID = 'CLIENT_ID';
const CLIENT_SECRET = 'CLIENT_SECRET';
const PIN_STORAGE_KEY = 'WALLET_PIN';
const IS_ACTIVE_STORAGE_KEY = 'IS_ACTIVE_STORAGE_KEY';
const BIOMETRIC_NOT_AVAILABLE = 'BIOMETRIC_NOT_AVAILABLE';
const BIOMETRIC_ACTIVATE_ERROR = 'BIOMETRIC_ACTIVATE_ERROR';
const BIOMETRIC_DEACTIVATE_ERROR = 'BIOMETRIC_DEACTIVATE_ERROR';

@Injectable({
  providedIn: 'root'
})
export class BiometricAuthService {
  secureStorage: any = {};

  constructor(private faio: FingerprintAIO) { }

  startBiometric() {
    if (!window.hasOwnProperty('cordova')) {
      this.secureStorage = {};
    } else {
      this.secureStorage = ((window as any).cordova.plugins as any).SecureKeyStore;
    }
  }

  protected setToStorage(key: string, value: string) {
    return new Promise((resolve, reject) => {
      this.secureStorage.set(
        () => resolve(true),
        err => reject(err),
        key, value
      );
    });
  }

  protected getFromStorage(key: string) {
    return new Promise((resolve, reject) => {
      this.secureStorage.get(
        value => resolve(value),
        err => reject(err),
        key
      );
    });
  }

  protected removeFromStorage(key: string) {
    return new Promise((resolve, reject) => {
      this.secureStorage.remove(
        value => resolve(value),
        err => reject(err),
        key
      );
    });
  }

  public async isAvailable() {
    let method;
    try {
      method = await this.getAuthenticationMethod();
    } catch (err) {
      if (err.message === 'Fingerprint authentication not ready') {
        return true;
      }
      return false;
    }

    return method !== 'none';
  }

  public async getAuthenticationMethod() {
    return new Promise((resolve, reject) => {
      if (!window.hasOwnProperty('cordova')) {
        return reject('Running on browser');
      }
      this.faio.isAvailable(type => resolve(type), err => reject(err));
    });
  }

  public showFingerprint() {
    return this.faio.show({
      clientId: CLIENT_ID,
      clientSecret: CLIENT_SECRET
    });
  }

  protected async setPin(pin: string) {
    try {
      await this.setToStorage(PIN_STORAGE_KEY, pin);
    } catch (err) {
      return false;
    }

    return true;
  }

  public async getPin() {
    let data;

    try {
      await this.showFingerprint();
    } catch (err) {
      throw err;
    }

    try {
      data = await this.getFromStorage(PIN_STORAGE_KEY);
    } catch (err) {
      throw err;
    }

    return data;
  }

  public async isActive() {
    const isAvailable = await this.isAvailable();

    try {
      await this.getFromStorage(IS_ACTIVE_STORAGE_KEY);
      await this.getAuthenticationMethod();
    } catch (err) {
      if (window.hasOwnProperty('cordova')) {
        await this.removeFromStorage(IS_ACTIVE_STORAGE_KEY);
        await this.removeFromStorage(PIN_STORAGE_KEY);
      }
      return false;
    }

    return isAvailable;
  }

  public async shouldActive() {
    try {
      await this.getAuthenticationMethod();
    } catch (err) {
      return false;
    }

    return true;
  }

  public async activateBiometric(pin: string) {
    const isBiometricAvailable = await this.isAvailable();

    if (!isBiometricAvailable) {
      throw new Error(BIOMETRIC_NOT_AVAILABLE);
    }

    try {
      await this.showFingerprint();
    } catch (err) {
      throw new Error(BIOMETRIC_ACTIVATE_ERROR);
    }

    try {
      await this.setPin(pin);
      await this.setToStorage(IS_ACTIVE_STORAGE_KEY, 'active');
    } catch (err) {
      throw new Error(BIOMETRIC_ACTIVATE_ERROR);
    }

    return true;
  }

  public async deactivateBiometric() {
    const isBiometricAvailable = await this.isAvailable();

    if (!isBiometricAvailable) {
      throw new Error(BIOMETRIC_NOT_AVAILABLE);
    }

    try {
      await this.showFingerprint();
    } catch (err) {
      throw new Error(BIOMETRIC_DEACTIVATE_ERROR);
    }

    try {
      await this.removeFromStorage(PIN_STORAGE_KEY);
      await this.removeFromStorage(IS_ACTIVE_STORAGE_KEY);
    } catch (err) {
      throw new Error(BIOMETRIC_DEACTIVATE_ERROR);
    }

    return true;
  }

}
