import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from './logger.service';
import { EXTERNAL_APIS } from '../constants';
import { timeout } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { SetServiceStatusAction } from '../actions/connection.actions';

@Injectable({
  providedIn: 'root'
})
export class HlAgxBalanceService {
  private apiBaseUrl: string = EXTERNAL_APIS.MEMBERS_PORTAL;
  private balanceUrl: string = `${this.apiBaseUrl}/getGeneralContext`;
  private hlBalancesUrl: string = `${this.apiBaseUrl}/getHlfBalances`;

  constructor(private http: HttpClient, private store: Store<AppState>) {
  }

  async getAgxBalance(jwt) {
    if (jwt) {
      try {
        // switch the last two lines to promise.all
        const generalContextRequest = this.http.post(`${this.balanceUrl}?token=${jwt}`, {}).toPromise();
        const balancesRequest = this.http.post(`${this.hlBalancesUrl}?token=${jwt}`, {}).toPromise();
        let [generalContext, balances]: [any, any] = await Promise.all([generalContextRequest, balancesRequest]);

        const totalBalance = generalContext?.totalAgxG;
        const confirmedBalance = Number(balances?.agxBalanceHL?.balance);
        const pendingBalance = totalBalance ? totalBalance.toString() : null;
        generalContext = {
          balance: confirmedBalance ? Number(confirmedBalance.toFixed(2)) : 0,
          pendingBalance: pendingBalance ? Number(pendingBalance.slice(0, pendingBalance.indexOf('.') + 4)) : 0
        };
        this.setServiceStatusInStore('memberPortal', true);
        return generalContext;
      } catch (e) {
        Logger.error('agx balance failed to respond', e);
        this.setServiceStatusInStore('memberPortal', false);
        return { balance: 0, pendingBalance: 0 };
      }
    }

    throw new Error('no JWT available');
  }

  async getAgxPrice() {
    return { fiatValue: 10 };
  }

  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }
}
