import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ImagePreloaderModule } from '../../angular-wallet-base/directives/image-preloader/image-preloader.module';
import { CaptureEmailModalComponent } from './capture-email-modal.component';
import { CaptureEmailFormModule } from '../capture-email-form/capture-email-form.module';
import { CaptureEmailFormComponent } from '../capture-email-form/capture-email-form.component';

@NgModule({
  declarations: [CaptureEmailModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    ImagePreloaderModule,
    FormsModule,
    ReactiveFormsModule,
    CaptureEmailFormModule
  ]
})
export class CaptureEmailModalModule {}
