import { createSelector } from '@ngrx/store';
import { getSetupState } from './appState';

export interface SetupState {
  readonly pin?: string;
  readonly mnemonic?: string;
  readonly oldPin?: string;
}

export const getSetupPin = createSelector(
  getSetupState,
  (state: SetupState) => state.pin
);

export const getSetupMnemonic = createSelector(
  getSetupState,
  (state: SetupState) => state.mnemonic
);

export const getOldPin = createSelector(
  getSetupState,
  (state: SetupState) => state.oldPin
);
