import { Component, NgZone, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { IonRouterOutlet, MenuController, ModalController, NavController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { ActionSheetController, AlertController, Config } from '@ionic/angular';
import { LanguageService } from './angular-wallet-base/services/language.service';
import { WalletService } from './angular-wallet-base/services/wallet.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Logger } from './angular-wallet-base/services/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from './angular-wallet-base/store/appState';
import { ChangeSecondatyTitleAction, ChangeTitleAction, ForceUpgradeAction, ShowSideMenu, UseI18nOnTitleAction } from './angular-wallet-base/actions/appSettings.actions';
import { DeauthenticateAction } from './angular-wallet-base/actions/wallet.actions';
import { WalletGuardService } from './angular-wallet-base/services/walletguard.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { TokenDefinitionService } from './angular-wallet-base/services/token-definition.service';
import { EXTERNAL_APIS, PREVENT_2FA_REAUTH_TIMEOUT, SUPPORTED_ASSETS, USER_PREFERENCES_STORAGE_KEY, WALLET_CONNECT_LAST_SIGNATURE } from './angular-wallet-base/constants';
import { HttpClient } from '@angular/common/http';
import { UpdateRequiredModalComponent } from './components/update-required-modal/update-required-modal.component';
import { RemoteVersionInfo } from './global';
import { environment } from 'src/environments/environment';
import { CodePush } from '@ionic-native/code-push/ngx';
import { getAuthenticated, getQueryCount } from './angular-wallet-base/store/wallet';
import { getToastPosition, mapUrlToLabel, compareAppVersion } from './angular-wallet-base/utils';
import { AppModeService } from './angular-wallet-base/services/app-mode.service';
import { AnalyticsTrackerService } from './angular-wallet-base/services/analytics-tracker.service';
import { ServiceCheckerService } from 'src/app/angular-wallet-base/services/service-check.service';
import { StorageService } from 'src/app/angular-wallet-base/services/storage.service';
import { BiometricAuthService } from './angular-wallet-base/services/biometric-auth.service';
import { File } from '@ionic-native/file/ngx';
import { getSideMenuShowStatus } from './angular-wallet-base/store/appSettings';
import { AuthService } from './angular-wallet-base/services/auth.service';
import { SetCredentialsAction } from './angular-wallet-base/actions/accountSetup.actions';
import { CryptoTransferService } from './angular-wallet-base/services/crypto-transfer.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { getWalletConnectApprovalSignatures } from './angular-wallet-base/store/walletConnect';
import { isNil } from './angular-wallet-base/ts-util';
import { TokenManagerService } from './angular-wallet-base/services/token-manager.service';
import { StorageSyncActions } from './angular-wallet-base/lib/ngrx-store-ionic-storage';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  // set up hardware back button event.
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  preventBack = ['/wallet/assets'];
  public isProduction;
  private modal = null;
  private codePushModal = null;
  public isAuthenticated = false;
  public showSideMenu = false;
  public isDesktopMode;
  public appPauseTime = null;
  public online: boolean = true;
  public scrollBar = false;
  public connectionAlert = false;

  constructor(
    private platform: Platform,
    public modalCtrl: ModalController,
    private menu: MenuController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private router: Router,
    private toastController: ToastController,
    private languageService: LanguageService,
    private wallet: WalletService,
    private authService: AuthService,
    private translate: TranslateService,
    private alertController: AlertController,
    private walletguard: WalletGuardService,
    private activeRoute: ActivatedRoute,
    private store: Store<AppState>,
    private nav: NavController,
    private zone: NgZone,
    private config: Config,
    private screenOrientation: ScreenOrientation,
    private tokenDef: TokenDefinitionService,
    private http: HttpClient,
    private splashScreen: SplashScreen,
    private codePush: CodePush,
    private tracker: AnalyticsTrackerService,
    private checkerService: ServiceCheckerService,
    private storage: StorageService,
    private bauth: BiometricAuthService,
    private appMode: AppModeService,
    private file: File,
    private cryptoTransferService: CryptoTransferService,
    private network: Network,
    private tokenService: TokenManagerService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    // dont change, since it wll affect the siedmenu UI
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
    this.platform.ready().then(async () => {
      // CHECK CONNECTION
      this.network.onDisconnect().subscribe(() => {
        Logger.info('disconnected');
        this.checkConnection();
      });
      this.network.onConnect().subscribe(() => {
        Logger.info('connected');
        this.checkConnection();
      });
      await this.storage.init();
      //dispatch action to hydrate syncIonicStorage
      this.store.dispatch({ type: StorageSyncActions.HYDRATE });
      this.tracker.startAnalytics();
      this.bauth.startBiometric();
      // changed querycount since its based on wallet status (we need logged in status)
      this.store.select(getAuthenticated).subscribe(status => {
        this.isAuthenticated = status;
      });

      this.store.select(getSideMenuShowStatus).subscribe(status => {
        this.showSideMenu = status;
      });

      this.splashScreen.show();
      // this.isProduction = environment.production;

      // this.config.set('backButtonText', await this.translate.get('btn_back').toPromise());
      await this.languageService.setInitialAppLanguage();

      if (await this.isLatestVersion()) {
        this.backButtonEvent();
      }

      // update storage with supported assets if not set
      const supportedAssets = await this.tokenService.getSupportedTokensList();
      if (isNil(supportedAssets)) {
        await this.tokenService.setSupportedTokenList();
      }
      await this.tokenDef.mergeTokenDefinitions(); // update token definitions with storage data

      await this.authService.loadUserFromStorage();

      this.platform.pause.subscribe(this.onPause.bind(this));
      this.platform.resume.subscribe(this.onResume.bind(this));

      if (this.platform.is('mobile')) {
        try {
          await this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
          await this.checkCodePushUpdate();
        } catch (e) {
          Logger.error('Error locking orientation. Must not be in Cordova context.');
        }
      }
      this.subscribeToRouteChange();

      const hasEnoughSpace = await this.storage.deviceHasEnoughSpace();

      if (!hasEnoughSpace) {
        this.showNotEnoughSpaceAlert();
      }
      
      //subscribe to wallet-connect notifications to show them as a toast
      this.store.select(getWalletConnectApprovalSignatures).subscribe(async (signatures) => {
        if (signatures && signatures.length > 0) {
          if((await this.storage.get(WALLET_CONNECT_LAST_SIGNATURE)) !== signatures[signatures.length - 1].signature) {
            await this.storage.set(WALLET_CONNECT_LAST_SIGNATURE, signatures[signatures.length - 1].signature);
            //show latest signature
            await this.presentToast('Wallet Connect TX Completed: ' + signatures[signatures.length - 1].signature);
          }
        }
      }
      );
    });
  }

  // NETWORK CONNECTION
  checkConnection() {
    const type = this.network.type;
    if (type === 'unknown' || type === 'none' || type === undefined) {
      this.online = false;
      this.networkConnection(0);
    } else {
      this.online = true;
      this.networkConnection(1);
    }
  }

  async networkConnection(status) {
    let title = await this.translate.get('internet_connection.title').toPromise();
    let subHeader = await this.translate.get('internet_connection.sub_header').toPromise();
    if (title.includes('MISSING')) {
      title = 'No Internet Connection';
      subHeader = 'You are not connected to the internet. Make sure Wi-Fi/Mobile Data is on, Airplane Mode is off and try again';
    }
    if (status === 0 && !this.connectionAlert) {
      this.connectionAlert = true;
      const confirm = await this.alertController.create({
        cssClass: 'index-adjust-alert',
        header: title,
        subHeader,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Retry',
            handler: () => {
              this.connectionAlert = false;
              this.checkConnection();
            }
          }
        ]
      });
      await confirm.present();
      await confirm.onDidDismiss();
    }
  }

  async showNotEnoughSpaceAlert() {
    const modal = await this.alertController.create({
      header: await this.translate.get('low_space.title').toPromise(),
      message: await this.translate.get('low_space.message').toPromise()
    });

    await modal.present();
  }

  subscribeToRouteChange() {
    this.router.events.subscribe((ev) => {
      // for show/hide the scrollbar wrt side menu
      if (['/vault-intro', '/edit-profile'].includes(this.router.url)) {
        this.scrollBar = true;
      } else {
        this.scrollBar = false;
      }
      if (ev instanceof NavigationEnd) {
        this.onRouteChange(ev);
      }
      if (ev instanceof NavigationStart && ev.navigationTrigger === 'popstate') {
        const currentUrl = this.router.url;
        if (ev.url === '/setup/setup-pin/new' || ev.url === '/setup/setup-pin/recover') {
          this.nav.navigateRoot('setup/setup-home');
        }
        if (currentUrl !== '/setup/setup-recovery-phrase' && ev.url === '/setup/confirm-recovery-phrase') {
          this.nav.navigateRoot('setup/setup-capture-email', { queryParams: { skip: true } });
        }
        if (currentUrl === '/wallet/assets' && (ev.url.indexOf('/setup/setup-capture-email') !== -1 || ev.url.indexOf('/setup/enter-recovery-phrase/recover') !== -1)) {
          this.nav.navigateRoot('wallet/assets');
        }
      }
    });
  }

  onRouteChange(e) {
    const pageTitle = mapUrlToLabel(e.url);
    if (pageTitle) {
      this.store.dispatch(new ChangeTitleAction(pageTitle));
      this.store.dispatch(new ChangeSecondatyTitleAction(''));
      this.store.dispatch(new UseI18nOnTitleAction(true));
    }
  }

  async checkCodePushUpdate() {
    return false;
    /*const remotePackage = await this.codePush.checkForUpdate();
    if (!remotePackage) {
      Logger.info('CODEPUSH: The app is up to date.');
    } else {
      this.codePushModal = await this.modalCtrl.create({
        component: CodePushModalComponent,
        componentProps: {
          IRemotePackage: remotePackage
        }
      });
      await this.codePushModal.present();
    }
    return false;*/
  }

  async onResume() {
    Logger.info('App resumed.', this.router.url);

    this.checkerService.closeCheckServicesInterval();
    const preventReAuthMs = PREVENT_2FA_REAUTH_TIMEOUT * 1000;
    if (this.isAuthenticated && ((Date.now() - this.appPauseTime) > preventReAuthMs)) {
      this.store.dispatch(new DeauthenticateAction());
    }

    if (this.router.url.includes('buy-assets/qr-code-page')) {
      this.cryptoTransferService.timeStamp.next(new Date().getTime());
    } else {
      this.cryptoTransferService.timeStamp.next(0);
    }

    await this.isLatestVersion();
    await this.checkCodePushUpdate();
    await this.zone.run(async () => {
      await this.nav.navigateForward(this.router.routerState.snapshot.url);
    });
  }

  async onPause() {
    Logger.info('App paused');
    this.checkerService.closeCheckServicesInterval();
    this.appPauseTime = new Date();
    if (!this.isAuthenticated) {
      this.store.dispatch(new DeauthenticateAction());
    }
  }

  async isLatestVersion() {
    if (!environment.features.versionCheck) {
      return true;
    }

    const remoteVersion = await this.http.get(EXTERNAL_APIS.VERSION_URL).toPromise() as RemoteVersionInfo;
    const packageJson = require('../../package.json');

    if (!remoteVersion) {
      Logger.info('Remote version fetch failed');
      return true; // let them proceed if we fail to fetch
    }
    this.store.dispatch(new ForceUpgradeAction(Boolean(remoteVersion.ongoing_upgrade)));
    if (Boolean(remoteVersion.ongoing_upgrade) || compareAppVersion(remoteVersion.latest_version, packageJson.version)) {
      Logger.info('Remote version greater than local;');
      Logger.info('local: ', parseFloat(packageJson.version), 'remote: ', parseFloat(remoteVersion.latest_version));
      this.modal = await this.modalCtrl.create({
        component: UpdateRequiredModalComponent,
        cssClass: 'fullscreen-modal',
        componentProps: {
          remoteVersion
        }
      });

      await this.modal.present();
      return false;
    }

    Logger.info('Remote less than or equal to local;');
    Logger.info('local: ', parseFloat(packageJson.version), 'remote: ', parseFloat(remoteVersion.latest_version));
    return true;
  }

  backButtonEvent() {
    document.addEventListener('backbutton', async () => {
      // close action sheet
      try {
        const element = await this.actionSheetCtrl.getTop();
        if (element) {
          Logger.info('closed action sheet');
          element.dismiss();
          return;
        }
      } catch (error) { }

      // close popover
      try {
        const element = await this.popoverCtrl.getTop();
        if (element) {
          Logger.info('closed popover');
          await this.popoverCtrl.dismiss();
          return;
        }
      } catch (error) { }

      // close alert
      try {
        const element = await this.alertController.getTop();
        if (element) {
          Logger.info('closed popover');
          await this.alertController.dismiss();
          return;
        }
      } catch (error) { }

      // close modal
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
          Logger.info('closed modal');
          await this.modalCtrl.dismiss();
          return;
        }
      } catch (error) { }

      // close side menu
      try {
        const isMenuOpen = await this.menu.isOpen('sideMenu');
        if (isMenuOpen) {
          Logger.info('closed side menu');
          await this.menu.close('sideMenu');
          return;
        }
      } catch (error) { }

      if (this.router.url === '/delete-account') {
        const event = new Event('del-nav');
        document.dispatchEvent(event);
        return false;
      }

      if (this.router.url.includes('/wallet/buy-tokens/crypto-payment')) {
        const event = new Event('crypto-nav');
        document.dispatchEvent(event);
        return false;
      }

      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if (outlet && outlet.canGoBack() && this.preventBack.indexOf(this.router.url) === -1) {
          await outlet.pop();
        } else if (this.router.url === '/wallet/assets') {
          const timeSinceLastPress = new Date().getTime() - this.lastTimeBackPress;
          Logger.info(timeSinceLastPress);
          if (timeSinceLastPress < this.timePeriodToExit) {
            navigator[`app`].exitApp(); // work in ionic 4
          } else {
            const toastMsg = await this.toastController.create({
              header: await this.translate.get('exit_toast.title').toPromise(),
              message: await this.translate.get('exit_toast.message').toPromise(),
              position: getToastPosition(this.platform),
              duration: 2000
            });
            toastMsg.present();
            this.lastTimeBackPress = new Date().getTime();
          }
        }
      });
    });
  }

  presentToast = async (msg: string) => {
    const toast = await this.toastController.create({
      // header: hdr,
      message: msg,
      position: getToastPosition(this.platform),
      duration: 4000
    });
    await toast.present();
  }

  handleWindowResize(event) {
    this.appMode.handleWindowResize(event);
  }

  ngOnDestroy() {
    this.checkerService.closeCheckServicesInterval();
  }
}
