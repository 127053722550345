import { Injectable } from '@angular/core';
import {
  pluck, take,
} from 'rxjs/operators';
import { EXTERNAL_APIS } from '../constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';
import { HTTP } from '@ionic-native/http/ngx';
import { NetworkService } from './network.service';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { Platform } from '@ionic/angular';
import { getJwtToken } from '../store/userPreferences';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CryptoTransferService {

  private url = EXTERNAL_APIS.MEMBERS_PORTAL;
  private getPriceQuoteUri: string = 'token/lode/getpricequote';
  private confirmQuoteUri: string = 'token/confirmquote';
  private confirmPaymentUri: string = 'paymentTxId';
  public timeStamp = new BehaviorSubject<number>(0);
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    public httpPlugin: HTTP,
    private platform: Platform,
    private store: Store<AppState>,
    private networkService: NetworkService
  ) { }


  public async getPriceQuoteTransaction(
    amount: string,
    cryptocurrency: string,
    promoCode: string = null,
    readOnly: string = '1',
    fiatcurrency: string = 'usd',
    asset:string,
    isTokenAmountChange?:boolean
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('currency', cryptocurrency);
    httpParams = httpParams.append('readonly', readOnly);
    if (promoCode) {
      httpParams = httpParams.append('promocode', promoCode);
    }
    httpParams = httpParams.append('paymentType', 'crypto');
    if(isTokenAmountChange){
      httpParams = httpParams.append('qty', amount);
    }else{
      httpParams = httpParams.append('fiatAmount', amount);
    }
    httpParams = httpParams.append('skip_auto_order', '1');
    httpParams = httpParams.append('fiatCurrency', fiatcurrency);

    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    const options = { params: httpParams, headers };
    return this.http.get(`${this.url}/token/${asset.toLocaleLowerCase()}/getpricequote`, options).toPromise();
  }

  public async confirmTransaction(quoteId: string,  address: string | null = null) {
    const formData = new FormData();
    formData.append('quote_id', quoteId.toString());
    formData.append('paymentType', 'crypto');
    formData.append('address', address)
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    return this.http.post(`${this.url}/${this.confirmQuoteUri}`, formData, { headers }).toPromise();
  }

  public async confirmPayment(quoteId: string,transactionId?:string) {
    const formData = new FormData();
    formData.append('quoteId', quoteId);
    formData.append('txId', transactionId);
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`,
    });
    return this.http.post(`${this.url}/${this.confirmPaymentUri}`, formData, { headers }).toPromise();
  }
}
