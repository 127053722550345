
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { inspect } from 'util';
import { EXTERNAL_APIS, SKIP_PRIMARY_ADDRESS_MODAL, WALLET_STORAGE_KEY } from '../constants';
import { Logger } from './logger.service';
import { MembersPortalService } from './members-portal.service';
import { StorageService } from './storage.service';
import { getUnlockKeychain } from '../lib/pangolin/keyring/keychainUtils';

@Injectable({
    providedIn: 'root'
})
export class MicroRewardService {
    private baseApi = EXTERNAL_APIS.MEMBERS_PORTAL;
    private getOrPostAssociateAddressOfUserApi = `${this.baseApi}/associatedAddresses`;
    public isDisplayAssociateAddressModal: boolean;
    public isSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);


    constructor(
        private http: HttpClient,
        private membersPortal: MembersPortalService,
        private storage: StorageService,

    ) { }

    async getAssociateAddressOfUser(): Promise<any> {
        const jwt = await this.membersPortal.returnJwtIfValid();

        if (!jwt) {
            return [];
        }

        const headers = new HttpHeaders({
            Authorization: `Bearer ${jwt}`,
        });

        try {
            return this.http.get(this.getOrPostAssociateAddressOfUserApi, { headers }).toPromise();
        } catch (error) {
            Logger.error('getAssociateAddressOfUser', inspect(error));
        }

    }

    async postAssociateAddressOfUser(
        signature: string,
        address: string,
        is_primary: number,
        address_type: string,
        message: string
    ): Promise<any> {

        const data = {
            signature,
            message,
            address,
            is_primary,
            address_type
        };
        Logger.info('postAssociateAddressOfUser payload', data);
        const jwt = await this.membersPortal.returnJwtIfValid();

        const headers = new HttpHeaders({
            Authorization: `Bearer ${jwt}`,
        });
        try {
            return this.http.post(this.getOrPostAssociateAddressOfUserApi, data, { headers }).toPromise();

        } catch (error) {
            Logger.error('getPriceQuoteTransaction', inspect(error));

        }
    }
    async signMessageForAvax(fromAddress: string, msg: string, password: string): Promise<string> {
        const vault = await this.storage.get(WALLET_STORAGE_KEY);
        const keychain = await getUnlockKeychain(vault, password);
        Logger.info('signing msg:', msg);
        const signedTx = keychain.signMessage(fromAddress, msg);

        Logger.info('signedTx:', signedTx);

        return signedTx;
    }

    async showAssociateAddressModal(isDisplay: boolean) {
        await this.storage.set(SKIP_PRIMARY_ADDRESS_MODAL, isDisplay);
        this.isDisplayAssociateAddressModal = isDisplay;
    }
}
