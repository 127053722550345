import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { BankAccountService } from 'src/app/angular-wallet-base/services/bank-account.service';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { MembersPortalService } from 'src/app/angular-wallet-base/services/members-portal.service';
import { environment } from 'src/environments/environment';
import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { Logger } from '../../../angular-wallet-base/services/logger.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { getSetupLevel } from 'src/app/angular-wallet-base/store/accountSetup';
import { TranslateService } from '@ngx-translate/core';
import { presentAlert } from '../../../angular-wallet-base/utils';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { WalletType } from '../../select-asset-modal/select-wallet-modal/select-wallet-modal.component';

@Component({
  selector: 'app-prime-tos',
  templateUrl: './prime-tos.component.html',
  styleUrls: ['./prime-tos.component.scss'],
})
export class PrimeTosComponent implements OnInit {
  // @ViewChild('tosContainerMobile', { static: true }) agreementScroll;
  @ViewChild('achContainer', { static: true }) agreementScroll;

  public setupLevel: number = 1;
  public type: WalletType = 'prime';
  @Input() public agreementData;
  public enableAgreeButton: boolean = false;
  public step = 0;
  public actPatriot: any = [];
  public firstTosStep: string;
  public patriotActPdf: any;
  private fileTransfer: FileTransferObject;
  public tosStatus: any = [];
  public secondTosStep: string;
  private acceptedTos = false;
  public agreementForm: FormGroup;
  public isModal = true;
  private secondLoadingAlert;
  private secondLoadingModal;
  public stepTwoAccepted = false;
  public isDesktopMode;

  options: InAppBrowserOptions = {
    location: 'yes', // Or 'no'
    hidden: 'no', // Or  'yes'
    clearcache: 'yes',
    clearsessioncache: 'yes',
    zoom: 'yes', // Android only ,shows browser zoom controls
    hardwareback: 'yes',
    mediaPlaybackRequiresUserAction: 'no',
    shouldPauseOnSuspend: 'no', // Android only
    closebuttoncaption: 'Close', // iOS only
    toolbar: 'yes', // iOS only
    toolbarposition: 'top', // iOS only
    enableViewportScale: 'no', // iOS only
    allowInlineMediaPlayback: 'no', // iOS only
    presentationstyle: 'fullscreen', // iOS only
    fullscreen: 'yes', // Windows only
  };
  constructor(
    protected store: Store<AppState>,
    private router: Router,
    private bankAccountService: BankAccountService,
    private modal: ModalController,
    private nav: NavController,
    private authService: AuthService,
    private membersPortalService: MembersPortalService,
    private platform: Platform,
    private file: File,
    private inappbrowser: InAppBrowser,
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private translate: TranslateService,
    private loadingController: LoadingController,
    public appMode: AppModeService,
    private document: DocumentViewer,
    private transfer: FileTransfer
  ) {
    this.appMode.isDesktopMode.subscribe((mode) => (this.isDesktopMode = mode));
  }

  ngOnInit() {
    this.agreementForm = this.formBuilder.group({
      term_condition: [false, [Validators.requiredTrue]],
      term_cancellation: [false, [Validators.requiredTrue]],
      term_privacy_policy: [false, [Validators.requiredTrue]],
    });
    setTimeout(() => {
      this.authService.setScrollToBottom(true);
    }, 600);

    this.agreementScroll.nativeElement.onscroll = (e) => {
      const tosEl = e.target;
      const scrollTop = tosEl.scrollTop;
      const offSetHeight = tosEl.offsetHeight;
      const scrollHeight = tosEl.scrollHeight;

      if (scrollTop === 0) {
        this.enableAgreeButton = false;
      }
      if (scrollTop > scrollHeight - offSetHeight - 1) {
        this.enableAgreeButton = true;
      }
    };

    this.tosData();
  }
  clearForm() {
    this.agreementForm.reset();
  }
  async tosData() {
    if (!this.actPatriot.length) {
      this.actPatriot = await this.membersPortalService.getActPatriotText();
      let actPatriotFileName = `${this.actPatriot[0].name}.pdf`;
      this.firstTosStep = `${environment.EXTERNAL_APIS.MEMBER_PORTAL_HOME}/pdfs/${actPatriotFileName}#navpanes=0&scrollbar=0`;
    }
    if (!this.tosStatus.length) {
      this.tosStatus = await this.membersPortalService.getTosAndStatus();
      let tosStatusFileName = `${this.tosStatus[0].name}_v3.pdf`;
      this.secondTosStep = `${environment.EXTERNAL_APIS.MEMBER_PORTAL_HOME}/pdfs/en_${tosStatusFileName}#navpanes=0&scrollbar=0`;
    }
  }
  onMoreInfo() {
    window.open('https://www.youtube.com/watch?v=6n3wpZlYzfo&feature=youtu.be', '_blank');
  }
  openUrl() {
    this.platform.ready().then(() => {
      this.inappbrowser.create(
        'https://lode.one/wp-content/uploads/2022/03/LODE-Switzerland-AG-Privacy-Policy-Feburary-2022.pdf',
        '_system',
        this.options
      );
    });
  }
  public downloadPdf(fileName, type) {
    var fileType = type == 'first' ? this.firstTosStep : this.secondTosStep;
    if (this.platform.is('ios')) {
      try {
        this.fileTransfer = this.transfer.create();
        this.fileTransfer.download(fileType, this.file.dataDirectory + fileName, true).then(async (entry) => {
          const url = entry.toURL();
          this.document.viewDocument(url, 'application/pdf', {});
          Logger.info('DOWNLOAD COMPLETE: ' + url);
        });
      } catch (error) {
        this.platform.ready().then(() => {
          this.inappbrowser.create(fileType, '_system', this.options);
        });
      }
    } else {
      this.platform.ready().then(() => {
        this.inappbrowser.create(fileType, '_system', this.options);
      });
    }
  }
  getPdfUrl(type) {
    return type == 'first' ? this.firstTosStep : this.secondTosStep;
  }
  async onAgree() {
    try {
      await this.bankAccountService.createCustodialAccount();
      this.bankAccountService.setServiceStatusInStore('bankAccount', true);
      return this.modal.dismiss().then((success) => {
        return this.nav.navigateBack('/wallet/prime-trust/USD');
      });
    } catch (e) {
      return this.modal.dismiss();
    }
  }
  closeModal() {
    this.modal.dismiss();
  }
  nextStep() {
    switch (this.step) {
      case 0:
        this.step = this.step + 1;
        this.store.select(getSetupLevel).subscribe((data) => {
          this.setupLevel = 2;
        });
        break;

      case 1:
        this.step = this.step + 1;
        this.store.select(getSetupLevel).subscribe((data) => {
          this.setupLevel = 3;
        });
        this.enableAgreeButton = false;
        break;

      case 2:
        setTimeout(() => {
          this.agreementScroll.nativeElement.onscroll = (e) => {
            const tosEl = e.target;
            const scrollTop = tosEl.scrollTop;
            const offSetHeight = tosEl.offsetHeight;
            const scrollHeight = tosEl.scrollHeight;

            if (scrollTop === 0) {
              this.enableAgreeButton = false;
            }
            if (scrollTop > scrollHeight - offSetHeight - 1) {
              this.enableAgreeButton = true;
            }
          };
        }, 1000);
      default:
        break;
    }
  }
  onCancel() {
    this.stepTwoAccepted = true;
  }
  async handleSubmitClick() {
    if (this.isModal) {
      this.secondLoadingAlert = await this.alertController.create({
        cssClass: 'light-font-alert',
        header: await this.translate.get('buy_agx.loading.please_wait').toPromise(),
      });
      await this.secondLoadingAlert.present();
    } else {
      this.secondLoadingModal = await this.loadingController.create({
        message: await this.translate.get('buy_agx.loading.please_wait').toPromise(),
        duration: 999999,
        cssClass: 'index-top',
      });
      await this.secondLoadingModal.present();
    }
    var res = await this.membersPortalService.updateAgreements(this.tosStatus[0].id);

    if (this.isModal) {
      await this.secondLoadingAlert.dismiss();
    } else {
      await this.secondLoadingModal.dismiss();
    }

    res && res.confirmed
      ? await presentAlert(
          this.alertController,
          this.translate,
          null,
          'settings.tos.alert_heading',
          'settings.tos.alert_success',
          'settings.tos.alert_ok'
        )
      : null;
    this.onCancel();

    if (this.isModal && res?.confirmed) {
      this.acceptedTos = true;
    }
  }
}
