import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.scss'],
})
export class InvestorsComponent implements OnInit {
  public investors: any;

  constructor(private modal: ModalController,
    private translate: TranslateService,
  ) { }

  async ngOnInit() {
  }
 
  closeModal() {
    this.modal.dismiss();
  }
}
