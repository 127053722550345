import { Action } from '@ngrx/store';

export enum AppSettingsActionTypes {
  CHANGE_TITLE = '[appState] Change page title',
  CHANGE_SECONDARY_TITLE = '[appState] Change page secondary title',
  USE_I18N_ON_TITLE = '[appState] Use i18n on title',
  FORCE_UPGRADE = '[appState] Force Upgrade',
  CHANGE_ACTIVE_PAGE = '[appState] Change Active Page',
  UPDATE_SIDE_MENU = '[appState] Update Side Menu',
  SHOW_SIDE_MENU = '[appState] Show Side Menu',
  RESET_SETTINGS = '[appState] Reset app settings to initial state'
}

export class ChangeTitleAction implements Action {
  readonly type = AppSettingsActionTypes.CHANGE_TITLE;
  constructor(readonly payload: string) { }
}

export class ChangeSecondatyTitleAction implements Action {
  readonly type = AppSettingsActionTypes.CHANGE_SECONDARY_TITLE;
  constructor(readonly payload: string) { }
}

export class UseI18nOnTitleAction implements Action {
  readonly type = AppSettingsActionTypes.USE_I18N_ON_TITLE;
  constructor(readonly payload: boolean) { }
}

export class ForceUpgradeAction implements Action {
  readonly type = AppSettingsActionTypes.FORCE_UPGRADE;
  constructor(readonly payload: boolean) { }
}

export class ChangeActivePage implements Action {
  readonly type = AppSettingsActionTypes.CHANGE_ACTIVE_PAGE;
  constructor(readonly payload: string) { }
}

export class UpdateSideMenu implements Action {
  readonly type = AppSettingsActionTypes.UPDATE_SIDE_MENU;
  constructor(readonly payload: boolean) { }
}

export class ShowSideMenu implements Action {
  readonly type = AppSettingsActionTypes.SHOW_SIDE_MENU;
  constructor(readonly payload: boolean) { }
}

export class ResetAppSettings implements Action {
  readonly type = AppSettingsActionTypes.RESET_SETTINGS;
}
