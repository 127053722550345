import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import {
  bankAccountActionTypes,
  GetBankAccountBalanceRequest,
  GetBankAccountBalanceError,
  GetBankAccountBalanceSuccess,
  GetBankAccountRequestAction,
  GetBankAccountErrorAction,
  GetBankAccountSuccessAction,
  GetBankAccountTransactionHistorySuccess,
  GetBankAccountTransactionHistoryError,
  GetBankAccountTransactionHistoryRequest,
  UpdateBankAccountAssetAction,
  GetBankAccountAssetRequestAction,
  AddBankingHistoryAction
} from '../actions/bankAccount.actions';
import { MembersPortalService } from '../services/members-portal.service';
import { BankAccountService, tokenToAssetToken, TransferType } from '../services/bank-account.service';
import { Logger } from '../services/logger.service';

@Injectable()
export class BankAccountsEffects {

  getBankAccounts$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountRequestAction>(bankAccountActionTypes.BANK_ACCOUNT_GET_LIST_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      let accs;
      try {
        accs = await this.bankAccount.getBankAccounts();
      } catch (err) {
        Logger.error(err);
        return this.store.dispatch(new GetBankAccountErrorAction());
      }
      return this.store.dispatch(new GetBankAccountSuccessAction(accs));
    })
  ), { dispatch: false });

  getBankAccountBalance$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountBalanceRequest>(bankAccountActionTypes.BANK_ACCOUNT_GET_BALANCE_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      let balance;
      try {
        balance = await this.bankAccount.getBankAccountsBalance();
      } catch (err) {
        Logger.error(err);
        return this.store.dispatch(new GetBankAccountBalanceError());
      }
      return this.store.dispatch(new GetBankAccountBalanceSuccess(balance));
    })
  ), { dispatch: false });

  getTransactionHistory$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountTransactionHistoryRequest>(bankAccountActionTypes.BANK_ACCOUNT_GET_TRANSACTION_HISTORY_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      let txs;
      let result;
      let resultExchange;
      try {
        await this.bankAccount.updateLoader('transaction', true);
        // txs = await this.bankAccount.getTransactionList();
        txs = [];
        result = await this.bankAccount.getTransferTypeTransactionHistory(1, 25, TransferType.FIAT, 'USD');
        resultExchange = await this.bankAccount.getTransferTypeTransactionHistory(1, 25, TransferType.EXCHANGE, 'USD');
        txs = txs.concat(result['transactions'], resultExchange['transactions']);
        txs.sort((a, b) => {
          return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
        });
      } catch (err) {
        Logger.error(err);
        await this.bankAccount.updateLoader('transaction', false);
        return this.store.dispatch(new GetBankAccountTransactionHistoryError());
      }
      await this.bankAccount.updateLoader('transaction', false);
      return this.store.dispatch(new GetBankAccountTransactionHistorySuccess(txs));
    })
  ), { dispatch: false });

  updateBankAccountAsset$ = createEffect(() => this.actions$.pipe(
    ofType<GetBankAccountAssetRequestAction>(bankAccountActionTypes.BANK_ACCOUNT_GET_ASSET_LIST_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      let assets;
      try {
        assets = await this.bankAccount.getAssetBalances();
        // fetch fiat-rates for assets AGX, AUX & LODE
        assets.forEach(async asset => {
          const fiatValue = await this.bankAccount.getTokentoFiatRate({ token: tokenToAssetToken(asset.token), amount: 1 });
          asset.fiatValue = Number(fiatValue) > 0 ? fiatValue : null;
          asset.fiatBalance = Number(fiatValue) > 0 ? (asset.balance * Number(fiatValue)) : null;
        });
      } catch (err) {
        Logger.error(err);
        assets = [];
      }
      return this.store.dispatch(new UpdateBankAccountAssetAction(assets));
    })
  ), { dispatch: false });

  getBankingHistory$ = createEffect(() => this.actions$.pipe(
    ofType(bankAccountActionTypes.GET_BANKING_HISTORY),
    withLatestFrom(this.store),
    map(async ([action, store]) => {
      let txs;
      let result;
      const bankAccount = (store as AppState).bankAccount;
      const existingData = bankAccount.bankingHistory.data;
      const pageNumber = action['initialCall'] ? 1 : (bankAccount.bankingHistory.pageNumber);
      const pageSize = bankAccount.bankingHistory.pageSize;
      try {
        await this.bankAccount.updateLoader('transaction', true);
        txs = await this.bankAccount.getTransactionList(pageNumber, pageSize,'fiat') as [];
        // result = await this.bankAccount.getTransactionList(pageNumber, pageSize,'exchange') as [];
        // txs = txs.concat(result);

        // update or insert data
        Logger.info("txssssssssssssss" ,txs)
        txs = [];
      let addedNewDataCount = 0;
      if(txs.length>0){
        txs.map((data: any) => {
          const history = { ...data };
          const dataIndex = existingData.findIndex(i => i.orderHash === history.orderHash);
          if (dataIndex === -1) {
            existingData.push(history);
            addedNewDataCount++;
          } else {
            existingData[dataIndex] = history;
          }
        });
  
     
      }
    // sort by date
    const data = existingData.sort((a, b) => {
      return <any>new Date(b.createDateTime) - <any>new Date(a.createDateTime);
    });

      // update page number or finish pagination
      const updatePageNumber = action['initialCall']
        ? (addedNewDataCount >= pageSize) ? 2 : bankAccount.bankingHistory.pageNumber // if all data from page 1 gets inserted, restart the pagination from 1
        : (txs.length >= pageSize ? (pageNumber + 1) : pageNumber);
      const loadedAll = (txs.length >= pageSize) ? false : true;

       this.store.dispatch(new AddBankingHistoryAction({ data, pageNumber: updatePageNumber, pageSize, loadedAll }));

      } catch (err) {
        Logger.error(err);
        await this.bankAccount.updateLoader('transaction', false);
        return this.store.dispatch(new GetBankAccountTransactionHistoryError());
      }
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private members: MembersPortalService,
    private bankAccount: BankAccountService
  ) {
  }
}
