import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EXTERNAL_APIS } from '../constants';
import { Logger } from './logger.service';
import { MembersPortalService } from './members-portal.service';

@Injectable({
  providedIn: 'root'
})
export class PrimetransactionsService {
  private baseApi = EXTERNAL_APIS.MEMBERS_PORTAL_BANK_API;
  constructor(private http: HttpClient,
    private membersPortal: MembersPortalService) { }




  async getPrimeAssetTransaction(asset, pagenumber, pagesize) {
    const url = `${this.baseApi}/exchange/transfers/assets/${asset}/me?page-number=${pagenumber}&page-size=${pagesize}`;
    const jwt = await this.membersPortal.returnJwtIfValid();

    if (!jwt) {
      return [];
    }

    let res: any;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jwt}`
    });

    try {
      res = await this.http.get(url, { headers }).toPromise();
    }
    catch (err) {
      Logger.info('Failed to get transactions', err);
      res = [];
    }
    return res;

  }
}
