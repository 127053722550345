import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import BigNumber from 'bignumber.js';
import { isNumeric } from 'rxjs/internal-compatibility';
import { take } from 'rxjs/operators';
import { LIQUIDITY_PAIRS, LIQUIDITY_PAIRS_1INCH, LIQUIDITY_PAIRS_WITH_SERVICE, PAYWITH, PAYMENT_MODES,  RESTRICTED_REGION} from 'src/app/angular-wallet-base/constants';
import { SmallToCryptoPipe } from 'src/app/angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { EnvironmentService } from 'src/app/angular-wallet-base/services/environment.service';
import { HealthResponse } from 'src/app/angular-wallet-base/services/gas-station.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { NetworkService } from 'src/app/angular-wallet-base/services/network.service';
import { StorageService } from 'src/app/angular-wallet-base/services/storage.service';
import { TradeService } from 'src/app/angular-wallet-base/services/trade.service';
import { WalletService } from 'src/app/angular-wallet-base/services/wallet.service';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { getBaseCurrency, getUserIsRestricted } from 'src/app/angular-wallet-base/store/userPreferences';
import { getKeychain } from 'src/app/angular-wallet-base/store/wallet';
import { SubscriptionListenerComponent } from 'src/app/angular-wallet-base/SubscriptionListenerComponent';
import {
  filterByTokenSymbol,
  getCurrentNetworkIcon,
  getCurrentNetworkLabel,
  getNonAssetPrimeTokenList,
  getPrimeTrustTokenInfo,
  getTokenBalance,
  getTokenFiatValue,
  getTokenInfoExcludeHLAndPTArray,
  getTokenInfoExcludeHLArray,
  groupByTokenSymbol,
  sortAssetList,
  getTokenInfoArray
} from 'src/app/angular-wallet-base/utils';
import { TokenDefinition, TokenInfo, TokenInfoExtended, WalletKeychain } from 'src/app/global';
import { WalletType } from './select-wallet-modal/select-wallet-modal.component';
import { getSideMenuStatus } from '../../angular-wallet-base/store/appSettings';
import { SendAssetModal } from '../send-asset-modal/send-asset-modal.component';
import { ReceiveAssetModal } from '../receive-asset-modal/receive-asset-modal.component';
import { PurchaseHistoryModalComponent } from '../buy-assets/purchase-history/purchase-history.modal';
import { SelectPaymentModal } from '../select-payment-modal/select-payment-modal';
import { inspect } from 'util';
import { TokenManagerService } from 'src/app/angular-wallet-base/services/token-manager.service';
import { supportedAssetList } from "src/app/angular-wallet-base/lib/supported-assets/supported-assets";

export type SelectScreens = 'send' | 'receive' | 'buy' | 'trade' | 'move' | 'sendAch' | 'buyAch' | 'receiveAch' | 'changeSelectionAch';
export type SelectTokenInfo = TokenInfo &
  TokenInfoExtended & {
    aggregateTotalFiat?: number;
    aggregateTotalBalance?: number;
    iconChain?: string;
    isExpanded?: boolean;
    name?: string;
    symbol: string;
    logo?: string;
    preciseBalance?: number;
  };

@Component({
  selector: 'app-select-asset-modal',
  templateUrl: './select-asset-modal.component.html',
  styleUrls: ['./select-asset-modal.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class SelectAssetModal extends SubscriptionListenerComponent implements OnInit {
  @Input() newMember: string;
  @Input() promocode: string;
  @Input() actionType: SelectScreens;
  @Input() isExchange?: boolean;
  @Input() noFiatAsset?: boolean;
  public isDesktopMode: boolean;
  public isRestricted?: boolean;
  public userAddress?: any;
  public userProvince?: any;
  public sendOrReceiveOrBuy: SelectScreens;
  public orderType: string;
  public title: string = '';
  public tokens: SelectTokenInfo[] = [];
  public filteredTokens: SelectTokenInfo[] = [];
  public mainTokenSymbol;
  public keychain;
  public assetHealthForMove: HealthResponse;
  public currentBaseCurrency: string;
  public aggregatedBalance = 0;
  public aggregatedFiatBalance = 0;
  public mainToken: any = {};
  public isLodeLinked: boolean = true;
  public groupedTokens;
  public progressBar: boolean = false;
  public walletType: WalletType;
  public usdBalance: TokenDefinition[] = [
    {
      name: 'USD Balance',
      symbol: 'USD',
      logo: '../../assets/images/coins/usdbalance.png',
      iconChain: '../../assets/images/coins/usdbalance.png',
    },
  ];
  public liquidityLoader = false;
  public assetToTrade;
  public tradeFor;
  public selectFor;
  public liquidityPairs;
  public liquidityPairsWithService;
  public limitOrderLiquidityPairs;

  constructor(
    private modalController: ModalController,
    public appMode: AppModeService,
    protected store: Store<AppState>,
    private nav: NavController,
    private smallToCrypto: SmallToCryptoPipe,
    private translate: TranslateService,
    private networkManager: NetworkService,
    private storage: StorageService,
    private walletService: WalletService,
    private tradeService: TradeService,
    private environmentService: EnvironmentService,
    private tokenMangerService: TokenManagerService,
  ) {
    super(store);
    this.appMode.isDesktopMode.subscribe((mode) => (this.isDesktopMode = mode));
  }

  async ngOnInit() {
    this.isRestricted = await this.store.select(getUserIsRestricted).pipe(take(1)).toPromise();
    this.walletType = this.walletService.getWalletType();
    await this.setTitleForPopup(this.actionType);
     try {
      await this.modalController.dismiss({}, '', 'successModal');
    } catch (error) {
      Logger.error('successModal dismiss', inspect(error));
    }

    this.subscriptions.add(
      this.store.select(getBaseCurrency).subscribe(baseCurrency => this.currentBaseCurrency = baseCurrency)
    )

    if (this.actionType == 'trade') {
      await this.initiateLiquidityForTrade();
    }

    await this.initialTokenFilter();
  }

  private async initiateLiquidityForTrade() {
    // get liquidity call loader status
    this.tradeService.getLiquidityLoader.subscribe(async (liquidityLoader) => {
      this.liquidityLoader = liquidityLoader;
    });
    this.liquidityPairs = await this.storage.get(LIQUIDITY_PAIRS);
    this.liquidityPairs = this.liquidityPairs && JSON.parse(this.liquidityPairs);
    Logger.info('quoted liquidity Pairs', this.liquidityPairs);
    if (this.isEmptyLiquidity()) { // if liquidity empty // return a default set:
      this.liquidityPairs = this.getDefaultLiquidityForTrade();
    }

    this.liquidityPairsWithService = await this.storage.get(LIQUIDITY_PAIRS_WITH_SERVICE);
    this.liquidityPairsWithService = this.liquidityPairsWithService && JSON.parse(this.liquidityPairsWithService);
    Logger.info('quoted liquidity Pairs with service', this.liquidityPairsWithService);

    this.limitOrderLiquidityPairs = await this.storage.get(LIQUIDITY_PAIRS_1INCH);
    this.limitOrderLiquidityPairs = this.limitOrderLiquidityPairs && JSON.parse(this.limitOrderLiquidityPairs);
    Logger.info('limit order liquidity pairs', this.limitOrderLiquidityPairs);
  }

  private isEmptyLiquidity(): boolean {
    let isEmpty = true;

    Object.keys(this.liquidityPairs).forEach((tokenKey) => {
      if (this.liquidityPairs[tokenKey] && this.liquidityPairs[tokenKey].length > 0) {
        isEmpty = false;
      } 
    });
    return isEmpty
  }

  private getDefaultLiquidityForTrade() {
    let defaultLiquidity = {};
      // AGX
     defaultLiquidity["0x13e7bcefdde72492e656f3fa58bae6029708e673"] = [
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        "0x68327a91e79f87f501bc8522fc333fb7a72393cb",
        "0xbbaaa0420d474b34be197f95a323c2ff3829e811",
        "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
        "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
        "0xd6711933736d739813fd248c0683ada46691bfd7",
        "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
        "0xea198f1b44a96a5e36e1e32e18d031936dce9b2b"
    ];

    // AVAX
    defaultLiquidity["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"] = [
      "0x13e7bcefdde72492e656f3fa58bae6029708e673",
      "0x68327a91e79f87f501bc8522fc333fb7a72393cb",
      "0xbbaaa0420d474b34be197f95a323c2ff3829e811",
      "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
      "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
      "0xd6711933736d739813fd248c0683ada46691bfd7",
      "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
      "0xea198f1b44a96a5e36e1e32e18d031936dce9b2b"
    ];

    // AUX
    defaultLiquidity["0x68327a91e79f87f501bc8522fc333fb7a72393cb"] = [
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        "0x13e7bcefdde72492e656f3fa58bae6029708e673",
        "0xbbaaa0420d474b34be197f95a323c2ff3829e811",
        "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
        "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
        "0xd6711933736d739813fd248c0683ada46691bfd7",
        "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
        "0xea198f1b44a96a5e36e1e32e18d031936dce9b2b"
    ]

     // USDC.e
    defaultLiquidity["0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664"] = [
      "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      "0x68327a91e79f87f501bc8522fc333fb7a72393cb",
      "0x13e7bcefdde72492e656f3fa58bae6029708e673",
      "0xbbaaa0420d474b34be197f95a323c2ff3829e811",
      "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
      "0xd6711933736d739813fd248c0683ada46691bfd7",
      "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
      "0xea198f1b44a96a5e36e1e32e18d031936dce9b2b"
    ]

         // USDC
    defaultLiquidity["0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e"] = [
        "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
        "0x68327a91e79f87f501bc8522fc333fb7a72393cb",
        "0x13e7bcefdde72492e656f3fa58bae6029708e673",
        "0xbbaaa0420d474b34be197f95a323c2ff3829e811",
        "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
        "0xd6711933736d739813fd248c0683ada46691bfd7",
        "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
        "0xea198f1b44a96a5e36e1e32e18d031936dce9b2b"
    ]

    defaultLiquidity["0xbbaaa0420d474b34be197f95a323c2ff3829e811"] = [
      "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      "0x68327a91e79f87f501bc8522fc333fb7a72393cb",
      "0x13e7bcefdde72492e656f3fa58bae6029708e673",
      "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
      "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
      "0xd6711933736d739813fd248c0683ada46691bfd7",
      "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
      "0xea198f1b44a96a5e36e1e32e18d031936dce9b2b"
    ]
    return defaultLiquidity;
  }

  async setTitleForPopup(popUpMode: string) {
    switch (popUpMode) {
      case 'trade':
        this.title =
        this.selectFor === 'assetToTrade'
          ? await this.translate.get('swap_home.asset_to_trade').toPromise()
          : await this.translate.get('swap_home.trade_for').toPromise();
      if (this.environmentService.trade) {
        this.title =
          this.selectFor === 'assetToTrade'
            ? await this.translate.get('select_purchasing.title_trade_sell').toPromise()
            : await this.translate.get('select_purchasing.title_trade_buy').toPromise();
      }
        break;
        case 'send':
          this.title = await this.translate.get('select_asset_popup.title_send').toPromise();
        break;
        case 'receive':
          this.title = await this.translate.get('select_asset_popup.title_receive').toPromise();
        break;
        case 'buy':
          this.title = await this.translate.get('select_purchasing.title_buy').toPromise();
        break;
    
      default:
        this.title = '';
        break;
    }
  
  }

  async initialTokenFilter() {
    const tokenScreens: SelectScreens[] = ['send', 'receive', 'buy', 'trade', 'move', 'buyAch', 'sendAch', 'receiveAch', 'changeSelectionAch'];
    if (tokenScreens.includes(this.actionType)) {
      this.progressBar = true;
      switch(this.actionType) {
        case 'trade':
          this.initiateTokenTradeFilterPopupScreen();
          break;
        case 'buy':
          if (this.walletType == 'prime') {
            this.initiateTokenBuyFilterForPrimeWalletPopupScreen();
          } else {
            this.initiateTokenBuyFilterPopupScreen();
          }
          break;
        case 'move' :
          this.initiateTokenForMovePopupScreen();
          break;
        case 'buyAch':
        case 'sendAch':
        case 'receiveAch':
          this.initiateAchPopScreen();
          break;
          
        default: // for case send, receive, changeSelectionAch
          this.initiateTokenFilterPopUpScreen(); // for future refactor for different screen
          break;
      }
      
    }
  }

  private isTradeAction(): boolean {
    return this.actionType == 'trade';
  }



  private async initiateTokenTradeFilterPopupScreen() {
    this.subscriptions.add(
      this.store.select(getKeychain).subscribe(async (keychain) => {
        if (!keychain) {
          // Prevent error on wallet reset.
          return;
        }
       
        // get tokens
        const tokens = await this.getTokenInfoByLiquidityPool(keychain);
        //const filteredTokens:any = groupByTokenSymbol(tokens);

         // We have some new requirements
         let filteredTokens = filterByTokenSymbol(tokens, 'symbol');
         // need to add USDC.e for aggregating balance
         const tokenForAggregation: SelectTokenInfo[] = tokens.map((token) => {
           if (token.symbol === 'USDC.e') {
             token.symbol = 'USDC';
           }
           return token;
         });
         const grouped = groupByTokenSymbol(tokenForAggregation);
         const updatedTokens = filteredTokens.map((filtered) => {
           const aggregates = this.calculateAggregatedBalance({
             groupedTokens: grouped,
             symbol: filtered.symbol,
             fiatVal: filtered.fiatValue,
           });
           const updatedFiltered = { ...filtered };
           updatedFiltered.aggregateTotalBalance = aggregates.aggregatedBalance;
           updatedFiltered.aggregateTotalFiat = aggregates.aggregatedFiatBalance;
           if (updatedFiltered?.tokens?.length > 1) {
             return updatedFiltered;
           }
           const nestedTokens = tokens.filter((token) => {
             if (updatedFiltered.symbol === 'USDC') {
               return ['USDC', 'USDC.e'].includes(token.symbol);
             } else {
               return updatedFiltered.symbol === token.symbol;
             }
           });
           updatedFiltered.tokens = nestedTokens;
           return updatedFiltered;
         });


        // remove double nested tokens
        this.progressBar = false;
        Logger.info(`initateTokenTradeFilterPopupScreen - ${inspect(tokens)}`);
        this.tokens = updatedTokens;
        this.filteredTokens = sortAssetList(updatedTokens);
      })
    );
  }

  private async initiateTokenBuyFilterPopupScreen() {
    const excludeToken = ['ETH', 'BTC'];

    this.subscriptions.add(
      this.store.select(getKeychain).subscribe(async (keychain) => {
        if (!keychain) {
          // Prevent error on wallet reset.
          return;
        }

        let tokenInfos: TokenInfo[] = await getNonAssetPrimeTokenList(keychain);
        let tokens = await this.tokenMangerService.transformTokenInfoIntoTokenInfoExtend(tokenInfos);
        if(this.isRestricted) {
          excludeToken.push('LODE')
        }
        tokens = tokens.filter(obj => !excludeToken.includes(obj.symbol));
        this.aggregateAndSortTokens(tokens);
      })
    );
  }

  private async initiateTokenBuyFilterForPrimeWalletPopupScreen() {
    this.subscriptions.add(
      this.store.select(getKeychain).subscribe(async (keychain) => {
        if (!keychain) {
          // Prevent error on wallet reset.
          return;
        }
        let tokenInfos: TokenInfo[] = await getPrimeTrustTokenInfo(keychain);
        let tokens = await this.tokenMangerService.transformTokenInfoIntoTokenInfoExtend(tokenInfos);
        this.aggregateAndSortTokens(tokens);
      })
    );
  }

  private async initiateTokenForMovePopupScreen() {
    this.subscriptions.add(
      this.store.select(getKeychain).subscribe(async (keychain) => {
        if (!keychain) {
          // Prevent error on wallet reset.
          return;
        }

        let tokenInfos: TokenInfo[] = await getTokenInfoExcludeHLAndPTArray(keychain);
        const moveTokens = ['AGX', 'AUX'].filter((token) => {
          const tokenInfo = tokenInfos.find((tkn) => tkn.baseChainSymbol === 'SYSNEVM' && tkn.symbol === token);
          const healthAddress = this.assetHealthForMove.avalanche[token.toLowerCase()].contract;
          const healthBalance = +this.assetHealthForMove.wallet[token.toLowerCase() + 'Balance'];
          Logger.info('tokenInfoassetGuid', tokenInfo.assetGuid.toLowerCase(), healthAddress.toLowerCase());
          return (
            healthBalance > 0 && (tokenInfo && tokenInfo.assetGuid.toLowerCase()) === (healthAddress && healthAddress.toLowerCase())
          );
        });

        tokenInfos = tokenInfos
          .filter((token) => ['SYS'].includes(token.baseChainSymbol) && moveTokens.includes(token.symbol))

        let tokens = await this.tokenMangerService.transformTokenInfoIntoTokenInfoExtend(tokenInfos);
        this.aggregateAndSortTokens(tokens);
      })
    );
  }

  private async initiateAchPopScreen() {
    this.subscriptions.add(
      this.store.select(getKeychain).subscribe(async (keychain) => {
        if (!keychain) {
          // Prevent error on wallet reset.
          return;
        }

        let tokenInfos: TokenInfo[] = await getTokenInfoExcludeHLArray(keychain);
        tokenInfos = this.getAssetsForAch(tokenInfos);
        let tokens = await this.tokenMangerService.transformTokenInfoIntoTokenInfoExtend(tokenInfos);
        this.aggregateAndSortTokens(tokens);
        if (this.actionType === 'buyAch' && this.walletType === 'prime') {
          // @ts-ignore
          this.filteredTokens = [...this.filteredTokens, ...this.usdBalance];
        }
      })
    );
  }

  private async aggregateAndSortTokens(tokens: TokenInfoExtended[]) {
    // We have some new requirements
    let filteredTokens = filterByTokenSymbol(tokens, 'symbol');
    // need to add USDC.e for aggregating balance
    const tokenForAggregation: SelectTokenInfo[] = tokens.map((token) => {
      if (token.symbol === 'USDC.e') {
        token.symbol = 'USDC';
      } else if (token.symbol === 'LTC') {
        token.symbol = 'LODE';
      }
      return token;
    });
    const grouped = groupByTokenSymbol(tokenForAggregation);
    const updatedTokens = filteredTokens.map((filtered) => {
        const aggregates = this.calculateAggregatedBalance({
          groupedTokens: grouped,
          symbol: filtered.symbol,
          fiatVal: filtered.fiatValue,
        });
        const updatedFiltered = { ...filtered };
        updatedFiltered.aggregateTotalBalance = aggregates.aggregatedBalance;
        updatedFiltered.aggregateTotalFiat = aggregates.aggregatedFiatBalance;
        if (updatedFiltered?.tokens?.length > 1) {
          return updatedFiltered;
        }
        const nestedTokens = tokens.filter((token) => {
          if (updatedFiltered.symbol === 'USDC') {
            return ['USDC', 'USDC.e'].includes(token.symbol);
          } else {
            return updatedFiltered.symbol === token.symbol;
          }
        });
        updatedFiltered.tokens = nestedTokens;
        return updatedFiltered;
    });

    // remove double nested tokens
    this.progressBar = false;
    this.tokens = updatedTokens;
    Logger.info('aggregateAndSortTokens - updatedTokens, ', inspect(updatedTokens));
    this.filteredTokens = sortAssetList(updatedTokens);
    Logger.info('aggregateAndSortTokens, ', inspect(filteredTokens));
  }


  private async initiateTokenFilterPopUpScreen() {
    this.subscriptions.add(
      this.store.select(getKeychain).subscribe(async (keychain) => {
        if (!keychain) {
          // Prevent error on wallet reset.
          return;
        }
        // get tokens

        // const tokens = await this.getTokenInfo(keychain);
        // We have some new requirements
                const listOfSupportedAssets = Object.values(supportedAssetList).filter(asset => !(asset?.custodyService === "PT"));
        Logger.info('listOfSupportedAssets', listOfSupportedAssets);
        this.currentBaseCurrency = await this.store.select(getBaseCurrency).pipe(take(1)).toPromise();
        keychain = Object.keys(keychain).filter((key) => !(keychain[key]?.custodyService === "PT")).reduce((obj, key) => {
          return Object.assign(obj, { [key]: keychain[key] })}, {});
            const tokens = await Promise.all(
          getTokenInfoArray(keychain)
            .sort((a, b) => a?.symbol?.charCodeAt(0) - b?.symbol?.charCodeAt(0))
            .map(async (token) => {
              const tokenCopy: any = { ...token };
              tokenCopy.preciseBalance = this.smallToCrypto.transform(tokenCopy.balance, {
                type: tokenCopy.baseChainSymbol,
                precision: tokenCopy.precision
              });
              tokenCopy.balance = tokenCopy.balance ? tokenCopy.balance.toString() : '0';
              tokenCopy.iconChain = await getCurrentNetworkIcon(tokenCopy.symbol, tokenCopy.baseChainSymbol);
              const found = listOfSupportedAssets.find(asset => asset.assetGuid?.toLowerCase() === token.assetGuid?.toLowerCase())

              tokenCopy.canRemovable = !!found;
              return tokenCopy;
            })
        );
        let filteredTokens = filterByTokenSymbol(tokens, 'symbol');
        // need to add USDC.e for aggregating balance
        const tokenForAggregation: SelectTokenInfo[] = tokens.map((token) => {
          if (token.symbol === 'USDC.e') {
            token.symbol = 'USDC';
          }
          return token;
        });
        const grouped = groupByTokenSymbol(tokenForAggregation);
        const updatedTokens = filteredTokens.map((filtered) => {
          const aggregates = this.calculateAggregatedBalance({
            groupedTokens: grouped,
            symbol: filtered.symbol,
            fiatVal: filtered.fiatValue,
          });
          const updatedFiltered = { ...filtered };
          updatedFiltered.aggregateTotalBalance = aggregates.aggregatedBalance;
          updatedFiltered.aggregateTotalFiat = aggregates.aggregatedFiatBalance;
          if (updatedFiltered?.tokens?.length > 1) {
            return updatedFiltered;
          }
          const nestedTokens = tokens.filter((token) => {
            if (updatedFiltered.symbol === 'USDC') {
              return ['USDC', 'USDC.e'].includes(token.symbol);
            }  else if (updatedFiltered.symbol === 'LODE') {

              return ['LODE', 'LTC'].includes(token.symbol) &&  token.baseChainSymbol
 != 'HL'
            } else {
              return updatedFiltered.symbol === token.symbol;
            }
          });
          updatedFiltered.tokens = nestedTokens;
          return updatedFiltered;
        });
        // remove double nested tokens
        this.progressBar = false;
        this.tokens = updatedTokens;
        Logger.info('sortAssetList - updatedTokens, ', inspect(updatedTokens));
        this.filteredTokens = sortAssetList(updatedTokens);
        Logger.info('sortAssetList, ', inspect(filteredTokens));
      })
    );
  }

  private async getTokenInfoByLiquidityPool(keychain: WalletKeychain): Promise<TokenInfoExtended[]> {
    let evmTokens: TokenInfo[] = await getTokenInfoExcludeHLAndPTArray(keychain);

    // async foreach on tokens
    const tokensExtended = [];

    if (!this.liquidityPairs) {
      return tokensExtended;
    }
    // includes both 1inch and trader-joe pairs
    const liquidityPairsFromStorage = Object.keys(this.liquidityPairs).filter(
      (tokenKey) => this.liquidityPairs[tokenKey] && this.liquidityPairs[tokenKey].length > 0 // return only the keys that have liquidity pairs
    );
    let liquidityTokens =  evmTokens
          .filter((token) => ['AVAX'].includes(token.baseChainSymbol)) // filter token with zero balance
          .filter((token) => {
            // market order will show token from both 1inch and trader-joe liquidities
            if (this.orderType === 'market') {
              if (this.selectFor === 'assetToTrade') {
                return liquidityPairsFromStorage.includes(token.assetGuid.toLowerCase()) || token.symbol === 'AVAX';
              }
              const selectedTokenForTrade = this.assetToTrade;
              return this.liquidityPairs[selectedTokenForTrade.assetGuid.toLowerCase()].includes(token.assetGuid.toLowerCase());
            } else {
              // Remove AVAX based liquidities since 1inch's limit order doesn't support it
              if(token.symbol==='AVAX' && token.baseChainSymbol==='AVAX') {
                return false;
              }
              const selectedTokenForTrade = this.assetToTrade;
              return this.limitOrderLiquidityPairs[selectedTokenForTrade.assetGuid.toLowerCase()].includes(token.assetGuid.toLowerCase());
            }
          });



    for (const token of liquidityTokens) {
      try {
        // @ts-ignore
        const tokenExtended: TokenInfoExtended = { ...token };
        tokenExtended.preciseBalance = this.smallToCrypto.transform(token.balance, {
          type: token.baseChainSymbol,
          precision: token.precision,
        });
        tokenExtended.networkLabel = await getCurrentNetworkLabel({
          tokenInfo: token,
          networkManager: this.networkManager,
          translateService: this.translate,
        });

        tokenExtended.iconChain = await getCurrentNetworkIcon(token.symbol, token.baseChainSymbol);
        tokenExtended.showIconInMain = true;
        tokenExtended.digital_currency = token.baseChainSymbol === 'AVAX' ? 'AVAX-C' : token.baseChainSymbol;
        tokensExtended.push(tokenExtended);
      } catch (e) {
        Logger.error('Error in getTokenInfo', e);
      }
    }

    return tokensExtended;
  }


  // get and filter tokens based on selection (buy/send/receive)
  private async getTokenInfo(keychain: WalletKeychain): Promise<TokenInfoExtended[]> {
    // get tokens
    let tokens: TokenInfo[] = await getTokenInfoExcludeHLAndPTArray(keychain);
    if (this.actionType == 'changeSelectionAch' && this.walletType === 'prime') {
      tokens = await getPrimeTrustTokenInfo(keychain);
    }
    return await this.tokenMangerService.transformTokenInfoIntoTokenInfoExtend(tokens);
  }

  getAssetsForAch(tokens: TokenInfo[]): TokenInfo[] {
    return tokens.filter(token => token?.custodyService == 'PT');
  }
  calculateAggregatedBalance({ groupedTokens, symbol, fiatVal }: { groupedTokens: any; symbol: any; fiatVal: any }) {
    const aggregatedTokens = groupedTokens[symbol];
    let aggregateTotal = {
      aggregatedBalance: 0,
      aggregatedFiatBalance: 0,
    };
    if (aggregatedTokens instanceof Array) {
      // let aggregatedBalance = 0;
      aggregatedTokens.forEach((token) => {
        if (token.balance) {
          let numBalance = this.smallToCrypto.transform(token.balance, { type: token.baseChainSymbol, precision: token.precision });
          // token['preciseBalance'] = numBalance;
          aggregateTotal.aggregatedBalance += new BigNumber(numBalance).toNumber();
          // let fiatBalance =
        }
        if (token.fiatValue) {
          let num = Number(token.fiatValue || 0) * Number(token.balance || 0);
          num = this.smallToCrypto.transform(num, { type: token.baseChainSymbol, precision: token.precision });
          aggregateTotal.aggregatedFiatBalance += new BigNumber(num).toNumber();
        }
        if (!token.fiatValue) {
          token['fiatValue'] = fiatVal;
        }
      });
    }
    return aggregateTotal;
  }


  closeModal() {
    this.modalController.dismiss();
  }

  getTokenBalance(keyring) {
    return getTokenBalance(keyring);
  }

  // TODO: format number - may change
  formatNumber(num = '') {
    if (isNumeric(num)) {
      const value = Number(num); // new BigNumber(num).toNumber();
      if (Math.abs(value) > 999) {
        const absValue = (Math.sign(value) * (Math.abs(value) / 1000));
        return (Math.floor(absValue * 100) / 100) + 'k';
      }
      return Math.sign(value) * Math.abs(value);
    }
    return 0;
  }

  getTokenFiatValue(fiatRate, balance) {
    fiatRate = fiatRate ? fiatRate : 0;
    return getTokenFiatValue(fiatRate, balance);
  }

  getTokenBalanceOfToken(token: any) {
    return this.smallToCrypto.transform(token.balance, { type: token.baseChainSymbol, precision: token.precision });
  }

  async openSendOrReceiveModal(asset) {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
    let cssClass = '';
    switch (this.actionType) {
      case 'sendAch':
      case 'send':
        cssClass += (this.isDesktopMode) ? 'send-asset-modal-desktop' : 'send-asset-modal';
        cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
        const sendModal = await this.modalController.create({
          component: SendAssetModal,
          componentProps: {
            mode: this.actionType,
            baseToken: asset?.baseChainSymbol,
            guid: asset?.guid,
            coinLogo: asset?.logo,
            tokenInfo: asset,
          },
          cssClass,
          backdropDismiss: false,
          id: 'sendAssetModal'
        });
        await sendModal.present();
        break;
      case 'receiveAch':
      case 'receive':
        cssClass += (this.isDesktopMode) ? 'send-asset-modal-desktop' : 'send-asset-modal';
        cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
        const receiveModal = await this.modalController.create({
          component: ReceiveAssetModal,
          componentProps: {
            tokenInfo: asset,
          },
          cssClass
        });
        await receiveModal.present();
        break;
      case 'move':
        const movingAssetNativeToken = this.tokens.find((token) => token.symbol === asset.baseChainSymbol);

        await this.modalController.dismiss(
          { tokenInfo: asset, movingAssetNetworkBalance: movingAssetNativeToken?.preciseBalance },
          '',
          'selectAssetModal'
        );
        break;
      default:
        await this.modalController.dismiss(asset); // trade
    }
  }

  SearchToken(ev) {
    this.filteredTokens = this.tokens.filter((item) => (
      item.name.toLowerCase().includes(ev.target.value.toLowerCase()) ||
      item.symbol.toLowerCase().includes(ev.target.value.toLowerCase())
    ));
  }

  isExpandChange(i, item) {
    if (item?.tokens?.length > 1 && !(['BTC', 'ETH', 'SYS', 'SOL']).includes(item.symbol)) {
      this.filteredTokens[i].isExpanded = !this.filteredTokens[i].isExpanded;
    } else {
      this.openSendOrReceiveModal(item);
    }
  }

  isExpandChangeChild(item) {
    this.openSendOrReceiveModal(item);
  }

  expandCard(index) {
    this.filteredTokens.forEach((element, i) => {
      if (i != index)
        element.isExpanded = false;
    });
    this.filteredTokens[index].isExpanded = !this.filteredTokens[index].isExpanded;
    const card: HTMLElement = document.querySelector('#card' + index);
    const textBlock = document.querySelector('#text-wrap' + index);
    const description: HTMLElement = document.querySelector(`#card${index} .text-wrap`);
    if (description != null) {
      if (parseInt(card.style.height.replace("px", "")) > 74) {
        card.style.height = '74px';
      } else {
        card.style.height = (74 + description.offsetHeight) + 'px';
      }
    }

    this.shrinkOthers(index);
  }

  shrinkOthers(index) {
    for (let i = 0; i < 3; i++) {
      if (index !== i) {
        const card: HTMLElement = document.querySelector('#card' + i);
        const textBlock = document.querySelector('#text-wrap' + i);
        const cardInner = document.querySelector('#cardId' + i);
        const description: HTMLElement = document.querySelector(`#card${index} .text-wrap`);
        if (description != null) {
          card.style.height = '74px';
        }
      }
    }
  }
  parseBaseChainSymbol(chain) {
    switch (chain) {
      case 'SYS':
        return 'Syscoin';
      case 'SOL':
        return 'Solana';
      case "AVAX":
        return "Avalanche";
      case 'ETH':
        return 'Ethereum';
      case 'SYSNEVM':
        return 'SYS-ROLLUX';
      default:
        return 'Unknown';
    }
  }
  
  async goToBuy(item) {
    let modal;
    const cssClass = (this.isDesktopMode) ? 'select-asset-modal-desktop' : 'select-asset-modal';
    if(this.actionType == 'buyAch') {
      this.modalController.dismiss();
      if(item.symbol == 'USD') {
        this.nav.navigateForward(['/exchange', item.symbol], { queryParams: { form: JSON.stringify({ payWith: PAYWITH.LODE, payWithDisplay: PAYMENT_MODES.LODE }) } });
      } else {
        this.nav.navigateForward(['/exchange', item.symbol], { queryParams: { form: JSON.stringify({ payWith: PAYWITH.USDBalance, payWithDisplay: PAYMENT_MODES.USD_BALANCE }) } });
      }
    } else {
      modal = await this.modalController.create({
        component: SelectPaymentModal,
        componentProps: {
          selectedToken: item.symbol,
          selectedTokenAddress: item.address,
          symbol: item.symbol
        },
        cssClass,
        id: 'select-payment-model',
        backdropDismiss: false,
        initialBreakpoint: (this.isDesktopMode) ? undefined : 0.9,
        breakpoints: (this.isDesktopMode) ? undefined : [0, 0.6, 0.9, 1],
      });
      await modal.present();
    }
  }

  async changeSelectionAch(item: SelectTokenInfo) {
    if(this.actionType == 'changeSelectionAch') {
      this.modalController.dismiss({asset: item});
    }
  }
  async openPurchaseHistory() {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();

    let cssClass = "";
    cssClass += this.isDesktopMode ? "select-asset-modal-desktop" : "select-asset-modal";
    cssClass += !sideMenuHidden && this.isDesktopMode ? " with-side-menu" : "";

    const modal = await this.modalController.create({
      component: PurchaseHistoryModalComponent,
      cssClass,
      initialBreakpoint: this.isDesktopMode ? undefined : 1,
      breakpoints: this.isDesktopMode ? undefined : [0, 0.6, 1],
    });
    await modal.present();
    await modal.onDidDismiss().then();
  }
}
