import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { MembersPortalService } from 'src/app/angular-wallet-base/services/members-portal.service';
import { ComplianceService } from "../../angular-wallet-base/services/compliance.service";
// import snsWebSdk from '@sumsub/websdk';
declare const snsWebSdk: any;
@Component({
  selector: "kyc-modal",
  templateUrl: "./kyc-modal.component.html",
  styleUrls: ["./kyc-modal.component.scss"]
})

export class KycModalComponent implements OnInit {
  public urlAPI;
  public flowName;
  public token;
  public canDismiss = false;

  constructor(
    private modal: ModalController,
    private translate: TranslateService,
    private membersPortal: MembersPortalService,
    private complianceService: ComplianceService
  ) { }

  ngOnInit() {
    this.launchWebSdk(this.token);
  }

  launchWebSdk(accessToken) {
    let snsWebSdkInstance = snsWebSdk.init(
      accessToken,
      // token update callback, must return Promise
      // Access token expired
      // get a new one and pass it to the callback to re-initiate the WebSDK
      () => this.getNewAccessToken()
    )
      .withConf({
        lang: this.translate.currentLang, //language of WebSDK texts and comments (ISO 639-1 format)           

      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      // see below what kind of messages WebSDK generates
      .on('idCheck.stepCompleted', (payload) => {
        Logger.info('stepCompleted', payload)
      })
      .on('idCheck.onError', (error) => {
        Logger.info('onError', error)
      })
      .build();

    // you are ready to go:
    // just launch the WebSDK by providing the container element for it
    snsWebSdkInstance.launch('#sumsub-websdk-container')
  }

  async getNewAccessToken() {
    const kycToken: string = await this.complianceService.createAccessToken();
    return kycToken;
  }

  cancelKYC() {
    this.modal.dismiss(true, "", "kyc-modal");
  }
}
