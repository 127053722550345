import { Injectable } from '@angular/core';
import { BlockbookTxNotifier } from 'blockbook-tx-notifier';
import { SyscoinWebsocketConfigService, SyscoinWebsocketService } from 'syscoin-websocket';
import { Logger } from './logger.service';
import { ToastController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  satoshiToNum,
  weiToNum,
  setNumberToPrecision,
  getToastPosition,
  getTransactionAmountsByAddress,
  getSendTypeValueFromtxAmount,
  getReceiveTypeValueFromtxAmount,
  getTransactionAmountsByAddressSol,
  formatTokenAmount,
  getTransactionAmountsByEVMAddress
} from '../utils';
import { Notifier } from '../Notifier';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { getKeychain } from '../store/wallet';
import { ZdagTransactionAction, WalletKeychain } from '../../global';
import {
  AddUnconfirmedTransactionAction,
  GetTokenBalancesRequestAction,
  ClearTxHistory,
  GetTokenHistoryRequestAction,
  RemoveUnconfirmedTransactionsAction,
  ZdagConfirmedTransactionAction,
  ZdagErrorTransactionAction
} from '../actions/wallet.actions';
import { ConnectSuccessAction, DisconnectAction, SetServiceStatusAction } from '../actions/connection.actions';
import { Subject } from 'rxjs';
import { NetworkService } from './network.service';
import { TokenManagerService } from './token-manager.service';
import { createKeyringId } from '../keyringUtils';
import { SmallToCryptoPipe } from '../../angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';
const sjs = require('syscoinjs-lib');
import { SocketService } from './custom-socket.service';
import { BigNumber, providers, utils } from 'ethers';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { OneInchService } from './one-inch.service';
import { inspect } from 'util';
import { RoutingService } from './routing.service';
import { GraphService } from './graph.service';
import { BlockBookService } from './blockbook.service';
import { startWith } from 'rxjs/operators';
import { interval } from 'rxjs';
import { isNil } from '../ts-util';
import { hexZeroPad } from 'ethers/lib/utils';
@Injectable()
export class WebSocketAddressNotifierService {
  private notifiers = [];
  private addresses: string[] = [];
  private keychain: WalletKeychain;
  private events$ = new Subject();
  public isConnected = false;
  socketService;
  private avaxBalance;
  private avaxProvider: providers.BaseProvider;
  private ethProvider: providers.BaseProvider;
  private sysnevmProvider: providers.BaseProvider;
  private ethLastBalance;
  private avaxTokensList = [];
  private avaxTokensGuids = [];
  private ethTokensList = [];
  private ethTokensGuids = [];
  private sysnevmBalance;
  private sysnevmTokensList = [];
  private sysnevmTokensGuids = [];
  private btcLastBalance;

  constructor(
    private toast: ToastController,
    private translate: TranslateService,
    private store: Store<AppState>,
    private syscoinWs: SyscoinWebsocketService,
    private syscoinWsConfig: SyscoinWebsocketConfigService,
    private networkService: NetworkService,
    private tokenManager: TokenManagerService,
    private authService: AuthService,
    private platform: Platform,
    private smallToCryptoPipe: SmallToCryptoPipe,
    private oneInchService: OneInchService,
    private routingService: RoutingService,
    private graphService: GraphService,
    private blockbookService: BlockBookService
  ) {
    this.store.select(getKeychain).subscribe(keychain => {
      this.keychain = keychain;
    });

  }

  async getSolApi() {
    const network = await this.networkService.getActiveSolNetwork();

    return {
      blockExplorer: network.URL,
      websocket: network.SOCKET_URL
    };
  }

  async getSysApi() {
    const network = await this.networkService.getActiveSysNetwork();

    return {
      blockExplorer: network.URL,
      websocket: network.SOCKET_URL,
      spt: network.SPT_SOCKET_URL
    };
  }

  async getBtcApi() {
    const network = await this.networkService.getActiveBtcNetwork();

    return {
      blockExplorer: network.SOCKET_URL,
      websocket: network.SOCKET_URL
    };
  }

  protected getBBTxToastAmount = (amount, tokenInfo) => {
    if (tokenInfo.baseChainSymbol === 'ETH') {
      if (tokenInfo.baseChainSymbol !== tokenInfo.symbol) {
        return setNumberToPrecision(amount, tokenInfo.precision || 8);
      }

      return weiToNum(amount);
    }

    return satoshiToNum(amount);
  }

  getCoinApiBasedOnType(type: string) {
    switch (type) {
      case 'sys':
        return this.getSysApi();
      case 'btc':
        return this.getBtcApi();
      case 'sol':
        return this.getSolApi();
    }
  }

  // TODO eric for notification
  public async newNotifier(address: string, type: string) {
    type = type.toLowerCase();
    let notifier;
    Logger.info(`Setting up ${type.toUpperCase()} notifier for address ${address}`);

    switch (type) {
      case 'spt':
        notifier = await this.handleSPTBalance(address);
        break;
      case 'sys':
        notifier = await this.handleBlockbookBalance(address, type);
        break;
      case 'btc':
        await this.handleBTCBalance(address, type);
        notifier = {
          notifier: true,
          type,
          address,
        }
        break;
      case 'avax':
        await this.handleBalancesOnAvax(address);
        notifier = {
          notifier: true,
          type,
          address,
        }
        break;
      case 'sysnevm':
        await this.handleBalancesOnSysnevm(address);
        notifier = {
          notifier: true,
          type,
          address,
        }
        break;
      case 'eth':
        await this.handleBalancesOnEth(address);
        notifier = {
          notifier: true,
          type,
          address,
        }
        break;
        /*
      case 'sol':
        await this.handleBalancesOnSolana(address, type);
        break; */
      default:
        Logger.error(`Not found type for newNotifier Setting up ${type.toUpperCase()} notifier for address ${address}`);
    }
    if (!notifier) {
      return;
    }

    this.notifiers.push(notifier);
  }

  private async handleBalancesOnSolana(address: string, type: string) {
    const coinApi = await this.getCoinApiBasedOnType(type);
    this.socketService = new SocketService(address, coinApi.websocket);
    this.socketService.getNewMessage().subscribe((message: any) => {
      if (message !== '') {
        this.handleMessage(message);
      }
    });
  }

  private async handleSPTBalance(address: string): Promise<Notifier> {

    const sysApi = await this.getSysApi();
    this.addresses.push(address);

    Logger.warn(`ZDAG Disabled, SPT notifier WONT be configured. Fallback to BB Notifier`);
    this.syscoinWsConfig.configure(sysApi.spt, address);

    const notifier = new Notifier({
      address,
      notifier: this.syscoinWs,
      type: 'spt',
      getConnectedMethod: 'connectedSubject',
      getTxMethod: 'txSubject',
      parser: event => event
    });

    notifier.connectedSubject$.subscribe(connected => this.handleConnectOrDisconnect(connected, 'SPT'));
    notifier.txSubject$.subscribe(this.handleMessage.bind(this));
    return notifier;
  }

  private async handleBTCBalance(address: string, type: 'btc') {
    const coinApi = await this.getBtcApi();
    Logger.info('handleBTCBalance', coinApi);
    try {
      // scheduler to 5 minutes 60000 * 5
      interval(60000 * 5).pipe(startWith(0)).subscribe(async (_) => {
        Logger.info('newBTCBalance ' + this.btcLastBalance);
        const userBTCBalance = await this.blockbookService.getBlockbookBalanceFromBTC(address);
        const newBTCBalance = parseInt(userBTCBalance.balance);
        Logger.info('newBTCBalance ' + this.btcLastBalance + ' new ', newBTCBalance);
        if (isNil(this.btcLastBalance)) {
          this.btcLastBalance = newBTCBalance;
        } else if (newBTCBalance !== this.btcLastBalance) {
          // Update balances
          this.store.dispatch(new GetTokenBalancesRequestAction({
            chains: [{
              symbol: 'BTC',
              address: address
            }]
          }));
          // update transaction
          this.store.dispatch(
            new GetTokenHistoryRequestAction({
              sysGuid: null,
              ethFilterIndex: null,
              address: address,
              pageNum: 1,
              itemsPerPage: 10,
              baseChainSymbol: 'BTC',
            }))

          const change = newBTCBalance - this.btcLastBalance;
          Logger.info('change btc', change);
          if (change > 0) {

            const toastMsg = await this.toast.create({
              header: await this.translate.get('new_tx_toast.title').toPromise(),
              message: await this.translate.get('new_tx_toast.message', {
                amount: formatTokenAmount(satoshiToNum(change)),
                symbol: 'BTC'
              }).toPromise(),
              position: getToastPosition(this.platform),
              duration: 4000,
            });
            toastMsg.present();
          }
          this.btcLastBalance = newBTCBalance;
        }
      });


    } catch (err) {
      Logger.error(`Failed to create ${type} notifier. Skipping...`, inspect(err));
    }
  }

  private async handleBlockbookBalance(address: string, type: 'sys' | 'btc'): Promise<Notifier> {
    const coinApi = await this.getCoinApiBasedOnType(type);
    let bbNotifier;
    try {
      bbNotifier = new BlockbookTxNotifier({
        address,
        url: coinApi.websocket,
        restUrl: coinApi.blockExplorer
      });
      const notifier = new Notifier({
        address,
        type,
        notifier: bbNotifier
      });
      notifier.txSubject$.subscribe(this.handleMessage.bind(this));
      notifier.connectedSubject$.subscribe(status => this.handleConnectOrDisconnect(status, type.toUpperCase()));
      return notifier;
    } catch (err) {
      Logger.error(`Failed to create ${type} notifier. Skipping...`, inspect(err));
    }
  }

  private async handleBalancesOnEth(address: string) {
    try {
      const ethNetwork = await this.networkService.getActiveEthNetwork();
      if (isNil(this.ethProvider)) {
        this.ethProvider = new providers.StaticJsonRpcProvider(ethNetwork.RPC_URL);
        this.ethProvider.pollingInterval = 30000;
        Logger.info(`handleBalancesOnEth ${new Date()} - ${inspect(this.ethProvider)}`);
      }
      
      await this.updateEthTokens();
      interval(30000).pipe(startWith(0)).subscribe(async (_) => {
        await this.handleEthBalance(address);
      })
      await this.handleNonNativeTokenBalancesOnEth(address);
    } catch (err) {
      Logger.error('handleBalancesOnEth', inspect(err));
    }
  }

  private async handleEthBalance(address: string) {
    this.ethProvider.getBalance(address).then((balance) => {
      Logger.info('handleEthBalance - ', new Date());
      const parsedBalance = utils.formatUnits(BigNumber.from(balance), 18);
      if (parsedBalance !== this.ethLastBalance) {
        this.store.dispatch(new GetTokenBalancesRequestAction({
          chains: [{
            symbol: 'ETH',
            address
          }]
        }));
        this.store.dispatch(new GetTokenHistoryRequestAction({
          sysGuid: null,
          ethFilterIndex: null,
          address,
          pageNum: 1,
          itemsPerPage: 10,
          baseChainSymbol: 'ETH',
        }));
        this.ethLastBalance = parsedBalance;
        Logger.info('handleEthBalance - new balance', parsedBalance);
        this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
      }
    });
  }

  public async updateEthTokens() {
    this.ethTokensList = [];
    this.ethTokensGuids = [];
    const tokenList = await this.tokenManager.getTokenList();
    for (const token of Object.values(tokenList)) {
      if (token['symbol'] !== 'ETH' && token['baseChainSymbol'] === 'ETH') {
        this.ethTokensList.push(token);
        this.ethTokensGuids.push(token['assetGuid'].toLowerCase());
      }
    }
  }

  private async handleNonNativeTokenBalancesOnEth(address: string) {
    const stripString = '0x000000000000000000000000';

    // listen for AVAX custom tokens for balance and transaction update
    const topicSets = [
      utils.id("Transfer(address,address,uint256)"),
      null,  // from address
      [ hexZeroPad(address.toLowerCase(), 32)]  // to address
    ]
    this.ethProvider.on(topicSets, async (log) => {
      Logger.info('handleNonNativeTokenBalancesOnEth - ', new Date());
      if (log.address && this.ethTokensGuids.includes(log.address.toLowerCase())) {
        const address1 = log.topics[1].replace(stripString, '0x');
        const address2 = log.topics[2].replace(stripString, '0x');
        if (
          address1.toLowerCase() === address.toLowerCase() ||
          address2.toLowerCase() === address.toLowerCase()
        ) {
          // Update balances
          this.store.dispatch(new GetTokenBalancesRequestAction({
            chains: [{
              symbol: 'ETH',
              address
            }]
          }));

          // update transaction
          this.store.dispatch(
            new GetTokenHistoryRequestAction({
              sysGuid: log.address,
              ethFilterIndex: null,
              address,
              pageNum: 1,
              itemsPerPage: 10,
              baseChainSymbol: 'ETH',
            })
          );
          this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
          // TODO: temp toast for ETH contract tokens
          if ((address1.toLowerCase() !== address.toLowerCase())) {
            Logger.info('received ERC20 token', log);
            const receivedToken = this.ethTokensList.find(token => (token.assetGuid.toLowerCase() === log.address.toLowerCase()));
            const toastMsg = await this.toast.create({
              header: await this.translate.get('new_tx_toast.title').toPromise(),
              message: await this.translate.get('new_tx_toast.message', {
                amount: formatTokenAmount(parseInt(log.data, 16) / (10 ** receivedToken.precision)),
                symbol: (receivedToken && receivedToken.symbol) ? receivedToken.symbol : 'Tokens'
              }).toPromise(),
              position: getToastPosition(this.platform),
              duration: 4000,
            });
            toastMsg.present();
          }
        }
      }
    });
  }


  private async handleBalancesOnAvax(address: string) {
    try {
      const network = await this.networkService.getActiveAvaxNetwork();
      if (isNil(this.avaxProvider)){
        Logger.info(`create new avaxProvider at ${new Date()}`);
        this.avaxProvider = new providers.StaticJsonRpcProvider(network.RPC_URL);
        // set polling to 8 seconds
        this.avaxProvider.pollingInterval = 10000;
      }
      Logger.info(`handleBalancesOnAvax - start at ${new Date()} - ${inspect(this.avaxProvider)}`);
      
      await this.updateAvaxTokens();

      interval(10000).pipe(startWith(0)).subscribe(async (_) => {
        Logger.info('handleAvaxBalance - interval - ', new Date());
        await this.handleAvaxBalance(address);
      })
      await this.handleNonNativeTokenBalancesOnAvax(address);
      
    } catch (err) {
      Logger.error('handleBalancesOnAvax - ', inspect(err));
    }
  }

  public async updatesysnevmTokens() {
    this.sysnevmTokensList = [];
    this.sysnevmTokensGuids = [];
    const tokenList = await this.tokenManager.getTokenList();
    for (const token of Object.values(tokenList)) {
      if (token['symbol'] !== 'SYSNEVM' && token['baseChainSymbol'] === 'SYSNEVM ') {
        this.sysnevmTokensList.push(token);
        this.sysnevmTokensGuids.push(token['assetGuid'].toLowerCase());
      }
    }
  }

  private async handleBalancesOnSysnevm(address:string) {
    try {
      const network = await this.networkService.getActiveSysnevmNetwork();
      if (isNil(this.sysnevmProvider)){
        Logger.info(`create new sysnevmProvider at ${new Date()}`);
        this.sysnevmProvider = new providers.StaticJsonRpcProvider(network.RPC_URL);
        // set polling to 8 seconds
        this.sysnevmProvider.pollingInterval = 30000;
      }
      Logger.info(`handleBalancesOnSysnevm - start at ${new Date()} - ${inspect(this.sysnevmProvider)}`);
      
      await this.updatesysnevmTokens();

      interval(30000).pipe(startWith(0)).subscribe(async (_) => {
        
        await this.handleSysnevmBalance(address);
      })
      await this.handleNonNativeTokenBalancesOnSysnevm(address);
      
    } catch (err) {
      Logger.error('handleBalancesOnSysnevm - ', inspect(err));
    }

  }

    private async handleSysnevmBalance(address: string) {
    // listen for native AVAX for balance and transaction update
    this.sysnevmProvider.getBalance(address).then(async (balance) => {
      Logger.info('handleSysnevmBalance - ', new Date());
      const parsedBalance = utils.formatUnits(BigNumber.from(balance), 18);
      if (parsedBalance !== this.sysnevmBalance) {
        // Update balances
        this.store.dispatch(new GetTokenBalancesRequestAction({
          chains: [{
            symbol: 'SYSNEVM',
            address
          }]
        }));
        // update transaction
        this.store.dispatch(
          new GetTokenHistoryRequestAction({
            sysGuid: null,
            ethFilterIndex: null,
            address,
            pageNum: 1,
            itemsPerPage: 10,
            baseChainSymbol: 'SYSNEVM',
          })
        );

        // Notification if we receive sysnevm
        if (Number(parsedBalance) > Number(this.sysnevmBalance)) {
          const toastMsg = await this.toast.create({
            header: await this.translate.get('new_tx_toast.title').toPromise(),
            message: await this.translate.get('new_tx_toast.message', {
              amount: formatTokenAmount(Number(parsedBalance) - Number(this.sysnevmBalance)),
              symbol: 'SYSNEVM'
            }).toPromise(),
            position: getToastPosition(this.platform),
            duration: 4000,
          });
          toastMsg.present();
        }
        Logger.info('handleSysnevmBalance - new balance', { parsedBalance, sysnevmBalance: this.sysnevmBalance });
        this.sysnevmBalance = parsedBalance;
        this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
      }
    });
  }

  private async handleAvaxBalance(address: string) {
    // listen for native AVAX for balance and transaction update
    this.avaxProvider.getBalance(address).then(async (balance) => {
      Logger.info('handleAvaxBalance - ', new Date());
      const parsedBalance = utils.formatUnits(BigNumber.from(balance), 18);
      if (parsedBalance !== this.avaxBalance) {

        Logger.info('handleAvaxBalance - test - new balance', { parsedBalance, avaxBalance: this.avaxBalance });
        // Update balances
        this.store.dispatch(new GetTokenBalancesRequestAction({
          chains: [{
            symbol: 'AVAX',
            address
          }]
        }));
        // update transaction
        this.store.dispatch(
          new GetTokenHistoryRequestAction({
            sysGuid: null,
            ethFilterIndex: null,
            address,
            pageNum: 1,
            itemsPerPage: 10,
            baseChainSymbol: 'AVAX',
          })
        );
        // Notification if we receive AVAX
        if (Number(parsedBalance) > Number(this.avaxBalance)) {
          const toastMsg = await this.toast.create({
            header: await this.translate.get('new_tx_toast.title').toPromise(),
            message: await this.translate.get('new_tx_toast.message', {
              amount: formatTokenAmount(Number(parsedBalance) - Number(this.avaxBalance)),
              symbol: 'AVAX'
            }).toPromise(),
            position: getToastPosition(this.platform),
            duration: 4000,
          });
          toastMsg.present();
        }
        Logger.info('handleAvaxBalance - new balance', { parsedBalance, avaxBalance: this.avaxBalance });
        this.avaxBalance = parsedBalance;
        this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
      }
    });
  }

  public async updateAvaxTokens() {
    this.avaxTokensList = [];
    this.avaxTokensGuids = [];
    const tokenList = await this.tokenManager.getTokenList();
    for (const token of Object.values(tokenList)) {
      if (token['symbol'] !== 'AVAX' && token['baseChainSymbol'] === 'AVAX') {
        this.avaxTokensList.push(token);
        this.avaxTokensGuids.push(token['assetGuid'].toLowerCase());
      }
    }
  }

  private async handleNonNativeTokenBalancesOnAvax(address: string) {
    const stripString = '0x000000000000000000000000';

    const topicSendToSets = [
      utils.id("Transfer(address,address,uint256)"),
      null,
      [hexZeroPad(address.toLowerCase(), 32),]
    ]
    this.avaxProvider.on(topicSendToSets, async (log) => {
      Logger.info('handleNonNativeTokenBalancesOnAvax - ', new Date());
      if (log.address && this.avaxTokensGuids.includes(log.address.toLowerCase())) {
        const address1 = log.topics[1].replace(stripString, '0x');
        const address2 = log.topics[2].replace(stripString, '0x');
        if (
          address1.toLowerCase() === address.toLowerCase() ||
          address2.toLowerCase() === address.toLowerCase()
        ) {
          // Update balances
          this.store.dispatch(new GetTokenBalancesRequestAction({
            chains: [{
              symbol: 'AVAX',
              address
            }]
          }));

          // update transaction
          this.store.dispatch(
            new GetTokenHistoryRequestAction({
              sysGuid: log.address,
              ethFilterIndex: null,
              address,
              pageNum: 1,
              itemsPerPage: 10,
              baseChainSymbol: 'AVAX',
            })
          );
          this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
          // TODO: temp toast for AVAX contract tokens
          if ((address1.toLowerCase() !== address.toLowerCase())) {
            Logger.info('received ERC20 token', log);
            const receviedToken = this.avaxTokensList.find(token => (token.assetGuid.toLowerCase() === log.address.toLowerCase()));
            const tokenDecimal = (receviedToken && receviedToken.assetGuid)
              ? await this.oneInchService.getDecimal(receviedToken.assetGuid)
              : 18;
            const toastMsg = await this.toast.create({
              header: await this.translate.get('new_tx_toast.title').toPromise(),
              message: await this.translate.get('new_tx_toast.message', {
                amount: formatTokenAmount(parseInt(log.data, 16) / (10 ** tokenDecimal)),
                symbol: (receviedToken && receviedToken.symbol) ? receviedToken.symbol : 'Tokens'
              }).toPromise(),
              position: getToastPosition(this.platform),
              duration: 4000,
            });
            toastMsg.present();
          }
        }
      }
    });
  }
  

  private async handleNonNativeTokenBalancesOnSysnevm(address: string) {
  const stripString = '0x000000000000000000000000';

  const topicSets = [
    utils.id("Transfer(address,address,uint256)"),
    null,
    [hexZeroPad(address.toLowerCase(), 32),]
  ]
  this.sysnevmProvider.on(topicSets, async (log) => {
    Logger.info('handleNonNativeTokenBalancesOnSysnevm - ', new Date());
    if (log.address && this.sysnevmTokensGuids.includes(log.address.toLowerCase())) {
      const address1 = log.topics[1].replace(stripString, '0x');
      const address2 = log.topics[2].replace(stripString, '0x');
      if (
        address1.toLowerCase() === address.toLowerCase() ||
        address2.toLowerCase() === address.toLowerCase()
      ) {
        // Update balances
        this.store.dispatch(new GetTokenBalancesRequestAction({
          chains: [{
            symbol: 'SYSNEVM', // Adjust the symbol to match the SYSNEVM chain symbol
            address
          }]
        }));

        // Update transaction history
        this.store.dispatch(
          new GetTokenHistoryRequestAction({
            sysGuid: log.address,
            ethFilterIndex: null,
            address,
            pageNum: 1,
            itemsPerPage: 10,
            baseChainSymbol: 'SYSNEVM', // Adjust the symbol to match the SYSNEVM chain symbol
          })
        );
        this.graphService.updateGraphAfterEveryTransaction(); // Update the balance in the graph after each transaction

        // TODO: temp toast for SYSNEVM contract tokens
        if ((address1.toLowerCase() !== address.toLowerCase())) {
          Logger.info('received ERC20 token on SYSNEVM', log);
          const receivedToken = this.sysnevmTokensList.find(token => (token.assetGuid.toLowerCase() === log.address.toLowerCase()));
          const tokenDecimal = (receivedToken && receivedToken.assetGuid)
            ? await this.oneInchService.getDecimal(receivedToken.assetGuid)
            : 18; // Update the decimal value for the token

          const toastMsg = await this.toast.create({
            header: await this.translate.get('new_tx_toast.title').toPromise(),
            message: await this.translate.get('new_tx_toast.message', {
              amount: formatTokenAmount(parseInt(log.data, 16) / (10 ** tokenDecimal)),
              symbol: (receivedToken && receivedToken.symbol) ? receivedToken.symbol : 'Tokens'
            }).toPromise(),
            position: getToastPosition(this.platform),
            duration: 4000,
          });
          toastMsg.present();
        }
      }
    }
  });
}

  private handleMessage(event) {
    Logger.info(`new ${event.txType.toUpperCase()} message: `, event);

    if (this.isZdagEvent(event)) {
      return this.handleZdagEvent(event);
    }

    if (this.isCustomWebSocket(event)) {
      if (event.confirmed) {
        return this.handleConfirmedWebSocketEvent(event);
      } else {
        return this.handleWebSocketEvent(event);
      }
    }

    if (event.confirmed) {
      this.handleConfirmedMessage(event);
    } else if (event.topic === 'rejected_txs') {
      this.handleRejectedTxsMessage(event);
    } else {
      this.privateUnconfirmedMessage(event);
    }
  }

  // Eric TODO for ETH case
  async handleWebSocketEvent(event) {
    const data = event.tx;

    if (!data) {
      return Logger.error('Skipping event due to incorrect format');
    }

    // Gather a bunch of data for toast/store
    const txid = data.txid;
    const baseChainSymbol = this.getBaseChainSymbolBasedOnTxType(event.txType);
    const bbTx = data;
    const walletAddress = event?.address;
    const tokenList = await this.tokenManager.getTokenList();
    let tokenAndTransferInfo = [];
    let txAmounts = {};
    switch (event.txType) {
      case 'sol':
      case 'spl-sol':
        txAmounts = getTransactionAmountsByAddressSol(bbTx, walletAddress);
        tokenAndTransferInfo = bbTx.tokenTransfers ?
          bbTx.tokenTransfers
            .map(transfer => this.createTokenTransferSolAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
            .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
            .filter(tt => tt.amount) // only keep transfers that actually have value
          : [];
        break;
      case 'avax':
      case 'erc20-avax':
        txAmounts = getTransactionAmountsByEVMAddress(bbTx, walletAddress);
        tokenAndTransferInfo = bbTx.tokenTransfers ?
          bbTx.tokenTransfers
            .map(transfer => this.createTokenTransferAvaxAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
            .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
            .filter(tt => tt.amount) // only keep transfers that actually have value
          : [];
        break;
      default:
        Logger.error('Error type coming from websocket');
        break;
    }

    // add baseChain transfer to tokenAndTransferInfo (toast wont be shown otherwise)
    tokenAndTransferInfo.push({
      precision: this.keychain[baseChainSymbol].precision,
      guid: baseChainSymbol,
      keyringId: createKeyringId(baseChainSymbol),
      amount: txAmounts['totalValue'],
      symbol: baseChainSymbol
    });

    if (txAmounts['type'] === 'receive') {
      // Fire notifications
      this.fireToastBasedOnTokenTransfers(tokenAndTransferInfo, baseChainSymbol);
    }

    const unconfirmedTx: any = {
      txid,
      time: Date.now(),
      tx: bbTx,
      // This is not 100% accurate, but will work in most cases
      // Bigger refactor is required in order to work with new SYS core standards
      tokenInfo: tokenAndTransferInfo[0]
    };

    // Update balances
    this.store.dispatch(new GetTokenBalancesRequestAction({
      chains: [{
        symbol: baseChainSymbol,
        address: event.address
      }]
    }));

    // Add utx to store
    this.store.dispatch(new AddUnconfirmedTransactionAction({ transaction: unconfirmedTx }));
    this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
  }

  async handleConfirmedWebSocketEvent(event) {
    const data = event.tx;

    if (!data) {
      return Logger.error('Skipping event due to incorrect format');
    }

    const txid = data.txid;
    const baseChainSymbol = this.getBaseChainSymbolBasedOnTxType(event.txType);
    const bbTx = data;
    const walletAddress = event?.address;
    let txAmounts = {};
    const tokenList = await this.tokenManager.getTokenList();
    let assetGuid;
    let tokenAndTransferInfo = [];
    let itemPerPage = 25;
    switch (event.txType) {
      case 'sol':
      case 'spl-sol':
        txAmounts = getTransactionAmountsByAddressSol(bbTx, walletAddress);
        tokenAndTransferInfo = bbTx.tokenTransfers ?
          bbTx.tokenTransfers
            .map(transfer => this.createTokenTransferSolAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
            .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
            .filter(tt => tt.amount) // only keep transfers that actually have value
          : [];
        itemPerPage = environment.SOLANA_HISTORY_LIMIT;
        break;
      case 'avax':
      case 'erc20-avax':
        txAmounts = getTransactionAmountsByEVMAddress(bbTx, walletAddress);
        tokenAndTransferInfo = bbTx.tokenTransfers ?
          bbTx.tokenTransfers
            .map(transfer => this.createTokenTransferAvaxAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
            .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
            .filter(tt => tt.amount) // only keep transfers that actually have value
          : [];
        break;
      default:
        Logger.error('Error type coming from websocket');
        break;
    }

    // add baseChain transfer to tokenAndTransferInfo (toast wont be shown otherwise)
    tokenAndTransferInfo.push({
      guid: baseChainSymbol,
      keyringId: createKeyringId(baseChainSymbol)
    });

    if (tokenAndTransferInfo.length > 1) {
      // tokenAndTransferInfo holds more than just the baseChain transfer
      assetGuid = tokenAndTransferInfo[0].guid;
    }

    // Update balances affected
    this.store.dispatch(new GetTokenBalancesRequestAction({
      chains: [{
        symbol: baseChainSymbol,
        address: event.address
      }]
    }));

    const ethFilterIndex = this.keychain[tokenAndTransferInfo[0].keyringId].ethFilterIndex;

    // Update affected tx history
    this.store.dispatch(new GetTokenHistoryRequestAction({
      sysGuid: assetGuid, // undefined if not erc20
      address: event.address,
      pageNum: 1,
      itemsPerPage: itemPerPage,
      baseChainSymbol,
      ethFilterIndex
    }));
    this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
  }

  private async privateUnconfirmedMessage(event) {
    const data = event.tx;

    if (!data) {
      return Logger.error('Skipping event due to incorrect format');
    }

    // Gather a bunch of data for toast/store
    const network = await this.getNetworkBasedOnTxType(event.txType);
    const txid = data.txid;
    const bbTx = await sjs.utils.fetchBackendRawTx(network.URL, txid);
    const baseChainSymbol = this.getBaseChainSymbolBasedOnTxType(event.txType);
    const walletAddress = this.getNotifierOfType(baseChainSymbol)?.address;
    const txAmounts = getTransactionAmountsByAddress(bbTx, walletAddress, baseChainSymbol === 'ETH');
    const tokenList = await this.tokenManager.getTokenList();
    const tokenAndTransferInfo = bbTx.tokenTransfers ?
      bbTx.tokenTransfers
        .map(transfer => this.createTokenTransferAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
        .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
        .filter(tt => tt.amount) // only keep transfers that actually have value
      : [];

    // add baseChain transfer to tokenAndTransferInfo (toast wont be shown otherwise)
    tokenAndTransferInfo.push({
      precision: this.keychain[baseChainSymbol].precision,
      guid: baseChainSymbol,
      keyringId: createKeyringId(baseChainSymbol),
      amount: txAmounts.type === 'send' ?
        getSendTypeValueFromtxAmount(txAmounts, walletAddress, true)
        : getReceiveTypeValueFromtxAmount(txAmounts, walletAddress, true),
      symbol: baseChainSymbol
    });


    if (txAmounts.type === 'receive') {
      // Fire notifications
      this.fireToastBasedOnTokenTransfers(tokenAndTransferInfo, baseChainSymbol);
    }

    const unconfirmedTx: any = {
      txid,
      time: Date.now(),
      tx: bbTx,
      // This is not 100% accurate, but will work in most cases
      // Bigger refactor is required in order to work with new SYS core standards
      tokenInfo: tokenAndTransferInfo[0]
    };

    // Update balances
    this.store.dispatch(new GetTokenBalancesRequestAction({
      chains: [{
        symbol: baseChainSymbol,
        address: event.address
      }]
    }));

    // Add utx to store
    this.store.dispatch(new AddUnconfirmedTransactionAction({ transaction: unconfirmedTx }));
    this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
  }

  async handleConfirmedMessage(event) {
    const data = event.tx;

    if (!data) {
      return Logger.error('Skipping event due to incorrect format');
    }

    // Gather a bunch of data for store
    const network = await this.getNetworkBasedOnTxType(event.txType);
    const txid = data.txid;
    const bbTx = await sjs.utils.fetchBackendRawTx(network.URL, txid);
    const notifierType = event.notifierType;
    const baseChainSymbol = this.getBaseChainSymbolBasedOnTxType(notifierType);
    const walletAddress = this.getNotifierOfType(baseChainSymbol)?.address;
    const txAmounts = getTransactionAmountsByAddress(bbTx, walletAddress, baseChainSymbol === 'ETH');

    const tokenList = await this.tokenManager.getTokenList();
    let assetGuid;

    const tokenAndTransferInfo = bbTx.tokenTransfers ?
      bbTx.tokenTransfers
        .map(transfer => this.createTokenTransferAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts))
        .filter(tt => Object.keys(tokenList).includes(tt.keyringId)) // only keep transfers that belong to supported asset list
        .filter(tt => tt.amount) // only keep transfers that actually have value
      : [];


    // add baseChain transfer to tokenAndTransferInfo (toast wont be shown otherwise)
    tokenAndTransferInfo.push({
      guid: baseChainSymbol,
      keyringId: createKeyringId(baseChainSymbol)
    });

    if (tokenAndTransferInfo.length > 1) {
      // tokenAndTransferInfo holds more than just the baseChain transfer
      assetGuid = tokenAndTransferInfo[0].guid;
    }

    // Update balances affected
    this.store.dispatch(new GetTokenBalancesRequestAction({
      chains: [{
        symbol: baseChainSymbol,
        address: event.address
      }]
    }));

    const ethFilterIndex = this.keychain[tokenAndTransferInfo[0].keyringId].ethFilterIndex;

    // Update affected tx history
    this.store.dispatch(new GetTokenHistoryRequestAction({
      sysGuid: assetGuid, // undefined if not erc20
      address: event.address,
      pageNum: 1,
      itemsPerPage: 20,
      baseChainSymbol,
      ethFilterIndex
    }));
    this.graphService.updateGraphAfterEveryTransaction(); // update the balance in graph after each transactions
  }

  getBaseChainSymbolBasedOnTxType(txType) {
    switch (txType.toLowerCase()) {
      case 'erc20':
      case 'eth':
        return 'ETH';
      case 'sys':
      case 'spt':
        return 'SYS';
      case 'btc':
        return 'BTC';
      case 'avax':
      case 'erc20-avax':
        return 'AVAX';
      case 'sol':
      case 'spl-sol':
        return 'SOL';
    }
  }

  isZdagEvent(event) {
    const data = event.message;

    return data?.isZdag || event.zdagTx;
  }

  isCustomWebSocket(event) {
    const data = event.txType.toUpperCase();
    return data === 'AVAX' || data === 'ERC20-AVAX' || data === 'SOL' || data === 'SPL-SOL';
  }

  handleZdagEvent(event) {
    const data = event.message;
    const actionPayload: ZdagTransactionAction = {
      txid: data.tx.txid,
      zdag_confirmed: null,
      zdag_error: null
    };

    setTimeout(() => {
      // Artificially add 1s timeout to this
      // When refetching unconf tx, most of the time this message will come before the one from BB (that adds the utx to the store).
      if (data.status === 0) {
        actionPayload.zdag_confirmed = true;
        this.store.dispatch(new ZdagConfirmedTransactionAction(actionPayload));
      } else if (Number.isInteger(data.status) && data.status !== 0) {
        actionPayload.zdag_confirmed = false;
        actionPayload.zdag_error = true;
        this.store.dispatch(new ZdagErrorTransactionAction(actionPayload));
      }
    }, 10000);
  }

  fireToastBasedOnTokenTransfers(tokenTransfers, baseChainSymbol) {
    let timeToFire = 0;
    tokenTransfers.forEach(tokenTransfer => {
      const toastDuration = 4000;
      setTimeout(async () => {
        const toastMsg = await this.toast.create({
          header: await this.translate.get('new_tx_toast.title').toPromise(),
          message: await this.translate.get('new_tx_toast.message', {
            amount: this.smallToCryptoPipe.transform(tokenTransfer.amount, { type: baseChainSymbol, precision: tokenTransfer.precision }),
            symbol: tokenTransfer.symbol
          }).toPromise(),
          position: getToastPosition(this.platform),
          duration: toastDuration,
          buttons: [{
            text: 'Refresh',
            handler: async () => {
              this.events$.next('go_history_top');
              this.store.dispatch(new ClearTxHistory({ keyringId: tokenTransfer.keyringId }));
              await this.routingService.goHome();
            }
          }]
        });

        toastMsg.present();
      }, timeToFire);
      timeToFire += toastDuration;
    });
  }

  getNetworkBasedOnTxType(txType) {
    switch (txType) {
      case 'sys':
      case 'spt':
        return this.networkService.getActiveSysNetwork();
      case 'eth':
      case 'erc20':
        return this.networkService.getActiveEthNetwork();
      case 'btc':
        return this.networkService.getActiveBtcNetwork();
      case 'avax':
      case 'erc20-avax':
        return this.networkService.getActiveAvaxNetwork();
      case 'sol':
      case 'spl-sol':
        return this.networkService.getActiveSolNetwork();
    }
  }

  createTokenTransferAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts) {
    const info: any = {
      precision: transfer.decimals,
      guid: transfer.token,
      keyringId: createKeyringId(baseChainSymbol, transfer.token),
      amount: txAmounts.type === 'send' ?
        getSendTypeValueFromtxAmount(txAmounts, walletAddress, false, transfer.token)
        : getReceiveTypeValueFromtxAmount(txAmounts, walletAddress, false, transfer.token)
    };

    try {
      info.symbol = atob(this.keychain[info.keyringId] ? this.keychain[info.keyringId].symbol : transfer.symbol);
    } catch (err) {
      info.symbol = this.keychain[info.keyringId] ? this.keychain[info.keyringId].symbol : transfer.symbol;
    }
    return info;
  }

  createTokenTransferAvaxAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts) {

    const info: any = {
      precision: transfer.decimals,
      guid: transfer.token,
      keyringId: createKeyringId(baseChainSymbol, transfer.token),
      amount: transfer.value
    };

    info.symbol = this.keychain[info.keyringId] ? this.keychain[info.keyringId].symbol : transfer.symbol;
    return info;
  }

  createTokenTransferSolAndInfoObject(transfer, baseChainSymbol, walletAddress, txAmounts) {

    const info: any = {
      precision: transfer.decimals,
      guid: transfer.token,
      keyringId: createKeyringId(baseChainSymbol, transfer.token),
      amount: transfer.value
    };

    info.symbol = this.keychain[info.keyringId] ? this.keychain[info.keyringId].symbol : transfer.symbol;
    return info;
  }

  private async handleRejectedTxsMessage(data) {
    Logger.info('handle rejected txs');
    const transactionIds = data.message;
    this.store.dispatch(new RemoveUnconfirmedTransactionsAction({ transactionIds }));
  }

  private handleConnectOrDisconnect(connected, type) {
    Logger.info(`${type} socket status: `, connected);
    this.setServiceStatusInStore(type, connected);

    if (connected === null) {
      return; // notifier is just initing
    }

    let notifiersRunning = 0;
    let waitForNotifiers = false; // wait for all notifiers to report some non-null status
    this.notifiers.forEach(notifier => {
      if (notifier.connectedSubject$.value === null) {
        // all notifiers are not ready yet
        waitForNotifiers = true;
        return;
      }

      if (notifier.connectedSubject$.value === true) {
        notifiersRunning++;
      }
    });

    if (!waitForNotifiers) {
      if (notifiersRunning === this.notifiers.length) {
        this.store.dispatch(new ConnectSuccessAction());
      } else {
        this.store.dispatch(new DisconnectAction());
      }
    }
  }

  public hasNotifierOfType(type: string) {
    type = type.toLowerCase();
    return !!this.notifiers.find(notifier => notifier.type === type);
  }

  public getNotifierOfType(type: string) {
    const lowercaseType = type.toLowerCase();
    return this.notifiers.find(notifier => notifier.type === lowercaseType);
  }

  public getEvents() {
    return this.events$;
  }

  public logNotifiers() {
    Logger.info('Current notifiers: ', this.notifiers);
  }

  clearNotifiers() {
    Logger.info('Cleared notifiers');
    try { this.notifiers.forEach(notifier => notifier.disconnect()); } catch (error) { }
    try { this.avaxProvider.removeAllListeners(); } catch (error) { }
    this.notifiers = [];
    this.avaxProvider = null;
  }

  public getWebSocketStatus(type: string) {
    const notifier = this.notifiers.find(notifierInfo => notifierInfo.type === type);
    
    if (notifier) {
      if (type == 'sys' || type == 'spt') {
        notifier.connectedSubject$.subscribe(connected => this.isConnected = connected);
      } else {
        this.isConnected = notifier.notifier;
      }
    }
    return this.isConnected;
  }

  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services['websocket' + type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }

}
