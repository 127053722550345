import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { ILoadingIndicator, IndicatorType, LoadingIndicatorService } from "../../services/loading-indicator.service";
import { AbstractDebounceDirective } from "./abstract-debounce.directive";

@Directive({
  selector: "[appDebounceClick]",
})
export class DebounceClickDirective extends AbstractDebounceDirective {
  @Input("loadingIndicator") loadingIndicator: string;
  @Output("debounceClick") debounceClick = new EventEmitter();

  constructor(private el: ElementRef, private loadingIndicatorService: LoadingIndicatorService) {
    super();
  }

  @HostListener("click", ["$event"])
  onClick(event) {
    event.preventDefault();
    this.emitEvent$.next(event);
  }

  public emitChange(value: any): void {
    this.debounceClick.emit(value);
  }
}
