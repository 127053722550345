import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxKjuaModule } from 'ngx-kjua';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionHistoryModule } from 'src/app/components/transaction-history/transaction-history.module';
import { CustomCurrencyPipeModule } from 'src/app/angular-wallet-base/pipes/customcurrency/customcurrency.module';
import { SmallToCryptoPipeModule } from 'src/app/angular-wallet-base/pipes/smalltocrypto/smalltocrypto.module';
import { MaxDecimalsModule } from 'src/app/angular-wallet-base/directives/max-decimal-digits/max-decimals.module';
import { TransactionSenderService } from 'src/app/angular-wallet-base/services/transaction-sender.service';
import { SmallToCryptoPipe } from 'src/app/angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';
import { QrcodeModule } from 'src/app/components/qrcode/qrcode.module';
import { DebounceDirectiveModule } from 'src/app/angular-wallet-base/directives/debounce/debounce-directive.module';
import { ConfirmModalComponent } from './confirm-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NgxKjuaModule,
    TranslateModule,
    TransactionHistoryModule,
    ReactiveFormsModule,
    CustomCurrencyPipeModule,
    SmallToCryptoPipeModule,
    MaxDecimalsModule,
    QrcodeModule,
    DebounceDirectiveModule,
  ],
  exports: [],
  providers: [TransactionSenderService, SmallToCryptoPipe],
  declarations: [ConfirmModalComponent]
})
export class ConfirmModalModule { }
