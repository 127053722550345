import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { RoutingService } from '../../angular-wallet-base/services/routing.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';

@Component({
  selector: 'app-simplex-modal-checkout',
  templateUrl: './simplex-modal-checkout.component.html',
  styleUrls: ['./simplex-modal-checkout.component.scss'],
})
export class SimplexModalCheckoutComponent implements OnInit {

  public url;

  constructor(private routingService: RoutingService, private modalController: ModalController, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    Logger.info(this.url);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
  }


  dismiss() {
    this.modalController.dismiss();
    this.routingService.goHome();
  }
}
