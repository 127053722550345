import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NetworkService } from './network.service';
import { RpcProvider } from './rpc-provider';
import { environment } from 'src/environments/environment';
import { BaseChainSymbol, TokenKeyring } from 'src/app/global';
import { BlockbookGetAddressResponse, BlockbookGetAddressTxsResponse } from './blockbook';
import { calculateUnconfBalance, createTokenKeyring } from '../utils';
import BigNumber from 'bignumber.js';
import { createKeyringId } from '../keyringUtils';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { TokenManagerService } from './token-manager.service';
import { ethers, providers, BigNumber as ethersBigNumber } from 'ethers';
import { ERC20_ABI_GSD_DAO } from '../constants';
import { Logger } from './logger.service';
import { inspect } from 'util';
import { OneInchService } from './one-inch.service';
import { isNil } from '../ts-util';
import { EvmNetworkService } from './evm-network.service';

export class JsonRpcBody {
  id: string;
  method: string;
  params: any[]
}

@Injectable({ providedIn: 'root' })
export class AvalancheService{

  constructor(
    protected http: HttpClient,
    private networkService: NetworkService,
    private rpcProvider: RpcProvider,
    private store: Store<AppState>,
    private tokenManager: TokenManagerService,
    private oneInchService: OneInchService,
  ) { }

  async getAvaxRpcUrl() {
    const network = await this.networkService.getActiveAvaxNetwork();
    return `${network.RPC_URL}`;
  }

  async getAvaxSnowTraceApi() {
    const network = await this.networkService.getActiveAvaxNetwork();
    return `${network.SNOWTRACE_URL}`;
  }

  async checkSnowTraceStatus() {
    try {
      const url = await this.getAvaxSnowTraceApi() + '/api?module=stats&action=AVAXsupply&apikey=' + environment.SNOWTRACE_API_KEY;
      await this.http.get(url).toPromise();
      return true;
    } catch (err) {
      Logger.error('checkSnowTraceStatus', inspect(err));
      return false;
    }
  }

  async getAvaxTokenBalances(address): Promise<TokenKeyring[] | object[]> {
    try {
      const url = await this.getAvaxRpcUrl();
      const data = await this.rpcProvider.rpc(url, 'eth_getBalance', [address, 'latest']).toPromise()
      const response: BlockbookGetAddressResponse = {};
      response.balance = new BigNumber(data?.result).toString();
      response.address = address;
      const avaxKeyring = createTokenKeyring({
        symbol: 'AVAX',
        address: address,
        balance: response.balance,
        baseChainSymbol: 'AVAX',
        guid: undefined,
        ethFilterIndex: undefined,
        precision: 18
      });

      // Add ERC20 Tokens manually
      response.tokens = [];
      const addressErc20wallet = address.substring(2);
      const supportedAssetList = await this.tokenManager.getTokenList();

      let ercContractsList = Object.keys(supportedAssetList).filter((keyringId: string) => keyringId.indexOf('AVAX-') !== -1);
      //filter out any ercContract that starts with PT
      ercContractsList = ercContractsList.filter((keyringId: string) => keyringId.indexOf('PT') === -1);
      for (const erc20Contract of ercContractsList) {
        const contract = erc20Contract.replace('AVAX-', '');
        const balanceErc20 = await this.rpcProvider
          .rpc(url, 'eth_call', [
            {
              to: `${contract}`,
              data: `0x70a08231000000000000000000000000${addressErc20wallet}`,
            },
            'latest',
          ])
          .toPromise();

        const erc20Obj = {
          balance: 0,
          contract,
          name: supportedAssetList[erc20Contract].name,
          symbol: supportedAssetList[erc20Contract].symbol,
          type: 'ERC20',
          decimals: supportedAssetList[erc20Contract].decimal,
        };

        erc20Obj.balance = balanceErc20 !== undefined ? new BigNumber(balanceErc20.result).toNumber() : 0;

        // find decimal from contract

        response.tokens.push(erc20Obj)
      }

      const keyrings = this.deriveTokenKeyrings(response, 'AVAX');
      this.setServiceStatusInStore('avaxRpc', true);
      return [...keyrings, avaxKeyring];
    } catch (error) {
      Logger.error('get AVAX token balance error', address, error)
      this.setServiceStatusInStore('avaxRpc', false);
      return [{}];
    }
  }

  async getAvaxTokenHistory(address, page, itemsPerPage, ethFilterIndex, tokenContract) {
    const response: BlockbookGetAddressTxsResponse = {};
    let txs;
    let res;
    if (!isNil(tokenContract)) {
      res = await this.http
        .get(`${await this.getAvaxSnowTraceApi()}/api?module=account&action=tokentx&contractaddress=${tokenContract}&address=${address}&page=${page}&offset=${itemsPerPage}&sort=desc&apikey=${environment.SNOWTRACE_API_KEY}`)
        .toPromise();
      txs = res.result;
    } else {
      // Fetch transactions
      const fetchTransactions = async (action: string) => {
        const response: any = await this.http
            .get(`${await this.getAvaxSnowTraceApi()}/api?module=account&action=${action}&address=${address}&startblock=1&endblock=9999999999&sort=desc&apikey=${environment.SNOWTRACE_API_KEY}`)
            .toPromise();
        return response.result || [];
      };

      // Need to capture internal transactions as well for swap to get final native avax transfers
      const [externalTxs, internalTxs] = await Promise.all([
        fetchTransactions('txlist'),
        fetchTransactions('txlistinternal')
      ]);

      const externalSwapTxs = externalTxs.filter(tx => tx.functionName.includes("swapExactTokensForNATIVESupportingFeeOnTransferTokens"));

      const relevantInternalTxs = internalTxs.map(internalTx => {
        const matchingExternalTx = externalSwapTxs.find(externalTx => externalTx.hash === internalTx.hash);
        return matchingExternalTx ? {...internalTx, confirmations: matchingExternalTx.confirmations} : null;
      }).filter(tx => tx !== null);

      const mergedTxs = externalTxs.concat(relevantInternalTxs);
      const sortedTxs = mergedTxs.sort((a, b) => b.timeStamp - a.timeStamp);

      const startIndex = (page - 1) * itemsPerPage;
      const paginatedTxs = sortedTxs.slice(startIndex, startIndex + itemsPerPage);

      txs = paginatedTxs;
    }

    // Filter the non AVAX tokens
    response.transactions = Array.isArray(txs) ? txs: [];
    response.page = page;
    return { keyringId: createKeyringId('AVAX', tokenContract), ...response };
  }

  async getGsdDaoPrice(): Promise<number> {
    try {
      const network = await this.networkService.getActiveAvaxNetwork();
      const oracleAddress = network.GSD_ORACLE_ADDRESS;
      const url = network.RPC_URL;
      const avaxProvider = ethers.providers.getDefaultProvider(url);
      const avaxContract = new ethers.Contract(oracleAddress, ERC20_ABI_GSD_DAO, avaxProvider);
      const [decimalFeed, balance] = await Promise.all([avaxContract.FEED_DECIMALS(), avaxContract.aggregate()]);
      const price = balance.currentPrice / (10 ** decimalFeed);
      return price;
    } catch (error) {
      Logger.error('get GSDAO price error', error);
      return 0;
    }
  }

  deriveTokenKeyrings(response: BlockbookGetAddressResponse, baseChain: BaseChainSymbol): TokenKeyring[] {
    const keyrings = [];
    response.tokens.forEach((token) => {
      if (token === null) {
        return;
      }

      let balance = calculateUnconfBalance(token.balance, token.unconfirmedBalance);

      const keyring = createTokenKeyring({
        symbol: token.symbol,
        address: response.address,
        balance: balance,
        baseChainSymbol: baseChain,
        guid: token.contract || token.assetGuid,
        ethFilterIndex: undefined,
        precision: token.decimals
      });
      keyrings.push(keyring);
    });

    return keyrings;
  }

  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }

  async getAbi(contractAddress: string, chainId: string) {
    const apiUrl = chainId == '43113' ? 'https://api-testnet.snowtrace.io' : 'https://api.snowtrace.io';
    const url = `${apiUrl}/api?module=contract&action=getabi&address=${contractAddress}&apikey=${environment.SNOWTRACE_API_KEY}`;
    const response: any = await this.http.get(url).toPromise();
    return JSON.parse(response?.result);
  }
}
