import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SendGasStationDataAction, GasStationActionTypes, TradeMoveAssetSuccessAction } from '../actions/gasStation.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { GasStationService } from '../services/gas-station.service';
import { TransactionSenderService } from '../services/transaction-sender.service';
import { take, tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { getGasStationInfo } from '../store/gasStation';

const bitcoin = (window as any).bitcoinjsLib;

@Injectable()
export class GasStationEffects {
  authSendTokens$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SendGasStationDataAction>(GasStationActionTypes.SEND_GAS_STATION_DATA),
        withLatestFrom(this.store),
        tap(async ([action, store]) => {
          Logger.info('effect');
          Logger.info('Starting gas station transaction');
          const gasStationData = await this.store.select(getGasStationInfo).pipe(take(1)).toPromise();
          let addressRes: any;
          try {
            if (gasStationData.type !== 'MOVE') {
              addressRes = await this.gasStation.getAddress();
            }
            if (gasStationData?.assetGuid == null || (gasStationData?.assetGuid != 367794646 && gasStationData?.assetGuid != 1358717298)) {
              throw Error(`Wrong assetGuid - ${gasStationData.assetGuid}`);
            }
            if (Number(gasStationData?.amount) < 0.1) {
              throw Error(`Wrong move amount - ${gasStationData?.amount}`)
            }
            if ( gasStationData.type === 'MOVE' && gasStationData.memo == null) {
              throw Error(`Wrong memo ${gasStationData.memo}`);
            }
            const txid: any = await this.transactionSender.sendAsset(
              Number(gasStationData.amount),
              gasStationData.address,
              gasStationData.type === 'MOVE' ? gasStationData.to : addressRes.address,
              action.payload.pin,
              gasStationData.assetGuid,
              gasStationData.memo
            );
            setTimeout(() => {
              // Avoid "Not an SPT transaction error"
              if (gasStationData.type === 'MOVE') {
                this.store.dispatch(new TradeMoveAssetSuccessAction({ txid }));
              } else {
                this.gasStation.fulfillOrder(txid);
              }
            }, 2000);
          } catch (e) {
            Logger.error('Something went wrong error', e);

            await this.modalController.dismiss({}, '', 'pinModal');

            if (e?.error?.message && e?.error?.message?.indexOf('txVersion') !== -1) {
              const alert = await this.alertController.create({
                header: 'Error',
                // tslint:disable-next-line
                message: await this.translate.get('tokens.send.errors.txversion').toPromise(),
                buttons: ['OK'],
              });
              await alert.present();
            } else if (e?.error?.message) {
              Logger.error('first error', e);
              const alert = await this.alertController.create({
                header: await this.translate.get('gas_station.refill_confirmation.error_title').toPromise(),
                message: e.error.message,
              });
              await alert.present();
            } else {
              Logger.error('second error', e);
              let message =  await this.translate.get('gas_station.move_error.error_description').toPromise();
              message += e?.message;
              const alert = await this.alertController.create({
                header: await this.translate.get('gas_station.refill_confirmation.error_title').toPromise(),
                message: message,
              });
              await alert.present();
            }
          }
          // await this.nav.navigateForward(['/wallet/assets']);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private transactionSender: TransactionSenderService,
    private gasStation: GasStationService,
    private nav: NavController,
    private alertController: AlertController,
    private modalController: ModalController,
    private translate: TranslateService
  ) {}
}
