import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TransactionHistoryComponent } from './transaction-history.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionDetailModalModule } from '../transaction-detail-modal/transaction-detail-modal.module';
import { TransactionDetailModalComponent } from '../transaction-detail-modal/transaction-detail-modal.component';
import { ImagePreloaderModule } from '../../angular-wallet-base/directives/image-preloader/image-preloader.module';
import { SmallToCryptoPipeModule } from '../../angular-wallet-base/pipes/smalltocrypto/smalltocrypto.module';

@NgModule({
  declarations: [TransactionHistoryComponent],
  imports: [CommonModule, IonicModule, TranslateModule, TransactionDetailModalModule, ImagePreloaderModule, SmallToCryptoPipeModule],
  exports: [TransactionHistoryComponent],
  entryComponents: [TransactionDetailModalComponent]
})
export class TransactionHistoryModule {
}
