import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { CREDIT_CARD_LIST } from 'src/app/angular-wallet-base/constants';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { SelectPaymentModal } from 'src/app/views/select-payment-modal/select-payment-modal';
import { AuthGuardService } from 'src/app/angular-wallet-base/services/auth-guard.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';

@Component({
  selector: 'app-select-credit-card',
  templateUrl: './select-credit-card.component.html',
  styleUrls: ['./select-credit-card.component.scss'],
})
export class SelectCreditCardComponent implements OnInit {

  constructor(private modalController: ModalController,
    public appMode: AppModeService,
    private authGuardService: AuthGuardService,
    protected store: Store<AppState>,
    private nav: NavController,
  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }
  public creditType : any;
  public selectType : any;
  public symbol: any; 
  public selectedToken?;
  public isDesktopMode: boolean;
  public items = CREDIT_CARD_LIST;

  async ngOnInit() {
     this.creditType = this.items.filter(card => !card?.disableAssets?.includes(this.selectedToken));
    this.modalController.dismiss({}, '', 'select-payment-model');
  }

  onCancel() {
    this.modalController.dismiss({}, '', 'select-credit-model');
  }
  async onBack() {
    this.modalController.dismiss();
    const cssClass = (this.isDesktopMode) ? 'select-asset-modal-desktop' : 'select-asset-modal';
    const modal = await this.modalController.create({
      component: SelectPaymentModal,
      componentProps: {
        selectedToken: this.selectedToken,
        symbol:this.symbol
      },
      id: 'select-payment-model',
      cssClass,
      backdropDismiss: false,
      initialBreakpoint: (this.isDesktopMode) ? undefined : 0.9,
      breakpoints: (this.isDesktopMode) ? undefined : [0, 0.6, 0.9, 1],
    });
    await modal.present();
  }


  async selectCreditCard(item: any) {
    if (item.payWith == 'Simplex') {
      this.doSimplexPayment();
    } else if(item.payWith == 'Stripe') {
      this.doStripePayment();
    } else {
      this.doSingularityPayment();
    }
  }

  // singularity payment method
  private async doSingularityPayment() {
    Logger.info(`doSingularityPayment ${this.selectType}`);
    this.onCancel();
    this.authGuardService.setSelectedBuyData({form:this.selectType});
    this.nav.navigateForward(['/buy-assets/singularity', this.symbol]);
  }
  
  // simplex payment
  private async doSimplexPayment() {
    this.onCancel()
    this.authGuardService.setSelectedBuyData({form:this.selectType});
    this.nav.navigateForward(['/buy-assets', this.symbol]);
  }
  
  // stripe payment
  private async doStripePayment() {
    this.onCancel()
    this.authGuardService.setSelectedBuyData({form:this.selectType});
    this.nav.navigateRoot(['/home-page/established']);
    const url = 'https://crypto.link.com/?ref=lb&source_amount=1000&source_currency=usd&destination_currency=avax&destination_network=avalanche';
    window.open(url, '_blank');
  }
}
