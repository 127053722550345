import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { getAuthenticated, getPreventAuthInfo, getQueryCount } from '../store/wallet';
import { AUTH_DATA_KEY, AUTH_REASON, SKIP_INTRO_PAGE_KEY, WALLET_STORAGE_KEY } from '../constants';
import { Logger } from './logger.service';
import { PreventAuthInfo, TokenInfo } from '../../global';
import { AuthenticateAction } from '../actions/wallet.actions';
import { AppModeService } from './app-mode.service';
import { ChangeActivePage, ShowSideMenu } from '../actions/appSettings.actions';
import { StorageService } from './storage.service';
import { AuthService } from './auth.service';
import { MembersPortalService } from './members-portal.service';
import { AuthenticatePage } from 'src/app/views/authenticate/authenticate.page';
import { take } from 'rxjs/operators';

export interface Credentials {
  username: string;
  password: string;
}
export interface Token {
  tokenInfo: TokenInfo;
  unconfirmedTxs: any;
}
export interface UserData {
  userData: any;
}
export interface isVerified {
  isVerified: any;
}
export interface buyData {
  buyData: any;
}
@Injectable()
export class AuthGuardService implements CanActivate {

  private authenticated: boolean = false;
  private queryCount: number;
  private preventInfo: PreventAuthInfo;
  private showSideMenu = [
    '/home-page/first-time',
    '/home-page/established',
    '/wallet/settings',
    '/edit-profile',
    '/stake-lode',
    '/vest-ltc',
    '/vault-intro',
    '/trade',
    '/my-cards',
    '/reserv3',
    '/otc3',
  ];
  private credentials: Credentials;
  public token: Token;
  public newUserData: UserData;
  private queryParams;
  public userDetails: any;
  public isUserEmailVerified: isVerified;
  public selectedBuyData: any;
  private appLinks = [
    'home-page',
    'send',
    'receive',
    'buy',
    'trade',
    'debitcard',
    'quickTour',
    'faq',
    'intestors',
    'profile',
    'settings',
    'contact'
  ];

  constructor(
    private nav: NavController,
    private store: Store<AppState>,
    private appMode: AppModeService,
    private storage: StorageService,
    private authService: AuthService,
    private membersPortalService: MembersPortalService,
    private modalController: ModalController
  ) {

  }

  setCredentials(credentials) {
    this.credentials = credentials;
  }

  setTokenData(token: Token) {
    this.token = token;
  }

  setNewUserData(data) {
    this.newUserData = data;
  }

  setUserDetails(details: any) {
    this.userDetails = details
  }

  setUserEmailActivateStatus(type: any) {
    this.isUserEmailVerified = type
  }

  setSelectedBuyData(type: any) {
    this.selectedBuyData = type
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    Logger.info('AUTH GUARD', route, state, this.preventInfo);
    this.queryParams = route.queryParams;

    const url = state.url;
    this.authenticated = await this.store.select(getAuthenticated).pipe(take(1)).toPromise();
    this.queryCount = await this.store.select(getQueryCount).pipe(take(1)).toPromise();
    this.preventInfo = await this.store.select(getPreventAuthInfo).pipe(take(1)).toPromise();

    // check if user data exists in storage
    const authData = await this.storage.get(AUTH_DATA_KEY);
    const skipIntroPage = await this.storage.get(SKIP_INTRO_PAGE_KEY);
    if (!authData) {
      if (skipIntroPage) {
        await this.nav.navigateRoot('setup/intro');
      } else {
        await this.nav.navigateRoot('setup/intro');
      }
      return false;
    }

    if (!this.authenticated) {
      if (this.queryCount === 0) {
        // first time login
        await this.authService.openAuthPinModal(null, url);
        // await this.nav.navigateForward(['/auth'], { skipLocationChange: true, queryParams: { url } });
      } else if (!this.preventInfo.preventAuthOnResume || this.preventAuthOnResumeTimedOut()) {
        // relogin
        await this.authService.openAuthPinModal(AUTH_REASON.RE_AUTHENTICATE, url);
        // await this.nav.navigateForward(['/auth', AUTH_REASON.RE_AUTHENTICATE], { skipLocationChange: true, queryParams: { url } });
      }
      return false;
    } else {
      const checkToken = await this.checkJwtToken();

    }

    // Show side menu based on url
    if (this.authenticated && this.showSideMenu.some(text => url.includes(text))) {
      this.store.dispatch(new ShowSideMenu(true));
    } else {
      this.store.dispatch(new ShowSideMenu(false));
    }

    // TODO: hightlight active menu
    this.appLinks.map(menu => {
      if (url.includes(menu)) {
        this.store.dispatch(new ChangeActivePage(menu));
      }
    });

    return true;
  }

  public async checkJwtToken() {
    // TODO: Check /me api if the response is same as login api do the changes is same.
    // const jwtToken = this.membersPortalService.returnJwtIfValid();

    // check if query params has skip 
    if (this.credentials) {
      this.membersPortalService.updateJwt(this.credentials.username, this.credentials.password);
    }
  }

  private preventAuthOnResumeTimedOut() {
    Logger.info('preventAuthOnResumeTimedOut check', this.preventInfo);
    if (this.preventInfo.unauthenticatedTime && this.preventInfo.preventAuthDuration > 0) {
      const preventAuthDurationMs = this.preventInfo.preventAuthDuration * 1000;
      Logger.info('unauth time:', this.preventInfo.unauthenticatedTime);
      Logger.info('unauth prevent auth duration:', this.preventInfo.preventAuthDuration);
      const unauthDuration = (Date.now() - this.preventInfo.unauthenticatedTime);
      Logger.info('unauth duration', unauthDuration);
      if (unauthDuration >= preventAuthDurationMs) {
        return true;
      } else {
        this.store.dispatch(new AuthenticateAction());
        return false;
      }
    }
    return false;
  }



}
