import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { SKIP_OPEN_VAULT_PAGE_KEY } from 'src/app/angular-wallet-base/constants';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { EnvironmentService } from 'src/app/angular-wallet-base/services/environment.service';
import { StorageService } from 'src/app/angular-wallet-base/services/storage.service';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-before-activation',
  templateUrl: './before-activation.component.html',
  styleUrls: ['./before-activation.component.scss'],
})
export class BeforeActivationComponent implements OnInit {

  @Input() currentPage: string;
  @Input() type: string;
  @Input() menuHeight: number;

  coinArray: Array<any> = [
    {
      imgName: 'AGX Coin@2x.png',
      desc: 'before_activation_buy.agx_message'
    },
    {
      imgName: 'AUX Coin@2x.png',
      desc: 'before_activation_buy.aux_message'
    },
    {
      imgName: 'LODE Token@2x.png',
      desc: 'before_activation_buy.lode_message'
    },
    {
      imgName: 'moreBtn.png',
      desc: 'before_activation_buy.other_token_message'
    }
  ];
  coinDescription: string = this.coinArray[0].desc;
  public isDesktopMode;
  public tradeFeature = this.environmentService.trade;

  constructor(
    protected store: Store<AppState>,
    private modalCtrl: ModalController,
    private storage: StorageService,
    private appMode: AppModeService,
    private nav: NavController,
    private environmentService: EnvironmentService
  ) {
    this.appMode.isDesktopMode.subscribe(mode => {
      this.isDesktopMode = mode;
      if (!this.isDesktopMode) {
        this.closeModal();
      }
    });
  }

  ngOnInit() { }

  selectedContent(e, from, description?) {
    let selected: any;
    let className: string;

    if (description) this.coinDescription = description;

    if (from === 'tab') {
      selected = document.querySelectorAll('.tabs .selected');
      className = 'selected';
    }
    else {
      selected = document.querySelectorAll('.graphics-icons .icons .glow');
      className = 'glow';
    }
    selected.forEach((el) => {
      el.classList.remove(className);
    });
    e.currentTarget.classList.add(className);
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  async toActivationPage() {
    const viewOpenVault = await this.storage.get(SKIP_OPEN_VAULT_PAGE_KEY);
    const link = viewOpenVault ? '/setup/enter-recovery-phrase' : '/setup/open-vault';
    await this.modalCtrl.dismiss({ link });
  }

}
