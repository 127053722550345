import { Pipe, PipeTransform } from '@angular/core';
import { satoshiToNum, weiToNum, setNumberToPrecision, lamportsToNum } from '../../utils';
import { Logger } from '../../services/logger.service';

@Pipe({
  name: 'smalltocrypto'
})
export class SmallToCryptoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let precision = args.precision;
    let type = args.type;

    if (!args.type) {
      type = 'sys';
    }

    if (args.precision) {
      type = ''; // custom precision, let default case to handle the operation
    }

    if (!args.precision) {
      precision = 8;
    }

    if (isNaN(value)) {
      return 0;
    }

    if (!value) {
      return 0;
    }

    try {
      switch (type.toLowerCase()) {
        case 'btc':
        case 'sys':
          return satoshiToNum(Number(value));
        case 'hl':
        case 'eth':
        case 'avax':
          return weiToNum(value);
        case 'sol':
          return lamportsToNum(value);
        default:
          return setNumberToPrecision(Number(value), precision);
      }
    } catch (err) {
      Logger.error(err);
      return 0;
    }
  }

}
