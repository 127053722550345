import { Action } from '@ngrx/store';
import { SendTokenState } from '../store/sendToken';
import { SendTokenActionTypes, SetSendTokenDataAction } from '../actions/sendToken.actions';

export const initialSendTokenState = {
  fromAddress: '',
  toAddress: '',
  amount: 0,
  ethGasFee: '', // gas Price in unit (Wei) - depreciated
  networkFee: '', // network fee gas price in unit and used to replace ethGasFee
};

export function sendTokenReducer(state: SendTokenState = initialSendTokenState, action: Action): SendTokenState {
  let payload;
  switch (action.type) {
    case SendTokenActionTypes.SET_SEND_TOKEN_DATA:
      payload = (action as SetSendTokenDataAction).payload;
      return {
        ...state,
        fromAddress: payload.fromAddress,
        toAddress: payload.toAddress,
        amount: payload.amount,
        memo: payload.memo,
        tokenInfo: payload.tokenInfo,
        ethGasFee: payload.ethGasFee,
        networkFee: payload.networkFee,
      };

    case SendTokenActionTypes.SEND_TOKENS_SUCCESS:
      return { ...state, fromAddress: '', toAddress: '', amount: 0, memo: '', tokenInfo: null, networkFee: '' };

    case SendTokenActionTypes.RESET_SEND_TOKEN_DATA:
      return { ...initialSendTokenState };

    default:
      return state;
  }
}
