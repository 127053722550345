import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { SubscriptionListenerComponent } from 'src/app/angular-wallet-base/SubscriptionListenerComponent';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { getKeychain } from 'src/app/angular-wallet-base/store/wallet';
import { take } from 'rxjs/operators';
import { getBaseCurrency } from 'src/app/angular-wallet-base/store/userPreferences';
import { getTokenInfoArray } from 'src/app/angular-wallet-base/utils';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { ConfirmAddressComponent } from '../confirm-address-modal/confirm-address-modal';

@Component({
  selector: 'app-select-receiving-address',
  templateUrl: './select-receiving-address.component.html',
  styleUrls: ['./select-receiving-address.component.scss'],
})
export class SelectReceivingAddressComponent extends SubscriptionListenerComponent implements OnInit, AfterViewInit, OnDestroy {
  public isDesktopMode: any;
  public currentBaseCurrency: any;
  public tokens: any = [];
  public address: any;
  constructor(private appMode: AppModeService,
    protected store: Store<AppState>,
    private authService: AuthService,
    private modalController: ModalController) {
    super(store);
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }
  ngAfterViewInit(): void {
  }

  async ngOnInit() {
    await this.authService.dismissModal('confirmAddressModal');
    await this.authService.dismissModal('primaryAddressSuccessFailModal');
    this.subscriptions.add(
      this.store.select(getKeychain).subscribe(async (keychain) => {
        if (!keychain) {
          // Prevent error on wallet reset.
          return;
        }
        // get tokens
        this.tokens = await Promise.all(getTokenInfoArray(keychain));
        // filter by HL tokens and receive selection
        this.tokens = this.tokens.filter(token => token.baseChainSymbol === 'AVAX' && token.symbol === 'AVAX');
        if (this.tokens.length === 1) {
          this.address = this.tokens[0];
        }
      })
    );
  }
  async continue() {
    // await this.modal.dismiss({ selectedAddress: this.address, allTokens: this.tokens });
    await this.openConfirmAddressModal(this.address, this.tokens);
  }
  async openConfirmAddressModal(address, tokens) {
    let cssClass = '';
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
    cssClass += (this.isDesktopMode) ? 'confirm-address-modal' : 'primary-address-success-modal';
    cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
    const modal = await this.modalController.create({
      component: ConfirmAddressComponent,
      id: 'confirmAddressModal',
      componentProps: {
        selectedAddress: address,
        allTokens: tokens
      },
      cssClass,
      initialBreakpoint: (this.isDesktopMode) ? undefined : 0.7,
      breakpoints: (this.isDesktopMode) ? undefined : [0, 0.6, 0.7, 1],
    });
    await modal.present();
  }
  onCancel() {
    this.modalController.dismiss();
  }

}
