import { Action } from '@ngrx/store';

interface SetServiceStatusActionProps {
  sysBB?: boolean;
  ethRPC?: boolean;
  btcBB?: boolean;
  gasStation?: boolean;
  vdcServer?: boolean;
  memberPortal?: boolean;
  websocketETH?: boolean;
  websocketSYS?: boolean;
  websocketSPT?: boolean;
  websocketAVAX?: boolean;
  websocketSOL?: boolean;
  bankAccount?: boolean;
  avaxSnowTrace?: boolean;
  avaxRpc?: boolean;
  solRpc?: boolean;
}

export enum ConnectionActionTypes {
  CONNECT_SUCCESS = '[connection] Connected',
  DISCONNECT = '[connection] Disconnected',
  SET_SERVICE_STATUS = '[connection] Set service status',
  RESET_CONNECTION_STATUS = '[connection] Reset connection status to initial state',
}

export class ConnectSuccessAction implements Action {
  readonly type = ConnectionActionTypes.CONNECT_SUCCESS;
}

export class DisconnectAction implements Action {
  readonly type = ConnectionActionTypes.DISCONNECT;
}

export class SetServiceStatusAction implements Action {
  readonly type = ConnectionActionTypes.SET_SERVICE_STATUS;
  constructor(readonly payload: SetServiceStatusActionProps) { }
}

export class ResetConnectionAction implements Action {
  readonly type = ConnectionActionTypes.RESET_CONNECTION_STATUS;
}
