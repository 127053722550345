import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, NavParams, Platform } from '@ionic/angular';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { Logger } from '../../angular-wallet-base/services/logger.service';
import * as bip21 from 'bip21';
import { getCoinNameBySymbol, handleCopy } from 'src/app/angular-wallet-base/utils';
import { ClipboardService } from 'ngx-clipboard';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';


@Component({
  selector: 'receive-asset-modal',
  templateUrl: './receive-asset-modal.component.html',
  styleUrls: ['./receive-asset-modal.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class ReceiveAssetModal implements OnInit {

  @ViewChild('imgBuffer') imageElement: ElementRef;

  public isDesktopMode;
  public qrCopied = false;
  public bip21str;
  public tokenInfo;
  public loader: boolean = false;
  public isBrowser: boolean = false;

  render = 'image';
  mode = 'image';
  ecLevel = 'L';
  size = 300;
  fill = '#000000';
  quiet = 1;
  mSize = 20;
  mPosX = 50;
  mPosY = 50;
  mSize2 = 30;
  mPosX2 = 50;
  mPosY2 = 50;
  fontoutline = true;
  imageText = '';
  imgNativeElement = undefined;
  img;
  base64element;
  coinLogo;
  address;
  coin;
  qrImg
  constructor(
    private modalController: ModalController,
    public appMode: AppModeService,
    private platform: Platform,
    private clipboardService: ClipboardService,
    private clipboard: Clipboard,
    public alertController: AlertController,
    private translate: TranslateService,
    private http: HttpClient,
    private navParams: NavParams,
    private socialSharing: SocialSharing,
    private _elementRef: ElementRef
  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
    this.isBrowser = !this.platform.is('cordova');
  }

  async ngOnInit() {
    this.tokenInfo = await this.navParams.get('tokenInfo');
    this.coinLogo = this.tokenInfo.logo;
    this.address = this.tokenInfo.address;
    this.coin = this.tokenInfo.symbol;

    this.qrCopied = false;
    this.coinLogo = this.coinLogo.substr(6); // TODO: needs changes if src address had changes

    this.http.get(this.coinLogo, { responseType: 'blob' })
    .subscribe(res => {
      const reader = new FileReader();
      reader.onloadend = (event2: any) => {
        this.base64element = reader.result;
        this.imgNativeElement = this.imageElement.nativeElement;
        this.imageElement.nativeElement.src = this.base64element;
        this.img = this.imageElement.nativeElement;
        this.fontoutline = false;
        setTimeout(() => {
          this.fontoutline = true;          
          const htmlElementSrc = document.querySelector('.qr-code-custom').querySelector('img').src;
          this.qrImg = htmlElementSrc;
        }, 1000);
      };
      reader.readAsDataURL(res);
    });
    this.bip21str = bip21.encode(this.address, {}, getCoinNameBySymbol(this.coin));
  }

  closeModal() {
    this.modalController.dismiss();
  }

  doCopy() {
    handleCopy(
      this.address,
      this.platform,
      this.clipboard,
      this.alertController,
      this.translate,
      this.clipboardService, null
    );
    this.qrCopied = true;
  }

  doShare() {
    this.loader = true;

    let qrImage;
    try {
      qrImage = this.qrImg;
    } catch (e) { Logger.info('qrImage', e); }

    if (!this.isBrowser) {
      this.socialSharing.share(this.address, 'Receive', (qrImage ? qrImage : this.base64element))
        .then(() => { this.loader = false; })
        .catch(error => { Logger.info('Share failed !!', error); this.loader = false; })
    } else {
      this.loader = false;
    }
  }
}
