import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TransactionDetailModalComponent } from './transaction-detail-modal.component';
import { ImagePreloaderModule } from '../../angular-wallet-base/directives/image-preloader/image-preloader.module';
import { SmallToCryptoPipeModule } from '../../angular-wallet-base/pipes/smalltocrypto/smalltocrypto.module';
import { TransactionDetailModule } from '../transaction-detail/transaction-detail.module';

@NgModule({
  declarations: [TransactionDetailModalComponent],
  imports: [CommonModule, IonicModule, TranslateModule, ImagePreloaderModule, SmallToCryptoPipeModule, TransactionDetailModule],
  exports: [TransactionDetailModalComponent]
})
export class TransactionDetailModalModule {}
