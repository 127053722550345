import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { ModalRequestComponent } from "../../components/modal-request/modal-request.component";
import { WalletConnectErrorAction, WalletConnectEventSessionRequestAction, WalletConnectSessionDisconnectRequestAction, WalletConnectSessionDisconnectedRequestFromDAppAction, WalletConnectSessionProposalSingularityPairRequestAction, WalletConnectSetSupportedNameSpaceAction, WalletConnectSingularitySessionApproveAction, WalletConnectStateInitRequestAction } from "../actions/walletConnect.actions";
import { AppState } from "../store/appState";
import { NetworkService } from "./network.service";
import {
  getSigningRequest,
  getSingularitySession,
  getSupportedNamespaces,
  getWalletConnectInstance,
  getWalletConnectInstanceNonce,
  getWalletConnectSelectedNetwork,
  getWalletConnectSession,
  IWalletConnectSelectedNetwork,
} from "../store/walletConnect";
import { Logger } from "./logger.service";
import { IClientMeta, IWalletConnectSession } from "@walletconnect/types";
import { take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Core } from "@walletconnect/core";
import { inspect } from "util";
import { buildApprovedNamespaces, getSdkError } from "@walletconnect/utils";
import { Explorer } from "src/environments/environment.interface";
import { isNil } from "../ts-util";
import { WalletService } from "./wallet.service";
import { now } from "moment";
import { IWeb3Wallet, Web3Wallet } from '@walletconnect/web3wallet'
import { TransactionSenderService } from "./transaction-sender.service";
import { WCModalRequestSendingTransactionComponent } from "src/app/components/modal-request/wc-modal-request-sending-transaction/wc-modal-request-sending-transaction.component";
import { SingularityUserLogOutFromDAppSingularityAction } from "../actions/singularityPayment.action";
import { getSignTypedDataParamsData } from "../utils";
import { getPendingOrder } from "../store/purchaseOrder.state";

export type WalletConnectSession = {
  connected: boolean;
  accounts: string[];
  chainId: number;
  bridge: string;
  key: string;
  clientId: string;
  clientMeta: IClientMeta | null;
  peerId: string;
  peerMeta: IClientMeta | null;
  handshakeId: number;
  handshakeTopic: string;
}


/**
 * Methods
 */
export const EIP155_SIGNING_METHODS = {
  PERSONAL_SIGN: 'personal_sign',
  ETH_SIGN: 'eth_sign',
  ETH_SIGN_TRANSACTION: 'eth_signTransaction',
  ETH_SIGN_TYPED_DATA: 'eth_signTypedData',
  ETH_SIGN_TYPED_DATA_V3: 'eth_signTypedData_v3',
  ETH_SIGN_TYPED_DATA_V4: 'eth_signTypedData_v4',
  ETH_SEND_TRANSACTION: 'eth_sendTransaction'
}

@Injectable({
  providedIn: "root"
})
export class WalletConnectService {
  private supportedBaseChainSymbols = ['AVAX', 'ETH'];
  private wcWeb3Wallet: IWeb3Wallet;
  private activeSession: IWalletConnectSession;
  private pendingProposalId: number = 0;
  private pendingEventRequestId: number = 0;
  private pendingDeleteSessionTopic: string = '';

  constructor(
    private store: Store<AppState>,
    public modalController: ModalController,
    private networkService: NetworkService,
    private walletService: WalletService,
    private transactionSenderService: TransactionSenderService,
  ) {
    this.store.select(getWalletConnectInstanceNonce).subscribe(async web3WalletInstance => {
      this.wcWeb3Wallet = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
      await this.subscribeProposalSessionFromDapp(this.wcWeb3Wallet);
    })
    this.store.select(getWalletConnectSession).subscribe(async session => {
      this.activeSession = session;
      await this.subscribeWalletConnectEvents();
    })
    this.store.select(getSingularitySession).subscribe(async session => {
      await this.subscribeWalletConnectEvents();
    })
}



  async initiateWalletConnectWeb3Instance() {
  const wcWeb3WalletInstance = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
  if (isNil(wcWeb3WalletInstance)) {
    Logger.info(`WalletConnect Web3Wallet Instance is not initialized yet`);
    this.store.dispatch(new WalletConnectStateInitRequestAction());
  } 
}
  async initiateWalletConnectWeb3Wallet() {
    const core = new Core({
      projectId: environment.WALLET_CONNECT_PROJECT_ID!
    });
    const metadata = {
      name: 'LodePay Wallet',
      description: 'Redefining Monetary Systems with Digital Silver and Gold',
      url: 'https://lode.one/',
      icons: ['']
    };
    const web3Wallet = await Web3Wallet.init({
      core,
      metadata: metadata
    })
    Logger.info(`initiateWeb3WalletConnect ${inspect(web3Wallet)}`);
    return web3Wallet;
  }

  async getWalletConnectSupportNetworks(): Promise<Explorer[]> {
    let wcSupportNetworks = [];
    for (let baseChainSymbol of this.supportedBaseChainSymbols) {
      let network = await this.networkService.getActiveNetwork(baseChainSymbol);
      if(!isNil(network)) {
        wcSupportNetworks.push(network)
      }
    }
    return wcSupportNetworks;
  }


  // https://docs.walletconnect.com/2.0/web/web3wallet/wallet-usage
  // instrument.js:109 Error: pairDAppRequest [Error: Pairing already exists: 34522ebfca52f58532c8ea220046fcba0b0a59fae00c0a55f241347202ee79e1]
  async pairNewDApp(uri: string) {
    const wcWeb3Wallet = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
    let topic;
    try {
      let pendingSessionProposals = wcWeb3Wallet.getPendingSessionProposals();
      let pairing = wcWeb3Wallet.core.pairing.getPairings();
      Logger.info(`current pairs ${inspect(pairing)}`);
      let topic = this.getTopic(uri);
      let isTopicExisted = false;
      let proposalSession;
      for (const key in pendingSessionProposals) {
        let pendingSessionProposal = pendingSessionProposals[key];
        if (pendingSessionProposal?.pairingTopic == topic) {
          isTopicExisted = true;
          proposalSession = pendingSessionProposal;
          break;
        }
      }

      if (isTopicExisted) {
        await wcWeb3Wallet.core.pairing.activate({topic});
        Logger.info(`pendingSessionProposals - ${inspect(pendingSessionProposals)}`);
        await this.showPendingSessionApprovalModal(proposalSession);
      } else {
        this.subscribeProposalSessionFromDapp(wcWeb3Wallet);
        await wcWeb3Wallet.core.pairing.pair({uri});
      }
    } catch (err) {
      Logger.error(`Error pairNewDApp ${err}`);
      if (err?.message?.includes('Pairing already exists')) { // for the case that uri is existed so remove and disconnected it
        this.disconnectActiveSession(topic);
        this.store.dispatch(new WalletConnectErrorAction(`Error: the pair is already exist. Please get a new URI`));
      }
    }
    return wcWeb3Wallet;
  }
  async pairNewProposalSessionSingularityDApp(uri: string) {
    const wcWeb3Wallet = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
    let topic;
    try {
      let pendingSessionProposals = wcWeb3Wallet.getPendingSessionProposals();
      let pairing = wcWeb3Wallet.core.pairing.getPairings();
      Logger.info(`current pairs ${inspect(pairing)}`);
      topic = this.getTopic(uri);
      let isTopicExisted = false;
      let proposalSession;
      for (const key in pendingSessionProposals) {
        let pendingSessionProposal = pendingSessionProposals[key];
        if (pendingSessionProposal?.pairingTopic == topic) {
          isTopicExisted = true;
          proposalSession = pendingSessionProposal;
          break;
        }
      }
      if (isTopicExisted) { // topic is existed
        await wcWeb3Wallet.core.pairing.activate({topic}); // activate the existed topic
        Logger.info(`pairNewProposalSessionSingularityDApp - ${inspect(pendingSessionProposals)}`);
        this.autoApproveProposalSessionFromSingularity(proposalSession);
      } else {
        this.subscribeProposalSessionFromDapp(wcWeb3Wallet); 
        await wcWeb3Wallet.core.pairing.pair({uri});// pair a new uri/ topic
      }
    } catch (err) {
      Logger.error(`Error pairNewProposalSessionSingularityDApp ${err}`);
      if (! err?.message?.includes('Pairing already exists')) { 
        this.store.dispatch(new WalletConnectErrorAction(`Failed To connect Singularity service and please try again!`));
      }
    }
    return wcWeb3Wallet;
  }

  private getTopic(uri: string): string {
    // uri sample 
    //wc:4b858d50a84623df7035f1cb9bf52453965c08abaaacc938bcca48be60b19d37@2?relay-protocol=irn&symKey=4f61495425ed5a97f1df1f3d56e572c732efdc7271f25bb1b9aaf0b816d1dc5f
    // https://docs.walletconnect.com/2.0/specs/clients/core/pairing/pairing-uri
    let data = uri.split(':');
    let data1 = data[1].split('@');
    return data1[0]
  }

  private async getSupportedNamespaces(chainId: number): Promise<Record<string, any>> {
    let supportedNameSpaces = await this.store.select(getSupportedNamespaces).pipe(take(1)).toPromise();
    Logger.info(`getSupportedNamespaces - ${inspect(supportedNameSpaces)}`);
    if (supportedNameSpaces && supportedNameSpaces?.size > 0) {
      return supportedNameSpaces.get(chainId);
    }
    supportedNameSpaces = new Map();
    const supportedNetworkExplorers = await this.getWalletConnectSupportNetworks();
    Logger.info(`getSupportedNamespaces  supportedNetworkExplorers new - ${inspect(supportedNetworkExplorers)}`);
    for (const supportedNetworkExplorer of supportedNetworkExplorers) {
      try {
        let chains: string[] = [];
        let accounts: string[] = [];
        let walletAddress = await this.walletService.getWalletAddressByBaseChainSymbol(supportedNetworkExplorer.chain);
        chains.push("eip155:"+ supportedNetworkExplorer.chainId);
        accounts.push("eip155:"+ supportedNetworkExplorer.chainId + ":"+walletAddress);
        let supportedNameSpace= {
          eip155: {
              chains,
              methods: ["eth_sendTransaction", "personal_sign", "eth_signTypedData_v4", "eth_signTypedData", "eth_sign"],
              events: ["accountsChanged", "chainChanged", 'message', 'disconnect', 'connect'],
              accounts
          }
        }
        supportedNameSpaces.set(supportedNetworkExplorer.chainId, supportedNameSpace);
      } catch (err) {
        Logger.error(`Error - getSupportedNamespaces ${err}`);
      }
    }
   
    Logger.info(`getSupportedNamespaces new - ${inspect(supportedNameSpaces)}`);
    this.store.dispatch(new WalletConnectSetSupportedNameSpaceAction({supportedNameSpaces}));
    return  supportedNameSpaces.get(chainId);
  }

  private async getSingularitySupportedNamespaces(): Promise<Record<string, any>> {
    const supportedNetworkExplorers = await this.getWalletConnectSupportNetworks();
    Logger.info(`getSupportedNamespaces  supportedNetworkExplorers new - ${inspect(supportedNetworkExplorers)}`);
    let chains: string[] = [];
    let accounts: string[] = [];
    for (const supportedNetworkExplorer of supportedNetworkExplorers) {
      try {
        let walletAddress = await this.walletService.getWalletAddressByBaseChainSymbol(supportedNetworkExplorer.chain);
        chains.push("eip155:"+ supportedNetworkExplorer.chainId);
        accounts.push("eip155:"+ supportedNetworkExplorer.chainId + ":"+walletAddress);
      } catch (err) {
        Logger.error(`Error - getSupportedNamespaces ${err}`);
      }
    }

    let supportedNameSpaces= {
      eip155: {
          chains,
          methods: ["eth_sendTransaction", "personal_sign", "eth_signTypedData_v4", "eth_signTypedData", "eth_sign"],
          events: ["accountsChanged", "chainChanged", 'message', 'disconnect', 'connect'],
          accounts
      }
    }
    Logger.info(`getSingularitySupportedNamespaces new - ${inspect(supportedNameSpaces)}`);
    return  supportedNameSpaces;
  }


  async setNewChain(baseChainSymbol: string): Promise<IWalletConnectSelectedNetwork> {
      const supportedNetworkExplorers = await this.getWalletConnectSupportNetworks();
      let selectedNetworkExplorer = supportedNetworkExplorers
                                      .find(supportedNetworkExplorer => supportedNetworkExplorer.chain == baseChainSymbol);
      if (isNil(selectedNetworkExplorer)) {
        throw Error(`Chain ${baseChainSymbol} is not supported`);
      }
      let walletAddress = await this.walletService.getWalletAddressByBaseChainSymbol(baseChainSymbol.toUpperCase());
      return {
        name: selectedNetworkExplorer.name,
        chainId: selectedNetworkExplorer.chainId,
        rpcUrl: selectedNetworkExplorer.RPC_URL,
        baseChainSymbol,
        walletAddress
      }                  
  }


  async disconnectActiveSession(sessionTopic: string) {
    const wcWeb3Wallet = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
    await wcWeb3Wallet.disconnectSession({
      topic: sessionTopic,
      reason: getSdkError('USER_DISCONNECTED')
    })
    return wcWeb3Wallet;
  }

  // todo need to subscribe more types of events 
  // https://docs.walletconnect.com/2.0/specs/clients/sign/session-events
  async subscribeWalletConnectEvents() {
    Logger.info(`start to subscribe wallet connect event ${now}`);
    if (this.wcWeb3Wallet) {
       try {
        await this.subcribeEventSessionRequestFromDapp();
        await this.subcribeDisconnectSessionRequestFromDapp();
       } catch (err ) {
        Logger.error(`subscribeWalletConnectEvents ${err}`);
       }
    }
  }

  async subscribeProposalSessionFromDapp(wcWeb3Wallet: IWeb3Wallet) {
    Logger.info(`subscribeProposalSessionFromDapp`);
    if (wcWeb3Wallet) {
      wcWeb3Wallet.on("session_proposal",async (sessionProposal) => {
        const { id, params } = sessionProposal;
        Logger.info(`session_proposal ${inspect(sessionProposal)}`)
        if (this.pendingProposalId == 0 || this.pendingProposalId != id) {
          this.pendingProposalId = id;
          if (params?.proposer?.metadata?.name?.includes("Singularity")){//"Singularity") { // automatically approve session from Singularity
            await this.autoApproveProposalSessionFromSingularity(params);
          } else {
            await this.showPendingSessionApprovalModal(params);
          }
        }
      }); 
    }
  }

  async autoApproveProposalSessionFromSingularity(sessionProposal) {
    try {
      Logger.info(`autoApproveProposalSessionFromSingularity ${inspect(sessionProposal)}`);
      const namespaces = await this.getSingularitySupportedNamespaces();
        // ------- namespaces builder util ------------ //
      const approvedNamespaces = buildApprovedNamespaces({
          proposal: sessionProposal,
          supportedNamespaces: namespaces,
      });

      const wcWeb3Wallet = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
      const connectedSession = await wcWeb3Wallet.approveSession({
        id: sessionProposal?.id,
        namespaces: approvedNamespaces,
      });
      // accounts = ['eip155:43114:0xCC...']
      let accountInfo = connectedSession?.namespaces?.eip155?.accounts[0]?.split(':');

      const singularitySession = {
        connected: true,
        accounts: isNil(accountInfo)? [] : [accountInfo[2]], 
        chainId: isNil(accountInfo)? 0 : Number(accountInfo[1]),
        bridge:connectedSession.relay.protocol,
        key: connectedSession.controller,
        clientId: connectedSession.self.publicKey,
        clientMeta: connectedSession.self.metadata,
        peerId: connectedSession.peer.publicKey,
        peerMeta: connectedSession.peer.metadata,
        handshakeId: sessionProposal?.id,
        handshakeTopic: connectedSession.topic
      }
      const eventRequests = await this.wcWeb3Wallet.getPendingSessionRequests();
      Logger.error(`Warning a new Singularity session is approved ${inspect(connectedSession)}`);
      this.store.dispatch(new WalletConnectSingularitySessionApproveAction({web3Wallet: this.wcWeb3Wallet, singularitySession, eventRequests}))
    } catch (err) {
      Logger.error(`autoApproveProposalSessionFromSingularity - ${err}`);
      this.pendingProposalId = 0;
      this.store.dispatch(new WalletConnectErrorAction( `Error in autoApproveProposalSessionFromSingularity ${err?.message}`));
    }
  }

  private async showPendingSessionApprovalModal(sessionProposal) {
    try {
      const selectedNetwork = await this.store.select(getWalletConnectSelectedNetwork).pipe(take(1)).toPromise();
      const namespaces = await this.getSupportedNamespaces(selectedNetwork.chainId);
      Logger.info(`showPendingSessionApprovalModal ${inspect(sessionProposal)}`);
        // ------- namespaces builder util ------------ //
      const approvedNamespaces = buildApprovedNamespaces({
          proposal: sessionProposal,
          supportedNamespaces: namespaces,
      });
     
      //display modal for approval
      const modal = await this.modalController.create({
        component: ModalRequestComponent,
        componentProps: {
          id: sessionProposal?.id,
          payload: approvedNamespaces,
          pendingSession: sessionProposal
        }
      });
      await modal.present();
    } catch (err) {
      Logger.error(`Error: showPendingSessionApprovalModal - session proposal ${inspect(sessionProposal)} + -- ${inspect(err)}`);
      this.store.dispatch(new WalletConnectErrorAction(err?.message));
    }

  }


  private async subcribeEventSessionRequestFromDapp() {
    this.wcWeb3Wallet.on('session_request', async eventRequest => {
      const { topic, params, id } = eventRequest;
      Logger.info(inspect(params));
      Logger.info(`new event - topic ${topic} - params: ${params} - id ${id}`);
      const singularitySession = await this.store.select(getSingularitySession).pipe(take(1)).toPromise();
      if (topic == singularitySession?.handshakeTopic && this.pendingEventRequestId != id) {
        this.pendingEventRequestId = id;
        await this.showSingularityEventRequest(eventRequest);
      }
      if (topic == this.activeSession?.handshakeTopic) { // we still add Singularity event request if it's active session
        this.store.dispatch(new WalletConnectEventSessionRequestAction( {eventRequest}));
      }
    });
  }

  private async showSingularityEventRequest(requestEvent) {
    const {topic, params} = requestEvent;
    const web3WalletInstance = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();
    const requestSession = web3WalletInstance.engine.signClient.session.get(topic);
    const modal = await this.modalController.create({
      component: WCModalRequestSendingTransactionComponent,
      componentProps: {
        requestEvent,
        requestSession
      },
      id: `requestEvent-${requestEvent?.id}`
    });
    await modal.present();
  }

  private async subcribeDisconnectSessionRequestFromDapp() {
    this.wcWeb3Wallet.on('session_delete', async eventSession => {
      const {id, topic} = eventSession;
      Logger.info(`session delete request id: ${id} - topic: ${topic}`);
      const singularitySession = await this.store.select(getSingularitySession).pipe(take(1)).toPromise();
      if (this.pendingDeleteSessionTopic != topic) {
        if (topic == singularitySession?.handshakeTopic) {
          this.store.dispatch(new SingularityUserLogOutFromDAppSingularityAction({web3Wallet: this.wcWeb3Wallet, sessionTopic: topic}));
        } else {
          this.store.dispatch(new WalletConnectSessionDisconnectedRequestFromDAppAction({web3Wallet: this.wcWeb3Wallet, sessionTopic: topic}));
        }
        this.pendingDeleteSessionTopic = topic;
      }
    })
  }
  
  
  async signEventRequest(pin: string) {
    const signingRequest = await this.store.select(getSigningRequest).pipe(take(1)).toPromise();
    const {eventRequest, requestSession, networkFee} = signingRequest;
    const {topic, params, id} = eventRequest;
    const {request} = params;
    let result;
    switch (request.method) {
      case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
        result = await this.signAndSendEthTransaction(pin, signingRequest);
        break;
      case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
        result = await this.signAndSendEthSignData(pin, signingRequest);
        break;
      default:
        throw new Error(getSdkError('INVALID_METHOD').message);
      /*
      // todo need to handle more type of message here;
        case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
        case EIP155_SIGNING_METHODS.ETH_SIGN:
          const message = getSignParamsMessage(request.params)
          const signedMessage = await wallet.signMessage(message)
          return formatJsonRpcResult(id, signedMessage)
    
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
          const { domain, types, message: data } = getSignTypedDataParamsData(request.params)
          // https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
          delete types.EIP712Domain
          const signedData = await wallet._signTypedData(domain, types, data)
          return formatJsonRpcResult(id, signedData)
    
        case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
          const provider = new providers.JsonRpcProvider(EIP155_CHAINS[chainId as TEIP155Chain].rpc)
          const sendTransaction = request.params[0]
          const connectedWallet = wallet.connect(provider)
          const { hash } = await connectedWallet.sendTransaction(sendTransaction)
          return formatJsonRpcResult(id, hash)
    
        case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
          const signTransaction = request.params[0]
          const signature = await wallet.signTransaction(signTransaction)
          return formatJsonRpcResult(id, signature) */
        
    }
    const response = { id, result, jsonrpc: '2.0' }
    await this.wcWeb3Wallet.respondSessionRequest({ topic, response });
    return {
      web3Wallet: this.wcWeb3Wallet,
      signedEventRequest: eventRequest
    }
  }

  private async signAndSendEthSignData(pin: string, signingRequest) {
    const {eventRequest, requestSession, networkFee} = signingRequest;
    const {topic, params, id} = eventRequest;
    const {request} = params;
    const { domain, types, message: data } = getSignTypedDataParamsData(request.params);
    Logger.info(`types: ${inspect(types)}`);
    // https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
    delete types.EIP712Domain;
    Logger.info(`types test: ${inspect(types)}`);
    const singularityPendingOrder = await this.store.select(getPendingOrder).pipe(take(1)).toPromise();
    const userWalletAddress = singularityPendingOrder.walletAddress;

    return await this.transactionSenderService.signTypedData(userWalletAddress, pin, domain, types, data);
  }

  private async signAndSendEthTransaction(pin: string, signingRequest) {
    const {eventRequest, requestSession, networkFee} = signingRequest;
    const {topic, params, id} = eventRequest;
    const {request} = params;
    const selectedNetwork = await this.store.select(getWalletConnectSelectedNetwork).pipe(take(1)).toPromise();
    let tokenInfo = {
      baseChainSymbol: selectedNetwork.baseChainSymbol,
      chainId: selectedNetwork.chainId,
      url: selectedNetwork.rpcUrl
    }
    let transactionData = {
      fromAddress: request?.params[0]?.from,
      toAddress: request?.params[0]?.to,
      value: request?.params[0]?.value,
      callData: request?.params[0]?.data,
      gasPrice: request?.params[0]?.gasPrice,
      gasLimit: request?.params[0]?.gasLimit,
      tokenInfo,
      
    }
    const hash = await this.transactionSenderService.evmSignAndSendRawTransaction(pin, transactionData);
    return hash;
  }

}
