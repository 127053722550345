import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { SubscriptionListenerComponent } from 'src/app/angular-wallet-base/SubscriptionListenerComponent';

@Component({
  selector: 'pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss'],
})
export class PinInputComponent extends SubscriptionListenerComponent implements OnInit, OnChanges {

  @ViewChild(IonInput, { read: ElementRef }) pinInput: ElementRef;

  @Input() pageTitle = '';
  @Input() showPinError = false;
  @Input() upgradeInProgress = false;
  @Input() pinLength = 6;
  @Input() pin = '';
  @Input() showForgotPin = false;

  @Output() pinComplete: EventEmitter<string> = new EventEmitter<string>();
  @Output() pinClear: EventEmitter<string> = new EventEmitter<string>();
  @Output() pinError: EventEmitter<string> = new EventEmitter<string>();
  @Output() forgetPassword: EventEmitter<any> = new EventEmitter<any>();


  public pinLengthArr = new Array(this.pinLength);
  public isDesktopMode: boolean = false;
  public pinType: string = 'password';
  public pinIcon: string = 'eye';
  public pinConfirmType: string = 'password';
  public pinConfirmIcon: string = 'eye';
  public showPinTextError: string = '';
  public pinValue: string = '';

  constructor(
    private translate: TranslateService,
    protected store: Store<AppState>,
    private appMode: AppModeService
  ) {
    super(store);
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.pinInput && this.pinInput.nativeElement.querySelector('input')) {
        this.pinInput.nativeElement.querySelector('input').focus();
      }
    }, 1000);
    this.subscriptions.add(this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode));
  }

  ngOnChanges(changes) {
    if (changes.showPinError && changes.showPinError.currentValue === true) {
      setTimeout(() => {
        this.handleClear();
      }, 860); // timing to match css animation
    }
  }

  async handlePin(pin: string, isKeyPad = true) {
    this.pinValue = isKeyPad
      ? (this.pinValue + pin) // handle pin from keypad/numpad
      : pin; // handle pin from input 

    const pattern = /^[0-9]*$/;
    if (this.pinValue.match(pattern) == null) {
      this.showPinTextError = await this.translate.get('pin_input.error.digits_only').toPromise();
      this.pinError.emit(this.showPinTextError);
      return;
    }

    if (this.pinValue.toString().length > this.pinLength) {
      this.showPinTextError = await this.translate.get('pin_input.error.max_length').toPromise();
      if (!isKeyPad) {
        this.pinError.emit(this.showPinTextError);
      }
      else{
        this.pinValue = this.pinValue.slice(0, 6);
      }
      return;
    }

    if (this.pinValue.length == this.pinLength) {
      setTimeout(() => {
        this.pinComplete.emit(this.pinValue);
      }, 250);
    }
  }

  handleClear() {
    this.pinValue = '';
    this.showPinTextError = '';
    if (this.pinInput && this.pinInput.nativeElement) { this.pinInput.nativeElement.value = ''; }
    this.pinClear.emit();
  }

  handleErase() {
    this.pinValue = this.pinValue.slice(0, this.pinValue.length === 0 ? 0 : this.pinValue.length - 1);
  }

  handleTogglePin(confirm) {
    if (confirm) {
      this.pinConfirmType = this.pinConfirmType === 'password' ? 'number' : 'password';
      this.pinConfirmIcon = this.pinConfirmIcon === 'eye' ? 'eye-off' : 'eye';
    } else {
      this.pinType = this.pinType === 'password' ? 'number' : 'password';
      this.pinIcon = this.pinIcon === 'eye' ? 'eye-off' : 'eye';
    }
  }

  async confirmRestore() {
    this.forgetPassword.emit();
  }
}
