import { Action } from '@ngrx/store';
import { GraphDataState } from '../store/graphData';
import { SetGraphHomeAction, SetAssetGraphAction, GraphDataActionTypes } from '../actions/graphData.actions';

export const initialGraphSetupState = {
  homeGraphData: [],
  assetGraphData: {}
};

export function graphDataReducer(state: GraphDataState = initialGraphSetupState, action: Action): GraphDataState {
  let payload;
  switch (action.type) {
    case GraphDataActionTypes.SET_HOME_GRAPH_DATA:
      payload = (action as SetGraphHomeAction).payload;
      return {
        ...state,
        homeGraphData: payload.homeGraphData,
      };

    case GraphDataActionTypes.SET_ASSET_GRAPH_DATA:
      payload = (action as SetAssetGraphAction).payload;
      return {
        ...state,
        assetGraphData: payload.assetGraphData,
      };

    case GraphDataActionTypes.RESET_GRAPH_DATA:
      return { ...initialGraphSetupState };

    default:
      return state;
  }
}

