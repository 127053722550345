import { Component, Input, OnInit } from "@angular/core";
import { InAppBrowser, InAppBrowserOptions } from "@ionic-native/in-app-browser/ngx";
import { Store } from "@ngrx/store";
import { Logger } from "src/app/angular-wallet-base/services/logger.service";
import { AppState } from "../../../angular-wallet-base/store/appState";
import {
  getWalletConnectInstance,
  getWalletConnectSelectedNetwork,
  IWalletConnectSelectedNetwork,
} from "../../../angular-wallet-base/store/walletConnect";
import { SubscriptionListenerComponent } from "../../../angular-wallet-base/SubscriptionListenerComponent";
import { take } from "rxjs/operators";
import { IWeb3Wallet } from "@walletconnect/web3wallet";
import { EvmNetworkService } from "src/app/angular-wallet-base/services/evm-network.service";
import { IPeerMeta } from "src/app/angular-wallet-base/lib/wallet-connect/helpers/types";
import { ModalController, Platform } from "@ionic/angular";
import { WalletConnectErrorAction, WalletConnectEventRequestCancelledSuccessAction, WalletConnectEventRequestSignAuthRequestAction } from "src/app/angular-wallet-base/actions/walletConnect.actions";
import { inspect } from "util";
import { AUTH_REASON, GAS_LIMIT_ERC20_SEND, GAS_LIMIT_ERC20_SEND_WC } from "src/app/angular-wallet-base/constants";
import { ethers } from "ethers";
import { PinModalComponent } from "../../pin-modal/pin-modal.component";


@Component({
  selector: "wc-app-modal-request-sending-transaction",
  templateUrl: "./wc-modal-request-sending-transaction.component.html",
  styleUrls: ["./wc-modal-request-sending-transaction.component.scss"],
})
export class WCModalRequestSendingTransactionComponent extends SubscriptionListenerComponent implements OnInit {
  
  @Input() requestEvent: any;
  @Input() requestSession: any;

  public peerMeta: IPeerMeta;
  public transaction: any;
  public requestMethod: string;

  public params;
  public payload: any;
  public selectedWCNetwork: IWalletConnectSelectedNetwork;

  options: InAppBrowserOptions = {
    location: "yes", // Or 'no'
    hidden: "no", // Or  'yes'
    clearcache: "yes",
    clearsessioncache: "yes",
    zoom: "yes", // Android only ,shows browser zoom controls
    hardwareback: "yes",
    mediaPlaybackRequiresUserAction: "no",
    shouldPauseOnSuspend: "no", // Android only
    closebuttoncaption: "Close", // iOS only
    toolbar: "yes", // iOS only
    toolbarposition: "top", // iOS only
    enableViewportScale: "no", // iOS only
    allowInlineMediaPlayback: "no", // iOS only
    presentationstyle: "fullscreen", // iOS only
    fullscreen: "yes", // Windows only
  };

  public gasPriceInToken: number = 0.0;
  public isLoaded = false;
  
  private wcWeb3Wallet: IWeb3Wallet;

  constructor(
    private modalController: ModalController,
    protected store: Store<AppState>,
    private platform: Platform,
    private inAppBrowser: InAppBrowser,
    private evmNetworkService: EvmNetworkService,
  ) {
    super(store);
  }

  async ngOnInit() {

    this.wcWeb3Wallet = await this.store.select(getWalletConnectInstance).pipe(take(1)).toPromise();

    this.peerMeta = this.requestSession?.peer?.metadata;

    const { topic, params } = this.requestEvent;
    const { request, chainId } = params
    this.transaction = request.params[0];
    this.requestMethod = request.method;

    this.payload = this.requestEvent.params;
    this.selectedWCNetwork = await this.store.select(getWalletConnectSelectedNetwork).pipe(take(1)).toPromise();
    await this.getGasPrice();
    this.isLoaded = true;
  }

  async getGasPrice() {
    if (this.selectedWCNetwork.baseChainSymbol == 'AVAX' || this.selectedWCNetwork.baseChainSymbol == 'ETH') {
      let gasPriceToken = await this.evmNetworkService.getEVMGasPrice(this.selectedWCNetwork.baseChainSymbol);
      this.gasPriceInToken = gasPriceToken.fastErc20;// default take fastERC20
    }
  }

  getGasPriceInUnit() {
    const gasPriceInUnit = ethers.utils.parseEther(this.gasPriceInToken.toString());
    return gasPriceInUnit.div(GAS_LIMIT_ERC20_SEND_WC).toString();
  }


  async signRequest(requestEvent) {
    Logger.info(`signing Request: ${requestEvent}`);
    this.store.dispatch(new WalletConnectEventRequestSignAuthRequestAction(
      { eventRequest: requestEvent,
        requestSession: this.requestSession,
        networkFee: this.getGasPriceInUnit(),
      }));

    const modal = await this.modalController.create({
      component: PinModalComponent,
      backdropDismiss: false,
      cssClass: 'fullscreen-modal',
      componentProps: {
        reason: AUTH_REASON.WALLET_CONNECT_SIGN
      },
      id: 'pinModal'
    });
    await modal.present();
    this.closeModal(requestEvent?.id);
  }

  async cancelRequest(requestEvent) {
    try {
      const { topic, params, id } = requestEvent;
      const response = {
        id,
        jsonrpc: '2.0',
        error: {
          code: 5000,
          message: 'User rejected.'
        }
      }
      await this.wcWeb3Wallet.respondSessionRequest({topic, response});
      this.store.dispatch(new WalletConnectEventRequestCancelledSuccessAction({web3Wallet: this.wcWeb3Wallet, eventRequestId: id}));
      this.closeModal(requestEvent?.id);
    } catch (err) {
      Logger.error(`Error - cancelRequest ${inspect(err)}`);
      this.store.dispatch(new WalletConnectErrorAction(err?.message));
    }
  }

  closeModal(requestEventId) {
    this.modalController.dismiss({},'', `requestEvent-${requestEventId}`);
  }

  openUrlPeerMetaUrl() {
    this.platform.ready().then(() => {
      this.inAppBrowser.create(this.peerMeta.url, "_system", this.options);
    });
  }
}
