import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TokenManagerService } from './token-manager.service';
import { AGX_KEYRING_ID, AUX_KEYRING_ID, BLOCKCHAIN_SERVICE_API, CURRENCY_LIST } from '../constants';
import { Logger } from './logger.service';
import { inspect } from 'util';
import { isNil } from '../ts-util';
import { StorageService } from './storage.service';
import { MembersPortalService } from './members-portal.service';

@Injectable()
export class CoinGeckoService {

  private baseUrl: string = 'https://api.coingecko.com/api/v3';
  private coinList: any;

  constructor(
    private http: HttpClient,
    private tokenManager: TokenManagerService,
    private storageService: StorageService,
    private memberPortalService: MembersPortalService
  ) {
  }

  async getSupportedBaseCurrencies() {
    let currencies = await this.storageService.get(CURRENCY_LIST);
    if (currencies) {
      return JSON.parse(currencies.toString());
    }
    // oracleCurrencyList is a list of currencies currently available via the oracle
    const oracleCurrencyList = ['usd', 'eur', 'cad', 'mxn', 'gbp', 'jpy'];
    
    const headers = new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Method": 'GET, POST, PUT, DELETE',
      "Access-Control-Allow-Headers": "*",
    });

    const coinGeckoCurrency: any = await this.http.get(`${this.baseUrl}/simple/supported_vs_currencies`, {headers}).toPromise();
    currencies = coinGeckoCurrency.filter(value => oracleCurrencyList.includes(value)).sort();
    await this.storageService.set(CURRENCY_LIST, JSON.stringify(currencies));
    return currencies;
  }

  async getMarketHistoricalData(coinType, currency, duration) {
    let coinHistoryData = {};
    try {
      const jwt = await this.memberPortalService.returnJwtIfValid();
      const headers = new HttpHeaders({ 
        Authorization: `Bearer ${jwt}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Method": 'GET, POST, PUT, DELETE',
        "Access-Control-Allow-Headers": "*",
       });
      let backendResult: any = await this.http.get(
        `${BLOCKCHAIN_SERVICE_API}/coingecko/getHistoryData/${coinType}/${currency}/${duration}`,
        { headers }
      ).pipe().toPromise();
      coinHistoryData = backendResult?.data;
    } catch (err) {
      Logger.error('getMarketHistoricalData', inspect(err));
      coinHistoryData =  await this.http.get(`${this.baseUrl}/coins/${coinType}/market_chart?vs_currency=${currency}&days=${duration}&interval=daily`).toPromise();
    }
    return coinHistoryData;
  }


  async getCurrentDataTokens(coinType) {
    try {
      const jwt = await this.memberPortalService.returnJwtIfValid();
      const headers = new HttpHeaders({ 
        Authorization: `Bearer ${jwt}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Method": 'GET, POST, PUT, DELETE',
        "Access-Control-Allow-Headers": "*",
     });
      const currentTokenData: any = await this.http.get(
        `${BLOCKCHAIN_SERVICE_API}/coingecko/getTokenData/${coinType}`,
        { headers }
      ).pipe().toPromise();
      Logger.info('getCurrentDataTokens- getbackend');
     return currentTokenData.data;
    } catch (err) {
      Logger.error('getCurrentDataTokens', inspect(err));
      return await this.http.get(`${this.baseUrl}/coins/${coinType}?localization=false&tickers=true&market_data=true&community_data=true&developer_data=false&sparkline=false`).toPromise();
    } 
   }

  async getValueInBaseCurrency(tokenSymbols, baseCurrencyId: string[]): Promise<any> {
    const fiatValues = {};
    const baseCurrency = baseCurrencyId.join(',').toLocaleLowerCase();
    try {
      const supportedAssetList = await this.tokenManager.getTokenList();
      let params = new HttpParams();
      let coingeckoIds: string[] = [];
      coingeckoIds = tokenSymbols
        .filter((keyringId) => supportedAssetList?.[keyringId]?.coingeckoId)
        .map((keyringId) => supportedAssetList[keyringId].coingeckoId)
        .filter((id) => id);

      params = params.append('ids', coingeckoIds.join(','));
      params = params.append('vs_currencies', baseCurrency);

      const geckoResponse = {
        ...(await this.http.get(`${this.baseUrl}/simple/price`, { params }).toPromise()),
      };

      coingeckoIds.forEach((coinName) => {
        const fiatValue: number = geckoResponse[coinName][baseCurrency];
        Object.keys(supportedAssetList).forEach((key) => {
          if(supportedAssetList[key].coingeckoId === coinName) {
            fiatValues[key] = fiatValue;
          }
        });
      });
    } catch (err) {
      Logger.error('Error: getValueInBaseCurrency ', inspect(err));
    }

    return {
      baseCurrency,
      fiatValues,
    };
  }

  // get coin details for summary
  async getCoinDetails(coin: string, currency: string) {
    try {
      const url = `${this.baseUrl}/coins/${coin}`;
      const data: any = await this.http.get(url, {}).toPromise();
      const marketData = data.market_data;
      const summary = {
        currentPrice: marketData.current_price[currency],
        lastChange: marketData.price_change_24h,
        lastChangePercentage: marketData.price_change_percentage_24h,
        latestHigh: marketData.high_24h[currency],
        latestLow: marketData.low_24h[currency],
        dayHigh: marketData.high_24h[currency]
      };
      return summary;
    } catch (error) {
      Logger.error('Get coin details error', coin, currency, inspect(error));
      return {
        currentPrice: 0,
        lastChange: 0,
        lastChangePercentage: 0,
        latestHigh: 0,
        latestLow: 0,
        dayHigh: 0
      };
    }
  }

  public async getCoinId(symbol: string) {
    try {
      if (isNil(this.coinList)) {
        this.coinList = await this.http.get(`${this.baseUrl}/coins/list`).toPromise();
      }
      const coinId = this.coinList.find(coin => coin.symbol.toLowerCase() === symbol.toLowerCase());
      return !isNil(coinId) ? coinId.id : null;
    } catch (error) {
      Logger.error('Get coin list from coingecko', error);
      return null;
    }
  }
}
