import { Action } from "@ngrx/store";
import { UserVestingLTC, UserGeneratorInformation, UserStakingLODE } from '../../global';

export enum StakingLodeActionTypes {
  // initiate the wallet connect web3wallet when loading page
  STAKING_LODE_STATE_INIT_REQUEST = "[stakingLode] Init Staking LODE Request",
  STAKING_LODE_STATE_SUCCESS = "[stakingLode] Init Staking LODE Instance successfully",
  STAKING_LODE_STATE_FAILURE = "[stakingLode] Init Staking LODE Instance failure",
  APPROVE_STAKING_LODE_STATE_REQUEST = "[stakingLode] Approve Staking LODE Request",
  APPROVE_STAKING_LODE_STATE_SUCCESS = "[stakingLode] Approve LODE Success",
  APPROVE_STAKING_LODE_STATE_FAILURE = "[stakingLode] Approve LODE Failure",
  STAKE_STAKING_LODE_STATE_REQUEST = "[stakingLode] Stake Staking LODE Request",
  STAKE_STAKING_LODE_STATE_SUCCESS = "[stakingLode] Stake LODE Success",
  STAKE_STAKING_LODE_STATE_FAILURE = "[stakingLode] Stake LODE Failure",
  LOCK_LODE_STATE_REQUEST = "[stakingLode] Lock LODE Request",
  LOCK_LODE_STATE_SUCCESS = "[stakingLode] Lock LODE Success",
  LOCK_LODE_STATE_FAILURE = "[stakingLode] Lock LODE Failure",
  UNSTAKE_STAKING_LODE_STATE_REQUEST = "[stakingLode] UnStake Staking LODE Request",
  UNSTAKE_STAKING_LODE_STATE_SUCCESS = "[stakingLode] UnStake LODE Success",
  UNSTAKE_STAKING_LODE_STATE_FAILURE = "[stakingLode] UnStake LODE Failure",
  CLAIM_STAKING_LODE_STATE_REQUEST = "[stakingLode] Claim Staking LODE Request",
  CLAIM_STAKING_LODE_STATE_SUCCESS = "[stakingLode] Claim LODE Success",
  CLAIM_STAKING_LODE_STATE_FAILURE = "[stakingLode] Claim LODE Failure",
  APPROVE_VOTE_STATE_REQUEST = "[stakingLode] Approve VOT3 Request",
  APPROVE_VOTE_STATE_SUCCESS = "[stakingLode] Approve VOT3 Success",
  APPROVE_VOTE_STATE_FAILURE = "[stakingLode] Approve VOT3 Failure",
}

export interface ClaimStakingLodeStateSuccessPayload {
  depositBalance: number;
  amountClaimed: number;
  claimableBalance: number;
}

export class StakingLodeStateInitRequestAction implements Action {
  readonly type = StakingLodeActionTypes.STAKING_LODE_STATE_INIT_REQUEST;
  constructor(readonly address: string) {}
}

export class StakingLodeStateInitSuccessAction implements Action {
  readonly type = StakingLodeActionTypes.STAKING_LODE_STATE_SUCCESS;
  constructor(readonly payload: {user: UserGeneratorInformation}) {}
}

export class StakingLodeStateInitFailureAction implements Action {
  readonly type = StakingLodeActionTypes.STAKING_LODE_STATE_FAILURE;
  constructor(readonly payload: string) {}
}

export class ApproveStakingLodeStateAction implements Action {
  readonly type = StakingLodeActionTypes.APPROVE_STAKING_LODE_STATE_REQUEST;
  constructor(readonly user: any) {}
}

export class ApproveStakingLodeStateSuccessAction implements Action {
  readonly type = StakingLodeActionTypes. APPROVE_STAKING_LODE_STATE_SUCCESS;
    constructor(readonly payload: {user: UserGeneratorInformation}) {}
}

export class ApproveStakingLodeStateFailureAction implements Action {
  readonly type = StakingLodeActionTypes.APPROVE_STAKING_LODE_STATE_FAILURE;
  constructor(readonly payload: string) {}
}

export class StakeStakingLodeStateAction implements Action {
  readonly type = StakingLodeActionTypes.STAKE_STAKING_LODE_STATE_REQUEST;
  constructor(readonly user: any, readonly amount: number) {}
}

export class StakeStakingLodeStateSuccessAction implements Action {
  readonly type = StakingLodeActionTypes.STAKE_STAKING_LODE_STATE_SUCCESS;
  constructor(readonly payload: {user: UserGeneratorInformation}) {}
}

export class StakeStakingLodeStateFailureAction implements Action {
  readonly type = StakingLodeActionTypes.STAKE_STAKING_LODE_STATE_FAILURE;
  constructor(readonly payload: string) {}
}



export class LockLodeStateAction implements Action {
  readonly type = StakingLodeActionTypes.LOCK_LODE_STATE_REQUEST;
  constructor(readonly user: any, readonly amount: number) {}
}

export class LockLodeStateSuccessAction implements Action {
  readonly type = StakingLodeActionTypes.LOCK_LODE_STATE_SUCCESS;
  constructor(readonly payload: {user: UserGeneratorInformation}) {}
}

export class LockLodeStateFailureAction implements Action {
  readonly type = StakingLodeActionTypes.LOCK_LODE_STATE_FAILURE;
  constructor(readonly payload: string) {}
}

export class UnStakeStakingLodeStateAction implements Action {
  readonly type = StakingLodeActionTypes.UNSTAKE_STAKING_LODE_STATE_REQUEST;
  constructor(readonly user: any, readonly amount: number) {}
}

export class UnStakeStakingLodeStateSuccessAction implements Action {
  readonly type = StakingLodeActionTypes.UNSTAKE_STAKING_LODE_STATE_SUCCESS;
  constructor(readonly payload: {user: UserGeneratorInformation}) {}
}

export class UnStakeStakingLodeStateFailureAction implements Action {
  readonly type = StakingLodeActionTypes.UNSTAKE_STAKING_LODE_STATE_FAILURE;
  constructor(readonly payload: string) {}
}

export class ClaimStakingLodeStateAction implements Action {
  readonly type = StakingLodeActionTypes.CLAIM_STAKING_LODE_STATE_REQUEST;
  constructor(readonly user: any) {}
}

export class ClaimStakingLodeStateSuccessAction implements Action {
  readonly type = StakingLodeActionTypes.CLAIM_STAKING_LODE_STATE_SUCCESS;
  constructor(readonly payload: {user: UserGeneratorInformation}) {}
}

export class ClaimStakingLodeStateFailureAction implements Action {
  readonly type = StakingLodeActionTypes.CLAIM_STAKING_LODE_STATE_FAILURE;
  constructor(readonly payload: string) {}
}

export class ApproveVoteStateAction implements Action {
  readonly type = StakingLodeActionTypes.APPROVE_VOTE_STATE_REQUEST;
  constructor(readonly user: any) {}
}

export class ApproveVoteStateSuccessAction implements Action {
  readonly type = StakingLodeActionTypes.APPROVE_VOTE_STATE_SUCCESS;
  constructor(readonly payload: {user: UserGeneratorInformation}) {}
}

export class ApproveVoteStateFailureAction implements Action {
  readonly type = StakingLodeActionTypes.APPROVE_VOTE_STATE_FAILURE;
  constructor(readonly payload: string) {}
}


