import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../services/storage.service';
import { AUX_FEE_CALC_CONST_1, AUX_FEE_CALC_CONST_2, GAS_LIMIT_ERC20_SEND, WALLET_STORAGE_KEY } from 'src/app/angular-wallet-base/constants';
import { Logger } from './logger.service';
import { hdPaths, TransactionOutput, TransactionOutputs } from '../lib/pangolin/keyring';
import { setTransactionMemo } from '../utils';
import { HTTP } from '@ionic-native/http/ngx';
import BtcEstimator from 'btc-tx-size-fee-estimator';
import { ethers } from 'ethers';
import { inspect } from 'util';

const sjs = require('syscoinjs-lib');
const bitcoin = (window as any).bitcoinjsLib;
const btcEstimator = new BtcEstimator();

import * as ed25519 from 'ed25519-hd-key';
const bip39 = (window as any).bip39;
import * as solanaWeb3 from '@solana/web3.js';
import * as splToken from '@solana/spl-token';
import { EvmNetworkService } from './evm-network.service';
import { BitCoinNetworkService } from './bitcoin-network.service';
import { getUnlockKeychain } from '../lib/pangolin/keyring/keychainUtils';

export interface UTXO {
  txid: string,
  vout: number,
  value: number,
  height: string,
  confirmations: number
}


@Injectable()
export class TransactionSenderService {
  private sendFromUri = 'api/v2/sendfrom';
  private assetAllocationSendUri = 'api/v2/assetallocationsend';
  private sendRawTransactionUri = 'api/v2/sendtx';
  private getRawTransactionUri: string = 'api/getrawtransaction';
  private currentNonce: Map<String, number> = new Map([['ETH', 0], ['AVAX', 0]]);

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    public httpPlugin: HTTP,
    private evmNetworkService: EvmNetworkService,
    private bitcoinNetworkService: BitCoinNetworkService,
  ) { }

  protected async getRawTransaction(txid: string) {
    const network = await this.evmNetworkService.getActiveSysNetwork();
    let params = new HttpParams();
    params = params.append('txid', txid);
    params = params.append('verbose', '1');

    return this.http.get(`${network.URL}/${this.getRawTransactionUri}`, { params }).toPromise();
  }

  public async appendTxFeeToRawTx(rawtx) {
    const newTx: any = bitcoin.Transaction.fromHex(rawtx.tx.hex);
    const prevOuts: TransactionOutput[] = rawtx.prevVouts;

    const prevOutsValue = prevOuts[0].ValueSat;
    const outputsValue = newTx.outs.reduce((prev, next) => ({ value: prev.value + next.value }), { value: 0 }).value;

    Logger.info('Previous outputs: ', prevOuts);
    Logger.info('New tx outputs: ', outputsValue);

    rawtx.fee = prevOutsValue - outputsValue;
  }

  public async calcTxFeeFetchingPrevOuts(tx: any) {
    // For usage outside send component
    const currentTxid: string = tx.txid;
    const currentTx: any = await this.getRawTransaction(currentTxid);
    const currentVins: any[] = currentTx.vin;

    let prevOutsValue: any = await Promise.all(
      currentVins.map(async (input: any) => {
        const prevTx: any = await this.getRawTransaction(input.txid);
        return prevTx.vout[input.vout].value;
      })
    );
    prevOutsValue = prevOutsValue.reduce((prev, next) => prev + next, 0);
    const currentOutsValue: number = currentTx.vout.map((out: any) => out.value).reduce((prev, next) => prev + next, 0);

    // SYS fee = inputs values - outputs value.
    return prevOutsValue - currentOutsValue;
  }

  async getUnsignedTransactionHex(fromAddress, toAddress, amount, guid?) {
    let response;
    let params = new HttpParams();
    const network = await this.evmNetworkService.getActiveSysNetwork();


    if (guid) { // asset allocation send
      params = params.append('from', fromAddress);
      params = params.append('to', toAddress);
      params = params.append('amount', amount);

      response = await this.http.get(`${network.URL}/${this.assetAllocationSendUri}/${guid}`, { params }).toPromise();

      try {
        await this.appendTxFeeToRawTx(response);
      } catch (err) {
        Logger.info(err);
      }
    } else { // regular sys send
      params = params.append('to', toAddress);
      params = params.append('amount', amount);
      response = await this.http.get(`${network.URL}/${this.sendFromUri}/${fromAddress}`, { params }).toPromise();
    }

    return response;

  }


  async sendRawTransaction(hexStr) {
    const network = await this.evmNetworkService.getActiveBtcNetwork();
    const response: any = await this.http.get(`${network.URL}/${this.sendRawTransactionUri}/${hexStr}`).toPromise();
    return response;
  }

  async signRawTransaction(fromAddress, hexStr, password, outputs, memo?): Promise<string> {
    const tx = bitcoin.Transaction.fromHex(hexStr);

    setTransactionMemo(tx, memo);

    Logger.info('tx', tx);
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getUnlockKeychain(vault, password);
    const signedTx = keychain.signTransaction(fromAddress, tx, (outputs as TransactionOutputs));
    const signTxHex = signedTx.toHex();

    Logger.info('signed hex:', signTxHex);

    return signTxHex;
  }

  async getNonceByEVMChain(address: string, network ) {
    const provider = ethers.providers.getDefaultProvider(network.RPC_URL);
    const latestNonce = await provider.getTransactionCount(address, 'latest');
    let currentNonce = this.currentNonce.get(network.chain);
    currentNonce = (currentNonce > 0 && currentNonce === latestNonce) ? currentNonce +1: latestNonce;
    this.currentNonce.set(network.chain, currentNonce);
    return currentNonce;
  }


  async getGasPrice() {
    const network = await this.evmNetworkService.getActiveAvaxNetwork();
    try {
      const provider = ethers.providers.getDefaultProvider(network.RPC_URL);
      const gasPrice = await provider.getGasPrice();
      return ethers.utils.hexlify(gasPrice);
    } catch (err) {
      Logger.error('Get Avax gas price error', inspect(err));
    }
  }

  async sendEvmRawTransaction(signTx, evmNetwork) {
    const provider = ethers.providers.getDefaultProvider(evmNetwork.RPC_URL);
    const response = await provider.sendTransaction(signTx);
    return response.hash;
  }

  async sendAvaxRawTransaction(hexStr) {
    const network = await this.evmNetworkService.getActiveAvaxNetwork();
    const provider = ethers.providers.getDefaultProvider(network.RPC_URL);
    const response = await provider.sendTransaction(hexStr);
    return response.hash;
  }

  async signAvaxRawTransaction(fromAddress: string, tx: any, password: string): Promise<string> {
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getUnlockKeychain(vault, password);
    Logger.info('pre-signing hex:', tx);
    const signedTx = keychain.signTransaction(fromAddress, tx, null);

    Logger.info('signedTx:', signedTx);

    return signedTx;
  }

  async sendEthRawTransaction(hexStr) {
    const network = await this.evmNetworkService.getActiveEthNetwork();
    const response: any = await this.http.get(`${network.URL}/${this.sendRawTransactionUri}/${hexStr}`).toPromise();
    return response;
  }
  async signEthRawTransaction(fromAddress, tx, password): Promise<string> {

    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getUnlockKeychain(vault, password);
    Logger.info('pre-signing hex:', tx);
    const signedTx = keychain.signTransaction(fromAddress, tx, null);

    Logger.info('signedTx:', signedTx);

    return signedTx;
  }

  async signTypedData(fromAddress, password, domain, types, data): Promise<string> {
    const vault = await this.storage.get(WALLET_STORAGE_KEY);
    const keychain = await getUnlockKeychain(vault, password);
    const sigedData = await keychain.signTypedData(fromAddress, domain, types, data);
    Logger.info('signedTx:', sigedData);

    return sigedData;
  }

  async evmSignAndSendRawTransaction(pin: string, transactionData): Promise<string> {
    const network = await this.evmNetworkService.getActiveNetwork(transactionData.tokenInfo.baseChainSymbol);
    Logger.info('evmSignAndSendRawTransaction :', inspect(transactionData));
    const nonce = await this.getNonceByEVMChain(transactionData.fromAddress, network);
    let tx = {
      chainId: network.chainId,
      gasLimit: ethers.utils.hexlify(transactionData?.gasLimit),      // TODO: investigate if there's a way to know this limit
      gasPrice: ethers.utils.hexlify(transactionData?.gasPrice),
      nonce: ethers.utils.hexlify(nonce),
      to: transactionData.toAddress,
      data: transactionData.callData,
      value: transactionData?.value
    };
    const signed = await this.signEthRawTransaction(transactionData.fromAddress, tx, pin);
    return await this.sendEvmRawTransaction(signed, network);
   }


  async getSignAndSendKit(coin: string, pin?: string) {
    let vault, keychain, mnemonic, HDSigner, wif;
    const activeSysNetwork = coin === 'BTC' ?
      await this.evmNetworkService.getActiveBtcNetwork() : await this.evmNetworkService.getActiveSysNetwork();
    const backendURL = activeSysNetwork.URL;
    const isTestnet = activeSysNetwork.network === 'testnet';
    const networks = (coin === 'BTC') ? sjs.utils.bitcoinNetworks : sjs.utils.syscoinNetworks;
    const SLIP44 = (coin === 'BTC') ? sjs.utils.bitcoinSLIP44 : sjs.utils.syscoinSLIP44;

    const network = networks[isTestnet ? 'testnet' : 'mainnet'];

    if (pin) {
      vault = await this.storage.get(WALLET_STORAGE_KEY);
      keychain = await getUnlockKeychain(vault, pin);
      mnemonic = keychain.keyrings[0].mnemonic;
      HDSigner = new sjs.utils.HDSigner(mnemonic, null, isTestnet, networks, SLIP44);
      try {
        wif = keychain.keyrings[0].nodes.find(node => coin === 'BTC' ? node.isBtc() : node.isSys()).keypairs[0].toWIF();
      } catch (err) {
        Logger.error('could not get WIF', err);
      }
    }

    const syscoinjs = new sjs.SyscoinJSLib(HDSigner || null, backendURL, network);

    return {
      hdSigner: HDSigner,
      syscoinjs,
      sjs,
      backendURL,
      wif
    };
  }


  async getSignAndSendBitcoinKit(coin: string, pin?: string) {
    let vault, keychain, mnemonic, HDSigner, wif;
    const bitCoinNetwork = await this.evmNetworkService.getActiveBtcNetwork();
    const backendURL = bitCoinNetwork.URL;
    const isTestnet = bitCoinNetwork.network === 'testnet';
    const networks = sjs.utils.bitcoinNetworks;
    const SLIP44 = sjs.utils.bitcoinSLIP44;

    const network = networks[isTestnet ? 'testnet' : 'mainnet'];

    if (pin) {
      vault = await this.storage.get(WALLET_STORAGE_KEY);
      keychain = await getUnlockKeychain(vault, pin);
      mnemonic = keychain.keyrings[0].mnemonic;
      HDSigner = new sjs.utils.HDSigner(mnemonic, null, isTestnet, networks, SLIP44);
      try {
        wif = keychain.keyrings[0].nodes.find(node => coin === 'BTC' ? node.isBtc() : node.isSys()).keypairs[0].toWIF();
      } catch (err) {
        Logger.error('could not get WIF', err);
      }
    }

    const syscoinjs = new sjs.SyscoinJSLib(HDSigner || null, backendURL, network);

    return {
      hdSigner: HDSigner,
      syscoinjs,
      sjs,
      backendURL,
      wif
    };
  }

  async sendSyscoin(amount, fromAddress, toAddress, txtmemo, password, coin) {
    if (!fromAddress || !toAddress || !amount) {
      throw new Error('sendSysBtccoin: Missing required param');
    }
    const sys = await this.getSignAndSendKit(coin, password);

    let psbt = await this.buildSysBtcTransaction(sys, amount, fromAddress, toAddress, txtmemo);
    psbt = await sys.syscoinjs.signAndSendWithWIF(psbt.psbt, sys.wif);

    if (!psbt) {
      Logger.error('Could not create transaction, not enough funds?');
      return;
    }

    return psbt.extractTransaction().getId();
  }

  async sendBitcoin(amount: number, fromAddress: string, toAddress: string, networkFee: number, password: string) {
    return this.bitcoinNetworkService.buildAndSendBitcoin(amount, fromAddress, toAddress, networkFee, password)
  }
  
  buildSysBtcTransaction(sys, amount, fromAddress, toAddress, txtmemo) {
    const feeRate = new sjs.utils.BN(10);
    const txOpts: any = { rbf: false };
    if (txtmemo) {
      const memo = Buffer.from(txtmemo);
      const memoHeader = Buffer.from([0xfe, 0xfe, 0xaf, 0xaf, 0xaf, 0xaf]);
      txOpts.memoHeader = memoHeader;
      txOpts.memo = memo;
    }
    // if SYS need change sent, set this address. null to let HDSigner find a new address for you
    const sysChangeAddress = fromAddress;
    const satoshiAmount = Math.round(100000000 * amount);
    const outputsArr = [
      { address: toAddress, value: new sjs.utils.BN(satoshiAmount) }
    ];
    return sys.syscoinjs.createTransaction(txOpts, sysChangeAddress, outputsArr, feeRate, sysChangeAddress);
  }

  async estimateFeeBasedOnAmount(amount, fromAddress, toAddress, txmemo = '', coin = 'BTC') {
    const sys = await this.getSignAndSendKit(coin);
    let psbt = await this.buildSysBtcTransaction(sys, amount, fromAddress, toAddress, txmemo);

    psbt = psbt.psbt;

    return this.estimateFee(psbt.data.inputs.length, psbt.data.outputs.length, 10);
  }

  estimateFee(inputCount, outputCount, feeRate) {
    const weight = btcEstimator.calcTxSize({
      input_count: inputCount,
      p2pkh_output_count: outputCount,
      input_m: inputCount
    });
    return btcEstimator.formatFeeRange(weight.txVBytes * feeRate, 0.1);
  }


  async sendAsset(amount, fromAddress, toAddress, password, guid, txtmemo) {
    if (!fromAddress || !toAddress || !amount) {
      throw new Error('sendSysBtccoin: Missing required param');
    }

    const sys = await this.getSignAndSendKit('sys', password);

    const feeRate = new sjs.utils.BN(10);
    const txOpts: any = { rbf: false };
    if (txtmemo) {
      const memo = Buffer.from(txtmemo);
      const memoHeader = Buffer.from([0xfe, 0xfe, 0xaf, 0xaf, 0xaf, 0xaf]);
      txOpts.memoHeader = memoHeader;
      txOpts.memo = memo;
    }
    const assetChangeAddress = fromAddress;
    const assetguid = guid;
    const satoshiAmount = Math.round(100000000 * amount);
    const assetMap = new Map([
      [assetguid, { changeAddress: assetChangeAddress, outputs: [{ value: new sjs.utils.BN(satoshiAmount), address: toAddress }] }]
    ]);
    let psbt = await sys.syscoinjs.assetAllocationSend(txOpts, assetMap, assetChangeAddress, feeRate, assetChangeAddress);
    psbt = await sys.syscoinjs.signAndSendWithWIF(psbt.psbt, sys.wif, psbt.assets);
    if (!psbt) {
      Logger.error('Could not create transaction, not enough funds?');
      return;
    }

    return psbt.extractTransaction().getId();
  }

  async calcAuxFees(assetGuid, amount) {
     try {
      const activeSysNetwork = await this.evmNetworkService.getActiveSysNetwork();
      const backendURL = activeSysNetwork.URL;
      const res = await sjs.utils.fetchBackendAsset(backendURL, assetGuid);
      Logger.info('fetchBackendAsset ', res, backendURL);
      let value = AUX_FEE_CALC_CONST_1 * amount;
      let auxfee = 0;
      if (res?.auxFeeDetails && res.auxFeeDetails.auxFees) {
        res.auxFeeDetails.auxFees[0].bound = 0;
        const feeEntry = res.auxFeeDetails.auxFees.filter(fee => fee.bound <= value);
        feeEntry.reverse();
        feeEntry.forEach((fe) => {
          if (fe.bound > value) {
            auxfee += this.calculateFees(value, fe.percent);
          } else {
            const tierAmount = value - fe.bound;
            value = fe.bound;
            auxfee += this.calculateFees(tierAmount, fe.percent);
          }
        });
      }
      return auxfee;
     } catch (err) {
        // Log error and return default value
        Logger.error(`Error: calcAuxFees ${inspect(err)}`);
        return this.calculateFees(1, 0.1);
     }
    
  }

  calculateFees(value: number, percentage: any) {
    return Number(((value / AUX_FEE_CALC_CONST_1 * percentage / AUX_FEE_CALC_CONST_2)).toFixed(12));
  }

  async sendSolSplTransaction(amount, fromAddress, toAddress, password, guid, txtmemo) {
    try {
      const vault = await this.storage.get(WALLET_STORAGE_KEY);
      const keychain = await getUnlockKeychain(vault, password);

      const seed: Buffer = await bip39.mnemonicToSeed(keychain.keyrings[0].mnemonic);
      const derivedSeed = ed25519.derivePath(hdPaths.solanaMainnet, seed.toString('hex')).key;
      const keypair = solanaWeb3.Keypair.fromSeed(derivedSeed);

      // Connect to cluster
      const rpcUrl = await this.evmNetworkService.getActiveSolNetwork();
      const connection = new solanaWeb3.Connection(rpcUrl.URL, 'confirmed');
      Logger.info('Sending across cluster:', rpcUrl.URL);
      const fromWallet = keypair;
      const toPublicKey = new solanaWeb3.PublicKey(toAddress)

      // Construct my token class
      const myMint = new solanaWeb3.PublicKey(guid);
      const myToken = new splToken.Token(
        connection,
        myMint,
        splToken.TOKEN_PROGRAM_ID,
        fromWallet
      );
      // Create associated token accounts for my token if they don't exist yet
      const fromTokenAccount = await myToken.getOrCreateAssociatedAccountInfo(
        fromWallet.publicKey
      );
      const toTokenAccount = await myToken.getOrCreateAssociatedAccountInfo(
        toPublicKey
      );
      // Add token transfer instructions to transaction
      const transaction = new solanaWeb3.Transaction()
        .add(
          splToken.Token.createTransferInstruction(
            splToken.TOKEN_PROGRAM_ID,
            fromTokenAccount.address,
            toTokenAccount.address,
            fromWallet.publicKey,
            [],
            solanaWeb3.LAMPORTS_PER_SOL * amount,
          )
        );
      // Sign transaction, broadcast, and confirm
      const signature = await solanaWeb3.sendAndConfirmTransaction(
        connection,
        transaction,
        [fromWallet]
      );

      Logger.info('signedTx:', signature);

      return signature;
    } catch (error) {
      throw error;
    }
  }

  async sendSolTransaction(amount, fromAddress, toAddress, txtmemo, password, coin) {

    try {
      const vault = await this.storage.get(WALLET_STORAGE_KEY);
      const keychain = await getUnlockKeychain(vault, password);

      const seed: Buffer = await bip39.mnemonicToSeed(keychain.keyrings[0].mnemonic);
      const derivedSeed = ed25519.derivePath(hdPaths.solanaMainnet, seed.toString('hex')).key;
      const keypair = solanaWeb3.Keypair.fromSeed(derivedSeed);

      // Connect to cluster
      const rpcUrl = await this.evmNetworkService.getActiveSolNetwork();
      const connection = new solanaWeb3.Connection(rpcUrl.URL, 'confirmed');
      Logger.info('Sending across cluster:', rpcUrl.URL);
      const fromWallet = keypair;
      const toPublicKey = new solanaWeb3.PublicKey(toAddress);

      // Creating transaction
      const transaction = new solanaWeb3.Transaction().add(
        solanaWeb3.SystemProgram.transfer({
          fromPubkey: fromWallet.publicKey,
          toPubkey: toPublicKey,
          lamports: solanaWeb3.LAMPORTS_PER_SOL * amount,
        }),
      );

      // Sign transaction, broadcast, and confirm
      const signature = await solanaWeb3.sendAndConfirmTransaction(
        connection,
        transaction,
        [fromWallet],
      );
      Logger.info('signedTx:', signature);

      return signature;
    } catch (error) {
      throw error;
    }
  }

}
