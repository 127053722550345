import { createSelector } from "@ngrx/store";
import { TokenInfo } from "src/app/global";
import { getPurchaseOrderState } from "./appState";

export enum ORDER_STATUS {
  INIT = 'INIT',
  PENDING = 'PENDING',
  SUCCESSED = 'SUCCESSED',
  FAILURED = 'FAILURED'
}

export interface OrderInfo {
  paymentType: string, // Credit, bank transfer,
  purchaseAmount?: number,
  tokenInfo?: TokenInfo,
  paidAmount?: number,
  paidCurrency?: string,
  paymentMethod: string, /// Singularity,...
  status: ORDER_STATUS,
  createdTime: number,
  walletAddress: string,
}

export interface SingularityOrderInfo extends OrderInfo {
  body: any,
  singularityAssetInfo: SingularityAssetInfo,
}

export interface SingularityAssetInfo {
  assetId: number,
  assetName: string,
  assetDescription: string,
  chainCategory: string,
  chainId: string,
  networkId: number,
  assetIconURL: string,
  chainDetailsId: string
}

export interface PurchaseOrderState {
  pendingOrder: OrderInfo,
  initSingularityPayment: boolean,
  singularityAssets: SingularityAssetInfo[],
  isSingularityPaymentReady: boolean,
}

export const isInitializedSingularityPayment = createSelector(
  getPurchaseOrderState,
  (state: PurchaseOrderState) => state.initSingularityPayment
)

export const getPendingOrder = createSelector(
  getPurchaseOrderState,
  (state: PurchaseOrderState) => state.pendingOrder
)

export const getSingularityAssets = createSelector(
  getPurchaseOrderState,
  (state: PurchaseOrderState) => state.singularityAssets
)


export const isSingularityPaymentReady = createSelector(
  getPurchaseOrderState,
  (state: PurchaseOrderState) => state.isSingularityPaymentReady
)
