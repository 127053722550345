import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BTC_TRANSACTION_SIZE, WALLET_STORAGE_KEY } from "../constants";
import { SmallToCryptoPipe } from "../pipes/smalltocrypto/smalltocrypto.pipe";
import { isNil } from "../ts-util";
import { numToSatoshi } from "../utils";
import { Logger } from "./logger.service";
import { NetworkService } from "./network.service";
import { RpcProvider } from "./rpc-provider";
import { StorageService } from "./storage.service";
import { getUnlockKeychain } from "../lib/pangolin/keyring/keychainUtils";
const sjs = require('syscoinjs-lib');
const bitcoin = sjs.utils.bitcoinjs;

export interface BTCTransactionOptions {
    from: string;
    to: string;
    amtSatoshi: number,
    privKeyWIF: string;
    feePerByte: number;
    minConfirmations?: number;
}

export interface UTXO {
    txid: string;
    vout: number;
    value: number;
    height: number;
    confirmations: number;
}

@Injectable({
  providedIn: 'root'
})
export class BitCoinNetworkService extends NetworkService {

    constructor(protected storage: StorageService, 
        protected http: HttpClient, 
        protected rpcProvider: RpcProvider,
        protected smallToCryptoPipe: SmallToCryptoPipe) {
        super(storage, http, rpcProvider, smallToCryptoPipe);
    }


    async buildAndSendBitcoin(amount: number, fromAddress: string, toAddress: string, networkFee: number, password: string) {
        try {
         const bitcoinCurrentNetwork = await this.getActiveBtcNetwork();
         const vault = await this.storage.get(WALLET_STORAGE_KEY);
         const keychain = await getUnlockKeychain(vault, password);
         let keypair;
          await keychain.keyrings[0].nodes.forEachAsync(async node => {
            if (node.isBtc()) {
              keypair = node.keypairs[0];
            }
          })
          Logger.info('networkFee ber byte ', numToSatoshi(networkFee)/BTC_TRANSACTION_SIZE);
          
    
          let options: BTCTransactionOptions = {
            from: fromAddress,
            to: toAddress,
            amtSatoshi: numToSatoshi(amount),
            privKeyWIF: keypair.toWIF(),
            feePerByte: Math.floor(numToSatoshi(networkFee)/BTC_TRANSACTION_SIZE)
          }
          let signedTransactionHex = await this.buildBtcTransaction(options, bitcoinCurrentNetwork);
          let transactionId = await this.sendRawTransaction(signedTransactionHex, bitcoinCurrentNetwork);

          Logger.info('result', transactionId);
          return transactionId;
        } catch (err) {
          Logger.error('sendBitcoin', err);
          throw new Error('Error ' + err.message);
        }
      }

    async buildBtcTransaction(options: BTCTransactionOptions, bitcoinCurrentNetwork) {
       
        //Required
        if (options == null || typeof options !== 'object') throw Error("Options must be specified and must be an object.");
        if (options.from == null) throw Error("Must specify from address.");
        if (options.to == null) throw Error("Must specify to address.");
        if (options.amtSatoshi == null) throw Error("Must specify amount of btc to send.");
        if (options.privKeyWIF == null) throw Error("Must specify the wallet's private key in WIF format.");
      
        //Optionals
        if (options.minConfirmations == null) options.minConfirmations = 0;
      
        let from = options.from;
        let to = options.to;
    
        var bitcoinNetwork = bitcoinCurrentNetwork.network == "testnet" ? bitcoin.networks.testnet : bitcoin.networks.bitcoin;
    
        let keyPair = bitcoin.ECPair.fromWIF(options.privKeyWIF, bitcoinNetwork);
        let utxos = await this.getUtxosByAddressByNowNode(options.from, bitcoinCurrentNetwork);
        if (isNil(utxos)) throw Error('Error the address ' + from + ' has no UTXO')
        //const p2wpkh = bitcoin2.payments.p2wpkh({ pubkey: keyPair.publicKey, network: bitcoinNetwork });
       /* const p2sh = bitcoin2.payments.p2sh({
          redeem: bitcoin2.payments.p2wpkh({
            pubkey: keyPair.publicKey,
            bitcoinNetwork
          }),
          bitcoinNetwork
        }); 
        
        Logger.info('redemptionScript', p2sh.output.toString())*/
    
        const psbt = new bitcoin.Psbt({ network: bitcoinNetwork, maximumFeeRate: 7500  })
    
        let availableSat: number = 0;
        let ninputs: number = 0;
    
        const MAX_BIP125_RBF_SEQUENCE = 0xfffffffd;
    
      
        for (let i = 0; i < utxos.length; i++) {
          var utxo = utxos[i];
          // only spend utxo with number block confirmation at least minConfirmation 6
          if (utxo.confirmations > options.minConfirmations) {
            availableSat += utxo.value;
            ninputs++;
            let rawTransaction = await this.getRawHex(utxo.txid, bitcoinCurrentNetwork);
            if (! isNil(rawTransaction)) {
              let isSegwit = rawTransaction.substring(8, 12) === '0001';
      
              if (isSegwit) {
                let scriptPubKey = bitcoin.address.toOutputScript(from,bitcoinNetwork);
                Logger.info('scriptPubKey', scriptPubKey);
              
                // add segwit transaction input
                await psbt.addInput({
                  hash: utxo.txid,
                  index: utxo.vout,
                  sequence: MAX_BIP125_RBF_SEQUENCE,
                 // bip32Derivation: bip32Derivation,
                  witnessUtxo: {
                    script: scriptPubKey,
                    value: utxo.value // value in satoshi
                  }
                })
              } else {
                // add non-segwit transaction input
                await psbt.addInput({
                  hash: utxo.txid,
                  index: utxo.vout,
                  sequence: MAX_BIP125_RBF_SEQUENCE,
                 // bip32Derivation: bip32Derivation,
                  nonWitnessUtxo: Buffer.from(rawTransaction, 'hex'),
                })
              }
            }
          }

          // only collect utxo until enough amtSatoshi
          if (availableSat > options.amtSatoshi + options.feePerByte * BTC_TRANSACTION_SIZE) break;
          
        }
    
        // add to address and amount transfer
        await psbt.addOutput({
          address: to,
          value: options.amtSatoshi
        });
        if (availableSat < options.amtSatoshi) throw Error("You do not have enough in your wallet to send that much.");
    
       
    
        let fee = this.getTransactionSize(ninputs, 2)* options.feePerByte;
        Logger.info('fee', fee);
        if (fee > 2* options.amtSatoshi) throw Error("BitCoin amount must be larger than the fee. (Ideally it should be MUCH larger)");;
        
        let change = availableSat - options.amtSatoshi - fee;
    
        if (change > 0) await psbt.addOutput({
          address: from,
          value: change
        });
        
        await psbt.signAllInputs(keyPair);
    
        Logger.info('sign' , psbt.validateSignaturesOfAllInputs());
    
        psbt.finalizeAllInputs()
        // signed transaction hex
       const transaction = psbt.extractTransaction();
       return transaction.toHex();
    }

    public async sendRawTransaction(transactionHex: string, bitcoinCurrentNetwork) {
        Logger.info('hex', transactionHex);
       
        const url =  bitcoinCurrentNetwork.URL + "/sendtx/" + transactionHex;
        Logger.info('nowNodes url ', url);
        const optionHeaders = new HttpHeaders({"api-key" : bitcoinCurrentNetwork.nowNodeAPIKey});
        let result: any = await this.http.get(url, {headers: optionHeaders}).toPromise();
        Logger.info('result', result);
        return result.result;
    }

    private getTransactionSize(numInputs, numOutputs) {
        return numInputs*180 + numOutputs*34 + 10 + numInputs;
      }

      
    private async getRawHex(txId: string, bitcoinNetwork) {
        try {
          const url = bitcoinNetwork.URL + "/tx/" + txId;
          Logger.info('nowNodes url ', url);
          const optionHeaders = new HttpHeaders({"api-key" : bitcoinNetwork.nowNodeAPIKey});
          let txDetails: any = await this.http.get(url, {headers: optionHeaders}).toPromise();
          Logger.info(txDetails);
          if(txDetails?.hex) {
            return txDetails.hex;
          }
        } catch (err) {
          Logger.error('getRawHex at transaction id ' + txId, err);
        }
    }

    private async getUtxosByAddressByNowNode(fromAddress: string, bitcoinNetwork): Promise<UTXO[]> {
        try {
            const url = bitcoinNetwork.URL  + "/utxo/" + fromAddress;
            Logger.info('nowNodes url ', url);
            const optionHeaders = new HttpHeaders({"api-key" : bitcoinNetwork.nowNodeAPIKey});
            let utxos: any = await this.http.get(url, {headers: optionHeaders}).toPromise();
            return utxos.map(utxo => {
              return {
                txid: utxo.txid,
                vout: utxo.vout,
                value: parseInt(utxo.value),
                height: utxo.height,
                confirmations: utxo.confirmations
            }
          });
        } catch (err) {
            Logger.error('getUtxosByAddressByNowNode', err);
            return [];
        }
    }
    
    
}
