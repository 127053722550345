import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { root } from 'rxjs/internal-compatibility';
@Injectable({
  providedIn: 'root'
})
export class QrDataServiceService {

  private messageSource = new BehaviorSubject('');
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(inputPublicKey: string) {
    this.messageSource.next(inputPublicKey);
  }

}
