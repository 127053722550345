import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { AppState, getAccountSetupState } from '../store/appState';
import { Store } from '@ngrx/store';
import { IonModal, ModalController, NavController, Platform } from '@ionic/angular';
import { Logger } from './logger.service';
import { CompleteSetupAction } from '../actions/setup.actions';
import { ResetUserPreferencesAction, UpdateJwtAction } from '../actions/userPreferences.actions';
import { decryptData, encryptData } from '../utils';
import { getSetupPin } from '../store/setup';
import 'rxjs/add/operator/first';
import { filter, take, pairwise } from 'rxjs/operators';
import { AUTH_DATA_KEY, LNG_KEY, LODE_ID, SKIP_INTRO_PAGE_KEY, SKIP_PRIMARY_ADDRESS_MODAL, SUPPORTED_ASSETS, USER_PREFERENCES_STORAGE_KEY, WALLET_STORAGE_KEY } from '../constants';
import { Storage } from '@ionic/storage';
import { ResetAccountAction, SetCredentialsAction } from '../actions/accountSetup.actions';
import { ResetAppSettings, ShowSideMenu } from '../actions/appSettings.actions';
import { ResetWalletAction, ResetWalletWithAuthAction } from '../actions/wallet.actions';
import { WebSocketAddressNotifierService } from './WebSocketAddressNotifier.service';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AuthenticatePage } from 'src/app/views/authenticate/authenticate.page';
import { getWalletRedirectUri } from '../store/wallet';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { ResetUserDevicesAction } from '../actions/devicesList.actions';
import { ResetSendTokenDataAction } from '../actions/sendToken.actions';
import { ResetConnectionAction } from '../actions/connection.actions';
import { ResetGasStationDataAction } from '../actions/gasStation.actions';
import { ResetPriceOracleDataAction } from '../actions/priceOracle.actions';
import { ResetVirtualDebitCardDataAction } from '../actions/virtualDebitCard.actions';
import { ResetBankAccountDataAction } from '../actions/bankAccount.actions';
import { getJwtToken } from '../store/userPreferences';
import { RoutingService } from './routing.service';
import * as jwt_decode from 'jwt-decode';

export type JWTGroupName = 'GROUP_WALLETCONNECT' | 'GROUP_MOVE_ASSETS' | 'GROUP_ACH';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userAgreement;
  public scrollToBottom = new BehaviorSubject<boolean>(false);
  public recoveryPhraseData = new BehaviorSubject<any>(null);
  private previousUrl: string;
  private authModal: HTMLIonModalElement;
  private walletRedirectUri = null;

  constructor(
    private storage: StorageService,
    private store: Store<AppState>,
    private nav: NavController,
    private router: Router,
    private modalController: ModalController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private routingService: RoutingService
  ) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        this.previousUrl = events[0].urlAfterRedirects;
        Logger.info('previous url', this.previousUrl);
      });
    this.store.select(getWalletRedirectUri).subscribe((walletRedirectUri) => {
      this.walletRedirectUri = walletRedirectUri;
    });
  }

  public setScrollToBottom(scroll) {
    this.scrollToBottom.next(scroll);
  }

  public setUserAgreement(agreement) {
    this.userAgreement = agreement;
  }

  public getUserAgreement() {
    return this.userAgreement;
  }

  async loadUserFromStorage() {
    try {
      let vault = await this.storage.get(WALLET_STORAGE_KEY);
      const oldStorage = await new Storage({}).create();
      const oldVault = await oldStorage.get(WALLET_STORAGE_KEY);
      const skipIntroPage = await this.storage.get(SKIP_INTRO_PAGE_KEY);
      const authData = await this.storage.get(AUTH_DATA_KEY);
      if (!authData) {
        if (skipIntroPage) {
          // back to intro page 3
          await this.nav.navigateRoot('setup/intro3').then(() => this.hideSplashSreen());
        } else {
          await this.nav.navigateRoot('setup/intro').then(() => this.hideSplashSreen());
        }
      } else {
        if (oldVault && !vault) {
          Logger.info('Starting vault migration');
          const userPreferences = await oldStorage.get(USER_PREFERENCES_STORAGE_KEY);
          // Save old vault and user preferences on new DB.
          await this.storage.set(WALLET_STORAGE_KEY, oldVault);
          await this.storage.set(USER_PREFERENCES_STORAGE_KEY, userPreferences);
          // Reload vault
          vault = await this.storage.get(WALLET_STORAGE_KEY);
          Logger.info('Vault migration finished');
        }
        this.hideSplashSreen();
        await this.routingService.goHome();
      }
    } catch (error) {
      Logger.error('loadUserFromStorage', error);
    }
  }

  hideSplashSreen() {
    // need to hide splash-screen before opening pin-modal
    this.platform.ready().then(() => {
      if (this.platform.is('mobile')) {
        Logger.info('Hide splash from auth', this.splashScreen.hide());
      }
    });
  }

  // store pin with user data with AES encryption
  async storePin(currentPin?) {
    const pin = currentPin ? currentPin : await this.store.select(getSetupPin).pipe(take(1)).toPromise();
    const userPref = await this.store.select(getAccountSetupState).pipe(take(1)).toPromise();
    const userDetails = {
      pin,
      credentials: {
        username: userPref.email,
        password: userPref.password
      },
      jwtToken: userPref.token,
      lodeid: userPref.lodeid
    };

    // encrypt using pin and store user data
    const encryptedData = encryptData(pin, userDetails);
    await this.storage.set(AUTH_DATA_KEY, encryptedData);

    this.store.dispatch(new SetCredentialsAction({
      email: '',
      contactNo: {},
      password: '',
      lodeid: null,
      token: null,
      otpCode: '',
      setupLevel: 1,
      isNewUser: true
    }));
    return true;
  }

  // Change pin 
  async changePin(oldPin, newPin) {
    const pin = await this.store.select(getSetupPin).pipe(take(1)).toPromise();
    const userData = await this.storage.get(AUTH_DATA_KEY);

    let username, password, jwtToken, lodeid;
    await decryptData(oldPin, userData).then(async data => {
      username = await data.credentials.username;
      password = await data.credentials.password;
      jwtToken = await data.jwtToken;
      lodeid = await data.lodeid;
    });

    if (newPin === pin && username && password && jwtToken && lodeid) {
      const userAction = {
        newPin,
        credentials: {
          username,
          password
        },
        jwtToken,
        lodeid
      };
      Logger.info(userAction);

      // encrypt using pin and store user data
      const encryptedData = encryptData(newPin, userAction);
      await this.storage.set(AUTH_DATA_KEY, encryptedData);
      return true;
    } else {
      return false;
    }
  }

  async completeSetup() {
    this.store.dispatch(new CompleteSetupAction());
  }

  public async logoutAccount({ resetWallet = false } = {}) {

    // reset wallet if user prompt to accept wallet reset
    if (resetWallet) {
      this.store.dispatch(new ResetWalletAction());
    }

    await this.storage.remove(AUTH_DATA_KEY);
    await this.storage.remove(LODE_ID);
    await this.storage.remove(LNG_KEY);
    await this.storage.remove(SKIP_INTRO_PAGE_KEY);
    await this.storage.remove(USER_PREFERENCES_STORAGE_KEY);
    await this.storage.remove(SKIP_PRIMARY_ADDRESS_MODAL);

    this.store.dispatch(new ResetUserPreferencesAction());
    this.store.dispatch(new ResetUserDevicesAction());
    this.store.dispatch(new ResetSendTokenDataAction());
    this.store.dispatch(new ResetConnectionAction());
    this.store.dispatch(new ResetGasStationDataAction());
    this.store.dispatch(new ResetPriceOracleDataAction());
    this.store.dispatch(new ResetVirtualDebitCardDataAction());
    this.store.dispatch(new ResetUserDevicesAction());
    this.store.dispatch(new ResetAppSettings());
    this.store.dispatch(new ResetBankAccountDataAction());
    this.store.dispatch(new ResetAccountAction());
    this.store.dispatch(new ResetWalletWithAuthAction({
      authenticated: false,
      queryCount: 0,
      preventAuthOnResume: true
    }));
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public async openAuthPinModal(
    reason?: string,
    url?: string,
    queryParams?: any,
  ) {
    if (this.authModal) {
      return;
    }
    this.authModal = await this.modalController.create({
      component: AuthenticatePage,
      cssClass: 'fullscreen-modal',
      componentProps: {
        authReason: reason,
        url: url,
        queryParams: queryParams,
      },
      id: 'authPinModal',
    });
    await this.authModal.present();
    await this.authModal.onDidDismiss().then(async (res) => {
      this.authModal = null;
      if (res.data.returnObject.type == 'root') {
        this.nav.navigateRoot(res.data.returnObject.url,
          {
            queryParams: res.data.returnObject.queryParams,
            skipLocationChange: res.data.returnObject.skipLocationChange,
            replaceUrl: res.data.returnObject.replaceUrl
          });
      } else if (res.data.returnObject.type == 'forward') {

        this.nav.navigateForward(res.data.returnObject.url,
          {
            queryParams: res.data.returnObject.queryParams,
            skipLocationChange: res.data.returnObject.skipLocationChange,
            replaceUrl: res.data.returnObject.replaceUrl,
          });

      } else {
        // return;
      }
    });
  };

  setRecoveryPhrase(phrase: any) {
    this.recoveryPhraseData.next(phrase);
  }

  async dismissModal(id: string) {
    try {
      await this.modalController.dismiss(null, null, id);
    } catch (err) {
    }
  }

  async isAuthGroupAch() {
    const jwtDecode = require('jwt-decode');
    const jwt = await this.store.select(getJwtToken).pipe(take(1)).toPromise();
    const decodedJwt = jwtDecode(jwt);
    return decodedJwt?.groups.includes("GROUP_ACH");
  }

  async returnJwtIfValid() {
    const userPref = await this.storage.get(USER_PREFERENCES_STORAGE_KEY).then((val) => JSON.parse(val));
    if (!userPref || !userPref.jwtToken) {
      return false;
    }
    const jwt = userPref.jwtToken;

    const jwtDecode = require('jwt-decode');
    let decoded;
    try {
      decoded = await jwtDecode(jwt);
      if (Date.now() <= decoded.exp * 1000) {
        return jwt;
      }
    } catch (e) {
      return false;
    }
  }

  /**
   * This method is used with a decorator like so 
   * @AuthService.requireGroup('<GROUP NAME HERE>').
   * 
   * Note the dependency for an instance of the AuthService, if it is not available, 
   * the decorator will not work.
   * 
   * @param group 
   * @returns 
   */
  static requireGroup(group: JWTGroupName): MethodDecorator {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor): PropertyDescriptor {
      const original = descriptor.value;
      descriptor.value = async function (...args: any[]) {
        try {
          let token = await this.authService.returnJwtIfValid();
          if (token) {
            const decodedToken = jwt_decode(token);
            let hasGroup = false;
            for (let userGroup of decodedToken.groups) {
              if (userGroup === group) {
                hasGroup = true;
                break;
              }
            }
            if (hasGroup) {
              return original.apply(this, args);
            }
          }
          throw new Error('Unauthorized');
        } catch (e) {
          throw new Error(e?.message || `Unknown Error ${JSON.stringify(e)}`);
        }
      };
      return descriptor;
    }
  }

  async hasGroup(role: JWTGroupName): Promise<boolean> {
    let hasGroup = false;

    let token = await this.returnJwtIfValid();
    if (!token) return false;

    const decodedToken = jwt_decode(token);
    for (let userGroup of decodedToken.groups) {
      if (userGroup === role) {
        hasGroup = true;
        break;
      }
    }
    return hasGroup;
  }
}
