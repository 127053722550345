import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import { BankAccountService } from 'src/app/angular-wallet-base/services/bank-account.service';
import { StorageService } from 'src/app/angular-wallet-base/services/storage.service';
import { WalletService } from 'src/app/angular-wallet-base/services/wallet.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { PrimeTosComponent } from '../../prime/prime-tos/prime-tos.component';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { getToastPosition, openKycModal, presentAlert } from 'src/app/angular-wallet-base/utils';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';

export type WalletType = 'vault' | 'prime' | 'on-ramper';
@Component({
  selector: 'app-select-wallet-modal',
  templateUrl: './select-wallet-modal.component.html',
  styleUrls: ['./select-wallet-modal.component.scss'],
})
export class SelectWalletModalComponent implements OnInit {
  public isWalletButtons: boolean = false;
  public achLoadingSpinner: boolean;
  public isDesktopMode: boolean;

  constructor(
    private modalController: ModalController,
    private storage: StorageService,
    private bankAccountService: BankAccountService,
    private nav: NavController,
    protected store: Store<AppState>,
    private translate: TranslateService,
    public appMode: AppModeService,
    private alert: AlertController,
    private platform: Platform,
    private toast: ToastController,
    private walletService: WalletService
  ) {
    this.appMode.isDesktopMode.subscribe((mode) => (this.isDesktopMode = mode));
  }

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss();
  }

  async selectWallet(type: WalletType) {
    switch (type) {
      case 'vault':
        this.walletService.setWalletType(type);
        this.modalController.dismiss({ type: 'vault' });
        break;
      case 'prime':
        this.walletService.setWalletType(type);
        this.goToPrime();
        this.modalController.dismiss({ type: 'prime' });
        break;
      case 'on-ramper':
        this.walletService.setWalletType(type);
        this.modalController.dismiss({ type: 'on-ramper' });
        break;
    }
  }
  async goToPrime() {
    const accountExists = await this.bankAccountService.getACHStatus();
    if (accountExists) {
      this.nav.navigateForward('prime-intro');
    }
    this.achLoadingSpinner = false;
    const agreementData: any = await this.bankAccountService.getAchAgreement();
    if (!(agreementData instanceof Array)) {
      // Error is sent in an array so if not an array API success
      const modal = await this.modalController.create({
        component: PrimeTosComponent,
        componentProps: {
          agreementData: agreementData.attributes.content,
        },
        cssClass: 'ach-modal-css',
      });
      await modal.present();
      this.achLoadingSpinner = false;
    } else if (agreementData) {
      const e = agreementData[0];
      if (e.status === 403) {
        const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
        await openKycModal(sideMenuHidden, this.isDesktopMode, this.modalController, 'prime');
        this.achLoadingSpinner = false;
        return;
      } else if (e.status === 422) {
        this.checkPTKycApproval();
      } else if (e.error) {
        Logger.info(e);
        const err = e.error;
        if (err.errors === 'Must be a US Resident to link a bank account.') {
          presentAlert(
            this.alert,
            this.translate,
            null,
            'settings.billing.non_us_account_title',
            'settings.billing.non_us_account_message',
            'btn_back'
          );
        } else this.presentToast('settings.toast_msgs.err_hdr', 'settings.toast_msgs.link_bank_acct_err_msg');
      } else {
        this.presentToast('settings.toast_msgs.err_hdr', 'settings.toast_msgs.link_bank_acct_err_msg');
        this.achLoadingSpinner = false;
      }
    }
  }
  async checkPTKycApproval() {
    try {
      const ptKycDataRes = await this.bankAccountService.getPTKycApproveStatus();
      const ptKycData = JSON.parse(ptKycDataRes.toString());
      Logger.info(ptKycData);
      if (!ptKycData.aml.status || !ptKycData.cip.status) {
        await this.bankAccountService.showKYCStatus(ptKycData);
      } else {
        this.nav.navigateForward('prime-intro');
      }
      this.achLoadingSpinner = false;
    } catch (e) {
      Logger.info(e);
      if (e.status === 422) {
        return this.bankAccountService.showKYCStatus(JSON.parse(e.error));
      }
      this.achLoadingSpinner = false;
    }
  }
  async presentToast(hdr, msg) {
    const toast = await this.toast.create({
      header: await this.translate.get(hdr).toPromise(),
      message: await this.translate.get(msg).toPromise(),
      position: getToastPosition(this.platform),
      duration: 4000,
    });
    await toast.present();
  }
}
