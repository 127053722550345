import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';

@Component({
  selector: 'app-on-ramper',
  templateUrl: './on-ramper.component.html',
  styleUrls: ['./on-ramper.component.scss']
})
export class OnRamperComponent implements OnInit {

  public isDesktopMode: boolean;
  public onRamperSrc;

  constructor(
    private modal: ModalController,
    public appMode: AppModeService,
    private sanitizer: DomSanitizer
  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  ngOnInit() {
    this.onRamperSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://widget.onramper.com?color=266677&apiKey=pk_prod_H0NEZSHvre6SD2xN0jTtlDl8SM5NHE77Is8dEaYDvQw0&supportSell=false&onlyCryptos=USDC,USDC_CCHAIN")
  }

  closeModal() {
    this.modal.dismiss();
  }

}
