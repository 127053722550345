import { createSelector } from '@ngrx/store';
import { getUserPreferencesState } from './appState';

export interface UserPreferencesState {
  readonly baseCurrency?: string;
  readonly isRestricted?: any;
  readonly jwtToken?: string;
  readonly credentials?: string;
  readonly lodeid?: string;
  readonly network?: string;
  readonly baseGwei?: number;
  readonly baseGas?: number;
}

export const getBaseCurrency = createSelector(
  getUserPreferencesState,
  (state: UserPreferencesState) => state.baseCurrency
);

export const getBaseGwei = createSelector(
  getUserPreferencesState,
  (state: UserPreferencesState) => state.baseGwei
);

export const getBaseGas = createSelector(
  getUserPreferencesState,
  (state: UserPreferencesState) => state.baseGas
);

export const getLodeId = createSelector(
  getUserPreferencesState,
  (state: UserPreferencesState) => state.lodeid
);

export const getUserIsRestricted = createSelector(
  getUserPreferencesState,
  (state: UserPreferencesState) => state.isRestricted
);

export const getJwtToken = createSelector(
  getUserPreferencesState,
  (state: UserPreferencesState) => state.jwtToken
);

export const getActiveNetworkByChain = (chain: 'sys' | 'eth') => createSelector(
  getUserPreferencesState,
  (state: UserPreferencesState) => state[chain].network
);


