import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { GasStationService } from '../services/gas-station.service';
import { TransactionSenderService } from '../services/transaction-sender.service';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  SendVirtualDebitCardDataAction,
  VirtualDebitCardActionTypes,
  GetVirtualDebitCardsSuccessAction,
  GetVirtualDebitCardsRequestAction,
  GetVirtualDebitCardsFailureAction
} from '../actions/virtualDebitCard.actions';
import { VirtualDebitCardService } from '../services/virtual-debit-card.service';
import { MembersPortalService } from '../services/members-portal.service';
import { getVirtualCardsAndBalances } from '../utils';
import { environment } from '../../../environments/environment';

@Injectable()
export class VirtualDebitCardEffects {
  DEFAULT_FEERATE = 10;
  MEMOHEADER = Buffer.from([0xff, 0xff, 0xaf, 0xaf, 0xaa, 0xaa]);

  authSendTokens$ = createEffect(() => this.actions$.pipe(
    ofType<SendVirtualDebitCardDataAction>(VirtualDebitCardActionTypes.SEND_VIRTUAL_DEBIT_CARD_DATA),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      Logger.info('effect');
      Logger.info('Starting virtual debit card transaction');

      const virtualDebitCardData = store.virtualDebitCard.newCard;
      try {
        const addressRes: any = await this.virtualDebitCard.getAddress();
        const txid: any = await this.transactionSender.sendAsset(
          Number(virtualDebitCardData.amount),
          virtualDebitCardData.address,
          addressRes.address,
          action.payload.pin,
          environment.AGX_GUID,
          'Virtual card TX'
        );
        const orderData = await this.virtualDebitCard.fulfillOrder({
          txid,
          operationType: virtualDebitCardData.operationType,
          epin: virtualDebitCardData.epin
        });
      } catch (e) {
        let errorMsg = await this.translate.get('virtual_debit_card.error_description').toPromise();
        if (e.error && e.error.message) {
          errorMsg = e.error.message;
        }
        Logger.error('Something went wrong error', e);
        const alert = await this.alertController.create({
          header: await this.translate.get('virtual_debit_card.error_title').toPromise(),
          message: errorMsg,
        });
        await alert.present();
      }

      if (virtualDebitCardData.operationType === 'create') {
        await this.nav.navigateForward(['/wallet/card/signup-complete']);
      } else {
        await this.nav.navigateBack(['/wallet/card']);
      }
    })
  ), { dispatch: false });

  getCards$ = createEffect(() => this.actions$.pipe(
    ofType<GetVirtualDebitCardsRequestAction>(VirtualDebitCardActionTypes.GET_VIRTUAL_DEBIT_CARDS_REQUEST),
    withLatestFrom(this.store),
    tap(async ([action, store]) => {
      Logger.info('effect');
      Logger.info('Starting virtual debit card transaction');
      let cards;

      try {
        cards = await getVirtualCardsAndBalances(this.members, this.virtualDebitCard);
        // cards = []; // enable this line to simulate 'no cards'
      } catch (err) {
        Logger.error('Error while fetching virtual cards', err);
        return this.store.dispatch(new GetVirtualDebitCardsFailureAction());
      }

      this.store.dispatch(new GetVirtualDebitCardsSuccessAction({ cards }));
    })
  ), { dispatch: false });

  async constructVdcTx(amount, fromAddress, toAddress, pin) {
    const syskit = await this.transactionSender.getSignAndSendKit('sys', pin);
    const keyPair = syskit.hdSigner.createKeypair(0, false);
    const parsedAmount = parseFloat(amount);
    const feeRate = new syskit.sjs.utils.BN(this.DEFAULT_FEERATE);
    const txOpts = { rbf: true, memoHeader: this.MEMOHEADER, memo: Buffer.from('Virtual card TX') };
    const assetChangeAddress = fromAddress;
    const assetMap = new Map([
      [
        environment.AGX_GUID,
        {
          changeAddress: assetChangeAddress,
          outputs: [{ value: new syskit.sjs.utils.BN(parsedAmount), address: toAddress }]
        }
      ]
    ]);
    const sysChangeAddress = fromAddress;
    const psbt = await syskit.syscoinjs.assetAllocationSend(txOpts, assetMap, sysChangeAddress, feeRate, fromAddress);

    return psbt.extractTransaction().getId();
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private transactionSender: TransactionSenderService,
    private gasStation: GasStationService,
    private virtualDebitCard: VirtualDebitCardService,
    private nav: NavController,
    private alertController: AlertController,
    private translate: TranslateService,
    private members: MembersPortalService
  ) {
  }

}
