import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';

@Component({
  selector: 'app-reset-wallet',
  templateUrl: './reset-wallet.component.html',
  styleUrls: ['./reset-wallet.component.scss']
})
export class ResetWalletComponent implements OnInit {

  public isDesktopMode: boolean;
  public loadingRemoveAccount: boolean = false;

  constructor(
    public appMode: AppModeService,
    private modal: ModalController,
  ) {
    this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
  }

  ngOnInit() { }

  closeModal() {
    this.modal.dismiss();
  }

  async resetWallet(reset) {
    this.modal.dismiss({ reset });
  }

}
