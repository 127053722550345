import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NetworkService } from './network.service';
import { RpcProvider } from './rpc-provider';
import { BaseChainSymbol, TokenKeyring } from 'src/app/global';
import { BlockbookGetAddressResponse, BlockbookGetAddressTxsResponse } from './blockbook';
import { calculateUnconfBalance, createTokenKeyring } from '../utils';
import BigNumber from 'bignumber.js';
import { SetServiceStatusAction } from '../actions/connection.actions';
import { AppState } from '../store/appState';
import { Store } from '@ngrx/store';
import { TokenManagerService } from './token-manager.service';
import { Logger } from './logger.service';
import { isNil } from '../ts-util';
import { createKeyringId } from '../keyringUtils';
import { environment } from 'src/environments/environment';
import { inspect } from 'util';


export class JsonRpcBody {
  id: string;
  method: string;
  params: any[]
}

@Injectable({ providedIn: 'root' })
export class SysnevmService {

  constructor(
    protected http: HttpClient,
    private networkService: NetworkService,
    private rpcProvider: RpcProvider,
    private store: Store<AppState>,
    private tokenManager: TokenManagerService,
  ) { }


  async getSysnevmRpcUrl() {
    const network = await this.networkService.getActiveSysnevmNetwork();
    return `${network.RPC_URL}`;
  }


  async getSysnevmTokenBalances(address): Promise<TokenKeyring[] | object[]> {
    try {
      const url = await this.getSysnevmRpcUrl();
      const data = await this.rpcProvider.rpc(url, 'eth_getBalance', [address, 'latest']).toPromise()
      const response: BlockbookGetAddressResponse = {};
      response.balance = new BigNumber(data?.result).toString();
      response.address = address;
      const sysnevmKeyring = createTokenKeyring({
        symbol: 'SYSNEVM',
        address: address,
        balance: response.balance,
        baseChainSymbol: 'SYSNEVM',
        guid: undefined,
        ethFilterIndex: undefined,
        precision: 18
      });

      // Add ERC20 Tokens manually
      response.tokens = [];
      const addressErc20wallet = address.substring(2);
      const supportedAssetList = await this.tokenManager.getTokenList();

      let ercContractsList = Object.keys(supportedAssetList).filter((keyringId: string) => keyringId.indexOf('SYSNEVM-') !== -1);
      for (const erc20Contract of ercContractsList) {
        const contract = erc20Contract.replace('SYSNEVM-', '');
        const erc20Obj = {
          balance: 0,
          contract,
          name: supportedAssetList[erc20Contract].name,
          symbol: supportedAssetList[erc20Contract].symbol,
          type: 'ERC20',
          decimals: supportedAssetList[erc20Contract].precision,
        };
        try {
          const balanceErc20 = await this.rpcProvider
          .rpc(url, 'eth_call', [
            {
              to: `${contract}`,
              data: `0x70a08231000000000000000000000000${addressErc20wallet}`,
            },
            'latest',
          ])
          .toPromise();
          erc20Obj.balance = balanceErc20 !== undefined ? new BigNumber(balanceErc20.result).toNumber() : 0;
        } catch (err) {
          Logger.error(`SYSNEVM failed to query token balance ${erc20Contract} - error ${inspect(err)}`);
        }

        response.tokens.push(erc20Obj)
      }

      const keyrings = this.deriveTokenKeyrings(response, 'SYSNEVM');
      this.setServiceStatusInStore('sysnevmRpc', true);
      return [...keyrings, sysnevmKeyring];
    } catch (error) {
      Logger.error('get SYSNEVM token balance error', address, error)
      this.setServiceStatusInStore('sysnevmRpc', false);
      return [{}];
    }
  }

  deriveTokenKeyrings(response: BlockbookGetAddressResponse, baseChain: BaseChainSymbol): TokenKeyring[] {
    const keyrings = [];
    response.tokens.forEach((token) => {
      if (token === null) {
        return;
      }

      let balance = calculateUnconfBalance(token.balance, token.unconfirmedBalance);

      const keyring = createTokenKeyring({
        symbol: token.symbol,
        address: response.address,
        balance: balance,
        baseChainSymbol: baseChain,
        guid: token.contract || token.assetGuid,
        ethFilterIndex: undefined,
        precision: token.decimals
      });
      keyrings.push(keyring);
    });

    return keyrings;
  }

  private setServiceStatusInStore(type, connected) {
    const services: any = {};
    services[type] = connected;
    this.store.dispatch(new SetServiceStatusAction(services));
  }

  async getScannerApi() {
    const network = await this.networkService.getActiveSysnevmNetwork();
    return `${network.ETHERSCAN_URL}`;
  }

  async getSysnevmTokenHistory(address, page, itemsPerPage, ethFilterIndex, tokenContract) {
    // https://explorer.rollux.com/api-docs

    const response: BlockbookGetAddressTxsResponse = {};
    let txs: any[] = [];
    let res: any;
    let scannerAPIUrl = await this.getScannerApi();

    if (!isNil(tokenContract)) {
      res = await this.http
        .get(`${scannerAPIUrl}/api/v2/addresses/${address}/token-transfers?type=ERC-20&filter=to%20%7C%20from&token=${tokenContract}`)
        .toPromise();
    } else {
      res = await this.http
        .get(`${scannerAPIUrl}/api/v2/addresses/${address}/transactions?filter=1%7C20`)
        .toPromise();
    }
    txs = Array.isArray(res.items) ? res.items : [];

    for(let tx of txs) {
      const resp: any = await this.http
        .get(`${scannerAPIUrl}/api/v2/transactions/${tx.tx_hash}`)
        .toPromise()
      tx.fee = resp.fee;
      tx.confirmations = resp.confirmations;
    }

    // Filter the non AVAX tokens
    response.transactions = txs;
    response.page = page;
    return { keyringId: createKeyringId('SYSNEVM', tokenContract), ...response };
  }

}
