import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, getUserPreferencesState } from '../../angular-wallet-base/store/appState';
import { getPageTitle, getPageSecondaryTitle, getShouldUseI18n } from '../../angular-wallet-base/store/appSettings';
import { MembersPortalService } from '../../angular-wallet-base/services/members-portal.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  public pageTitle = '';
  public pageSecondaryTitle = '';
  public shouldUseI18n;
  public lodeData;
  public isLodeLinked;

  constructor(private store: Store<AppState>,
              private membersPortalService: MembersPortalService) { }

  async ngOnInit() {
    this.store.select(getUserPreferencesState).subscribe(this.handleLodeDataLoad.bind(this));
    this.store.select(getPageTitle).subscribe(title => {
      this.pageTitle = title;
    });
    this.store.select(getPageSecondaryTitle).subscribe(secondaryTitle => {
      this.pageSecondaryTitle = secondaryTitle;
    });
    this.store.select(getShouldUseI18n).subscribe(shouldUseI18n => {
      this.shouldUseI18n = shouldUseI18n;
    });

    this.isLodeLinked = await this.membersPortalService.isJwtValid();

    if (this.isLodeLinked) {
      this.getLodeData();
    }
  }

  public async getLodeData() {
    try {
      this.lodeData = await this.membersPortalService.getUserInfo();
      this.isLodeLinked = true;
    } catch (err) {
      this.lodeData = null;
      this.isLodeLinked = false;
    }
  }

  async handleLodeDataLoad() {
    this.getLodeData();
  }

  public generateAvatarUrl() {
    return 'https://avatar.oxro.io/avatar.svg?&background=687984&color=fff&name=' + (this.lodeData.fname ? this.lodeData.fname.replace(/\s/g, '+') : 'Lode');
  }

}
