import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../store/appState';
import { catchError, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Logger } from '../services/logger.service';
import {
  PlaceOrderAction,
  DismissOrderAction,
  FulfillOrderAction,
  Otc3ActionTypes,
  FetchOrdersRequestAction,
  UpdateOrdersAction,
  FetchOrdersStateAction,
  TxPendingStateAction,
  OrderPendingStateAction,
  PlaceOrderFailureAction,
  DismissOrderFailureAction,
  FulfillOrderFailureAction,
  FetchOrdersFailureAction,
  PlaceOrderSuccessAction,
  DismissOrderSuccessAction,
  FulfillOrderSuccessAction,
} from '../actions/otc3.actions';
import { OTC3Service } from '../services/otc3.service';
import { AddressWAVAX } from '../constants';
import { from, of } from 'rxjs';
import { ToastController } from '@ionic/angular';

@Injectable()
export class Otc3Effects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private otc3Service: OTC3Service,
    private toastController: ToastController
  ) {}

  private async presentToast(
    message: string,
    color: 'success' | 'warning' | 'danger' = 'warning',
    duration: number = 3000,
    position: 'top' | 'bottom' | 'middle' = 'top'
  ): Promise<void> {
    const toast = await this.toastController.create({
      message,
      color,
      duration,
      position,
    });
    await toast.present();
  }

  fetchOrderRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchOrdersRequestAction>(Otc3ActionTypes.FETCH_ORDERS),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const { wallet, orderType, tokenIn, tokenOut } = action.payload;
        this.store.dispatch(new FetchOrdersStateAction({ isFetchingOrders: true }));

        const fetchOrders$ =
          orderType === 'user_order'
            ? from(this.otc3Service.getUserOrders(wallet.address))
            : from(this.otc3Service.getMarketOrders(wallet.address, tokenIn, tokenOut));

        return fetchOrders$.pipe(
          map((orders) => {
            this.store.dispatch(new FetchOrdersStateAction({ isFetchingOrders: false }));
            return new UpdateOrdersAction({ orders, orderType });
          }),
          catchError((err) => {
            Logger.error(`Error: fetch order failed ${err}`);
            this.store.dispatch(new FetchOrdersStateAction({ isFetchingOrders: false }));
            return of(new FetchOrdersFailureAction({ error: err }));
          })
        );
      })
    )
  );

  placeOrderInitRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PlaceOrderAction>(Otc3ActionTypes.PLACE_ORDER),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const { wallet, order } = action.payload;
        this.store.dispatch(new TxPendingStateAction({ isTxPending: true }));

        const placeOrder$ =
          order.tokenIn?.assetGuid?.toLowerCase() === AddressWAVAX
            ? from(this.otc3Service.placeAVAXForTokenOrder(wallet, order))
            : from(this.otc3Service.placeOrder(wallet, order));

        return placeOrder$.pipe(
          mergeMap(() => {
            this.store.dispatch(new TxPendingStateAction({ isTxPending: false }));
            return [new PlaceOrderSuccessAction(), new FetchOrdersRequestAction({ wallet, orderType: 'user_order' })];
          }),
          catchError((err) => {
            Logger.error(`Error: place order failed ${err}`);
            this.store.dispatch(new TxPendingStateAction({ isTxPending: false }));
            return of(new PlaceOrderFailureAction({ error: err }));
          })
        );
      })
    )
  );

  dismissOrderInitRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DismissOrderAction>(Otc3ActionTypes.DISMISS_ORDER),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const { wallet, orderIndex } = action.payload;
        this.store.dispatch(new OrderPendingStateAction({ pendingOrderId: orderIndex }));

        return from(this.otc3Service.dismissOrder(wallet, orderIndex)).pipe(
          mergeMap(() => {
            this.store.dispatch(new OrderPendingStateAction({ pendingOrderId: -1 }));
            return [new DismissOrderSuccessAction(), new FetchOrdersRequestAction({ wallet, orderType: 'user_order' })];
          }),
          catchError((err) => {
            Logger.error(`Error: dismiss order failed ${err}`);
            this.store.dispatch(new OrderPendingStateAction({ pendingOrderId: -1 }));
            return of(new DismissOrderFailureAction({ error: err }));
          })
        );
      })
    )
  );

  fulfillOrderInitRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FulfillOrderAction>(Otc3ActionTypes.FULFILL_ORDER),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const { wallet, order } = action.payload;
        const orderIdx = +order.orderIdx;
        this.store.dispatch(new OrderPendingStateAction({ pendingOrderId: orderIdx }));

        const fulfillOrder$ =
          order.tokenOut.assetGuid === AddressWAVAX
            ? from(this.otc3Service.fulfillTokenForAVAXOrder(wallet, orderIdx, order.amountOut))
            : from(this.otc3Service.fulfillOrder(wallet, orderIdx));

        return fulfillOrder$.pipe(
          mergeMap(() => {
            this.store.dispatch(new OrderPendingStateAction({ pendingOrderId: -1 }));
            return [new FulfillOrderSuccessAction(), new FetchOrdersRequestAction({ wallet, orderType: 'market_order' })];
          }),
          catchError((err) => {
            Logger.error(`Error: fulfill order failed ${err}`);
            this.store.dispatch(new OrderPendingStateAction({ pendingOrderId: -1 }));
            return of(new FulfillOrderFailureAction({ error: err }));
          })
        );
      })
    )
  );

  // order failure effects
  fetchOrdersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FetchOrdersFailureAction>(Otc3ActionTypes.FETCH_ORDERS_FAILURE),
        mergeMap(async ({ payload }) => {
          this.presentToast(
            `An error occurred during orders fetch: insufficient token balance or gas fee for transaction`,
            'warning'
          );
        })
      ),
    { dispatch: false }
  );

  dismissOrderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DismissOrderFailureAction>(Otc3ActionTypes.DISMISS_ORDER_FAILURE),
        mergeMap(async ({ payload }) => {
          this.presentToast(
            `An error occurred during order dismissal: insufficient token balance or gas fee for transaction`,
            'warning'
          );
        })
      ),
    { dispatch: false }
  );

  dismissOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DismissOrderSuccessAction>(Otc3ActionTypes.DISMISS_ORDER_SUCCESS),
        mergeMap(async () => {
          this.presentToast(`Success on order dismissal!`, 'success');
        })
      ),
    { dispatch: false }
  );

  placeOrderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PlaceOrderFailureAction>(Otc3ActionTypes.PLACE_ORDER_FAILURE),
        mergeMap(async ({ payload }) => {
          this.presentToast(
            `An error occurred during order placement: insufficient token balance or gas fee for transaction`,
            'warning'
          );
        })
      ),
    { dispatch: false }
  );

  placeOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PlaceOrderSuccessAction>(Otc3ActionTypes.PLACE_ORDER_SUCCESS),
        mergeMap(async () => {
          this.presentToast(`Success on order placement!`, 'success');
        })
      ),
    { dispatch: false }
  );

  fulfillOrderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FulfillOrderFailureAction>(Otc3ActionTypes.FULFILL_ORDER_FAILURE),
        mergeMap(async ({ payload }) => {
          this.presentToast(
            `An error occurred during order fulfillment: insufficient token balance or gas fee for transaction`,
            'warning'
          );
        })
      ),
    { dispatch: false }
  );

  fulfillOrderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FulfillOrderSuccessAction>(Otc3ActionTypes.FULFILL_ORDER_SUCCESS),
        mergeMap(async () => {
          this.presentToast(`Success on order fulfillment!`, 'success');
        })
      ),
    { dispatch: false }
  );
}
