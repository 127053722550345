import { Action } from "@ngrx/store";
import { IPeerMeta } from "../lib/wallet-connect/helpers/types";
import { IWalletConnectSelectedNetwork, WalletConnectState } from "../store/walletConnect";
import Connector from "@walletconnect/core";
import { WalletConnectSession } from "../services/wallet-connect.service";
import { SignatureResult } from "../../angular-wallet-base/lib/wallet-connect/helpers/types";
import { IWalletConnectSession } from "@walletconnect/types";
import { IWeb3Wallet } from "@walletconnect/web3wallet";
/**
 * Note the usage of Connector type here for the expectation of Connector property.  
 * Connector actually exposes a session getter function which returns the object of 
 * schema IWalletConnectSession.
 * 
 * We could in the future add an additional property specific to this session data to
 * make things cleaner but as it stands the full connector instance must be passed around
 * as certain lifecycle hooks and triggers inside of it are leveraged.
 */
export enum WalletConnectActionTypes {
  // initiate the wallet connect web3wallet when loading page
  WALLETCONNECT_STATE_INIT_REQUEST = "[walletconnect] Init Web3Wallet Wallet Connect Instance  for wallet connect",
  WALLETCONNECT_STATE_INIT_SUCCESS = "[walletconnect] Init Web3Wallet Instance successfully",
  WALLETCONNECT_STATE_INIT_FAILURE = "[walletconnect] Init Web3Wallet Instance failure",

  WALLETCONNECT_CHAIN_REQUEST = "[walletconnect] Request update the chain",

  WALLETCONNECT_SESSION_PROPOSAL_PAIR_REQUEST = "[walletconnect] Create a session proposal to pair a new DApp",
  WALLETCONNECT_SESSION_PROPOSAL_PAIR_SUCCESS = "[walletconnect] Success to pair a session proposal of a new DApp",
  

  WALLETCONNECT_SESSION_APPROVE = "[walletconnect] User approve a session of a new DApp",
  WALLETCONNECT_SESSION_REJECT = "[walletconnect] User reject a session of a new DApp",

  WALLETCONNECT_EVENT_REQUEST_CANCELLED_SUCCESS = "[walletconnect] User cancelled an event request from the DApp",
  WALLETCONNECT_EVENT_REQUEST_SIGN_AUTH_REQUEST = "[walletconnect] User enter a wallet pin to auth and sign an event request",
  WALLETCONNECT_EVENT_REQUEST_SIGN_AUTH_SUCCESS = "[walletconnect] User enter a wallet pin success and start to sign an event request",
  WALLETCONNECT_EVENT_REQUEST_SIGN_SUCCESS = "[walletconnect] the wallet publish the signed event request to wallet connect relayer successfully",

  WALLETCONNECT_SESSION_DISCONNECT_REQUEST = "[walletconnect] User disconnect a session from a wallet",
  WALLETCONNECT_SESSION_DISCONNECT_SUCCESS = "[walletconnect] The Wallet disconnect a session successfully",
  WALLETCONNECT_SESSION_DISCONNECTED_REQUEST = "[walletconnect] The wallet received a disconnect session request from Dapp",

  WALLETCONNECT_EVENT_SESSION_REQUEST = "[walletconnect] Receive a new session event request DApp",
  WALLETCONNECT_EVENT_SESSION_REQUEST_AUTH_APPROVE = "[walletconnect] Approve and Sign a new session request event",
  WALLETCONNECT_EVENT_SESSION_REQUEST_REJECT = "[walletconnect] Reject a new session request event",

  WALLETCONNECT_ERROR_ACTION = "[walletconnect] Error in the Wallet Connect action",


  WALLETCONNECT_SET_CHAIN_REQUEST = "[walletconnect] Request to set the chain",
  WALLETCONNECT_SET_CHAIN_REQUEST_SUCCESS = "[walletconnect] Success to request to set the chain",
  WALLETCONNECT_SET_CHAIN_REQUEST_FAILURE = "[walletconnect] Fail to Request to set the chain",


  WALLETCONNECT_SET_SUPPORTED_NAMESPACE_REQUEST = "[walletconnect] Request to set supported namespace",

 
  WALLETCONNECT_SINGULARITY_SESSION_PROPOSAL_REQUEST = "[wallet connect] Request to from Singularity to create a new session proposal",
  WALLETCONNECT_SINGULARITY_SESSION_PROPOSAL_REQUEST_APPROVAL = "[wallet connect] Auto approve Singularity session request",


  SET_BASE_CURRENCY = "[walletconnect] Set blockchain network",
  SET_CONNECTED_ADDRESS = "[walletconnect] Set connected address",
  WALLETCONNECT_SESSION_REQUEST = "[walletconnect] Set connect session request",
  WALLETCONNECT_INIT_REQUEST = "[walletconnect] Set init request",

  WALLETCONNECT_CALL_REJECTION = "[walletconnect] Set call rejection",
  WALLETCONNECT_CALL_APPROVAL = "[walletconnect] Set call approval",
  WALLETCONNECT_CALL_REQUEST = "[walletconnect] Set call request",
  WALLETCONNECT_SESSION_APPROVAL = "[walletconnect] Set session approval",
  WALLETCONNECT_SESSION_REJECTION = "[walletconnect] Set session rejection",
  WALLETCONNECT_SESSION_DISCONNECT = "[walletconnect] Set session disconnect",
  WALLETCONNECT_SESSION_DISCONNECTED = "[walletconnect] Set session disconnected",
  WALLETCONNECT_SESSION_UPDATE = "[walletconnect] Set session update",
  WALLETCONNECT_SET_PAYLOAD = "[walletconnect] Set payload",

}

export class WalletConnectStateInitRequestAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_STATE_INIT_REQUEST;
  constructor() {}
}

export class WalletConnectStateInitSuccessAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_STATE_INIT_SUCCESS;
  constructor(readonly payload: {web3Wallet: IWeb3Wallet}) {}
}

export class WalletConnectStateInitFailureAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_STATE_INIT_FAILURE;
  constructor(readonly payload: string) {}
}

export class WalletConnectSessionProposalPairRequestAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_PROPOSAL_PAIR_REQUEST;
  constructor(readonly payload: { uri: string }) {}
}

export class WalletConnectSessionProposalPairSuccessAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_PROPOSAL_PAIR_SUCCESS;
  constructor(readonly payload: { web3Wallet: IWeb3Wallet }) {}
}

export class WalletConnectSessionDisconnectRequestAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_DISCONNECT_REQUEST;
  constructor(readonly payload: {sessionTopic: string}) {};
}

export class WalletConnectSessionDisconnectSuccessAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_DISCONNECT_SUCCESS;
  constructor(readonly payload: { web3Wallet: IWeb3Wallet, sessionTopic: string}) {};
}


export class WalletConnectSessionDisconnectedRequestFromDAppAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_DISCONNECTED_REQUEST;
  constructor(readonly payload: {web3Wallet: IWeb3Wallet, sessionTopic: string}) {};
}


export class WalletConnectErrorAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_ERROR_ACTION;
  constructor(readonly payload: string) {}
}

export class WalletConnectSessionApproveAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_APPROVE;
  constructor(readonly payload: {web3Wallet: IWeb3Wallet, session: IWalletConnectSession, eventRequests: any[]}){};
}


export class WalletConnectSessionRejectAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_REJECT;
  constructor(readonly payload: {web3Wallet: IWeb3Wallet, message: string}){};
}


export class WalletConnectEventRequestSignAuthRequestAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_EVENT_REQUEST_SIGN_AUTH_REQUEST;
  constructor(readonly payload: {eventRequest: any, requestSession: any, networkFee: string}){};
}

export class WalletConnectEventRequestSignAuthSuccessAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_EVENT_REQUEST_SIGN_AUTH_SUCCESS;
  constructor(readonly payload: {pin: string}){};
}

export class WalletConnectEventRequestSignSuccessAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_EVENT_REQUEST_SIGN_SUCCESS;
  constructor(readonly payload: {web3Wallet: IWeb3Wallet, signedEventRequest: any}){};
}


export class WalletConnectEventRequestCancelledSuccessAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_EVENT_REQUEST_CANCELLED_SUCCESS;
  constructor(readonly payload: {web3Wallet: IWeb3Wallet, eventRequestId: string}){};
}

export class WalletConnectEventSessionRequestAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_EVENT_SESSION_REQUEST;
  constructor(readonly payload: {eventRequest: any}){};
}


export class WalletConnectSetChainRequestAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SET_CHAIN_REQUEST;
  constructor(readonly payload: {baseChainSymbol: string}) {}
}


export class WalletConnectSetChainRequestSuccessAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SET_CHAIN_REQUEST_SUCCESS;
  constructor(readonly payload: {walletConnectSelectedNetwork: IWalletConnectSelectedNetwork}) {}
}


export class WalletConnectSetChainRequestFailureAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SET_CHAIN_REQUEST_FAILURE;
  constructor(readonly payload: string) {}
}


export class WalletConnectSetSupportedNameSpaceAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SET_SUPPORTED_NAMESPACE_REQUEST;
  constructor(readonly payload: {supportedNameSpaces: Map<number,Record<string, any>>}) {};
}

export class WalletConnectSessionProposalSingularityPairRequestAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SINGULARITY_SESSION_PROPOSAL_REQUEST;
  constructor() {};
}


export class WalletConnectSingularitySessionApproveAction implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SINGULARITY_SESSION_PROPOSAL_REQUEST_APPROVAL;
  constructor(readonly payload: {web3Wallet: IWeb3Wallet, singularitySession: IWalletConnectSession, eventRequests: any[]}) {}
}








export class SetWalletConnectNetworkAction implements Action {
  readonly type = WalletConnectActionTypes.SET_BASE_CURRENCY;
  constructor(readonly payload: { walletConnectSelectedNetwork: IWalletConnectSelectedNetwork }) {}
}

export class SetWalletConnectSetPayload implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SET_PAYLOAD;
  constructor(readonly payload: { payload: any }) {}
}

export class SetWalletConnectConnectedAddressAction implements Action {
  readonly type = WalletConnectActionTypes.SET_CONNECTED_ADDRESS;
  constructor(readonly payload: { walletConnectConnectedAddress: string }) {}
}

export class WalletConnectSessionDisconnect implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_DISCONNECT;
  constructor() {}
}
export class WalletConnectSessionDisconnected implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_DISCONNECTED;
  constructor(public payload: { initialState: WalletConnectState }) {}
}

export class WalletConnectSessionRequest implements Action {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_REQUEST;
  constructor(readonly payload: { connector: Connector; payload: any; peerMeta: IPeerMeta }) {}
}



export class WalletConnectCallRejection {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_CALL_REJECTION;
  constructor(readonly payload: { connector: Connector, requestId?: string, error: any}) {}
}

export class WalletConnectCallApproval {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_CALL_APPROVAL;
  constructor(readonly payload: { connector: Connector, result: SignatureResult, request?: any}) {}
}

export class WalletConnectCallRequest {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_CALL_REQUEST;
  constructor(readonly payload: { connector: Connector, request: any }) {}
}

export class WalletConnectSessionApproval {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_APPROVAL;
  constructor(readonly payload: { session: WalletConnectSession }) {}
}

export class WalletConnectSessionUpdate {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_UPDATE;
  constructor(readonly payload: { session: WalletConnectSession }) {}
}

export class WalletConnectSessionRejection {
  readonly type = WalletConnectActionTypes.WALLETCONNECT_SESSION_REJECTION;
  constructor(readonly payload: { connector: Connector }) {}
}
