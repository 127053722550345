import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { AuthService } from 'src/app/angular-wallet-base/services/auth.service';
import { MicroRewardService } from 'src/app/angular-wallet-base/services/micro-reward.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { SelectReceivingAddressComponent } from '../select-receiving-address/select-receiving-address.component';
import { SignatureRequestComponent } from '../signature-request/signature-request.component';

@Component({
    selector: 'app-confirm-address-modal',
    templateUrl: './confirm-address-modal.html',
    styleUrls: ['./confirm-address-modal.scss']
})
export class ConfirmAddressComponent implements OnInit {

    public isDesktopMode;
    public selectedAddress: any;
    public allTokens: any;
    constructor(
        private modalController: ModalController,
        public appMode: AppModeService,
        protected store: Store<AppState>,
        private authService: AuthService,
        private microRewardService: MicroRewardService,

    ) {
        this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode);
    }

    async ngOnInit() {
        await this.authService.dismissModal('selectAddressModal');
    }
    closeModal() {
        this.modalController.dismiss();
    }

}
