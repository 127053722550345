import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonInput, MenuController, ModalController, NavParams, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { SetSendTokenDataAction } from 'src/app/angular-wallet-base/actions/sendToken.actions';
import { PreventAuthOnResumeAction } from 'src/app/angular-wallet-base/actions/wallet.actions';
import {
  ASSET_ALLOCATION_FEE,
  DEFAULT_GAS_LIMIT_TO_SEND,
  DEFAULT_GWEI_TO_SEND,
  GAS_LIMIT_ERC20_SEND,
  GAS_LIMIT_SEND,
  MAX_GAS_LIMIT_TO_SEND,
  MAX_GWEI_TO_SEND,
  MIN_BTC_TO_SEND
} from 'src/app/angular-wallet-base/constants';
import { SmallToCryptoPipe } from 'src/app/angular-wallet-base/pipes/smalltocrypto/smalltocrypto.pipe';
import { AppModeService } from 'src/app/angular-wallet-base/services/app-mode.service';
import { Logger } from 'src/app/angular-wallet-base/services/logger.service';
import { MembersPortalService } from 'src/app/angular-wallet-base/services/members-portal.service';
import { NetworkService } from 'src/app/angular-wallet-base/services/network.service';
import { TransactionSenderService } from 'src/app/angular-wallet-base/services/transaction-sender.service';
import { getSideMenuStatus } from 'src/app/angular-wallet-base/store/appSettings';
import { AppState } from 'src/app/angular-wallet-base/store/appState';
import { getBaseCurrency, getBaseGas, getBaseGwei } from 'src/app/angular-wallet-base/store/userPreferences';
import { getTokenKeyring } from 'src/app/angular-wallet-base/store/wallet';
import { SubscriptionListenerComponent } from 'src/app/angular-wallet-base/SubscriptionListenerComponent';
import {
  getCoinNameBySymbol,
  getObjectValueByKey,
  isEnterKey,
  matchAfterDecimal,
  matchBeforeDecimal,
  matchValidDecimal,
  openKycModal,
  round,
  satoshiToNum,
  shortHandAddress,
  showFormErrors,
  weiToNum
} from 'src/app/angular-wallet-base/utils';
import { Bip21Data, SendTokenData, TokenInfo } from 'src/app/global';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { GasInfoModalComponent } from './gas-info-modal/gas-info-modal.component';
import { PurchaseGasModalComponent } from './purchase-gas-model/purchase-gas-model.component';
import CustomValidators from '../../angular-wallet-base/CustomValidators';
import { QrDataServiceService } from 'src/app/angular-wallet-base/services/qr-data-service.service';
import { getSendToken } from 'src/app/angular-wallet-base/store/sendToken';
import * as bip21 from 'bip21';
import { ethers } from 'ethers';
const bitcoin = (window as any).bitcoinjsLib;
import * as solanaWeb3 from '@solana/web3.js';
import networks from '../../angular-wallet-base/lib/pangolin/config/network.config';
import { getServiceStatus } from 'src/app/angular-wallet-base/store/connection';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { ComplianceService } from '../../angular-wallet-base/services/compliance.service';
import { EvmNetworkService } from 'src/app/angular-wallet-base/services/evm-network.service';
import { ScannerComponent } from "src/app/components/browser-scanner/scanner/scanner.component";

@Component({
  selector: 'send-asset-modal',
  templateUrl: './send-asset-modal.component.html',
  styleUrls: ['./send-asset-modal.component.scss'],
})
// tslint:disable-next-line: component-class-suffix
export class SendAssetModal extends SubscriptionListenerComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  @ViewChild('battery', { read: ElementRef, static: false }) battery: ElementRef;

  public baseToken = null;
  public guid;
  public tokenInfo;
  public currentBaseCurrency: string;
  public sendAgainAddress: string;
  @Input() toAddress: string;
  @Input() toTempAddress: string;
  @Input() fiatCurrencySymbol: string;
  @Input() mode: string = 'mobile';
  @ViewChild('amountInput') amountInput: IonInput;
  @ViewChild('addressInput', { static: false }) addressInput: IonInput;

  public isDesktopMode;
  public qrText;
  public showQrCodeReader = false;
  public formErrors = {
    amount: '',
    toAddress: '',
    customGasPriceGwei: '',
    customGasLimit: ''
  };
  public validationMessages;
  inputValue: any;
  public sendForm: FormGroup;
  public noSys: boolean;
  public cantEmptyBtcWallet: boolean;
  public isLodeLinked: boolean;
  public notEnoughTxs = false;
  public gasFeeData;
  public selectedFeeTier = 'standard';
  public showMemoInputBox = false;
  public showAdvancedGasOptions = false;
  public showMultilineAddress = false;
  private btcBalance;
  public maxGweiToSend: number = MAX_GWEI_TO_SEND;
  public maxGasToSend: number = MAX_GAS_LIMIT_TO_SEND;
  public amountInCurrency = '0.00';
  public currentTxNumber;
  public sysBalance;
  public gasStationStatus;
  public gasSpeed: string = 'regular';
  public deliverySpeed: string = 'regular';
  public networkFee;
  public networkFeeWithCurrency;
  public kycData;
  public showLogoOnHeader: boolean = false;
  public isMaxClicked: boolean = false;
  private baseTokenKeyring;
  public isKeyBoardOpen = false;

  constructor(
    private smallToCryptoPipe: SmallToCryptoPipe,
    private qrData: QrDataServiceService,
    private transactionSender: TransactionSenderService,
    private formBuilder: FormBuilder,
    private membersPortalService: MembersPortalService,
    private translate: TranslateService,
    private networkService: NetworkService,
    private appMode: AppModeService,
    protected store: Store<AppState>,
    private navParams: NavParams,
    private menu: MenuController,
    private modalController: ModalController,
    private platform: Platform,
    private keyboard: Keyboard,
    private complianceService: ComplianceService,
    private evmNetworkService: EvmNetworkService
  ) {
    super(store);
    this.subscriptions.add(this.appMode.isDesktopMode.subscribe(mode => this.isDesktopMode = mode));
    this.platform.ready().then(() => {
      if (this.platform.height() < 750) {
        this.showLogoOnHeader = true;
      }
      if (this.platform.is('cordova')) {
        this.keyboard.onKeyboardWillShow().subscribe(ev => {
          this.isKeyBoardOpen = true;
        });
        this.keyboard.onKeyboardDidHide().subscribe(ev => {
          this.isKeyBoardOpen = false;
        });
      }
    });
    this.keyboard.disableScroll(true);
  }

  async ngOnInit() {
    this.baseToken = await this.navParams.get('baseToken');
    this.baseTokenKeyring = await this.store.select(getTokenKeyring, { keyringId: this.baseToken }).pipe(take(1)).toPromise();
    this.guid = await this.navParams.get('guid');
    this.tokenInfo = await this.navParams.get('tokenInfo');
    await this.buildForm();
    if (this.sendAgainAddress) {
      let tempAddress = shortHandAddress(this.sendAgainAddress);
      this.sendForm.patchValue({
        toAddress: this.sendAgainAddress,
        toTempAddress: tempAddress
      });
    }

    await this.initGasFeeData();

    this.isLodeLinked = await this.membersPortalService.isJwtValid();
    this.kycData = await this.complianceService.getKycCip();
    this.currentBaseCurrency = await this.store.select(getBaseCurrency).pipe(take(1)).toPromise();

    // TODO: check how gas stations are enabled : currently its false
    this.subscriptions.add(this.store.select(getServiceStatus).subscribe(statuses => {
      this.gasStationStatus = statuses.gasStation;
    }));


    if (this.tokenInfo.baseChainSymbol === 'SYS') {
      await this.initDateForSysChain();
    }

    if (this.tokenInfo.baseChainSymbol === 'BTC') {
      this.initDateForBtcChain();
    }

    await this.AddErrorMessages();
    this.subscriptions.add(this.sendForm.valueChanges.subscribe(data => this.onValueChanged(data)));
    this.qrData.changeMessage('');

    this.subscriptions.add(this.qrData.currentMessage.subscribe(async data => {
      const sendTokenInfo: any = await this.store.select(getSendToken).pipe(take(1)).toPromise();
      data = data.trim();
      Logger.info('QR Code data:', data);
      this.store.dispatch(new SetSendTokenDataAction({
        fromAddress: '',
        toAddress: '',
        tokenInfo: this.tokenInfo,
        amount: 0,
        ethGasFee: this.getCurrentGasPrice().toString(),
        memo: '',
        networkFee: this.getCurrentGasPrice().toString()
      }));
      if (data) {
        try {
          const bip21Data: Bip21Data = bip21.decode(data, getCoinNameBySymbol(this.tokenInfo.baseChainSymbol));
          this.sendForm.patchValue({
            toAddress: bip21Data.address,
            amount: bip21Data.options.amount || sendTokenInfo.amount || 0,
            memo: bip21Data.options.label || sendTokenInfo.memo || ''
          });
        } catch (e) {
          Logger.error('Invalid BIP21 QR Code Data');

          // if QR data is invalid just stick it in address
          this.sendForm.patchValue({
            toAddress: data,
            amount: sendTokenInfo.amount,
            memo: sendTokenInfo.memo
          });
        }
        this.validateAddress();
        this.onValueChanged(null);
      }
    }));

    this.updateMaxAmountValidator(this.tokenInfo);

    if (this.tokenInfo.baseChainSymbol === 'ETH') {
      this.initDataForEthChain()
    }

    if (this.tokenInfo.baseChainSymbol === 'AVAX') {
      this.initDataForAvaxChain();
    }
  }

  private initDateForBtcChain() {
    this.subscriptions.add(this.store.select(getTokenKeyring, { keyringId: 'BTC' }).subscribe(keyring => {
      const { balance } = keyring;
      this.btcBalance = balance;
    }));
  }

  private async initDateForSysChain() {
    const sysInfo = await this.store.select(getTokenKeyring, { keyringId: 'SYS' }).pipe(take(1)).toPromise();
    if (this.tokenInfo.baseChainSymbol === 'SYS' && this.tokenInfo.symbol !== 'SYS') {
      if (this.tokenInfo.balance <= sysInfo.feePerAssetTx) {
        this.notEnoughTxs = true;
      }
    }
    this.subscriptions.add(this.store.select(getTokenKeyring, { keyringId: 'SYS' }).subscribe(async keyring => {
      const { balance } = keyring;
      const sjsKit = await this.transactionSender.getSignAndSendKit(this.tokenInfo.baseChainSymbol);
      const estimatedFee = Number(await sjsKit.sjs.utils.fetchEstimateFee(sjsKit.backendURL, 6));
      Logger.info(`estimatedFee ${estimatedFee}`);
      const sysBalanceSmallCrypto = this.smallToCryptoPipe.transform(balance, { type: this.tokenInfo.baseChainSymbol });
      if (sysBalanceSmallCrypto <= (sysInfo.feePerAssetTx + ASSET_ALLOCATION_FEE) && this.tokenInfo.baseChainSymbol === 'SYS') {
        this.noSys = true;
        return;
      }
      if (estimatedFee >= balance) {
        this.noSys = true;
        return;
      }
      this.noSys = false;

      // calculate total transactions
      const sysTxRate = keyring.feePerAssetTx + ASSET_ALLOCATION_FEE;
      this.currentTxNumber = Math.floor(sysBalanceSmallCrypto / sysTxRate);


      this.sysBalance = Number(balance);
      Logger.info(`currentTxNumber ${this.currentTxNumber}`);
      this.renderSysTransactionGuage(this.currentTxNumber);
    }));
  }

  private initDataForEthChain() {
    this.subscriptions.add(this.store.select(getBaseGwei).subscribe(async (baseGwei) => {
      if (baseGwei != null) {
        this.maxGweiToSend = baseGwei;
        this.validationMessages.customGasPriceGwei.maxGwei = await this.translate.get(
          'tokens.send.errors.eth_max_gwei',
          { maxGwei: this.maxGweiToSend }
        ).toPromise();
      }
    }));
    this.subscriptions.add(this.store.select(getBaseGas).subscribe(async (baseGas) => {
      if (baseGas != null) {
        this.maxGasToSend = baseGas;
        this.validationMessages.customGasLimit.maxGasLimit = await this.translate.get(
          'tokens.send.errors.eth_max_gas_limit',
          { maxGas: this.maxGasToSend }
        ).toPromise();
      }
    }));
  }

  private initDataForAvaxChain() {
    this.subscriptions.add(this.store.select(getBaseGwei).subscribe(async (baseGwei) => {
      if (baseGwei != null) {
        this.maxGweiToSend = baseGwei;
        this.validationMessages.customGasPriceGwei.maxGwei = await this.translate.get(
          'tokens.send.errors.eth_max_gwei', { maxGwei: this.maxGweiToSend }
        ).toPromise();
      }
    }));
    this.subscriptions.add(this.store.select(getBaseGas).subscribe(async (baseGas) => {
      if (baseGas != null) {
        this.maxGasToSend = baseGas;
        this.validationMessages.customGasLimit.maxGasLimit = await this.translate.get(
          'tokens.send.errors.eth_max_gas_limit', { maxGas: this.maxGasToSend }
        ).toPromise();
      }
    }));
  }

  private async initGasFeeData() {
    this.selectedFeeTier = this.isErc20() ? this.selectedFeeTier + 'Erc20' : this.selectedFeeTier;
    if (this.tokenInfo.baseChainSymbol === 'ETH' || this.tokenInfo.baseChainSymbol === 'AVAX' || this.tokenInfo.baseChainSymbol === 'SYSNEVM') {
      this.gasFeeData = await this.evmNetworkService.getEVMGasPrice(this.tokenInfo.baseChainSymbol);
      this.changeSelectedFeeTier(this.selectedFeeTier);
    } else if (this.tokenInfo.baseChainSymbol === "BTC") {
      this.gasFeeData = await this.evmNetworkService.getBTCFeeEstimate();
      this.changeSelectedBtcFeeTier(this.selectedFeeTier);
    }
  }

  private async buildForm() {
    this.sendForm = this.formBuilder.group({
      toAddress: ['', [
        Validators.required,
        CustomValidators.sendToSelf({ fromAddress: this.tokenInfo.address })
      ]],
      toTempAddress: [''],
      amount: ['', [Validators.required, Validators.pattern('^[0-9]+\.[0-9]+$')]],
      fiatAmount: [''],
      memo: [''],
      ethGasFee: [''],
      customGasPriceGwei: [DEFAULT_GWEI_TO_SEND],
      customGasLimit: [DEFAULT_GAS_LIMIT_TO_SEND],
      networkFee: [''],
    });
  }

  async ngAfterViewInit() {
    await this.menu.enable(true, 'sideMenu');
  }

  async ionViewDidEnter() {
    // MOBILE: ngAfterViewInit and ngOnInit appear to not fire after authenticate so we use ionViewDidEnter here
    await this.menu.enable(true, 'sideMenu');
  }

  getSelectedFeeTier() {
    return this.selectedFeeTier;
  }
  private async AddErrorMessages() {
    // init validation messages
    const {
      address_required,
      amount_required,
      no_legacy_addresses,
      no_send_to_self
    } = await this.translate.get('tokens.send.errors').toPromise();
    this.validationMessages = {
      toAddress: {
        invalid: address_required,
        no_legacy_addresses,
        no_send_to_self
      },
      amount: {
        required: amount_required,
        max: '',
        minAmountRequired: await this.translate.get('tokens.send.errors.min_amount_required').toPromise(),
        maxDigitsBeforeDecimal: await this.translate.get('tokens.send.errors.exceeds_digits_before_decimal', { maxDigits: 10 }).toPromise(),
        maxDigitsAfterDecimal: await this.translate.get('tokens.send.errors.exceeds_digits_after_decimal', { maxDigits: 8 }).toPromise(),
        invalidInput: await this.translate.get('tokens.send.errors.invalid_input').toPromise(),
      },
      customGasPriceGwei: {
        maxGwei: await this.translate.get('tokens.send.errors.eth_max_gwei', { maxGwei: this.maxGweiToSend }).toPromise(),
      },
      customGasLimit: {
        maxGasLimit: await this.translate.get('tokens.send.errors.eth_max_gas_limit', { maxGas: this.maxGasToSend }).toPromise(),
      },
    };
  }

  renderSysTransactionGuage(transactions: number) {
    Logger.info(` this.battery ${ this.battery}`);
    // return to solve elementRef error
    let gauageStyle;
    this.battery.nativeElement.style.border = `1px solid var(--ion-color-danger)`;

    if (transactions <= 10) {
      gauageStyle = { border: 'var(--ion-color-danger)', block: transactions > 0 ? 1 : 0 };
    } else if (transactions >= 70) {
      gauageStyle = { border: 'var(--ion-color-success)', block: transactions >= 100 ? 7 : 6 };
    } else {
      gauageStyle = { border: 'var(--ion-color-warning)', block: Math.round(0.07 * transactions) };
    }
    this.battery.nativeElement.style.border = `1px solid ${gauageStyle.border}`;

    for (let i = 0; i <= 6; i++) {
      if (i >= (7 - gauageStyle.block)) {
        this.battery.nativeElement.children[i].style.background = `${gauageStyle.border}`;
      } else {
        this.battery.nativeElement.children[i].style.background = 'var(--ion-color-primary)';
      }
    }
  }

  ngOnChanges(changes) {
    if (changes.tokenInfo && changes.tokenInfo.currentValue !== this.tokenInfo) {
      this.updateMaxAmountValidator(changes.tokenInfo);
    }
  }

  onValueChanged(data) {
    if (!this.sendForm) {
      return;
    }
    this.cantEmptyBtcWallet = false; // reset state before validations

    // Having these checks on change seem to be more reliable than onblur
    if (data && data.amount != null) {
      if (!matchValidDecimal(data.amount)) {
        this.sendForm.controls[`amount`].setErrors({ invalidInput: true });
      }
      else if (data.amount <= 0) {
        this.sendForm.controls[`amount`].setErrors({ minAmountRequired: true });
      }
      else if (matchBeforeDecimal(data.amount, 10)) {
        this.sendForm.controls[`amount`].setErrors({ maxDigitsBeforeDecimal: true });
      }
      else if (matchAfterDecimal(data.amount, 8)) {
        this.sendForm.controls[`amount`].setErrors({ maxDigitsAfterDecimal: true });
      }
      else if (this.tokenInfo.baseChainSymbol === 'BTC' && (satoshiToNum(this.btcBalance) - Number(data.amount)) < MIN_BTC_TO_SEND) {
        this.cantEmptyBtcWallet = true;
      }
    }

    if (data && data.customGasPriceGwei != null && this.tokenInfo.baseChainSymbol === 'ETH') {
      if (data.customGasPriceGwei > this.maxGweiToSend) {
        this.sendForm.controls[`customGasPriceGwei`].setErrors({ maxGwei: true });
      }
    }

    if (data && data.customGasLimit != null && this.tokenInfo.baseChainSymbol === 'ETH') {
      if (data.customGasLimit > this.maxGasToSend) {
        this.sendForm.controls[`customGasLimit`].setErrors({ maxGasLimit: true });
      }
    }

    showFormErrors(this.sendForm, this.formErrors, this.validationMessages);
  }

  async validateAddress() {
    // check validity of address
    try {
      this.showMultilineAddress = false;
      const address = this.sendForm.controls.toAddress.value;
      if (address.length > 12) {
        let tempAddress = shortHandAddress(address);
        if (this.tokenInfo.symbol === 'USDC' || this.tokenInfo.symbol === 'ETH') {
          tempAddress = shortHandAddress(address, 8);
        }
        this.sendForm.controls[`toTempAddress`].setValue(tempAddress);
      }
      else {
        this.sendForm.controls[`toTempAddress`].setValue(address);
      }
      if (this.tokenInfo.baseChainSymbol === 'ETH' || this.tokenInfo.baseChainSymbol === 'AVAX' ||  this.tokenInfo.baseChainSymbol === 'SYSNEVM') {
        ethers.utils.getAddress(this.sendForm.controls.toAddress.value);
        return;
      }
      if (this.tokenInfo.baseChainSymbol === 'BTC') {
        const activeNetworkAddress = await this.networkService.getActiveBtcNetwork();
        bitcoin.address.toOutputScript(this.sendForm.controls.toAddress.value, bitcoin.networks[activeNetworkAddress.network]);
        return;
      }

      if (this.tokenInfo.baseChainSymbol === 'SOL') {
        const toPublicKey = new solanaWeb3.PublicKey(this.sendForm.controls.toAddress.value);
        return solanaWeb3.PublicKey.isOnCurve(toPublicKey.toBuffer());
      }

      const activeNetwork = await this.networkService.getActiveSysNetwork();

      const coinSymbol = networks[activeNetwork.network].bech32;
      bitcoin.address.toOutputScript(this.sendForm.controls.toAddress.value, networks[activeNetwork.network]);

      // assets can only be sent to bech32 addresses
      if (this.tokenInfo.assetGuid && this.sendForm.controls.toAddress.value.toLowerCase().indexOf(coinSymbol) !== 0) {
        this.sendForm.controls[`toAddress`].setErrors({ no_legacy_addresses: true });
        this.sendForm.controls[`toAddress`].markAsDirty();
        this.onValueChanged(null);
      }
    } catch (e) {
      Logger.info('invalid address', e);
      this.sendForm.controls[`toAddress`].setErrors({ invalid: true });
      this.sendForm.controls[`toAddress`].markAsDirty();
      this.onValueChanged(null);
    }
  }

  async updateMaxAmountValidator(newInfo: TokenInfo) {
    let maxAmount = SmallToCryptoPipe.prototype.transform(
      this.tokenInfo.balance,
      {
        precision: this.tokenInfo.precision,
        type: this.tokenInfo.baseChainSymbol
      });
    if (this.tokenInfo.assetGuid) {
      const fee = await this.transactionSender.calcAuxFees(this.tokenInfo.assetGuid, maxAmount);
      maxAmount -= fee;
    }
    this.validationMessages.amount.max = await this.translate.get('tokens.send.errors.amount_exceeds_balance', {
      balance: maxAmount
    }).toPromise();
    Logger.info('Update max validator', maxAmount);
    this.sendForm.controls[`amount`].setValidators([
      Validators.required,
      Validators.max(maxAmount)
    ]);
    this.sendForm.controls[`amount`].updateValueAndValidity();
  }

  updateFiatAmount(event) {
    this.sendForm.patchValue(
      { fiatAmount: round(Number(event.target.value) * (this.tokenInfo.retailFiatValue || this.tokenInfo.fiatValue), 4) }
    );
    this.amountInCurrency = (event.target.value * this.tokenInfo.fiatValue).toString();
  }

  updateTokenAmount(event) {
    this.sendForm.patchValue({ amount: round(event.target.value / (this.tokenInfo.retailFiatValue || this.tokenInfo.fiatValue), 8) });
  }

  async handleSendClick() {
    await this.validateAddress()
    if (!this.sendForm.valid) {
      return false;
    }
    if (this.baseToken == 'SYS' && !this.isValidateSysCoinTx()) {
      return false
    }
    Logger.info('send', this.sendForm.value);
    Logger.info('gasFeeData', this.gasFeeData);
    Logger.info('showAdvancedGasOptions', this.showAdvancedGasOptions);
    let sendToken;
    
    if(this.mode == 'sendAch') {
      sendToken = ({
        toAddress: this.sendForm.value.toAddress,
        amount: Number(this.sendForm.value.amount),    // converting if String to datatype number 
      });
    } else {
      sendToken = ({
        toAddress: this.sendForm.value.toAddress,
        toTempAddress: this.sendForm.value.toTempAddress,
        amount: Number(this.sendForm.value.amount),    // converting if String to datatype number 
        memo: this.sendForm.value.memo,
        ethGasFee: this.gasFeeData
          ? this.showAdvancedGasOptions
            ? this.getCustomGasWeiValue().toString()
            : this.getCurrentGasPrice().toString()
          : null,
        ethTxn: this.gasFeeData
          ? this.showAdvancedGasOptions
            ? this.getCustomGasEthValue().toString()
            : this.getGasFeeInNativeToken().toFixed(6).toString()
          : null,
        ethTxnFee: this.gasFeeData
          ? this.showAdvancedGasOptions
            ? this.getCustomGasFiatValue().toString()
            : this.getGasFeeInFiat().toString()
          : null,
        networkFee: this.networkFee
      });
    }
    this.openConfirmModal(sendToken);
  }

  private isValidateSysCoinTx(): boolean {
    if (this.noSys || this.notEnoughTxs) {
      return false;
    }
    return true;
  }

  getCurrentGasPrice() {
    if (this.tokenInfo.baseChainSymbol === 'SYS') {
      return 0;
    }
    const gasPriceInUnit = ethers.utils.parseEther(this.getGasFeeInNativeToken().toString());
    return this.isErc20() ? gasPriceInUnit.div(GAS_LIMIT_ERC20_SEND) : gasPriceInUnit.div(GAS_LIMIT_SEND);
  }

  handleCancelClick() {
    Logger.info('cancel');
  }

  public onQrCodeIconClickOld() {
    Logger.info('QR Click');
    this.store.dispatch(new SetSendTokenDataAction({
      fromAddress: '',
      toAddress: '',
      tokenInfo: this.tokenInfo,
      ethGasFee: '',
      amount: this.sendForm.controls.amount.value || 0,
      memo: this.sendForm.controls.memo.value || '',
      networkFee: ''
    }));
    this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: true }));
    setTimeout(() => {
      this.showQrCodeReader = !this.showQrCodeReader;
    }, 1000);
  }


  public async onQrCodeIconClick() {
    const modal = await this.modalController.create({
      component: ScannerComponent
    });
 
    modal.onDidDismiss().then(data => {
      Logger.info('scanQROnBrowser', JSON.stringify(data.data));
       this.sendForm.patchValue({
        toAddress: data.data?.scannedData,
      });
      this.focusAddress()
    });
    await modal.present();
  }

  clearForm() {
    if (this.sendForm) {
      this.sendForm.reset();
    }
  }

  setSendData(data: SendTokenData) {
    if (this.sendForm === undefined) {
      setTimeout(() => {
        this.setSendData(data);
      }, 1000);
      return;
    }
    this.sendForm.patchValue(data);
  }

  async focusToAddress() {
    if (this.amountInput === undefined) {
      setTimeout(() => {
        this.focusToAddress();
      }, 1000);
      return;
    }
    await this.amountInput.setFocus();
  }

  changeSelectedFeeTier(tier) {
    this.selectedFeeTier = tier;
    this.networkFee = this.getGasFeeInNativeToken().toFixed(5);
    this.networkFeeWithCurrency = this.getGasFeeInFiat();
  
    //Whenever user clicks on the delivery speed button after selecting the MAX feature, should update the max value based on nw fee
    !this.isErc20() ? this.onDeliverySpeedButtonClick() : undefined;
  }

  changeSelectedBtcFeeTier(tier) {
    this.selectedFeeTier = tier;
    this.networkFee = this.getBTCNetworkFee(tier);
    this.networkFeeWithCurrency = (this.networkFee * ((this.baseTokenKeyring.retailFiatValue || this.baseTokenKeyring.fiatValue))).toFixed(2);
    this.onDeliverySpeedButtonClick();
  }

  getBTCNetworkFee(tier) {
    return parseFloat(getObjectValueByKey(this.gasFeeData, tier)).toFixed(8);
  }


  isErc20() {
    return (this.tokenInfo.baseChainSymbol === 'ETH' || this.tokenInfo.baseChainSymbol === 'AVAX') && (this.tokenInfo.baseChainSymbol !== this.tokenInfo.symbol);
  }

  setShowMemoInputBox(state) {
    this.showMemoInputBox = state;
  }

  clearMax() {
    this.isMaxClicked = false;
    this.inputValue = null;
    this.amountInCurrency = '';
  }
  // TODO need to change
  calculateMaxValue() {
    this.isMaxClicked = true;
    let balanceToken = this.tokenInfo?.balance ? this.smallToCryptoPipe.transform(this.tokenInfo?.balance,
      { precision: this.tokenInfo.precision }) : undefined; //conversion from small to crypto
    let networkFee = 0;
    if (balanceToken === undefined || balanceToken === 0) {
      return;
    }

    if (this.tokenInfo.baseChainSymbol === 'SYS') {
      return;
    } else if (this.tokenInfo.baseChainSymbol === 'BTC') {
      networkFee = parseFloat(this.getBTCNetworkFee(this.selectedFeeTier));
      if (balanceToken < networkFee) {
        return;
      }
    } else if (this.tokenInfo.baseChainSymbol === this.tokenInfo.symbol) { // AVAX/ ETH native token
      networkFee = this.getGasFeeInNativeToken();
    }
    this.inputValue = (balanceToken - networkFee).toFixed(8).slice(0, -1);  //reducing network fee from total token balance
    this.amountInCurrencyCalculate(this.inputValue);
  }

  amountInCurrencyCalculate(inputData) {
    if (inputData) {
      this.sendForm.patchValue(
        { fiatAmount: round(Number(this.inputValue) * (this.tokenInfo.retailFiatValue || this.tokenInfo.fiatValue), 4) }
      );
      this.amountInCurrency = (this.inputValue * this.tokenInfo.fiatValue).toString();
    }
  }

  onDeliverySpeedButtonClick() {
    if (this.isMaxClicked) {
      let balanceToken = this.tokenInfo?.balance ? this.smallToCryptoPipe.transform(this.tokenInfo?.balance,
        { type: this.tokenInfo.baseChainSymbol }) : undefined;
      if (balanceToken === 0 || balanceToken === undefined) {
        return;
      }
      this.inputValue = (balanceToken - this.networkFee).toFixed(8).slice(0, -1);
      this.amountInCurrencyCalculate(this.inputValue);
    }
  }

  onKeyUp(ev) {
    if (isEnterKey(ev) && !(!this.sendForm.valid || this.noSys || this.notEnoughTxs)) {
      this.handleSendClick();
    }
  }

  onInputValueChange(ev) {
    // this.onKeyUp(ev);
    this.updateFiatAmount(ev);
    this.isMaxClicked = false;
  }

  toggleAdvancedGasOptions() {
    this.showAdvancedGasOptions = !this.showAdvancedGasOptions;
    if (!this.showAdvancedGasOptions) {
      this.resetAdvancedOptions();
    }
  }

  getCustomGasEthValue() {
    const gasPriceWei = this.getCustomGasWeiValue();
    const gasLimit = this.sendForm.controls.customGasLimit.value || 0;
    return weiToNum(gasPriceWei.mul(gasLimit));
  }

  getCustomGasWeiValue() {
    const gasPriceGwei = this.sendForm.controls.customGasPriceGwei.value || 0;
    return ethers.utils.parseUnits(gasPriceGwei, "gwei");
  }

  getCustomGasFiatValue() {
    return (this.getCustomGasEthValue() * this.gasFeeData.priceUSD).toFixed(2);
  }

  // ETH,  - in Wei
  // 
  getGasFeeInNativeToken(): number {
    let currentTier = this.isErc20() && !this.selectedFeeTier.includes('Erc20') ? this.selectedFeeTier + 'Erc20' : this.selectedFeeTier; // convert tier -> tier + Erc20 in the case for Erc20
    return parseFloat(getObjectValueByKey(this.gasFeeData, currentTier));
  }

  getGasFeeInFiat() {
    const gasFeeInNativeToken = this.getGasFeeInNativeToken();
    const fiatValue = +(this.baseTokenKeyring.retailFiatValue || this.baseTokenKeyring.fiatValue);
    return (gasFeeInNativeToken * fiatValue).toFixed(6);
  }

  updateInitialCustomGasPrice() {
    this.sendForm.controls.customGasPriceGwei.setValue(this.gasFeeData.standardBase);
  }

  resetAdvancedOptions() {
    this.sendForm.controls.customGasPriceGwei.setValue(DEFAULT_GWEI_TO_SEND);
    this.sendForm.controls.customGasLimit.setValue(DEFAULT_GAS_LIMIT_TO_SEND);
  }

  closeQr() {
    this.store.dispatch(new PreventAuthOnResumeAction({ preventLogout: false }));
  }

  focusAddress() {
    this.showMultilineAddress = true;
    setTimeout(async () => {
      await this.addressInput.setFocus();
    }, 1);
  }

  unfocusAddress() {
    this.showMultilineAddress = false;
  }

  changeGasSpeed(speed: string) {
    this.gasSpeed = speed;
  }

  changeDeliverySpeed(speed: string) {
    this.deliverySpeed = speed;
  }

  async openGasInfoModal() {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
    let cssClass = 'vault-promo-modal';
    cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
    const modal = await this.modalController.create({
      component: GasInfoModalComponent,
      componentProps: {
        kycData: this.kycData,
        tokenInfo: this.tokenInfo
      },
      cssClass,
      id: 'gasInfoModal'
    });

    await modal.present();
    await modal.onDidDismiss().then();
  }

  async openConfirmModal(sendTokenData) {
    const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
    let cssClass = '';
    cssClass += (this.isDesktopMode) ? 'confirm-modal-desktop' : 'confirm-modal';
    cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
    const modal = await this.modalController.create({
      component: ConfirmModalComponent,
      cssClass,
      componentProps: {
        sendTokenData,
        tokenInfo: this.tokenInfo,
        mode: this.mode
      },
      backdropDismiss: false,
      id: 'confirmModal',
      initialBreakpoint: (this.isDesktopMode) ? undefined : 0.8,
      breakpoints: (this.isDesktopMode) ? undefined : [0, 0.8, 1],
    });
    await modal.present();
    await modal.onDidDismiss().then(data => Logger.info(data));
  }

  async openPurchaseGasModal() {
    if (this.kycData && this.kycData['passingLevel']) {
      const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
      let cssClass = '';
      cssClass += (this.isDesktopMode) ? 'purchase-gas-modal-desktop' : 'purchase-gas-modal';
      cssClass += (!sideMenuHidden && this.isDesktopMode) ? ' with-side-menu' : '';
      const modal = await this.modalController.create({
        component: PurchaseGasModalComponent,
        cssClass,
        componentProps: {
          assetGuid: this.tokenInfo.assetGuid,
          tokenInfo: this.tokenInfo
        },
        initialBreakpoint: (this.isDesktopMode) ? undefined : 0.5,
        breakpoints: (this.isDesktopMode) ? undefined : [0, 0.5, 1],
      });
      await modal.present();
      await modal.onDidDismiss().then();
    } else {
      const sideMenuHidden = await this.store.select(getSideMenuStatus).pipe(take(1)).toPromise();
      await openKycModal(sideMenuHidden, this.isDesktopMode, this.modalController);
    }
  }

  async closeModal() {
    await this.modalController.dismiss();
  }
}
