import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

@NgModule({
  declarations: [],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink): NamedOptions => ({
        dexcandles: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/dexcandles',
          }),
        },
        dexcandlesFuji: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/dexcandles-fuji',
          }),
        },
        exchange: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/exchange',
          }),
        },
        exchangeFuji: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: 'https://api.thegraph.com/subgraphs/name/traderjoe-xyz/exchange-fuji',
          }),
        }
      }),
      deps: [HttpLink],
    }
  ],
  imports: [
    CommonModule,
    ApolloModule
  ]
})
export class GraphqlModule { }
