import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { QrDataServiceService } from '../../angular-wallet-base/services/qr-data-service.service';
import { Logger } from '../../angular-wallet-base/services/logger.service';

@Component({
    selector: 'app-qrcode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent implements OnInit {

    inputPublicKey: string;
    @Output() messageEvent = new EventEmitter<string>();
    @Output() close = new EventEmitter<void>();
    constructor(
        private barcodeScanner: BarcodeScanner,
        private route: ActivatedRoute,
        private data: QrDataServiceService
    ) {
    }

    token = this.route.snapshot.paramMap.get('token');
    startCamera() {
        return this.barcodeScanner.scan(
            {
                preferFrontCamera: false, // iOS and Android
                showFlipCameraButton: true, // iOS and Android
                showTorchButton: true, // iOS and Android
                torchOn: false, // Android, launch with the torch switched on (if available)
                prompt: 'Place a barcode inside the scan area', // Android
                resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                formats: 'QR_CODE,PDF_417', // default: all but PDF_417 and RSS_EXPANDED
                orientation: 'portrait', // Android only (portrait|landscape), default unset so it rotates with the device
                disableAnimations: true, // iOS
                disableSuccessBeep: false // iOS and Android,
            }
        ).then(val => {
            this.data.changeMessage(val.text);
        }).catch(Logger.error).finally(() => {
            this.close.emit()
        });
    }

    ngOnInit() {
        this.data.currentMessage.subscribe(inputPublicKey => this.inputPublicKey = inputPublicKey);
        return this.startCamera();
    }

}
