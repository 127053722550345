import { Action } from "@ngrx/store";
import { StorageSyncActions } from "../lib/ngrx-store-ionic-storage";

export class HydratedAction implements Action {
    readonly type = StorageSyncActions.HYDRATED;
    constructor(readonly payload: any) { }
}

export class HydrateAction implements Action {
    readonly type = StorageSyncActions.HYDRATE;
    constructor() { }
}