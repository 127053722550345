import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AUTH_DATA_KEY, SKIP_INTRO_PAGE_KEY } from '../constants';
import { StorageService } from './storage.service';


@Injectable()
export class NonAuthGuardService implements CanActivate {

  constructor(
    private storage: StorageService,
    private nav: NavController
  ) {

  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    // check if user data is not exists in storage
    const authData = await this.storage.get(AUTH_DATA_KEY);
    if (authData) {
      return false;
    }
    return true;
  }
}
